import React, {useContext} from "react";
import { useHistory } from "react-router-dom";
import { Box, Grid } from "@material-ui/core";

import { BackNavigation } from "components/_others/BackNavigation";
import { Title } from "../../../../../components/_consumer/ObjectDetails";
import { ActionButtons } from "./ActionButtons";
import {fixedHeaderStyles} from "../../../../../assets/styles/fixed-header";
import {UserContext} from "../../../../../core/context/user";
import {UserRoles} from "../../../../../core/enums";

type HeaderProps = {
    name: string;
    detailsPercent: number;
    isOnEditMode: boolean;
    isSharedToPublicFeed: boolean;
    toggleEditMode: (newState: boolean, onBack: boolean) => void;
    onSaveObject: () => void;
    noOfRecommendedExperts: number;
    paymentFreePass: boolean;
}

export const Header: React.FC<HeaderProps> = (
    {name, isOnEditMode, isSharedToPublicFeed, toggleEditMode, onSaveObject,
        noOfRecommendedExperts, paymentFreePass, detailsPercent}
) => {

    const history = useHistory();
    const fixedHeaderCss = fixedHeaderStyles();
    const userContext = useContext(UserContext);

    const handlePageLeave = (): void => {
        if(isOnEditMode) {
            toggleEditMode(false, true);
        } else {
            history.goBack()
        }
    }

    return (
        <Box className={`${fixedHeaderCss.root} page-header-container`}
             style={userContext.user?.role === UserRoles.COLLECTOR ? {} : {top: 130}}>
            <BackNavigation
                notify={() => handlePageLeave()}
            />

            <Grid container>
                <Grid item xs={12} sm={12} md={6} xl={8}>
                    <Title
                        name={name}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} xl={4}>
                    <ActionButtons
                        isOnEditMode={isOnEditMode}
                        detailsPercent={detailsPercent}
                        onToggleEditMode={(newState: boolean) => toggleEditMode(newState, false)}
                        onSaveObject={() => onSaveObject()}
                        noOfRecommendedExperts={noOfRecommendedExperts}
                        paymentFreePass={paymentFreePass}
                        isSharedToPublicFeed={isSharedToPublicFeed}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
