import React, {useContext, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { Box, Container } from "@material-ui/core";

import "scenes/consumer/sharedWithMe/styles/style.less";

import { AppRoutes } from "core/helpers/app-routes";
import { CollectorShareApi } from "core/api/consumer/collector-share.api";
import { GalleryTypeEnum } from "components/Gallery/helpers/GalleryTypeEnum";
import { SharedWithMeContext } from "core/context/consumer/sharedWithMe";

import { Loader } from "components/layout/_components/Loader";
import { Gallery } from "components/Gallery";
import {getCurrentPageFromUrl} from "core/helpers/utils";
import {Statistics} from "../../../_components/Statistics";
import {UtilsContext} from "../../../../../core/context/utils.context";

export const SharedWithMeDashboard: React.FC = () => {

    const sharedWithMeContext = useContext(SharedWithMeContext);
    const history = useHistory();

    const utilsContext = useContext(UtilsContext);
    useEffect(() => {
        const pageArtifacts = getCurrentPageFromUrl('pageArtifacts');
        sharedWithMeContext.setCurrentPageObjects(pageArtifacts);
        getArtifacts(pageArtifacts);

        const pageCollections = getCurrentPageFromUrl('pageCollections');
        sharedWithMeContext.setCurrentPageCollections(pageCollections);
        getCollections(pageCollections);
    }, [])

    const getArtifacts = (page: number): void => {
        sharedWithMeContext.setLoadingObjects(true);

        CollectorShareApi.getSharedObjects(sharedWithMeContext.paginationObjects.size, page)
            .then((res: any) => {
                sharedWithMeContext.setArtifacts(res.data);
                window.scrollTo(0, 0);
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error', 'Sorry, there was an error and we were unable to display ' +
                    'your shared objects.')
            })
    }

    const getCollections = (page: number): void => {
        sharedWithMeContext.setLoadingCollections(true);

        CollectorShareApi.getSharedCollections(sharedWithMeContext.paginationCollections.size, page)
            .then((res: any) => {
                sharedWithMeContext.setCollections(res.data);
                window.scrollTo(0, 0);
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error', 'Sorry, there was an error and we were unable to display your' +
                    ' shared collections.')
            })
    }

    const handlePageChangeObjects = (page: number): void => {
        // eslint-disable-next-line max-len
        history.push(`${AppRoutes.SHARED_WITH_ME}?pageArtifacts=${page}&pageCollections=${sharedWithMeContext.paginationCollections.page}`);
        sharedWithMeContext.setCurrentPageObjects(page);
        getArtifacts(page);
    }

    const handlePageChangeCollections = (page: number): void => {
        // eslint-disable-next-line max-len
        history.push(`${AppRoutes.SHARED_WITH_ME}?pageArtifacts=${sharedWithMeContext.paginationObjects.page}&pageCollections=${page}`);
        sharedWithMeContext.setCurrentPageCollections(page);
        getCollections(page);
    }

    return (
        <Container maxWidth={false} className="shared-with-me-container">
            {
                !sharedWithMeContext.isLoadingObjects &&
                    <>
                        <Box style={{paddingTop: 15, paddingBottom: 15}}>
                            <Statistics
                                statistics={[
                                    {label: 'Total Objects', value: `${sharedWithMeContext.statistics.totalObjects}`}
                                ]}
                            />
                        </Box>

                        <Box>
                            <Gallery
                                pagination={{
                                    numberOfPages: sharedWithMeContext.paginationObjects.numberOfPages,
                                    currentPage: sharedWithMeContext.paginationObjects.page,
                                }}
                                galleryType={GalleryTypeEnum.SharedWithMe}
                                data={sharedWithMeContext.artifacts}
                                handlePageChange={(page: number) => handlePageChangeObjects(page)}
                            />
                        </Box>
                    </>
            }

            {
                sharedWithMeContext.isLoadingObjects &&
                    <Loader />
            }

            {
                !sharedWithMeContext.isLoadingCollections &&
                    <>
                        <Box style={{paddingBottom: 15}}>
                            <Statistics
                                statistics={[
                                    {
                                        label: 'Total Collections',
                                        value: `${sharedWithMeContext.statistics.totalCollections}`
                                    }
                                ]}
                            />
                        </Box>

                        <Box>
                            <Gallery
                                pagination={{
                                    numberOfPages: sharedWithMeContext.paginationCollections.numberOfPages,
                                    currentPage: sharedWithMeContext.paginationCollections.page,
                                }}
                                galleryType={GalleryTypeEnum.CollectionsSharedWithMe}
                                data={sharedWithMeContext.collections.map(c => c.collection)}
                                handlePageChange={(page: number) => handlePageChangeCollections(page)}
                            />
                        </Box>
                    </>
            }

            {
                (sharedWithMeContext.isLoadingCollections && !sharedWithMeContext.isLoadingObjects) &&
                <Loader />
            }
        </Container>
    )
}
