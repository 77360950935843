import React, { useState } from "react";
import { modalStyles } from "components/_others/modals/modal-styles";
import { Box, Button, CircularProgress, TextField, Typography } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import EmailIcon from "@material-ui/icons/Email";
import DoneIcon from "@material-ui/icons/Done";

import { validateEmail } from "core/helpers/validators";
import { UserApi } from "core/api/user";
import {ConfirmEmailModal} from "../ConfirmationPin/ConfirmEmailModal";

type EnterEmailProps = {
    userEmail?: string|null;
    handleClose: () => void;
    onEmailSent: (email: string) => void;
}

export const EnterEmail: React.FC<EnterEmailProps> = ({userEmail, handleClose, onEmailSent}) => {

    const classes = modalStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [reqResult, setReqResult] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [email, setEmail] = useState({text: '', error: ''});

    const [emailNotConfirmed, setEmailNotConfirmed] = useState(false);
    const [displayConfirmEmailModal, toggleDisplayConfirmEmail] = useState(false);
    const [emailHasBeenConfirmed, setEmailHasBeenConfirmed] = useState(false);

    const computeEmailError = (): string => {

        let error = '';

        if(!email?.text.length) {
            error = 'Please enter your email';
        } else if(!validateEmail(email.text)) {
            error = 'Please enter a valid email address';
        }

        setEmail({...email, error});
        return error;
    }

    const onSave = (): void => {

        if(!userEmail) {
            const error = computeEmailError();
            if(error) {
                return;
            }
        }

        if(emailNotConfirmed) {
            sendConfirmationEmail();
            return;
        }

        setIsLoading(true);
        setReqResult(false);
        setErrorMessage('');

        UserApi.sendForgotPasswordEmail(userEmail ? userEmail : email.text)
            .then(() => {
                setIsLoading(false);
                setReqResult(true);
                setEmailNotConfirmed(false);
                setEmailHasBeenConfirmed(false);
                onEmailSent(userEmail ? userEmail : email.text);
            }, (error: any) => {
                console.error(error.response);
                if(error.response.data.message === 'UserNotFound') {
                    setErrorMessage('Sorry, there is no user associated with your email address.');
                } else if(error.response.data.message === 'UserNotConfirmed') {
                    setErrorMessage('Your account is not confirmed yet.');
                    setEmailNotConfirmed(true);
                } else if (error.response.data.message === 'ExternalRegister') {
                    setErrorMessage('This email is registered with an external provider.' +
                        ' You will not be able to set up a password for this account');
                } else {
                    setErrorMessage('Sorry, there was an error. Please try again later.');
                }
                setIsLoading(false);
                setReqResult(false);
            })
    }

    const sendConfirmationEmail = (): void => {
        setIsLoading(true);
        setReqResult(false);
        setErrorMessage('');

        UserApi.resendRegisterConfirmationEmail(email.text)
            .then(() => {
                setIsLoading(false);
                toggleDisplayConfirmEmail(true);
            })
            .catch((error: any) => {
                setIsLoading(false);
                console.error(error);
                setErrorMessage('Sorry, there was an error. Please try again later.');
            })
    }

    const handleKeyPress = (e: any): void => {
        if(e.key === 'Enter') {
            onSave();
            e.preventDefault();
        }
    }

    return (
        <>
            <Box marginBottom={'20px'}>
                {
                    !userEmail &&
                    <Typography className={classes.modalSubtitle}>
                        Enter you email associated with your account and
                        we will send an email with instructions to reset
                        your password.
                    </Typography>
                }

                {
                    userEmail &&
                    <Typography className={classes.modalSubtitle}>
                        We will send an email with instructions to reset
                        your password, on <b>{userEmail}</b>.
                    </Typography>
                }

            </Box>

            {
                !userEmail &&
                <Box>
                    <TextField
                        id="forgot-password-email-field"
                        variant="outlined"
                        size="small"
                        type="text"
                        placeholder="Email"
                        fullWidth
                        error={email.error !== ''}
                        helperText={email.error}
                        value={email.text}
                        inputProps={{ maxLength: 100 }}
                        onChange={(e: any) => setEmail({
                            ...email,
                            text: e.target.value,
                        })}
                        onBlur={() => computeEmailError()}
                        onKeyPress={(e: any) => handleKeyPress(e)}
                    />
                </Box>
            }

            {
                !reqResult &&
                <Typography className="error-message" color="error">
                    {errorMessage}
                </Typography>
            }

            {
                emailHasBeenConfirmed &&
                <Typography className="error-message" color="primary">
                    Your email has been confirmed. You can now request password change.
                </Typography>
            }

            <Box textAlign={'right'} marginTop={'60px'}>
                <Button
                    id="cancel-delete-btn"
                    variant="contained"
                    size={"small"}
                    style={{marginRight: '15px'}}
                    className={classes.secondaryButton}
                    onClick={handleClose}
                >
                    <CloseIcon height={'20px'} /> Cancel
                </Button>

                <Button
                    id="delete-btn"
                    variant="contained"
                    color="primary"
                    size={"small"}
                    className={classes.primaryButton}
                    onClick={() => onSave()}
                >
                    {
                        isLoading && <CircularProgress className="circular-progress sm" />
                    }
                    {
                        !isLoading && !reqResult && <EmailIcon style={{
                            width: 20, marginRight: 5
                        }} />
                    }
                    {
                        reqResult &&
                        <>
                            <DoneIcon />
                        </>
                    }
                    {emailNotConfirmed ? 'Send confirmation email' : 'Send Email'}
                </Button>
            </Box>

            <ConfirmEmailModal
                open={displayConfirmEmailModal}
                handleClose={() => toggleDisplayConfirmEmail(false)}
                credentials={{email: email.text, password: ''}}
                onSuccess={() => {
                    setReqResult(true);
                    setEmailNotConfirmed(false);
                    toggleDisplayConfirmEmail(false);
                    setEmailHasBeenConfirmed(true);
                }}
                message={'Please enter the code received on your email here:'}
            />
        </>
    )
}
