import React, {useState} from "react";
import {modalStyles} from "components/_others/modals/modal-styles";
import {Backdrop, Box, Button, CircularProgress, Fade, Modal, TextField, Typography} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from '@material-ui/icons/Save';

import {useNewCollection} from "../hooks/use-new-collection.hook";
import {ModalHeader} from "components/_others/modals/ModalHeader";

type AddNewCollectionModalProps = {
    open: boolean;
    handleClose: () => void;
}

export const AddNewCollectionModal: React.FC<AddNewCollectionModalProps> = ({open,handleClose}) => {

    const classes = modalStyles();

    const [collectionName, setCollectionName] = useState({text: '', error: ''});

    const {isLoading, addNewCollection} = useNewCollection();

    const onSave = (): void => {
        if(!collectionName.text.length) {
            setCollectionName({...collectionName, error: 'Please enter a collection name'});
            return;
        }
        addNewCollection(collectionName.text?.trim()).then((res: boolean) => {
            if(res) {
                handleClose();
            }
        })
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>

                    <ModalHeader
                        title={'Add new collection'}
                        handleClose={() => handleClose()}
                    />

                    <Typography className={classes.modalSubtitle} style={{marginBottom: 20}}>
                        Please enter a name for your collection
                    </Typography>

                    <Box>
                        <TextField
                            id="collectionName"
                            variant="outlined"
                            size="small"
                            type="text"
                            placeholder="Collection Name"
                            fullWidth
                            error={collectionName.error !== ''}
                            helperText={collectionName.error}
                            value={collectionName.text}
                            inputProps={{ maxLength: 100 }}
                            onChange={(e: any) => setCollectionName({
                                text: e.target.value,
                                error: e.target.value.length === 0 ? 'Please enter a collection name' : ''
                            })}
                        />
                    </Box>

                    <Box textAlign={'right'} marginTop={'60px'}>
                        <Button
                            id="cancel-btn"
                            variant="contained"
                            size={"small"}
                            style={{marginRight: '15px'}}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>

                        <Button
                            id="confirm-btn"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            className={classes.primaryButton}
                            onClick={() => onSave()}
                        >
                            {
                                isLoading && <CircularProgress className="circular-progress sm" />
                            }
                            {
                                !isLoading && <SaveIcon style={{
                                    width: 20, marginRight: 5
                                }} />
                            }
                            Save
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    )
}
