import React, { useState } from "react";
import { makeStyles} from "@material-ui/styles";

import { EditUserAccessModal } from "../EditUserAccessModal/EditUserAccessModal";

type AccessStatusLabelProps = {
    isActive: boolean;
    userId: number;
    hasActiveJobs?: boolean;
}

const useStyles = makeStyles({
    root: {
        fontSize: 14,
        padding: '5px 15px',
        borderRadius: 2,
        cursor: 'pointer',
    }
})

export const AccessStatusLabel: React.FC<AccessStatusLabelProps> = ({isActive, userId, hasActiveJobs}) => {

    const classes = useStyles();

    const [isEnabled, setIsEnabled] = useState(isActive);

    const [displayEditModal, setDisplayEditModal] = useState(false);

    return (
        <>
           <span
               onClick={() => setDisplayEditModal(true)}
               className={classes.root}
               style={
                   isEnabled
                       ? {backgroundColor: 'rgb(200 230 198)', color: 'rgb(9 107 5)'}
                       : {backgroundColor: 'rgb(234 184 184)', color: 'rgb(107 5 5)'}
               }
           >
                {isEnabled ? 'Active' : 'Inactive'}
            </span>

            <EditUserAccessModal
                open={displayEditModal}
                userId={userId}
                enable={!isEnabled}
                hasActiveJobs={hasActiveJobs}
                handleClose={() => setDisplayEditModal(false)}
                onSuccess={() => setIsEnabled(!isEnabled)}
            />
        </>
    )
}
