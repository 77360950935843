import {UserRoles} from "../enums";
import {AppRoutes} from "./app-routes";

export function homepageByUserRole(userRole: string|undefined): string {
    if(userRole === UserRoles.COLLECTOR) {
        return AppRoutes.MY_OBJECTS;
    }
    if (userRole === UserRoles.EXPERT) {
        return AppRoutes.FEEDBACK_REQUESTS;
    }
    if (userRole === UserRoles.ADMIN) {
        return AppRoutes.FEEDBACK_REQUESTS;
    }
    if (userRole === UserRoles.ARCHIVIST || userRole === UserRoles.CURATOR) {
        return AppRoutes.ASSIGNED_COLLECTORS_INDEX;
    }
    return AppRoutes.SIGN_IN;
}
