import React, {useContext, useState} from "react";
import {Backdrop, Box, Button, Fade, Modal, Typography} from "@material-ui/core";

import { modalStyles } from "../../../_others/modals/modal-styles";
import CloseIcon from "@material-ui/icons/Close";

import { ModalHeader} from "../../../_others/modals/ModalHeader";
import { ShareOption} from "./ShareOption";

import { ShareArtifactOptions } from "core/enums";
import {CommunityFeedApi} from "../../../../core/api/consumer";
import {UtilsContext} from "../../../../core/context/utils.context";

type SelectShareOptionModalProps = {
    artifactId: number;
    canBeSharedToFeed: boolean;
    isSharedToPublicFeed: boolean;
    open: boolean;
    handleClose: () => void;
    onSelect: (type: number) => void;
    onRemove: () => void;
}

export const SelectShareOptionModal: React.FC<SelectShareOptionModalProps> = (
    {artifactId, isSharedToPublicFeed, canBeSharedToFeed, open, handleClose, onSelect, onRemove}
) => {

    const classes = modalStyles();

    const utilsContext = useContext(UtilsContext);

    const [isLoading, setIsLoading] = useState(false);

    const onRemoveShare = (): void => {
        if(!confirm('Are you sure you want to remove this object from the Community Feed?')) {
            return;
        }

        if(isLoading) {
            return;
        }

        setIsLoading(true);
        CommunityFeedApi.removeObjectFromCommunityFeed(artifactId)
            .then(() => {
                setIsLoading(false);
                utilsContext.addSnackbar('success', 'Success! Your object was removed from the Community Feed.');
                handleClose();
                onRemove();
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar('error');

            })
    }

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>

                    <ModalHeader
                        title={'Share Object'}
                        handleClose={() => handleClose()}
                    />

                    <Box marginTop={1.5}>
                        {
                            isSharedToPublicFeed &&
                                <Box>
                                    <Typography color={'secondary'} style={{marginBottom: 15, fontSize: 14}}>
                                        Your artifact is shared to the community feed.
                                        Please{' '}
                                        <span
                                            onClick={() => onRemoveShare()}
                                            style={{textDecoration: 'underline', cursor: 'pointer'}}
                                        >
                                            click here
                                        </span>
                                        {' '}if you want to remove the artifact from the
                                        Community Feed.
                                    </Typography>
                                </Box>
                        }
                        {
                            !canBeSharedToFeed && !isSharedToPublicFeed &&
                            <Box>
                                <Typography color={'secondary'} style={{marginBottom: 15, fontSize: 14}}>
                                    You can not share this object to the Community Feed, unless you received
                                    a feedback from one of our experts.
                                </Typography>
                            </Box>
                        }
                        {
                            !isSharedToPublicFeed && canBeSharedToFeed && <ShareOption
                                title={'Share to Community Feed'}
                                description={'Your object will be shared with all the community.'}
                                color={'#029BFE'}
                                onSelect={() => onSelect(ShareArtifactOptions.PublicFeed)}
                            />
                        }

                        <ShareOption
                            title={'Share with specific persons'}
                            description={'Your object will be shared with people you choose.'}
                            color={'#FE6502'}
                            onSelect={() => onSelect(ShareArtifactOptions.People)}
                        />
                    </Box>

                    <Box textAlign={'right'} marginTop={3}>
                        <Button
                            id="cancel-share-btn"
                            variant="contained"
                            size={"small"}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    )
}
