import {makeStyles} from "@material-ui/styles";

export const appDrawerStyles = makeStyles(() => ({
    root: {
        width: 220,
        flexShrink: 0,
    },
    paper: {
        width: 220,
        backgroundColor: "#2B2B33",
        overflow: 'hidden',
        boxShadow: '0px 3px 10px #00000029',
    },
    logoContainer: {
      textAlign: 'center',
      marginTop: 20,
      marginBottom: '40%'
    },
    themeSwitchContainer: {
        position: 'absolute',
        bottom: 20,
        left: 40
    }
}));

export const drawerNavigationStyles = makeStyles({
    listItem: {
        color: '#EFEFEF',
        fontSize: 16,
        fontWeight: 400,
        borderRadius: '2px 0px 0px 2px',
        marginLeft: 10,
        padding: '5px 16px',
        cursor: 'pointer'
    },
    link: {
        color: 'inherit',
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        minWidth: '40px',
        color: 'inherit !important'
    }
})
