import {useContext, useState} from "react";
import { useHistory } from "react-router-dom";
import {UtilsContext} from "core/context/utils.context";
import {CollectionApi} from "core/api/collection.api";
import {AppRoutes} from "core/helpers";

export const useRemoveCollection = (): any => {

    const history = useHistory();
    const utilsContext = useContext(UtilsContext);

    const [isLoading, setIsLoading] = useState(false);

    const removeCollection = async (collectionId: number): Promise<boolean> => {
        setIsLoading(true);

        return await CollectionApi.removeCollection(collectionId)
            .then(() => {
                setIsLoading(false);
                utilsContext.addSnackbar('success', 'Success! Your collection was removed.');
                history.push(AppRoutes.MY_COLLECTIONS);
                return true;
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar('error');
                return false;
            })
    }

    return {
        isLoading,
        removeCollection,
    }
}
