import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from '@material-ui/core';

import { TutorialViews } from './views.enum';
import { AppRoutes } from 'core/helpers/app-routes';

import { TakePhoto } from './components/TakePhoto';
import { HowToOrganize } from './components/HowToOrganize';
import { HowToPhotograph } from './components/HowToPhotograph';
import { StepDots } from './components/StepDots';
import { UserContext } from 'core/context/user';
import {UserRoles} from "../../../core";

type TutorialProps = {
    activeStep?: number;
    hideNavigation?: boolean;
};

export const Tutorial: React.FC<TutorialProps> = ({ activeStep, hideNavigation }) => {
    const history = useHistory();
    const userContext = useContext(UserContext);

    const [currentView, setCurrentView] = useState(activeStep || TutorialViews.TakePhoto);

    const goToAddObject = (): void => {
        const currentUserRole = userContext.user?.role;
        if(currentUserRole === UserRoles.ARCHIVIST || currentUserRole === UserRoles.CURATOR) {
            history.push(AppRoutes.ASSIGNED_COLLECTORS_INDEX);
            return;
        }
        history.push(AppRoutes.ADD_OBJECT)
    }

    return <Container>
        {currentView === TutorialViews.TakePhoto && (
            <TakePhoto changeView={() => setCurrentView(TutorialViews.HowToOrganize)} />
        )}
        {currentView === TutorialViews.HowToOrganize && (
            <HowToOrganize
                hideNavigation={hideNavigation}
                onSkip={() => history.push(AppRoutes.ADD_OBJECT)}
                onNext={() => setCurrentView(TutorialViews.HowToPhotograph)}
            />
        )}
        {currentView === TutorialViews.HowToPhotograph && (
            <HowToPhotograph
                hideNavigation={hideNavigation}
                onAddObject={goToAddObject}
            />
        )}
        {(currentView === TutorialViews.HowToOrganize || currentView === TutorialViews.HowToPhotograph) && (
            <StepDots view={currentView} changeView={(view: number) => setCurrentView(view)} />
        )}
    </Container>
};
