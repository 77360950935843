import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";

type SwitchViewProps = {
    text: string;
    btnText: string;
    redirectUrl: string;
}

export const SwitchAuthView: React.FC<SwitchViewProps> = ({text, btnText, redirectUrl}) => {

    const history = useHistory();

    return (
        <Box className="switch-auth-view">
            <Typography className="text" color="textPrimary">
                {text}
            </Typography>

            <Button
                fullWidth
                id="switch-auth-view-btn"
                variant="outlined"
                color="primary"
                onClick={() => history.push(redirectUrl)}
            >
                {btnText}
            </Button>
        </Box>
    )
}
