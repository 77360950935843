import {Box, Typography} from "@material-ui/core";
import React from "react";

type AboutObjectShortProps = {
    title: string;
    description: string;
    id: number;
}

export const AboutObjectShort: React.FC<AboutObjectShortProps> = (
    {title, description, id}
) => {
    return (
        <Box>
            <Typography
                variant="h6"
                color="textPrimary"
                style={{
                    fontWeight: 'bold',
                    fontSize: 16,
                    lineHeight: 1.3,
                }}
            >
                {title}
                <Typography
                    color={"textSecondary"}
                    style={{
                        display: 'inline-block',
                        marginLeft: 10,
                        fontSize: 13
                    }}
                >
                    #{id}
                </Typography>
            </Typography>

            <Typography
                variant="h6"
                color={"textPrimary"}
                style={{
                    marginBottom: 7,
                    fontSize: 14,
                    lineHeight: 1.3,
                    marginTop: 7
                }}
            >
                {description}
            </Typography>
        </Box>
    )
}
