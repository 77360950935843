import React, { useState } from 'react';
import { Button, Grid, InputLabel, TextField } from '@material-ui/core';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { makeStyles } from '@material-ui/core/styles';
import { FeedbackRequestsApi } from 'core/api/feedback-requests/feedback-requests.api';
import { IFeedbackComment } from 'core/context/feedback-requests/feedback';

type AddCommentProps = {
    artifactId: number;
    feedbackRequestId: number;
    pushComment: (comment: IFeedbackComment) => void;
};

export const AddComment: React.FC<AddCommentProps> = ({ feedbackRequestId, pushComment, artifactId }) => {
    const classes = useStyles();

    const [comment, setComment] = useState('');

    const addComment = (): void => {
        if (!comment?.trim().length) {
            return;
        }

        onAddComment();
        setComment('');
    };

    const onAddComment = (): void => {
        FeedbackRequestsApi.addComment(feedbackRequestId, JSON.stringify(comment), true, artifactId)
            .then((res: any) => {
                pushComment(res.data);
                setComment('');
            })
            .catch((error: any) => {
                console.error(error.response);
            });
    };

    // const handleKeyPress = (key: string): void => {
    //     if(key === 'Enter') {
    //         addComment();
    //     }
    // }

    return (
        <form id="add-comment-form" className={classes.root}>
            <InputLabel htmlFor="add-comment-textarea" className={classes.label}>
                Add a comment
            </InputLabel>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={10} lg={11}>
                    <TextField
                        id="add-comment-textarea"
                        multiline
                        fullWidth
                        value={comment}
                        onChange={(e: any) => setComment(e.target.value)}
                        rows={2}
                        rowsMax={5}
                        inputProps={{ maxLength: 1000 }}
                        variant="outlined"
                        className={classes.textarea}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        id="add-comment-btn"
                        className={classes.button}
                        onClick={() => addComment()}
                    >
                        <ChatBubbleIcon />
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

const useStyles = makeStyles({
    root: {
        marginTop: 15,
    },
    label: {
        marginBottom: 15,
        fontSize: 14,
    },
    textarea: {
        width: '96%',
        ['@media (max-width:960px)']: { width: '100%' },
    },
    button: {
        float: 'right',
        height: 55,
        padding: '0px 10px',
        fontWeight: 300,
        fontSize: 15,
        ['@media (max-width:960px)']: { height: 35, width: 100 },
    },
});
