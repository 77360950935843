import {makeStyles} from "@material-ui/core/styles";

export const howToPhotographStyles = makeStyles({
    root: {
        textAlign: 'center',
        marginTop: '3%',
        minHeight: '40px',
    },
    videoContainer: {
        height: 300,
        maxWidth: 590,
        margin: 'auto',
        marginBottom: 20
    },
    title: {
        fontSize: 22,
        marginBottom: '2%'
    },
    subtitle: {
        color: '#B2B2B2',
        fontSize: 14,
        marginBottom: '2%',
        maxWidth: 650,
        margin: 'auto'
    },
    navigationContainer: {
        margin: '20px auto',
        maxWidth: 450,
        maxHeight: 40,
    },
})
