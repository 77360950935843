import {JobsApi} from "core/api/jobs.api";
import {useContext, useState} from "react";
import {UtilsContext} from "core/context/utils.context";

export const useRemoveJob = (userId: number, collectorId: number, role: string): any => {

    const utilsContext = useContext(UtilsContext);

    const [isLoading, setIsLoading] = useState(false);

    const removeJob = async (): Promise<boolean> => {
        setIsLoading(true);

       return await JobsApi.removeJob(userId, collectorId, role)
            .then(() => {
                setIsLoading(false);
                utilsContext.addSnackbar(
                    'success',
                    'Success!'
                );
                return true;
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar(
                    'error',
                    'Sorry, there was an error and we were unable to remove this job.'
                );
                return false;
            })
    }

    return {
        removeJob,
        isLoading,
    }
}
