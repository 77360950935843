import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export const rootStyles = makeStyles((_theme: Theme) => ({
    root: {
        padding: '25px 30px 15px',
        ["@media (max-width:855px)"]: { padding: '25px 15px 15px' },
    },
    widgetsContainer: {
        marginTop: 40,
        maxWidth: 900,
        display: 'flex',
        justifyContent: 'space-between',
        ["@media (max-width:855px)"]: { display: 'block' },
    },
    objectsContainer: {
        marginTop: 20,
        maxWidth: 900,
    }
}));
