import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';

import { objectStyles } from '../styles/objects-list.style';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import imagePlaceholder from 'assets/placeholders/artifact-placeholder.png';

import { AppRoutes, getPhotoUrl } from 'core/helpers';
import { FileSize, ReportTypeEnum } from 'core/enums';
import { IReportObject } from '../types';

import { ObjectDetailsProgressBar } from 'scenes/consumer/_components/ObjectDetailsProgressBar';
import { RemoveFeedObjectModal } from 'scenes/consumer/_components/RemoveFeedObjectModal';

type ObjectDetailsProps = {
    object: IReportObject;
    reportType: string;
    onObjectRemove: (id: number) => void;
};

export const ObjectDetails: React.FC<ObjectDetailsProps> = ({ object, reportType, onObjectRemove }) => {
    const classes = objectStyles();

    const [isRemoveObjectFeedModalOpen, toggleRemoveObjectFeedModal] = useState(false);
    const [isRemovedFromFeed, setIsRemovedFromFeed] = useState(false);

    const handleObjectRemove = (): void => {
        onObjectRemove(object.id);
        setIsRemovedFromFeed(true);
    };

    return (
        <Box className={classes.root} style={isRemovedFromFeed ? { opacity: 0.4 } : {}}>
            <img
                src={getPhotoUrl(object.photoUrl, FileSize.SM) || imagePlaceholder}
                alt={'photo'}
                className={classes.photo}
            />
            <Box className={classes.detailsBox}>
                <Box className={classes.titleBox}>
                    <Typography className={classes.title}>
                        {object.title?.substring(0, 20)}
                        {object.title?.length > 20 ? '...' : ''}
                    </Typography>

                    <Box className={classes.actionsContainer}>
                        {reportType === ReportTypeEnum.SharedOnCommunityFeed && !isRemovedFromFeed && (
                            <Button
                                id={`${object.id}_remove_feed`}
                                size={'small'}
                                color={'primary'}
                                className={classes.removeFromFeedBtn}
                                onClick={() => toggleRemoveObjectFeedModal(true)}
                            >
                                Remove from Feed
                            </Button>
                        )}
                        {reportType === ReportTypeEnum.MissingDetails && (
                            <ObjectDetailsProgressBar percentage={Math.round(object.detailsPercent)} />
                        )}

                        <Link to={`${AppRoutes.OBJECT}?id=${object.id}`}>
                            <Button
                                id="view-object-btn" //
                                size={'small'}
                                color={'primary'}
                                className={classes.viewObjectBtn}
                            >
                                View object <ChevronRightIcon className={classes.viewObjectIcon} />
                            </Button>
                        </Link>
                    </Box>
                </Box>

                <Typography className={classes.description}>
                    {object.description?.substring(0, 550)}
                    {object.description?.length > 550 ? '...' : ''}
                </Typography>
            </Box>

            {isRemoveObjectFeedModalOpen && (
                <RemoveFeedObjectModal
                    objectId={object.id}
                    open={isRemoveObjectFeedModalOpen}
                    handleClose={() => toggleRemoveObjectFeedModal(false)}
                    onRemove={handleObjectRemove}
                />
            )}
        </Box>
    );
};
