import { IAction } from "../interfaces";
import { IGlobalState } from "./global.interfaces";

export default (state: IGlobalState, action: IAction): IGlobalState => {
    switch(action.type) {
        case 'SET_UPLOAD_PHOTOS_BATCH_1':
            return {
                ...state,
                uploadPhotosBatch1: action.payload,
            }
        case 'SET_UPLOAD_PHOTOS_BATCH_2':
            return {
                ...state,
                uploadPhotosBatch2: action.payload,
            }
        case 'SET_UPLOAD_PHOTOS_BATCH_3':
            return {
                ...state,
                uploadPhotosBatch3: action.payload,
            }
        case 'SET_SHOULD_RELOAD_BATCH_1':
            return {
                ...state,
                shouldReloadBatch1: action.payload,
            }
        case 'SET_SHOULD_RELOAD_BATCH_2':
            return {
                ...state,
                shouldReloadBatch2: action.payload,
            }
        case 'SET_SHOULD_RELOAD_BATCH_3':
            return {
                ...state,
                shouldReloadBatch3: action.payload,
            }
        case 'SET_JOB_ID':
            return {
                ...state,
                jobId: action.payload,
            }
        default:
            return state;
    }
}
