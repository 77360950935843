import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export const jobCardStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '18%',
        backgroundColor: theme.rootBackground.cardBackground,
        border: '1px solid',
        borderColor: theme.rootBackground.secondaryBorder,
        marginLeft: '1%',
        marginRight: '1%',
        marginBottom: 15,
        textAlign: 'center',
        padding: 20,
    },
    photo: {
        width: 40,
        height: 40,
        borderRadius: 40,
    },
    name: {
        fontSize: 16,
    },
    email: {
        fontSize: 13,
        color: theme.formLabel.main,
        opacity: 0.8,
    },
    info: {
        fontSize: 16,
        fontFamily: 'Myriad Pro Bold',
        marginTop: 7
    },
    divider: {
        width: '85%',
        borderTop: '1px solid',
        borderColor: theme.divider.main,
        margin: '10px auto',
    },
    actionBtn: {
        fontSize: 16
    }
}))
