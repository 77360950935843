import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {Box, ThemeOptions, Typography} from "@material-ui/core";
import {CollectorProfileContext} from "../../../../../../core/context/admin/collector-profile";
import {UtilsContext} from "../../../../../../core/context/utils.context";
import {CollectorsApi} from "../../../../../../core/api/admin";
import Pagination from "@material-ui/lab/Pagination";
import {Loader} from "../../../../../../components";
import {DeletedObjectCard} from "./DeletedObjectCard";

type DeletedObjectsListProps = {
    userId: number;
}

export const DeletedObjectsList: React.FC<DeletedObjectsListProps> = ({userId}) => {

    const classes = useStyles();
    const collectorProfileContext = useContext(CollectorProfileContext);

    const [isLoading, setIsLoading] = useState(true);
    const utilsContext = useContext(UtilsContext);
    useEffect(() => {
        collectorProfileContext.setCurrentPage(1);
        getArtifacts(1);
    }, []);

    const getArtifacts = (page: number): void => {
        CollectorsApi.getCollectorDeletedArtifacts(userId, collectorProfileContext.artifactsPagination.size, page)
            .then((res: any) => {
                collectorProfileContext.setArtifacts(res.data);
                setIsLoading(false);
                window.scrollTo(0, 0);
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar('error');
            })
    }

    const handlePageChange = (page: number): void => {
        collectorProfileContext.setCurrentPage(page);
        getArtifacts(page);
    }

    return (
        <Box className={classes.root}>
            {
                !isLoading &&
                <>
                    {
                        collectorProfileContext.artifacts.map(artifact => (
                            <DeletedObjectCard artifact={artifact} key={artifact.id}/>
                        ))
                    }

                    {
                        collectorProfileContext.artifactsPagination.numberOfPages > 1 &&
                        <Box textAlign="right" width="100%" marginTop={'10px'}>
                            <Pagination
                                count={collectorProfileContext.artifactsPagination.numberOfPages}
                                page={collectorProfileContext.artifactsPagination.page}
                                siblingCount={1}
                                shape="rounded"
                                style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 10}}
                                onChange={(_e: any, value: number) => handlePageChange(value)}
                            />
                        </Box>
                    }

                    {
                        collectorProfileContext?.artifacts?.length === 0 &&
                        <Typography color={'textSecondary'} style={{fontSize: 13}}>
                            This collector has no objects.
                        </Typography>
                    }

                </>
            }
            {
                isLoading &&
                <Box textAlign={'center'} width={'100%'}>
                    <Box margin={'auto'}>
                        <Loader />
                    </Box>
                </Box>
            }
        </Box>
    )
}

const useStyles = makeStyles((_theme: ThemeOptions) => ({
    root: {
        fontSize: 16,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
}))
