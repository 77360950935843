import {AppRoutes} from "../../../core/helpers";
import {INavList} from "./nav-link.interfaces";

export const navLinks: INavList[] = [
    {
        title: 'Our Company',
        id: 0,
        list: [
            {
                id: 0,
                name: 'Our Mission',
                path: AppRoutes.OUR_MISSION
            },
            {
                id: 1,
                name: 'Contact Us',
                path: '',
            },
        ]
    },
    {
        title: 'Privacy',
        id: 1,
        list: [
            {
                id: 0,
                name: 'Privacy Policy',
                path: AppRoutes.PRIVACY_POLICY
            },
            {
                id: 1,
                name: 'Terms and Conditions',
                path: AppRoutes.TERMS_AND_CONDITIONS
            },
        ]
    },
    {
        title: 'Help Center',
        id: 2,
        list: [
            {
                id: 0,
                name: 'Tutorial',
                path: AppRoutes.TUTORIAL
            },
        ]
    },
];
