import NumberFormat from 'react-number-format';
import React from "react";


export const NumberInput: React.FC = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    const MAX_VAL = 9999999999;

    const withValueCap = (inputObj: any): any => {
        const { value } = inputObj;
        if (value <= MAX_VAL) return inputObj;
    };

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            allowNegative={false}
            thousandSeparator={','}
            type={'tel'}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            isAllowed={withValueCap}
            isNumericString
            decimalScale={2}
        />
    );
}

export const YearInput: React.FC = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    const MAX_VAL = 9999999999;

    const withValueCap = (inputObj: any): any => {
        const { value } = inputObj;
        if (value <= MAX_VAL) return inputObj;
    };

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            allowNegative={false}
            type={'tel'}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            isAllowed={withValueCap}
            isNumericString
            decimalScale={2}
        />
    );
}
