import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export const objectsListStyles = makeStyles((theme: Theme) => ({
    title: {
        fontSize: 13,
        color: theme.formLabel.main,
        marginBottom: 5,
    },
    objectsList: {
        padding: 10,
        borderColor: theme.rootBackground.secondaryBorder,
        border: '1px solid',
        backgroundColor: theme.rootBackground.cardBackground,
        borderRadius: 2,
    }
}));

export const objectStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.rootBackground.secondary,
        marginBottom: 12,
        display: 'flex',
        height: 120,
        '&:last-child': {
            marginBottom: 0,
        },
        ["@media (max-width:855px)"]: { display: 'block', height: 'unset', textAlign: 'center', paddingTop: 10 },
    },
    photo: {
        width: 120,
        height: 120,
        minWidth: 120,
        minHeight: 120,
        objectFit: 'cover',
    },
    detailsBox: {
        padding: 10,
        width: '100%',
    },
    titleBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ["@media (max-width:855px)"]: { display: 'block' },
    },
    title: {
        fontSize: 16,
    },
    description: {
        fontSize: 12,
        color: theme.formLabel.main,
        lineHeight: 1.4,
    },
    actionsContainer: {
        display: 'flex',
        alignItems: 'center',
        ["@media (max-width:855px)"]: { display: 'block', marginTop: 10 },
    },
    viewObjectBtn: {
        fontSize: 14,
    },
    viewObjectIcon: {
        ["@media (max-width:855px)"]: { display: 'none'},
    },
    removeFromFeedBtn: {
        fontSize: 14,
        color: theme.palette.error.main,
        marginRight: 10,
    }
}));
