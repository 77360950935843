import React, {useContext} from "react";
import {useRouteMatch} from "react-router-dom";
import {Link} from "react-router-dom";

import {ListItem, Typography} from "@material-ui/core";

import {UserContext} from "core/context/user";
import {generalStyles} from "../styles/styles";
import {INavLink} from "../../_helpers/nav-link.interfaces";
import {UserRoles} from "../../../../core/enums";

type Props = {
    navLink: INavLink;
    onClick?: () => void
}

const activeLinkColor = (isLightTheme: boolean): string => {
    return isLightTheme ? 'black' : '#FFFFFF';
}

const inactiveLinkColor = (isLightTheme: boolean): string => {
    return isLightTheme ? '#6c6c6c' : '#B2B2B2';
}

export const NavLink: React.FC<Props> = ({navLink, onClick}) => {

    const userContext = useContext(UserContext);
    const classes = generalStyles();

    const isHelperUser = userContext.user?.role === UserRoles.ARCHIVIST || userContext.user?.role === UserRoles.CURATOR;

    const isRouteMatch = (path: string, exact: boolean): any => {
        return useRouteMatch({
            path, exact
        });
    }

    return (
        <Link to={navLink.path}
            onClick={() => {onClick ? onClick() : {}}}
            style={
                navLink.onAuthPages || isRouteMatch(navLink.path, true) || isHelperUser
                    ? {color: activeLinkColor(userContext.lightTheme)}
                    : {color: inactiveLinkColor(userContext.lightTheme)}
            }
        >
            <ListItem className={classes.navItem}>
                <Typography variant="h6" className={classes.navLink}>
                    {navLink.name}
                </Typography>
            </ListItem>
        </Link>
    )
}
