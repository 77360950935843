import React, { useContext, useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Switch, Typography } from '@material-ui/core';

import { FeedbackRequestsListingContext } from 'core/context/feedback-requests/listing';
import { UserContext } from 'core/context/user';
import { FeedbackRequestsApi } from 'core/api/feedback-requests/feedback-requests.api';

import { Loader } from 'components/_others';
import { FeedbackRequestsList } from './components/FeedbackRequestsList';
import { Searchbox } from 'components/_dashboard/Searchbox/Searchbox';
import { useHistory } from 'react-router-dom';
import { getCurrentPageFromUrl } from '../../../../../core/helpers/utils';
import { AppRoutes } from '../../../../../core/helpers';
import { UtilsContext } from '../../../../../core/context/utils.context';

export const FeedbackRequestsListing: React.FC = () => {
    const history = useHistory();

    const feedbackRequestsListingContext = useContext(FeedbackRequestsListingContext);
    const userContext = useContext(UserContext);

    const utilsContext = useContext(UtilsContext);
    const [searchBy, setSearchBy] = useState('');
    const [isCompleted, toggleIsCompleted] = useState(
        new URLSearchParams(window.location.search).get('isCompleted') === 'true',
    );
    const [ratingFilter, toggleRatingFilter] = useState<boolean | null>(
        new URLSearchParams(window.location.search).get('isRated') === 'true'
            ? true
            : new URLSearchParams(window.location.search).get('isRated') === 'false'
            ? false
            : null,
    );

    useEffect(() => {
        const page = getCurrentPageFromUrl();

        const params = new URLSearchParams(window.location.search);
        const isCompletedParam = params.get('isCompleted');

        if (isCompletedParam === 'true') {
            toggleIsCompleted(true);
        } else {
            toggleIsCompleted(false);
        }

        feedbackRequestsListingContext.setCurrentPage(page);
    }, []);

    useEffect(() => {
        getListing(0, searchBy, ratingFilter);
    }, [ratingFilter, isCompleted]);

    useEffect(() => {
        history.push(
            `${AppRoutes.FEEDBACK_REQUESTS}?page=${feedbackRequestsListingContext.pagination.page}&isCompleted=${isCompleted}&isRated=${ratingFilter}`,
        );
    }, [ratingFilter]);

    const handlePageChange = (page: number, searchBy: string): void => {
        history.push(`${AppRoutes.FEEDBACK_REQUESTS}?page=${page}&isCompleted=${isCompleted}&isRated=${ratingFilter}`);
        feedbackRequestsListingContext.setCurrentPage(page);
        getListing(page, searchBy);
    };

    const getListing = (page: number, searchBy: string, ratingFilter: boolean | null = null): void => {
        feedbackRequestsListingContext.setLoading(true);

        FeedbackRequestsApi.getListing(
            feedbackRequestsListingContext.pagination.size,
            page,
            searchBy ? searchBy : '',
            isCompleted,
            isCompleted ? ratingFilter : null,
            userContext.user?.role,
        )
            .then((res: any) => {
                feedbackRequestsListingContext.setData(res.data);
                window.scrollTo(0, 0);
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error');
            });
    };

    return (
        <Box>
            {!feedbackRequestsListingContext.isLoading && (
                <>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Box>
                            <Typography variant="h5" color="textPrimary">
                                Feedback Request
                            </Typography>
                            <Typography variant="h5" color="textSecondary" style={{ fontSize: 16 }}>
                                {feedbackRequestsListingContext.statistics.totalRequests} requests
                            </Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isCompleted}
                                        onChange={() => {
                                            // eslint-disable-next-line max-len
                                            history.push(
                                                `${AppRoutes.FEEDBACK_REQUESTS}?page=${
                                                    feedbackRequestsListingContext.pagination.page
                                                }&isCompleted=${!isCompleted}&isRated=${ratingFilter}`,
                                            );
                                            toggleIsCompleted(!isCompleted);
                                        }}
                                        color={'primary'}
                                        name="change-is-completed"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                }
                                color={'textPrimary'}
                                label={!isCompleted ? 'Incomplete' : 'Completed'}
                            />
                            <Searchbox
                                searchBy={searchBy}
                                setSearchBy={(val: string) => setSearchBy(val)}
                                onClear={() => {
                                    setSearchBy('');
                                    handlePageChange(1, '');
                                }}
                                onSearch={(val: string) =>
                                    val && val.length > 3 ? handlePageChange(1, val) : handlePageChange(1, '')
                                }
                            />
                        </Box>
                    </Box>

                    <Box marginTop={'15px'}>
                        {isCompleted && (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedB"
                                            color="secondary"
                                            checked={ratingFilter === null || ratingFilter}
                                            onChange={() =>
                                                toggleRatingFilter(
                                                    ratingFilter === null ? false : !ratingFilter ? null : true,
                                                )
                                            }
                                        />
                                    }
                                    label={
                                        <Typography className="checkbox-label" color="textPrimary">
                                            Show feedback with rating
                                        </Typography>
                                    }
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedB"
                                            color="secondary"
                                            checked={ratingFilter === null || !ratingFilter}
                                            onChange={() =>
                                                toggleRatingFilter(
                                                    ratingFilter === null ? true : !ratingFilter ? true : null,
                                                )
                                            }
                                        />
                                    }
                                    label={
                                        <Typography className="checkbox-label" color="textPrimary">
                                            Show feedback with NO rating
                                        </Typography>
                                    }
                                />
                            </>
                        )}

                        <FeedbackRequestsList
                            data={feedbackRequestsListingContext.data}
                            pagination={{
                                numberOfPages: feedbackRequestsListingContext.pagination.numberOfPages,
                                currentPage: feedbackRequestsListingContext.pagination.page,
                            }}
                            handlePageChange={(page: number) => handlePageChange(page, searchBy)}
                        />
                    </Box>

                    {feedbackRequestsListingContext.displayEmptyPage && (
                        <Typography variant="h5" color="textSecondary" style={{ fontSize: 16 }}>
                            This list is empty.
                        </Typography>
                    )}
                </>
            )}

            {feedbackRequestsListingContext.isLoading && <Loader />}
        </Box>
    );
};
