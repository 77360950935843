import React, {useContext} from "react";
import {Box, Button, Tooltip, Typography} from "@material-ui/core";

import InfoIcon from '@material-ui/icons/Info';
import ReplyIcon from '@material-ui/icons/Reply';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import { ButtonGrow } from "components/_others/ButtonGrow";
import { ObjectDetailsContext } from "core/context/consumer/objectDetails";
import { computeMissingFields } from "scenes/consumer/objectDetails/helpers/missing-info-helper";
import {ObjectDetailsProgressBar} from "../../../_components/ObjectDetailsProgressBar";
import {UserContext} from "../../../../../core/context/user";
import {UserRoles} from "../../../../../core/enums";

type ActionButtonsProps = {
    noOfRecommendedExperts: number;
    detailsPercent: number;
    isOnEditMode: boolean;
    paymentFreePass: boolean;
    isSharedToPublicFeed: boolean;
    onToggleEditMode: (newState: boolean) => void;
    onSaveObject: () => void;
};

const assessmentInfoTooltipText = `
    You can use the "Assessment" button to request an expert opinion for your object
`;

export const ActionButtons: React.FC<ActionButtonsProps> = (
    {isOnEditMode, onToggleEditMode, onSaveObject, noOfRecommendedExperts, paymentFreePass,
        isSharedToPublicFeed, detailsPercent}
) => {

    const objectDetailsContext = useContext(ObjectDetailsContext);
    const userContext = useContext(UserContext)

    const onRequestAssessment = (): void => {
        if(computeMissingFields(objectDetailsContext.artifact).length !== 0) {
            objectDetailsContext.modals.toggleMissingInfoModal();
        } else {
            objectDetailsContext.modals.toggleRequestAssessmentModal();
        }
    }

    const hasPendingAsessments = (): boolean => {
        if(!objectDetailsContext.artifact.feedbackRequests) {
            return false;
        }
        return objectDetailsContext.artifact.feedbackRequests.findIndex(f => !f.isCompleted) > -1;
    }

    return (
        <Box className="action-buttons-container" style={{alignItems: 'flex-start'}}>
            {
                !isOnEditMode && !hasPendingAsessments() &&
                    <>
                        <Box style={{marginTop: 5, display: 'flex', marginRight: 5}}>
                            <Box textAlign={'center'} style={{maxWidth: 190}}>
                                <ObjectDetailsProgressBar percentage={detailsPercent} />
                                <span style={{fontSize: 12, opacity: 0.8}}>Details Completed</span>
                            </Box>
                        </Box>

                        <Box style={{maxWidth: 165}}>
                            {
                                userContext.user?.role !== UserRoles.ARCHIVIST &&
                                <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Button
                                        id="request-assessment-btn"
                                        color="primary"
                                        variant="contained"
                                        onClick={() => onRequestAssessment()}
                                        style={{marginRight: 5}}
                                    >
                                        {paymentFreePass ? '' : '$9'} Assessment
                                    </Button>

                                    <Tooltip
                                        arrow
                                        title={assessmentInfoTooltipText}
                                        className="assessment-info-tooltip"
                                    >
                                        <InfoIcon className="assessment-info-icon" />
                                    </Tooltip>
                                </Box>
                            }

                            {
                                noOfRecommendedExperts > 0 && userContext.user?.role !== UserRoles.ARCHIVIST &&
                                <Typography color={'textSecondary'} style={{whiteSpace: 'nowrap', marginTop: 7}}>
                                    View the recommended{' '}
                                    <span style={
                                        {fontSize: 18, color: '#029BFE', textDecoration: 'underline', cursor: 'pointer'}
                                    }
                                          onClick={() => objectDetailsContext.modals.toggleRecommendedExpertsModal()}
                                    >
                                    <b>{noOfRecommendedExperts}</b>
                                </span>
                                    {noOfRecommendedExperts === 1 ? ' expert' : ' experts'}
                                </Typography>
                            }
                            {
                                isSharedToPublicFeed &&
                                <Typography
                                    color={'secondary'}
                                    style={{fontSize: 13, minWidth: 220, whiteSpace: 'nowrap'}}
                                >
                                    Your artifact is shared to the Community Feed.
                                </Typography>
                            }
                        </Box>
                    </>
            }

            <div className="small-action-buttons-container">
                {
                    !isOnEditMode &&
                        <>
                            {
                                userContext.user?.role !== UserRoles.ARCHIVIST &&
                                <ButtonGrow
                                    icon={<ReplyIcon className="share-icon" />}
                                    text={'Share'}
                                    id={'share-object-btn'}
                                    onClick={() => objectDetailsContext.modals.toggleShareObjectOptionsModal()}
                                />
                            }

                            {
                                (userContext.user?.role === UserRoles.COLLECTOR || userContext.user?.role === UserRoles.CURATOR ||
                                (
                                    (
                                        userContext.user?.role === UserRoles.ARCHIVIST
                                    ) && userContext.user.id === objectDetailsContext.artifact.createdBy
                                )) && <ButtonGrow
                                    icon={<EditIcon className="edit-icon" />}
                                    text={'Edit'}
                                    id={'edit-object-btn'}
                                    onClick={() => onToggleEditMode(!isOnEditMode)}
                                />
                            }

                            {
                                (userContext.user?.role === UserRoles.COLLECTOR || userContext.user?.role === UserRoles.CURATOR ||
                                (
                                    (
                                        userContext.user?.role === UserRoles.ARCHIVIST
                                    ) && userContext.user.id === objectDetailsContext.artifact.createdBy
                                )) && !hasPendingAsessments() && <ButtonGrow
                                    icon={<DeleteIcon className="delete-icon" />}
                                    text={'Delete'}
                                    id={'delete-object-btn'}
                                    onClick={() => objectDetailsContext.modals.toggleConfirmDeleteModal()}
                                />
                            }
                        </>
                }
                {
                    isOnEditMode &&
                        <>
                            <ButtonGrow
                                icon={<CloseIcon className="close-icon" />}
                                text={'Cancel'}
                                id={'cancel-edit-object-btn'}
                                onClick={() => onToggleEditMode(!isOnEditMode)}
                            />
                            <Button
                                color={'primary'}
                                id={'save-new-object-btn'}
                                style={{height: 28}}
                                variant="contained"
                                onClick={() => onSaveObject()}
                            >
                                <SaveIcon className="share-icon" style={{width: 19, height: 19}}/>
                                <span>&nbsp;Save</span>
                            </Button>
                        </>
                }
            </div>
        </Box>
    )
}
