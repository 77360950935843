import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import 'scenes/auth/styles/auth.less';

import { Box, Container, Grid, Typography } from '@material-ui/core';
import { UserContext } from 'core/context/user';

import { AppRoutes } from 'core/helpers/app-routes';
import { RegisterApi, UserApi } from 'core/api/user';
import { UserRoles } from 'core/enums';
import { AfterRegisterAction } from '../../managers/enums';

import { PhotoArea } from 'scenes/auth/components/PhotoArea';
import { PageTitle } from 'scenes/auth/components/PageTitle';
import { SwitchAuthView } from 'scenes/auth/components/SwitchAuthView';
import { CreateAccountForm } from './CreateAccountForm';
import { ConfirmEmailModal } from 'scenes/auth/components/ConfirmationPin/ConfirmEmailModal';
import { ChooseRole } from '../../components/ChooseRole';
import { ExpertiseModal } from '../../components/ExpertiseModal';
import { ReferralModal } from '../../components/ReferralModal';
import { RegisterReasonModal } from '../../components/RegisterReasonModal';
import { ExternalAuth } from '../../components/ExternalAuth';

import { getAccountRoleFromURLSearchParams } from '../../managers/utils.manager';
import { getAuthError } from '../../managers/errors.manager';
import {
    askForExpertise,
    askForReferral,
    getLoginPayload,
    getRegisterPayload,
    manageRegister,
    confirmEmailModalMessage,
    initialRegisterData,
    waitForAccountConfirmationMessage,
} from '../../managers/business-rules.manager';
import { UtilsContext } from 'core/context/utils.context';
import { CustomSnackbar } from 'components';

const PAGE_TITLE = 'Welcome!';
const PAGE_SUBTITLE = 'Catalog, identify, and assess your art and collectibles all in one place.';

export const CreateAccount: React.FC = () => {
    const history = useHistory();
    const userContext = useContext(UserContext);

    const utilsContext = useContext(UtilsContext);
    const [accountRole, setAccountRole] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [registerData, setRegisterData] = useState(initialRegisterData);
    const [displayCEM, toggleCEM] = useState(false);

    useEffect(() => {
        userContext.onLogout();
        setAccountRole(getAccountRoleFromURLSearchParams());
    }, []);

    useEffect(() => {
        if (error) {
            utilsContext.addSnackbar('error', error);
        }
    }, [error]);

    const manageRegisterIntent = (userData: any, isExternalRegister = false): void => {
        if (!accountRole) {
            return;
        }

        setIsLoading(true);
        setError('');

        const registerPayload = getRegisterPayload(isExternalRegister, userData);
        setRegisterData({ ...userData, shouldAskForExpertise: false, shouldAskForReferral: false });

        if (askForExpertise(isExternalRegister, accountRole)) {
            setRegisterData({ ...userData, shouldAskForExpertise: true, shouldAskForReferral: false });
            return;
        }

        if (askForReferral(isExternalRegister, accountRole)) {
            setRegisterData({ ...userData, shouldAskForExpertise: false, shouldAskForReferral: true });
            return;
        }

        onRegister(registerPayload, isExternalRegister);
    };

    const onRegister = (payload: any, external: boolean): void => {
        if (!accountRole) {
            return;
        }

        RegisterApi.register(accountRole || UserRoles.COLLECTOR, payload)
            .then(() => {
                setIsLoading(false);
                const afterRegisterAction = manageRegister(external, accountRole);

                if (afterRegisterAction === AfterRegisterAction.DisplayConfirmEmailModal) {
                    manageCEM(payload.email, payload.password);
                    return;
                }

                if (afterRegisterAction === AfterRegisterAction.DisplayWaitForActivationMessage) {
                    utilsContext.addSnackbar('success', waitForAccountConfirmationMessage);
                    return;
                }

                if (afterRegisterAction === AfterRegisterAction.ContinueToLogin) {
                    onLogin(getLoginPayload(payload));
                }
            })
            .catch((error: any) => {
                manageHttpError(error, payload.email);
            });
    };

    const onLogin = (credentials: any): void => {
        UserApi.login(credentials)
            .then((res: any) => {
                setIsLoading(false);
                userContext.setUser(res.data);
                setRegisterData({ ...registerData, shouldAskForRegisterReason: true });
            })
            .catch((error: any) => {
                manageHttpError(error);
            });
    };

    const runAfterEmailConfirmation = (): void => {
        if (!accountRole) {
            return;
        }

        onLogin({ email: registerData.email, password: registerData.password });

        toggleCEM(false);
        utilsContext.addSnackbar('success', waitForAccountConfirmationMessage);
    };

    const manageHttpError = (error: any, email = ''): void => {
        console.error(error);
        setIsLoading(false);

        const errorData = error?.response?.data;
        if (errorData?.message === 'UserNotConfirmed') {
            if (email !== '') {
                void UserApi.resendRegisterConfirmationEmail(email).then(() => {
                    toggleCEM(true);
                });
            } else {
                toggleCEM(true);
            }
            return;
        }
        setError(getAuthError(errorData?.message || null));
    };

    const manageCEM = (email: string, password: string): void => {
        setRegisterData({ ...registerData, email, password });
        toggleCEM(true);
    };

    return (
        <Grid container spacing={0}>
            <PhotoArea />

            <Grid item xs={12} sm={12} md={7} lg={6}>
                <Container style={{ textAlign: 'center' }}>
                    <Box className="auth-container create-account-container">
                        <PageTitle title={PAGE_TITLE} subtitle={PAGE_SUBTITLE} />

                        {accountRole !== null && (
                            <Box className="p-content-container" style={{ paddingTop: 120 }}>
                                <CreateAccountForm
                                    isLoading={isLoading}
                                    role={accountRole}
                                    toggleAcceptTerms={(value: boolean) =>
                                        setRegisterData({ ...registerData, acceptTerms: value })
                                    }
                                    onRegisterUser={(userData: any) => manageRegisterIntent(userData)}
                                />

                                {error && (
                                    <Typography className="error-message" color="error">
                                        {error}
                                    </Typography>
                                )}

                                <ExternalAuth
                                    acceptTerms={registerData.acceptTerms}
                                    onSuccess={(data) => manageRegisterIntent(data, true)}
                                />

                                <SwitchAuthView
                                    text="Already have an account?"
                                    btnText="Sign In"
                                    redirectUrl={AppRoutes.SIGN_IN}
                                />
                            </Box>
                        )}

                        {accountRole === null && (
                            <Box className="p-content-container" marginTop={'40px'}>
                                <ChooseRole onSelect={(role: string) => setAccountRole(role)} />
                            </Box>
                        )}
                    </Box>
                </Container>
            </Grid>

            <ConfirmEmailModal
                credentials={{ email: registerData.email, password: registerData.password }}
                message={confirmEmailModalMessage}
                open={displayCEM}
                handleClose={() => toggleCEM(false)}
                onSuccess={() => runAfterEmailConfirmation()}
            />

            <ExpertiseModal
                open={registerData.shouldAskForExpertise}
                handleClose={() => setRegisterData({ ...registerData, shouldAskForExpertise: false })}
                onConfirm={(expertise: string) => {
                    const payload = registerData;
                    payload['expertise'] = expertise;
                    onRegister(payload, true);
                    setRegisterData({ ...registerData, shouldAskForExpertise: false });
                }}
            />

            <ReferralModal
                open={registerData.shouldAskForReferral}
                handleClose={() => setRegisterData({ ...registerData, shouldAskForReferral: false })}
                onConfirm={(reference: string) => {
                    const payload = registerData;
                    payload['reference'] = reference;
                    onRegister(payload, true);
                    setRegisterData({ ...registerData, shouldAskForReferral: false });
                }}
            />

            <RegisterReasonModal
                open={registerData.shouldAskForRegisterReason}
                handleClose={() => {
                    setRegisterData({ ...registerData, shouldAskForRegisterReason: false });
                    history.push(AppRoutes.TUTORIAL);
                }}
            />

            <CustomSnackbar
                open={utilsContext.snackbar.text !== ''}
                handleClose={() => utilsContext.closeSnackbar()}
                severity={utilsContext.snackbar.severity}
                message={utilsContext.snackbar.text}
            />
        </Grid>
    );
};
