import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Tooltip, Card, Button, Box } from '@material-ui/core';

import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ReplyIcon from '@material-ui/icons/Reply';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import imagePlaceholder from 'assets/placeholders/artifact-placeholder.png';

import { AppRoutes } from 'core/helpers/app-routes';
import { getPhotoUrl } from 'core/helpers/file-size.helper';
import { FileSize, ShareArtifactOptions, UserRoles } from 'core/enums';
import { getEstimatedDisplayValue } from 'core/helpers/artifact-value.helper';
import { ShareObjectToPersonsModal } from 'components/_consumer/share-object/ShareObjectToPersonsModal';
import { UserContext } from 'core/context/user';
import { SelectShareOptionModal } from '../../_consumer/share-object/SelectShareOptionModal';
import { ShareObjectToPublicFeedModal } from '../../_consumer/share-object/ShareObjectToPublicFeedModal';
import { IObjectCard } from 'types';

type ObjectCardProps = {
    artifact: IObjectCard;
    onDelete: () => void;
};

export const ObjectCard: React.FC<ObjectCardProps> = ({ artifact, onDelete }) => {
    const history = useHistory();
    const userContext = useContext(UserContext);

    const [displayShareOptionsModal, toggleShareOptionsModal] = useState(false);
    const [displayShareObjectModal, toggleShareObjectModal] = useState(false);
    const [displayShareObjectToFeedModal, toggleShareObjectToFeedModal] = useState(false);

    const role = userContext.user?.role;
    const canEdit = role === UserRoles.COLLECTOR || role === UserRoles.CURATOR;
    const canDelete =
        role === UserRoles.COLLECTOR || role === UserRoles.CURATOR ? true : artifact.createdBy === userContext.user?.id;

    const goToObjectPage = (): void => {
        history.push({
            pathname: AppRoutes.OBJECT,
            search: `?id=${artifact.id}`,
        });
    };

    return (
        <div className={'root card-link'}>
            <Card
                className="root object-card-root"
                id={`${artifact.id}`}
                style={{ backgroundColor: userContext.lightTheme ? '#EFEFEF' : '#2B2B33' }}
            >
                <CardActionArea>
                    <Link to={`${AppRoutes.OBJECT}?id=${artifact.id}`}>
                        <CardMedia
                            className="media"
                            image={getPhotoUrl(artifact.photoUrl, FileSize.SM) || imagePlaceholder}
                            style={!getPhotoUrl(artifact.photoUrl, FileSize.SM) ? { opacity: 0.4 } : {}}
                            title={artifact.title}
                        />
                    </Link>

                    <Box className="object-actions">
                        {artifact.displayShareButtonOnCard && (
                            <Button
                                id="share-btn"
                                variant="contained"
                                size={'small'}
                                className="action action-share"
                                style={artifact.displayDeleteButtonOnCard ? { right: 85 } : { right: 50 }}
                                onClick={() => toggleShareOptionsModal(true)}
                            >
                                <ReplyIcon />
                            </Button>
                        )}
                        {artifact.displayEditButtonOnCard && canEdit && (
                            <Button
                                id="edit-btn"
                                variant="contained"
                                size={'small'}
                                className="action action-edit"
                                style={artifact.displayDeleteButtonOnCard ? { right: 50 } : { right: 15 }}
                                onClick={() => goToObjectPage()}
                            >
                                <EditIcon />
                            </Button>
                        )}
                        {artifact.displayDeleteButtonOnCard && canDelete && (
                            <Button
                                id="delete-btn"
                                variant="contained"
                                size={'small'}
                                className="action action-delete"
                                onClick={() => onDelete()}
                            >
                                <DeleteIcon />
                            </Button>
                        )}
                    </Box>
                    <CardContent onClick={() => goToObjectPage()}>
                        <Tooltip arrow title={artifact.title} className="full-artifact-name-tooltip">
                            <Typography component="h2" className="card-title">
                                {artifact.title}
                            </Typography>
                        </Tooltip>
                        <Typography variant="body2" color="textSecondary" component="p">
                            <span className="label">Estimated Value:</span>
                            <span className="value" style={{ display: 'inline-flex', alignItems: 'center' }}>
                                {getEstimatedDisplayValue(
                                    artifact.exactValue,
                                    artifact.minimumValue,
                                    artifact.maximumValue,
                                )}
                                {artifact.feedback !== null &&
                                    artifact.exactValue === artifact.feedback.exactValue &&
                                    artifact.minimumValue === artifact.feedback.minimumValue &&
                                    artifact.maximumValue === artifact.feedback.maximumValue && (
                                        <>
                                            <Tooltip
                                                arrow
                                                title={'Expert feedback'}
                                                className="full-artifact-name-tooltip"
                                            >
                                                <CheckCircleIcon
                                                    style={{ width: 16, height: 16, marginLeft: 5, color: 'green' }}
                                                />
                                            </Tooltip>
                                        </>
                                    )}
                            </span>
                        </Typography>
                    </CardContent>
                </CardActionArea>

                {displayShareObjectModal && (
                    <ShareObjectToPersonsModal
                        artifactId={artifact.id}
                        title={artifact.title}
                        description={artifact.description}
                        photo={artifact.photoUrl}
                        open={displayShareObjectModal}
                        handleClose={() => toggleShareObjectModal(false)}
                    />
                )}

                {displayShareOptionsModal && (
                    <SelectShareOptionModal
                        artifactId={artifact.id}
                        isSharedToPublicFeed={artifact.isSharedToPublicFeed}
                        // canBeSharedToFeed={artifact.canBeSharedToPublicFeed}
                        canBeSharedToFeed={true}
                        open={displayShareOptionsModal}
                        handleClose={() => toggleShareOptionsModal(false)}
                        onSelect={(type: number) => {
                            if (type === ShareArtifactOptions.People) {
                                toggleShareObjectModal(true);
                            }
                            if (type === ShareArtifactOptions.PublicFeed) {
                                toggleShareObjectToFeedModal(true);
                            }
                            toggleShareOptionsModal(false);
                        }}
                        onRemove={() => {
                            artifact.isSharedToPublicFeed = false;
                        }}
                    />
                )}

                {displayShareObjectToFeedModal && (
                    <ShareObjectToPublicFeedModal
                        artifactId={artifact.id}
                        artifact={{
                            title: artifact.title,
                            description: artifact.description,
                            photo: artifact.photoUrl,
                        }}
                        open={displayShareObjectToFeedModal}
                        handleClose={() => toggleShareObjectToFeedModal(false)}
                        onShareSuccess={() => {
                            artifact.isSharedToPublicFeed = true;
                        }}
                    />
                )}
            </Card>
        </div>
    );
};
