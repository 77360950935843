import React from "react";

import loader from "assets/images/loader.svg";
import {Box} from "@material-ui/core";

export const Loader: React.FC = () => {
    return (
        <Box textAlign={'center'}>
            <img src={loader} alt="loader" />
        </Box>
    )
}
