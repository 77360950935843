import {useContext, useState} from "react";
import {CommunityFeedApi} from "core/api/consumer";
import {UtilsContext} from "core/context/utils.context";

export const useMakeOffer = (): any => {

    const utilsContext = useContext(UtilsContext);

    const [isMakeOfferLoading, setIsLoading] = useState(false);

    const makeOffer = async (objectId: number, amount: string, message: string): Promise<boolean> => {
        setIsLoading(true);

        return await CommunityFeedApi.postCommunityFeedOffer(objectId, {amount, message})
            .then(() => {
                setIsLoading(false);
                utilsContext.addSnackbar('success', 'Thank you for your offer!');
                return true;
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar('error');
                return false;
            })
    }

    return {
        isMakeOfferLoading,
        makeOffer
    }
}
