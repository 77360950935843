import { Box, Button, LinearProgress, Paper, Typography } from '@material-ui/core';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { TextDivider } from 'components/_others/TextDivider';
import React, { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { IPhoto, ObjectDetailsContext } from '../../../../core/context/consumer/objectDetails';
import { UserContext } from '../../../../core/context/user';
import '../styles/upload-photos.less';

type UploadPhotoProps = {
    onUpload: (photos: string[]) => void;
    photos: Array<IPhoto | string>;
};

export const UploadPhoto: React.FC<UploadPhotoProps> = ({ onUpload, photos }) => {
    const [uploading, setUploading] = useState(false);
    const userContext = useContext(UserContext);
    const objectDetailsContext = useContext(ObjectDetailsContext);

    const onDrop = useCallback(
        (acceptedFiles) => {
            uploadFiles(acceptedFiles);
        },
        [photos],
    );

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const uploadFiles = (files: FileList): void => {
        if (files.length + photos.length > 25) {
            alert('We are sorry, but you can upload a maximum of 25 photos to an artifact.');
            return;
        }

        for (const file of Array.from(files)) {
            if (file.size > 40 * 1000000) {
                alert('We are sorry, but your photo size is too large (40MB).');
                return;
            }

            if (
                file.name &&
                (file?.name?.substr(file.name?.length - 4, 4).toLowerCase() === '.exe' ||
                    file?.name?.substr(file.name?.length - 4, 4).toLowerCase() === '.bat')
            ) {
                alert('We are sorry, but we do not accept this file type.');
                return;
            }

            if (
                file.name &&
                file?.name?.substr(file.name?.length - 4, 4).toLowerCase() !== '.jpg' &&
                file?.name?.substr(file.name?.length - 5, 5).toLowerCase() !== '.jpeg' &&
                file?.name?.substr(file.name?.length - 4, 4).toLowerCase() !== '.png'
            ) {
                alert('We are sorry, but we do not accept this file type.');
                return;
            }
        }
        setUploading(true);
        setTimeout(() => {
            const newPhotosArr: string[] = [];
            const maxWidth = 1920;

            Array.from(files).forEach((file) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const image = new Image();
                    image.src = reader.result as string;
                    image.onload = function () {
                        //get width and height of the photo
                        //@ts-expect-error implicit-type-mismatch-width
                        const { width, height } = this;

                        //calculate the width and height it should crop to
                        const ratio = parseFloat((maxWidth / width).toFixed(2));
                        const canvasHeight = Math.min(height, Math.floor(height * ratio));
                        const canvasWidth = Math.min(maxWidth, width);

                        //draw and resize the photo
                        const canvas = document.createElement('canvas');
                        canvas.width = canvasWidth;
                        canvas.height = canvasHeight;
                        const ctx = canvas.getContext('2d');
                        ctx?.drawImage(image, 0, 0, canvasWidth, canvasHeight);
                        const dataUrl = canvas.toDataURL('image/png');
                        canvas.remove();
                        image.remove();

                        //add photo to the processed photos array
                        newPhotosArr.push(dataUrl);
                        if (newPhotosArr.length === files.length) {
                            onUpload(newPhotosArr);
                            setUploading(false);
                        }
                    };
                };
            });
        }, 100);
        objectDetailsContext.modals.toggleDisplayUnsavedPhotosModal(false);
    };

    const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { files } = e.target;
        if (!files?.length) {
            return;
        }
        uploadFiles(files);
    };

    return (
        <Paper
            elevation={0}
            className="upload-photos-paper"
            style={
                userContext.lightTheme
                    ? { backgroundColor: '#F7F7F7', border: '1px solid #bababa' }
                    : { backgroundColor: '#383838' }
            }
        >
            <div {...getRootProps()}>
                <input {...getInputProps()} multiple={true} accept=".png, .jpeg, .jpg" />
                <Box className="dropzone-area">
                    <PhotoLibraryIcon className={'dropzone-area-icon'} />
                </Box>
            </div>

            {uploading && (
                <Box textAlign={'left'}>
                    <Typography className="updating-text">Updating...</Typography>
                    <LinearProgress color="primary" />
                </Box>
            )}

            <Typography className="drag-and-drop-text">Drag & drop your photos</Typography>

            <Box margin={'15px auto'} width={'40%'}>
                <TextDivider text="or" />
            </Box>

            <input
                accept=".png,.jpeg,.jpg"
                style={{ display: 'none' }}
                id="upload-photos"
                type="file"
                multiple={true}
                onChange={handleFileInputChange}
                onClick={(event) => {
                    //@ts-expect-error aaa
                    event.target.value = null;
                }}
            />
            <label htmlFor="upload-photos">
                <Button id="upload-photos-btn" variant="contained" color="primary" component="span">
                    Select a photo from your computer
                </Button>
            </label>
        </Paper>
    );
};
