import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        color: '#707070',
        fontSize: 18,
        cursor: 'pointer'
    },
    icon: {
        fontSize: 15
    }
});

type BackNavigationProps = {
    notify?: () => void;
}

export const BackNavigation: React.FC<BackNavigationProps> = ({notify}) => {

    const classes = useStyles();
    const history = useHistory();

    return (
        <Box>
            <Typography
                variant="h6"
                style={{width: 'fit-content'}}
            >
                <span
                    onClick={() => notify ? notify() : history.goBack()}
                    className={classes.root}
                >
                    <ArrowBackIosIcon
                        className={classes.icon}
                    /> Back
                </span>
            </Typography>
        </Box>
    )
}
