import React from "react";
import {Button} from "@material-ui/core";

type AssignJobBtnProps = {
    text: string;
    onClick: () => void;
}

export const AssignJobBtn: React.FC<AssignJobBtnProps> = ({text, onClick}) => {
    return (
        <Button
            id="assign-job-btn"
            variant="contained"
            size={"small"}
            color={'primary'}
            onClick={onClick}
            style={{padding: '5px 25px'}}
        >
            {text}
        </Button>
    )
}
