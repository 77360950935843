import React from "react";
import {makeStyles} from "@material-ui/styles";

type TextDividerProps = {
    text?: string;
}

export const TextDivider: React.FC<TextDividerProps> = ({text}) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.border} />
            {
                text &&
                    <>
                        <span className={classes.content}>
                            {text}
                        </span>
                        <div className={classes.border} />
                    </>
            }

        </div>
    )
}

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    border: {
        borderBottom: '1px solid #676767',
        width: '100%',
    },
    content: {
        color: '#ACACAC',
        opacity: 0.5,
        padding: '0 10px 0 10px',
        fontSize: 16,
    }
})
