import React from "react";
import {Box, Button, Typography} from "@material-ui/core";
import {jobCardStyles} from "./style";
import {getPhotoUrl} from "../../../core/helpers";
import {FileSize} from "../../../core/enums";
import avatarPlaceholder from "../../../assets/placeholders/avatar-placeholder.png";

type JobCardProps = {
    photoUrl: string;
    name: string;
    email: string;
    info: string;
    actionText: string;
    onAssign: () => void;
}

export const JobCard: React.FC<JobCardProps> = (
    {photoUrl, name, email, info, actionText, onAssign}
) => {

    const classes = jobCardStyles();

    return (
        <Box className={classes.root}>
            <img src={getPhotoUrl(photoUrl, FileSize.SM) || avatarPlaceholder}
                 alt={'photo'}
                 className={classes.photo}
            />
            <Typography color={'textPrimary'} className={classes.name}>
                {name}
            </Typography>
            <Typography className={classes.email}>
                {email}
            </Typography>
            <Typography className={classes.info}>
                {info}
            </Typography>

            <div className={classes.divider} />

            <Button
                id="assign-job-btn"
                variant="text"
                size={"small"}
                color={'primary'}
                className={classes.actionBtn}
                onClick={onAssign}
            >
                {actionText}
            </Button>

        </Box>
    )
}
