import {
    Box,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { CollectorCollectionApi } from 'core/api/consumer/collector-collection.api';
import { IDocument, IPhoto } from 'core/context/consumer/objectDetails';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../core/context/user';
import { SortCollectionsOptions, UserRoles } from '../../../core/enums';
import { NumberInput, YearInput } from '../../_others/NumberInput';
import TypeaheadChipMultiselect from '../../_others/TypeaheadChipMultiselect';
import { Documents } from './components/Documents';
import { Photos } from './components/Photos';
import {
    manageDescriptionError,
    manageExactProductionYearError,
    manageMaxProductionYearError,
    manageMinProductionYearError,
    managePriceRangesError,
    manageTitleError,
    manageYearRangeError,
} from './helpers/errors.manager';
import { IObject, IObjectErrors } from './helpers/object.interface';

const useStyles = makeStyles({
    root: {
        margin: 'auto',
    },
    divider: {
        width: 55,
        height: 1,
        backgroundColor: '#707070',
        marginTop: 15,
        marginBottom: 25,
    },
    labelSm: {
        fontSize: 13,
    },
    labelXs: {
        fontSize: 11,
    },
    focusLabel: {
        color: '#FE6502',
    },
    errorLabel: {
        color: '#B32222',
    },
    inputWrapper: {
        marginTop: '16px',
        marginBottom: '16px',
    },
    input: {
        marginTop: '4px',
    },
});

type ObjectFormProps = {
    values: IObject;
    disableEditFields?: boolean;
    displayErrors?: number;
    onAddNew: boolean;
    photos?: Array<IPhoto | string>;
    documents?: IDocument[];
    setValues: (values: IObject) => void;
    setFormErrors: (values: boolean) => void;
    setPhotos: (photos: Array<string | IPhoto>) => void;
    setDocuments: (document: File[]) => void;
    onPhotoDelete?: (id: number) => void;
    onDocumentDelete?: (id: number) => void;
};

export const ObjectForm: React.FC<ObjectFormProps> = ({
    values,
    disableEditFields,
    setValues,
    setFormErrors,
    displayErrors,
    setPhotos,
    documents,
    setDocuments,
    photos,
    onPhotoDelete,
    onDocumentDelete,
    onAddNew,
}) => {
    const classes = useStyles();
    const userContext = useContext(UserContext);

    const [newPhotosNumber, setNewPhotosNumber] = useState<number | null>(null);
    const [collections, setCollections] = useState<{ id: number; name: string }[]>([]);

    const [onFocus, setOnFocus] = useState('');
    const [errors, setErrors] = useState<IObjectErrors>({
        title: '',
        description: '',
        // photo: '',
        estimatedRanges: '',
        yearRange: '',
        exactProductionYear: '',
        minProductionYear: '',
        maxProductionYear: '',
    });

    useEffect(() => {
        if (userContext.user?.role !== UserRoles.ARCHIVIST) {
            getCollections();
        }
    }, []);

    useEffect(() => {
        if (!displayErrors) {
            return;
        }
        manageErrors(null);
    }, [displayErrors]);

    const getCollections = (): void => {
        CollectorCollectionApi.getCollections(SortCollectionsOptions.Title, true, 1, 500)
            .then((res: any) => {
                setCollections(res.data.data);
            })
            .catch((error: any) => {
                console.error(error.response);
                setCollections([]);
            });
    };

    const manageErrors = (field: string | null = null): void => {
        const err: any = {
            title: field === null || field === 'title' || errors.title ? manageTitleError(values.title) : '',
            description:
                field === null || field === 'description' || errors.description
                    ? manageDescriptionError(values.description)
                    : '',
            estimatedRanges:
                values.estimatedValueOption === 'range'
                    ? managePriceRangesError(values.minimumValue, values.maximumValue)
                    : '',
            yearRange:
                values.productionYearOption === 'range'
                    ? manageYearRangeError(values.lowerProductionYear, values.upperProductionYear)
                    : '',
            exactProductionYear:
                values.productionYearOption === 'exact'
                    ? manageExactProductionYearError(values.exactProductionYear, values.exactYearNotation, false)
                    : '',
            minProductionYear:
                values.productionYearOption === 'range'
                    ? manageMinProductionYearError(
                          values.lowerProductionYear,
                          values.upperProductionYear,
                          values.lowerYearNotation,
                          values.upperYearNotation,
                      )
                    : '',
            maxProductionYear:
                values.productionYearOption === 'range'
                    ? manageMaxProductionYearError(values.upperProductionYear, values.upperYearNotation)
                    : '',
            // photo:
            //     onAddNew && (newPhotosNumber === null || newPhotosNumber <= 0)
            //         ? 'Please upload at least one photo'
            //         : '',
        };

        setErrors({ ...err });
        setFormErrors(
            Object.keys(err).findIndex((key: string) => err[key] !== '') > -1 ||
                !values.title ||
                !values.description
                //  || (onAddNew && (newPhotosNumber === null || newPhotosNumber <= 0)),
        );
    };

    return (
        <>
            {disableEditFields && (
                <Typography className={'error-message'} color={'error'} style={{ marginBottom: '10px' }}>
                    You can only update the photos, documents and collections, as there is an assessment waiting for
                    evaluation. Also, your object must have at least one photo.
                </Typography>
            )}
            <form id="object-form" className={classes.root} noValidate>
                {onAddNew && (
                    <>
                        <InputLabel htmlFor="photos">Photos</InputLabel>
                        <Box display={'flex'} alignItems={'center'} className="input">
                            <InfoIcon style={{ color: '#B2B2B2', width: '15px', height: '15px' }} />
                            <Typography style={{ fontSize: '13px', marginLeft: '5px', color: '#B2B2B2' }}>
                                Add at least one photo to your object board for your records. The more photo details you
                                provide, the more accurate the identification.
                            </Typography>
                        </Box>

                        {/*{errors.photo && (*/}
                        {/*    <Typography color={'error'} style={{ marginTop: '40px', fontSize: 13 }}>*/}
                        {/*        {errors.photo}*/}
                        {/*    </Typography>*/}
                        {/*)}*/}

                        <Photos
                            hasPendingAssessments={disableEditFields}
                            initialPhotos={photos as IPhoto[]}
                            setCurrentPhotos={(photos: any) => {
                                setPhotos(photos);
                                setNewPhotosNumber(photos.length);
                            }}
                            onDelete={(id: number) => {
                                if (onPhotoDelete) {
                                    onPhotoDelete(id);
                                }
                            }}
                            onRemovePhoto={(_id: number) => setNewPhotosNumber((newPhotosNumber || 0) - 1)}
                        />
                    </>
                )}

                <Box className={classes.inputWrapper}>
                    <InputLabel
                        htmlFor="title"
                        className={
                            errors.title !== '' ? classes.errorLabel : onFocus === 'title' ? classes.focusLabel : ''
                        }
                    >
                        Title of this object *
                    </InputLabel>
                    <TextField
                        id="title"
                        className={classes.input}
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        disabled={disableEditFields}
                        helperText={errors.title}
                        error={errors.title !== ''}
                        value={values.title}
                        inputProps={{ maxLength: 200 }}
                        onChange={(e: any) => setValues({ ...values, title: e.target.value })}
                        onFocus={() => setOnFocus('title')}
                        onBlur={() => {
                            setOnFocus('');
                            manageErrors('title');
                        }}
                    />
                </Box>

                <Box className={classes.inputWrapper}>
                    <InputLabel
                        htmlFor="description"
                        className={
                            errors.description !== ''
                                ? classes.errorLabel
                                : onFocus === 'description'
                                ? classes.focusLabel
                                : ''
                        }
                    >
                        Description *
                    </InputLabel>
                    <TextField
                        className={classes.input}
                        multiline
                        rows={3}
                        rowsMax={10}
                        type="text"
                        id="description"
                        variant="outlined"
                        placeholder={
                            'Please share some key details about the object you are listing.' +
                            ' The more information you are able to share the better!'
                        }
                        fullWidth
                        disabled={disableEditFields}
                        helperText={errors.description}
                        error={errors.description !== ''}
                        value={values.description}
                        inputProps={{ maxLength: 5000 }}
                        onChange={(e: any) => setValues({ ...values, description: e.target.value })}
                        onFocus={() => setOnFocus('description')}
                        onBlur={() => {
                            setOnFocus('');
                            manageErrors('description');
                        }}
                    />
                </Box>

                <div className={classes.divider} />

                <InputLabel htmlFor="" color="primary" style={{ marginBottom: 10 }}>
                    Dimensions
                </InputLabel>

                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}>
                        <>
                            <InputLabel
                                htmlFor="height"
                                className={
                                    onFocus === 'height' ? `${classes.focusLabel} ${classes.labelSm}` : classes.labelSm
                                }
                            >
                                Height <span className={classes.labelXs}>(inches)</span>
                            </InputLabel>
                            <TextField
                                className={classes.input}
                                id="height"
                                variant="outlined"
                                size="small"
                                fullWidth
                                disabled={disableEditFields}
                                value={values.height}
                                onChange={(e: any) => setValues({ ...values, height: e.target.value })}
                                onFocus={() => setOnFocus('height')}
                                onBlur={() => setOnFocus('')}
                                InputProps={{
                                    inputComponent: NumberInput,
                                }}
                            />
                        </>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <>
                            <InputLabel
                                htmlFor="width"
                                className={
                                    onFocus === 'width' ? `${classes.focusLabel} ${classes.labelSm}` : classes.labelSm
                                }
                            >
                                Width <span className={classes.labelXs}>(inches)</span>
                            </InputLabel>
                            <TextField
                                className={classes.input}
                                id="width"
                                variant="outlined"
                                size="small"
                                type="number"
                                fullWidth
                                disabled={disableEditFields}
                                value={values.width}
                                inputProps={{ maxLength: 50 }}
                                onChange={(e: any) => setValues({ ...values, width: e.target.value })}
                                onFocus={() => setOnFocus('width')}
                                onBlur={() => setOnFocus('')}
                                InputProps={{
                                    inputComponent: NumberInput,
                                }}
                            />
                        </>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <>
                            <InputLabel
                                htmlFor="depth"
                                className={
                                    onFocus === 'depth' ? `${classes.focusLabel} ${classes.labelSm}` : classes.labelSm
                                }
                            >
                                Depth <span className={classes.labelXs}>(inches)</span>
                            </InputLabel>
                            <TextField
                                className={classes.input}
                                rows={3}
                                id="depth"
                                variant="outlined"
                                size="small"
                                type="number"
                                fullWidth
                                disabled={disableEditFields}
                                value={values.depth}
                                inputProps={{ maxLength: 50 }}
                                onChange={(e: any) => setValues({ ...values, depth: e.target.value })}
                                onFocus={() => setOnFocus('depth')}
                                onBlur={() => setOnFocus('')}
                                InputProps={{
                                    inputComponent: NumberInput,
                                }}
                            />
                        </>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <>
                            <InputLabel
                                htmlFor="weight"
                                className={
                                    onFocus === 'weight' ? `${classes.focusLabel} ${classes.labelSm}` : classes.labelSm
                                }
                            >
                                Weight <span className={classes.labelXs}>(pounds)</span>
                            </InputLabel>
                            <TextField
                                className={classes.input}
                                rows={3}
                                id="weight"
                                variant="outlined"
                                size="small"
                                type="number"
                                fullWidth
                                disabled={disableEditFields}
                                value={values.weight}
                                inputProps={{ maxLength: 50 }}
                                onChange={(e: any) => setValues({ ...values, weight: e.target.value })}
                                onFocus={() => setOnFocus('weight')}
                                onBlur={() => setOnFocus('')}
                                InputProps={{
                                    inputComponent: NumberInput,
                                }}
                            />
                        </>
                    </Grid>
                </Grid>

                <Box className={classes.inputWrapper}>
                    <InputLabel htmlFor="productionYear" className={onFocus === 'year' ? classes.focusLabel : ''}>
                        What is this the approximate age? <span className={classes.labelXs}>(years)</span>
                    </InputLabel>
                    <RadioGroup
                        row
                        aria-label="productionYear"
                        name="productionYear"
                        value={values.productionYearOption}
                        style={{ marginBottom: 5 }}
                        onChange={(e: any) => setValues({ ...values, productionYearOption: e.target.value })}
                    >
                        <FormControlLabel
                            value="exact"
                            control={<Radio disabled={disableEditFields} />}
                            label="Exact Year"
                        />
                        <FormControlLabel
                            value="range"
                            control={<Radio disabled={disableEditFields} />}
                            label="Range"
                        />
                    </RadioGroup>
                </Box>

                {values.productionYearOption === 'exact' && (
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={8} md={2}>
                            <TextField
                                id="exactProductionYear"
                                variant="outlined"
                                size="small"
                                type="number"
                                fullWidth
                                disabled={disableEditFields}
                                helperText={errors.exactProductionYear}
                                error={errors.exactProductionYear !== ''}
                                value={values.exactProductionYear || ''}
                                onChange={(e: any) =>
                                    setValues({
                                        ...values,
                                        exactProductionYear: e.target.value,
                                        lowerProductionYear: null,
                                        upperProductionYear: null,
                                    })
                                }
                                onFocus={() => setOnFocus('exactProductionYear')}
                                onBlur={() => {
                                    setOnFocus('');
                                    manageErrors('exactProductionYear');
                                }}
                                InputProps={{
                                    inputComponent: YearInput,
                                }}
                            />
                        </Grid>

                        <Grid item xs={6} sm={4} md={2}>
                            <Select
                                fullWidth
                                variant="outlined"
                                id="exactYearNotation"
                                value={values.exactYearNotation}
                                disabled={disableEditFields}
                                onChange={(e: any) => {
                                    setValues({ ...values, exactYearNotation: e.target.value });
                                }}
                                onBlur={() => {
                                    setOnFocus('');
                                    manageErrors('exactYearNotation');
                                }}
                            >
                                <MenuItem value={'AD'}>AD</MenuItem>
                                <MenuItem value={'BC'}>BC</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                )}

                {values.productionYearOption === 'range' && (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={3}>
                                <TextField
                                    rows={3}
                                    id="lowerProductionYear"
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    placeholder={'Min year'}
                                    fullWidth
                                    disabled={disableEditFields}
                                    helperText={errors.minProductionYear}
                                    error={errors.minProductionYear !== ''}
                                    value={values.lowerProductionYear || ''}
                                    onChange={(e: any) =>
                                        setValues({
                                            ...values,
                                            lowerProductionYear: e.target.value,
                                            exactProductionYear: null,
                                        })
                                    }
                                    onFocus={() => setOnFocus('lowerProductionYear')}
                                    onBlur={() => {
                                        setOnFocus('');
                                        manageErrors('lowerProductionYear');
                                    }}
                                    InputProps={{
                                        inputComponent: YearInput,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} sm={6} md={3}>
                                <Select
                                    fullWidth
                                    variant="outlined"
                                    id="minYearNotation"
                                    value={values.lowerYearNotation}
                                    disabled={disableEditFields}
                                    onChange={(e: any) => {
                                        setValues({ ...values, lowerYearNotation: e.target.value });
                                    }}
                                    onBlur={() => {
                                        setOnFocus('');
                                        manageErrors('minYearNotation');
                                    }}
                                >
                                    <MenuItem value={'AD'}>AD</MenuItem>
                                    <MenuItem value={'BC'}>BC</MenuItem>
                                </Select>
                            </Grid>

                            <Grid item xs={6} sm={6} md={3}>
                                <TextField
                                    rows={3}
                                    id="upperProductionYear"
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    placeholder={'Max year'}
                                    fullWidth
                                    disabled={disableEditFields}
                                    helperText={errors.maxProductionYear}
                                    error={errors.maxProductionYear !== ''}
                                    value={values.upperProductionYear || ''}
                                    onChange={(e: any) =>
                                        setValues({
                                            ...values,
                                            upperProductionYear: e.target.value,
                                            exactProductionYear: null,
                                        })
                                    }
                                    onFocus={() => setOnFocus('upperProductionYear')}
                                    onBlur={() => {
                                        setOnFocus('');
                                        manageErrors('upperProductionYear');
                                    }}
                                    InputProps={{
                                        inputComponent: YearInput,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} sm={6} md={3}>
                                <Select
                                    fullWidth
                                    variant="outlined"
                                    id="maxYearNotation"
                                    value={values.upperYearNotation}
                                    disabled={disableEditFields}
                                    onChange={(e: any) => {
                                        setValues({ ...values, upperYearNotation: e.target.value });
                                    }}
                                    onBlur={() => {
                                        setOnFocus('');
                                        manageErrors('maxYearNotation');
                                    }}
                                >
                                    <MenuItem value={'AD'}>AD</MenuItem>
                                    <MenuItem value={'BC'}>BC</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </>
                )}

                <Box className={classes.inputWrapper}>
                    <InputLabel htmlFor="material" className={onFocus === 'material' ? classes.focusLabel : ''}>
                        What is the Material?
                    </InputLabel>
                    <TextField
                        className={classes.input}
                        id="material"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        disabled={disableEditFields}
                        value={values.material}
                        inputProps={{ maxLength: 200 }}
                        onChange={(e: any) => setValues({ ...values, material: e.target.value })}
                        onFocus={() => setOnFocus('material')}
                        onBlur={() => setOnFocus('')}
                    />
                </Box>

                <Box className={classes.inputWrapper}>
                    <InputLabel htmlFor="style" className={onFocus === 'style' ? classes.focusLabel : ''}>
                        What is the Style?
                    </InputLabel>
                    <TextField
                        className={classes.input}
                        id="style"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        disabled={disableEditFields}
                        value={values.style}
                        inputProps={{ maxLength: 200 }}
                        onChange={(e: any) => setValues({ ...values, style: e.target.value })}
                        onFocus={() => setOnFocus('style')}
                        onBlur={() => setOnFocus('')}
                    />
                </Box>

                <Box className={classes.inputWrapper}>
                    <InputLabel htmlFor="origin" className={onFocus === 'origin' ? classes.focusLabel : ''}>
                        What is the Origin?
                    </InputLabel>
                    <TextField
                        className={classes.input}
                        id="origin"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        disabled={disableEditFields}
                        value={values.origin}
                        inputProps={{ maxLength: 200 }}
                        onChange={(e: any) => setValues({ ...values, origin: e.target.value })}
                        onFocus={() => setOnFocus('origin')}
                        onBlur={() => setOnFocus('')}
                    />
                </Box>

                <Box className={classes.inputWrapper}>
                    <InputLabel htmlFor="condition" className={onFocus === 'condition' ? classes.focusLabel : ''}>
                        What is the Condition?
                    </InputLabel>
                    <TextField
                        className={classes.input}
                        id="condition"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        disabled={disableEditFields}
                        value={values.condition}
                        inputProps={{ maxLength: 200 }}
                        onChange={(e: any) => setValues({ ...values, condition: e.target.value })}
                        onFocus={() => setOnFocus('condition')}
                        onBlur={() => setOnFocus('')}
                    />
                </Box>

                <Box className={classes.inputWrapper}>
                    <InputLabel htmlFor="artist" className={onFocus === 'artist' ? classes.focusLabel : ''}>
                        Who is the Artist or Maker?
                    </InputLabel>
                    <TextField
                        className={classes.input}
                        id="artist"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        disabled={disableEditFields}
                        value={values.artist}
                        inputProps={{ maxLength: 500 }}
                        onChange={(e: any) => setValues({ ...values, artist: e.target.value })}
                        onFocus={() => setOnFocus('artist')}
                        onBlur={() => setOnFocus('')}
                    />
                </Box>

                <Box className={classes.inputWrapper}>
                    <InputLabel htmlFor="artistBio" className={onFocus === 'artistBio' ? classes.focusLabel : ''}>
                        Artist Bio
                    </InputLabel>
                    <TextField
                        className={classes.input}
                        multiline
                        rows={3}
                        rowsMax={10}
                        type="text"
                        id="artistBio"
                        variant="outlined"
                        fullWidth
                        disabled={disableEditFields}
                        value={values.artistBio}
                        inputProps={{ maxLength: 5000 }}
                        onChange={(e: any) => setValues({ ...values, artistBio: e.target.value })}
                        onFocus={() => setOnFocus('artistBio')}
                        onBlur={() => {
                            setOnFocus('');
                        }}
                    />
                </Box>

                <Box className={classes.inputWrapper}>
                    <InputLabel htmlFor="artistImageUrl">Artist image link:</InputLabel>

                    <TextField
                        className={classes.input}
                        id="artistImageUrl"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        disabled={disableEditFields}
                        inputProps={{ maxLength: 400 }}
                        onFocus={() => setOnFocus('condition')}
                        onBlur={() => setOnFocus('')}
                        value={values.artistImageUrl}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setValues({ ...values, artistImageUrl: e.target.value });
                        }}
                    />
                </Box>

                {collections?.length > 0 && userContext.user?.role !== UserRoles.ARCHIVIST && (
                    <>
                        <div className={classes.divider} style={{ marginBottom: 26, marginTop: 14 }} />

                        <InputLabel
                            htmlFor="collections"
                            className={onFocus === 'collections' ? classes.focusLabel : ''}
                        >
                            Add this object to some collections.
                        </InputLabel>
                        <Box className={classes.input}>
                            <TypeaheadChipMultiselect
                                options={collections}
                                selectedOptions={values.collections}
                                onChange={(e: any) => setValues({ ...values, collections: e })}
                            />
                        </Box>
                    </>
                )}

                <>
                    <div className={classes.divider} style={{ marginTop: collections?.length ? 30 : 14 }} />
                    <InputLabel htmlFor="artist">What is the estimated value? ($)</InputLabel>

                    <>
                        <RadioGroup
                            row
                            aria-label="estimatedValue"
                            name="estimatedValue"
                            value={values.estimatedValueOption}
                            onChange={(e: any) => setValues({ ...values, estimatedValueOption: e.target.value })}
                        >
                            <FormControlLabel
                                value="exact"
                                control={<Radio disabled={disableEditFields} />}
                                label="Exact Value"
                            />
                            <FormControlLabel
                                value="range"
                                control={<Radio disabled={disableEditFields} />}
                                label="Range"
                            />
                        </RadioGroup>
                    </>

                    {values.estimatedValueOption === 'exact' && (
                        <>
                            <TextField
                                id="exactValue"
                                variant="outlined"
                                size="small"
                                type="number"
                                fullWidth
                                disabled={disableEditFields}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    inputComponent: NumberInput,
                                }}
                                value={values.exactValue}
                                onChange={(e: any) =>
                                    setValues({
                                        ...values,
                                        minimumValue: '',
                                        maximumValue: '',
                                        exactValue: e.target.value,
                                    })
                                }
                                onFocus={() => setOnFocus('exactValue')}
                                onBlur={() => setOnFocus('')}
                            />
                        </>
                    )}

                    {values.estimatedValueOption === 'range' && (
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <InputLabel
                                    htmlFor="minimumValue"
                                    className={onFocus === 'minimumValue' ? classes.focusLabel : ''}
                                >
                                    Min
                                </InputLabel>
                                <TextField
                                    id="minimumValue"
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    fullWidth
                                    disabled={disableEditFields}
                                    helperText={errors.estimatedRanges}
                                    error={errors.estimatedRanges !== ''}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        inputComponent: NumberInput,
                                    }}
                                    value={values.minimumValue}
                                    onChange={(e: any) =>
                                        setValues({
                                            ...values,
                                            minimumValue: e.target.value,
                                            exactValue: '',
                                        })
                                    }
                                    onFocus={() => setOnFocus('minimumValue')}
                                    onBlur={() => {
                                        setOnFocus('');
                                        manageErrors('minimumValue');
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel
                                    htmlFor="maximumValue"
                                    className={onFocus === 'maximumValue' ? classes.focusLabel : ''}
                                >
                                    Max
                                </InputLabel>
                                <TextField
                                    id="maximumValue"
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    fullWidth
                                    disabled={disableEditFields}
                                    error={errors.estimatedRanges !== ''}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        inputComponent: NumberInput,
                                    }}
                                    value={values.maximumValue}
                                    onChange={(e: any) =>
                                        setValues({
                                            ...values,
                                            maximumValue: e.target.value,
                                            exactValue: '',
                                        })
                                    }
                                    onFocus={() => setOnFocus('maximumValue')}
                                    onBlur={() => {
                                        setOnFocus('');
                                        manageErrors('maximumValue');
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                </>

                {!onAddNew && (
                    <>
                        <div className={classes.divider} />

                        <InputLabel htmlFor="photos">Photos</InputLabel>
                        <Box display={'flex'} alignItems={'center'}>
                            <InfoIcon style={{ color: '#B2B2B2', width: '15px', height: '15px' }} />
                            <Typography style={{ fontSize: '13px', marginLeft: '5px', color: '#B2B2B2' }}>
                                Add at least one photo to your object board for your records. The more photo details you
                                provide, the more accurate the identification.
                            </Typography>
                        </Box>

                        <Photos
                            hasPendingAssessments={disableEditFields}
                            initialPhotos={photos as IPhoto[]}
                            setCurrentPhotos={(photos: any) => {
                                setPhotos(photos);
                            }}
                            onDelete={(id: number) => (onPhotoDelete ? onPhotoDelete(id) : {})}
                        />
                    </>
                )}

                <div className={classes.divider} />

                <>
                    <InputLabel htmlFor="photos">Documents</InputLabel>
                    <Box display={'flex'} alignItems={'center'}>
                        <InfoIcon style={{ color: '#B2B2B2', width: '15px', height: '15px' }} />
                        <Typography style={{ fontSize: '13px', marginLeft: '5px', color: '#B2B2B2' }}>
                            Add all known documentation you may have for your object.
                            <i> Example: Receipts, condition report, catalog raisonne...</i>
                        </Typography>
                    </Box>

                    <Documents
                        initialDocuments={documents}
                        setCurrentDocuments={(documents: File[]) => setDocuments(documents)}
                        onDelete={(id: number) => (onDocumentDelete ? onDocumentDelete(id) : {})}
                    />
                </>
            </form>
        </>
    );
};
