import React from "react";

import {Button} from "@material-ui/core";
import {makeOfferBtnStyles} from "../../styles/make-offer.style";

type MakeAnOfferButtonProps = {
    onClick: () => void;
}

export const MakeAnOfferButton: React.FC<MakeAnOfferButtonProps> = ({onClick}) => {

    const classes = makeOfferBtnStyles();

    return (
        <Button
            id="cancel-share-btn"
            variant="contained"
            size={"small"}
            className={classes.root}
            onClick={onClick}
        >
            Make an Offer
        </Button>
    )
}
