import { useEffect, useRef } from 'react';

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function useInterval(callback: any, delay: any) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        function tick() {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            savedCallback?.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
