import React, {useContext, useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { modalStyles } from "components/_others/modals/modal-styles";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Fade,
    Modal,
    TextField,
    ThemeOptions,
    Typography
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

import { CommonApi } from "core/api/shared";
import { ExpertsApi } from "core/api/admin";
import { ITaxonomyLabel } from "core/context/admin/taxonomy";

import {UtilsContext} from "../../../../../../core/context/utils.context";

type AddNewLabelsModalProps = {
    expertId: number;
    open: boolean;
    handleClose: () => void;
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
    label: {
        backgroundColor: theme.label.main,
        color: theme.label.text,
        fontSize: 13,
        marginRight: 7,
        padding: '3px 10px',
        borderRadius: 2,
        display: 'inline-block',
        marginTop: 7,
        cursor: 'pointer'
    },
    labelContent: {
        display: 'flex',
        alignItems: 'center',
    },
    divider: {
        width: '85%',
        margin: '7px auto',
        borderTop: '1px solid',
        borderColor: theme.divider.main
    },
    closeIcon: {
        color: theme.label.text,
        cursor: 'pointer',
        width: 15,
        marginLeft: 5
    },
}))

export const AddNewLabelsModal: React.FC<AddNewLabelsModalProps> = ({expertId, open, handleClose}) => {

    const history = useHistory();

    const classes = modalStyles();
    const labelStyles = useStyles();

    const [searchBy, setSearchBy] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [categories, setCategories] = useState<ITaxonomyLabel[]>([]);

    const [selectedLabels, setSelectedLabels] = useState<ITaxonomyLabel[]>([]);
    const utilsContext = useContext(UtilsContext);
    useEffect(() => {
        setSearchBy('');
        setCategories([]);
        setSelectedLabels([]);
    }, [open])

    const handleKeyPress = (e: any): void => {
        if(searchBy.length === 0) {
            setCategories([]);
        }
        if(e.key === 'Enter' && searchBy.length > 3) {
            onSearch();
            e.preventDefault();
        }
    }

    const onSearch = (): void => {
        CommonApi.searchTaxonomy(JSON.stringify(searchBy), 'Category')
            .then((res: any) => {
                setCategories(res.data.filter((l: ITaxonomyLabel) => l.isActive && !l.isDeleted));
            })
            .catch((error: any) => {
                console.error(error.response);
            })
    }

    const onSave = (): void => {
        setIsLoading(true);

        ExpertsApi.assignLabelsToExpert(expertId, selectedLabels.map(l => l.id))
            .then(() => {
                history.go(0);
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error');
            })
    }

    const onLabelSelect = (label: ITaxonomyLabel): void => {
        if(selectedLabels.findIndex(l => l.id === label.id) === -1) {
            const result = selectedLabels;
            result.push(label);
            setSelectedLabels(result.slice());
        }
    }

    const onLabelDelete = (id: number): void => {
        const index = selectedLabels.findIndex(l => l.id === id);
        if(index !== -1) {
            const result = selectedLabels;
            result.splice(index, 1);
            setSelectedLabels(result.slice());
        }
    }

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Box textAlign={'right'}>
                        <CloseIcon cursor={'pointer'} onClick={handleClose} />
                    </Box>

                    <Box>
                        <Typography className={classes.modalTitle}>
                            Add new labels
                        </Typography>
                        <Typography className={classes.modalSubtitle}>
                            In the field below type your area fields and choose from the results.
                        </Typography>
                    </Box>

                    <Box marginTop={'5px'}>
                        <TextField
                            id="search-labels"
                            variant="outlined"
                            size="small"
                            type="text"
                            fullWidth
                            inputProps={{ maxLength: 100 }}
                            value={searchBy}
                            onChange={(e: any) => setSearchBy(e.target.value)}
                            onKeyPress={(e: any) => handleKeyPress(e)}
                        />
                    </Box>

                    <Box>
                        {
                            categories.length > 0 &&
                                <>
                                    <Typography className={classes.modalSubtitle}>
                                        Categories:
                                    </Typography>
                                    {
                                        categories.map((category: any) => (
                                            <span
                                                className={labelStyles.label}
                                                key={category.id}
                                                onClick={() => onLabelSelect(category)}
                                            >
                                                <span className={labelStyles.labelContent}>
                                                    {category.name}
                                                </span>
                                            </span>
                                        ))
                                    }
                                </>
                        }
                    </Box>

                    <Box>
                        {
                            selectedLabels.length > 0 &&
                            <>
                                <div className={classes.divider} />
                                <Typography className={classes.modalSubtitle}>
                                    New selected labels:
                                </Typography>
                                {
                                    selectedLabels.map((label: any) => (
                                        <span
                                            className={labelStyles.label}
                                            style={{backgroundColor: '#FE6502'}}
                                            key={label.id}
                                        >
                                            <span className={labelStyles.labelContent}>
                                                {label.name}
                                                <CloseIcon
                                                    className={labelStyles.closeIcon}
                                                    onClick={() => onLabelDelete(label.id)}
                                                />
                                            </span>
                                        </span>
                                    ))
                                }
                            </>
                        }
                    </Box>

                    <Box textAlign={'right'} marginTop={'60px'}>
                        <Button
                            id="cancel-btn"
                            variant="contained"
                            size={"small"}
                            style={{marginRight: '15px'}}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>

                        <Button
                            id="save-labels-btn"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            className={classes.primaryButton}
                            disabled={!selectedLabels.length}
                            onClick={() => onSave()}
                        >
                            {
                                isLoading && <CircularProgress className="circular-progress sm" />
                            }
                            {
                                !isLoading && <SaveIcon style={{
                                    width: 20, marginRight: 5
                                }} />
                            }
                            Save
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    )
}
