import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Card, CardContent, ThemeOptions, Tooltip, Typography } from '@material-ui/core';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import { EditUserAccessModal } from '../../_dashboard/EditUserAccessModal/EditUserAccessModal';

import { ICollector } from 'core/context/admin/collector-listing';
import { CollectorTypes, FileSize } from 'core/enums';
import { AppRoutes, getPhotoUrl } from 'core/helpers';
import { ConfirmUserEmailModal } from 'components/_dashboard/ConfirmUserEmailModal/ConfirmUserEmailModal';

const useStyles = makeStyles((theme: ThemeOptions) => ({
    root: {
        border: '1px solid',
        borderColor: theme.divider.main,
        borderRadius: 2,
    },
    profileIconPlaceholder: {
        width: 40,
        height: 40,
        color: '#FE6502',
    },
    profilePic: {
        width: 40,
        height: 40,
        borderRadius: 40,
    },
    name: {
        fontSize: 16,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    email: {
        fontSize: 12,
        wordBreak: 'break-all',
    },
    totalObjects: {
        fontSize: 16,
        fontWeight: 'bold',
        marginTop: 7,
    },
    expertReviews: {
        fontSize: 13,
    },
    divider: {
        width: '85%',
        margin: '7px auto',
        borderTop: '1px solid',
        borderColor: theme.divider.main,
    },
    typeLabel: {
        fontSize: 12,
        color: theme.label.text,
        backgroundColor: theme.label.main,
        borderRadius: 2,
        padding: '2px 7px',
    },
    activeIcon: {
        color: '#0F940A',
        width: 22,
    },
    inactiveIcon: {
        color: '#FF0000',
        width: 22,
    },
    activateEmailButton: {
        fontSize: 12,
    },
}));

type CollectorCardProps = {
    collector: ICollector;
};

export const CollectorCard: React.FC<CollectorCardProps> = ({ collector }) => {
    const classes = useStyles();

    const [isEnabled, setIsEnabled] = useState(collector.isActive);
    const [displayManageAccessModal, toggleManageAccessModal] = useState({ display: false, enable: false });
    const [displayConfirmEmailModal, setDisplayConfirmEmailModal] = useState<boolean>(false);
    const [emailConfirmed, setEmailConfirmed] = useState<boolean>(collector.emailConfirmed);

    const handleConfirmEmailClose = (): void => {
        setDisplayConfirmEmailModal(false);
    };

    const handleConfirmEmailOpen = (): void => {
        setDisplayConfirmEmailModal(true);
    };

    const onConfirmEmailSuccess = (): void => {
        setEmailConfirmed(true);
    };

    return (
        <Card className={`${classes.root} root object-card-root collector-card`} id={`${collector.userId}`}>
            <CardContent>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <span
                        className={classes.typeLabel}
                        style={collector.type !== CollectorTypes.AVID ? { visibility: 'hidden' } : {}}
                    >
                        {collector.type}
                    </span>

                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        {isEnabled && (
                            <CheckCircleIcon
                                style={{ cursor: 'pointer' }}
                                className={classes.activeIcon}
                                onClick={() => toggleManageAccessModal({ display: true, enable: false })}
                            />
                        )}
                        {!isEnabled && (
                            <CancelIcon
                                style={{ cursor: 'pointer' }}
                                className={classes.inactiveIcon}
                                onClick={() => toggleManageAccessModal({ display: true, enable: true })}
                            />
                        )}
                    </Box>
                </Box>

                <Box textAlign={'center'} marginTop={'7px'}>
                    {!collector.photoUrl && <AccountCircleIcon className={classes.profileIconPlaceholder} />}
                    {collector.photoUrl && (
                        <img
                            src={getPhotoUrl(collector.photoUrl || '', FileSize.SM) || ''}
                            className={classes.profilePic}
                            alt={collector.firstName}
                        />
                    )}

                    <Tooltip
                        arrow
                        interactive={true}
                        title={`${collector.firstName} ${collector.lastName}`}
                        className="full-artifact-name-tooltip"
                    >
                        <Typography color="textPrimary" className={classes.name}>
                            {collector.firstName} {collector.lastName}
                        </Typography>
                    </Tooltip>

                    <Typography color="textSecondary" className={classes.email}>
                        {collector.email}
                    </Typography>

                    <Typography color="textPrimary" className={classes.totalObjects}>
                        {collector.totalObjects} objects
                    </Typography>
                    <Typography color="secondary" className={classes.expertReviews}>
                        {collector.totalReviewedObjects} objects - expert reviews
                    </Typography>

                    <div className={classes.divider} />

                    <Link to={`${AppRoutes.ADMIN_COLLECTOR_PROFILE}?id=${collector.userId}`} color="primary">
                        <Typography color="primary" style={{ cursor: 'pointer' }}>
                            View Profile
                        </Typography>
                    </Link>

                    {!emailConfirmed && (
                        <Box width={'100%'} display="flex" justifyContent={'space-around'} alignItems="center">
                            <span style={{ fontSize: 12, color: 'red', marginRight: 5 }}>Email not confirmed</span>

                            <Button
                                className={classes.activateEmailButton}
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={handleConfirmEmailOpen}
                            >
                                Activate Email
                            </Button>
                        </Box>
                    )}
                </Box>

                <EditUserAccessModal
                    open={displayManageAccessModal.display}
                    userId={collector.userId}
                    enable={displayManageAccessModal.enable}
                    handleClose={() => toggleManageAccessModal({ display: false, enable: false })}
                    onSuccess={() => setIsEnabled(displayManageAccessModal.enable)}
                />

                <ConfirmUserEmailModal
                    isOpen={displayConfirmEmailModal}
                    userId={collector.userId}
                    onClose={handleConfirmEmailClose}
                    name={`${collector.firstName} ${collector.lastName}`}
                    onSuccess={onConfirmEmailSuccess}
                />
            </CardContent>
        </Card>
    );
};
