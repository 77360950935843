import React from "react";
import {Box, Divider, Typography} from "@material-ui/core";

import {commentsStyles} from "../../styles/comments.styles";

import {ICommunityFeedArtifact} from "../../types";
import {IObjectComment} from "types";
import {useObjectCommentsData} from "scenes/consumer/_hooks";

import {CommentDetails} from "./CommentDetails";
import {AddComment} from "./AddComment";

type CommentsProps = {
    artifact: ICommunityFeedArtifact;
}

export const Comments: React.FC<CommentsProps> = ({artifact}) => {

    const classes = commentsStyles();

    const {
        comments,
        currentPage,
        hasMoreComments,
        getMoreComments,
        addNewComment,
        editComment,
        deleteComment,
        isAllowedToDelete,
        isAllowedToEdit
    } = useObjectCommentsData(artifact.id, 3);

    return (
        <Box>
            {
                comments?.length > 0 &&
                <>
                    <Divider className={classes.divider} />

                    <Box className={classes.commentsContainer}>
                        {
                            comments.map((c: IObjectComment) => (
                                <CommentDetails
                                    key={c.id}
                                    comment={c}
                                    isAllowedToDelete={isAllowedToDelete(artifact.collector.userId, c.createdBy)}
                                    isAllowedToEdit={isAllowedToEdit(c.createdBy)}
                                    onDeleteComment={deleteComment}
                                    onEditComment={editComment}
                                />
                            ))
                        }
                    </Box>
                </>
            }

            {
                artifact.totalCommentsCount > 0 && currentPage === 0 &&
                <Typography
                    className={classes.showMore}
                    onClick={() => getMoreComments()}
                >
                    {artifact.totalCommentsCount} {artifact.totalCommentsCount === 1 ? ' comment' : 'comments'}
                </Typography>
            }

            {
                hasMoreComments &&
                    <Typography
                        className={classes.showMore}
                        onClick={() => getMoreComments()}
                    >
                        Show more comments
                    </Typography>
            }

            <Divider className={classes.divider} />

            <AddComment
                id={artifact.id}
                onAddNewComment={addNewComment}
            />
        </Box>
    )
}


