import {useContext, useState} from "react";
import {ObjectApi} from "core/api/consumer";
import {UtilsContext} from "core/context/utils.context";

export const useRemoveObject = (objectId: number): any => {

    const utilsContext = useContext(UtilsContext);

    const [isLoading, setIsLoading] = useState(false);

    const removeObject = async (): Promise<boolean> => {
        setIsLoading(true);

        return await ObjectApi.deleteObject(objectId)
            .then(() => {
                setIsLoading(false);
                utilsContext.addSnackbar('success', 'Success! Your object was removed.');
                return true;
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar('error');
                return false;
            })
    }

    return {
        isLoading,
        removeObject,
    }
}
