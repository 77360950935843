import React, { useEffect, useState } from "react";
import { Backdrop, Fade, Modal } from "@material-ui/core";
import { modalStyles } from "components/_others/modals/modal-styles";


import { EnterEmail } from "./EnterEmail";
import { EnterNewPassword } from "./EnterNewPassword";
import { ModalHeader } from "../../../../components/_others/modals/ModalHeader";

type ForgetPasswordModalProps = {
    open: boolean;
    forChangePassword?: boolean;
    userEmail?: string;
    handleClose: () => void;
}

enum Views {
    EnterEmail,
    EnterCode,
}

export const ForgotPasswordModal: React.FC<ForgetPasswordModalProps> =  (
    {open, userEmail, forChangePassword, handleClose}
) => {

    const classes = modalStyles();

    const [email, setEmail] = useState('');
    const [view, setView] = useState(Views.EnterEmail);

    useEffect(() => {
        setView(Views.EnterEmail);
    }, []);

    const onEmailSent = (email: string): void => {
        setEmail(email);
        setView(Views.EnterCode);
    }

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <ModalHeader
                        title={forChangePassword ? 'Change Password' : 'Forgot Password?'}
                        handleClose={() => handleClose()}
                    />

                    {
                        view === Views.EnterEmail &&
                        <EnterEmail
                            userEmail={forChangePassword ? userEmail : null}
                            handleClose={handleClose}
                            onEmailSent={(email: string) => onEmailSent(email)}
                        />
                    }

                    {
                        view === Views.EnterCode &&
                            <EnterNewPassword
                                email={email}
                                handleClose={handleClose}
                                onBack={() => setView(Views.EnterEmail)}
                            />
                    }

                </div>
            </Fade>
        </Modal>
    )
}
