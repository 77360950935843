import React, {useContext, useState} from "react";
import {Box, Button, Typography} from "@material-ui/core";
import {IJob} from "../../../types";
import {activeJobStyles} from "./style";
import {Link} from "react-router-dom";
import {UserContext} from "core/context/user";
import {UserRoles} from "core/enums";
import {AppRoutes} from "core/helpers";
import {RemoveJobModal} from "../RemoveJobModal";

type ActiveJobProps = {
    job: IJob;
    jobOwnerId: number;
    collectorId: number;
    role: string;
    jobRole: string;
    isOld: boolean;
    onRemoveJob: () => void;
}

export const ActiveJob: React.FC<ActiveJobProps> = (
    {jobRole, job, jobOwnerId, collectorId, role, isOld, onRemoveJob}
    ) => {

    const classes = activeJobStyles();
    const userContext = useContext(UserContext);

    const [displayRemoveConfirmModal, toggleRemoveConfirmModal] = useState(false);

    const getUserProfileUrl = (): string => {
        if(jobRole === UserRoles.COLLECTOR) {
            return `${AppRoutes.ADMIN_COLLECTOR_PROFILE}?id=${job.userId}`;
        }
        if(jobRole === UserRoles.ARCHIVIST) {
            return `${AppRoutes.ADMIN_ARCHIVIST_PROFILE}?id=${job.userId}`;
        }
        if(jobRole === UserRoles.CURATOR) {
            return `${AppRoutes.ADMIN_CURATOR_PROFILE}?id=${job.userId}`;
        }
        return '';
    }

    return (
        <Box className={classes.root}>

            <Box className={classes.flexRow}>
                <Typography className={classes.text}>
                    <span className={classes.textHighlight}>Name:</span> {job.userName}
                </Typography>
                {
                    userContext.user?.role === UserRoles.ADMIN &&
                        <Box>
                            {
                                !isOld &&
                                <Button
                                    id="remove-job-btn"
                                    variant="text"
                                    size={"small"}
                                    className={classes.removeJob}
                                    onClick={() => toggleRemoveConfirmModal(true)}
                                >
                                    Remove Job
                                </Button>
                            }
                            <Link to={getUserProfileUrl()}>
                                <Button
                                    id="remove-job-btn"
                                    variant="text"
                                    size={"small"}
                                    className={classes.viewCollectorLink}
                                >
                                    View User
                                </Button>
                            </Link>
                        </Box>
                }
            </Box>

            <Typography className={classes.text} color={'textSecondary'}>
                {job.info}
            </Typography>

            {
                displayRemoveConfirmModal &&
                    <RemoveJobModal
                        open={true}
                        userId={jobOwnerId}
                        collectorId={collectorId}
                        role={role}
                        handleClose={() => toggleRemoveConfirmModal(false)}
                        onRemove={() => onRemoveJob()}
                    />
            }
        </Box>
    )
}
