import { Box, Container, Grid, Typography } from '@material-ui/core';
import { CustomSnackbar } from 'components';
import { UserApi } from 'core/api/user';
import { UserContext } from 'core/context/user';
import { UtilsContext } from 'core/context/utils.context';
import { AppRoutes } from 'core/helpers/app-routes';
import { homepageByUserRole } from 'core/helpers/redirect-helper';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ConfirmEmailModal } from 'scenes/auth/components/ConfirmationPin/ConfirmEmailModal';
import { PageTitle } from 'scenes/auth/components/PageTitle';
import { PhotoArea } from 'scenes/auth/components/PhotoArea';
import { ResendEmail } from 'scenes/auth/components/ResendEmail/ResendEmail';
import { SwitchAuthView } from 'scenes/auth/components/SwitchAuthView';
import 'scenes/auth/styles/auth.less';
import { ExternalAuth } from '../../components/ExternalAuth';
import { RegisterReasonModal } from '../../components/RegisterReasonModal';
import { getLoginPayload, manageLogin } from '../../managers/business-rules.manager';
import { AfterLoginAction } from '../../managers/enums';
import { getAuthError, getBlockedPlatformMessage } from '../../managers/errors.manager';
import { getRedirectUrl } from '../../managers/utils.manager';
import { SignInForm } from './SignInForm';

export const SignIn: React.FC = () => {
    const history = useHistory();
    const userContext = useContext(UserContext);
    const utilsContext = useContext(UtilsContext);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const [redirectUrl, setRedirectUrl] = useState('');

    const [displayRegisterReasonModal, toggleRegisterReasonModal] = useState(false);
    const [displayCEM, toggleCEM] = useState(false);

    useEffect(() => {
        userContext.onLogout();
        setRedirectUrl(getRedirectUrl());
    }, []);

    useEffect(() => {
        if (error) {
            utilsContext.addSnackbar('error', error);
        }
    }, [error]);

    const onLoginUser = (userData: any = null): void => {
        setIsLoading(false);
        setError('');
        setCredentials(userData);

        UserApi.login(getLoginPayload(userData))
            .then((res: any) => {
                setIsLoading(false);
                const afterLoginAction = manageLogin(res.data?.isNewUser, res.data.roles);

                if (afterLoginAction === AfterLoginAction.DisplayBlockedPlatformMessage) {
                    setError(getBlockedPlatformMessage());
                    return;
                }

                userContext.setUser(res.data);
                getUserDetails();

                if (afterLoginAction === AfterLoginAction.AskForRegisterReason) {
                    toggleRegisterReasonModal(true);
                    return;
                }

                if (afterLoginAction === AfterLoginAction.GoToApp) {
                    history.push(redirectUrl ? redirectUrl : homepageByUserRole(res.data.roles));
                }
            })
            .catch((error: any) => {
                manageHttpError(error);
            });
    };

    const getUserDetails = (): void => {
        UserApi.getUser()
            .then((res: any) => {
                userContext.setUser(res.data);
            })
            .catch((_error: any) => null)
    }

    const manageHttpError = (error: any): void => {
        console.error(error);
        setIsLoading(false);

        const errorData = error?.response?.data;
        if (errorData?.message === 'UserNotConfirmed') {
            toggleCEM(true);
            return;
        }
        setError(getAuthError(errorData?.message || null));
    };

    return (
        <>
            <Grid container spacing={0}>
                <PhotoArea />

                <Grid item sm={12} md={7} lg={6} style={{ width: '100%' }}>
                    <Container style={{ textAlign: 'center' }}>
                        <Box className="auth-container">
                            <PageTitle title="Welcome back!" />

                            <Box className="p-content-container">
                                <SignInForm
                                    isLoading={isLoading}
                                    onLoginUser={(userData: any) => onLoginUser(userData)}
                                />

                                {error && (
                                    <Typography className="error-message" color="error">
                                        {error}
                                    </Typography>
                                )}

                                <ExternalAuth acceptTerms={true} onSuccess={(data) => onLoginUser(data)} />

                                <SwitchAuthView
                                    text="Don't have an account?"
                                    btnText="Create Account"
                                    redirectUrl={AppRoutes.CREATE_ACCOUNT}
                                />

                                <ResendEmail />
                            </Box>
                        </Box>
                    </Container>
                </Grid>

                <ConfirmEmailModal
                    credentials={credentials}
                    message={'Please enter the code received on your email here:'}
                    open={displayCEM}
                    handleClose={() => toggleCEM(false)}
                    onSuccess={() => {
                        toggleCEM(false);
                        onLoginUser(credentials);
                    }}
                />

                <RegisterReasonModal
                    open={displayRegisterReasonModal}
                    handleClose={() => {
                        toggleRegisterReasonModal(false);
                        history.push(AppRoutes.TUTORIAL);
                    }}
                />

                <CustomSnackbar
                    open={utilsContext.snackbar.text !== ''}
                    handleClose={() => utilsContext.closeSnackbar()}
                    severity={utilsContext.snackbar.severity}
                    message={utilsContext.snackbar.text}
                />
            </Grid>
        </>
    );
};
