import React from "react";
import {ThemeProvider} from '@material-ui/core/styles'
import {Box, Typography} from "@material-ui/core";

import {emptyTheme} from "assets/themes/components/empty-theme";
import {emptyStyles} from "../styles/root.style";

import {ReactComponent as EmptyPageIcon} from "assets/images/empty-page.svg";

export const EmptyFeed: React.FC = () => {

    const classes = emptyStyles();

    return ( 
        <ThemeProvider theme={emptyTheme}>
            <Box className={classes.root}>
                <div className={classes.content}>
                    <EmptyPageIcon className={classes.icon}/>

                    <Typography variant="h6" color="textPrimary" className={classes.typography}>
                        The Community Feed is empty at the moment. Please come back later.
                    </Typography>
                </div>
            </Box>
        </ThemeProvider>
    )
}
