import * as api from './_utils/api';
import { UserRoles } from '../enums';

export class JobsApi {
    static async assignCollectorToArchivist(collectorId: number, archivistId: number): Promise<any> {
        return api._post(`archivists/assign/${archivistId}/toCollector/${collectorId}`);
    }

    static async assignJob(collectorId: number, userId: number, role: string): Promise<any> {
        switch (role) {
            case UserRoles.ARCHIVIST:
                return api._post(`archivists/assign/${userId}/toCollector/${collectorId}`);
            case UserRoles.CURATOR:
                return api._post(`curators/assign/${userId}/toCollector/${collectorId}`);
        }
    }

    static async getJobs(size: number, page: number, role: string, body: unknown): Promise<any> {
        switch (role) {
            case UserRoles.ARCHIVIST:
                return api._post(
                    //@ts-expect-error unknown-body-1
                    `collectors/get/assignedTo/archivist?page=${page}&size=${size}&userId=${body.userId}`,
                    body,
                );
            case UserRoles.CURATOR:
                return api._post(
                    //@ts-expect-error unknown-body-1
                    `collectors/get/assignedTo/curator?page=${page}&size=${size}&userId=${body.userId}`,
                    body,
                );
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static async getAssignedUsers(size: number, page: number, role: string, body: any): Promise<any> {
        switch (role) {
            case UserRoles.ARCHIVIST:
                return api._post(`archivists/get/assignedTo?page=${page}&size=${size}&userId=${body?.userId}`, body);
            case UserRoles.CURATOR:
                return api._post(`curators/get/assignedTo?page=${page}&size=${size}&userId=${body?.userId}`, body);
        }
    }

    static async removeJob(userId: number, collectorId: number, role: string): Promise<any> {
        switch (role) {
            case UserRoles.ARCHIVIST:
                return api._delete(`archivists/removeAssign/${userId}/toCollector/${collectorId}`);
            case UserRoles.CURATOR:
                return api._delete(`curators/removeAssign/${userId}/toCollector/${collectorId}`);
        }
    }
}
