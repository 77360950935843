import React, {useContext} from "react";
import {Box, Drawer} from "@material-ui/core";

import {appDrawerStyles} from "./styles";

import {UserContext} from "core/context/user";
import {getNavLinks} from "../_helpers/dashboard-drawer-nav-links";

import {Logo} from "../..";
import {Navigation} from "./components/Navigation";
import {ThemeSwitch} from "../_components/ThemeSwitch";

export const DashboardDrawer: React.FC = () => {

    const userContext = useContext(UserContext);

    const classes = appDrawerStyles();

    return (
        <Drawer
            className={classes.root}
            variant="persistent"
            anchor="left"
            open={true}
            classes={{
                paper: classes.paper,
            }}
        >
            <Box className={classes.logoContainer}>
                <Logo />
            </Box>

            <Navigation
                navLinks={getNavLinks(userContext.user?.role || '')}
            />

            <Box className={classes.themeSwitchContainer}>
                <ThemeSwitch />
            </Box>
        </Drawer>
    )
}
