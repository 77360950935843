import React, {useContext} from "react";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from '@material-ui/core/styles'
import { Box, Button, Typography } from "@material-ui/core";

import { AppRoutes } from "core/helpers/app-routes";
import { ReactComponent as EmptyPageIcon } from "assets/images/empty-page.svg";
import { emptyTheme } from "assets/themes/components/empty-theme";
import {emptyPageStyles} from "../styles/root.styles";
import {UserRoles} from "core/enums";
import {UserContext} from "core/context/user";

type EmptyProps = {
    hasError: boolean;
}

export const Empty: React.FC<EmptyProps> = ({hasError}) => {

    const userContext = useContext(UserContext);

    const history = useHistory();
    const classes = emptyPageStyles();

    const goToAddObject = (): void => {
        switch(userContext.user?.role) {
            case UserRoles.COLLECTOR:
                history.push(AppRoutes.ADD_OBJECT);
                break;
            case UserRoles.ARCHIVIST:
                history.push(AppRoutes.ADD_OBJECT);
                break;
            case UserRoles.CURATOR:
                history.push(AppRoutes.ADD_OBJECT);
                break;
        }
    }

    return (
        <ThemeProvider theme={emptyTheme}>
            <Box className={classes.root}>
                <div className={classes.content}>
                    <EmptyPageIcon className={classes.icon}/>

                    <Typography variant="h6" color="textPrimary" className={classes.typography}>
                        {
                            hasError
                                ? 'Sorry, there was an error. Please try again later.'
                                : 'Your collection is empty!\n' +
                                '  It is time to add an artifact.'
                        }
                    </Typography>

                    <Button
                        id="add-new-object-btn"
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={() => goToAddObject()}
                    >
                        Add new object
                    </Button>
                </div>
            </Box>
        </ThemeProvider>
    )
}
