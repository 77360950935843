import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";

import { modalStyles } from "components/_others/modals/modal-styles";

import CloseIcon from "@material-ui/icons/Close";

type ModalHeaderProps = {
    title: string;
    handleClose: () => void;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({title, handleClose}) => {

    const classes = modalStyles();

    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
        >
            <div>
                <Typography className={classes.modalTitle}>
                    {title}
                </Typography>
                <Divider className={classes.divider} />
            </div>
            <CloseIcon
                cursor={'pointer'}
                style={{marginBottom: 15}}
                onClick={handleClose}
            />
        </Box>
    )
}
