import * as api from './_utils/api';

export class ReportingApi {

    static async getArtifactsByStatistics(page:number, size: number, type: string): Promise<any> {
        return api._post(`artifact/get?page=${page}&size=${size}`, {statistics: type});
    }

    static async getReportingStatistics(): Promise<any> {
        return api._get(`artifact/getStatistics`);
    }
}
