import * as api from './_utils/api';

export class CollectionApi {

    static async getMyCollections(page: number, size: number, sortBy: string, asc: boolean|undefined): Promise<any> {
        return api._post(`collection/get?page=${page}&size=${size}&desc=${!asc}&orderBy=${sortBy}`, {});
    }

    static async addNewCollection(name: string): Promise<any> {
        return api._post(`collection/create`, {name});
    }

    static async removeCollection(collectionId: number): Promise<any> {
        return api._delete(`collection/delete/${collectionId}`);
    }

    static async searchCollections(page: number, size: number, searchBy: string, jobId = -1): Promise<any> {
        if(jobId === -1) {
            return api._post(`collection/get?page=${page}&size=${size}`, {search: searchBy});
        }
        return api._post(`collection/get?page=${page}&size=${size}`, {userId: jobId, search: searchBy});
    }
}
