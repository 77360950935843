import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {Box, Button, Grid, Typography} from "@material-ui/core";

import { UserRoles } from "core/enums";
import { AppRoutes } from "core/helpers";
import { FeedbackRequestsApi } from "core/api/feedback-requests/feedback-requests.api";
import { UserContext } from "core/context/user";
import { FeedbackRequestContext } from "core/context/feedback-requests/feedback";

import { BackNavigation, Loader } from "components/_others";
import { Gallery } from "components/_consumer/ObjectDetails/Gallery";
import { AboutObject } from "./components/AboutObject";
import { Documents } from "components/_consumer/ObjectDetails";
import { AboutCollector } from "./components/AboutCollector";
import { RequestBetterPhotos } from "./components/RequestBetterPhotos";
import { AboutObjectShort } from "./components/AboutObjectShort";
import { FeedbackForm } from "./components/FeedbackForm";
import {Chat} from "./components/Chat";
import {UtilsContext} from "../../../../../core/context/utils.context";
import {AdminAssignLabels} from "./components/FeedbackForm/AdminAssignLabels";
import {ICategory} from "../../../../../core/context/consumer/objectDetails";

export const FeedbackRequest: React.FC = () => {

    const history = useHistory();
    const userContext = useContext(UserContext);
    const feedbackRequestContext = useContext(FeedbackRequestContext);

    const [morePhotosRequired, setMorePhotosRequired] = useState(false);
    const [onGiveFeedback, setGiveFeedback] = useState(false);
    const utilsContext = useContext(UtilsContext);

    const [categories, setCategories] = useState<ICategory[]>([]);
    const [materials, setMaterials] = useState<ICategory[]>([]);
    const [styles, setStyles] = useState<ICategory[]>([]);
    const [origins, setOrigins] = useState<ICategory[]>([]);
    const [conditions, setConditions] = useState<ICategory[]>([]);

    useEffect(() => {
        feedbackRequestContext.setLoading(true);

        const params = new URLSearchParams(window.location.search);
        const id = params.get('id');

        if(!id) {
            history.push(AppRoutes.FEEDBACK_REQUESTS);
        }

        getFeedbackRequest(Number(id));

    }, [])

    const getFeedbackRequest = (id: number): void => {
        FeedbackRequestsApi.getFeedbackRequest(id, userContext.user?.role)
            .then((res: any) => {
                feedbackRequestContext.setData(res.data, id);
                setMorePhotosRequired(res.data.morePhotosRequired);
                if(res.data.feedbackDraft !== null && userContext.user?.role === UserRoles.EXPERT) {
                    setGiveFeedback(true);
                    return;
                }
                if(res.data.feedback !== null) {
                    setGiveFeedback(true);
                }
                setCategories(res.data.artifact.categories);
                setMaterials(res.data.artifact.materials);
                setStyles(res.data.artifact.styles);
                setOrigins(res.data.artifact.origins);
                setConditions(res.data.artifact.conditions);
            })
            .catch((error: any) => {
                console.error(error.response);
                history.push(AppRoutes.FEEDBACK_REQUESTS);
                utilsContext.addSnackbar('error', 'Sorry, there was an error and we were unable to display' +
                    ' the request. Please try again later.');
            })
    }

    return (
        <Box>
            {
                !feedbackRequestContext.isLoading && feedbackRequestContext.data?.artifact &&
                    <>
                        <Box>
                            <BackNavigation />
                        </Box>

                        <Grid container spacing={3} style={{marginTop: 20}}>
                            {
                                onGiveFeedback &&
                                <Grid item sm={12} md={6} lg={6}>
                                    <FeedbackForm
                                        id={feedbackRequestContext.data.id}
                                        isCompleted={feedbackRequestContext.data.isCompleted}
                                        artifact={feedbackRequestContext.data.artifact}
                                        onCancel={() => setGiveFeedback(false)}
                                        feedbackResult={feedbackRequestContext.data.feedback}
                                        feedbackDraft={feedbackRequestContext.data.feedbackDraft}
                                        onSuccess={() => {
                                            utilsContext.addSnackbar('success', 'Thank you for your feedback!');
                                            setTimeout(() => {
                                                history.push(AppRoutes.FEEDBACK_REQUESTS);
                                            }, 1500)
                                        }}
                                        onError={() => {
                                            utilsContext.addSnackbar('error');
                                        }}
                                    />
                                </Grid>
                            }

                            <Grid item sm={12} md={onGiveFeedback ? 6 : 12} lg={onGiveFeedback ? 6 : 12}>
                                <Grid container spacing={3}>
                                    <Grid item sm={12} md={6} lg={6}>
                                        {
                                            onGiveFeedback &&
                                            <AboutObjectShort
                                                title={feedbackRequestContext.data?.artifact.title}
                                                description={feedbackRequestContext.data?.artifact.description}
                                                id={feedbackRequestContext.data?.artifact.id}
                                            />
                                        }

                                        <Box style={!onGiveFeedback ? {} : {maxHeight: 200}}>
                                            <Gallery
                                                photos={feedbackRequestContext.data.artifact.photos || []}
                                                maxHeight={onGiveFeedback ? 200 : undefined}
                                            />
                                        </Box>

                                        {
                                            userContext.user?.role === UserRoles.EXPERT &&
                                            !morePhotosRequired && feedbackRequestContext.data.feedback === null &&
                                            <Box marginTop={'10px'}>
                                                <RequestBetterPhotos
                                                    feedbackRequestId={feedbackRequestContext.data.id}
                                                    onSuccess={() => setMorePhotosRequired(true)}
                                                />
                                            </Box>
                                        }

                                        {
                                            morePhotosRequired && feedbackRequestContext.data.feedback === null &&
                                            <Box textAlign={'right'} marginTop={'10px'}>
                                                <Typography style={{fontSize: 13}}>
                                                    Better photos requested
                                                </Typography>
                                            </Box>
                                        }

                                        <Grid container spacing={2} style={{marginTop: 25}}>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <AboutCollector
                                                    id={
                                                        userContext.user?.role === UserRoles.ADMIN
                                                            ? feedbackRequestContext.data.collector.userId
                                                            : null
                                                    }
                                                    firstName={feedbackRequestContext.data.collector.firstName}
                                                    lastName={feedbackRequestContext.data.collector.lastName}
                                                    photoUrl={feedbackRequestContext.data.collector.photoUrl}
                                                    requestedDate={feedbackRequestContext.data.dateCreated}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Typography style={{color: '#B2B2B2', fontSize: 13, marginTop: 5}}>
                                                    Documents & Receipts
                                                </Typography>
                                                <Documents
                                                    documents={feedbackRequestContext.data.artifact.documents}
                                                    hideSectionHeader={true}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item sm={12} md={6} lg={6}>
                                        <AboutObject
                                            onGiveFeedback={onGiveFeedback}
                                            ownerMessage={feedbackRequestContext.data.message}
                                            object={feedbackRequestContext.data.artifact}
                                        />

                                        {
                                            userContext.user?.role === UserRoles.ADMIN &&
                                                <Box marginBottom={'20px'}>
                                                    <Typography
                                                        color={'secondary'}
                                                        style={{fontSize: 18, marginTop: 20, marginBottom: 20}}
                                                    >
                                                        Object Labels
                                                    </Typography>
                                                    <AdminAssignLabels
                                                        disabled={false}
                                                        forAdmins={true}
                                                        artifactId={feedbackRequestContext.data.artifact.id}
                                                        categories={categories}
                                                        materials={materials}
                                                        styles={styles}
                                                        origins={origins}
                                                        conditions={conditions}
                                                        setNewCategories={(c) => setCategories(c)}
                                                        setNewMaterials={(c) => setMaterials(c)}
                                                        setNewStyles={(c) => setStyles(c)}
                                                        setNewOrigins={(c) => setOrigins(c)}
                                                        setNewConditions={(c) => setConditions(c)}
                                                    />
                                                </Box>

                                        }

                                        {
                                            userContext.user?.role === UserRoles.EXPERT &&
                                            !onGiveFeedback &&
                                            feedbackRequestContext.data.feedback === null &&
                                            <Box textAlign={'right'} marginTop={'30px'}>
                                                <Button
                                                    id="give-feedback-btn"
                                                    variant="contained"
                                                    color="primary"
                                                    type="button"
                                                    onClick={() => setGiveFeedback(true)}
                                                >
                                                    Give Feedback
                                                </Button>
                                            </Box>
                                        }

                                        {
                                            !onGiveFeedback &&
                                            <Box>
                                                <Chat
                                                    feedbackRequestId={feedbackRequestContext.data.id}
                                                    isFeedbackCompleted={feedbackRequestContext.data.feedback !== null}
                                                    comments={feedbackRequestContext.data.comments}
                                                    isExpert={userContext.user?.role === UserRoles.EXPERT}
                                                    isAdmin={userContext.user?.role === UserRoles.ADMIN}
                                                />
                                            </Box>
                                        }

                                    </Grid>

                                    {
                                        onGiveFeedback &&
                                        <Box style={{marginLeft: 15, width: '100%'}}>
                                            <Chat
                                                feedbackRequestId={feedbackRequestContext.data.id}
                                                isFeedbackCompleted={feedbackRequestContext.data.feedback !== null}
                                                comments={feedbackRequestContext.data.comments}
                                                isExpert={userContext.user?.role === UserRoles.EXPERT}
                                                isAdmin={userContext.user?.role === UserRoles.ADMIN}
                                            />
                                        </Box>
                                    }
                                </Grid>
                            </Grid>

                        </Grid>
                    </>
            }
            {
                feedbackRequestContext.isLoading && <Loader />
            }
        </Box>
    )
}
