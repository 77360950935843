import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, ThemeOptions, Typography } from "@material-ui/core";

type StatsProps = {
    totalJobs: number;
    totalActiveJobs: number;
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
    label: {
        fontSize: 16,
        marginBottom: 0,
    },
    value: {
        color: theme.palette?.text?.primary,
    }
}))

export const Stats: React.FC<StatsProps> = ({totalJobs, totalActiveJobs}) => {

    const classes = useStyles();

    return (
        <Box>
            <Typography className={classes.label} color="textSecondary">
                Total Jobs:{' '}
                <span color="textPrimary" className={classes.value}><b>{totalJobs}</b></span>
            </Typography>

            <Typography className={classes.label} color="textSecondary">
                Total Active Jobs:{' '}
                <span color="textPrimary" className={classes.value}><b>{totalActiveJobs}</b></span>
            </Typography>
        </Box>
    )
}
