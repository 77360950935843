import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export const commentsStyles = makeStyles((theme: Theme) => ({
    divider: {
        width: '100%',
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: theme.divider.main,
    },
    showMore: {
        cursor: 'pointer',
        color: "#029BFE"
    },
    commentsContainer: {
        maxHeight: 300,
        overflow: 'auto',
        marginBottom: 5,
    },
    root: {
        marginBottom: 15,
        marginTop: 15
    },
    name: {
        fontFamily: 'Myriad Pro Semibold',
        lineHeight: 1,
        display: 'flex',
        alignItems: 'center',
    },
    date: {
        fontSize: 14,
        marginLeft: 10,
        color: theme.formLabel.main,
        opacity: 0.8,
        fontFamily: 'Myriad Pro Regular',
    },
    text: {
        fontSize: 15,
        color: theme.formLabel.main,
        opacity: 1,
        lineHeight: 1.3,
    },
    deleteIcon: {
        marginLeft: 10,
        width: 18,
        cursor: 'pointer',
    },
    editIcon: {
        marginLeft: 10,
        width: 18,
        cursor: 'pointer',
    },
    saveIcon: {
        marginLeft: 10,
        width: 18,
        cursor: 'pointer',
        color: '#FE6502'
    },
    textarea: {
        marginTop: 5
    }
}))

export const addCommentStyles = makeStyles({
    root: {
        marginTop: 15,
    },
    label: {
        marginBottom: 15,
        fontSize: 14,
    },
    textarea: {
        width: '96%',
        ["@media (max-width:960px)"]: { width: '100%' },
    },
    button: {
        float: 'right',
        height: 55,
        padding: '0px 10px',
        fontWeight: 300,
        fontSize: 15,
        ["@media (max-width:960px)"]: { height: 35, width: 100 },
    }
});
