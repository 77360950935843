import React from "react";
import { makeStyles } from "@material-ui/styles";
import {Box, Theme, Typography} from "@material-ui/core";

import imagePlaceholder from "assets/placeholders/artifact-placeholder.png";

import { getPhotoUrl } from "core/helpers";
import { FileSize } from "core/enums";

type ArtifactHighlightsProps = {
    photo: string|null;
    title: string;
    description: string;
}

export const ObjectHighlights: React.FC<ArtifactHighlightsProps> = (
    {photo, title, description}
) => {

    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <img src={getPhotoUrl(photo, FileSize.SM) || imagePlaceholder}
                 alt={title || 'no-photo'}
                 className={classes.photo}
            />
            <Box>
                <Typography className={classes.artifactTitle}>
                    {title}
                </Typography>
                <Typography className={classes.artifactDescription}>
                    {description}
                </Typography>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: 15,
        maxHeight: 360,
        overflow: 'auto',
        backgroundColor: theme.rootBackground.secondary,
        border: '1px solid',
        borderColor: theme.rootBackground.secondaryBorder,
        padding: 10,
    },
    photo: {
        width: '50%',
        maxHeight: 200,
        objectFit: 'cover',
        float: 'left',
        paddingRight: 10,
        ["@media (max-width:960px)"]: { width: '100%', paddingRight: 0 },
    },
    artifactTitle: {
        fontSize: 16,
        lineHeight: 1.3,
        marginTop: -4,
    },
    artifactDescription: {
        fontSize: 12,
        opacity: 0.7,
        marginTop: 5,
    }
}))
