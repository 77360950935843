import React, {useContext, useState} from "react";
import { Box, Button, ThemeOptions, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import CloseIcon from "@material-ui/icons/Close";

import { ICategory } from "core/context/admin/expert-profile";
import { ExpertsApi } from "core/api/admin";

import { AddNewLabelsModal } from "./AddNewLabelsModal";
import { ConfirmDeleteModal } from "components/_others";
import {UtilsContext} from "../../../../../../core/context/utils.context";

type ExpertLabelsProps = {
    expertId: number;
    labels: ICategory[]
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
    label: {
        backgroundColor: theme.label.main,
        color: theme.label.text,
        fontSize: 13,
        marginRight: 7,
        padding: '3px 10px',
        borderRadius: 2,
        display: 'inline-block',
        marginTop: 7,
    },
    closeIcon: {
        color: theme.label.text,
        cursor: 'pointer',
        width: 15,
        marginLeft: 5
    },
    labelContent: {
        display: 'flex',
        alignItems: 'center',
    },
    addNewBtn: {
        padding: '4px 7px',
        fontSize: 13,
        marginRight: 7,
    }
}))

export const ExpertLabels: React.FC<ExpertLabelsProps> = ({expertId, labels}) => {

    const classes = useStyles();

    const [currentLabels, setCurrentLabels] = useState(labels);

    const [displayAddLabelsModal, toggleAddLabelsModal] = useState(false);
    const [displayConfirmDeleteModal, toggleConfirmDeleteModal] = useState(-1);
    const utilsContext = useContext(UtilsContext);
    const onLabelDelete = (id: number): void => {
        ExpertsApi.deleteExpertLabel(expertId, id)
            .then(() => {
                const index = currentLabels.findIndex(l => l.id === id);
                if(index !== -1) {
                    const result = currentLabels;
                    result.splice(index, 1);
                    setCurrentLabels(result.slice());
                }
                toggleConfirmDeleteModal(-1);
                utilsContext.addSnackbar('success', 'Label deleted.');
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error');
            })
    }

    return (
        <Box>
            <Typography color="textSecondary" style={{fontSize: 16, marginTop: 10}}>
                Expert fields:
            </Typography>

            {
                currentLabels.length === 0 &&
                <Typography color="textSecondary" style={{fontSize: 16, marginTop: 10}}>
                    There are no labels associated with this expert.
                </Typography>
            }

            <Box marginTop={'10px'} maxHeight={'150px'} overflow={'auto'}>
                <Button
                    id="add-new-label-btn"
                    variant="outlined"
                    color="primary"
                    className={classes.addNewBtn}
                    onClick={() => toggleAddLabelsModal(true)}
                >
                    Add new labels
                </Button>
                {
                    currentLabels.map((category: ICategory) => (
                        <span className={classes.label} key={category.id}>
                            <span className={classes.labelContent}>
                                {category.name}
                                <CloseIcon
                                    className={classes.closeIcon}
                                    onClick={() => toggleConfirmDeleteModal(category.id)}
                                />
                            </span>
                        </span>
                    ))
                }
            </Box>

            <AddNewLabelsModal
                expertId={expertId}
                open={displayAddLabelsModal}
                handleClose={() => toggleAddLabelsModal(false)}
            />

            <ConfirmDeleteModal
                open={displayConfirmDeleteModal !== -1}
                isLoading={false}
                subtitle={'Are you sure you want to delete this label?'}
                handleClose={() => toggleConfirmDeleteModal(-1)}
                onConfirm={() => onLabelDelete(displayConfirmDeleteModal)}
            />
        </Box>
    )
}
