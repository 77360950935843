import React, { useContext, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { UserContext } from '../../../core/context/user';
import { AppBar, Box, FormControl, MenuItem, Select, Toolbar } from '@material-ui/core';
import { useAvailableJobsData } from '../../jobs/_hooks/use-available-jobs-data.hook';
import { UserRoles } from '../../../core/enums';
import { getHelperToolbarTheme } from '../_styles/helper-toolbar-theme';
import { IJob } from '../../../types';
import { Searchbar } from '../ConsumerToolbar/components/Searchbar';
import { helperToolbarClasses } from './styles';
import { UtilsContext } from '../../../core/context/utils.context';
import { Navigation } from './components/Navigation';
import { navLinks } from '../_helpers/helper-toolbar-nav-links';
import { AppRoutes, setJobIdInStorage } from '../../../core/helpers';
import { useHistory } from 'react-router-dom';

export const HelperToolbar: React.FC = () => {
    const userContext = useContext(UserContext);
    const utilsContext = useContext(UtilsContext);
    const history = useHistory();

    const classes = helperToolbarClasses();

    const [selectedCollector, setSelectedCollector] = React.useState(utilsContext.selectedJob);

    const { jobs, getAvailableJobs, isLoading } = useAvailableJobsData(
        userContext.user?.id || 0,
        UserRoles.COLLECTOR,
        userContext.user?.role || '',
        false,
    );

    useEffect(() => {
        getAvailableJobs(1, null, 100);
    }, []);

    useEffect(() => {
        setSelectedCollector(utilsContext.selectedJob);
    }, [utilsContext.selectedJob]);

    const handleCollectorSelect = (event: any): void => {
        setSelectedCollector(event.target.value);
        utilsContext.selectJob(Number(event.target.value));
        setJobIdInStorage(event.target.value);
        history.push(`${AppRoutes.MY_OBJECTS}?jobId=${Number(event.target.value)}`);
        history.go(0);
    };

    return (
        <ThemeProvider theme={getHelperToolbarTheme(userContext.lightTheme)}>
            <AppBar position="sticky" style={{ top: 65 }}>
                <Toolbar disableGutters style={{ minHeight: 65, borderTop: '1px solid #474747' }}>
                    <Box className={classes.root}>
                        <Box className={classes.selectCollectorBox}>
                            {!isLoading && (
                                <>
                                    <label className={classes.selectCollectorLabel}>Collector</label>
                                    <FormControl variant="outlined" className={classes.selectFormControl}>
                                        <Select
                                            id="collector-select"
                                            value={selectedCollector}
                                            onChange={handleCollectorSelect}
                                            label="Collector"
                                        >
                                            {!jobs.length && (
                                                <MenuItem value={-1} disabled={true}>
                                                    <em>None</em>
                                                </MenuItem>
                                            )}
                                            {jobs.map((j: IJob) => (
                                                <MenuItem value={j.userId} key={j.userId}>
                                                    {j.userName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    {selectedCollector !== -1 && (
                                        <Navigation
                                            links={navLinks}
                                            isLightTheme={userContext.lightTheme}
                                            selectedCollector={selectedCollector}
                                        />
                                    )}
                                </>
                            )}
                        </Box>

                        <Box>{<Searchbar />}</Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    );
};
