import React, {useContext} from "react";
import {useHistory} from "react-router-dom";
import {Box, Divider, Typography} from "@material-ui/core";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {mobileMenuStyles} from "../_styles/mobile-menu.styles";

import {AppRoutes, clearLocalStorage} from "core/helpers";
import {UserContext} from "core/context/user";

import {ThemeSwitch} from "./ThemeSwitch";

type UserActionsProps = {
    handleClose: () => void;
}

export const MobileMenuUserActions: React.FC<UserActionsProps> = ({handleClose}) => {

    const history = useHistory();
    const userContext = useContext(UserContext);

    const mobileMenuClasses = mobileMenuStyles();

    const onLogout = (): void => {
        clearLocalStorage();
        userContext.onLogout();
        history.push(AppRoutes.SIGN_IN);
        handleClose();
    }

    return (
        <>
            <Divider className={mobileMenuClasses.divider} />

            <Box style={{marginLeft: 15}}>
                <ThemeSwitch />
            </Box>

            <Divider className={mobileMenuClasses.divider} />

            <Typography
                style={{display: 'flex', marginTop: 15}}
                onClick={() => onLogout()}
            >
                <ExitToAppIcon
                    style={{marginRight: 10, marginLeft: 10}}/>
                <span>Log out</span>
            </Typography>
        </>
    )
}
