import React from "react";
import "./style.less";

import PinInput from "react-pin-input";

import { IConfirmRegistrationEmail, UserApi } from "core/api/user";

type ConfirmationPinProps = {
    email: string;
    setError: (error: string) => void,
    onSuccess: () => void
}

export const ConfirmationPin: React.FC<ConfirmationPinProps> = ({email, setError, onSuccess}) => {


    const onComplete = (value: string): void => {

        const payload: IConfirmRegistrationEmail = {
            email,
            code: value,
        }

        UserApi.confirmRegistrationEmail(payload)
            .then(() => {
                onSuccess();
            })
            .catch((error: any) => {
                console.error(error.response);
                console.error(error);
                if(error.response.data.message === 'UserNotFound') {
                    setError('Sorry, there is no account associated with your email.');
                    return;
                }
                if(error.response.data.message === 'InvalidCode') {
                    setError('Your confirmation code is invalid.');
                    return;
                }
                setError('Sorry, there was an error. Please try again later.');
            })
    }

    return (
        <PinInput
            length={6}
            initialValue=""
            type="numeric"
            inputMode="number"
            style={{padding: '10px'}}
            inputStyle={{borderColor: 'red'}}
            inputFocusStyle={{borderColor: 'blue'}}
            onComplete={(value, _index) => onComplete(value)}
            autoSelect={true}
            regexCriteria={/^[0-9]*$/}
        />
    )
}
