import React, {useContext, useState} from "react";
import {Box, Card} from "@material-ui/core";

import {artifactCardStyles} from "../../styles/artifact-card.style";

import {UserContext} from "core/context/user";
import {ICommunityFeedArtifact} from "../../types";

import {Comments} from "../Comments";
import {Photos} from "./Photos";
import {Details} from "./Details";
import {MakeAnOfferButton} from "../MakeAnOffer/MakeAnOfferButton";
import {MakeAnOffer} from "../MakeAnOffer";

type ArtifactCardProps = {
    artifact: ICommunityFeedArtifact;
}

export const ArtifactCard: React.FC<ArtifactCardProps> = ({artifact}) => {

    const classes = artifactCardStyles();

    const userContext = useContext(UserContext);

    const [displayMakeAnOfferModal, toggleMakeAnOfferModal] = useState(false);

    return (
        <Card
            className={classes.root}
            id={`${artifact.id}`}
        >

            <Box textAlign={'right'}>
                {
                    artifact.offersEnabled && userContext.user?.id !== artifact.collector.userId &&
                    <Box>
                        <MakeAnOfferButton
                            onClick={() => toggleMakeAnOfferModal(true)}
                        />
                        {
                            displayMakeAnOfferModal &&
                                <MakeAnOffer
                                    artifact={artifact}
                                    open={displayMakeAnOfferModal}
                                    handleClose={() => toggleMakeAnOfferModal(false)}
                                />
                        }
                    </Box>

                }
            </Box>


            <Photos
                photos={artifact.photos}
                artifactId={artifact.id}
            />

            <Details
                title={artifact.title}
                description={artifact.description}
            />

            <Comments
                artifact={artifact}
            />

        </Card>
    )
}
