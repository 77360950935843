import React, {useContext} from "react";
import GoogleLogin from "react-google-login";
import { Button } from "@material-ui/core";

import googleIcon from "assets/images/google-icon.png";
import { RegisterTypesEnum } from "core/enums";
import {UtilsContext} from "core/context/utils.context";

type GoogleAuthProps = {
    acceptTerms?: boolean;
    onSuccess: (data: any) => void;
}

export const GoogleAuth: React.FC<GoogleAuthProps> = ({onSuccess, acceptTerms}) => {

    const utilsContext = useContext(UtilsContext);


    const manageGoogleSuccess = (data: any): void => {

        if(acceptTerms === false) {
            utilsContext.addSnackbar('error', 'Please accept the terms and conditions');
            return;
        }

        const payload = {
            firstName: data.profileObj.givenName,
            lastName: data.profileObj.familyName,
            email: data.profileObj.email,
            token: data.tokenId,
            termsAccepted: true,
            registerType: RegisterTypesEnum.Google,

        }
        onSuccess(payload);
    }

    const onError = (error: any): void => {
        console.error(error);
    }

    return (
        <>
            <GoogleLogin
                clientId={'578242812671-7415ohn5ad6jk0cfmfndajlb59u8vpv4.apps.googleusercontent.com'}
                accessType={'online'}
                cookiePolicy={'single_host_origin'}
                onSuccess={(resp) => manageGoogleSuccess(resp)}
                onFailure={(resp) => onError(resp)}
                onRequest={() => null}
                render={(renderProps: any) => (
                    <Button
                        id="continue-with-google-btn"
                        variant="contained"
                        className="external-auth-btn"
                        onClick={() => renderProps.onClick()}
                    >
                        <img src={googleIcon} alt="google-icon" />
                    </Button>
                )}
            />
        </>

    )
}
