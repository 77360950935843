import { UserRoles } from "core/enums";

export function getAccountRoleFromURLSearchParams(): string|null {

    const urlSearchParams = new URLSearchParams(window.location.search);
    const roleQueryParam = urlSearchParams.get('role')?.toLowerCase();

    if(!roleQueryParam) {
        return null;
    }

    switch(roleQueryParam) {
        case 'Collector':
            return UserRoles.COLLECTOR;
        case 'Archivist':
            return UserRoles.ARCHIVIST;
        case 'Expert':
            return UserRoles.EXPERT;
        case 'Curator':
            return UserRoles.CURATOR;
        default:
            return null;
    }
}

export function getRedirectUrl(): string {
    return window.location.search.indexOf('redirectUrl') > -1
        ? window.location.search.replace('?redirectUrl=', '')
        : ''
}
