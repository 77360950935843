import React from "react";
import { modalStyles } from "components/_others/modals/modal-styles";

import { Backdrop, Box, Button, Divider, Fade, Modal, Typography } from "@material-ui/core";
import { IExpert } from "core/context/consumer/objectDetails";
import { RecommendedExperts } from "./index";

import CloseIcon from "@material-ui/icons/Close";

type RecommendedExpertsModalProps = {
    open: boolean;
    handleClose: () => void;
    experts: IExpert[]
}

export const RecommendedExpertsModal: React.FC<RecommendedExpertsModalProps> = ({open, handleClose, experts}) => {

    const classes = modalStyles();

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open} style={{maxHeight: '600px', overflow: 'auto', paddingTop: 0}}>
                <div className={classes.paper}>
                    <Box
                        className={classes.modalHeader}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        top={'0'}
                        paddingTop={'15px'}
                    >
                        <div>
                            <Typography className={classes.modalTitle}>
                                Recommended Experts
                            </Typography>
                            <Divider className={classes.divider} />
                        </div>
                        <CloseIcon cursor={'pointer'} onClick={handleClose} />
                    </Box>

                    <RecommendedExperts
                        experts={experts}
                    />

                    <Box textAlign={'right'} marginTop={'20px'}>
                        <Button
                            id="cancel-btn"
                            variant="contained"
                            size={"small"}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Close
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    )
}
