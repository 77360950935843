import {Box, Tooltip} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import React from "react";
import {linearProgressBarStyles} from "./progress-bars.style";

type LinearProgressBarProps = {
    percentage: number;
}

export const ObjectDetailsProgressBar: React.FC<LinearProgressBarProps> = ({percentage}) => {

    const classes = linearProgressBarStyles();

    const getValueLeftPosition = (): number => {
        if(!percentage) {
            return 10;
        }
        if(percentage < 10) {
            return percentage + 10;
        }
        if(percentage < 20) {
            return 10 + Math.max(7, percentage);
        }
        if(percentage < 30) {
            return 5 + Math.max(7, percentage);
        }
        if(percentage <= 40) {
            return 3 + percentage;
        }
        if(percentage <= 50) {
            return percentage - 2;
        }
        if(percentage <= 60) {
            return percentage - 2;
        }
        if(percentage <= 70) {
            return percentage - 7;
        }
        if(percentage <= 80) {
            return percentage - 9;
        }
        if(percentage <= 90) {
            return percentage - 12;
        }
        if(percentage <= 100) {
            return percentage - 13;
        }
        return percentage;
    }

    return (
        <Box className={classes.container}>
            <span
                className={classes.value}
                style={{left: `${getValueLeftPosition()}%`}}
            >{percentage}%
            </span>
            <span className={classes.legend}>0%</span>
            <Tooltip
                arrow
                classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.tooltipArrow,
                }}
                title={
                    percentage >= 50
                        ? 'There are enough details for our experts to evaluate your object!' +
                        ' We encourage you to submit an assessment and find more about your artifact.'
                        : ''
                }
            >
                <LinearProgress value={percentage}
                                variant="determinate"
                                classes={{
                                    root: classes.root,
                                    colorPrimary: classes.colorPrimary,
                                    bar: percentage >= 50 ? classes.barSuccess : classes.bar
                                }}
                />
            </Tooltip>

            <span className={classes.legend}>100%</span>
        </Box>
    )
}
