import React, {useEffect, useState} from "react";
import {useJobsData} from "../_hooks/use-jobs-data.hook";
import {Loader} from "../..";
import {Box, Typography} from "@material-ui/core";
import {IJob} from "../../../types";
import {ActiveJob} from "../ActiveJob";
import Pagination from "@material-ui/lab/Pagination";
import {UserRoles} from "../../../core/enums";

type ActiveJobsListProps = {
    userId: number;
    role: string;
    refresh: number;
    hasHistory: boolean;
    onJobStatistics: (totalJobs: any) => void;
}

export const ActiveJobsList: React.FC<ActiveJobsListProps> = (
    {userId, role, refresh, hasHistory, onJobStatistics}
) => {

    const [displayHistory, setDisplayHistory] = useState(false);

    const {jobs, totalJobs, getActiveJobs, isLoading, isEmptyList, pagination} = useJobsData(role, userId);

    useEffect(() => {
        getActiveJobs(1);
    }, [refresh])

    useEffect(() => {
        onJobStatistics(totalJobs);
    }, [totalJobs.totalJobs, totalJobs.totalActiveJobs])

    return (
        <Box>
            {
                !isLoading && !isEmptyList &&
                    <>
                        <Box display={'flex'}>
                            <Typography
                                color={displayHistory ? 'textSecondary' : 'textPrimary'}
                                style={{marginBottom: 7, cursor: 'pointer'}}
                                onClick={() => {
                                    setDisplayHistory(false);
                                    getActiveJobs(1);
                                }}
                            >
                                Active Jobs
                            </Typography>

                            {
                                hasHistory &&
                                <Typography
                                    color={!displayHistory ? 'textSecondary' : 'textPrimary'}
                                    style={{marginBottom: 7, marginLeft: 15, cursor: 'pointer'}}
                                    onClick={() => {
                                        setDisplayHistory(true);
                                        getActiveJobs(1, true);
                                    }}
                                >
                                    Past Jobs
                                </Typography>
                            }
                        </Box>

                        {
                            jobs.map((job: IJob) => (
                                <ActiveJob
                                    key={job.userId}
                                    job={job}
                                    jobOwnerId={userId}
                                    collectorId={job.userId}
                                    role={role}
                                    jobRole={UserRoles.COLLECTOR}
                                    isOld={displayHistory}
                                    onRemoveJob={() => getActiveJobs(1, displayHistory)}
                                />
                            ))
                        }

                        {
                            pagination.numberOfPages > 1 && !isLoading &&
                            <Pagination
                                count={pagination.numberOfPages}
                                page={pagination.page}
                                siblingCount={1}
                                shape="rounded"
                                style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 10, marginTop: 10}}
                                onChange={(_e: any, value: number) => getActiveJobs(value)}
                            />
                        }
                    </>
            }

            {
                totalJobs.totalJobs === 0 && displayHistory && !isLoading &&
                <Typography>
                    There are no jobs for this user.
                </Typography>
            }

            {
                totalJobs.totalActiveJobs === 0 && !displayHistory && !isLoading &&
                <Typography>
                    There are no active jobs for this user.
                </Typography>
            }

            {
                isLoading && <Loader />
            }
        </Box>
    )
}
