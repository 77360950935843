import {ICollectorProfileState} from "./collector-profile.interfaces";
import {IAction} from "../../interfaces";

export default (state: ICollectorProfileState, action: IAction): ICollectorProfileState => {
    switch(action.type) {
        case 'SET_DATA':
            return {
                ...state,
                data: action.payload,
                activeFeedbackRequests: action.payload.activeFeedbackRequests,
                isLoading: false,
            };
        case 'SET_ARTIFACTS':
            return {
                ...state,
                artifacts: action.payload.data,
                isLoading: false,
                artifactsPagination: {
                    page: state.artifactsPagination.page,
                    size: state.artifactsPagination.size,
                    totalCount: action.payload.totalCount,
                    numberOfPages: Math.ceil(action.payload.totalCount/state.artifactsPagination.size),
                },
            };
        case 'SET_CURRENT_PAGE':
            return {
                ...state,
                isLoading: false,
                artifactsPagination: {
                    ...state.artifactsPagination,
                    page: action.payload,
                }
            }
        default:
            return state;
    }
}
