import {IAction} from "../../interfaces";
import {IExpertProfileState} from "./expert-profile.interfaces";

export default (state: IExpertProfileState, action: IAction): IExpertProfileState => {
    switch(action.type) {
        case 'SET_DATA':
            return {
                ...state,
                data: action.payload,
                categories: action.payload.categories,
            };
        case 'SET_EXPERT_TYPE':
            return {
                ...state,
                data: {
                    ...state.data,
                    type: action.payload,
                },
                categories: action.payload.categories,
            };
        case 'SET_COMPLETED_FEEDBACKS':
            return {
                ...state,
                completedFeedbacks: action.payload.data,
                totalDeletedFeedbacksCount: action.payload.totalDeletedCount,
                isLoading: false,
                feedbacksPagination: {
                    page: state.feedbacksPagination.page,
                    size: state.feedbacksPagination.size,
                    totalCount: action.payload.totalCount,
                    numberOfPages: Math.ceil(action.payload.totalCount/state.feedbacksPagination.size),
                },
            };
        case 'SET_CURRENT_PAGE':
            return {
                ...state,
                isLoading: false,
                feedbacksPagination: {
                    ...state.feedbacksPagination,
                    page: action.payload,
                }
            }
        default:
            return state;
    }
}
