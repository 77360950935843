import React from "react";
import {Box, ThemeOptions, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {AppRoutes, getPhotoUrl} from "../../../../../../core/helpers";
import {FileSize} from "../../../../../../core/enums";
import moment from "moment";
import {Link} from "react-router-dom";

type AboutCollectorProps = {
    id?: number|null;
    firstName: string;
    lastName: string;
    requestedDate: string;
    photoUrl: string|null;
}

const useStyles = makeStyles((_theme: ThemeOptions) => ({
    label: {
        color: '#B2B2B2',
        fontSize: 13,
        marginTop: 5,
    },
}))

export const AboutCollector: React.FC<AboutCollectorProps> = (
    {id, firstName, lastName, requestedDate, photoUrl}
) => {

    const classes = useStyles();

    return (
        <Box>
            <Typography className={classes.label}>Feedback requested by</Typography>
            <Box style={{display: 'flex', alignItems: 'center'}}>
                {
                    !photoUrl &&
                    <AccountCircleIcon
                        style={{width: 40, height: 40, color: '#FE6502', cursor: 'pointer'}}
                    />
                }
                {
                    photoUrl &&
                    <img
                        src={getPhotoUrl(photoUrl || '', FileSize.SM)  || '' }
                        style={{width: '40px', height: '40px', borderRadius: '40px'}}
                    />
                } 

                <Box style={{marginLeft: '7px'}}>
                    {
                        id &&
                        <Link to={`${AppRoutes.ADMIN_COLLECTOR_PROFILE}?id=${id}`} color="primary">
                            <Typography color={'textPrimary'} style={{wordBreak: "break-all"}}>
                                {firstName}{' '}{lastName}
                            </Typography>
                        </Link>
                    }

                    {
                        !id &&
                        <Typography color={'textPrimary'} style={{wordBreak: "break-all"}}>
                            {firstName}{' '}{lastName}
                        </Typography>
                    }

                    <Typography color={'textSecondary'} style={{fontSize: 13}}>
                        {moment(requestedDate).format('h:mma MM/DD/YYYY')}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
