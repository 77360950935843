import React from "react";
import {INavLink} from "../../_helpers/nav-link.interfaces";
import {List, ListItem, Typography} from "@material-ui/core";
import {Link, useRouteMatch} from "react-router-dom";
import {navigationClasses} from "../styles";

type NavigationProps = {
    links: INavLink[];
    isLightTheme: boolean;
    selectedCollector: number;
}

const activeLinkColor = (isLightTheme: boolean): string => {
    return isLightTheme ? 'black' : '#FFFFFF';
}

const inactiveLinkColor = (isLightTheme: boolean): string => {
    return isLightTheme ? '#6c6c6c' : '#B2B2B2';
}

export const Navigation: React.FC<NavigationProps> = ({links, isLightTheme, selectedCollector}) => {

    const classes = navigationClasses();

    const isRouteMatch = (path: string, exact: boolean): any => {
        return useRouteMatch({
            path, exact
        });
    }

    return (
        <List component="nav" aria-labelledby="main navigation" className={classes.root}>
            {links.map((navLink: INavLink) => (
                <Link to={`${navLink.path}?jobId=${selectedCollector}`}
                      key={navLink.id}
                      style={
                          isRouteMatch(navLink.path, true)
                              ? {color: activeLinkColor(isLightTheme)}
                              : {color: inactiveLinkColor(isLightTheme)}
                      }
                >
                    <ListItem>
                        <Typography variant="h6">
                            {navLink.name}
                        </Typography>
                    </ListItem>
                </Link>
            ))}
        </List>
    )
}
