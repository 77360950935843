import { Box, Grid, ThemeOptions, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Dimensions } from 'components/_consumer/ObjectDetails';
import { IArtifact } from 'core/context/consumer/objectDetails';
import React from 'react';

type AboutObjectProps = {
    object: IArtifact;
    ownerMessage: string;
    onGiveFeedback: boolean;
};

const useStyles = makeStyles((_theme: ThemeOptions) => ({
    titleBox: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 10,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    id: {
        fontSize: 13,
        marginLeft: 7,
    },
    value: {
        fontSize: 14,
    },
    label: {
        color: '#B2B2B2',
        fontSize: 13,
        marginTop: 5,
    },
}));

export const AboutObject: React.FC<AboutObjectProps> = ({ object, ownerMessage, onGiveFeedback }) => {
    const classes = useStyles();

    return (
        <Box>
            {!onGiveFeedback && (
                <>
                    <Box className={classes.titleBox}>
                        <Typography className={classes.title} variant="h2" color="secondary">
                            {object.title}
                        </Typography>
                        <Typography className={classes.id} color={'textSecondary'}>
                            #{object.id}
                        </Typography>
                    </Box>

                    <Typography
                        className={classes.value}
                        variant="h2"
                        color={'textPrimary'}
                        style={{ marginBottom: 7 }}
                    >
                        {object.description}
                    </Typography>
                </>
            )}

            {onGiveFeedback && (
                <>
                    <Typography className={classes.label} style={{ marginTop: 0 }}>
                        Owners statement:
                    </Typography>
                    <Typography className={classes.value}>{ownerMessage || 'no message'}</Typography>
                </>
            )}

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Dimensions height={object.height} width={object.width} depth={object.depth} />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.label} style={{ marginTop: 10 }}>
                        Weight
                    </Typography>
                    <Typography className={classes.value}>
                        {object.weight ? `${object.weight} lb.` : 'unknown'}
                    </Typography>
                </Grid>
            </Grid>

            <Typography className={classes.label}>Approximate age</Typography>
            <Typography className={classes.value}>
                {object.exactProductionYear !== null && (
                    <>
                        {object.exactProductionYear < 0
                            ? `${object.exactProductionYear * -1} BC`
                            : object.exactProductionYear}
                    </>
                )}
                {object.lowerProductionYear !== null && object.upperProductionYear !== null && (
                    <>
                        {object.lowerProductionYear < 0
                            ? `${object.lowerProductionYear * -1} BC`
                            : object.lowerProductionYear}
                        {' - '}
                        {object.upperProductionYear < 0
                            ? `${object.upperProductionYear * -1} BC`
                            : object.upperProductionYear}
                    </>
                )}
                {object.lowerProductionYear === null && object.exactProductionYear === null && <>{' unknown '}</>}
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.label}>Material</Typography>
                    <Typography className={classes.value}>{object.material || 'unknown'}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.label}>Style</Typography>
                    <Typography className={classes.value}>{object.style || 'unknown'}</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.label}>Origin</Typography>
                    <Typography className={classes.value}>{object.origin || 'unknown'}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.label}>Condition</Typography>
                    <Typography className={classes.value}>{object.condition || 'unknown'}</Typography>
                </Grid>
            </Grid>

            <Typography className={classes.label}>Artist or Maker</Typography>
            <Typography className={classes.value}>{object.artist || 'unknown'}</Typography>

            {object.artistImageUrl && (
                <div>
                    <img src={object.artistImageUrl} style={{ objectFit: 'cover', width: '300px', maxWidth: '100%' }} />
                </div>
            )}

            <Typography className={classes.label}>Artist Bio</Typography>
            <Typography className={classes.value} style={{ maxHeight: 100, overflow: 'auto', marginBottom: 10 }}>
                {object.artistBio || '-'}
            </Typography>

            <Typography className={classes.label}>Scanned Documents</Typography>
            <Typography className={`${classes.value} object-description`}>{object.fileOcrContent || '-'}</Typography>
        </Box>
    );
};
