import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Box, Checkbox,
    FormControlLabel,
    Grid,
    InputLabel, MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    ThemeOptions, Typography
} from "@material-ui/core";

import {
    manageExactProductionYearError,
    manageMaxProductionYearError,
    manageMinProductionYearError,
    manageYearRangeError
} from "components/_consumer/ObjectForm/helpers/errors.manager";
import {YearInput} from "../../../../../../../components/_others/NumberInput";

type ApproximateAgeProps = {
    id: string;
    label: string;
    disabled: boolean;
    setError: (hasError: boolean) => void;
    data: {
        productionYear: number|null;
        lowerProductionYear: number|null;
        upperProductionYear: number|null;
    };
    draftData: {
        productionYear: number|null;
        lowerProductionYear: number|null;
        upperProductionYear: number|null;
    }|null;
    setValue: (v: any) => void;
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
    root: {
        marginBottom: 20
    },
    labelSm: {
        fontSize: 14,
        marginBottom: 5,
        color: theme.palette?.text?.primary,
    },
    focusLabel: {
        fontSize: 14,
        color: "#FE6502",
        marginBottom: 5,
    },
    errorLabel: {
        fontSize: 14,
        color: "#B32222",
        marginBottom: 5,
    },
    collectorInputLabel: {
        color: theme.palette?.text?.secondary,
        fontSize: 14,
        marginBottom: 5,
    },
    collectorInput: {
        color: theme.palette?.text?.primary,
        marginLeft: 4
    }
}));

export const ApproximateAge: React.FC<ApproximateAgeProps> = (
    {id, label,  data, setValue, setError, disabled, draftData}
) => {

    const classes = useStyles();

    const [onFocus, setOnFocus] = useState('');
    const [productionYearOption, setProductionYearOption] = useState('');
    const [accurateStatement, setAccurateStatement] = useState(false);

    const [errors, setErrors] = useState({
        yearRange: '',
        exactProductionYear: '',
        lowerProductionYear: '',
        upperProductionYear: '',
    });

    const [values, setValues] = useState({
        exactProductionYear: '',
        lowerProductionYear: '',
        upperProductionYear: '',
        exactYearNotation: 'AD',
        lowerYearNotation: 'AD',
        upperYearNotation: 'AD',
    })

    useEffect(() => {
        if(disabled) {
            setValuesByCollectorInput();
        }

        if(draftData && !disabled) {
            const option = draftData.lowerProductionYear !== null ? 'range' : 'exact';
            setProductionYearOption(option);

            setValues({
                exactProductionYear: draftData.productionYear ? (draftData.productionYear).toString() : '',
                lowerProductionYear: draftData.lowerProductionYear ? (draftData.lowerProductionYear).toString() : '',
                upperProductionYear: draftData.upperProductionYear ? (draftData.upperProductionYear).toString() : '',
                exactYearNotation: draftData.productionYear ? draftData.productionYear < 0 ? 'BC':'AD' : 'AD',
                lowerYearNotation: draftData.lowerProductionYear ? draftData.lowerProductionYear < 0 ? 'BC':'AD' : 'AD',
                upperYearNotation: draftData.upperProductionYear ? draftData.upperProductionYear < 0 ? 'BC':'AD' : 'AD',
            })
        }
    }, [])

    useEffect(() => {
        setValue({
            exactProductionYear: values.exactYearNotation === 'AD'
                ? values.exactProductionYear
                : Number(values.exactProductionYear) * -1,
            lowerProductionYear: values.lowerYearNotation === 'AD'
                ? values.lowerProductionYear
                : Number(values.lowerProductionYear) * -1,
            upperProductionYear: values.upperYearNotation === 'AD'
                ? values.upperProductionYear
                : Number(values.upperProductionYear) * -1,
        })
    }, [values])

    const setValuesByCollectorInput = (): void => {
        const option = data.lowerProductionYear !== null ? 'range' : 'exact';
        setProductionYearOption(option);

        setValues({
            exactProductionYear: data.productionYear ? (data.productionYear).toString() : '',
            lowerProductionYear: data.lowerProductionYear ? (data.lowerProductionYear).toString() : '',
            upperProductionYear: data.upperProductionYear ? (data.upperProductionYear).toString() : '',
            exactYearNotation: data.productionYear ? data.productionYear < 0 ? 'BC':'AD' : 'AD',
            lowerYearNotation: data.lowerProductionYear ? data.lowerProductionYear < 0 ? 'BC':'AD' : 'AD',
            upperYearNotation: data.upperProductionYear ? data.upperProductionYear < 0 ? 'BC':'AD' : 'AD',
        })
        setError(false);
    }
    const manageErrors = (): void => {
        const errors: any = {
            yearRange: productionYearOption === 'range'
                ? manageYearRangeError(values.lowerProductionYear, values.upperProductionYear)
                : '',
            exactProductionYear: productionYearOption === 'exact'
                ? manageExactProductionYearError(
                    values.exactProductionYear,
                    values.exactYearNotation
                ) : '',
            lowerProductionYear: productionYearOption === 'range'
                ? manageMinProductionYearError(
                    values.lowerProductionYear,
                    values.upperProductionYear,
                    values.lowerYearNotation,
                    values.upperYearNotation
                ) : '',
            upperProductionYear: productionYearOption === 'range'
                ? manageMaxProductionYearError(
                    values.lowerProductionYear,
                    values.upperProductionYear,
                ) : '',
        }

        const err = Object.keys(errors).map((k: string) => errors[k]);
        const hasErrors = err.findIndex(el => el !== '') > -1;

        setErrors({...errors});
        setError(hasErrors);
        setValue(values);
    }

    return (
        <Box className={classes.root}>
            <InputLabel
                htmlFor={id}
                className={onFocus  ? classes.focusLabel : classes.labelSm}
            >
                {label}
            </InputLabel>

            {
                !disabled &&
                <Box display={'flex'} alignItems={'center'}>
                    <Typography className={classes.collectorInputLabel}>
                        Collector's input:
                        <span className={classes.collectorInput}>
                    {
                        data.productionYear !== null &&
                        <>
                            {
                                data.productionYear < 0
                                    ? ` ${data.productionYear * -1} BC`
                                    : data.productionYear
                            }
                        </>
                    }
                            {
                                data.lowerProductionYear !== null && data.upperProductionYear !== null &&
                                <>
                                    {
                                        data.lowerProductionYear < 0
                                            ? `${data.lowerProductionYear * -1} BC`
                                            : data.lowerProductionYear
                                    }{' - '}
                                    {
                                        data.upperProductionYear < 0
                                            ? `${data.upperProductionYear * -1} BC`
                                            : data.upperProductionYear
                                    }
                                </>
                            }
                            {
                                data.lowerProductionYear === null && data.productionYear === null &&
                                <>
                                    {' no information provided '}
                                </>
                            }
                    </span>
                    </Typography>

                    {
                        (data.lowerProductionYear !== null || data.productionYear !== null) &&
                        <FormControlLabel
                            style={{marginBottom: 5, marginLeft: 15}}
                            control={
                                <Checkbox
                                    name="accurate-statement"
                                    color="secondary"
                                    style={{ transform: "scale(0.8)" }}
                                    checked={accurateStatement}
                                    onChange={() => {
                                        if(!accurateStatement) {
                                            setValuesByCollectorInput();
                                        }
                                        setAccurateStatement(!accurateStatement);
                                    }}
                                />
                            }
                            label={
                                <Typography className="checkbox-label" color="textSecondary" style={{marginLeft: -5}}>
                                    This statement is accurate.
                                </Typography>
                            }
                        />
                    }

                </Box>
            }

            <RadioGroup
                row
                aria-label="productionYear"
                name="productionYear"
                value={productionYearOption}
                style={{marginBottom: 5}}
                onChange={(e: any) => {
                    setProductionYearOption(e.target.value)
                    if(e.target.value === 'exact') {
                        setValues({
                            ...values,
                            lowerProductionYear: '',
                            upperProductionYear: '',
                        })
                    } else {
                        setValues({
                            ...values,
                            exactProductionYear: '',
                        })
                    }
                }}
            >
                <FormControlLabel value="exact" control={
                    <Radio disabled={accurateStatement || disabled} />
                } label="Exact Year" />
                <FormControlLabel value="range" control={
                    <Radio disabled={accurateStatement || disabled} />
                } label="Range" />
            </RadioGroup>

            {
                productionYearOption === 'exact' &&
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={8} md={2}>
                        <TextField
                            id="exactProductionYear"
                            variant="outlined"
                            size="small"
                            type="number"
                            fullWidth
                            disabled={accurateStatement || disabled}
                            helperText={errors.exactProductionYear}
                            error={errors.exactProductionYear !== ''}
                            value={values.exactProductionYear || ''}
                            onChange={(e: any) => setValues({
                                ...values,
                                exactProductionYear: e.target.value,
                                lowerProductionYear: '',
                                upperProductionYear: '',
                            })}
                            onFocus={() => setOnFocus('exactProductionYear')}
                            onBlur={() => {setOnFocus(''); manageErrors()}}
                            InputProps={{
                                inputComponent: YearInput,
                            }}
                        />
                    </Grid>

                    <Grid item xs={6} sm={4} md={2}>
                        <Select fullWidth
                                variant="outlined"
                                id="exactYearNotation"
                                value={values.exactYearNotation}
                                disabled={accurateStatement || disabled}
                                onChange={
                                    (e: any) => {
                                        setValues({...values, exactYearNotation: e.target.value});
                                    }
                                }
                                onBlur={() => {setOnFocus(''); manageErrors()}}
                        >
                            <MenuItem value={'AD'}>AD</MenuItem>
                            <MenuItem value={'BC'}>BC</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
            }

            {
                productionYearOption === 'range' &&
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={3}>
                            <TextField
                                id="lowerProductionYear"
                                variant="outlined"
                                size="small"
                                type="number"
                                placeholder={'Min year'}
                                fullWidth
                                disabled={accurateStatement || disabled}
                                helperText={errors.lowerProductionYear}
                                error={errors.lowerProductionYear !== ''}
                                value={values.lowerProductionYear || ''}
                                onChange={(e: any) => setValues({
                                    ...values,
                                    lowerProductionYear: e.target.value,
                                    exactProductionYear: ''
                                })}
                                onFocus={() => setOnFocus('lowerProductionYear')}
                                onBlur={() => {setOnFocus(''); manageErrors()}}
                                InputProps={{
                                    inputComponent: YearInput,
                                }}
                            />
                        </Grid>

                        <Grid item xs={6} sm={6} md={3}>
                            <Select fullWidth
                                    variant="outlined"
                                    id="minYearNotation"
                                    value={values.lowerYearNotation}
                                    disabled={accurateStatement || disabled}
                                    onChange={
                                        (e: any) => {
                                            setValues({...values, lowerYearNotation: e.target.value});
                                        }
                                    }
                                    onBlur={() => {setOnFocus(''); manageErrors()}}
                            >
                                <MenuItem value={'AD'}>AD</MenuItem>
                                <MenuItem value={'BC'}>BC</MenuItem>
                            </Select>
                        </Grid>

                        <Grid item xs={6} sm={6} md={3}>
                            <TextField
                                rows={3}
                                id="upperProductionYear"
                                variant="outlined"
                                size="small"
                                type="number"
                                placeholder={'Max year'}
                                fullWidth
                                disabled={accurateStatement || disabled}
                                helperText={errors.upperProductionYear}
                                error={errors.upperProductionYear !== ''}
                                value={values.upperProductionYear || ''}
                                onChange={(e: any) => setValues({
                                    ...values,
                                    upperProductionYear: e.target.value,
                                    exactProductionYear: ''
                                })}
                                onFocus={() => setOnFocus('upperProductionYear')}
                                onBlur={() => {setOnFocus(''); manageErrors()}}
                                InputProps={{
                                    inputComponent: YearInput,
                                }}
                            />
                        </Grid>

                        <Grid item xs={6} sm={6} md={3}>
                            <Select fullWidth
                                    variant="outlined"
                                    id="maxYearNotation"
                                    value={values.upperYearNotation}
                                    disabled={accurateStatement || disabled}
                                    onChange={
                                        (e: any) => {
                                            setValues({...values, upperYearNotation: e.target.value});
                                        }
                                    }
                                    onBlur={() => {setOnFocus(''); manageErrors()}}
                            >
                                <MenuItem value={'AD'}>AD</MenuItem>
                                <MenuItem value={'BC'}>BC</MenuItem>
                            </Select>
                        </Grid>

                    </Grid>
                </>
            }
        </Box>
    )
}
