import React from "react";
import {Box} from "@material-ui/core";

import {authButtonsStyles} from "../styles/styles";

import {LogInBtn} from "./LogInBtn";
import {SignUpBtn} from "./SignUpBtn";
import {responsiveStyles} from "../../_styles/responsive-styles";

export const AuthButtons: React.FC = () => {

    const classes = authButtonsStyles();
    const responsiveClasses = responsiveStyles();

    return (
        <Box className={`${responsiveClasses.hideMobile} ${classes.authButtonsContainer}`}>
            <LogInBtn />
            <SignUpBtn />
        </Box>
    )
}
