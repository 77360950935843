import React from "react";
import {NavigationEnum} from "../enums";
import {Box} from "@material-ui/core";
import {navigationStyles} from "../styles/navigation.style";

const navItems = [
    {
        id: NavigationEnum.OBJECTS,
        name: 'Objects'
    },
    {
        id: NavigationEnum.DELETED_OBJECTS,
        name: 'Deleted Objects'
    },
    {
        id: NavigationEnum.JOBS,
        name: 'Archivists/Curator'
    },
]

type NavigationProps = {
    activeView: number;
    onChange: (view: number) => void;
}

export const Navigation: React.FC<NavigationProps> = ({activeView, onChange}) => {

    const classes = navigationStyles();

    return (
        <Box className={classes.root}>
            {
                navItems.map((n: any) => (
                    <Box key={n.id}
                         className={activeView === n.id ? classes.activeItem : classes.item}
                         onClick={() => onChange(n.id)}
                    >
                        {n.name}
                    </Box>
                ))
            }
        </Box>
    )
}
