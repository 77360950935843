import {SortingOption, SortCollectionsOptions} from "core/enums";

export function getSortingOptions(activeOption: string, asc: boolean): SortingOption[] {
    const result: SortingOption[] = [];
    if(activeOption === undefined) {
        return result;
    }

    result.push({
        name: `Title`,
        type: SortCollectionsOptions.Title,
        asc,
        active: activeOption === SortCollectionsOptions.Title,
    })

    result.push({
        name: `Estimated Value`,
        type: SortCollectionsOptions.EstimatedValue,
        asc,
        active: activeOption === SortCollectionsOptions.EstimatedValue,
    })

    result.push({
        name: `No. of Items`,
        type: SortCollectionsOptions.NumberOfItems,
        asc,
        active: activeOption === SortCollectionsOptions.NumberOfItems,
    })

    result.push({
        name: `Date Created`,
        type: SortCollectionsOptions.DateCreated,
        asc,
        active: activeOption === SortCollectionsOptions.DateCreated,
    })

    return result;
}

export function getActiveSortingOption(option: string): SortingOption {
    return getSortingOptions(option, false).find(e => e.name === option) || {
        name: `Date Created`,
        type: SortCollectionsOptions.DateCreated,
        asc: false,
        active: true,
    };
}

export function getNewSortingOption(activeOption: string, newOption: string, asc: boolean): SortingOption {
    if(activeOption === newOption) {
        return {
            name: '',
            type: newOption,
            asc: asc === undefined ? true : !asc,
            active: true,
        }
    }

    return {
        name: '',
        type: newOption,
        asc,
        active: true,
    }
}
