import * as api from '../_utils/api';
import { UserRoles } from '../../enums';

export class FeedbackRequestsApi {
    static async getListing(
        size: number,
        page: number,
        searchBy: string,
        isCompleted: boolean,
        rating: boolean | null,
        _role?: string,
    ): Promise<any> {
        return api._post(`feedback/getFeedbackRequests?page=${page}&size=${size}`, {
            isCompleted,
            search: searchBy,
            isRated: rating,
        });
    }

    static async getFeedbackRequest(id: number, role?: string): Promise<any> {
        if (!role) {
            return null;
        }

        switch (role) {
            case UserRoles.ADMIN:
                return api._get(`feedback/getFeedbackRequest/${id}`);
            case UserRoles.EXPERT:
                return api._get(`feedback/getFeedbackRequest/${id}`);
        }
    }

    static async requestBetterPhotos(feedbackRequestId: number): Promise<any> {
        return api._put(`feedback/requireMorePhotos/${feedbackRequestId}`, { morePhotosRequired: true });
    }

    static async saveFeedbackRequest(id: number, payload: unknown): Promise<any> {
        return api._post(`feedback/create/${id}`, payload);
    }

    static async saveFeedbackDraft(id: number, payload: unknown): Promise<any> {
        return api._patch(`feedback/SaveDraft/${id}`, payload);
    }

    static async markFeedbackAsRead(id: number): Promise<any> {
        return api._put(`feedback/readFeedback/${id}`);
    }

    static async addComment(
        feedbackRequestId: number,
        comment: string,
        isCollector = false,
        _artifactId = -1,
    ): Promise<any> {
        if (isCollector) {
            return api._post(`feedback/createComment/${feedbackRequestId}`, comment, {
                'Content-Type': 'application/json-patch+json',
            });
        }

        return api._post(`feedback/createComment/${feedbackRequestId}`, comment, {
            'Content-Type': 'application/json-patch+json',
        });
    }

    static async deleteComment(
        _feedbackRequestId: number,
        id: number,
        isCollector = false,
        _artifactId = -1,
    ): Promise<any> {
        if (isCollector) {
            // eslint-disable-next-line max-len
            return api._delete(`Feedback/DeleteComment/${id}`);
        }
        return api._delete(`Feedback/DeleteComment/${id}`);
    }

    static async deleteFeedbackRequest(id: number): Promise<any> {
        return api._delete(`feedback/deleteRequest/${id}`);
    }
}
