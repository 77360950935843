import React, {useState} from "react";
import {
    Box,
    Button,
    InputAdornment,
    Modal,
    Typography,
    Backdrop,
    CircularProgress,
    Fade,
    InputLabel,
    TextField,
} from "@material-ui/core";

import {modalStyles} from "components/_others/modals/modal-styles";
import {makeOfferStyles} from "../../styles/make-offer.style";
import CloseIcon from "@material-ui/icons/Close";

import {ICommunityFeedArtifact} from "../../types";
import {useMakeOffer} from "../../hooks/use-make-offer.hook";

import {ModalHeader} from "components/_others/modals/ModalHeader";
import {ObjectHighlights} from "components/object/ObjectHighlights";
import {NumberInput} from "components/_others/NumberInput";


type MakeAnOfferProps = {
    artifact: ICommunityFeedArtifact;
    open: boolean;
    handleClose: () => void;
}

export const MakeAnOffer: React.FC<MakeAnOfferProps> = ({artifact, open, handleClose}) => {

    const modalClasses = modalStyles();
    const classes = makeOfferStyles();

    const [values, setValues] = useState({amount: '', message: ''});
    const [onFocus, setOnFocus] = useState('');

    const {isMakeOfferLoading, makeOffer} = useMakeOffer();

    const sendOffer = (): void => {
        makeOffer(artifact.publicFeedId, values.amount, values.message).then((res: boolean) => {
            if(res) {
                handleClose();
            }
        })
    }

    return (
        <Modal
            className={modalClasses.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={modalClasses.paper}>

                    <ModalHeader
                        title={'Make an Offer'}
                        handleClose={() => handleClose()}
                    />

                    <Typography className={modalClasses.modalSubtitle}>
                        You can send a proposal for this collector to buy his object.
                        Make sure you add also a message to this request.
                    </Typography>

                    <ObjectHighlights
                        photo={artifact.photos?.length > 0 ? artifact.photos[0].url : null}
                        title={artifact.title}
                        description={artifact.description}
                    />

                    <Box className={classes.formContainer}>
                        <Box className={classes.formControl}>
                            <InputLabel htmlFor="amount"
                                        className={onFocus === 'amount'
                                            ? `${classes.focusLabel} ${classes.formLabel}`
                                            : classes.formLabel
                                        }
                            >
                                Amount
                            </InputLabel>
                            <TextField
                                id="amount"
                                variant="outlined"
                                size="small"
                                type="number"
                                fullWidth
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    inputComponent: NumberInput,
                                }}
                                value={values.amount}
                                onChange={(e: any) => setValues({
                                    ...values,
                                    amount: e.target.value,
                                })}
                                onFocus={() => setOnFocus('amount')}
                                onBlur={() => setOnFocus('')}
                            />
                        </Box>

                        <Box className={classes.formControl}>
                            <InputLabel htmlFor="amount"
                                        className={onFocus === 'message'
                                            ? `${classes.focusLabel} ${classes.formLabel}`
                                            : classes.formLabel
                                        }
                            >
                                Add a message to your offer
                            </InputLabel>
                            <TextField
                                multiline
                                rows={3}
                                rowsMax={10}
                                type="text"
                                id="description"
                                variant="outlined"
                                fullWidth
                                value={values.message}
                                inputProps={{ maxLength: 2000 }}
                                onChange={(e: any) => setValues({...values, message: e.target.value})}
                                onFocus={() => setOnFocus('message')}
                                onBlur={() => setOnFocus('')}
                            />
                        </Box>
                    </Box>

                    <Box textAlign={'right'} marginTop={'40px'}>
                        <Button
                            id="cancel-btn"
                            variant="contained"
                            size={"small"}
                            style={{marginRight: '15px'}}
                            className={modalClasses.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>

                        <Button
                            id="submit-btn"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            style={{minWidth: 120}}
                            disabled={!values.message || !values.amount}
                            className={modalClasses.primaryButton}
                            onClick={() => sendOffer()}
                        >
                            {
                                isMakeOfferLoading && <CircularProgress className="circular-progress sm" />
                            }
                            Send Offer
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    )
}
