import {makeStyles, Theme} from "@material-ui/core/styles";

export const statisticsStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    item: {
        marginLeft: 40,
        marginTop: 0,
        lineHeight: 1.1,
        '&:first-child': {
            marginLeft: 0,
        },
        ["@media (max-width: 710px)"]: {
            width: '100%',
            ['&:last-child']: {
                marginLeft: 0,
            }
        },
    },
    label: {
        fontSize: 18,
        opacity: 0.8,
        ["@media (max-width: 710px)"]: { fontSize: 14},

    },
    value: {
        color: theme.palette.secondary.main,
        fontSize: 22,
        fontWeight: 600,
        marginLeft: 8,
        ["@media (max-width: 710px)"]: { fontSize: 17},
    }
}));
