import React, { useContext, useEffect, useState } from "react";
import {
    Box, Button, Chip, Typography, CircularProgress, InputLabel, TextField,
    ThemeOptions
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { Autocomplete } from "@material-ui/lab";
import { validateEmail } from "core/helpers";

import { modalStyles } from "../../_others/modals/modal-styles";
import { UserContext } from "core/context/user";

import CloseIcon from "@material-ui/icons/Close";
import ReplyIcon from "@material-ui/icons/Reply";

type FormProps = {
    isLoading: boolean;
    errorMessage: string;
    onShare: (emails: string[], message: string) => void;
    handleClose: () => void;
}

export const Form: React.FC<FormProps> = ({isLoading, errorMessage, handleClose, onShare}) => {

    const userContext = useContext(UserContext);

    const classes = useStyles();
    const modalClasses = modalStyles();

    const [emails, setEmails] = useState<string[]>([]);
    const [currentEmail, setCurrentEmail] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        setEmails([]);
        setMessage('');
    }, [])

    const onAddEmail = (values: any): void => {
        const newEmails = values;
        const invalidEmailIndex = newEmails.findIndex((el: string) =>
            !validateEmail(el) || el === userContext.user?.email
        );
        if(invalidEmailIndex > -1) {
            newEmails.splice(invalidEmailIndex, 1);
        }
        setEmails(newEmails.slice());
        setCurrentEmail('');
    }

    const handleEmailsBlur = (): void => {
        if(currentEmail && validateEmail(currentEmail) && currentEmail !== userContext.user?.email) {
            const result = emails;
            result.push(currentEmail);
            setEmails(result.slice());
        }
        setCurrentEmail('');
    }

    return (
        <>
            <form id="share-artifact-to-persons-form" className={classes.form}>
                <InputLabel htmlFor="emails" className={classes.formLabel}>
                    Provide one or more email addresses of the people with
                    whom you want to share this
                </InputLabel>
                <Autocomplete
                    multiple
                    id="emails"
                    options={[]}
                    value={emails}
                    freeSolo
                    disableClearable={true}
                    clearOnBlur={true}
                    onChange={(_e, value) => onAddEmail(value)}
                    onInputChange={(_e, value) => setCurrentEmail(value)}
                    onBlur={() => handleEmailsBlur()}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                        />
                    )}
                />

                <InputLabel htmlFor="message" className={classes.formLabel}>
                    Add a short message
                </InputLabel>
                <TextField
                    multiline
                    rows={3}
                    rowsMax={10}
                    type="text"
                    id="message"
                    variant="outlined"
                    fullWidth
                    value={message}
                    inputProps={{ maxLength: 500 }}
                    onChange={(e: any) => setMessage(e.target.value)}
                />
            </form>

            {
                errorMessage &&
                <Typography className="error-message" color="error">
                    {errorMessage}
                </Typography>
            }

            <Box textAlign={'right'} marginTop={'20px'}>
                <Button
                    id="cancel-share-btn"
                    variant="contained"
                    size={"small"}
                    style={{marginRight: '15px'}}
                    className={modalClasses.secondaryButton}
                    onClick={handleClose}
                >
                    <CloseIcon height={'20px'} /> Cancel
                </Button>

                <Button
                    id="share-btn"
                    variant="contained"
                    color="primary"
                    size={"small"}
                    className={modalClasses.primaryButton}
                    onClick={() => onShare(emails, message)}
                    disabled={!emails.length}
                >
                    {
                        isLoading && <CircularProgress className="circular-progress sm" />
                    }
                    {
                        !isLoading && <ReplyIcon className="share-icon" />
                    }
                    Share
                </Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
    form: {
      marginTop: 20,
    },
    formLabel: {
        fontSize: 13,
        marginBottom: 5,
        color: theme.formLabel.main
    },
}))
