import * as api from '../_utils/api';

export class TaxonomyApi {

    static async getRootTypes(parentId: number | null = null): Promise<any> {
        return api._get(`label/get${parentId !== null ? `?parentId=${parentId}` : ''}`);
    }

    static async deleteLabel(id: number): Promise<any> {
        return api._delete(`label/delete/${id}`);
    }

    static async updateLabel(id: number, text: unknown, parentId: number, isActive: boolean): Promise<any> {
        return api._put(`label/update/${id}`, {name: text, parentId, isActive});
    }

    static async addLabel(payload: unknown): Promise<any> {
        return api._post('label/create', payload);
    }

    static async isLabelInUse(id: number): Promise<any> {
        return api._post(`label/LabelInUse/${id}`);
    }
}
