import React from "react";
import {Box, Typography} from "@material-ui/core";
import {statisticsStyles} from "./styles";

type StatisticsProps = {
    statistics: {label: string, value: string}[];
}

export const Statistics: React.FC<StatisticsProps> = ({statistics}) => {

    const classes = statisticsStyles();

    return (
        <Box className={classes.root}>
            {
                statistics.map(s => (
                    <Typography className={classes.item} key={s.label}>
                        <span className={classes.label}>{s.label}:</span>
                        <span className={classes.value}>{s.value}</span>
                    </Typography>
                ))
            }
        </Box>
    )
}
