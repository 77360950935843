import { createContext, useReducer } from 'react';
import UserReducer from './user.reducer';

import { IUserState } from "./user.interfaces";

const initialState: IUserState = {
    user: null,
    newNotifications: false,
    lightTheme: false,
    setUser: () => null,
    onLogout: () => null,
    setProfilePic: () => null,
    setHasNewNotifications: () => null,
    setTheme: () => null,
}

export const UserContext = createContext(initialState);

export const UserProvider: any = ({children}: any) => {

    const [state, dispatch] = useReducer(UserReducer, initialState);

    const setUser = (user: unknown): void => {
        dispatch({
            type: 'SET_USER',
            payload: user
        })
    }

    const onLogout = (): void => {
        dispatch({
            type: 'LOGOUT',
            payload: null,
        })
    }

    const setProfilePic = (url: string): void => {
        dispatch({
            type: 'SET_PROFILE_PIC',
            payload: url
        })
    }

    const setHasNewNotifications = (value: boolean): void => {
        dispatch({
            type: 'SET_HAS_NEW_NOTIFICATIONS',
            payload: value,
        })
    }

    const setTheme = (value: boolean, save: boolean): void => {
        dispatch({
            type: 'SET_THEME',
            payload: {value, save},
        })
    }

    return (
        <UserContext.Provider value={{
            ...state,
            setUser,
            onLogout,
            setProfilePic,
            setHasNewNotifications,
            setTheme,
        }}>
            {children}
        </UserContext.Provider>
    )
}
