import  { createContext, useReducer } from 'react';
import SearchResultsReducer from "./search-results.reducer";
import {ISearchResultsState} from "./search-results.interfaces";

const initialState: ISearchResultsState = {
    artifacts: [],
    collections: [],
    searchBy: '',
    paginationArtifacts: {
        page: 1,
        size: 5,
        totalCount: 0,
        numberOfPages: 0,
    },
    paginationCollections: {
        page: 1,
        size: 5,
        totalCount: 0,
        numberOfPages: 0,
    },
    setArtifactsResult: () => null,
    setCollectionsResult: () => null,
    setCurrentPage: () => null,
    setSearchBy: () => null,
    clearSearchResults: () => null,
}

export const SearchResultsContext = createContext(initialState);

export const SearchResultsProvider: any = ({children}: any) => {

    const [state, dispatch] = useReducer(SearchResultsReducer, initialState);

    const setArtifactsResult = (artifacts: unknown): void => {
        dispatch({
            type: 'SET_ARTIFACTS_RESULT',
            payload: artifacts || []
        })
    }

    const setCollectionsResult = (collections: unknown): void => {
        dispatch({
            type: 'SET_COLLECTIONS_RESULT',
            payload: collections || []
        })
    }

    const clearSearchResults = (): void => {
        dispatch({
            type: 'CLEAR_SEARCH_RESULTS',
            payload: null
        })
    }

    const setCurrentPage = (scope: string, page: number): void => {
        dispatch({
            type: 'SET_CURRENT_PAGE',
            payload: {scope, page}
        })
    }

    const setSearchBy = (searchBy: string): void => {
        dispatch({
            type: 'SET_SEARCH_BY',
            payload: searchBy
        })
    }

    return (
        <SearchResultsContext.Provider value={{
            ...state,
            clearSearchResults,
            setArtifactsResult,
            setCollectionsResult,
            setCurrentPage,
            setSearchBy,
        }}>
            {children}
        </SearchResultsContext.Provider>
    )
}
