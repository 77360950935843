import * as api from './_utils/api';

export class CommunityFeedApi {

    static async shareObjectToCommunityFeed(objectId: number, enableOffers: boolean): Promise<any> {
        return api._post(
            `publicFeed/shareArtifact/${objectId}?offersEnabled=${enableOffers}`,
            {message: ''}
        );
    }

    static async getCommunityFeedObjects(page:number, size: number): Promise<any> {
        return api._post(`artifact/get?page=${page}&size=${size}`, {publicFeed: true});
    }

    static async removeObjectFromCommunityFeed(id: number): Promise<any> {
        return api._delete(`publicFeed/removeArtifact/${id}`);
    }

    static async postCommunityFeedOffer(id: number, payload: unknown): Promise<any> {
        return api._post(`publicFeed/makeOffer/${id}`, payload);
    }
}
