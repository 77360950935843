import { makeStyles } from "@material-ui/core/styles";

export const modalStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'auto'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        outline: 'none',
        boxShadow: theme.shadows[5],
        padding: '15px 20px',
        width: '30%',
        maxWidth: '440px',
        [theme.breakpoints.down('md')]: {
            width: '40%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    modalHeader: {
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.background.paper,
      zIndex: 1,
    },
    modalFooter: {
        position: 'sticky',
        bottom: -15,
        padding: '10px 0',
        marginBottom: 10,
        backgroundColor: theme.palette.background.paper
    },
    modalTitle: {
        color: theme.palette.text.primary,
        fontSize: 20,
        letterSpacing: 1,
        fontWeight: 'bold'
    },
    modalSubtitle: {
        color: theme.palette.text.primary,
        fontSize: 13.5,
        marginTop: 10,
        opacity: 0.8,
    },
    divider: {
        backgroundColor: '#707070',
        margin: '10px 0',
        height: 0.5,
        maxWidth: 135,
    },
    primaryButton: {
        color: '#FFFFFF',
        fontSize: 16,
        height: 28,
        borderRadius: 2,
        '&:disabled': {
            backgroundColor: '#A94E15',
            color: '#a8a8a8'
        }
    },
    secondaryButton: {
        color: '#FFFFFF',
        fontSize: 16,
        height: 28,
        borderRadius: 2,
        backgroundColor: '#707070',
        '&:hover': {
            backgroundColor: '#929292',
        }
    }
}));
