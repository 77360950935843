import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export const activeJobStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.label.labelBackground,
        borderLeft: '7px solid',
        borderLeftColor: theme.palette.primary.main,
        borderRadius: 2,
        marginBottom: 10,
        padding: '5px 10px',
    },
    flexRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    text: {
        fontSize: 14,
    },
    textHighlight: {
      color: theme.palette.secondary.main,
    },
    viewCollectorLink: {
        color: theme.palette.secondary.main,
        fontSize: 12,
    },
    removeJob: {
        color: theme.palette.error.main,
        fontSize: 12,
        marginLeft: 5,
    }
}))
