import React, {useContext, useState} from "react";
import { useHistory } from "react-router-dom";
import { Backdrop, Box, Button, CircularProgress, Fade, Modal, Typography } from "@material-ui/core";
import { modalStyles } from "components/_others/modals/modal-styles";

import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

import { CollectorApi } from "core/api/consumer/collector.api";
import {UtilsContext} from "../../../../core/context/utils.context";

type DeleteCollectionModalProps = {
    objectId: number;
    collectionId: number;
    open: boolean;
    handleClose: () => void;
}

export const DeleteObjectModal: React.FC<DeleteCollectionModalProps> = (
    {objectId, collectionId, open, handleClose}
) => {

    const classes = modalStyles();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const utilsContext = useContext(UtilsContext);
    const onDelete = (): void => {
        setIsLoading(true);

        CollectorApi.removeArtifactFromCollection(objectId, collectionId)
            .then(() => {
                setIsLoading(false);
                handleClose();
                history.go(0);
                utilsContext.addSnackbar('success', 'This object was removed')
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error', 'Sorry, there was an error and we were unable to remove this item.')
            })
    }

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Box textAlign={'right'}>
                        <CloseIcon cursor={'pointer'} onClick={handleClose} />
                    </Box>

                    <Box>
                        <Typography className={classes.modalTitle}>
                            Remove Object?
                        </Typography>
                        <Typography className={classes.modalSubtitle}>
                            Are you sure you want to remove the item from this collection?
                        </Typography>
                    </Box>

                    <Box textAlign={'right'} marginTop={'60px'}>
                        <Button
                            id="cancel-delete-btn"
                            variant="contained"
                            size={"small"}
                            style={{marginRight: '15px'}}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>

                        <Button
                            id="delete-btn"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            className={classes.primaryButton}
                            onClick={() => onDelete()}
                        >
                            {
                                isLoading && <CircularProgress className="circular-progress sm" />
                            }
                            {
                                !isLoading && <DeleteIcon />
                            }
                            Remove
                        </Button>
                    </Box>
                </div>

            </Fade>
        </Modal>
    )
}
