import React, {useContext} from "react";
import { Link } from "react-router-dom";
import { ISharedArtifact } from "core/context/consumer/sharedWithMe";
import { Card, CardContent, Tooltip, Typography } from "@material-ui/core";

import "../styles/shared-with-me-card.less";
import imagePlaceholder from "assets/placeholders/artifact-placeholder.png";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { getPhotoUrl } from "core/helpers/file-size.helper";
import { FileSize } from "core/enums";
import { AppRoutes } from "core/helpers/app-routes";
import {UserContext} from "../../../core/context/user";

type SharedWithMeCardProps = {
    artifact: ISharedArtifact
}

export const SharedWithMeCard: React.FC<SharedWithMeCardProps> = ({artifact}) => {

    const userContext = useContext(UserContext);

    return (
        <Link to={
                !artifact.sharedFromCollection
                    ? `${AppRoutes.SHARED_OBJECT}?id=${artifact.id}`
                    : `${AppRoutes.SHARED_OBJECT}?id=${artifact.id}&collectionShare=${artifact.shareCollectionId}`
              }
              className={'shared-with-me-card card-link'}>
            <Card
                className="shared-with-me-card"
                id={`${artifact.id}`}
                style={{backgroundColor: userContext.lightTheme ? '#EFEFEF' : "#2B2B33"}}
            >
                <CardActionArea>
                    <CardContent className="collector-details">
                        {
                            !artifact.collectorPhoto &&
                            <AccountCircleIcon
                                style={{width: 40, height: 40, color: '#FE6502', cursor: 'pointer'}}
                            />
                        }
                        {
                            artifact.collectorPhoto &&
                            <img
                                src={getPhotoUrl(artifact.collectorPhoto || '', FileSize.SM)  || '' }
                                style={!getPhotoUrl(artifact.collectorPhoto, FileSize.SM) ? {opacity: 0.4} : {}}
                                alt={artifact.collectorName}
                                className="collector-avatar"
                            />
                        }

                        <Typography className="collector-name" style={userContext.lightTheme ? {color: '#333333'} : {}}>
                            {artifact.collectorName}
                        </Typography>
                    </CardContent>

                    <CardMedia
                        className="media"
                        image={getPhotoUrl(artifact.photo?.url, FileSize.SM) || imagePlaceholder}
                        title={artifact.title}
                    />

                    <CardContent>
                        <Tooltip
                            arrow
                            title={artifact.title || ''}
                            className="full-artifact-name-tooltip"
                        >
                            <Typography
                                component="h2"
                                className="card-title"
                                style={userContext.lightTheme ? {color: '#333333'} : {}}
                            >
                                {artifact.title}
                            </Typography>
                        </Tooltip>

                        <Typography
                            className="description"
                            style={
                                userContext.lightTheme
                                    ? {color: 'rgb(129 129 129)', minHeight: 135}
                                    : {minHeight: 135}
                            }
                        >
                            {
                                artifact.description?.length > 300
                                    ? `${artifact.description?.substring(0,300)}...`
                                    : artifact?.description
                            }
                        </Typography>

                    </CardContent>
                </CardActionArea>
            </Card>
        </Link>
    )
}
