import { SortingOption, SortObjectsOptions } from 'core/enums';

export function getSortingOptions(activeOption: string, asc: boolean): SortingOption[] {
    const result: SortingOption[] = [];
    if (activeOption === undefined) {
        return result;
    }

    result.push({
        name: `Title`,
        type: SortObjectsOptions.Title,
        asc,
        active: activeOption === SortObjectsOptions.Title,
    });

    result.push({
        name: `Estimated Value`,
        type: SortObjectsOptions.EstimatedValue,
        asc,
        active: activeOption === SortObjectsOptions.EstimatedValue,
    });

    result.push({
        name: `No. of Feedbacks`,
        type: SortObjectsOptions.Feedbacks,
        asc,
        active: activeOption === SortObjectsOptions.Feedbacks,
    });

    result.push({
        name: `Date Created`,
        type: SortObjectsOptions.DateCreated,
        asc,
        active: activeOption === SortObjectsOptions.DateCreated,
    });

    result.push({
        name: 'Artist Name',
        type: SortObjectsOptions.Artist,
        asc,
        active: activeOption === SortObjectsOptions.Artist,
    });

    return result;
}

export function getNewSortingOption(activeOption: string, newOption: string, asc: boolean | undefined): SortingOption {
    if (activeOption === newOption) {
        return {
            name: '',
            type: newOption,
            asc: asc === undefined ? true : !asc,
            active: true,
        };
    }

    return {
        name: '',
        type: newOption,
        asc: true,
        active: true,
    };
}
