import React from "react";
import {Box, Theme, Typography} from "@material-ui/core";
import {getPhotoUrl} from "../../core/helpers";
import {FileSize} from "../../core/enums";
import imagePlaceholder from "../../assets/placeholders/artifact-placeholder.png";
import {makeStyles} from "@material-ui/styles";

type CollectionHighlightsProps = {
    id: number;
    name: string;
    photoUrl: string;
    totalObjects: string;
}

export const CollectionHighlights: React.FC<CollectionHighlightsProps> = (
    {id, name, photoUrl, totalObjects}
) => {

    const classes = useStyles();

    return (
        <Box id={`${id}`}>
            <img src={getPhotoUrl(photoUrl, FileSize.SM) || imagePlaceholder}
                 alt={name || 'no-photo'}
                 className={classes.photo}
            />

            <Box className={classes.detailsBox}>
                <Typography color={'primary'}>
                    {name}
                </Typography>
                <Typography>
                    <span className={classes.totalObjects}>Total Objects:</span> <b>{totalObjects}</b>
                </Typography>
            </Box>

        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    photo: {
        width: '100%',
        maxHeight: 300,
        objectFit: 'cover',
        ["@media (max-width:960px)"]: { width: '100%', paddingRight: 0 },
    },
    detailsBox: {
        backgroundColor: theme.rootBackground.secondary,
        marginTop: -5,
        padding: 15,
    },
    totalObjects: {
        color: theme.palette.secondary.main,
    }
}))
