import React, {useState} from "react";
import { modalStyles } from "components/_others/modals/modal-styles";

import { Backdrop, Box, Button,  Fade, Modal, TextField, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

type ReferralModalProps = {
    open: boolean;
    handleClose: () => void;
    onConfirm: (reference: string) => void
}

export const ReferralModal: React.FC<ReferralModalProps> = ({open, handleClose, onConfirm}) => {

    const classes = modalStyles();

    const [answer, setAnswer] = useState('');
    const [error, setError] = useState('');

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Box>
                        <Typography className={classes.modalTitle}>
                            How did you hear about us?
                        </Typography>
                        <Typography className={classes.modalSubtitle}>
                            Please say a few words about how did you hear about us.
                        </Typography>
                    </Box>

                    <TextField
                        fullWidth
                        multiline
                        rows={3}
                        rowsMax={10}
                        variant="outlined"
                        margin="normal"
                        id="answer"
                        name="answer"
                        autoComplete="new-password"
                        type="text"
                        placeholder="Please say a few words about how did you hear about us"
                        inputProps={{ maxLength: 1000 }}
                        error={error !== ''}
                        helperText={error}
                        value={answer}
                        onChange={(e) => {
                            setAnswer(e.target.value);
                            setError(answer !== '' || answer?.trim().length > 0
                                ? '' : 'Please say a few words about how did you hear about us.')
                        }}
                    />

                    <Box textAlign={'right'} marginTop={'60px'}>
                        <Button
                            id="cancel-btn"
                            variant="contained"
                            size={"small"}
                            style={{marginRight: '15px'}}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel Registration
                        </Button>

                        <Button
                            id="delete-btn"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            className={classes.primaryButton}
                            disabled={!answer}
                            onClick={() => onConfirm(answer)}
                        >
                            Continue
                        </Button>
                    </Box>

                </div>
            </Fade>
        </Modal>
    )
}
