import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export const rootStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: '15px 10px 30px 15px',
    },
    statistics: {
        position: 'fixed',
        marginTop: -15,
        marginLeft: 15,
        ["@media (max-width:1280px)"]: {
            backgroundColor: theme.rootBackground.main,
            zIndex: 1,
            width: '100%',
            marginTop: 0,
            top: 65,
            paddingTop: 10,
            marginLeft: -3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    pagination: {
        marginTop: 10,
        marginLeft: -10,
        ["@media (max-width:1280px)"]: {
            marginRight: 10,
        }
    },
    feed: {
        ["@media (max-width:1280px)"]: {
            marginTop: 50,
        }
    }
}))

export const emptyStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: 'calc(100vh - 65px)',
    },
    content: {
        margin: 'auto',
        textAlign: 'center',
    },
    icon: {
        marginBottom: 30,
    },
    typography: {
        marginBottom: 50,
        fontSize: 20,
        maxWidth: 525,
        lineHeight: 1,
    },
});
