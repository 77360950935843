import React, { useState } from "react";
import { modalStyles } from "components/_others/modals/modal-styles";
import { Backdrop, Box, Button, Fade, Modal, TextField, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

type ExpertiseModalProps = {
    open: boolean;
    handleClose: () => void;
    onConfirm: (expertise: string) => void
}

export const ExpertiseModal: React.FC<ExpertiseModalProps> = ({open, handleClose, onConfirm}) => {

    const classes = modalStyles();

    const [error, setError] = useState('');
    const [expertise, setExpertise] = useState('');

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Box>
                        <Typography className={classes.modalTitle}>
                            Expertise
                        </Typography>
                        <Typography className={classes.modalSubtitle}>
                            Please say a few words about your expertise
                        </Typography>
                    </Box>

                    <Box>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            rowsMax={10}
                            variant="outlined"
                            margin="normal"
                            id="expertise"
                            name="expertise"
                            autoComplete="new-password"
                            placeholder="Please say a few words about your expertise"
                            type="text"
                            inputProps={{ maxLength: 1000 }}
                            error={error !== ''}
                            helperText={error}
                            value={expertise}
                            onChange={(e) => {
                                setExpertise(e.target.value);
                                setError(expertise !== '' || expertise?.trim().length > 0
                                    ? '' : 'Please say a few words about your expertise.')
                            }}
                        />
                    </Box>

                    <Box textAlign={'right'} marginTop={'60px'}>
                        <Button
                            id="cancel-btn"
                            variant="contained"
                            size={"small"}
                            style={{marginRight: '15px'}}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel Registration
                        </Button>

                        <Button
                            id="delete-btn"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            className={classes.primaryButton}
                            disabled={!expertise}
                            onClick={() => onConfirm(expertise)}
                        >
                            Continue
                        </Button>
                    </Box>
                </div>
            </Fade>

        </Modal>
    )
}
