import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export const navigationStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 7,
    },
    item: {
        width: '50%',
        backgroundColor: theme.rootBackground.cardBackground,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        fontSize: 13,
        color: theme.palette.text.secondary,
    },
    activeItem: {
        width: '50%',
        backgroundColor: theme.rootBackground.highlightBackground,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        fontSize: 13,
        color: theme.palette.primary.main,
        fontWeight: 600,
        borderBottom: '2px solid',
        borderColor: theme.palette.primary.main,
    }
}))
