import MyriadProRegular from './MYRIADPRO-REGULAR.woff';
import MyriadProBold from './MYRIADPRO-BOLD.woff';
import MyriadProSemibold from './MYRIADPRO-SEMIBOLD.woff';
import MyriadProLight from './MyriadPro-Light.woff';

export const myriadProRegular: any = {
    fontFamily: 'Myriad Pro Regular',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 'normal',
    src: `
    local('Myriad Pro'),
    local('Myriad Pro Regular'),
    url(${MyriadProRegular}) format('woff')
  `,
};

export const myriadProBold: any = {
    fontFamily: 'Myriad Pro Bold',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 'bold',
    src: `
    local('Myriad Pro'),
    local('Myriad Pro Bold'),
    url(${MyriadProBold}) format('woff')
  `,
};

export const myriadProSemiBold: any = {
    fontFamily: 'Myriad Pro Semibold',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 'bolder',
    src: `
    local('Myriad Pro'),
    local('Myriad Pro Semibold'),
    url(${MyriadProSemibold}) format('woff')
  `,
};

export const myriadProLight: any = {
    fontFamily: 'Myriad Pro Light',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 'light',
    src: `
    local('Myriad Pro'),
    local('Myriad Pro Light'),
    url(${MyriadProLight}) format('woff')
  `,
}
