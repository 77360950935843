import * as api from '../_utils/api';

export class CollectorsApi {
    static async getCollectorProfile(collectorId: number): Promise<any> {
        return api._get(`collectors/get/${collectorId}`);
    }

    static async getCollectorArtifacts(collectorId: number, size: number, page: number): Promise<any> {
        return api._post(`artifact/get?page=${page}&size=${size}&userId=${collectorId}`, {
            userId: collectorId,
            isDeleted: false,
        });
    }

    static async getCollectorDeletedArtifacts(collectorId: number, size: number, page: number): Promise<any> {
        return api._post(`artifact/get?page=${page}&size=${size}&userId=${collectorId}`, {
            userId: collectorId,
            isDeleted: true,
        });
    }

    static async changeCollectorType(collectorId: number, type: string): Promise<any> {
        return api._put(`collectors/update/${collectorId}/`, { type });
    }

    static async changeUserPaymentFreePass(userId: number, paymentFreePass: boolean): Promise<any> {
        return api._put(`collectors/update/${userId}/`, { paymentFreePass });
    }

    static async getCollectorArtifact(userId: number, artifactId: number): Promise<any> {
        return api._get(`artifact/get/${artifactId}?userId=${userId}`);
    }

    static async restoreObject(objectId: number): Promise<any> {
        return api._put(`artifact/restore/${objectId}/`);
    }
}
