import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid, ThemeOptions, Typography } from "@material-ui/core";

import { AppRoutes } from "core/helpers";
import { CollectorsApi } from "core/api/admin";
import { IFeedbackResult } from "core/context/feedback-requests/feedback";
import { CollectorArtifactContext } from "./context/collector-artifact.context";
import { getCollectorAndArtifactIdFromURLSearchParams, isInvalidURLSearchParams } from "./managers/utils.manager";

import { BackNavigation, Loader } from "components/_others";
import { Gallery } from "components/_consumer/ObjectDetails/Gallery";
import { AboutObject } from "../../../feedbackRequests/scenes/FeedbackRequest/components/AboutObject";
import { Documents, Feedbacks, ObjectLabels } from "components/_consumer/ObjectDetails";
import { FeedbackResultModal } from "components/_consumer/ObjectDetails/FeedbackResultModal";
import {UtilsContext} from "../../../../../core/context/utils.context";
import {AdminAssignLabels} from "../../../feedbackRequests/scenes/FeedbackRequest/components/FeedbackForm/AdminAssignLabels";
import {ICategory} from "../../../../../core/context/consumer/objectDetails";

const useStyles = makeStyles((_theme: ThemeOptions) => ({
    label: {
        color: '#B2B2B2',
        fontSize: 13,
        marginTop: 5,
    },
}))

export const CollectorArtifact: React.FC = () => {

    const classes = useStyles();

    const collectorArtifactContext = useContext(CollectorArtifactContext);
    const history = useHistory();

    const utilsContext = useContext(UtilsContext);
    const [displayFeedbackResultModal, toggleFeedbackResultModal] = useState<IFeedbackResult|null>(null);

    const [categories, setCategories] = useState<ICategory[]>([]);
    const [materials, setMaterials] = useState<ICategory[]>([]);
    const [styles, setStyles] = useState<ICategory[]>([]);
    const [origins, setOrigins] = useState<ICategory[]>([]);
    const [conditions, setConditions] = useState<ICategory[]>([]);

    useEffect(() => {
        const {collectorId, artifactId} = getCollectorAndArtifactIdFromURLSearchParams();
        if(isInvalidURLSearchParams(collectorId, artifactId)) {
            history.push(AppRoutes.COLLECTOR_LISTING);
        }

        getArtifact(Number(collectorId), Number(artifactId))

    }, [])

    const getArtifact = (collectorId: number, artifactId: number): void => {
        CollectorsApi.getCollectorArtifact(collectorId, artifactId)
            .then((res: any) => {
                collectorArtifactContext.setArtifact(res.data);
                collectorArtifactContext.setLoadingState(false);

                setCategories(res.data.categories);
                setMaterials(res.data.materials);
                setStyles(res.data.styles);
                setOrigins(res.data.origins);
                setConditions(res.data.conditions);
            })
            .catch((error: any) => {
                console.error(error);
                history.push(AppRoutes.COLLECTOR_LISTING);
                utilsContext.addSnackbar('error',  'Sorry, there was an error and we were unable to display ' +
                    'the request. Please try again later.');

            })
    }

    return (
        <Box>
            {
                !collectorArtifactContext.isLoading &&
                    <>
                        <Box>
                            <BackNavigation />
                        </Box>

                        <Grid container spacing={3} style={{marginTop: 20}}>

                            <Grid item sm={12} md={6} lg={6} style={{overflow: 'auto'}} >
                                <Gallery
                                    photos={collectorArtifactContext.artifact.photos || []}
                                />
                                {
                                    collectorArtifactContext.artifact.isFeedbackImported && <ObjectLabels
                                        canDeleteLabels={false}
                                        artifactId={collectorArtifactContext.artifact.id}
                                        categories={collectorArtifactContext.artifact.categories}
                                        materials={collectorArtifactContext.artifact.materials}
                                        styles={collectorArtifactContext.artifact.styles}
                                        origins={collectorArtifactContext.artifact.origins}
                                        conditions={collectorArtifactContext.artifact.conditions}
                                        eras={collectorArtifactContext.artifact.eras}
                                        setNewCategories={() => null}
                                    />
                                }

                            </Grid>

                            <Grid item sm={12} md={6} lg={6} style={{overflow: 'auto'}}>
                                <AboutObject
                                    onGiveFeedback={false}
                                    ownerMessage={''}
                                    object={collectorArtifactContext.artifact}
                                />

                                <Box marginTop={'20px'}>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Typography className={classes.label}>Object Feedback</Typography>
                                            <Feedbacks
                                                hideSectionHeader={true}
                                                feedbacks={collectorArtifactContext.artifact.feedbacks}
                                                displayFeedbackRequestMessage={''}
                                                onSelect={(feedback) => toggleFeedbackResultModal(feedback)}
                                                activeFeedbacksComments={[]}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Typography className={classes.label}>Documents & Receipts</Typography>
                                            <Documents
                                                hideSectionHeader={true}
                                                documents={collectorArtifactContext.artifact.documents}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box marginBottom={'20px'}>
                                    <Typography
                                        color={'secondary'}
                                        style={{fontSize: 18, marginTop: 20, marginBottom: 20}}
                                    >
                                        Object Labels
                                    </Typography>
                                    <AdminAssignLabels
                                        disabled={false}
                                        forAdmins={true}
                                        artifactId={collectorArtifactContext.artifact.id}
                                        categories={categories}
                                        materials={materials}
                                        styles={styles}
                                        origins={origins}
                                        conditions={conditions}
                                        setNewCategories={(c) => setCategories(c)}
                                        setNewMaterials={(c) => setMaterials(c)}
                                        setNewStyles={(c) => setStyles(c)}
                                        setNewOrigins={(c) => setOrigins(c)}
                                        setNewConditions={(c) => setConditions(c)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </>
            }

            {
                collectorArtifactContext.isLoading && <Loader />
            }

            {
                displayFeedbackResultModal !== null &&
                <FeedbackResultModal
                    open={true}
                    handleClose={() => toggleFeedbackResultModal(null)}
                    isOwnArtifact={false}
                    photo={collectorArtifactContext.artifact.photos?.find(p => p.isPrimary)?.url || ''}
                    feedback={displayFeedbackResultModal}
                    artifactId={collectorArtifactContext.artifact.id}
                />
            }
        </Box>
    )
}
