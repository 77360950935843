import React, { useContext } from 'react';
import { Box, Grid, Hidden } from '@material-ui/core';

import { fixedHeaderStyles } from 'assets/styles/fixed-header';
import { headerStyles } from '../styles/header.styles';

import { SortingOption, UserRoles } from 'core/enums';

import { Statistics } from 'scenes/consumer/_components/Statistics';
import { SortBy } from 'components/_others/SortBy';
import { AddNewCollectionBtn } from './AddNewCollectionBtn';
import { UserContext } from 'core/context/user';

type PageHeaderProps = {
    totalCount: number;
    sortingOptions: SortingOption[];
    onSortByChange: (option: string) => void;
    sortByValue: string;
};

export const PageHeader: React.FC<PageHeaderProps> = ({ totalCount, sortingOptions, onSortByChange, sortByValue }) => {
    const userContext = useContext(UserContext);

    const fixedHeaderCss = fixedHeaderStyles();
    const classes = headerStyles();

    return (
        <Box className={`${fixedHeaderCss.root} ${classes.root}`}>
            <Grid container className={classes.gridContainer}>
                <Grid item xs={8} sm={8} md={6}>
                    <Statistics statistics={[{ label: 'Total Collections', value: `${totalCount}` }]} />
                </Grid>
                <Grid item xs={4} sm={4} md={6}>
                    <Box className={classes.actionsContainer}>
                        <Hidden smDown>
                            <span style={{ marginRight: 10 }}>
                                <SortBy
                                    options={sortingOptions}
                                    onSelect={(option: string) => onSortByChange(option)}
                                    sortByValue={sortByValue}
                                />
                            </span>
                        </Hidden>

                        {userContext.user?.role !== UserRoles.ARCHIVIST && <AddNewCollectionBtn />}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};
