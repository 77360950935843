import { Backdrop, Box, Button, CircularProgress, Divider, Fade, Modal, Typography } from '@material-ui/core';
import { modalStyles } from 'components/_others/modals/modal-styles';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';

import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import { CollectorApi } from 'core/api/consumer';
import { IPhoto } from 'core/context/consumer/objectDetails';
import { PhotoBox } from './PhotoBox';

type ReorderPhotosModalProps = {
    open: boolean;
    handleClose: () => void;
    photos: IPhoto[];
};

const reorder = (list: IPhoto[], startIndex: number, endIndex: number): IPhoto[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const ReorderPhotosModal: React.FC<ReorderPhotosModalProps> = ({ open, handleClose, photos }) => {
    const classes = modalStyles();
    const history = useHistory();

    const [items, setItems] = useState<IPhoto[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setItems(photos);
    }, [photos]);

    const onDragEnd = (result: any): void => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const res = reorder(items, result.source.index, result.destination.index);

        setItems(res);
    };

    const handleSubmit = (): void => {
        setIsLoading(true);

        const payload = items.map((i: IPhoto, index: number) => {
            return {
                id: i.id,
                order: index,
            };
        });

        CollectorApi.reorderPhotos(payload)
            .then(() => {
                const params = new URLSearchParams(window.location.search);
                const assess = params.get('assess');
                const id = params.get('id');
                if (assess === 'open') {
                    history.replace(`${history.location.pathname}?id=${id}`);
                    history.go(0);
                } else {
                    history.go(0);
                }
            })
            .catch((error: any) => {
                console.error(error.response);
            });
    };

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open} style={{ maxHeight: '600px', overflow: 'auto', paddingTop: 0 }}>
                <div className={classes.paper} style={{ maxWidth: '700px' }}>
                    <Box
                        className={classes.modalHeader}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        top={'0'}
                        paddingTop={'15px'}
                    >
                        <div>
                            <Typography className={classes.modalTitle}>Reorder Photos</Typography>
                            <Divider className={classes.divider} />
                        </div>
                        <CloseIcon cursor={'pointer'} onClick={handleClose} />
                    </Box>

                    <Typography className={classes.modalSubtitle}>
                        You can click and hold a photo and drag it into the position you like.
                    </Typography>

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" direction="horizontal">
                            {(provided: any) => (
                                <div
                                    ref={provided.innerRef}
                                    style={{
                                        maxWidth: '100%',
                                        overflow: 'auto',
                                        display: 'flex',
                                        marginTop: 15,
                                    }}
                                >
                                    <div style={{ display: 'flex', paddingRight: 100 }}>
                                        {items.map((p, index) => (
                                            <Draggable key={p.id} draggableId={p.id.toString()} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <PhotoBox key={p.id} photo={p} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    <Box className={classes.modalFooter}>
                        <Box textAlign={'right'} marginTop={'20px'}>
                            <Button
                                id="cancel-assessment-btn"
                                variant="contained"
                                size={'small'}
                                style={{ marginRight: '15px' }}
                                className={classes.secondaryButton}
                                onClick={handleClose}
                                disabled={isLoading}
                            >
                                <CloseIcon height={'20px'} /> Cancel
                            </Button>

                            <Button
                                id="delete-btn"
                                variant="contained"
                                color="primary"
                                size={'small'}
                                className={classes.primaryButton}
                                onClick={() => handleSubmit()}
                                disabled={isLoading}
                            >
                                {isLoading && <CircularProgress className="circular-progress sm" />}
                                {!isLoading && (
                                    <SaveIcon
                                        style={{
                                            width: 20,
                                            marginRight: 5,
                                        }}
                                    />
                                )}
                                Save
                            </Button>
                        </Box>
                    </Box>
                </div>
            </Fade>
        </Modal>
    );
};
