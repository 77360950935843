import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export const headerStyles = makeStyles((_theme: Theme) => ({
    root: {
        position: 'sticky',
        top: 65,
        zIndex: 3,
        paddingTop: 15,
        paddingBottom: 20,
        width: '100.1%',
        ["@media (max-width:1250px)"]: { width: '100.5%'},
        ["@media (max-width: 812px) and (orientation: landscape)"]: { position: 'unset'},
    },
    gridContainer: {
        alignItems: 'center',
    },
    actionsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    }
}));

export const addNewObjectBtnStyles = makeStyles((_theme: Theme) => ({
    root: {
        height: 35,
        fontSize: 17,
        fontWeight: 600,
        letterSpacing: 0,
        width: 180,
        ["@media (max-width:545px)"]: { width: 'unset', fontSize: 15 },
        ["@media (max-width:600px)"]: { fontSize: 15 },
    },
    icon: {
        fontSize: 22,
        marginRight: 5,
        marginTop: 1,
        ["@media (max-width:545px)"]: { marginRight: 0 },
    },
    hideXS: {
        ["@media (max-width:545px)"]: { display: 'none'},
    }
}));
