import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Box, Button, Typography } from '@material-ui/core';

import 'react-circular-progressbar/dist/styles.css';
import { widgetStyles } from '../styles/widget.style';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { getStylesForCircularProgress } from '../../_components/ObjectDetailsProgressBar/progress-bars.style';

type WidgetProps = {
    isLightTheme: boolean;
    isSelected: boolean;
    title: string;
    subtitle: string;
    count: number;
    percentage: number;
    onSelect: () => void;
};

export const Widget: React.FC<WidgetProps> = ({
    isLightTheme,
    isSelected,
    title,
    count,
    subtitle,
    percentage,
    onSelect,
}) => {
    const widgetClasses = widgetStyles();

    return (
        <Box className={`${isSelected ? widgetClasses.selected : ''} ${widgetClasses.root}`}>
            <Typography className={widgetClasses.title}>{title}</Typography>
            <Typography className={widgetClasses.count}>{subtitle}</Typography>

            <Box className={widgetClasses.flexBox}>
                <Button
                    id="toggle-list-btn" //
                    variant={isSelected ? 'outlined' : 'contained'}
                    size={'small'}
                    className={`${isSelected ? widgetClasses.selectedBtn : ''} ${widgetClasses.toggleListBtn}`}
                    onClick={() => onSelect()}
                    style={count === 0 ? { visibility: 'hidden' } : {}}
                >
                    {!isSelected && (
                        <>
                            <ExpandMoreIcon className={widgetClasses.expandIcon} /> Show List
                        </>
                    )}
                    {isSelected && (
                        <>
                            <ExpandLessIcon className={widgetClasses.expandIcon} /> Hide List
                        </>
                    )}
                </Button>

                <Box className={widgetClasses.circularProgressBox}>
                    <CircularProgressbar
                        value={percentage}
                        text={`${percentage}%`}
                        strokeWidth={13}
                        styles={buildStyles(getStylesForCircularProgress(isLightTheme))}
                    />
                </Box>
            </Box>
        </Box>
    );
};
