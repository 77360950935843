import {UserRoles} from "../enums";

export enum AppRoutes {
    BASE = '',
    PRIVACY_POLICY = '/privacy-policy',
    TERMS_AND_CONDITIONS = '/terms-and-conditions',
    OUR_MISSION = '/our-mission',
    SIGN_IN = '/sign-in',
    CREATE_ACCOUNT = '/create-account',
    NOTIFICATIONS = '/collector/notifications',
    COLLECTOR_PROFILE = '/collector/my-profile',
    SEARCH_RESULTS = '/collector/search-results',
    MY_OBJECTS = '/collector/my-objects',
    MY_COLLECTIONS = '/collector/my-collections',
    COLLECTION = '/collector/collection',
    OBJECT = '/collector/object',
    ADD_OBJECT = '/collector/add-object',
    SHARED_WITH_ME = '/collector/shared-with-me',
    SHARED_OBJECT = '/collector/shared/object',
    TUTORIAL = '/help',
    PUBLIC_FEED = '/collector/community-feed',
    REPORTING = '/collector/reporting',

    FEEDBACK_REQUESTS = '/dashboard/feedback-requests',
    FEEDBACK_REQUEST = '/dashboard/feedback-requests/details',
    COLLECTOR_LISTING = '/dashboard/collectors',
    COLLECTOR_ARTIFACT = '/dashboard/collector/artifact',
    EXPERT_LISTING = '/dashboard/experts',
    ARCHIVIST_LISTING = '/dashboard/archivists',
    CURATOR_LISTING = '/dashboard/curators',
    TAXONOMY = '/dashboard/taxonomy',
    ADMIN_COLLECTOR_PROFILE = '/dashboard/collectors/profile',
    ADMIN_ARCHIVIST_PROFILE = '/dashboard/archivists/profile',
    ADMIN_CURATOR_PROFILE = '/dashboard/curators/profile',
    ADMIN_EXPERT_PROFILE = '/dashboard/experts/profile',
    DASHBOARD_NOTIFICATIONS = '/dashboard/notifications',
    ADMIN_STATISTICS = '/dashboard/statistics',

    ASSIGNED_COLLECTORS_INDEX = '/helper/assigned-collectors',
}

export function getMyObjectsRoute(role: string|undefined): string {
    switch(role) {
        case UserRoles.COLLECTOR:
            return AppRoutes.MY_OBJECTS;
        case UserRoles.ARCHIVIST:
            return AppRoutes.MY_OBJECTS;
        case UserRoles.CURATOR:
            return AppRoutes.MY_OBJECTS;
        default:
            return '';
    }
}
