import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { UserRoles } from "core/enums";
import {TextDivider} from "../../../components";

type ChooseRoleProps = {
    onSelect: (role: string) => void;
}

const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: '15%',
    },
    box: {
        textAlign: 'left',
        marginBottom: 30,
        cursor: 'pointer',
        '&:hover': {
            "& > p": {
                color: `${theme.palette.primary.main}!important`,
                "& > svg": {
                    display: 'block',
                    left: 50
                }
            },
        },
    },
    boxTitle: {
        fontSize: 17,
        marginBottom: 5,
        display: 'flex',
        alignItems: 'center',
    },
    boxSubtitle: {
        color: theme.palette.text.secondary,
        fontSize: 13,
    },
    arrowIcon: {
        width: '15px',
        display: 'none',
        position: 'relative',
        left: 0,
    },
    divider: {
        marginBottom: 30,
        width: '50%'
    }
}))

export const ChooseRole: React.FC<ChooseRoleProps> = ({onSelect}) => {

    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box
                onClick={() => onSelect(UserRoles.COLLECTOR)}
                className={classes.box}
            >
                <Typography className={classes.boxTitle} color={'primary'}>
                    I want to become a Collector <ArrowForwardIcon className={classes.arrowIcon}/>
                </Typography>
                <span className={classes.boxSubtitle}>
                    A Collector is someone who wants to document and manage their collection.
                </span>
            </Box>

            <Box className={classes.divider} >
                <TextDivider text={'or'} />
            </Box>

            <Box
                onClick={() => onSelect(UserRoles.EXPERT)}
                className={classes.box}
            >
                <Typography className={classes.boxTitle} color={'textPrimary'}>
                    I want to become an Expert <ArrowForwardIcon className={classes.arrowIcon}/>
                </Typography>
                <span className={classes.boxSubtitle}>
                    An Expert shares their knowledge with collectors
                </span>
            </Box>

            <Box
                onClick={() => onSelect(UserRoles.ARCHIVIST)}
                className={classes.box}
            >
                <Typography className={classes.boxTitle} color={'textPrimary'}>
                    I want to become an Archivist <ArrowForwardIcon className={classes.arrowIcon}/>
                </Typography>
                <span className={classes.boxSubtitle}>
                    An Archivist is someone who helps an avid collector document their collection.
                </span>
            </Box>

            <Box
                onClick={() => onSelect(UserRoles.CURATOR)}
                className={classes.box}
            > 
                <Typography className={classes.boxTitle} color={'textPrimary'}>
                    I want to become a Curator <ArrowForwardIcon className={classes.arrowIcon}/>
                </Typography>
                <span className={classes.boxSubtitle}>
                    A Curator is someone who helps the Avid Collector review their collections
                    once the avid collector's collection has been uploaded to Artifact.
                </span>
            </Box>
        </Box>
    )
}
