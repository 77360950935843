import React, {useContext} from "react";
import { Box } from "@material-ui/core";

import { ConsumerFooter} from "components/layout/ConsumerFooter";

import { AppRoutes } from "core/helpers";
import { PrivateRoute } from "components/_others/PrivateRoute";
import { MyObjects } from "./MyObjects";
import { AddObject } from "./addObject/scenes/AddObject";
import { ObjectDetails } from "./objectDetails/scenes/ObjectDetails";
import { SharedWithMeDashboard } from "./sharedWithMe/scenes/SharedWithMeDashboard";
import { SharedObjectDetails } from "./sharedWithMe/scenes/SharedObjectDetails";
import { MyCollections } from "./MyCollections";
import { MyCollectionObjects } from "./MyCollectionObjects";
import { SearchResults } from "../shared/SearchResults";
import { CollectorProfile } from "../auth/scenes/UserProfile/CollectorProfile";
import { Notifications } from "../auth/scenes/Notifications";
import {CommunityFeed} from "./CommunityFeed";
import {Reporting} from "./Reporting";
import {CustomSnackbar} from "../../components/_others/CustomSnackbar";
import {UtilsContext} from "../../core/context/utils.context";
import {Loader} from "../../components";

export const CollectorApp: React.FC = () => {

    const utilsContext = useContext(UtilsContext);

    return (
        <Box>
            <Box style={{minHeight: '72vh'}}>

                {
                    utilsContext.appLoader && <Loader />
                }

                <Box style={utilsContext.appLoader ? {display: 'none'} : {}}>
                    <PrivateRoute path={AppRoutes.NOTIFICATIONS} component={Notifications} />
                    <PrivateRoute path={AppRoutes.MY_OBJECTS} component={MyObjects} />
                    <PrivateRoute path={AppRoutes.ADD_OBJECT} component={AddObject} />
                    <PrivateRoute path={AppRoutes.OBJECT} component={ObjectDetails} />
                    <PrivateRoute path={AppRoutes.SHARED_WITH_ME} component={SharedWithMeDashboard} />
                    <PrivateRoute path={AppRoutes.SHARED_OBJECT} component={SharedObjectDetails} />
                    <PrivateRoute path={AppRoutes.MY_COLLECTIONS} component={MyCollections} />
                    <PrivateRoute path={AppRoutes.COLLECTION} component={MyCollectionObjects} />
                    <PrivateRoute path={AppRoutes.SEARCH_RESULTS} component={SearchResults} />
                    <PrivateRoute path={AppRoutes.COLLECTOR_PROFILE} component={CollectorProfile} />
                    <PrivateRoute path={AppRoutes.PUBLIC_FEED} component={CommunityFeed} />
                    <PrivateRoute path={AppRoutes.REPORTING} component={Reporting} />
                </Box>

            </Box>

            <ConsumerFooter />

            <CustomSnackbar
                open={utilsContext.snackbar.text !== ''}
                handleClose={() => utilsContext.closeSnackbar()}
                severity={utilsContext.snackbar.severity}
                message={utilsContext.snackbar.text}
            />
        </Box>
    )
}
