import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';

import { AppRoutes } from 'core/helpers';
import { SearchResultsContext } from 'core/context/searchResults';

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        height: 35,
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        backgroundColor: theme.divider.main,
        border: '1px solid',
        borderColor: theme.label.main,
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(3),
            maxWidth: 180,
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.formLabel.main,
    },
    inputRoot: {
        color: theme.formLabel.main,
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '40ch',
        },
        [theme.breakpoints.down('md')]: {
            width: '17ch',
        },
    },
    hideOnSmallScreens: {
        ['@media (max-width:600px)']: { display: 'none !important' },
        ['@media (min-width:600px) and (max-width:1000px)']: { position: 'absolute', right: 55 },
    },
}));

export const Searchbar: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const searchResultsContext = useContext(SearchResultsContext);

    const [searchBy, setSearchBy] = useState('');

    const onSearch = (): void => {
        if (searchBy.length < 4 && searchBy.length !== 0) {
            return;
        }
        searchResultsContext.setSearchBy(searchBy);
        history.push(AppRoutes.SEARCH_RESULTS);
    };

    const handleKeyPress = (e: any): void => {
        if (e.key === 'Enter') {
            onSearch();
            e.preventDefault();
        }
    };

    return (
        <div className={`${classes.search} ${classes.hideOnSmallScreens}`}>
            <div className={classes.searchIcon}>
                <SearchIcon onClick={() => onSearch()} />
            </div>
            <InputBase
                placeholder="Search here"
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={searchBy}
                onChange={(e) => setSearchBy(e.target.value)}
                onKeyPress={(e: any) => handleKeyPress(e)}
            />
        </div>
    );
};
