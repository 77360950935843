import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export function getStylesForCircularProgress(isLightTheme: boolean): any {
    return {
        // Rotation of path and trail, in number of turns (0-1)
        rotation: 0,

        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
        strokeLinecap: 'butt',

        // Text size
        textSize: '23px',

        // How long animation takes to go from one percentage to another, in seconds
        pathTransitionDuration: 0.5,

        // Can specify path transition in more detail, or remove it entirely
        // pathTransition: 'none',

        // Colors
        pathColor: `#FE6502`,
        textColor: isLightTheme ? 'black' : 'white',
        trailColor: isLightTheme ? '#acacac' : '#707070',
        backgroundColor: '#FE6502',
    }
}


export const linearProgressBarStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 5,
        ["@media (max-width:855px)"]: { width: '80%', margin: 'auto'},
    },
    root: {
        height: 8,
        width: 190,
        margin: '0 4px',
        ["@media (max-width:855px)"]: { width: '100%' },
    },
    legend: {
        color: theme.formLabel.main,
        fontSize: 10,
        opacity: 0.8,
    },
    value: {
        position: 'relative',
        top: -13,
        fontSize: 12,
       // ["@media (max-width:855px)"]: { display: 'none' },
    },
    colorPrimary: {
        backgroundColor: theme.rootBackground.progressBarBackground,
    },
    barSuccess: {
        backgroundColor: '#309e16',
    },
    bar: {
        backgroundColor: theme.palette.secondary.main,
    },
    tooltip: {
        textAlign: 'center',
        color: '#fefefe !important',
        backgroundColor: '#474747 !important'
    },
    tooltipArrow: {
        color: '#474747 !important'
    }
}));
