import React from "react";
import {Box, Grid} from "@material-ui/core";

import {mobileMenuStyles} from "../_styles/mobile-menu.styles";
import CloseIcon from "@material-ui/icons/Close";

import {Logo} from "components/_others";

type HeaderProps = {
    handleClose: () => void;
}

export const Header: React.FC<HeaderProps> = ({handleClose}) => {

    const mobileMenuClasses = mobileMenuStyles();

    return (
        <Box className={mobileMenuClasses.headerBox}>
            <Grid container>
                <Grid item xs={6}>
                    <Box className={mobileMenuClasses.logoBox}>
                        <Logo />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box className={mobileMenuClasses.iconBox}>
                        <CloseIcon
                            className={mobileMenuClasses.closeIcon}
                            onClick={handleClose}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
