import React, { useEffect, useState } from 'react';

import { UploadPhoto } from './UploadPhoto';
import { SelectedPhotos } from './SelectedPhotos';
import { IPhoto } from 'core/context/consumer/objectDetails';
import { Typography } from '@material-ui/core';
import { PhotoCropper } from './PhotoCropper';
import { FileSize, getPhotoUrl } from 'core';

export interface PhotoOnCrop {
    id: number;
    photo: string | IPhoto | null;
    existing: boolean | null;
    index?: number;
}

type PhotosProps = {
    initialPhotos?: IPhoto[];
    hasPendingAssessments?: boolean;
    setCurrentPhotos: (photos: any) => void;
    onDelete?: (id: number) => void;
    onRemovePhoto?: (id: number) => void;
};

export const Photos: React.FC<PhotosProps> = ({ setCurrentPhotos, initialPhotos, onDelete }) => {
    const [photos, setPhotos] = useState<Array<string | IPhoto>>(initialPhotos ?? []);
    const [photoOnCrop, setPhotoOnCrop] = useState<PhotoOnCrop>({ id: -1, photo: null, existing: null });

    const addNewPhotos = (newPhotos: string[]): void => {
        setPhotos([...photos, ...newPhotos]);
    };

    useEffect(() => {
        setCurrentPhotos(photos);
    }, [photos]);

    const onDeleteClick = (photo: IPhoto | string, photoIndex: number): void => {
        if (typeof photo === 'string') {
            setPhotos(photos.filter((_p, index) => index !== photoIndex));
        } else {
            setPhotos(
                photos.map((p) => {
                    if ((p as IPhoto).id === photo.id) {
                        return {
                            ...photo,
                            deleted: true,
                        };
                    }
                    return p;
                }),
            );
            if (onDelete) {
                onDelete(photo.id);
            }
        }
    };

    const onCropClick = (photo: IPhoto | string, index?: number): void => {
        if (typeof photo === 'string') {
            setPhotoOnCrop({ id: -1, photo, existing: false, index });
        } else {
            setPhotoOnCrop({
                id: photo.id,
                photo: getPhotoUrl(photo.url, FileSize.SM) ?? '',
                existing: true,
            });
        }
    };

    const onCropCancel = (): void => {
        setPhotoOnCrop({
            id: -1,
            photo: null,
            existing: null,
        });
    };

    const onCropDone = (res: string): void => {
        if (photoOnCrop.index === 0 || photoOnCrop.index) {
            setPhotos(
                photos.map((photo, index) => {
                    if (index === photoOnCrop.index) {
                        return res;
                    }
                    return photo;
                }),
            );
        } else {
            const oldPhoto = photos.filter((photo) => (photo as IPhoto).id === photoOnCrop.id)[0];
            setPhotos([
                ...photos.map((photo) => {
                    if ((photo as IPhoto).id === photoOnCrop.id) {
                        return res;
                    }
                    return photo;
                }),
                { ...(oldPhoto as IPhoto), deleted: true },
            ]);
        }
        setPhotoOnCrop({ id: -1, photo: null, existing: null });
    };

    return (
        <>
            <SelectedPhotos photos={photos} onDeleteClick={onDeleteClick} onCropClick={onCropClick} />
            {photos.length < 25 && !photoOnCrop.photo && (
                <UploadPhoto onUpload={addNewPhotos} photos={photos} />
            )}

            {!!photoOnCrop.photo && (
                <PhotoCropper
                    src={typeof photoOnCrop.photo === 'string' ? photoOnCrop.photo : photoOnCrop.photo.url ?? ''}
                    onCancelClick={onCropCancel}
                    onDoneClick={onCropDone}
                />
            )}

            {photos.length >= 25 && (
                <Typography className="error-message" color={'error'} style={{ marginBottom: 10 }}>
                    You can not upload more than 25 pictures.
                </Typography>
            )}
        </>
    );
};
