import { createContext, useReducer } from 'react';
import {ICuratorListingState} from "./curator-listing.interfaces";
import CuratorListingReducer from "./curator-listing.reducer";

const initialState: ICuratorListingState = {
    curators: [],
    isLoading: true,
    displayEmptyPage: false,
    statistics: {
        totalUsers: 0
    },
    pagination: {
        page: 1,
        size: 20,
        totalCount: 0,
        numberOfPages: 0,
    },
    setCurators: () => null,
    setCurrentPage: () => null,
    setLoading: () => null,
}

export const CuratorListingContext = createContext(initialState);

export const CuratorListingProvider: any = ({children}: any) => {

    const [state, dispatch] = useReducer(CuratorListingReducer, initialState);

    const setCurators = (data: unknown): void => {
        dispatch({
            type: 'SET_CURATORS',
            payload: data
        })
    }

    const setCurrentPage = (page: number): void => {
        dispatch({
            type: 'SET_CURRENT_PAGE',
            payload: page,
        })
    }

    const setLoading = (loading: boolean): void => {
        dispatch({
            type: 'SET_LOADING',
            payload: loading
        });
    }

    return (
        <CuratorListingContext.Provider value={{
            ...state,
            setCurators,
            setCurrentPage,
            setLoading,
        }}>
            {children}
        </CuratorListingContext.Provider>
    )
}
