import React, { useContext, useEffect, useState } from "react";
import {Box, Button, Typography} from "@material-ui/core";

import { ArchivistListingContext } from "core/context/admin/archivist-listing";
import { GalleryTypeEnum } from "components/Gallery/helpers/GalleryTypeEnum";

import { Loader} from "components/_others";
import { Gallery } from "components/Gallery";
import { Searchbox } from "components/_dashboard/Searchbox/Searchbox";
import { UserRoles } from "core/enums";
import { InviteUsersModal } from "components/_dashboard/InviteUsersModal";
import {getCurrentPageFromUrl} from "../../../../../core/helpers/utils";
import {AppRoutes} from "../../../../../core/helpers";
import {useHistory} from "react-router-dom";
import {UtilsContext} from "../../../../../core/context/utils.context";
import {AdminUsersApi} from "../../../../../core/api/admin-users.api";

export const ArchivistListing: React.FC = () => {

    const history = useHistory();

    const archivistListingContext = useContext(ArchivistListingContext);

    const [displayInviteModal, toggleInviteModal] = useState(false);
    const utilsContext = useContext(UtilsContext);
    const [searchBy, setSearchBy] = useState('');

    useEffect(() => {
        const page = getCurrentPageFromUrl();
        archivistListingContext.setCurrentPage(page);
        getArchivists(page, '');
    }, [])

    const getArchivists = (page: number, searchBy: string): void => {
        archivistListingContext.setLoading(true);

        AdminUsersApi.getArchivists(archivistListingContext.pagination.size, page, searchBy)
            .then((res: any) => {
                archivistListingContext.setArchivists(res.data);
                window.scrollTo(0, 0);
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error');
            })
    }

    const handlePageChange = (page: number, searchBy: string): void => {
        history.push(`${AppRoutes.ARCHIVIST_LISTING}?page=${page}`);
        archivistListingContext.setCurrentPage(page);
        getArchivists(page, searchBy);
    }

    return (
        <Box>
            {
                !archivistListingContext.isLoading &&
                <>

                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Box>
                            <Typography variant="h5" color="textPrimary">
                                Archivists
                            </Typography>
                            <Typography variant="h5" color="textSecondary" style={{fontSize: 16}}>
                                {archivistListingContext.statistics.totalUsers}
                            </Typography>
                        </Box>
                        <Box display={'flex'} alignItems={'center'}>
                            <Button
                                id="delete-btn"
                                variant="contained"
                                color="primary"
                                size={"small"}
                                onClick={() => toggleInviteModal(true)}
                            >
                                Invite Archivists
                            </Button>
                            <Searchbox
                                searchBy={searchBy}
                                setSearchBy={(val: string) => setSearchBy(val)}
                                onClear={() => {
                                    setSearchBy('');
                                    handlePageChange(1, '');
                                }}
                                onSearch={(val: string) => val && val.length > 3
                                    ? handlePageChange(1, val)
                                    : handlePageChange(1, '')
                                }
                            />
                        </Box>
                    </Box>

                    <Box marginTop={'15px'}>
                        <Gallery
                            galleryType={GalleryTypeEnum.Archivists}
                            data={archivistListingContext.archivists}
                            pagination={{
                                numberOfPages: archivistListingContext.pagination.numberOfPages,
                                currentPage: archivistListingContext.pagination.page,
                            }}
                            handlePageChange={(page: number) => handlePageChange(page, searchBy)}
                        />
                    </Box>

                    {
                        archivistListingContext.displayEmptyPage &&
                        <Typography variant="h5" color="textSecondary" style={{fontSize: 16}}>
                            This list is empty.
                        </Typography>
                    }

                </>
            }

            {
                archivistListingContext.isLoading &&
                <Loader />
            }

            <InviteUsersModal
                open={displayInviteModal}
                role={UserRoles.ARCHIVIST}
                handleClose={() => toggleInviteModal(false)}
            />
        </Box>
    )
}
