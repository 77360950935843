import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

type SectionHeaderProps = {
    title: string;
}

const useStyles = makeStyles({
    root: {
        fontSize: 23,
        fontWeight: 'bold',
    },
    divider: {
        maxWidth: 55,
        height: 0,
        marginTop: 0,
        borderRadius: 2,
        marginBottom: 15,
        marginLeft: 0,
        borderTop: '1px solid #029BFE',
    }
});


export const SectionHeader: React.FC<SectionHeaderProps> = ({title}) => {

   const classes = useStyles();

    return (
        <>
            <Typography className={classes.root} color={'textPrimary'}>
                {title}
            </Typography>
            <div className={classes.divider} />
        </>

    )
}
