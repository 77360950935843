import moment from "moment";

export function manageTitleError(value: string): string {
    return !value || !value.trim() ? 'Please enter a title for this object.' : '';
}

export function manageDescriptionError(value: string): string {
    return !value || !value.trim() ? 'Please enter a description for this object.' : '';
}

export function managePriceRangesError(min: string, max: string): string {
    if(!min && max) {
        return 'Please enter a minimum price';
    }
    if(min && !max) {
        return 'Please enter a maximum price';
    }
    if(Number(min) >= Number(max)) {
        return 'The maximum price can\'t be lower or equal than the minimum price.'
    }
    return '';
}

export function manageYearRangeError(min: string, max: string): string {
    if(!min && max) {
        return 'Please enter a minimum year';
    }
    if(min && !max) {
        return 'Please enter a maximum year';
    }
    return '';
}

export function manageExactProductionYearError(year: string, notation: string, required = true): string {
    if(!year && !required) {
        return '';
    }
    if(!year || isNaN(Number(year)) || Number(year) <= 0) {
        return 'Please enter a valid year';
    }
    if(year.length > 4 && notation === 'AD') {
        return 'Please enter a valid year';
    }

    if(Number(year) > moment().year() && notation === 'AD') {
        return "Year can't be in the future";
    }
    return '';
}

export function manageMinProductionYearError(
    min: string, max: string, minNotation: string, maxNotation: string
): string {
    if(!min || isNaN(Number(min)) || Number(min) <= 0) {
        return 'Please enter a valid year';
    }
    if(min.length > 5) {
        return 'Please enter a valid year';
    }
    if(Number(min) > moment().year() && minNotation === 'AD') {
        return "Year can't be in the future";
    }
    if(!minIsBeforeMax(min, max, minNotation, maxNotation)) {
        return "Min year can't be after max year";
    }
    return '';
}

export function manageMaxProductionYearError(
    max: string, maxNotation: string
): string {
    if(!max || isNaN(Number(max)) || Number(max) <= 0) {
        return 'Please enter a valid year';
    }
    if(max.length > 5) {
        return 'Please enter a valid year';
    }
    if(Number(max) > moment().year() && maxNotation === 'AD') {
        return "Year can't be in the future";
    }
    return '';
}

function minIsBeforeMax(min: string, max: string, minNotation: string, maxNotation: string): boolean {
    if(minNotation === 'BC' && maxNotation === 'BC') {
        return Number(min) > Number(max);
    }
    if(minNotation === 'AD' && maxNotation === 'AD') {
        return Number(min) < Number(max);
    }
    return !(minNotation === 'AD' && maxNotation === 'BC');
}
