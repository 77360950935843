import { Box, Container } from '@material-ui/core';
import { Gallery } from 'components/Gallery';
import { GalleryTypeEnum } from 'components/Gallery/helpers/GalleryTypeEnum';
import { Loader } from 'components/layout/_components/Loader';
import { CollectorCollectionApi } from 'core/api/consumer/collector-collection.api';
import { CollectionContext } from 'core/context/consumer/collection';
import { SortObjectsOptions } from 'core/enums';
import { AppRoutes, getMyObjectsRoute } from 'core/helpers/app-routes';
import { getCurrentPageFromUrl, getPageSizeFromUrl, getSortPreference } from 'core/helpers/utils';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../../core/context/user';
import { UtilsContext } from '../../../core/context/utils.context';
import { getNewSortingOption } from '../_helpers/objects-sorting.helper';
import { AddObjectToCollectionModal } from './components/AddObjectToCollectionModal';
import { CollectionPageHeader } from './components/CollectionPageHeader';
import { DeleteObjectModal } from './components/DeleteObjectModal';
import { EmptyCollection } from './components/EmptyCollection';

export const MyCollectionObjects: React.FC = () => {
    const userContext = useContext(UserContext);
    const collectionContext = useContext(CollectionContext);
    const history = useHistory();

    const utilsContext = useContext(UtilsContext);
    const [isSharedWithMe, setIsSharedWithMe] = useState(false);
    const [shareCollectionId, setShareCollectionId] = useState(-1);

    const getCollectionArtifactsAsync = async (): Promise<void> => {
        utilsContext.setAppLoaderState(true);
        const params = new URLSearchParams(window.location.search);
        const id = params.get('id');
        const jobId = params.get('jobId');
        const sharedWithMe = params.get('sharedWithMe');

        if (sharedWithMe === 'true') {
            setIsSharedWithMe(true);
        }

        if (!id) {
            history.push(getMyObjectsRoute(userContext.user?.role));
        }
        const page = getCurrentPageFromUrl();
        const pageSize = getPageSizeFromUrl();
        const preferences =
            (await getSortPreference('objects', jobId ? parseInt(jobId) : -1)) || SortObjectsOptions.DateCreated;

        collectionContext.setCurrentPage(page, pageSize);
        collectionContext.setSortBy(preferences.sortPreference, preferences.ascPreference);
        collectionContext.setCollectionId(Number(id));
        collectionContext.modals.toggleDeleteObjectModal(-1);
        await getCollectionArtifacts(
            page,
            Number(id),
            pageSize,
            preferences.sortPreference,
            preferences.ascPreference,
            sharedWithMe === 'true',
        );
        utilsContext.setAppLoaderState(false);
    };

    useEffect(() => {
        void getCollectionArtifactsAsync();
    }, []);

    const getCollectionArtifacts = (
        page: number,
        collectionId: number,
        size: number,
        sortBy: string,
        asc: boolean | undefined,
        sharedWithMe: boolean,
    ): void => {
        collectionContext.setLoading(true);

        CollectorCollectionApi.getCollectionItems(collectionId, page, size, sortBy, asc, sharedWithMe)
            .then((res: any) => {
                if (sharedWithMe) {
                    collectionContext.setArtifacts(res.data);
                    setShareCollectionId(res.data?.id);
                } else {
                    collectionContext.setArtifacts(res.data);
                }
                window.scrollTo(0, 0);
            })
            .catch((error: any) => {
                console.error(error.response);
                history.push(AppRoutes.MY_COLLECTIONS);
                utilsContext.addSnackbar(
                    'error',
                    'Sorry, there was an error and we were unable to display your collection.',
                );
            });
    };

    const handlePageChange = (page: number): void => {
        const { sortBy, size } = collectionContext.pagination;
        const desc = !collectionContext.pagination.asc;
        // eslint-disable-next-line max-len
        history.push(
            `${AppRoutes.COLLECTION}?id=${collectionContext.id}&page=${page}&size=${size}&sortBy=${sortBy}&desc=${desc}`,
        );
        collectionContext.setCurrentPage(page, size);
        getCollectionArtifacts(
            page,
            collectionContext.id,
            size,
            collectionContext.pagination.sortBy || SortObjectsOptions.DateCreated,
            collectionContext.pagination.asc,
            isSharedWithMe,
        );
    };

    const handlePageSizeChange = (size: number): void => {
        const { sortBy, page } = collectionContext.pagination;
        const desc = !collectionContext.pagination.asc;
        // eslint-disable-next-line max-len
        history.push(
            `${AppRoutes.COLLECTION}?id=${collectionContext.id}&page=${page}&size=${size}&sortBy=${sortBy}&desc=${desc}`,
        );
        collectionContext.setCurrentPage(page, size);
        getCollectionArtifacts(
            page,
            collectionContext.id,
            size,
            collectionContext.pagination.sortBy || SortObjectsOptions.DateCreated,
            collectionContext.pagination.asc,
            isSharedWithMe,
        );
    };

    const changeSortByOption = (option: string): void => {
        const newOption = getNewSortingOption(
            collectionContext.pagination.sortBy || '',
            option,
            collectionContext.pagination.asc || false,
        );
        collectionContext.setSortBy(newOption.type, newOption.asc);
        history.push(
            // eslint-disable-next-line max-len
            `${AppRoutes.COLLECTION}?id=${collectionContext.id}&page=${1}&sortBy=${
                newOption.type
            }&desc=${!newOption.asc}
        `,
        );
        getCollectionArtifacts(
            collectionContext.pagination.page,
            collectionContext.id,
            collectionContext.pagination.size,
            newOption.type,
            newOption.asc,
            isSharedWithMe,
        );
    };

    return (
        <Container maxWidth={false} className="my-objects-container">
            {!collectionContext.isLoading && (
                <>
                    <CollectionPageHeader
                        isSharedWithMe={isSharedWithMe}
                        changeSortByOption={(option: string) => changeSortByOption(option)}
                        sortByValue={collectionContext.pagination.sortBy as string}
                    />

                    {!isSharedWithMe && (
                        <Box>
                            <Gallery
                                galleryType={GalleryTypeEnum.Artifacts}
                                data={collectionContext.artifacts}
                                pagination={{
                                    numberOfPages: collectionContext.pagination.numberOfPages,
                                    currentPage: collectionContext.pagination.page,
                                    pageSize: collectionContext.pagination.size,
                                }}
                                handlePageChange={(page: number) => handlePageChange(page)}
                                onObjectDelete={(id: number) => collectionContext.modals.toggleDeleteObjectModal(id)}
                                handlePageSizeChange={(size: number) => handlePageSizeChange(size)}
                            />
                        </Box>
                    )}

                    {isSharedWithMe && (
                        <Box>
                            <Gallery
                                galleryType={GalleryTypeEnum.SharedWithMe}
                                data={collectionContext.artifacts?.map((a) => {
                                    return {
                                        id: a.id,
                                        title: a.title,
                                        description: a.title,
                                        collectorName: `${a.collector?.firstName} ${a.collector?.lastName}`,
                                        collectorPhoto: a.collector?.photoUrl,
                                        sharedFromCollection: true,
                                        shareCollectionId,
                                        photo: {
                                            fileName: a.title,
                                            url: a.photoUrl,
                                        },
                                    };
                                })}
                                pagination={{
                                    numberOfPages: collectionContext.pagination.numberOfPages,
                                    currentPage: collectionContext.pagination.page,
                                    pageSize: collectionContext.pagination.size,
                                }}
                                handlePageChange={(page: number) => handlePageChange(page)}
                                handlePageSizeChange={(size: number) => handlePageSizeChange(size)}
                            />
                        </Box>
                    )}
                </>
            )}
            {!collectionContext.isLoading && collectionContext.displayEmptyPage && <EmptyCollection />}
            {collectionContext.isLoading && <Loader />}

            <AddObjectToCollectionModal
                collectionId={collectionContext.id}
                collectionName={collectionContext.name}
                open={collectionContext.modals.displayAddObjectModal}
                handleClose={() => collectionContext.modals.toggleAddObjectModal()}
            />

            <DeleteObjectModal
                collectionId={collectionContext.id}
                objectId={collectionContext.modals.displayDeleteObjectModal}
                open={collectionContext.modals.displayDeleteObjectModal !== -1}
                handleClose={() => collectionContext.modals.toggleDeleteObjectModal(-1)}
            />
        </Container>
    );
};
