import { useContext, useState } from 'react';
import { usePagination } from 'core/hooks/use-pagination.hook';
import { UtilsContext } from 'core/context/utils.context';
import { IJob } from '../../../types';
import { JobsApi } from 'core/api/jobs.api';
import { UserRoles } from 'core/enums';

export const useAvailableJobsData = (userId: number, jobRole: string, userRole: string, notAssigned: boolean): any => {
    const utilsContext = useContext(UtilsContext);

    const [jobs, setJobs] = useState<IJob[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isEmptyList, setIsEmptyList] = useState(false);

    const { pagination, setPagination } = usePagination();

    const getAvailableJobs = (page: number, searchBy: string, size = 10): void => {
        setIsLoading(true);
        setIsEmptyList(false);

        const body = {
            userId,
            old: false,
            notAssigned,
            search: searchBy !== '' ? searchBy : null,
        };

        JobsApi.getJobs(10, page, userRole, body)
            .then((res: any) => {
                setJobs(mapData(res?.data?.data || [], jobRole));
                setPagination({
                    ...pagination,
                    page,
                    size,
                    totalCount: res.data?.totalCount,
                    numberOfPages: Math.ceil(res.data?.totalCount / res.data?.size),
                });
                setIsLoading(false);
                setIsEmptyList(res.data.totalCount === 0);
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar(
                    'error',
                    'Sorry, there was an error and we were unable to display the active jobs.',
                );
            });
    };

    return {
        jobs,
        getAvailableJobs,
        isLoading,
        isEmptyList,
        pagination,
    };
};

const mapData = (data: any, role: string): IJob[] => {
    return data.map((j: any) => {
        return {
            userPhotoUrl: j.photoUrl,
            userName: `${j.firstName || ''} ${j.lastName || ''}`,
            userEmail: j.email,
            userId: j.userId,
            info: getInfoText(j, role),
        };
    });
};

const getInfoText = (job: any, role: string): string => {
    switch (role) {
        case UserRoles.COLLECTOR:
            return `${job.totalObjects} ${job.totalObjects === 1 ? 'object' : 'objects'}`;
        default:
            return '';
    }
};
