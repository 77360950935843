import React, {useContext} from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";

import {addNewObjectBtnStyles} from "../styles/header.styles";
import AddIcon from '@material-ui/icons/Add';

import {UserContext} from "core/context/user";
import {AppRoutes} from "core/helpers/app-routes";
import {UserRoles} from "core/enums";
import {UtilsContext} from "core/context/utils.context";

export const AddNewObjectBtn: React.FC = () => {

    const userContext = useContext(UserContext);
    const utilsContext = useContext(UtilsContext);

    const history = useHistory();

    const classes = addNewObjectBtnStyles();

    const goToAddObject = (): void => {
        switch(userContext.user?.role) {
            case UserRoles.COLLECTOR:
                history.push(AppRoutes.ADD_OBJECT);
                break;
            case UserRoles.ARCHIVIST:
                history.push(`${AppRoutes.ADD_OBJECT}?jobId=${utilsContext.selectedJob}`);
                break;
            case UserRoles.CURATOR:
                history.push(`${AppRoutes.ADD_OBJECT}?jobId=${utilsContext.selectedJob}`);
                break;
        }
    }

    return (
        <Button
            id="go-to-new-object-btn"
            variant="contained"
            color="primary"
            className={classes.root}
            onClick={() => goToAddObject()}
        >
            <AddIcon className={classes.icon} />
            <span className={classes.hideXS}>Add new object</span>
        </Button>
    )
}
