import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, ThemeOptions, Typography } from "@material-ui/core";

type StatsProps = {
    totalObjects: number;
    totalReviewedObjects: number;
    registerPurpose: string[];
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
    label: {
        fontSize: 16,
        marginBottom: 7,
    },
    value: {
        color: theme.palette?.text?.primary,
    }
}))

export const Stats: React.FC<StatsProps> = ({totalObjects, totalReviewedObjects, registerPurpose}) => {

    const classes = useStyles();

    return (
        <Box>
            <Typography className={classes.label} color="textSecondary" style={{marginBottom: 15}}>
                Register Reason:{' '}
                <span color="textPrimary" className={classes.value}>
                    {registerPurpose.join(' ') || 'unknown'}
                </span>
            </Typography>
            <Typography className={classes.label} color="textSecondary">
                Total Objects:{' '}
                <span color="textPrimary" className={classes.value}><b>{totalObjects} objects</b></span>
            </Typography>

            <Typography className={classes.label} color="textSecondary">
                Total Expert Reviews:{' '}
                <span color="textPrimary" className={classes.value}>{totalReviewedObjects} objects</span>
            </Typography>
        </Box>
    )
}
