import React, {useContext} from "react";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import {Box, List, Popover} from "@material-ui/core";

import {mobileDrawerDarkTheme} from "./theme";
import {mobileMenuStyles} from "../_styles/mobile-menu.styles";
import MenuIcon from "@material-ui/icons/Menu";

import {UserContext} from "../../../core/context/user";
import {getNavLinks} from "../_helpers/dashboard-drawer-nav-links";

import {Header} from "../_components/MobileMenuHeader";
import {MobileMenuNavigation} from "../_components/MobileMenuNavigation";
import {MobileMenuUserActions} from "../_components/MobileMenuUserActions";

export const DashboardMobileDrawer: React.FC = () => {

    const userContext = useContext(UserContext);

    const classes = mobileMenuStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'mobile-menu-popover' : undefined;

    return (
        <ThemeProvider theme={mobileDrawerDarkTheme}>
            <Box className={classes.hamburgerMenu} style={{left: 0}}>
                <MenuIcon
                    className={classes.hamburgerMenuSvg}
                    aria-describedby={id}
                    onClick={handleClick}
                />

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                >
                    <Header handleClose={handleClose} />

                    <Box className={classes.navBox}>
                        <List
                            component="ul"
                            aria-labelledby="main navigation"
                            className={classes.nav}
                        >
                            <MobileMenuNavigation
                                navLinks={getNavLinks(userContext.user?.role || '')}
                                authLinks={[]}
                                onAuthPages={false}
                                onLightTheme={false}
                                handleClose={handleClose}
                            />

                            {
                                userContext.user !== null &&
                                <MobileMenuUserActions
                                    handleClose={handleClose}
                                />
                            }

                        </List>
                    </Box>
                </Popover>
            </Box>
        </ThemeProvider>
    )
}
