import React, { useState } from "react";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ThemeProvider } from "@material-ui/core/styles";

import { lightTheme } from "assets/themes/light-theme";

import AsyncCreatableSelect from 'react-select/async-creatable';

import { CommonApi } from "core/api/shared";
import { ICategory } from "core/context/consumer/objectDetails";

type AddNewLabelProps = {
    type: string;
    onSave: (labels: ICategory[]) => void;
    disableCreate?: boolean;
    isMulti: boolean;
}

const useStyles = makeStyles({
    button: {
        fontSize: 12,
        padding: '0 6px',
    },
    selectBox: {

        marginLeft: 15,
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center'
    },
    select: {
        width: '250px',
    },
    actionBtn: {
        fontSize: 13,
        paddingLeft: 0,
        paddingRight: 0,
        maxWidth: 30
    }
})

const customStyles = {
    menu: (provided: any, _state: any) => ({
        ...provided,
        color: 'black',
        backgroundColor: '#F7F7F7',
    }),
    multiValueRemove: (provided: any, _state: any) => ({
        ...provided,
        color: 'black',
    }),
}

export const AddNewLabel: React.FC<AddNewLabelProps> = ({onSave, disableCreate, type, isMulti}) => {

    const classes = useStyles();

    const [onAddLabel, setOnAddLabel] = useState(false);
    const [selectedLabels, setSelectedLabels] = useState<any>([]);

    const promiseOptions = (inputValue: string): any => {
        return CommonApi.searchTaxonomy(JSON.stringify(inputValue), type)
            .then((res: any) => {
                return res.data.map((el: any) => {
                    return {
                        value: el.id,
                        label: el.name,
                    }
                })
            });
    }

    const handleCancel = (): void => {
        setOnAddLabel(false);
        setSelectedLabels([]);
    }

    const handleSave = (): void => {
        onSave((Array.isArray(selectedLabels) ? selectedLabels : [selectedLabels]).map((l: any) => {
            return {
                id: l.value,
                name: l.label
            }
        }));
        setOnAddLabel(false);
    }

    return (
        <>
            {
                !onAddLabel &&
                <Button
                    id="save-feedback-btn"
                    color="primary"
                    variant={'outlined'}
                    type="button"
                    className={classes.button}
                    onClick={() => setOnAddLabel(true)}
                >
                    Add new label
                </Button>
            }

            {
                onAddLabel &&
                    <Box className={classes.selectBox}>
                        <Box className={classes.select}>
                            <ThemeProvider theme={lightTheme}>
                                <AsyncCreatableSelect
                                    isMulti={isMulti}
                                    isValidNewOption={() => !disableCreate}
                                    allowCreateWhileLoading={false}
                                    placeholder={'Search...'}
                                    loadOptions={promiseOptions}
                                    onChange={(e) => setSelectedLabels(e)}
                                    styles={customStyles}
                                />
                            </ThemeProvider>
                        </Box>

                        <Button
                            id="cancel-add-label-btn"
                            color="primary"
                            type="button"
                            className={classes.actionBtn}
                            onClick={() => handleSave()}
                        >
                            Save
                        </Button>

                        <Button
                            id="cancel-add-label-btn"
                            color="default"
                            type="button"
                            className={classes.actionBtn}
                            onClick={() => handleCancel()}
                        >
                            Cancel
                        </Button>
                    </Box>
            }
        </>
    )
}
