import React, {useContext} from "react";
import {ThemeProvider} from '@material-ui/core/styles'
import {AppBar, Box, Hidden, Toolbar } from "@material-ui/core";

import {logoStyles} from "./styles/styles";
import {responsiveStyles} from "../_styles/responsive-styles";

import {navLinks} from "../_helpers/consumer-toolbar-nav-links";
import {UserContext} from "core/context/user";
import {getHeaderTheme} from "components/layout/_styles/header-theme";
import {hasFullOpacity} from "./utils";

import {Searchbar} from "./components/Searchbar";
import {UserAvatar} from "./components/UserAvatar";
import {MobileMenu} from "../ConsumerMobileMenu";
import {Logo} from "components/_others";
import {FullOpacity} from "./components/FullOpacity";
import {AuthButtons} from "./components/AuthButtons";
import {Navigation} from "./components/Navigation";
import {ThemeSwitch} from "../_components/ThemeSwitch";
import {UserRoles} from "../../../core/enums";

export const ConsumerToolbar: React.FC = () => {

    const userContext = useContext(UserContext);

    const logoClasses = logoStyles();
    const responsiveClasses = responsiveStyles();

    const isLoggedOut = userContext.user === null;
    const fullOpacity = hasFullOpacity(window.location.href) || !isLoggedOut;

    const routes = (userContext.user?.role === UserRoles.ARCHIVIST || userContext.user?.role === UserRoles.CURATOR) && !isLoggedOut ?
        navLinks.helpers : isLoggedOut
            ? navLinks.default
            : navLinks.dashboard;

    return (
        <ThemeProvider theme={getHeaderTheme(userContext.lightTheme)}>
            <AppBar position="sticky">
                <FullOpacity fullOpacity={fullOpacity} />

                <Toolbar disableGutters style={{minHeight: 65}}>
                    <Box className={logoClasses.root}>
                        <Logo />
                    </Box>

                    <Navigation
                        isLoggedOut={isLoggedOut}
                        links={routes}
                    />

                    {
                        isLoggedOut && <AuthButtons />
                    }

                    {
                        !isLoggedOut &&
                        <>
                            {
                                userContext.user?.role === UserRoles.COLLECTOR &&
                                <Searchbar />
                            }

                            <Hidden xsDown>
                                <Box className={responsiveClasses.hideMobile}>
                                    <ThemeSwitch />
                                </Box>
                            </Hidden>

                            <span className={responsiveClasses.hideMobile}>
                                <UserAvatar />
                            </span>
                        </>
                    }

                    <Box className={responsiveClasses.justOnMobile}>
                        <MobileMenu
                            navLinks={routes}
                            authLinks={navLinks.auth}
                            onAuthPages={isLoggedOut}
                        />
                    </Box>

                </Toolbar>
            </AppBar>
        </ThemeProvider>
    )
}
