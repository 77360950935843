import React from "react";
import {Grid} from "@material-ui/core";

import {navLinks} from "../../_helpers/consumer-footer-nav-links";
import {NavigationLinks} from "./NavigationList";
import {INavList} from "../../_helpers/nav-link.interfaces";

export const Navigation: React.FC = () => {

    return (
        <Grid container alignContent={'space-around'} spacing={4}>
            {
                navLinks.map((navLink: INavList) => (
                    <Grid item xs={6} sm={6} md={3} key={navLink.id}>
                        <NavigationLinks key={navLink.id} list={navLink} />
                    </Grid>
                ))
            }
        </Grid>
    )
}
