import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export const helperToolbarClasses = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        paddingLeft: 24,
        paddingRight: 13,
    },
    selectCollectorBox: {
        display: 'flex',
        alignItems: 'center',
    },
    selectCollectorLabel: {
        color: theme.palette.text.primary,
        opacity: 0.8,
        fontSize: 13,
        marginRight: 15,
    },
    selectFormControl: {
        minWidth: 250,
    }
}))

export const navigationClasses = makeStyles((_theme: Theme) => ({
    root: {
        display: 'inline-flex',
        paddingLeft: 24,
    },
}))
