import * as api from "../_utils/api";

const resourceUrl = 'common';

export interface ICurrency {
    id: number;
    code: string;
    name: string;
    namePlural: string;
    symbol: string;
    symbolNative: string;
}

export interface ICountry {
    id: number;
    name: string;
}

export interface ICity {
    id: number;
    name: string;
}

export interface IState {
    id: number;
    name: string;
}

export class CommonApi {

    static async getCategoriesByText(text: string): Promise<any> {
        return api._post(
            `${resourceUrl}/Category/Search?type=Category`,
            text,
            {'Content-Type': "application/json-patch+json"}
        )
    }

    static async searchTaxonomy(searchBy: string, type: string): Promise<any> {
        return api._post(
            `${resourceUrl}/Category/Search?type=${type}`,
            searchBy,
            {'Content-Type': "application/json-patch+json"}
        )
    }

    static async getCurrencies(): Promise<any> {
        return api._get('common/currency');
    }

    static async getCountries(): Promise<any> {
        return api._get('common/country');
    }

    static async getStates(countryId: number): Promise<any> {
        return api._get(`common/state?countryId=${countryId}`);
    }

    static async getCities(stateId: number): Promise<any> {
        return api._get(`common/city?stateId=${stateId}`);
    }

    static async sendContactUsEmail(payload: unknown): Promise<any> {
        return api._post(`common/review`, payload);
    }
}
