import React from "react";
import { Box } from "@material-ui/core";

import { IPhoto } from "core/context/consumer/objectDetails";
import { getPhotoUrl } from "core/helpers";
import { FileSize } from "core/enums";

type PhotoBoxProps = {
    photo: IPhoto;
}

export const PhotoBox: React.FC<PhotoBoxProps> = ({photo}) => {
    return (
        <Box style={{
                width: 100,
                height: 100,
                backgroundColor: 'gray',
                marginBottom: '10px',
                marginRight: 10,
                paddingRight: 20,
            }}
        >
            <img
                src={getPhotoUrl(photo?.url || '', FileSize.SM) || ''}
                alt={photo.id.toString()}
                style={{
                    width: "inherit",
                    height: "inherit",
                    objectFit: "cover",
                }}
            />
        </Box>
    )
}
