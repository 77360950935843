import React, { useState } from "react";
import {Box, Checkbox, FormControlLabel, InputLabel, TextField, ThemeOptions, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

type EntryFieldProps = {
    id: string;
    label: string;
    collectorInput: string|null;
    error: string;
    setError: () => void;
    value: string;
    disabled: boolean;
    setValue: (v: string) => void;
    onBlur: (byCollectorInput: boolean) => void;
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
    root: {
      marginBottom: 20
    },
    labelSm: {
        fontSize: 14,
        marginBottom: 5,
        color: theme.palette?.text?.primary,
    },
    focusLabel: {
        fontSize: 14,
        color: "#FE6502",
        marginBottom: 5,
    },
    errorLabel: {
        fontSize: 14,
        color: "#B32222",
        marginBottom: 5,
    },
    collectorInputLabel: {
        color: theme.palette?.text?.secondary,
        fontSize: 14,
        marginBottom: 5,
    },
    collectorInput: {
        color: theme.palette?.text?.primary,
    }
}));

export const EntryField: React.FC<EntryFieldProps> = (
    {id, label, collectorInput, error, setError, value, setValue, onBlur, disabled}
) => {

    const classes = useStyles();

    const [onFocus, setOnFocus] = useState(false);
    const [accurateStatement, setAccurateStatement] = useState(false);

    return (
        <Box className={classes.root}>
            <InputLabel
                htmlFor={id}
                className={
                    error !== ''
                        ? classes.errorLabel
                        : onFocus  ? classes.focusLabel : classes.labelSm
                }
            >
                {label}
            </InputLabel>

            {
                collectorInput != null && !disabled &&
                <Box display={'flex'} alignItems={'center'}>
                    <Typography className={classes.collectorInputLabel}>
                        Collector's input: <span className={classes.collectorInput}>{collectorInput}</span>
                    </Typography>

                    {
                        collectorInput !== 'no information provided' &&
                            <FormControlLabel
                                style={{marginBottom: 5, marginLeft: 15}}
                                control={
                                    <Checkbox
                                        name="accurate-statement"
                                        color="secondary"
                                        style={{ transform: "scale(0.8)" }}
                                        checked={accurateStatement}
                                        onChange={() => {
                                            if(!accurateStatement) {
                                                setValue(collectorInput);
                                            }
                                            setAccurateStatement(!accurateStatement);
                                        }}
                                        onBlur={() => {setError(); onBlur(accurateStatement)}}
                                    />
                                }
                                label={
                                    <Typography
                                        className="checkbox-label"
                                        color="textSecondary"
                                        style={{marginLeft: -5}}
                                    >
                                        This statement is accurate.
                                    </Typography>
                                }
                            />
                    }
                </Box>
            }

            <TextField
                id={id}
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                disabled={accurateStatement || disabled}
                placeholder={'Type here your statement.'}
                helperText={error}
                error={error !== ''}
                value={value}
                inputProps={{ maxLength: 200 }}
                onChange={(e: any) => setValue(e.target.value)}
                onFocus={() => setOnFocus(true)}
                onBlur={() => {setOnFocus(false); setError(); onBlur(false)}}
            />
        </Box>
    )
}
