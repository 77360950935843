import React, {useContext, useEffect, useState} from "react";
import { modalStyles } from "components/_others/modals/modal-styles";
import "assets/styles/chip-autocomplete.less";
import { Backdrop, Fade, Modal } from "@material-ui/core";

import { CollectorApi } from "core/api/consumer/collector.api";

import { ModalHeader } from "../../../_others/modals/ModalHeader";
import { ObjectHighlights } from "../../../object/ObjectHighlights";
import { Form } from "../Form";
import {UtilsContext} from "../../../../core/context/utils.context";

type ShareObjectModalProps = {
    artifactId: number;
    photo: string|null;
    title: string;
    description: string;
    open: boolean;
    handleClose: () => void;
}

export const ShareObjectToPersonsModal: React.FC<ShareObjectModalProps> = (
    {open, handleClose, artifactId, photo, title, description}
) => {

    const classes = modalStyles();

    const utilsContext = useContext(UtilsContext);

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setErrorMessage('');
    }, [])

    const onShare = (emails: string[], message: string): void => {
        setIsLoading(true);
        setErrorMessage('');

        CollectorApi.shareArtifact(artifactId, emails, message)
            .then(() => {
                setIsLoading(false);
                utilsContext.addSnackbar('success', 'Thank you! This object was shared.');
                handleClose();
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                if(error.response.data.message === 'ShareAlreadyExists') {
                    setErrorMessage('You already shared this artifact with that person.')
                } else {
                    setErrorMessage('Sorry, there was an error. Please try again later.')
                }
            })
    }

    return (
        <Modal
            className={classes.modal}
            style={{alignItems: 'flex-start', top: '20px', bottom: '20px'}}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>

                    <ModalHeader
                        title={'Share this artifact'}
                        handleClose={() => handleClose()}
                    />

                    <ObjectHighlights
                        photo={photo}
                        title={title}
                        description={description}
                    />

                    <Form
                        isLoading={isLoading}
                        errorMessage={errorMessage}
                        onShare={(emails: string[], message: string) => onShare(emails, message)}
                        handleClose={() => handleClose()}
                    />
                </div>
            </Fade>
        </Modal>
    )
}
