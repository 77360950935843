import React, {useContext, useEffect, useState} from "react";
import {modalStyles} from "../../_others/modals/modal-styles";
import {
    Backdrop,
    Box,
    Button,
    Chip,
    CircularProgress,
    Fade,
    InputLabel,
    Modal,
    TextField,
    Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {Autocomplete} from "@material-ui/lab";
import {validateEmail} from "../../../core/helpers";
import ReplyIcon from "@material-ui/icons/Reply";
import {ManageUsersApi} from "../../../core/api/admin/manage-users.api";
import {ModalHeader} from "../../_others/modals/ModalHeader";
import {UtilsContext} from "../../../core/context/utils.context";

type InviteUsersModalProps = {
    open: boolean;
    handleClose: () => void;
    role: string;
}

export const InviteUsersModal: React.FC<InviteUsersModalProps> = ({open, handleClose, role}) => {

    const classes = modalStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [emails, setEmails] = useState<string[]>([]);
    const [currentEmail, setCurrentEmail] = useState('');
    const [message, setMessage] = useState('');
    const utilsContext = useContext(UtilsContext);

    const [alreadyInvitedUsers, setAlreadyInvitedUsers] = useState<string[]>([]);

    useEffect(() => {
        setEmails([]);
        setMessage('');
        setErrorMessage('');
        setAlreadyInvitedUsers([]);
    }, [open])

    const onAddEmail = (values: any): void => {
        const newEmails = values;
        const invalidEmailIndex = newEmails.findIndex((el: string) => !validateEmail(el));
        if(invalidEmailIndex > -1) {
            newEmails.splice(invalidEmailIndex, 1);
        }
        setEmails(newEmails.slice());
        setCurrentEmail('');
    }

    const handleBlur = (): void => {
        if(currentEmail && validateEmail(currentEmail)) {
            const result = emails;
            result.push(currentEmail);
            setEmails(result.slice());
        }
        setCurrentEmail('');
    }

    const onInvite = (): void => {
        setIsLoading(true);
        setErrorMessage('');

        ManageUsersApi.inviteUsers({emails, role, message})
            .then((res: any) => {
                setIsLoading(false);
                utilsContext.addSnackbar('success', 'Success!');
                setAlreadyInvitedUsers(res.data);
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                setErrorMessage('Sorry, there was an error. Please try again later.')
            })
    }

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>

                    <ModalHeader
                        title={`Invite ${role}s`}
                        handleClose={() => handleClose()}
                    />

                    <form id="invite-users-form">
                        <>
                            <InputLabel htmlFor="emails" style={{marginBottom: '5px'}}>
                                Provide one or more email addresses of the people with
                                whom you want to send an invitation to join Artifact.
                            </InputLabel>
                            <Autocomplete
                                multiple
                                id="emails"
                                options={[]}
                                value={emails}
                                freeSolo
                                disableClearable={true}
                                clearOnBlur={true}
                                onChange={(_e, value) => onAddEmail(value)}
                                onInputChange={(_e, value) => setCurrentEmail(value)}
                                onBlur={() => handleBlur()}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            variant="outlined"
                                            label={option}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </>

                        <>
                            <InputLabel htmlFor="message" style={{marginTop: '10px', marginBottom: '5px'}}>
                                Add a short message
                            </InputLabel>
                            <TextField
                                multiline
                                rows={3}
                                rowsMax={10}
                                type="text"
                                id="message"
                                variant="outlined"
                                fullWidth
                                value={message}
                                inputProps={{ maxLength: 1000 }}
                                onChange={(e: any) => setMessage(e.target.value)}
                            />
                        </>
                    </form>

                    {
                        errorMessage &&
                        <Typography className="error-message" color="error">
                            {errorMessage}
                        </Typography>
                    }

                    {
                        alreadyInvitedUsers.length > 0 &&
                            <>
                                <Typography className="error-message" color="error">
                                    We were unable to send the invitation email to the following account(s),
                                    because those are already registered in the system:
                                </Typography>
                                <Typography color={'textPrimary'}>
                                    {alreadyInvitedUsers.join(', ')}
                                </Typography>
                            </>
                    }

                    <Box textAlign={'right'} marginTop={'20px'}>
                        <Button
                            id="cancel-delete-btn"
                            variant="contained"
                            size={"small"}
                            style={{marginRight: '15px'}}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>

                        <Button
                            id="delete-btn"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            className={classes.primaryButton}
                            onClick={() => onInvite()}
                            disabled={!emails.length}
                        >
                            {
                                isLoading && <CircularProgress className="circular-progress sm" />
                            }
                            {
                                !isLoading && <ReplyIcon className="share-icon" />
                            }
                            Invite
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    )
}
