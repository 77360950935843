import { Box } from "@material-ui/core";
import React from "react";

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {TutorialViews} from "../views.enum";

type StepDotsProps = {
    view: number;
    changeView: (view: number) => void
}

export const StepDots: React.FC<StepDotsProps> = ({view, changeView}) => {
    return (
        <Box textAlign={'center'} marginBottom={'20px'}>
            <FiberManualRecordIcon
                style={{
                    marginRight: '10px',
                    cursor: 'pointer',
                    color: view === TutorialViews.HowToOrganize ? '#FE6502' : '#707070'
                }}
                onClick={() => changeView(TutorialViews.HowToOrganize)}
            />
            <FiberManualRecordIcon
                style={{
                    cursor: 'pointer',
                    color: view === TutorialViews.HowToPhotograph ? '#FE6502' : '#707070'
                }}
                onClick={() => changeView(TutorialViews.HowToPhotograph)}
            />
        </Box>
    )
}
