import React from "react";
import { Box, Typography } from "@material-ui/core";

import { TextDivider } from "components";
import { GoogleAuth } from "./GoogleAuth";
import { FacebookAuth } from "./FacebookAuth";
import { AppleAuth } from "./AppleAuth";

type ExternalAuthProps = {
    acceptTerms: boolean;
    onSuccess: (data: any) => void;
}

export const ExternalAuth: React.FC<ExternalAuthProps> = ({acceptTerms, onSuccess}) => {
    return (
        <>
            <Box className="divider-box">
                <TextDivider text="or" />
            </Box>

            <Box>
                <Typography style={{fontSize: 14, marginBottom: 7}}>
                    Continue with:
                </Typography>

                <GoogleAuth
                    acceptTerms={acceptTerms}
                    onSuccess={(data) => onSuccess(data)}
                />

                <FacebookAuth
                    acceptTerms={acceptTerms}
                    onSuccess={(data) => onSuccess(data)}
                />

                <AppleAuth
                    acceptTerms={acceptTerms}
                    onSuccess={(data) => onSuccess(data)}
                />
            </Box>
        </>
    )
}
