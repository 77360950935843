import React, {useContext, useState} from "react";
import {
    Backdrop,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Fade,
    FormControlLabel,
    Modal,
    Typography
} from "@material-ui/core";
import {ModalHeader} from "../../../_others/modals/ModalHeader";
import {modalStyles} from "../../../_others/modals/modal-styles";
import {ObjectHighlights} from "../../../object/ObjectHighlights";
import CloseIcon from "@material-ui/icons/Close";
import ReplyIcon from "@material-ui/icons/Reply";
import {CommunityFeedApi} from "core/api/consumer";
import {UtilsContext} from "../../../../core/context/utils.context";

type ShareObjectToArtifactFeedModalProps = {
    artifactId: number;
    open: boolean;
    handleClose: () => void;
    onShareSuccess: () => void;
    artifact: {
        photo: string|null;
        title: string;
        description: string;
    }
}

export const ShareObjectToPublicFeedModal: React.FC<ShareObjectToArtifactFeedModalProps> = (
    {artifactId, artifact, open, handleClose, onShareSuccess}
) => {

    const classes = modalStyles();

    const [enableOffers, setEnableOffers] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const utilsContext = useContext(UtilsContext);


    const onShare = (): void => {
        setIsLoading(true);
        CommunityFeedApi.shareObjectToCommunityFeed(artifactId, enableOffers)
            .then(() => {
                setIsLoading(false);
                utilsContext.addSnackbar('success', 'Thank you! This object was shared to the Community Feed.');
                handleClose();
                onShareSuccess();
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                let message = 'Sorry, there was an error. Please try again later.';
                if(error?.response?.data?.message === 'ShareAlreadyExists') {
                    message = 'This object is already shared to the community feed.'
                }
                utilsContext.addSnackbar('error', message);
            })
    }

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>

                    <ModalHeader
                        title={'Share to Community Feed'}
                        handleClose={() => handleClose()}
                    />

                    <ObjectHighlights
                        photo={artifact.photo}
                        title={artifact.title}
                        description={artifact.description}
                    />

                    <FormControlLabel
                        style={{marginTop: 15}}
                        control={
                            <Checkbox
                                name="checkedB"
                                color="secondary"
                                checked={enableOffers}
                                onChange={() => setEnableOffers(!enableOffers)}
                            />
                        }
                        label={
                            <Typography className="checkbox-label" color="textPrimary">
                                Check this if you want to receive offers from buyers.
                            </Typography>
                        }
                    />

                    <Box textAlign={'right'} marginTop={'20px'}>
                        <Button
                            id="cancel-share-btn"
                            variant="contained"
                            size={"small"}
                            style={{marginRight: '15px'}}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>

                        <Button
                            id="share-btn"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            className={classes.primaryButton}
                            onClick={() => onShare()}
                        >
                            {
                                isLoading && <CircularProgress className="circular-progress sm" />
                            }
                            {
                                !isLoading && <ReplyIcon className="share-icon" />
                            }
                            Share
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    )
}
