import { Box, Button, makeStyles, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import 'cropperjs/dist/cropper.css';
import React, { useRef, useState } from 'react';
import Cropper from 'react-cropper';
import '../styles/cropper.less';
import '../styles/upload-photos.less';

export const useStyles = makeStyles((_theme) => ({
    cropZone: {
        minHeight: '162px',
        padding: 6,
        borderRadius: '2px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    buttonsZone: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    cropperContainer: {
        marginTop: 30,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginLeft: 10,
        marginRight: 10,
    },
}));

type PhotoCropperProps = {
    src: string;
    onCancelClick: () => void;
    onDoneClick: (res: string) => void;
    hideCancelButton?: boolean;
};

export const PhotoCropper: React.FC<PhotoCropperProps> = ({ src, onCancelClick, onDoneClick, hideCancelButton }) => {
    const [photo, setPhoto] = useState<string>(src);
    const cropperRef = useRef<HTMLImageElement>(null);
    const classes = useStyles();

    const onCrop = (): void => {
        const imageElement: any = cropperRef?.current;
        const cropper: any = imageElement?.cropper;
        setPhoto(cropper.getCroppedCanvas()?.toDataURL());
    };

    const rotate = (angle: number): void => {
        const imageElement: any = cropperRef?.current;
        const cropper: any = imageElement?.cropper;
        cropper.rotate(angle);
    };

    const handleDoneClick = (): void => {
        onDoneClick(photo);
    };

    const handleCancelClick = (): void => {
        onCancelClick();
    };

    return (
        <>
            <Paper className={classes.cropZone}>
                <Box className={classes.cropperContainer}>
                    <Cropper
                        src={src}
                        style={{ maxHeight: 400, width: '100%' }}
                        guides={false}
                        crop={() => onCrop()}
                        ref={cropperRef}
                        background={false}
                        viewMode={2}
                        autoCropArea={1}
                        autoCrop={true}
                        checkOrientation={false}
                        checkCrossOrigin={true}
                        rotatable={true}
                        movable={true}
                    />
                    <Button onClick={() => rotate(-90)}>
                        <RotateLeftIcon />
                    </Button>
                </Box>

                <Box className={classes.buttonsZone}>
                    {/* <Typography className="file-name">{file.name}</Typography> */}
                    <Box>
                        {!hideCancelButton && (
                            <Button id="file-delete" onClick={handleCancelClick}>
                                <CloseIcon /> Cancel
                            </Button>
                        )}
                        <Button id="file-done" onClick={handleDoneClick} color="primary">
                            <DoneIcon /> Done
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </>
    );
};
