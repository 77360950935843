import React from 'react';
import { Box, Grid, Hidden } from '@material-ui/core';

import { fixedHeaderStyles } from 'assets/styles/fixed-header';
import { headerStyles } from 'scenes/consumer/MyObjects/styles/header.styles';

import { SortingOption } from 'core/enums';
import { formatUSD } from 'core/helpers';

import { SortBy } from 'components/_others/SortBy';
import { AddNewObjectBtn } from './AddNewObjectBtn';
import { Statistics } from 'scenes/consumer/_components/Statistics';

type PageHeaderProps = {
    totalCount: number;
    totalValue: number;
    sortingOptions: SortingOption[];
    onSortByChange: (option: string) => void;
    sortByValue: string;
};

export const PageHeader: React.FC<PageHeaderProps> = ({
    totalCount,
    totalValue,
    sortingOptions,
    onSortByChange,
    sortByValue,
}) => {
    const fixedHeaderCss = fixedHeaderStyles();
    const classes = headerStyles();

    return (
        <Box className={`${fixedHeaderCss.root} ${classes.root}`}>
            <Grid container className={classes.gridContainer}>
                <Grid item xs={8} sm={8} md={6}>
                    <Statistics
                        statistics={[
                            { label: 'Total Objects', value: `${totalCount}` },
                            { label: 'Total Value', value: formatUSD(totalValue) },
                        ]}
                    />
                </Grid>
                <Grid item xs={4} sm={4} md={6}>
                    <Box className={classes.actionsContainer}>
                        <Hidden smDown>
                            <span style={{ marginRight: 10 }}>
                                <SortBy options={sortingOptions} onSelect={onSortByChange} sortByValue={sortByValue} />
                            </span>
                        </Hidden>

                        <AddNewObjectBtn />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};
