import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {AppBar, Box, ThemeOptions, Toolbar, Typography} from "@material-ui/core";

import NotificationsIcon from "@material-ui/icons/Notifications";

import { UserContext } from "core/context/user";
import { AppRoutes } from "core/helpers";
import { UserAvatar } from "./UserAvatar";
import { DashboardMobileDrawer } from "../DashboardMobileDrawer";
import {responsiveStyles} from "../_styles/responsive-styles";

const useStyles = makeStyles((theme: ThemeOptions) => ({
    root: {
        backgroundColor: theme.dashboardHeader.main,
        height: 65,
        boxShadow: '0px 3px 10px #00000014;'
    },
    toolbar: {
        justifyContent: 'flex-end',
        padding: '0 35px'
    },
    notificationIcon: {
        color: "#707070",
        marginTop: 7,
        cursor: "pointer"
    }
}))

export const DashboardToolbar: React.FC = () => {

    const classes = useStyles();
    const userContext = useContext(UserContext);

    const generalClasses = responsiveStyles();

    return (
        <AppBar
            position="sticky"
            className={classes.root}
        >
            <Toolbar disableGutters className={classes.toolbar}>

                <Box className={generalClasses.justOnMobile}>
                   <DashboardMobileDrawer />
                </Box>

                <Link to={AppRoutes.DASHBOARD_NOTIFICATIONS}>
                    <Typography className="menu-item">
                        <NotificationsIcon className={classes.notificationIcon} />
                        {
                            userContext.newNotifications && <span className="new-notifications" />
                        }
                    </Typography>
                </Link>

                <UserAvatar />

            </Toolbar>
        </AppBar>
    )
}
