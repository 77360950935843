import React, {useContext, useEffect, useState} from "react";
import { Box, InputLabel, TextField, ThemeOptions, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { IArtifact, ICategory } from "core/context/consumer/objectDetails";
import { CommonApi } from "core/api/shared";
import { FeedbackRequestsApi } from "core/api/feedback-requests/feedback-requests.api";
import {FeedbackDraft, IFeedbackResult} from "core/context/feedback-requests/feedback";

import { SectionTitle } from "./SectionTitle";
import { InfoText } from "components/_others/InfoText";
import { EntryField } from "./EntryField";
import { GiveFeedbackActions } from "../GiveFeedbackActions";
import { ApproximateAge } from "./ApproximateAge";
import { ApproximateValue } from "./ApproximateValue";
import { Labels } from "./Labels";
import {useInterval} from "../../../../../../../core/hooks/use-interval.hook";
import {UserContext} from "../../../../../../../core/context/user";
import {UserRoles} from "../../../../../../../core/enums";

type FeedbackFormProps = {
    id: number;
    artifact: IArtifact;
    feedbackResult: IFeedbackResult|null;
    feedbackDraft: FeedbackDraft|null;
    onCancel: () => void;
    onSuccess: () => void;
    onError: () => void;
    isCompleted: boolean;
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
    root: {
        margin: 'auto'
    },
    divider: {
        width: 55,
        height: 1,
        backgroundColor: "#707070",
        marginTop: 15,
        marginBottom: 25,
    },
    labelSm: {
        fontSize: 14,
        marginBottom: 5,
    },
    labelXs: {
        fontSize: 11
    },
    focusLabel: {
        fontSize: 14,
        color: "#FE6502",
        marginBottom: 5,
    },
    errorLabel: {
        fontSize: 14,
        color: "#B32222",
        marginBottom: 5,
    },
    actionsBox: {
        position: 'sticky',
        top: 65,
        paddingTop: 3,
        paddingBottom: 3,
        zIndex: 1,
        backgroundColor: theme.rootBackground.main
    }
}));

export const FeedbackForm: React.FC<FeedbackFormProps> = (
    {id, artifact, feedbackResult,feedbackDraft, onCancel, onSuccess, onError, isCompleted}
) => {

    const classes = useStyles();
    const userContext = useContext(UserContext)

    const [onFocus, setOnFocus] = useState('');
    const [saveAttempt, setSaveAttempt] = useState(false);

    const [errors, setErrors] = useState({
        expertStatement: '',
        comments: '',
        category: '',
        material: '',
        style: '',
        origin: '',
        condition: '',
        artist: '',
        ageInvalid: true,
        valueInvalid: true,
    });
    const [values, setValues] = useState({
        expertStatement: '',
        comments: '',
        category: '',
        material: '',
        style: '',
        origin: '',
        condition: '',
        artist: '',
        artistBio: '',
        exactProductionYear: '',
        lowerProductionYear: '',
        upperProductionYear: '',
        exactValue: '',
        minimumValue: '',
        maximumValue: '',
    });

    const [categories, setCategories] = useState<ICategory[]>([]);
    const [materials, setMaterials] = useState<ICategory[]>([]);
    const [styles, setStyles] = useState<ICategory[]>([]);
    const [origins, setOrigins] = useState<ICategory[]>([]);
    const [conditions, setConditions] = useState<ICategory[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isSaveDraftLoading, setSaveDraftLoading] = useState(false);

    useInterval(() => {
        if(feedbackDraft !== null && !isCompleted) {
            console.log("Saving draft...")
            onSaveDraft();
        }
    }, 120000);

    useEffect(() => {
        if(feedbackDraft !== null && !isCompleted && userContext.user?.role === UserRoles.EXPERT) {
            initDraft();
            return;
        }

        if(!feedbackResult) {
            return;
        }

        setValues({
            expertStatement: feedbackResult.statement,
            comments: feedbackResult.comment,
            category: feedbackResult.category,
            material: feedbackResult.material,
            style: feedbackResult.style,
            origin: feedbackResult.origin,
            condition: feedbackResult.condition,
            artist: feedbackResult.artist,
            artistBio: feedbackResult.artistBio,
            exactProductionYear: feedbackResult.exactProductionYear !== null
                ? feedbackResult.exactProductionYear.toString()
                : '',
            lowerProductionYear: feedbackResult.lowerProductionYear !== null
                ? feedbackResult.lowerProductionYear.toString()
                : '',
            upperProductionYear: feedbackResult.upperProductionYear !== null
                ? feedbackResult.upperProductionYear.toString()
                : '',
            exactValue: feedbackResult.exactValue !== null
                ? feedbackResult.exactValue.toString()
                : '',
            minimumValue: feedbackResult.minimumValue !== null
                ? feedbackResult.minimumValue.toString()
                : '',
            maximumValue: feedbackResult.maximumValue !== null
                ? feedbackResult.maximumValue.toString()
                : '',
        });

        setCategories(feedbackResult.categories);
        setMaterials(feedbackResult.materials);
        setStyles(feedbackResult.styles);
        setOrigins(feedbackResult.origins);
        setConditions(feedbackResult.conditions);


    }, [])

    const initDraft = (): void => {

        if(!feedbackDraft) {
            return;
        }

        setValues({
            expertStatement: feedbackDraft.statement,
            comments: feedbackDraft.comment,
            category: feedbackDraft.category,
            material: feedbackDraft.material,
            style: feedbackDraft.style,
            origin: feedbackDraft.origin,
            condition: feedbackDraft.condition,
            artist: feedbackDraft.artist,
            artistBio: feedbackDraft.artistBio,
            exactProductionYear: feedbackDraft.exactProductionYear !== null
                ? feedbackDraft.exactProductionYear.toString()
                : '',
            lowerProductionYear: feedbackDraft.lowerProductionYear !== null
                ? feedbackDraft.lowerProductionYear.toString()
                : '',
            upperProductionYear: feedbackDraft.upperProductionYear !== null
                ? feedbackDraft.upperProductionYear.toString()
                : '',
            exactValue: feedbackDraft.exactValue !== null
                ? feedbackDraft.exactValue.toString()
                : '',
            minimumValue: feedbackDraft.minimumValue !== null
                ? feedbackDraft.minimumValue.toString()
                : '',
            maximumValue: feedbackDraft.maximumValue !== null
                ? feedbackDraft.maximumValue.toString()
                : '',
        });

        setErrors({...errors,
            ageInvalid: feedbackDraft.exactProductionYear === null && feedbackDraft.lowerProductionYear === null,
            valueInvalid: feedbackDraft.exactValue === null && feedbackDraft.minimumValue === null,
        });

        if(feedbackDraft.category) {
            searchForLabels('Category', feedbackDraft.category)
        }

        if(feedbackDraft.material) {
            searchForLabels('Material', feedbackDraft.material)
        }

        if(feedbackDraft.style) {
            searchForLabels('Style', feedbackDraft.style)
        }

        if(feedbackDraft.origin) {
            searchForLabels('Origin', feedbackDraft.origin)
        }

        if(feedbackDraft.condition) {
            searchForLabels('Condition', feedbackDraft.condition)
        }
    }

    const manageErrors = (field: null|string = null): any => {
        const err = {
            ...errors,
            expertStatement: (field === null || field === 'expertStatement') && !values.expertStatement.trim().length
                ? 'This field is required.' : '',
            comments: (field === null || field === 'comments') &&  !values.comments.trim().length
                ? 'This field is required.' : '',
            category:  (field === null || field === 'category') && !values.category.trim().length
                ? 'This field is required.' : '',
            material:  (field === null || field === 'material') && !values.material.trim().length
                ? 'This field is required.' : '',
            style:  (field === null || field === 'style') && !values.style.trim().length
                ? 'This field is required.' : '',
            origin:  (field === null || field === 'origin') && !values.origin.trim().length
                ? 'This field is required.' : '',
            condition:  (field === null || field === 'condition') && !values.condition.trim().length
                ? 'This field is required.' : '',
            artist:  (field === null || field === 'artist') && !values.artist.trim().length
                ? 'This field is required.' : '',
        }
        setErrors(err);
        return err;
    }

    const onSaveFeedback = (): void => {
        setSaveAttempt(true);

        const err = manageErrors();
        const hasErrors = Object.keys(err).map(k => err[k]).findIndex(el => el !== '' && el !== false) > -1;

        if(hasErrors) {
            return;
        }

        setIsLoading(true);

        const payload = {
            statement: values.expertStatement,
            comment: values.comments,
            category: values.category,
            origin: values.origin,
            material: values.material,
            style: values.style,
            condition: values.condition,
            artist: values.artist,
            artistBio: values.artistBio,
            lowerProductionYear: values.lowerProductionYear,
            upperProductionYear: values.upperProductionYear,
            exactProductionYear: values.exactProductionYear,
            minimumValue: values.minimumValue,
            maximumValue: values.maximumValue,
            exactValue: values.exactValue,
            categories: categories.map(c => c.name),
            conditions: conditions.map(c => c.name),
            origins: origins.map(c => c.name),
            materials: materials.map(c => c.name),
            styles: styles.map(c => c.name),
        }

        FeedbackRequestsApi.saveFeedbackRequest(id, payload)
            .then(() => {
                onSuccess();
               setIsLoading(false);
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                onError();
            })
    }

    const onSaveDraft = (): void => {
        setSaveDraftLoading(true);
        const payload = {
            statement: values.expertStatement,
            comment: values.comments,
            category: values.category,
            origin: values.origin,
            material: values.material,
            style: values.style,
            condition: values.condition,
            artist: values.artist,
            artistBio: values.artistBio,
            lowerProductionYear: values.lowerProductionYear,
            upperProductionYear: values.upperProductionYear,
            exactProductionYear: values.exactProductionYear,
            minimumValue: values.minimumValue,
            maximumValue: values.maximumValue,
            exactValue: values.exactValue,
        }

        FeedbackRequestsApi.saveFeedbackDraft(id, payload)
            .then(() => {
                setSaveDraftLoading(false);
            })
            .catch((_error: any) => {
                setSaveDraftLoading(false);
            })
    }

    const searchForLabels = (type: string, value: string): void => {
        CommonApi.searchTaxonomy(JSON.stringify(value), type)
            .then((res: any) => {
                if(type === 'Category') {
                    setCategories(res.data);
                    return;
                }
                if(type === 'Material') {
                    setMaterials(res.data);
                    return;
                }
                if(type === 'Style') {
                    setStyles(res.data);
                    return;
                }
                if(type === 'Origin') {
                    setOrigins(res.data);
                    return;
                }
                if(type === 'Condition') {
                    setConditions(res.data);
                }
            })
            .catch((error: any) => {
                console.error(error.response);
            })
    }

    return (
        <form id="feedback-form">

            {
                !feedbackResult &&
                <Box textAlign={'right'} marginBottom={'5px'} className={classes.actionsBox}>
                    <GiveFeedbackActions
                        onCancel={() => onCancel()}
                        onSaveFeedback={() => onSaveFeedback()}
                        onSaveDraft={() => onSaveDraft()}
                        isSaveDraftLoading={isSaveDraftLoading}
                        isLoading={isLoading}
                    />
                </Box>
            }

            <>
                <InputLabel
                    htmlFor="expertStatement"
                    className={
                        errors.expertStatement !== ''
                            ? classes.errorLabel
                            : onFocus === 'expertStatement' ? classes.focusLabel : classes.labelSm
                    }
                >
                    Statement from Expert:
                </InputLabel>
                <TextField
                    multiline
                    rows={2}
                    rowsMax={10}
                    id="expertStatement"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    inputProps={{ maxLength: 6000 }}
                    disabled={feedbackResult !== null}
                    placeholder={'Type here if you have any message or thought for the collector.'}
                    helperText={errors.expertStatement}
                    error={errors.expertStatement !== ''}
                    value={values.expertStatement}
                    onChange={(e: any) => setValues({...values, expertStatement: e.target.value})}
                    onFocus={() => setOnFocus('expertStatement')}
                    onBlur={() => {setOnFocus(''); manageErrors('expertStatement')}}
                />
            </>

            <Box marginTop={'30px'}>
                <SectionTitle title={'About the object'} />
                <Box marginTop={'10px'} marginBottom={'20px'}>
                    <InfoText text={
                        'You will find in this form values input by the collector,' +
                        ' if you agree with it select the check box, if not and your estimate.'}
                    />
                </Box>
            </Box>

            <EntryField
                id={'category'}
                label={'What is this Object?'}
                disabled={feedbackResult !== null}
                collectorInput={null}
                error={errors.category}
                setError={() => manageErrors('category')}
                value={values.category}
                setValue={(v: string) => setValues({...values, category: v})}
                onBlur={(byCollectorInput) => {
                    if(byCollectorInput) {
                        setCategories(artifact.categories);
                        return;
                    }
                    searchForLabels('Category', values.category)
                }}
            />

            <ApproximateAge
                id={'approximate-age'}
                disabled={feedbackResult !== null}
                label={'What is the approximate age?'}
                data={{
                    productionYear: feedbackResult
                        ? feedbackResult.exactProductionYear : artifact.exactProductionYear,
                    lowerProductionYear: feedbackResult
                        ? feedbackResult.lowerProductionYear : artifact.lowerProductionYear,
                    upperProductionYear: feedbackResult
                        ? feedbackResult.upperProductionYear :  artifact.upperProductionYear,
                }}
                draftData={feedbackDraft ? {
                    productionYear: feedbackDraft
                        ? feedbackDraft.exactProductionYear : null,
                    lowerProductionYear: feedbackDraft
                        ? feedbackDraft.lowerProductionYear : null,
                    upperProductionYear: feedbackDraft
                        ? feedbackDraft.upperProductionYear : null,
                } : null}
                setError={(hasError: boolean) => setErrors({...errors, ageInvalid: hasError})}
                setValue={(v: any) => setValues({
                    ...values,
                    exactProductionYear: v.exactProductionYear,
                    lowerProductionYear: v.lowerProductionYear,
                    upperProductionYear: v.upperProductionYear,
                })}
            />

            {
                (!values.exactProductionYear && !values.lowerProductionYear) && saveAttempt &&
                    <Typography
                        className={'error-message'}
                        color={'error'}
                        style={{marginBottom: 20, marginTop: -20}}
                    >
                        The approximate age field is required.
                    </Typography>
            }

            <EntryField
                id={'material'}
                label={'What is the Material?'}
                disabled={feedbackResult !== null}
                collectorInput={artifact.material || 'no information provided'}
                error={errors.material}
                setError={() => manageErrors('material')}
                value={values.material}
                setValue={(v: string) => setValues({...values, material: v})}
                onBlur={(byCollectorInput) => {
                    if(byCollectorInput) {
                        setMaterials(artifact.materials);
                        return;
                    }
                    searchForLabels('Material', values.material)
                }}
            />

            <EntryField
                id={'style'}
                label={'What is the Style?'}
                disabled={feedbackResult !== null}
                collectorInput={artifact.style || 'no information provided'}
                error={errors.style}
                setError={() => manageErrors('style')}
                value={values.style}
                setValue={(v: string) => setValues({...values, style: v})}
                onBlur={(byCollectorInput) => {
                    if(byCollectorInput) {
                        setStyles(artifact.styles);
                        return;
                    }
                    searchForLabels('Style', values.style)
                }}
            />

            <EntryField
                id={'origin'}
                label={'What is the Origin?'}
                disabled={feedbackResult !== null}
                collectorInput={artifact.origin || 'no information provided'}
                error={errors.origin}
                setError={() => manageErrors('origin')}
                value={values.origin}
                setValue={(v: string) => setValues({...values, origin: v})}
                onBlur={(byCollectorInput) => {
                    if(byCollectorInput) {
                        setOrigins(artifact.origins);
                        return;
                    }
                    searchForLabels('Origin', values.origin)
                }}
            />

            <EntryField
                id={'condition'}
                label={'What is the Condition?'}
                disabled={feedbackResult !== null}
                collectorInput={artifact.condition || 'no information provided'}
                error={errors.condition}
                setError={() => manageErrors('condition')}
                value={values.condition}
                setValue={(v: string) => setValues({...values, condition: v})}
                onBlur={(byCollectorInput) => {
                    if(byCollectorInput) {
                        setConditions(artifact.conditions);
                        return;
                    }
                    searchForLabels('Condition', values.condition)
                }}
            />

            <EntryField
                id={'artist'}
                label={'Who is the Artist or Maker?'}
                disabled={feedbackResult !== null}
                collectorInput={artifact.artist || 'no information provided'}
                error={errors.artist}
                setError={() => manageErrors('artist')}
                value={values.artist}
                setValue={(v: string) => setValues({...values, artist: v})}
                onBlur={() => null}
            />

            <>
                <InputLabel
                    htmlFor="artistBio"
                    className={
                        onFocus === 'artistBio' ? classes.focusLabel : classes.labelSm
                    }
                >
                    Artist Bio (optional)
                </InputLabel>
                <TextField
                    multiline
                    rows={2}
                    rowsMax={10}
                    id="artistBio"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    inputProps={{ maxLength: 5000 }}
                    disabled={feedbackResult !== null}
                    value={values.artistBio}
                    onChange={(e: any) => setValues({...values, artistBio: e.target.value})}
                    onFocus={() => setOnFocus('artistBio')}
                    onBlur={() => {setOnFocus('')}}
                />
            </>

            <Box marginTop={'30px'} marginBottom={'20px'}>
                <SectionTitle title={'Value'} />
            </Box>

            <ApproximateValue
                id={'approximate-value'}
                label={'What is the approximate value?'}
                disabled={feedbackResult !== null}
                data={{
                    exactValue:  feedbackResult
                        ? feedbackResult.exactValue : artifact.exactValue,
                    minimumValue:  feedbackResult
                        ? feedbackResult.minimumValue : artifact.minimumValue,
                    maximumValue:  feedbackResult
                        ? feedbackResult.maximumValue : artifact.maximumValue,
                }}
                draftData={feedbackDraft ? {
                    exactValue: feedbackDraft
                        ? feedbackDraft.exactValue : null,
                    minimumValue: feedbackDraft
                        ? feedbackDraft.minimumValue : null,
                    maximumValue: feedbackDraft
                        ? feedbackDraft.maximumValue : null,
                } : null}
                setError={(hasError: boolean) => setErrors({...errors, valueInvalid: hasError})}
                setValue={(v: any) => setValues({
                    ...values,
                    exactValue: v.exactValue,
                    minimumValue: v.minimumValue,
                    maximumValue: v.maximumValue,
                })}
            />

            {
                ((!values.exactValue && !values.minimumValue) && saveAttempt) || (errors.valueInvalid && saveAttempt) &&
                <Typography
                    className={'error-message'}
                    color={'error'}
                    style={{marginBottom: 20, marginTop: -20}}
                >
                    The estimated value field is required.
                </Typography>
            }

            <Box marginTop={'30px'} marginBottom={'20px'}>
                <SectionTitle title={'Labels'} />
            </Box>

            <Labels
                disabled={feedbackResult !== null}
                categories={categories}
                materials={materials}
                styles={styles}
                origins={origins}
                conditions={conditions}
                setNewCategories={(c) => setCategories(c)}
                setNewMaterials={(c) => setMaterials(c)}
                setNewStyles={(c) => setStyles(c)}
                setNewOrigins={(c) => setOrigins(c)}
                setNewConditions={(c) => setConditions(c)}
            />

            <Box marginTop={'30px'} marginBottom={'20px'}>
                <SectionTitle title={'Other'} />
            </Box>

            <>
                <InputLabel
                    htmlFor="comments"
                    className={
                        errors.comments !== ''
                            ? classes.errorLabel
                            : onFocus === 'expertStatement' ? classes.focusLabel : classes.labelSm
                    }
                >
                    Personal comments and recommendations:
                </InputLabel>
                <TextField
                    multiline
                    rows={2}
                    rowsMax={10}
                    id="comments"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    inputProps={{ maxLength: 5000 }}
                    disabled={feedbackResult !== null}
                    placeholder={'Add your recommendations or comments regarding this object.'}
                    helperText={errors.comments}
                    error={errors.comments !== ''}
                    value={values.comments}
                    onChange={(e: any) => setValues({...values, comments: e.target.value})}
                    onFocus={() => setOnFocus('comments')}
                    onBlur={() => {setOnFocus(''); manageErrors('comments')}}
                />
            </>

        </form>
    )
}
