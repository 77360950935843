import React from "react";
import {Box, Checkbox, FormControlLabel, Typography} from "@material-ui/core";
import {ExpertsSearchType} from "core/enums";

type SearchTypeProps = {
    searchType: string;
    onChange: (type: string) => void;
}

export const SearchType: React.FC<SearchTypeProps> = ({searchType, onChange}) => {

    return (
        <Box>
            <FormControlLabel
                control={
                    <Checkbox
                        name="type"
                        color="secondary"
                        checked={searchType === ExpertsSearchType.Type}
                        onChange={() => onChange(ExpertsSearchType.Type)}
                    />
                }
                label={
                    <Typography className="checkbox-label" color="textPrimary">
                        Type
                    </Typography>
                }
            />
            <FormControlLabel
                control={
                    <Checkbox
                        name="location"
                        color="secondary"
                        checked={searchType === ExpertsSearchType.Location}
                        onChange={() => onChange(ExpertsSearchType.Location)}
                    />
                }
                label={
                    <Typography className="checkbox-label" color="textPrimary">
                        Location
                    </Typography>
                }
            />
            <FormControlLabel
                control={
                    <Checkbox
                        name="expertise"
                        color="secondary"
                        checked={searchType === ExpertsSearchType.Expertise}
                        onChange={() => onChange(ExpertsSearchType.Expertise)}
                    />
                }
                label={
                    <Typography className="checkbox-label" color="textPrimary">
                        Expertise
                    </Typography>
                }
            />
        </Box>
    )
}
