import * as api from '../_utils/api';

export class ExpertsApi {

    static async getExpertProfile(expertId: number): Promise<any> {
        return api._get(`experts/get/${expertId}`);
    }

    static async getExpertCompletedFeedbacks(expertId: number, size: number, page: number): Promise<any> {
        return api._post(
            `feedback/getFeedbackRequests?page=${page}&size=${size}`, {
                isCompleted: true,
                userId: expertId,
            }
        );
    }

    static async updateExpertType(expertId: number, type: string): Promise<any> {
        return api._put(`experts/update/${expertId}`, {type});
    }

    static async assignLabelsToExpert(expertId: number, labels: number[]): Promise<any> {
        return api._post(`label/addLabelToExpert/${expertId}`, labels);
    }

    static async deleteExpertLabel(expertId: number, labelId: number): Promise<any> {
        return api._delete(`label/removeLabel/${labelId}/fromExpert/${expertId}`);
    }
}
