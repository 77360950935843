export function getCustomerSatisfactionColor(rating: number): string {
    if(rating >= 96) {
        return '#3adc3a';
    }
    if(rating >= 86) {
        return '#158415';
    }
    if(rating >= 70) {
        return '#717171';
    }
    if(rating >= 55) {
        return '#a52a2a';
    }
    return '#ff0000';
}

export function getCustomerSatisfactionText(rating: number): string {
    if(rating >= 96) {
        return 'Highest Satisfaction';
    }
    if(rating >= 86) {
        return 'Very Satisfied Customer';
    }
    if(rating >= 70) {
        return 'Ok, could be better';
    }
    if(rating >= 55) {
        return 'Needs improvement';
    }
    return 'There is an issue';
}
