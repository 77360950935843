import React, {useContext} from "react";
import {Box, List, Popover} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import {mobileMenuStyles} from "../_styles/mobile-menu.styles";

import {UserContext} from "core/context/user";

import {Header} from "../_components/MobileMenuHeader";
import {MobileMenuNavigation} from "../_components/MobileMenuNavigation";
import {MobileMenuUserActions} from "../_components/MobileMenuUserActions";
import {INavLink} from "../_helpers/nav-link.interfaces";

type MobileMenuProps = {
    navLinks: INavLink[],
    authLinks: INavLink[],
    onAuthPages: boolean;
}

export const MobileMenu: React.FC<MobileMenuProps> = ({navLinks, authLinks, onAuthPages}) => {

    const userContext = useContext(UserContext);

    const mobileMenuClasses = mobileMenuStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'mobile-menu-popover' : undefined;

    return (
        <Box className={mobileMenuClasses.hamburgerMenu}>
            <MenuIcon
                className={mobileMenuClasses.hamburgerMenuSvg}
                aria-describedby={id}
                onClick={handleClick}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <Header handleClose={handleClose} />

                <Box className={mobileMenuClasses.navBox}>
                    <List component="ul" aria-labelledby="main navigation" className={mobileMenuClasses.nav}>
                        <MobileMenuNavigation
                            navLinks={navLinks}
                            authLinks={authLinks}
                            onAuthPages={onAuthPages}
                            onLightTheme={userContext.lightTheme}
                            handleClose={handleClose}
                        />

                        {
                            userContext.user !== null &&
                               <MobileMenuUserActions
                                    handleClose={handleClose}
                               />
                        }
                    </List>
                </Box>
            </Popover>
        </Box>

    )
}
