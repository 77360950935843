import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Box, ThemeOptions } from '@material-ui/core';

import { AppRoutes } from 'core/helpers';
import { CollectorsApi } from 'core/api/admin';
import { CollectorProfileContext } from 'core/context/admin/collector-profile';

import { BackNavigation, Loader } from 'components/_others';
import { Lightbox } from './components/Lightbox';
import { Stats } from './components/Stats';
import { Bio } from './components/Bio';
import { ActiveRequests } from './components/ActiveRequests';
import { ArtifactsList } from './components/ArtifactsList';
import { ChangeUserRole } from '../../../../../components/_dashboard/_user-profiles/ChangeUserRole';
import { UserRoles } from '../../../../../core/enums';
import { PaymentFreePas } from './components/PaymentFreePass';
import { UtilsContext } from '../../../../../core/context/utils.context';
import { Navigation } from './components/Navigation';
import { NavigationEnum } from './enums';
import { AssignedUsersList } from '../../../../../components/jobs/AssignedUsersList';
import { DeletedObjectsList } from './components/DeletedObjectsList';

const useStyles = makeStyles((theme: ThemeOptions) => ({
    root: {
        border: '1px solid',
        borderColor: theme.divider.main,
        marginTop: 20,
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px',
    },
    profileBox: {
        width: '46%',
    },
    artifactsBox: {
        width: '49%',
    },
    divider: {
        width: '40%',
        borderTop: '1px solid',
        borderColor: theme.divider.main,
        margin: '20px 0',
    },
    stats: {},
}));

export const CollectorProfile: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const collectorProfileContext = useContext(CollectorProfileContext);

    const [isLoading, setIsLoading] = useState(true);
    const utilsContext = useContext(UtilsContext);

    const [activeView, setActiveView] = useState(NavigationEnum.OBJECTS);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('id');

        if (!id) {
            history.push(AppRoutes.COLLECTOR_LISTING);
        }

        CollectorsApi.getCollectorProfile(Number(id))
            .then((res: any) => {
                collectorProfileContext.setData(res.data);
                window.scrollTo(0, 0);
                setIsLoading(false);
            })
            .catch((error: any) => {
                console.error(error.response);
                history.push(AppRoutes.COLLECTOR_LISTING);
                utilsContext.addSnackbar('error');
            });
    }, []);

    return (
        <Box>
            {!isLoading && (
                <>
                    <BackNavigation />

                    <Box className={classes.root}>
                        <Box className={classes.profileBox}>
                            <Box>
                                <Lightbox user={collectorProfileContext.data} />

                                <div className={classes.divider} />

                                <ChangeUserRole currentRole={UserRoles.COLLECTOR} user={collectorProfileContext.data} />

                                <Box marginTop={'10px'}>
                                    <PaymentFreePas
                                        userId={collectorProfileContext.data.userId}
                                        paymentFreePass={collectorProfileContext.data.paymentFreePass}
                                    />
                                </Box>

                                <div className={classes.divider} />

                                <Stats
                                    totalObjects={collectorProfileContext.data.totalObjects}
                                    totalReviewedObjects={collectorProfileContext.data.totalReviewedObjects}
                                    registerPurpose={collectorProfileContext.data.registerPurpose}
                                />

                                <div className={classes.divider} />

                                <Bio user={collectorProfileContext.data} />

                                <div className={classes.divider} />

                                <ActiveRequests activeRequests={collectorProfileContext.activeFeedbackRequests} />
                            </Box>
                        </Box>

                        <Box className={classes.artifactsBox}>
                            <Navigation activeView={activeView} onChange={(view: number) => setActiveView(view)} />
                            {activeView === NavigationEnum.OBJECTS && (
                                <ArtifactsList userId={collectorProfileContext.data.userId} />
                            )}
                            {activeView === NavigationEnum.DELETED_OBJECTS && (
                                <DeletedObjectsList userId={collectorProfileContext.data.userId} />
                            )}
                            {activeView === NavigationEnum.JOBS && (
                                <AssignedUsersList userId={collectorProfileContext.data.userId} />
                            )}
                        </Box>
                    </Box>
                </>
            )}

            {isLoading && <Loader />}
        </Box>
    );
};
