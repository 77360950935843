import React, { useContext, useEffect, useState } from 'react';
import { Box, Container, Grid, ThemeOptions } from '@material-ui/core';

import '../../../styles/user-profile.less';

import { Lightbox } from './Lightbox';
import { UserProfileForm } from './UserProfileForm';
import { makeStyles } from '@material-ui/core/styles';
import { SortObjectsOptions } from '../../../../../core/enums';
import { ObjectApi } from 'core/api/consumer';
import { UtilsContext } from 'core/context/utils.context';
import { UserContext } from 'core/context/user';

export const useStyles = makeStyles((theme: ThemeOptions) => ({
    leftSection: {
        borderRightColor: `${theme.divider.main} !important`,
    },
}));

export const CollectorProfile: React.FC = () => {
    const classes = useStyles();

    const utilsContext = useContext(UtilsContext);
    const userContext = useContext(UserContext);

    const [stats, setStats] = useState({ totalObjects: 0, totalValue: 0 });

    useEffect(() => {
        if (userContext.user?.role === 'Collector') {
            ObjectApi.getMyObjects(1, 5, SortObjectsOptions.DateCreated, false)
                .then((res: any) => {
                    setStats({
                        totalObjects: res.data.totalCount,
                        totalValue: res.data.totalValue,
                    });
                })
                .catch((error: any) => {
                    console.error(error.response);
                    utilsContext.addSnackbar(
                        'error',
                        'Sorry, there was an error and we were unable to display your objects statistics.',
                    );
                });
        }
    }, []);

    return (
        <Container className="current-user-profile-container">
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={5}>
                    <Box className={`${classes.leftSection} user-profile-left`}>
                        <Lightbox stats={stats} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={7}>
                    <UserProfileForm />
                </Grid>
            </Grid>
        </Container>
    );
};
