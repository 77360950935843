import * as api from '../_utils/api';

export class NotificationsApi {

    static async getNotifications(): Promise<any> {
        return api._get(`users/getNotifications`);
    }

    static async markAsRead(ids: number[]): Promise<any> {
        return api._put(`users/updateNotifications`, ids);
    }
}
