import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Box, Container, Typography } from '@material-ui/core';

import { AppRoutes, getMyObjectsRoute } from 'core/helpers';
import { GalleryTypeEnum } from 'components/Gallery/helpers/GalleryTypeEnum';
import { SearchResultsContext } from 'core/context/searchResults';

import { Gallery } from 'components/Gallery';
import { Loader } from 'components/_others';
import { getCurrentPageFromUrl } from '../../../core/helpers/utils';
import { fixedHeaderStyles } from '../../../assets/styles/fixed-header';
import { UserContext } from '../../../core/context/user';
import { ObjectApi } from '../../../core/api';
import { UtilsContext } from '../../../core/context/utils.context';
import { CollectionApi } from '../../../core/api/collection.api';

const useStyles = makeStyles(() => ({
    root: {
        padding: '0 30px 15px',
    },
    pageTitle: {
        fontSize: 26,
        fontWeight: 400,
        opacity: 0.8,
    },
    sectionTitle: {
        fontSize: 18,
        fontWeight: 400,
        marginBottom: 10,
    },
}));

export const SearchResults: React.FC = () => {
    const userContext = useContext(UserContext);
    const utilsContext = useContext(UtilsContext);
    const classes = useStyles();
    const history = useHistory();
    const searchResultsContext = useContext(SearchResultsContext);

    const [isLoading, setIsLoading] = useState(false);
    const fixedHeaderCss = fixedHeaderStyles();

    useEffect(() => {
        if (!searchResultsContext.searchBy) {
            history.push(getMyObjectsRoute(userContext.user?.role));
            return;
        }

        const page = getCurrentPageFromUrl();
        searchResultsContext.setCurrentPage('artifacts', page);
        searchResultsContext.setCurrentPage('collections', page);
        onSearch();
    }, [searchResultsContext.searchBy]);

    const onSearch = (): void => {
        setIsLoading(true);
        searchForArtifacts(searchResultsContext.paginationArtifacts.page);
        searchForCollections(searchResultsContext.paginationCollections.page);
    };

    const handlePageChange = (scope: string, page: number): void => {
        history.push(`${AppRoutes.SEARCH_RESULTS}?page=${page}`);
        searchResultsContext.setCurrentPage(scope, page);
        if (scope === 'artifacts') {
            searchForArtifacts(page);
        }
        if (scope === 'collections') {
            searchForCollections(page);
        }
    };

    const searchForArtifacts = (page: number): void => {
        ObjectApi.searchObjects(
            page,
            searchResultsContext.paginationArtifacts.size,
            searchResultsContext.searchBy,
            utilsContext.selectedJob,
        )
            .then((res: any) => {
                searchResultsContext.setArtifactsResult(res.data);
                setIsLoading(false);
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                searchResultsContext.setArtifactsResult([]);
            });
    };

    const searchForCollections = (page: number): void => {
        CollectionApi.searchCollections(
            page,
            searchResultsContext.paginationArtifacts.size,
            searchResultsContext.searchBy,
            utilsContext.selectedJob,
        )
            .then((res: any) => {
                searchResultsContext.setCollectionsResult(res.data);
                setIsLoading(false);
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                searchResultsContext.setCollectionsResult([]);
            });
    };

    return (
        <Container maxWidth={false} className={classes.root}>
            {!isLoading && (
                <>
                    <Box className={`${fixedHeaderCss.root} header-fixed`}>
                        <Typography component="h5" color="textPrimary" className={classes.pageTitle}>
                            Search Results
                        </Typography>
                    </Box>

                    {searchResultsContext.artifacts.length > 0 && (
                        <Box>
                            <Typography color="textPrimary" className={classes.sectionTitle}>
                                Artifacts - {searchResultsContext.paginationArtifacts.totalCount} result(s)
                            </Typography>

                            <Box>
                                <Gallery
                                    galleryType={GalleryTypeEnum.Artifacts}
                                    data={searchResultsContext.artifacts}
                                    pagination={{
                                        numberOfPages: searchResultsContext.paginationArtifacts.numberOfPages,
                                        currentPage: searchResultsContext.paginationArtifacts.page,
                                    }}
                                    handlePageChange={(page: number) => handlePageChange('artifacts', page)}
                                />
                            </Box>
                        </Box>
                    )}

                    {searchResultsContext.collections.length > 0 && (
                        <Box>
                            <Typography color="textPrimary" className={classes.sectionTitle}>
                                Collections - {searchResultsContext.paginationCollections.totalCount} result(s)
                            </Typography>

                            <Box>
                                <Gallery
                                    galleryType={GalleryTypeEnum.Collections}
                                    data={searchResultsContext.collections}
                                    pagination={{
                                        numberOfPages: searchResultsContext.paginationCollections.numberOfPages,
                                        currentPage: searchResultsContext.paginationCollections.page,
                                    }}
                                    handlePageChange={(page: number) => handlePageChange('collections', page)}
                                />
                            </Box>
                        </Box>
                    )}

                    {searchResultsContext.collections.length === 0 && searchResultsContext.artifacts.length === 0 && (
                        <Box textAlign="center">
                            <Typography color="textPrimary" variant="h5">
                                There are no items matching your search criteria.
                            </Typography>
                        </Box>
                    )}
                </>
            )}

            {isLoading && <Loader />}
        </Container>
    );
};
