import  { createContext, useReducer } from 'react';
import FeedbackRequestsListingReducer from "./feedback-requests-listing.reducer";
import {IFeedbackRequestsListingState} from "./feedback-requests-listing.interfaces";

const initialState: IFeedbackRequestsListingState = {
    data: [],
    isLoading: true,
    displayEmptyPage: false,
    statistics: {
        totalRequests: 0
    },
    pagination: {
        page: 1,
        size: 20,
        totalCount: 0,
        numberOfPages: 0,
    },
    setData: () => null,
    setCurrentPage: () => null,
    setLoading: () => null,
}

export const FeedbackRequestsListingContext = createContext(initialState);

export const FeedbackRequestsListingProvider: any = ({children}: any) => {

    const [state, dispatch] = useReducer(FeedbackRequestsListingReducer, initialState);

    const setData = (data: unknown): void => {
        dispatch({
            type: 'SET_DATA',
            payload: data
        })
    }

    const setCurrentPage = (page: number): void => {
        dispatch({
            type: 'SET_CURRENT_PAGE',
            payload: page,
        })
    }

    const setLoading = (loading: boolean): void => {
        dispatch({
            type: 'SET_LOADING',
            payload: loading
        });
    }

    return (
        <FeedbackRequestsListingContext.Provider value={{
            ...state,
            setData,
            setCurrentPage,
            setLoading,
        }}>
            {children}
        </FeedbackRequestsListingContext.Provider>
    )
}
