import {useContext, useState} from "react";
import {UtilsContext} from "core/context/utils.context";
import {CollectionApi} from "core/api/collection.api";
import { useHistory } from "react-router-dom";
import {AppRoutes} from "../../../../core/helpers";

export const useNewCollection = (): any => {

    const history = useHistory();
    const utilsContext = useContext(UtilsContext);

    const [isLoading, setIsLoading] = useState(false);

    const addNewCollection = async (name: string): Promise<boolean> => {
        setIsLoading(true);

        return await CollectionApi.addNewCollection(name)
            .then((res: any) => {
                setIsLoading(false);
                utilsContext.addSnackbar('success', 'Success! Your collection was added.');
                history.push(`${AppRoutes.COLLECTION}?id=${res.data.id}`);
                return true;
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar('error');
                return false;
            })
    }

    return {
        isLoading,
        addNewCollection,
    }
}
