import React, {useState} from "react";
import { Button } from "@material-ui/core";

import {addNewCollectionBtnStyles} from "../styles/header.styles";
import AddIcon from '@material-ui/icons/Add';

import {AddNewCollectionModal} from "./AddNewCollectionModal";

export const AddNewCollectionBtn: React.FC = () => {

    const [displayAddNewModal, toggleAddNewModal] = useState(false);

    const classes = addNewCollectionBtnStyles();

    return (
        <>
            <Button
                id="go-to-new-collection-btn"
                variant="contained"
                color="primary"
                className={classes.root}
                onClick={() => toggleAddNewModal(true)}
            >
                <AddIcon className={classes.icon} />
                <span className={classes.hideXS}>Add new collection</span>
            </Button>

            {
                displayAddNewModal &&
                <AddNewCollectionModal
                    open={true}
                    handleClose={() => toggleAddNewModal(false)}
                />
            }
        </>
    )
}
