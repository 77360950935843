import {ITaxonomyLabel, ITaxonomyState} from "./taxonomy.interfaces";
import {createContext, useReducer} from "react";
import TaxonomyReducer from "./taxonomy.reducer";

const initialState: ITaxonomyState = {
    rootTypes: [],
    selectedRootType: null,
    selectedForDeleteId: null,
    deletedLabels: [],
    displayAddLabelModal: -1,
    setRootTypes: () => null,
    selectRootType: () => null,
    setSelectedForDeleteId: () => null,
    pushDeletedLabel: () => null,
    toggleAddLabelModal: () => null,
}

export const TaxonomyContext = createContext(initialState);

export const TaxonomyProvider: any = ({children}: any) => {

    const [state, dispatch] = useReducer(TaxonomyReducer, initialState);

    const setRootTypes = (rootTypes: ITaxonomyLabel[]): void => {
        dispatch({
            type: 'SET_ROOT_TYPES',
            payload: rootTypes,
        })
    }

    const selectRootType = (label: ITaxonomyLabel): void => {
        dispatch({
            type: 'SELECT_ROOT_TYPE',
            payload: label,
        })
    }

    const setSelectedForDeleteId = (id: number|null): void => {
        dispatch({
            type: 'SET_SELECTED_FOR_DELETE_ID',
            payload: id,
        })
    }

    const pushDeletedLabel = (id: number): void => {
        dispatch({
            type: 'PUSH_DELETED_LABEL',
            payload: id,
        })
    }

    const toggleAddLabelModal = (value: number): void => {
        dispatch({
            type: 'TOGGLE_ADD_LABEL_MODAL',
            payload: value,
        })
    }

    return (
        <TaxonomyContext.Provider value={{
            ...state,
            setRootTypes,
            selectRootType,
            setSelectedForDeleteId,
            pushDeletedLabel,
            toggleAddLabelModal,
        }}>
            {children}
        </TaxonomyContext.Provider>
    )
}
