import { useContext, useState } from 'react';
import { usePagination } from 'core/hooks/use-pagination.hook';
import { ReportingApi } from 'core/api/consumer';
import { UtilsContext } from 'core/context/utils.context';

export const useReportingData = (): any => {
    const [isObjectDataLoading, setIsLoading] = useState(false);
    const [objectsData, setObjectsData] = useState([]);

    const [widgetData, setWidgetDataData] = useState({
        missingPhotos: {
            count: 0,
            percentage: 0,
        },
        missingDetails: {
            count: 0,
            percentage: 0,
        },
        sharedOnCommunityFeed: {
            count: 0,
            percentage: 0,
        },
        totalCount: 0,
    });

    const { pagination, setPagination } = usePagination();

    const utilsContext = useContext(UtilsContext);

    const getObjectsData = (page: number, reportType: string): void => {
        setIsLoading(true);

        ReportingApi.getArtifactsByStatistics(page, pagination.size, reportType)
            .then((res: any) => {
                setObjectsData(res.data.data);
                setPagination({
                    ...pagination,
                    page,
                    totalCount: res.data.totalCount,
                    numberOfPages: Math.ceil(res.data.totalCount / res.data.size),
                });
                setIsLoading(false);
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
            });
    };

    const getWidgetData = (): void => {
        utilsContext.setAppLoaderState(true);
        ReportingApi.getReportingStatistics()
            .then((res: any) => {
                setWidgetDataData({
                    missingPhotos: {
                        count: res.data.onePhotoCount,
                        percentage: 100 - Math.round(res.data.onePhotoPercent),
                    },
                    missingDetails: {
                        count: res.data.missingDetailsCount,
                        percentage: 100 - Math.round(res.data.missingDetailsPercent),
                    },
                    sharedOnCommunityFeed: {
                        count: res.data.sharedOnPublicFeedCount,
                        percentage: Math.round(res.data.sharedOnPublicFeedPercent),
                    },
                    totalCount: res.data.totalCount,
                });
                utilsContext.setAppLoaderState(false);
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.setAppLoaderState(false);
                utilsContext.addSnackbar('error');
            });
    };

    const onRemoveFromCommunityFeed = (_id: number): void => {
        setWidgetDataData({
            ...widgetData,
            sharedOnCommunityFeed: {
                count: widgetData.sharedOnCommunityFeed.count - 1,
                percentage: Math.floor(((widgetData.sharedOnCommunityFeed.count - 1) * 100) / widgetData.totalCount),
            },
        });
    };

    return {
        isObjectDataLoading,
        objectsData,
        getObjectsData,
        pagination,
        widgetData,
        getWidgetData,
        onRemoveFromCommunityFeed,
    };
};
