import React, { useContext, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import { Box, ThemeOptions, Typography } from "@material-ui/core";

import { ExpertProfileContext } from "core/context/admin/expert-profile";
import { ExpertsApi } from "core/api/admin";
import { Loader } from "components/_others";
import { CompletedFeedback } from "./CompletedFeedback";
import {UtilsContext} from "../../../../../../core/context/utils.context";

type CompletedFeedbacksListProps = {
    userId: number;
}

const useStyles = makeStyles((_theme: ThemeOptions) => ({
    root: {
        fontSize: 16,
    },
}))

export const CompletedFeedbacksList: React.FC<CompletedFeedbacksListProps> = ({userId}) => {

    const classes = useStyles();
    const expertProfileContext = useContext(ExpertProfileContext);

    const [isLoading, setIsLoading] = useState(true);
    const utilsContext = useContext(UtilsContext);
    useEffect(() => {
        expertProfileContext.setCurrentPage(1);
        getFeedbacks(1);
    }, []);

    const getFeedbacks = (page: number): void => {
        ExpertsApi.getExpertCompletedFeedbacks(userId, expertProfileContext.feedbacksPagination.size, page)
            .then((res: any) => {
                expertProfileContext.setCompletedFeedbacks(res.data);
                setIsLoading(false);
                window.scrollTo(0, 0);
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar('error');
            })
    }

    const handlePageChange = (page: number): void => {
        expertProfileContext.setCurrentPage(page);
        getFeedbacks(page);
    }

    return (
        <Box className={classes.root}>
            {
                !isLoading &&
                    <>
                        <Typography variant="h5" color={'textSecondary'} style={{fontSize: 16, marginBottom: 10}}>
                            Completed feedback:
                        </Typography>

                        {
                            expertProfileContext.completedFeedbacks.length === 0 &&
                            <Typography variant="h5" color={'textSecondary'} style={{fontSize: 16, marginBottom: 10}}>
                                There are no completed feedback.
                            </Typography>
                        }

                        {
                            expertProfileContext.completedFeedbacks.map(feedback => (
                                <CompletedFeedback key={feedback.id} feedback={feedback}/>
                            ))
                        }

                        {
                            expertProfileContext.feedbacksPagination.numberOfPages > 1 &&
                            <Box textAlign="right" width="100%" marginTop={'10px'}>
                                <Pagination
                                    count={expertProfileContext.feedbacksPagination.numberOfPages}
                                    page={expertProfileContext.feedbacksPagination.page}
                                    siblingCount={1}
                                    shape="rounded"
                                    style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 10}}
                                    onChange={(_e: any, value: number) => handlePageChange(value)}
                                />
                            </Box>
                        }
                    </>
            }

            {
                isLoading && <Loader />
            }
        </Box>
    )
}
