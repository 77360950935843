import React from "react";
import { ThemeOptions, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

type SectionTitleProps = {
    title: string;
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
    root: {
        fontSize: 18,
        fontWeight: 'bold',
        borderBottom: '1px solid',
        borderColor: `${theme.divider.main}!important`,
        maxWidth: '30%'
    }
}))

export const SectionTitle: React.FC<SectionTitleProps> = ({title}) => {

    const classes = useStyles();

    return (
        <Typography
            className={classes.root}
            color={'secondary'}
        >
            {title}
        </Typography>
    )
}
