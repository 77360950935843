import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Box } from '@material-ui/core';

import { IPhoto } from 'core/context/consumer/objectDetails';
import { getPhotoUrl } from 'core/helpers/file-size.helper';
import { FileSize } from 'core/enums';
import { UserContext } from '../../../../core/context/user';

type SelectedPhotosProps = {
    photos: Array<string | IPhoto>;
    onDeleteClick: (photo: IPhoto | string, index: number) => void;
    onCropClick: (photo: IPhoto | string, index?: number) => void;
};

const useStyles = makeStyles({
    container: {
        display: 'inline-block',
        overflow: 'auto',
    },
    photoContainer: {
        display: 'inline-block',
        marginTop: 15,
        marginRight: 10,
        backgroundColor: '#383838',
        padding: 10,
        minWidth: 100,
        textAlign: 'center',
        '&:last-child': {
            marginRight: 0,
        },
    },
    photo: {
        maxHeight: 100,
        border: '1px solid #505050',
    },
    remove: {
        width: 15,
        height: 17,
        fontSize: 12,
        cursor: 'pointer',
        marginRight: 25,
    },
    crop: {
        width: 15,
        height: 17,
        fontSize: 12,
        cursor: 'pointer',
        marginRight: 10,
    },
    actionsBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 5,
        paddingTop: 5,
        borderTop: '1px solid gray',
    },
});

export const SelectedPhotos: React.FC<SelectedPhotosProps> = ({ photos, onDeleteClick, onCropClick }) => {
    const classes = useStyles();
    const userContext = useContext(UserContext);

    const hideDeleteButton = (): boolean => {
        return (
            photos.filter((p) => {
                if ((p as IPhoto).deleted) {
                    return false;
                }
                return true;
            }).length <= 1
        );
    };

    const handleCropClick = (photo: IPhoto | string, index: number): void => {
        if (typeof photo === 'string') {
            onCropClick(photo, index);
        } else {
            onCropClick(photo);
        }
    };

    return (
        <Box className={classes.container}>
            {photos
                .filter((photo) => !(photo as IPhoto).deleted)
                .map((photo, index) => (
                    <Box
                        className={classes.photoContainer}
                        key={(photo as IPhoto).id ?? `photo${index}`}
                        style={userContext.lightTheme ? { backgroundColor: '#EFEFEF' } : {}}
                    >
                        <img
                            src={
                                (photo as IPhoto).url
                                    ? getPhotoUrl((photo as IPhoto).url, FileSize.XS) ?? ''
                                    : (photo as string)
                            }
                            className={classes.photo}
                            alt="photo"
                        />
                        <Box
                            className={classes.actionsBox}
                            style={hideDeleteButton() ? { justifyContent: 'center' } : {}}
                        >
                            {
                                <Box className={classes.crop} onClick={() => handleCropClick(photo, index)}>
                                    Crop
                                </Box>
                            }
                            {!hideDeleteButton() && (
                                <Box className={classes.remove} onClick={() => onDeleteClick(photo, index)}>
                                    Remove
                                </Box>
                            )}
                        </Box>
                    </Box>
                ))}
        </Box>
    );
};
