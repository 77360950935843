import React, {useState} from "react";
import {Box, Typography} from "@material-ui/core";
import {artifactCardStyles} from "../../styles/artifact-card.style";

type DetailsProps = {
    title: string;
    description: string;
}

export const Details: React.FC<DetailsProps> = ({title, description}) => {

    const classes = artifactCardStyles();

    const [displayFullDescription, toggleFullDescription] = useState(false);

    return (
        <Box marginTop={2}>
            <Typography color={'textPrimary'} className={classes.artifactTitle}>
                {title}
            </Typography>
            <Typography className={classes.artifactSubtitle}>
                {
                    description?.length > 180 && !displayFullDescription &&
                    <>
                        {description?.substr(0, 180)}
                        <span
                            onClick={() => toggleFullDescription(true)}
                            className={classes.showMore}
                        >
                            {' '}...see more
                        </span>
                    </>
                }
                {
                    (description?.length <= 180 || displayFullDescription) &&
                    <>
                        {description}
                    </>
                }
            </Typography>
        </Box>
    )
}
