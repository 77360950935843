import React, {useContext, useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import { modalStyles } from "components/_others/modals/modal-styles";
import {
    Backdrop,
    Box,
    Button,
    Chip,
    CircularProgress,
    Fade,
    Modal,
    TextField,
    Typography
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import imagePlaceholder from "assets/placeholders/artifact-placeholder.png";
import "scenes/consumer/MyCollectionObjects/styles/add-object-modal.less";

import { AppRoutes } from "core/helpers/app-routes";
import { CollectorApi } from "core/api/consumer/collector.api";
import { CollectorCollectionApi } from "core/api/consumer/collector-collection.api";
import { intersectArrays } from "core/helpers/utils";
import { getPhotoUrl } from "core/helpers";
import { FileSize } from "core/enums";
import {UserContext} from "../../../../core/context/user";
import {UtilsContext} from "../../../../core/context/utils.context";

type AddObjectToCollectionModalProps = {
    collectionId: number;
    collectionName: string;
    open: boolean;
    handleClose: () => void;
}

interface ObjectOption {
    id: number;
    title: string;
}

export const AddObjectToCollectionModal: React.FC<AddObjectToCollectionModalProps> = (
    {collectionId, collectionName, open, handleClose}
) => {

    const classes = modalStyles();
    const history = useHistory();
    const userContext = useContext(UserContext);
    const utilsContext = useContext(UtilsContext);

    const [view, setView] = useState('select-option');
    const [isLoading, setIsLoading] = useState(false);

    const [selectedObjects, setSelectedObjects] = useState<ObjectOption[]>([]);
    const [objectOptions, setObjectOptions] = useState<ObjectOption[]>([]);


    useEffect(() => {
        if (open) {
            setView('select-option');
            setSelectedObjects([]);
            setObjectOptions([]);
        }
    }, [open])

    const goToNewObject = (): void => {
        handleClose();
        history.push({
            pathname: AppRoutes.ADD_OBJECT,
            search: `?collectionId=${collectionId}&collectionName=${collectionName}`
        });
    }

    const getObjectsOptions = (value: string): void => {
        if(value?.length < 4) {
            setObjectOptions([]);
            return;
        }

        CollectorApi.searchArtifactsByCollection(JSON.stringify(value), collectionId)
            .then((res: any) => {
                setObjectOptions(res.data.data);
            })
            .catch((error: any) => {
                console.error(error.response);
                setObjectOptions([]);
            })
    }

    const onSave = (): void => {
        setIsLoading(true);

        CollectorCollectionApi.addArtifactsToCollection(collectionId, selectedObjects.map((el: ObjectOption) => el.id))
            .then(() => {
                handleClose();
                history.go(0);
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error');
            })
    }

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Box textAlign={'right'}>
                        <CloseIcon cursor={'pointer'} onClick={handleClose} />
                    </Box>

                    <Box marginBottom={'10px'}>
                        <Typography className={classes.modalTitle}>
                            Add Object?
                        </Typography>
                    </Box>

                    {
                        view === 'select-option' &&
                            <>
                                <Box
                                    className="collection-add-obj existing-obj"
                                    style={userContext.lightTheme ? {backgroundColor: '#EFEFEF'} : {}}
                                    onClick={() => setView('select-object')}
                                >
                                    <Typography className="title" color={'textPrimary'}>
                                        Add Items from My Objects
                                    </Typography>
                                    <Typography className="subtitle">
                                        Simply add existing items shared from "My Objects" page.
                                    </Typography>
                                </Box>

                                <Box
                                    className="collection-add-obj new-obj"
                                    style={userContext.lightTheme ? {backgroundColor: '#EFEFEF'} : {}}
                                    onClick={() => goToNewObject()}
                                >
                                    <Typography className="title">
                                        Add New Object
                                    </Typography>
                                    <Typography className="subtitle">
                                        Upload a new object to your collection.
                                    </Typography>
                                </Box>
                            </>
                    }

                    {
                        view === 'select-object' &&
                            <Box className="select-objects-autocomplete">
                                <Typography className={classes.modalSubtitle}>
                                    Choose the name of your object form the dropdown below:
                                </Typography>
                                <Autocomplete
                                    id="select-object-dropdown"
                                    multiple
                                    value={selectedObjects}
                                    options={intersectArrays(objectOptions, selectedObjects, 'id')}
                                    disableCloseOnSelect
                                    placeholder="Search by name"
                                    noOptionsText={'Please type an object name'}
                                    getOptionLabel={(option) => option.title}
                                    getOptionSelected={
                                        (option, _value) =>
                                            selectedObjects.findIndex((el: ObjectOption) => el.id === option.id) !== -1
                                    }
                                    onChange={(_e, value) => setSelectedObjects(value)}
                                    onInputChange={(_e, value) => getObjectsOptions(value)}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                variant="outlined"
                                                label={option.title}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                        />
                                    )}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            <img
                                                src={getPhotoUrl(option.photoUrl, FileSize.XS) || imagePlaceholder}
                                                alt={option.title}
                                                style={{maxWidth: 100, marginRight: 10}}
                                            />
                                            {option.title}
                                        </React.Fragment>
                                    )}
                                />
                            </Box>
                    }

                    <Box textAlign={'right'} marginTop={'15px'}>
                        <Button
                            id="cancel-delete-btn"
                            variant="contained"
                            size={"small"}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>

                        {
                            view === 'select-object' &&
                            <Button
                                id="delete-btn"
                                variant="contained"
                                color="primary"
                                size={"small"}
                                style={{marginLeft: '15px'}}
                                className={classes.primaryButton}
                                onClick={() => onSave()}
                            >
                                {
                                    isLoading && <CircularProgress className="circular-progress sm" />
                                }
                                {
                                    !isLoading && <SaveIcon style={{
                                        width: 20, marginRight: 5
                                    }}/>
                                }
                                Save
                            </Button>
                        }
                    </Box>
                </div>
            </Fade>
        </Modal>
    )
}
