import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { EnterResendEmailModal } from './EnterResendEmailModal';
//import { UserApi } from 'core/api/user/user.api';

const useStyles = makeStyles({
    clickableText: {
        fontSize: '14px',
        display: 'inline',

        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    text: {
        fontSize: '14px',
        display: 'inline',
    },
});

export const ResendEmail: React.FC = () => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const openModal = (): void => {
        setIsOpen(true);
    };

    const handleModalClose = (): void => {
        setIsOpen(false);
    };

    return (
        <Box marginBottom={4}>
            <Typography style={{ fontSize: '14px' }} className={classes.text}>
                {' '}
                Your confirmation E-mail was lost and you need to re-send it? <br />
                Click{' '}
            </Typography>
            <Typography className={classes.clickableText} color="primary" onClick={openModal}>
                {' here '}
            </Typography>
            <Typography style={{ fontSize: '14px' }} className={classes.text}>
                to resend the confirmation E-mail.
            </Typography>

            <EnterResendEmailModal isOpen={isOpen} onClose={handleModalClose} />
        </Box>
    );
};
