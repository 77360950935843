import React, {useState} from "react";
import {Box, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {AddComment} from "./AddComment";
import {IFeedbackComment} from "core/context/feedback-requests/feedback";
import {Comments} from "./Comments";

type ChatProps = {
    artifactId: number;
    feedbackRequestId: number;
    isFeedbackCompleted: boolean,
    comments: IFeedbackComment[],
}

export const Chat: React.FC<ChatProps> = (
    {feedbackRequestId, isFeedbackCompleted, comments, artifactId}
) => {

    const classes = useStyles();

    const [currentComments, setComments] = useState<IFeedbackComment[]>(comments);

    const pushComment = (comment: IFeedbackComment): void => {
        const newComments = comments;
        newComments.push(comment);
        setComments(newComments.slice());
    }

    const onDeleteComment = (id: number): void => {
        const newComments = comments;
        const index = newComments.findIndex(e => e.id === id);
        if(index > -1) {
            newComments.splice(index, 1);
        }
        setComments(newComments.slice());
    }

    return (
        <>
            {
                !isFeedbackCompleted &&
                <Typography color={'textPrimary'} className={classes.infoText}>
                    You have a pending assessment, so you can chat with the expert below.
                </Typography>
            }
            {
                isFeedbackCompleted &&
                <Typography color={'textPrimary'} className={classes.infoText}>
                    This feedback is completed. You can not chat with the expert anymore.
                </Typography>
            }

            <Box>

                {
                    currentComments &&
                        <Box className={classes.commentsBox}>
                            <Comments
                                artifactId={artifactId}
                                feedbackRequestId={feedbackRequestId}
                                comments={currentComments}
                                isFeedbackCompleted={isFeedbackCompleted}
                                onDeleteComment={(id: number) => onDeleteComment(id)}
                            />
                        </Box>
                }

                {
                    !isFeedbackCompleted &&
                    <AddComment
                        artifactId={artifactId}
                        feedbackRequestId={feedbackRequestId}
                        pushComment={(comment: IFeedbackComment) => pushComment(comment)}
                    />
                }

            </Box>
        </>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        fontSize: 18,
        color: theme.palette.secondary.main,
    },
    divider: {
        width: '100%',
        borderTop: '1px solid',
        borderColor: theme.divider.main,
        margin: '0'
    },
    infoText: {
        fontSize: 13
    },
    commentsBox: {
        maxHeight: 250,
        overflow: 'auto',
        paddingRight: 10,
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column-reverse',
    }
}))
