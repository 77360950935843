import { createContext, useReducer } from 'react';
import ExpertProfileReducer from "./expert-profile.reducer";
import {IExpertProfileState} from "./expert-profile.interfaces";

const initialState: IExpertProfileState = {
    data: {
        userId: 0,
        isActive: false,
        type: '',
        email: '',
        phoneNumber: '',
        firstName: '',
        lastName: '',
        organization: '',
        website: '',
        address: '',
        title: '',
        description: '',
        totalFeedbacks: 0,
        rating: 0,
        expertise: '',
        currency: {
            id: -1,
            code: '',
            name: '',
            namePlural: '',
            symbol: '',
            symbolNative: '',
        },
        country: {id: -1, name: ''},
        state: {id: -1, name: ''},
        city: {id: -1, name: ''},
        photoUrl: '',
    },
    categories: [],
    completedFeedbacks: [],
    totalDeletedFeedbacksCount: 0,
    feedbacksPagination: {
        page: 0,
        size: 10,
        totalCount: 0,
        numberOfPages: 0,
    },
    isLoading: true,
    setData: () => null,
    setCompletedFeedbacks: () => null,
    setLoading: () => null,
    setCurrentPage: () => null,
    setExpertType: () => null,
}

export const ExpertProfileContext = createContext(initialState);

export const ExpertProfileProvider: any = ({children}: any) => {

    const [state, dispatch] = useReducer(ExpertProfileReducer, initialState);

    const setData = (data: unknown): void => {
        dispatch({
            type: 'SET_DATA',
            payload: data
        })
    }

    const setCompletedFeedbacks = (data: unknown): void => {
        dispatch({
            type: 'SET_COMPLETED_FEEDBACKS',
            payload: data,
        })
    }

    const setCurrentPage = (page: number): void => {
        dispatch({
            type: 'SET_CURRENT_PAGE',
            payload: page,
        })
    }

    const setLoading = (loading: boolean): void => {
        dispatch({
            type: 'SET_LOADING',
            payload: loading
        });
    }

    const setExpertType = (type: string): void => {
        dispatch({
            type: 'SET_EXPERT_TYPE',
            payload: type
        });
    }

    return (
        <ExpertProfileContext.Provider value={{
            ...state,
            setData,
            setCompletedFeedbacks,
            setCurrentPage,
            setLoading,
            setExpertType,
        }}>
            {children}
        </ExpertProfileContext.Provider>
    )
}
