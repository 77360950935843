import {IFeedbackRequestsListingState} from "./feedback-requests-listing.interfaces";
import {IAction} from "../../interfaces";

export default (state: IFeedbackRequestsListingState, action: IAction): IFeedbackRequestsListingState => {
    switch(action.type) {
        case 'SET_DATA':
            return {
                ...state,
                data: action.payload.data,
                isLoading: false,
                displayEmptyPage: action.payload.data?.length === 0,
                statistics: {
                    totalRequests: action.payload.totalCount
                },
                pagination: {
                    page: state.pagination.page,
                    size: state.pagination.size,
                    totalCount: action.payload.totalCount,
                    numberOfPages: Math.ceil(action.payload.totalCount/state.pagination.size),
                },
            }
        case 'SET_CURRENT_PAGE':
            return {
                ...state,
                isLoading: false,
                pagination: {
                    ...state.pagination,
                    page: action.payload,
                }
            }
        case 'SET_LOADING':
            return {
                ...state,
                isLoading: action.payload
            }
        default:
            return state;
    }
}
