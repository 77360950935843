import React, {useContext, useState} from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, TextField, ThemeOptions, Tooltip, Typography } from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";

import { getCustomerSatisfactionColor, getCustomerSatisfactionText } from "core/helpers/customer-satisfaction";
import {UserContext} from "../../../../../../core/context/user";
import {UserRoles} from "../../../../../../core/enums";

type StatsProps = {
    totalFeedbacks: number;
    totalDeletedFeedbacksCount: number;
    customerSatisfaction: number;
    type: string;
    saveExpertType: (type: string) => void;
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
    label: {
        fontSize: 16,
        marginBottom: 7,
        width: 'fit-content'
    },
    value: {
        color: theme.palette?.text?.primary,
    },
    editIcon: {
        marginLeft: 7, width: 15, marginBottom: 6, cursor: 'pointer'
    }
}))

export const Stats: React.FC<StatsProps> = (
    {totalFeedbacks, totalDeletedFeedbacksCount, customerSatisfaction, type, saveExpertType}
) => {

    const classes = useStyles();

    const [isEditingType, setIsEditingType] = useState(false);
    const [currentType, setCurrentType] = useState(type);

    const userContext = useContext(UserContext);

    return (
        <Box>
            <Box display={'flex'} alignItems={'center'}>
                {
                    !isEditingType &&
                        <>
                            <Typography className={classes.label} color="textSecondary">
                                Type:{' '}
                                <span color="textPrimary" className={classes.value}><b>{currentType}</b></span>
                            </Typography>
                            <EditIcon
                                className={classes.editIcon}
                                color={'primary'}
                                onClick={() => setIsEditingType(true)}
                            />
                        </>
                }
                {
                    isEditingType &&
                        <>
                            <TextField
                                id="title"
                                variant="outlined"
                                size="small"
                                type="text"
                                inputProps={{ maxLength: 100 }}
                                style={{marginBottom: 5}}
                                value={currentType}
                                onChange={(e: any) => setCurrentType(e.target.value)}
                            />
                            <SaveIcon color={'primary'} style={{
                                width: 20, marginLeft: 7, cursor: 'pointer'
                            }} onClick={() => {saveExpertType(currentType); setIsEditingType(false)}} />
                            <Typography
                                color="textSecondary"
                                style={{marginLeft: 5, fontSize: 13, cursor: 'pointer'}}
                                onClick={() => {setIsEditingType(false); setCurrentType(type)}}
                            >
                                Cancel
                            </Typography>
                        </>
                }

            </Box>

            <Tooltip
                arrow
                interactive={true}
                title={getCustomerSatisfactionText(customerSatisfaction)}
                className="customer-satisfaction-tooltip"
                placement="bottom"
            >
                <>
                {
                    userContext.user?.role === UserRoles.ADMIN &&
                        <Typography className={classes.label} color="textSecondary">
                            Customer Satisfaction:{' '}
                            <span
                                style={{color: getCustomerSatisfactionColor(customerSatisfaction)}}
                                className={classes.value}
                            >
                            <b>{customerSatisfaction.toFixed(0)}%</b>
                            </span>
                        </Typography>
                }
                </>
            </Tooltip>


            <Typography className={classes.label} color="textSecondary">
                Number of feedbacks:{' '}
                <span color="textPrimary" className={classes.value}>
                    <b>{totalFeedbacks}</b>
                    {
                        totalDeletedFeedbacksCount > 0 &&
                        <Tooltip
                            arrow
                            interactive={true}
                            title={'The collector deleted the object(s) from his collection.'}
                            className="customer-satisfaction-tooltip"
                            placement="bottom"
                        >
                            <span><i> (+ {totalDeletedFeedbacksCount} deleted)</i></span>
                        </Tooltip>
                    }
                </span>
            </Typography>
        </Box>
    )
}
