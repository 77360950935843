import React from "react";
import { Box } from "@material-ui/core";

import { BackNavigation } from "components/_others/BackNavigation";
import { Title } from "components/_consumer/ObjectDetails/Title";
import {fixedHeaderStyles} from "../../../../../../assets/styles/fixed-header";

type HeaderProps = {
    name: string;
}

export const Header: React.FC<HeaderProps> = ({name}) => {

    const fixedHeaderCss = fixedHeaderStyles();

    return (
        <Box className={`${fixedHeaderCss.root} page-header-container`}>
            <BackNavigation />

            <Title
                name={name}
            />
        </Box>
    )
}
