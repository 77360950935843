import React from "react";
import { Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, ThemeOptions } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import { IFeedbackRequest } from "core/context/feedback-requests/listing";
import { FeedbackRequestItem } from "./FeedbackRequestItem";

const useStyles = makeStyles((theme: ThemeOptions) => ({
    container: {
        marginTop: 30,
        width: '100%',
        border: '1px solid',
        borderColor: theme.divider.main,
        borderRadius: 1,
        padding: '20px 15px',
        marginBottom: 25,
    },
    divider: {
        borderTop: '1px solid',
        borderColor: theme.divider.main,
        width: '100%',
        marginTop: 20,
        marginBottom: 15,
    },
}))

type FeedbackRequestsListProps = {
    data: IFeedbackRequest[],
    pagination: {
        numberOfPages: number,
        currentPage: number;
    },
    handlePageChange: (page: number) => void;
}

export const FeedbackRequestsList: React.FC<FeedbackRequestsListProps> = (
    {data, pagination, handlePageChange}
) => {

    const classes = useStyles();

    return (
        <>

            <Box className={classes.container}>
                {
                    data.map((feedback, index) => (
                        <Fragment key={feedback.id}>
                            <FeedbackRequestItem
                                key={feedback.id}
                                item={feedback}
                            />
                            {
                                index !== data.length - 1 && <div className={classes.divider} />
                            }
                        </Fragment>
                    ))
                }
            </Box>

            {
                pagination.numberOfPages > 1 &&
                <Box textAlign="right">
                    <Pagination
                        count={pagination.numberOfPages}
                        page={pagination.currentPage}
                        siblingCount={1}
                        shape="rounded"
                        style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 10}}
                        onChange={(_e: any, value: number) => handlePageChange(value)}
                    />
                </Box>
            }
        </>
    )
}
