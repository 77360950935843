import {EMAIL} from "./reg-exp";

export function validateEmail(email: string): boolean {
    return EMAIL.test(email);
}

export function passwordStrength(password: string): any {
    const result = {
        valid: true,
        length: {
            valid: true,
            message: '',
        },
        digits: {
            valid: true,
            message: '',
        },
        uppercase: {
            valid: true,
            message: '',
        },
        lowercase: {
            valid: true,
            message: '',
        },
        specialChars: {
            valid: true,
            message: '',
        },
    }

    if(!password?.length) {
        return result;
    }

    if(password.length < 8) {
        result.length.valid = false;
        result.length.message = 'Your password must contain at least 8 characters.';
        result.valid = false;
    }

    if(!(/[0-9]/.test(password))) {
        result.digits.valid = false;
        result.digits.message = 'Your password must contain at least 1 digit.';
        result.valid = false;
    }

    if(!(/[A-Z]/.test(password))) {
        result.uppercase.valid = false;
        result.uppercase.message = 'Your password must contain at least 1 uppercase character.';
        result.valid = false;
    }

    if(!(/[a-z]/.test(password))) {
        result.lowercase.valid = false;
        result.lowercase.message = 'Your password must contain at least 1 lowercase character.';
        result.valid = false;
    }
    if(!(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password))) {
        result.specialChars.valid = false;
        result.specialChars.message = 'Your password must contain at least 1 special character (eg. !, @, #).';
        result.valid = false;
    }

    return result;
}
