import React, {useContext, useState} from "react";

import {Backdrop, Fade, Modal} from "@material-ui/core";
import {modalStyles} from "../../../_others/modals/modal-styles";
import {ModalHeader} from "../../../_others/modals/ModalHeader";
import { ICollection } from "types";
import {CollectionHighlights} from "../../../collection/CollectionHighlights";
import {Form} from "../Form";
import {CollectorCollectionApi} from "core/api/consumer";
import {UtilsContext} from "../../../../core/context/utils.context";

type ShareCollectionsToPersonsModalProps = {
    collection: ICollection;
    open: boolean;
    handleClose: () => void;
}

export const ShareCollectionsToPersonsModal: React.FC<ShareCollectionsToPersonsModalProps> = (
    {collection, open, handleClose}
) => {

    const classes = modalStyles();

    const utilsContext = useContext(UtilsContext);

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onShare = (emails: string[], message: string): void => {
        setIsLoading(true);
        setErrorMessage('');

        CollectorCollectionApi.shareCollection(collection.id, emails, message)
            .then(() => {
                setIsLoading(false);
                utilsContext.addSnackbar('success', 'Thank you! This collection was shared.');
                handleClose();
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                if(error.response.data.message === 'ShareAlreadyExists') {
                    setErrorMessage('You already shared this collection with that person.')
                } else {
                    setErrorMessage('Sorry, there was an error. Please try again later.')
                }
            })
    }

    return (
        <Modal
            className={classes.modal}
            style={{alignItems: 'flex-start', top: '20px', bottom: '20px'}}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>

                    <ModalHeader
                        title={'Share this collection'}
                        handleClose={() => handleClose()}
                    />

                    <CollectionHighlights
                     id={collection.id}
                     name={collection.name}
                     photoUrl={collection.photoUrl}
                     totalObjects={collection.totalObjects}
                    />

                    <Form
                        isLoading={isLoading}
                        errorMessage={errorMessage}
                        onShare={(emails: string[], message: string) => onShare(emails, message)}
                        handleClose={() => handleClose()}
                    />

                </div>
            </Fade>
        </Modal>
    )
}
