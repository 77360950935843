import {JobsApi} from "core/api/jobs.api";
import {useContext} from "react";
import {UtilsContext} from "core/context/utils.context";

export const useAssignJob = (userId: number, role: string): any => {

    const utilsContext = useContext(UtilsContext);

    const assignJob = (collectorId: number): void => {
        JobsApi.assignJob(collectorId, userId, role)
            .then(() => {
                utilsContext.addSnackbar(
                    'success',
                    'Success! This job was assigned.'
                );
            })
            .catch((error: any) => {
                console.error(error.response);
                let errorMessage = 'Sorry, there was an error. Please try again later.'
                if(error.response.data.message === 'AlreadyExists') {
                    errorMessage = 'This job is already assigned.';
                }
                utilsContext.addSnackbar(
                    'error',
                    errorMessage
                );
            })
    }

    return {
        assignJob,
    }
}
