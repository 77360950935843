import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Box, ThemeOptions } from '@material-ui/core';

import { CuratorProfileContext } from 'core/context/admin/curator-profile';
import { AppRoutes } from 'core/helpers';
import { CuratorsApi } from 'core/api/admin';

import { BackNavigation, Loader } from 'components/_others';
import { Lightbox } from './components/Lightbox';
import { Stats } from './components/Stats';
import { Bio } from './components/Bio';
import { ChangeUserRole } from '../../../../../components/_dashboard/_user-profiles/ChangeUserRole';
import { UserRoles } from '../../../../../core/enums';
import { UtilsContext } from '../../../../../core/context/utils.context';
import { AssignJobBtn } from '../../../../../components/jobs/AssignJobBtn';
import { AssignJobModal } from '../../../../../components/jobs/AssignJobModal';
import { ActiveJobsList } from '../../../../../components/jobs/ActiveJobsList';

const useStyles = makeStyles((theme: ThemeOptions) => ({
    root: {
        border: '1px solid',
        borderColor: theme.divider.main,
        marginTop: 20,
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px',
    },
    profileBox: {
        width: '46%',
    },
    artifactsBox: {
        width: '49%',
    },
    divider: {
        width: '40%',
        borderTop: '1px solid',
        borderColor: theme.divider.main,
        margin: '20px 0',
    },
    jobsBox: {
        width: '49%',
        borderLeft: '1px solid',
        borderColor: theme.divider.main,
        paddingLeft: 15,
    },
}));

export const CuratorProfile: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const curatorProfileContext = useContext(CuratorProfileContext);

    const [isLoading, setIsLoading] = useState(true);
    const utilsContext = useContext(UtilsContext);

    const [totalJobs, setTotalJobs] = useState({ totalJobs: 0, totalActiveJobs: 0 });
    const [refreshJobs, setRefreshJobs] = useState(0);
    const [displayJobsModal, toggleJobsModal] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('id');

        if (!id) {
            history.push(AppRoutes.CURATOR_LISTING);
        }

        CuratorsApi.getCuratorProfile(Number(id))
            .then((res: any) => {
                curatorProfileContext.setData(res.data);
                setTotalJobs({ totalJobs: res.data.totalJobs, totalActiveJobs: res.data.totalActiveJobs });
                window.scrollTo(0, 0);
                setIsLoading(false);
            })
            .catch((error: any) => {
                console.error(error.response);
                history.push(AppRoutes.CURATOR_LISTING);
                utilsContext.addSnackbar('error');
            });
    }, []);

    return (
        <Box>
            {!isLoading && (
                <>
                    <BackNavigation />

                    <Box className={classes.root}>
                        <Box className={classes.profileBox}>
                            <Lightbox user={curatorProfileContext.data} hasActiveJobs={totalJobs.totalActiveJobs > 0} />

                            <div className={classes.divider} />

                            <ChangeUserRole currentRole={UserRoles.CURATOR} user={curatorProfileContext.data} />

                            <div className={classes.divider} />

                            <Bio user={curatorProfileContext.data} />
                        </Box>

                        <Box className={classes.jobsBox}>
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Stats totalJobs={totalJobs.totalJobs} totalActiveJobs={totalJobs.totalActiveJobs} />

                                {curatorProfileContext.data.isActive && (
                                    <AssignJobBtn text={'Assign Collector'} onClick={() => toggleJobsModal(true)} />
                                )}

                                {displayJobsModal && (
                                    <AssignJobModal
                                        open={true}
                                        handleClose={() => {
                                            toggleJobsModal(false);
                                            setRefreshJobs(refreshJobs + 1);
                                        }}
                                        title={'Assign Collector'}
                                        jobRole={UserRoles.COLLECTOR}
                                        userId={curatorProfileContext.data.userId}
                                        userRole={UserRoles.CURATOR}
                                    />
                                )}
                            </Box>

                            {curatorProfileContext.data.isActive && (
                                <>
                                    <div className={classes.divider} />
                                    <ActiveJobsList
                                        userId={curatorProfileContext.data.userId}
                                        role={UserRoles.CURATOR}
                                        refresh={refreshJobs}
                                        hasHistory={totalJobs.totalJobs > totalJobs.totalActiveJobs}
                                        onJobStatistics={(jobs: any) => setTotalJobs(jobs)}
                                    />
                                </>
                            )}
                        </Box>
                    </Box>
                </>
            )}

            {isLoading && <Loader />}
        </Box>
    );
};
