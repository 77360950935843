import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Backdrop,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    Fade,
    FormControlLabel,
    Modal,
    ThemeOptions,
    Typography,
} from '@material-ui/core';
import ReactStars from 'react-rating-stars-component';
import { modalStyles } from 'components/_others/modals/modal-styles';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import imagePlaceholder from 'assets/placeholders/artifact-placeholder.png';
import { IFeedbackResult } from 'core/context/feedback-requests/feedback';
import { FileSize, UserRoles } from 'core/enums';
import { InfoText } from '../../_others/InfoText';
import { getEstimatedDisplayValue, getPhotoUrl } from 'core/helpers';
import moment from 'moment';
import { CollectorApi } from '../../../core/api/consumer';
import { Chat } from '../../../scenes/consumer/objectDetails/components/Chat';
import { UtilsContext } from '../../../core/context/utils.context';
import { FeedbackRequestsApi } from '../../../core/api/feedback-requests/feedback-requests.api';
import { UserContext } from '../../../core/context/user';

type FeedbackResultModalProps = {
    open: boolean;
    photo: string;
    artifactId: number;
    feedback: IFeedbackResult;
    handleClose: () => void;
    isOwnArtifact?: boolean;
};

const useStyles = makeStyles((theme: ThemeOptions) => ({
    lightbox: {
        display: 'flow-root',
        marginBottom: 10,
    },
    photo: {
        width: '50%',
        float: 'left',
        paddingRight: 10,
        paddingBottom: 5,
    },
    label: {
        color: '#B2B2B2',
        fontSize: 13,
    },
    value: {
        fontSize: 13,
    },
    sectionTitle: {
        fontSize: 17,
        fontWeight: 'bold',
        borderBottom: '2px solid',
        maxWidth: '50%',
        borderColor: `${theme.divider.main}!important`,
        marginBottom: 10,
    },
    taxonomy: {
        backgroundColor: theme.label.main,
        color: theme.label.text,
        fontSize: 13,
        marginRight: 5,
        padding: '2px 7px',
        borderRadius: 2,
        marginBottom: 5,
    },
    taxonomyContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 5,
        marginBottom: 10,
    },
}));

export const FeedbackResultModal: React.FC<FeedbackResultModalProps> = ({
    open,
    handleClose,
    isOwnArtifact,
    photo,
    artifactId,
    feedback,
}) => {
    const classes = modalStyles();
    const styles = useStyles();

    const utilsContext = useContext(UtilsContext);
    const userContext = useContext(UserContext);

    const [importFeedback, setImportFeedback] = useState(feedback.isImported);
    const [infoUsage, setInfoUsage] = useState({
        insurance: feedback.usagePurpose.findIndex((el) => el === 'Insurance') > -1,
        estate: feedback.usagePurpose.findIndex((el) => el === 'Estate Planning') > -1,
        sale: feedback.usagePurpose.findIndex((el) => el === 'Object Sale') > -1,
    });
    const [rating, setRating] = useState(feedback.rating || 0);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (userContext.user?.role !== UserRoles.COLLECTOR) {
            return;
        }

        FeedbackRequestsApi.markFeedbackAsRead(feedback.id)
            .then(() => {
                feedback.isRead = true;
            })
            .catch((error: any) => {
                console.error(error.data);
            });
    }, []);

    const onSave = (): void => {
        setIsLoading(true);

        const usagePurpose = [];
        if (infoUsage.insurance) {
            usagePurpose.push('Insurance');
        }
        if (infoUsage.estate) {
            usagePurpose.push('Estate Planning');
        }
        if (infoUsage.sale) {
            usagePurpose.push('Object Sale');
        }

        const payload = {
            isImported: importFeedback,
            rating,
            usagePurpose,
        };

        CollectorApi.updateFeedbackResult(feedback.id, payload)
            .then(() => {
                setIsLoading(true);
                utilsContext.addSnackbar('success', 'Thank you!');
                history.go(0);
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(true);
                utilsContext.addSnackbar('error');
            });
    };

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open} style={{ maxHeight: '600px', overflow: 'auto', paddingTop: 0 }}>
                <div className={classes.paper}>
                    <Box
                        className={classes.modalHeader}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        top={'0'}
                        paddingTop={'15px'}
                    >
                        <div>
                            <Typography className={classes.modalTitle}>Feedback from expert</Typography>
                            <Divider className={classes.divider} />
                        </div>
                        <CloseIcon cursor={'pointer'} onClick={handleClose} />
                    </Box>

                    {isOwnArtifact && (
                        <Typography style={{ fontSize: '13px', color: '#B2B2B2' }}>
                            You have requested feedback from our experts for this object, bellow you will see the
                            feedback.
                        </Typography>
                    )}

                    <Box marginTop={'10px'} marginBottom={'20px'}>
                        <InfoText
                            fontSize={11}
                            text={'Remember that Artifact recommends you to get a second opinion from another expert'}
                        />
                    </Box>

                    <Box className={styles.lightbox}>
                        <img
                            src={getPhotoUrl(photo, FileSize.SM) || imagePlaceholder}
                            className={styles.photo}
                            alt={'photo'}
                        />
                        <div>
                            <Typography className={styles.label}>Statement from Expert</Typography>
                            <Typography color={'textPrimary'} className={styles.value}>
                                {feedback.statement}
                            </Typography>
                        </div>
                    </Box>

                    <>
                        <Typography className={styles.sectionTitle} color={'secondary'}>
                            About the object
                        </Typography>

                        <Typography className={styles.label}>What is this Object?</Typography>
                        <Typography color={'textPrimary'} className={styles.value} style={{ marginBottom: 7 }}>
                            {feedback.category}
                        </Typography>

                        <Typography className={styles.label}>What is the approximate age?</Typography>
                        <Typography color={'textPrimary'} className={styles.value} style={{ marginBottom: 7 }}>
                            {feedback.exactProductionYear !== null && (
                                <>
                                    {feedback.exactProductionYear < 0
                                        ? `${feedback.exactProductionYear * -1} BC`
                                        : feedback.exactProductionYear}
                                </>
                            )}
                            {feedback.lowerProductionYear !== null && feedback.upperProductionYear !== null && (
                                <>
                                    {feedback.lowerProductionYear < 0
                                        ? `${feedback.lowerProductionYear * -1} BC`
                                        : feedback.lowerProductionYear}
                                    {' - '}
                                    {feedback.upperProductionYear < 0
                                        ? `${feedback.upperProductionYear * -1} BC`
                                        : feedback.upperProductionYear}
                                </>
                            )}
                        </Typography>

                        <Typography className={styles.label}>What is the Material?</Typography>
                        <Typography color={'textPrimary'} className={styles.value} style={{ marginBottom: 7 }}>
                            {feedback.material}
                        </Typography>

                        <Typography className={styles.label}>What is the Style?</Typography>
                        <Typography color={'textPrimary'} className={styles.value} style={{ marginBottom: 7 }}>
                            {feedback.style}
                        </Typography>

                        <Typography className={styles.label}>What is the Origin?</Typography>
                        <Typography color={'textPrimary'} className={styles.value} style={{ marginBottom: 7 }}>
                            {feedback.origin}
                        </Typography>

                        <Typography className={styles.label}>What is the Condition?</Typography>
                        <Typography color={'textPrimary'} className={styles.value} style={{ marginBottom: 7 }}>
                            {feedback.condition}
                        </Typography>

                        <Typography className={styles.label}>Who is the Artist or Maker?</Typography>
                        <Typography color={'textPrimary'} className={styles.value} style={{ marginBottom: 7 }}>
                            {feedback.artist}
                        </Typography>

                        <Typography className={styles.label}>Artist Bio</Typography>
                        <Typography color={'textPrimary'} className={styles.value} style={{ marginBottom: 7 }}>
                            {feedback.artistBio}
                        </Typography>
                    </>

                    <>
                        <Typography className={styles.sectionTitle} color={'secondary'} style={{ marginTop: 15 }}>
                            Value
                        </Typography>
                        <Typography className={styles.label}>Approximate value?</Typography>
                        <Typography color={'textPrimary'} className={styles.value} style={{ marginBottom: 7 }}>
                            {getEstimatedDisplayValue(
                                feedback.exactValue,
                                feedback.minimumValue,
                                feedback.maximumValue,
                            )}
                        </Typography>
                    </>

                    <>
                        <Typography className={styles.sectionTitle} color={'secondary'} style={{ marginTop: 15 }}>
                            Labels
                        </Typography>
                        <Typography color={'textPrimary'} style={{ fontSize: 13 }}>
                            These labels are based on the feedback provided by
                            <b>
                                {' '}
                                {feedback.expert.firstName} {feedback.expert.lastName}{' '}
                            </b>
                            on {moment(feedback.dateCreated).format('MM/DD/YYYY')}.
                        </Typography>

                        <Typography className={styles.label}>
                            Category {feedback.categories.length === 0 ? ': none' : ''}
                        </Typography>
                        <Box className={styles.taxonomyContainer}>
                            {feedback.categories.map((c) => (
                                <span key={c.id} className={styles.taxonomy}>
                                    {c.name}
                                </span>
                            ))}
                        </Box>

                        <Typography className={styles.label}>
                            Material {feedback.materials.length === 0 ? ': none' : ''}
                        </Typography>
                        <Box className={styles.taxonomyContainer}>
                            {feedback.materials.map((c) => (
                                <span key={c.id} className={styles.taxonomy}>
                                    {c.name}
                                </span>
                            ))}
                        </Box>

                        <Typography className={styles.label}>
                            Style {feedback.styles.length === 0 ? ': none' : ''}
                        </Typography>
                        <Box className={styles.taxonomyContainer}>
                            {feedback.styles.map((c) => (
                                <span key={c.id} className={styles.taxonomy}>
                                    {c.name}
                                </span>
                            ))}
                        </Box>

                        <Typography className={styles.label}>
                            Origin {feedback.origins.length === 0 ? ': none' : ''}
                        </Typography>
                        <Box className={styles.taxonomyContainer}>
                            {feedback.origins.map((c) => (
                                <span key={c.id} className={styles.taxonomy}>
                                    {c.name}
                                </span>
                            ))}
                        </Box>

                        <Typography className={styles.label}>
                            Condition {feedback.conditions.length === 0 ? ': none' : ''}
                        </Typography>
                        <Box className={styles.taxonomyContainer}>
                            {feedback.conditions.map((c) => (
                                <span key={c.id} className={styles.taxonomy}>
                                    {c.name}
                                </span>
                            ))}
                        </Box>

                        <Typography className={styles.label}>
                            Era {feedback.eras.length === 0 ? ': none' : ''}
                        </Typography>
                        <Box className={styles.taxonomyContainer}>
                            {feedback.eras.map((c) => (
                                <span key={c.id} className={styles.taxonomy}>
                                    {c.name}
                                </span>
                            ))}
                        </Box>
                    </>

                    <>
                        <Typography className={styles.sectionTitle} color={'secondary'} style={{ marginTop: 15 }}>
                            Others
                        </Typography>
                        <Typography className={styles.label}>Personal comments and recommendations:</Typography>
                        <Typography color={'textPrimary'} className={styles.value} style={{ marginBottom: 7 }}>
                            {feedback.comment}
                        </Typography>
                    </>

                    {(isOwnArtifact || userContext.user?.role === UserRoles.ADMIN) && (
                        <>
                            <Typography
                                className={styles.sectionTitle}
                                color={'secondary'}
                                style={{ marginTop: 15, maxWidth: 300 }}
                            >
                                Do you want to import this feedback?
                            </Typography>
                            <Box marginTop={'10px'} marginBottom={'10px'}>
                                <InfoText
                                    fontSize={11}
                                    text={
                                        'This means that your information about this object will be updated' +
                                        ' with this feedback,' +
                                        ' along with the labels that will help you organize your objects'
                                    }
                                />
                            </Box>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="checkedB"
                                        color="secondary"
                                        disabled={!isOwnArtifact}
                                        checked={importFeedback}
                                        onChange={() => setImportFeedback(true)}
                                    />
                                }
                                label={
                                    <Typography className="checkbox-label" color="textPrimary">
                                        Yes
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="checkedB"
                                        color="secondary"
                                        disabled={!isOwnArtifact}
                                        checked={!importFeedback}
                                        onChange={() => setImportFeedback(false)}
                                    />
                                }
                                label={
                                    <Typography className="checkbox-label" color="textPrimary">
                                        No
                                    </Typography>
                                }
                            />
                        </>
                    )}

                    {(isOwnArtifact || userContext.user?.role === UserRoles.ADMIN) && (
                        <>
                            <Typography
                                className={styles.sectionTitle}
                                color={'secondary'}
                                style={{ marginTop: 15, maxWidth: 330 }}
                            >
                                How are you going to use this information?
                            </Typography>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="checkedB"
                                        color="secondary"
                                        disabled={!isOwnArtifact}
                                        checked={infoUsage.insurance}
                                        onChange={() => setInfoUsage({ ...infoUsage, insurance: !infoUsage.insurance })}
                                    />
                                }
                                label={
                                    <Typography className="checkbox-label" color="textPrimary">
                                        Insurance
                                    </Typography>
                                }
                            />
                            <br />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="checkedB"
                                        color="secondary"
                                        disabled={!isOwnArtifact}
                                        checked={infoUsage.estate}
                                        onChange={() => setInfoUsage({ ...infoUsage, estate: !infoUsage.estate })}
                                    />
                                }
                                label={
                                    <Typography className="checkbox-label" color="textPrimary">
                                        Estate Planning
                                    </Typography>
                                }
                            />
                            <br />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="checkedB"
                                        color="secondary"
                                        disabled={!isOwnArtifact}
                                        checked={infoUsage.sale}
                                        onChange={() => setInfoUsage({ ...infoUsage, sale: !infoUsage.sale })}
                                    />
                                }
                                label={
                                    <Typography className="checkbox-label" color="textPrimary">
                                        Object Sale
                                    </Typography>
                                }
                            />
                        </>
                    )}

                    {(isOwnArtifact || userContext.user?.role === UserRoles.ADMIN) && (
                        <>
                            <Typography className={styles.sectionTitle} color={'secondary'} style={{ marginTop: 15 }}>
                                Rate this feedback
                            </Typography>

                            {isOwnArtifact && (
                                <ReactStars
                                    count={5}
                                    value={rating}
                                    onChange={(newRating: number) => setRating(newRating)}
                                    size={24}
                                    disabled={!isOwnArtifact}
                                    activeColor="#FE6502"
                                    style={{ outline: 'none' }}
                                />
                            )}

                            {userContext.user?.role === UserRoles.ADMIN && <Typography>{rating}/5</Typography>}

                            {feedback.comments && (
                                <Box marginBottom={5}>
                                    <Typography
                                        className={styles.sectionTitle}
                                        color={'secondary'}
                                        style={{ marginTop: 15 }}
                                    >
                                        Chat History
                                    </Typography>

                                    <Chat
                                        artifactId={artifactId || -1}
                                        feedbackRequestId={feedback.id}
                                        isFeedbackCompleted={true}
                                        comments={feedback.comments}
                                    />
                                </Box>
                            )}
                        </>
                    )}

                    <Box textAlign={'right'} marginTop={'20px'}>
                        <Button
                            id="cancel-delete-btn"
                            variant="contained"
                            size={'small'}
                            style={{ marginRight: '15px' }}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Close
                        </Button>

                        {isOwnArtifact && (
                            <Button
                                id="delete-btn"
                                variant="contained"
                                color="primary"
                                size={'small'}
                                className={classes.primaryButton}
                                onClick={() => onSave()}
                            >
                                {isLoading && <CircularProgress className="circular-progress sm" />}
                                {!isLoading && (
                                    <SaveIcon
                                        style={{
                                            width: 20,
                                            marginRight: 5,
                                        }}
                                    />
                                )}
                                Save
                            </Button>
                        )}
                    </Box>
                </div>
            </Fade>
        </Modal>
    );
};
