import React, { useContext, useState } from 'react';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { UserRoles } from '../../../core/enums';
import { ManageUsersApi } from '../../../core/api/admin/manage-users.api';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from '../../../core/helpers';
import { UtilsContext } from '../../../core/context/utils.context';
import { IArchivistProfile } from 'core/context/admin/archivist-profile';
import { IExpertProfile } from 'core/context/admin/expert-profile';
import { ICollectorProfile } from 'core/context/admin/collector-profile';
import { ICuratorProfile } from 'core/context/admin/curator-profile';

type ChangeUserRoleProps = {
    user: IArchivistProfile | IExpertProfile | ICollectorProfile | ICuratorProfile;
    currentRole: string;
};

export const ChangeUserRole: React.FC<ChangeUserRoleProps> = ({ user, currentRole }) => {
    const history = useHistory();

    const [role, setRole] = useState(currentRole);
    const utilsContext = useContext(UtilsContext);

    const onSelectRole = (role: string): void => {
        const confirmMessage = `Are you sure you want to change the user's role to ${role}?`;
        const answer = confirm(confirmMessage);
        if (answer) {
            setRole(role);
            ManageUsersApi.changeUserRole(user.userId, role)
                .then(() => {
                    if (role === UserRoles.COLLECTOR) {
                        history.push(`${AppRoutes.ADMIN_COLLECTOR_PROFILE}?id=${user.userId}`);
                    }

                    if (role === UserRoles.EXPERT) {
                        history.push(`${AppRoutes.ADMIN_EXPERT_PROFILE}?id=${user.userId}`);
                    }

                    if (role === UserRoles.ARCHIVIST) {
                        history.push(`${AppRoutes.ADMIN_ARCHIVIST_PROFILE}?id=${user.userId}`);
                    }

                    if (role === UserRoles.CURATOR) {
                        history.push(`${AppRoutes.ADMIN_CURATOR_PROFILE}?id=${user.userId}`);
                    }
                    utilsContext.addSnackbar('success', 'Success! Please wait...');
                })
                .catch((error: any) => {
                    console.error(error?.response);
                    utilsContext.addSnackbar('error');
                });
        }
    };

    return (
        <>
            <InputLabel htmlFor={'change-user-role'} style={{ marginBottom: 5 }}>
                Change Role
            </InputLabel>
            <Select
                variant="outlined"
                id="change-user-role"
                value={role}
                fullWidth
                disabled={!user.isActive}
                style={{ maxWidth: '200px' }}
                onChange={(e: any) => {
                    onSelectRole(e.target.value);
                }}
            >
                <MenuItem value={UserRoles.EXPERT}>Expert</MenuItem>
                <MenuItem value={UserRoles.COLLECTOR}>Collector</MenuItem>
                <MenuItem value={UserRoles.ARCHIVIST}>Archivist</MenuItem>
                <MenuItem value={UserRoles.CURATOR}>Curator</MenuItem>
            </Select>
        </>
    );
};
