export function getCollectorAndArtifactIdFromURLSearchParams(): {collectorId: string, artifactId: string} {

    const urlSearchParams = new URLSearchParams(window.location.search);
    const collectorId = urlSearchParams.get('collectorId');
    const artifactId = urlSearchParams.get('artifactId');

    return {
        collectorId: collectorId || '',
        artifactId: artifactId || ''
    }
}

export function isInvalidURLSearchParams(collectorId: string, artifactId: string): boolean {
    return !collectorId || !artifactId || isNaN(Number(collectorId)) || isNaN(Number(artifactId));
}
