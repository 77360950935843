import React, { useEffect, useState } from "react";
import { IPhoto } from "core/context/consumer/objectDetails";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { getPhotoUrl } from "core/helpers";
import { FileSize } from "core/enums";

type PhotoLightboxProps = {
    open: boolean;
    index: number;
    handleClose: () => void;
    photos: IPhoto[]|string[];
}

export const PhotoLightbox: React.FC<PhotoLightboxProps> = (
    {photos, open, index, handleClose}
) => {

    const [photoIndex, setPhotoIndex] = useState(index);

    useEffect(() => {
        setPhotoIndex(index);
    }, [index])

    const photoUrl = (photo: string|IPhoto): string => {
        if(typeof photo === 'string') {
            return photo;
        }
        return photo?.url || '';
    }

    return (
        <>
            {
                open &&
                <Lightbox
                    mainSrc={
                        getPhotoUrl(photoUrl(photos[photoIndex] || ''), FileSize.LG) || ''
                    }
                    nextSrc={
                        getPhotoUrl(photoUrl(photos[(photoIndex + 1) % photos.length] || ''), FileSize.LG) || ''
                    }
                    prevSrc={
                        getPhotoUrl(
                            photoUrl(photos[(photoIndex + photos.length - 1) % photos.length] || ''),
                            FileSize.LG
                        ) || ''
                    }
                    onCloseRequest={handleClose}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + photos.length - 1) % photos.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photos.length)}
                />
            }
        </>
    )
}
