import React, {useContext} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {Link, ListItem, Typography} from "@material-ui/core";

import {UserContext} from "core/context/user";
import {INavLink} from "../_helpers/nav-link.interfaces";

type Props = {
    navLink: INavLink;
    onClick?: () => void
}

const activeLinkColor = (isLightTheme: boolean): string => {
    return isLightTheme ? 'black' : '#FFFFFF';
}

const inactiveLinkColor = (isLightTheme: boolean): string => {
    return isLightTheme ? '#6c6c6c' : '#B2B2B2';
}

export const MobileMenuNavLink: React.FC<Props> = ({navLink, onClick}) => {

    const history = useHistory();
    const userContext = useContext(UserContext);

    const isRouteMatch = (path: string, exact: boolean): any => {
        return useRouteMatch({
            path, exact
        });
    }

    return (
        <Link
            onClick={() => {history.push(navLink.path); onClick ? onClick() : {}}}
            style={
                navLink.onAuthPages || isRouteMatch(navLink.path, true)
                    ? {color: activeLinkColor(userContext.lightTheme)}
                    : {color: inactiveLinkColor(userContext.lightTheme)}
            }
        >
            <ListItem>
                <Typography variant="h6">
                    {navLink.name}
                </Typography>
            </ListItem>
        </Link>
    )
}
