import React, {useEffect} from "react";
import {useAssignedUsersData} from "../_hooks/use-assigned-users-data.hook";
import {UserRoles} from "../../../core/enums";
import {Box, Typography} from "@material-ui/core";
import {Loader} from "../..";
import {ActiveJob} from "../ActiveJob";
import {IJob} from "../../../types";

type AssignedUsersListProps = {
    userId: number;
}

export const AssignedUsersList: React.FC<AssignedUsersListProps> = ({userId}) => {

    const {
        archivists,
        getAssignedArchivists,
        curators,
        getAssignedCurators,
        isLoading,
        pagination
    } = useAssignedUsersData(userId);

    useEffect(() => {
        getAssignedArchivists(1);
        getAssignedCurators(1);
    }, [])

    console.log(pagination)

    return (
        <Box>
            {
                !isLoading &&
                    <>
                        <Typography>Archivists</Typography>
                        {
                            archivists.map((a: IJob) => (
                                <ActiveJob
                                    job={a}
                                    key={a.userId}
                                    jobOwnerId={a.userId}
                                    role={UserRoles.ARCHIVIST}
                                    isOld={false}
                                    onRemoveJob={() => {
                                        getAssignedArchivists(1);
                                        getAssignedCurators(1);
                                    }}
                                    jobRole={UserRoles.ARCHIVIST}
                                    collectorId={userId}
                                />
                            ))
                        }

                        {
                            archivists.length === 0 &&
                            <Typography color={'textSecondary'} style={{fontSize: 13}}>
                                There are no assigned archivists.
                            </Typography>
                        }

                        <Typography style={{marginTop: 15}}>Curator</Typography>
                        {
                            curators.map((c: IJob) => (
                                <ActiveJob
                                    key={c.userId}
                                    job={c}
                                    jobOwnerId={c.userId}
                                    role={UserRoles.CURATOR}
                                    isOld={false}
                                    onRemoveJob={() => null}
                                    jobRole={UserRoles.CURATOR}
                                    collectorId={userId}
                                />
                            ))
                        }

                        {
                            curators.length === 0 &&
                            <Typography color={'textSecondary'} style={{fontSize: 13}}>
                                There is no curator assigned.
                            </Typography>
                        }
                    </>
            }

            {
                isLoading && <Loader />
            }
        </Box>
    )
}
