import React, {useContext, useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Box, Container, Typography } from "@material-ui/core";

import { AppRoutes } from "core/helpers";
import { NotificationsApi } from "core/api/user";
import {INotification, UserContext} from "core/context/user";

import { Empty } from "./Empty";
import { NotificationItem } from "./NotificationItem";
import { Loader } from "components/_others";
import {UtilsContext} from "../../../../core/context/utils.context";

const useStyles = makeStyles(() => ({
    root: {
        padding: '0 30px 15px'
    },
    pageTitle: {
        fontSize: 26,
        fontWeight: 400,
        opacity: 0.8
    },
    itemsContainer: {
        maxWidth: 500
    },
    sectionTitle: {
        fontSize: 18,
        fontWeight: 400,
        borderBottom: '1px solid',
        width: 150,
    },
    emptySectionText: {
        fontSize: 14,
        marginTop: 10,
    },
    newNotificationsBox: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    readAllText: {
        fontSize: 13,
        cursor: 'pointer',
        opacity: 0.8
    }
}))

export const Notifications: React.FC = () => {

    const classes = useStyles();
    const history = useHistory();
    const userContext = useContext(UserContext);

    const [notifications, setNotifications] = useState({new: [], old: []});
    const [isLoading, setIsLoading] = useState(true);
    const utilsContext = useContext(UtilsContext);
    useEffect(() => {
        getNotifications();
        window.scrollTo(0, 0);
    }, [])

    const getNotifications = (): void => {
        NotificationsApi.getNotifications()
            .then((res: any) => {
                setNotifications({
                    new: res.data.newNotifications || [],
                    old: res.data.oldNotifications || []
                });
                if(res.data.newNotifications?.length > 0) {
                    userContext.setHasNewNotifications(true);
                } else {
                    userContext.setHasNewNotifications(false);
                }
                setIsLoading(false);
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar('error');
                history.push(AppRoutes.MY_OBJECTS);
            })
    }

    const markAsRead = (notificationIds: number[]): void => {
        NotificationsApi.markAsRead(notificationIds)
            .then(() => {
                getNotifications();
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error');
            })
    }

    return (
        <Container maxWidth={false} className={classes.root}>
            {
                !isLoading &&
                    <>
                        <Box>
                            <Typography
                                component="h5"
                                color="textSecondary"
                                className={classes.pageTitle}
                            >
                                Notifications
                            </Typography>
                        </Box>

                        {
                            (notifications.new.length > 0 || notifications.old.length > 0) &&
                            <Container className={classes.itemsContainer}>

                                <Box className={classes.newNotificationsBox}>
                                    <Typography color="textPrimary" className={classes.sectionTitle}>
                                        New
                                    </Typography>
                                    {
                                        notifications.new.length > 0 &&
                                        <Typography
                                            color="textPrimary"
                                            className={classes.readAllText}
                                            onClick={() => markAsRead(
                                                notifications.new.map((n: INotification) => n.id)
                                            )}
                                        >
                                            Read all
                                        </Typography>
                                    }
                                </Box>


                                {
                                    notifications.new.length === 0 &&
                                    <Typography className={classes.emptySectionText} style={{marginBottom: '15px'}}>
                                        You are up to date!
                                    </Typography>
                                }

                                {
                                    notifications.new.map((notification: INotification) => (
                                        <NotificationItem
                                            notification={notification}
                                            onClick={() => markAsRead([notification.id])}
                                        />
                                    ))
                                }

                                <Typography color="textPrimary" className={classes.sectionTitle}>
                                    Older
                                </Typography>

                                {
                                    notifications.old.map((notification: INotification) => (
                                        <NotificationItem
                                            notification={notification}
                                            onClick={() => null}
                                        />
                                    ))
                                }

                                {
                                    notifications.old.length === 0 &&
                                    <Typography className={classes.emptySectionText}>
                                        You are up to date!
                                    </Typography>
                                }
                            </Container>
                        }

                        {
                            notifications.new.length === 0 && notifications.old.length === 0 &&
                            <Empty />
                        }
                    </>
            }

            {
                isLoading &&
                <Loader />
            }
        </Container>
    )
}
