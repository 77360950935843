import { Box, Container } from '@material-ui/core';
import { Gallery } from 'components/Gallery';
import { GalleryTypeEnum } from 'components/Gallery/helpers/GalleryTypeEnum';
import { SortCollectionsOptions } from 'core/enums';
import { getCurrentPageFromUrl, getPageSizeFromUrl, getSortPreference } from 'core/helpers/utils';
import React, { useEffect, useState } from 'react';
import { Empty } from 'scenes/consumer/MyCollections/components/Empty';
import { RemoveCollectionModal } from 'scenes/consumer/_components/RemoveCollectionModal';
import { getSortingOptions } from '../_helpers/collections-sorting.helper';
import { PageHeader } from './components/PageHeader';
import { useMyCollectionsData } from './hooks/use-my-collections-data.hook';

export const MyCollections: React.FC = () => {
    const [displayRemoveCollectionModal, toggleRemoveCollectionModal] = useState(-1);
    const [selectedSortBy, setSelectedSortBy] = useState('');

    const { collections, getCollections, pagination, handlePageChange, handlePageSizeChange, handleSortByChange } =
        useMyCollectionsData();

    const getCollectionsAsync = async (): Promise<void> => {
        const page = getCurrentPageFromUrl();
        const pageSize = getPageSizeFromUrl(100);
        const preferences = (await getSortPreference('collection')) || SortCollectionsOptions.DateCreated;
        setSelectedSortBy(preferences.sortPreference);
        getCollections(page, pageSize, preferences.sortPreference, preferences.ascPreference);
    };

    useEffect(() => {
        void getCollectionsAsync();
    }, []);

    return (
        <Container maxWidth={false} style={{ padding: '0 30px 15px' }}>
            {collections?.length > 0 && (
                <>
                    <PageHeader
                        totalCount={pagination.totalCount}
                        sortingOptions={getSortingOptions(pagination.sortBy, pagination.asc)}
                        onSortByChange={(option: string) => handleSortByChange(option)}
                        sortByValue={selectedSortBy}
                    />

                    <Box>
                        <Gallery
                            pagination={{
                                numberOfPages: pagination.numberOfPages,
                                currentPage: pagination.page,
                                pageSize: pagination.size,
                            }}
                            galleryType={GalleryTypeEnum.Collections}
                            data={collections}
                            handlePageChange={(page: number) => handlePageChange(page)}
                            onObjectDelete={(id: number) => toggleRemoveCollectionModal(id)}
                            handlePageSizeChange={(size: number) => handlePageSizeChange(size)}
                        />
                    </Box>
                </>
            )}

            {collections?.length === 0 && <Empty />}

            {displayRemoveCollectionModal !== -1 && (
                <RemoveCollectionModal
                    collectionId={displayRemoveCollectionModal}
                    open={true}
                    handleClose={(result) => {
                        if (result) {
                            getCollections(pagination.page, pagination.size, pagination.sortBy, pagination.asc);
                        }
                        toggleRemoveCollectionModal(-1);
                    }}
                />
            )}
        </Container>
    );
};
