import React, {useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    InputAdornment,
    TextField,
    ThemeOptions,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

import {StatisticsApi} from "core/api/admin";
import {AdminStatistics} from "core/enums";
import {NumberInput} from "../../../../../components/_others/NumberInput";


export const Statistics: React.FC = () => {

    const classes = useStyles();

    const [numberOfDays, setNumberOfDays] = useState(90);
    const [showInactiveUsers, setShowInactiveUsers] = useState(false);

    const downloadStatistics = (type: string): void => {
        StatisticsApi.getStatistics(type, numberOfDays, showInactiveUsers)
            .then((res: any) => {
                window.open(res.data, '_blank')?.focus();
            })
            .catch((error: any) => {
                console.error(error.response);
            })
    }

    return (
        <Box>
            <Typography variant="h5" color="textPrimary">
                Statistics
            </Typography>

            <Box className={classes.container}>
                <Typography variant="h6" color="textPrimary" className={classes.infoText}>
                    Download statistics by...
                </Typography>

                <Box marginTop={2}>
                    <Button
                        id="cancel-delete-btn"
                        variant="contained"
                        size={"small"}
                        style={{marginRight: '15px'}}
                        onClick={() => downloadStatistics(AdminStatistics.Date)}
                    >
                        Date
                    </Button>

                    <Button
                        id="cancel-delete-btn"
                        variant="contained"
                        size={"small"}
                        style={{marginRight: '15px'}}
                        onClick={() => downloadStatistics(AdminStatistics.User)}
                    >
                        User
                    </Button>

                    <Button
                        id="cancel-delete-btn"
                        variant="contained"
                        size={"small"}
                        style={{marginRight: '15px'}}
                        onClick={() => downloadStatistics(AdminStatistics.UserByDay)}
                    >
                        UserID By Day
                    </Button>

                    <Button
                        id="cancel-delete-btn"
                        variant="contained"
                        size={"small"}
                        style={{marginRight: '15px'}}
                        onClick={() => downloadStatistics(AdminStatistics.TotalUsersStats)}
                    >
                        UserID (Total Stats)
                    </Button>

                    <Button
                        id="cancel-delete-btn"
                        variant="contained"
                        size={"small"}
                        onClick={() => downloadStatistics(AdminStatistics.TotalUsersByDate)}
                    >
                        Total Users By Date
                    </Button>
                </Box>

                <Box marginTop={2}>
                    <Typography variant="h6" color="textPrimary" className={classes.infoText}>
                        For the last...
                    </Typography>

                    <TextField
                        id="numberOfDays"
                        variant="outlined"
                        size="small"
                        type="number"
                        style={{marginTop: 15}}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">days</InputAdornment>,
                            inputComponent: NumberInput,
                        }}
                        value={numberOfDays}
                        onChange={(e: any) => setNumberOfDays(e.target.value)}
                    />
                </Box>

                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="checkedB"
                                color="secondary"
                                checked={showInactiveUsers}
                                onChange={() => setShowInactiveUsers(!showInactiveUsers)}
                            />
                        }
                        label={
                            <Typography className="checkbox-label" color="textPrimary">
                                Show Inactive Users (used by Total Users By Date only)
                            </Typography>
                        }
                    />
                </Box>
            </Box>


        </Box>
    )
}

const useStyles = makeStyles((_theme: ThemeOptions) => ({
    container: {
        marginTop: 30,
        width: '100%',
        border: '1px solid',
        borderRadius: 1,
        padding: '20px 15px',
    },
    infoText: {
        fontSize: 15,
    }
}))
