import { Box, CircularProgress, Container, Grid, Typography } from '@material-ui/core';
import { Loader } from 'components/layout/_components/Loader';
import { ObjectComments } from 'components/object/ObjectComments';
import { ObjectLabels } from 'components/_consumer/ObjectDetails';
import { Gallery } from 'components/_consumer/ObjectDetails/Gallery';
import { RecommendedExpertsModal } from 'components/_consumer/ObjectDetails/RecommendedExperts/RecommendedExpertsModal';
import 'components/_consumer/ObjectDetails/styles/style.less';
import { ObjectForm } from 'components/_consumer/ObjectForm';
import { IObject } from 'components/_consumer/ObjectForm/helpers/object.interface';
import { ShareObjectToPersonsModal } from 'components/_consumer/share-object/ShareObjectToPersonsModal';
import { ShareObjectToPublicFeedModal } from 'components/_consumer/share-object/ShareObjectToPublicFeedModal';
import { UnsavedChangesModal } from 'components/_others/UnsavedChangesModal';
import { IDocument, IPhoto, ObjectDetailsContext } from 'core/context/consumer/objectDetails';
import { getMyObjectsRoute } from 'core/helpers/app-routes';
import { getEstimatedDisplayValue } from 'core/helpers/artifact-value.helper';
import { isEqual } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AboutObject } from 'scenes/consumer/objectDetails/components/AboutObject';
import { MissingInformationModal } from 'scenes/consumer/objectDetails/components/Feedback/MissingInformationModal';
import { Header } from 'scenes/consumer/objectDetails/components/Header';
import { SelectShareOptionModal } from '../../../../../components/_consumer/share-object/SelectShareOptionModal';
import { ShareArtifactOptions, UserRoles } from '../../../../../core';
import { ObjectApi } from '../../../../../core/api';
import { GlobalContext } from '../../../../../core/context/global';
import { UserContext } from '../../../../../core/context/user';
import { UtilsContext } from '../../../../../core/context/utils.context';
import { splitArrayIntoChunksOfLen } from '../../../../../core/helpers/utils';
import { RemoveObjectModal } from '../../../_components/RemoveObjectModal';
import { RequestAssessmentModal } from '../../components/Feedback/RequestAsessmentModal';
import { ReorderPhotosModal } from '../../components/ReorderPhotosModal';
import { computeMissingFields } from '../../helpers/missing-info-helper';

export const ObjectDetails: React.FC = () => {
    const history = useHistory();
    const objectDetailsContext = useContext(ObjectDetailsContext);

    const globalContext = useContext(GlobalContext);
    const userContext = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(true);
    const [hasErrors, setErrors] = useState(false);
    const [displayDeleteButtonInGallery, setDisplayDeleteButtonInGallery] = useState<boolean>(true);
    const [values, setValues] = useState<IObject>({
        title: '',
        description: '',
        height: '',
        width: '',
        depth: '',
        weight: '',
        material: '',
        style: '',
        origin: '',
        condition: '',
        artist: '',
        artistBio: '',
        exactProductionYear: '',
        lowerProductionYear: '',
        upperProductionYear: '',
        productionYearOption: '',
        exactYearNotation: 'AD',
        lowerYearNotation: 'AD',
        upperYearNotation: 'AD',
        era: '',
        exactValue: '',
        minimumValue: '',
        maximumValue: '',
        estimatedValueOption: '',
        artistImageUrl: '',
        categories: objectDetailsContext.artifact.categories,
        collections: objectDetailsContext.artifact.collections,
    });

    const [hasEditChanges, setHasEditChanges] = useState(false);
    const [photos, setPhotos] = useState<Array<string | IPhoto>>([]);
    const [documents, setDocuments] = useState<IDocument[]>([]);
    const [newDocuments, setNewDocuments] = useState<File[]>([]);
    const [shouldLeavePage, toggleShouldLeavePage] = useState(false);
    const [updateLoading, setUpdateLoading] = useState({ artifact: false, documents: false, photos: false });
    const utilsContext = useContext(UtilsContext);
    const [showSharedToPublicFeed, setShowSharedToPublicFeed] = useState(true);

    const [displayReorderPhotosModal, toggleReorderPhotosModal] = useState(false);
    const [loadingPhotos, setLoadingPhotos] = useState(
        globalContext.uploadPhotosBatch1 || globalContext.uploadPhotosBatch2 || globalContext.uploadPhotosBatch3,
    );

    useEffect(() => {
        if (
            globalContext.shouldReloadBatch1 &&
            !globalContext.uploadPhotosBatch1 &&
            objectDetailsContext.artifact.id !== 0
        ) {
            setTimeout(() => {
                globalContext.setShouldReloadBatch1(false);
                getArtifact(objectDetailsContext.artifact.id);
                setLoadingPhotos(
                    globalContext.uploadPhotosBatch1 ||
                        globalContext.uploadPhotosBatch2 ||
                        globalContext.uploadPhotosBatch3,
                );
            }, 1000);
        }
    }, [globalContext.uploadPhotosBatch1]);

    useEffect(() => {
        if (
            globalContext.shouldReloadBatch2 &&
            !globalContext.uploadPhotosBatch2 &&
            objectDetailsContext.artifact.id !== 0
        ) {
            globalContext.setShouldReloadBatch2(false);
            getArtifact(objectDetailsContext.artifact.id);
            setLoadingPhotos(
                globalContext.uploadPhotosBatch1 ||
                    globalContext.uploadPhotosBatch2 ||
                    globalContext.uploadPhotosBatch3,
            );
        }
    }, [globalContext.uploadPhotosBatch2]);

    useEffect(() => {
        if (
            globalContext.shouldReloadBatch3 &&
            !globalContext.uploadPhotosBatch3 &&
            objectDetailsContext.artifact.id !== 0
        ) {
            globalContext.setShouldReloadBatch3(false);
            getArtifact(objectDetailsContext.artifact.id);
            setLoadingPhotos(
                globalContext.uploadPhotosBatch1 ||
                    globalContext.uploadPhotosBatch2 ||
                    globalContext.uploadPhotosBatch3,
            );
        }
    }, [globalContext.uploadPhotosBatch3]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('id');
        const assess = params.get('assess'); //the url has &assess=open when this component is reached from add and assess button on object create
        if (!id) {
            history.push(getMyObjectsRoute(userContext.user?.role));
        }
        getArtifact(Number(id));
        if (assess === 'open') {
            objectDetailsContext.modals.toggleRequestAssessmentModal();
        }
    }, []);

    const getArtifact = (id: number): void => {
        ObjectApi.getArtifact(id)
            .then((res: any) => {
                objectDetailsContext.setArtifact(res.data, false);
                objectDetailsContext.setEditMode(false);
                setObjectDetails(res.data);
                setIsLoading(false);
                window.scrollTo(0, 0);
            })
            .catch((error: any) => {
                console.error(error.response);
                history.push(getMyObjectsRoute(userContext.user?.role));
                utilsContext.addSnackbar(
                    'error',
                    'Sorry, there was an error and we were unable to display your object.' + ' Please try again later.',
                );
            });
    };

    const setObjectDetails = (data: any): void => {
        setValues({
            title: data.title,
            description: data.description,
            height: data.height || '',
            width: data.width || '',
            depth: data.depth || '',
            weight: data.weight || '',
            material: data.material || '',
            style: data.style || '',
            origin: data.origin || '',
            condition: data.condition || '',
            artist: data.artist || '',
            artistBio: data.artistBio || '',
            exactProductionYear: data.exactProductionYear !== null ? Math.abs(data.exactProductionYear) : '',
            lowerProductionYear: data.lowerProductionYear !== null ? Math.abs(data.lowerProductionYear) : '',
            upperProductionYear: data.upperProductionYear !== null ? Math.abs(data.upperProductionYear) : '',
            productionYearOption:
                data.exactProductionYear !== null ? 'exact' : data.lowerProductionYear !== null ? 'range' : '',
            exactYearNotation: data.exactProductionYear < 0 ? 'BC' : 'AD',
            lowerYearNotation: data.lowerProductionYear < 0 ? 'BC' : 'AD',
            upperYearNotation: data.upperProductionYear < 0 ? 'BC' : 'AD',
            era: data.era || '',
            exactValue: data.exactValue || '',
            minimumValue: data.minimumValue || '',
            maximumValue: data.maximumValue || '',
            estimatedValueOption: data.exactValue !== null ? 'exact' : data.minimumValue !== null ? 'range' : '',
            categories: data.categories,
            collections: data.collections,
            artistImageUrl: data.artistImageUrl,
        });

        data.photos.forEach((photo: IPhoto) => (photo.deleted = false));
        data.documents.forEach((document: IDocument) => (document.deleted = false));
        setPhotos(data.photos);
        setDocuments(data.documents.slice());
    };

    const cancelEditMode = (resetValues = true): void => {
        if (resetValues) {
            setObjectDetails(objectDetailsContext.artifact);
            if (objectDetailsContext.modals.displayUnsavedChangesModal) {
                objectDetailsContext.modals.toggleUnsavedChangesModal();
            }
            setPhotos(objectDetailsContext.artifact.photos);
        }
        objectDetailsContext.setEditMode(false);
        window.scrollTo(0, 0);

        setHasEditChanges(false);
    };

    const markPhotoAsDeleted = (id: number): void => {
        setPhotos(
            photos.map((photo) => {
                if ((photo as IPhoto).id === id && !(photo as IPhoto).deleted) {
                    return {
                        ...(photo as IPhoto),
                        deleted: true,
                    };
                }
                return photo;
            }),
        );
        setHasEditChanges(true);
    };

    useEffect(() => {
        if (!hasEditChanges) {
            if (
                photos.filter((photo) => (photo as IPhoto).deleted).length !==
                objectDetailsContext.artifact.photos.filter((photo) => photo.deleted).length
            ) {
                objectDetailsContext.setPhotos(photos.filter((p) => typeof p !== 'string') as IPhoto[]);
            }
        }
    }, [hasEditChanges]);

    const markDocumentAsDeleted = (id: number): void => {
        const document = documents.find((document: IDocument) => document.id === id);
        if (document) {
            document.deleted = true;
            setDocuments(documents.slice());
        }
    };

    const shouldUpdateArtifact = (): boolean => {
        if (!shouldDisableEdit()) {
            return true;
        }
        return !isEqual(values.collections, objectDetailsContext.artifact.collections);
    };

    const shouldDisableEdit = (): boolean => {
        if (objectDetailsContext.artifact.feedbackRequests.findIndex((f) => !f.isCompleted) === -1) {
            return false;
        }

        const activeFeedback = objectDetailsContext.artifact.feedbackRequests.find((f) => !f.isCompleted);
        return !(activeFeedback && activeFeedback.comments?.length > 0);
    };

    const saveObject = (bypassPhoto = false): void => {
        if (objectDetailsContext.modals.displayUnsavedPhotosModal && !bypassPhoto) {
            objectDetailsContext.modals.toggleUnsavedChangesModal();
            return;
        }

        objectDetailsContext.modals.toggleDisplayUnsavedPhotosModal(false);

        if (hasErrors) {
            return;
        }

        setIsLoading(true);
        setUpdateLoading({ ...updateLoading, artifact: true });

        if (shouldUpdateArtifact()) {
            ObjectApi.updateObject(values, objectDetailsContext.artifact.id)
                .then((res: any) => {
                    setUpdateLoading({ ...updateLoading, artifact: false });
                    if (!updateLoading.photos && !updateLoading.documents) {
                        objectDetailsContext.setArtifact(res.data, true);
                        cancelEditMode(false);
                        setIsLoading(false);
                    }
                })
                .catch((error: any) => {
                    console.error(error.response);
                    console.error(error);
                    setIsLoading(false);
                    setUpdateLoading({ documents: false, photos: false, artifact: false });
                    utilsContext.addSnackbar('error');
                });
        } else {
            setUpdateLoading({ ...updateLoading, artifact: false });
            setIsLoading(false);
        }

        documents
            .filter((document: IDocument) => document.deleted && !document.wasDeleted)
            .forEach((document: IDocument) => {
                ObjectApi.deleteDocument(document.id)
                    .then(() => {
                        document.wasDeleted = true;
                        cancelEditMode(false);
                        setIsLoading(false);
                    })
                    .catch((error: any) => {
                        setIsLoading(false);
                        console.error(error.response);
                        utilsContext.addSnackbar(
                            'error',
                            'Sorry, there was an error and we were unable to ' + 'delete your documents.',
                        );
                    });
            });

        if (newDocuments.length) {
            setIsLoading(true);
            setUpdateLoading({ ...updateLoading, documents: true });
            ObjectApi.addFile(objectDetailsContext.artifact.id, newDocuments)
                .then((res: any) => {
                    setUpdateLoading({ ...updateLoading, documents: false });
                    objectDetailsContext.addNewDocuments(res.data);
                    setDocuments(documents.concat(res.data).slice());
                    setNewDocuments([]);
                    if (!updateLoading.artifact && !updateLoading.photos) {
                        cancelEditMode(false);
                        setIsLoading(false);
                    }
                })
                .catch((error: any) => {
                    console.error(error.response);
                    setUpdateLoading({ ...updateLoading, documents: false });
                    utilsContext.addSnackbar(
                        'error',
                        'Sorry, there was an error and we were unable to add your documents.',
                    );
                });
        }

        const newPhotos = photos.filter((p) => typeof p === 'string');
        if (newPhotos.length) {
            const uploadPhotosChunks = splitArrayIntoChunksOfLen(newPhotos, Math.ceil(newPhotos.length / 3));

            if (uploadPhotosChunks[0]?.length) {
                globalContext.setShouldReloadBatch1(true);
                globalContext.setUploadPhotosBatch1(true);
                setLoadingPhotos(
                    globalContext.uploadPhotosBatch1 ||
                        globalContext.uploadPhotosBatch2 ||
                        globalContext.uploadPhotosBatch3,
                );
                ObjectApi.addPhoto(objectDetailsContext.artifact.id, uploadPhotosChunks[0], 0)
                    .then(() => {
                        globalContext.setUploadPhotosBatch1(false);
                        const photosToDelete = photos
                            .filter(
                                (photo) =>
                                    (photo as IPhoto).deleted &&
                                    !(photo as IPhoto).wasDeleted &&
                                    typeof photo !== 'string',
                            )
                            .map((p) => (p as IPhoto).id);

                        if (photosToDelete.length) {
                            ObjectApi.deletePhoto(photosToDelete)
                                .then(() => {
                                    setPhotos(
                                        photos.map((photo) => {
                                            if (photosToDelete.includes((photo as IPhoto).id && (photo as IPhoto).id)) {
                                                return {
                                                    ...(photo as IPhoto),
                                                    deleted: true,
                                                    wasDeleted: true,
                                                };
                                            }
                                            return photo;
                                        }),
                                    );
                                    objectDetailsContext.setPhotos(
                                        objectDetailsContext.artifact.photos.map((photo) => {
                                            if (photosToDelete.includes(photo.id)) {
                                                return {
                                                    ...photo,
                                                    deleted: false,
                                                    wasDeleted: true,
                                                };
                                            }
                                            return photo;
                                        }),
                                    );
                                    cancelEditMode(false);
                                    setIsLoading(false);
                                })
                                .catch((error: any) => {
                                    console.error(error.response);
                                    setIsLoading(false);
                                    utilsContext.addSnackbar(
                                        'error',
                                        'Sorry, there was an error and we were unable ' + 'to delete your photos.',
                                    );
                                });
                        } else {
                            if (!updateLoading.artifact) {
                                cancelEditMode(false);
                                setIsLoading(false);
                            }
                        }
                    })
                    .catch((error: any) => {
                        console.error(error);
                        setUpdateLoading({ ...updateLoading, photos: false });
                        utilsContext.addSnackbar(
                            'error',
                            'Sorry, there was an error and some of your photos were ' +
                                'not saved. Please try again later.',
                        );
                    });
            }

            if (uploadPhotosChunks[1]?.length) {
                globalContext.setShouldReloadBatch2(true);
                globalContext.setUploadPhotosBatch2(true);
                setLoadingPhotos(
                    globalContext.uploadPhotosBatch1 ||
                        globalContext.uploadPhotosBatch2 ||
                        globalContext.uploadPhotosBatch3,
                );
                ObjectApi.addPhoto(objectDetailsContext.artifact.id, uploadPhotosChunks[1], 1)
                    .then(() => {
                        globalContext.setUploadPhotosBatch2(false);
                        if (!updateLoading.artifact) {
                            cancelEditMode(false);
                            setIsLoading(false);
                        }
                    })
                    .catch((error: any) => {
                        console.error(error);
                        setUpdateLoading({ ...updateLoading, photos: false });
                        utilsContext.addSnackbar(
                            'error',
                            'Sorry, there was an error and some of your photos were ' +
                                'not saved. Please try again later.',
                        );
                    });
            }

            if (uploadPhotosChunks[2]?.length) {
                globalContext.setShouldReloadBatch3(true);
                globalContext.setUploadPhotosBatch3(true);
                setLoadingPhotos(
                    globalContext.uploadPhotosBatch1 ||
                        globalContext.uploadPhotosBatch2 ||
                        globalContext.uploadPhotosBatch3,
                );
                ObjectApi.addPhoto(objectDetailsContext.artifact.id, uploadPhotosChunks[2], 2)
                    .then(() => {
                        globalContext.setUploadPhotosBatch3(false);
                        if (!updateLoading.artifact) {
                            cancelEditMode(false);
                            setIsLoading(false);
                        }
                    })
                    .catch((error: any) => {
                        console.error(error);
                        setUpdateLoading({ ...updateLoading, photos: false });
                        utilsContext.addSnackbar(
                            'error',
                            'Sorry, there was an error and some of your photos were not' +
                                ' saved. Please try again later.',
                        );
                    });
            }
        } else {
            const photosToDelete = photos
                .filter(
                    (photo) => (photo as IPhoto).deleted && !(photo as IPhoto).wasDeleted && typeof photo !== 'string',
                )
                .map((p) => (p as IPhoto).id);
            if (photosToDelete.length) {
                ObjectApi.deletePhoto(photosToDelete)
                    .then(() => {
                        setPhotos(
                            photos.map((photo) => {
                                if (photosToDelete.includes((photo as IPhoto).id && (photo as IPhoto).id)) {
                                    return {
                                        ...(photo as IPhoto),
                                        deleted: true,
                                        wasDeleted: true,
                                    };
                                }
                                return photo;
                            }),
                        );
                        objectDetailsContext.setPhotos(
                            objectDetailsContext.artifact.photos.map((photo) => {
                                if (photosToDelete.includes(photo.id)) {
                                    return {
                                        ...photo,
                                        deleted: false,
                                        wasDeleted: true,
                                    };
                                }
                                return photo;
                            }),
                        );

                        cancelEditMode(false);
                        setIsLoading(false);
                    })
                    .catch((error: any) => {
                        console.error(error.response);
                        setIsLoading(false);
                        utilsContext.addSnackbar(
                            'error',
                            'Sorry, there was an error and we were unable to delete' + ' your photos.',
                        );
                    });
            }
        }
    };

    useEffect(() => {
        if (objectDetailsContext.artifact) {
            if (objectDetailsContext.artifact.feedbackRequests.length) {
                if (objectDetailsContext.artifact.feedbackRequests.findIndex((f) => !f.isCompleted) > -1) {
                    setDisplayDeleteButtonInGallery(
                        objectDetailsContext.artifact.photos.filter((p) => !p.wasDeleted).length > 1,
                    );
                } else {
                    setDisplayDeleteButtonInGallery(true);
                }
            } else {
                setDisplayDeleteButtonInGallery(
                    objectDetailsContext.artifact.photos.filter((p) => !p.wasDeleted).length > 1,
                );
            }
        }
    }, [objectDetailsContext.artifact.photos]);

    const onDeleteFromGallery = (id: number): void => {
        objectDetailsContext.setPhotos(
            objectDetailsContext.artifact.photos.map((p) => {
                if (p.id === id) {
                    return {
                        ...p,
                        wasDeleted: true,
                    };
                }
                return p;
            }),
        );
    };

    return (
        <Container maxWidth={false} className="object-details-container">
            {!isLoading && (
                <>
                    <Header
                        name={objectDetailsContext.artifact.title}
                        detailsPercent={objectDetailsContext.artifact.detailsPercent || 0}
                        isOnEditMode={objectDetailsContext.onEditMode}
                        onSaveObject={() => saveObject()}
                        noOfRecommendedExperts={objectDetailsContext.artifact.experts.length}
                        paymentFreePass={
                            userContext.user?.paymentFreePass || objectDetailsContext.artifact.paymentFreePass || false
                        }
                        isSharedToPublicFeed={
                            objectDetailsContext.artifact.isSharedToPublicFeed && showSharedToPublicFeed
                        }
                        toggleEditMode={(newState: boolean, onBack: boolean) => {
                            if (!newState) {
                                if (onBack) {
                                    toggleShouldLeavePage(true);
                                }
                                if (hasEditChanges) {
                                    objectDetailsContext.modals.toggleUnsavedChangesModal();
                                } else {
                                    cancelEditMode();
                                }
                            } else {
                                objectDetailsContext.setEditMode(newState);
                            }
                        }}
                    />

                    <Box className="page-content-container">
                        <Grid container spacing={6}>
                            <Grid item sm={12} md={12} lg={6}>
                                <Box className={'object-details-left-side'}>
                                    <Grid container style={{ marginBottom: 10, alignItems: 'center', marginTop: 5 }}>
                                        <Grid item xs={12} sm={12} md={5} lg={5}>
                                            <Typography
                                                className="estimated-value-stats"
                                                style={userContext.lightTheme ? { color: 'black' } : {}}
                                            >
                                                Estimated Value:
                                                <span className="estimated-value-amount">
                                                    {getEstimatedDisplayValue(
                                                        objectDetailsContext.artifact.exactValue,
                                                        objectDetailsContext.artifact.minimumValue,
                                                        objectDetailsContext.artifact.maximumValue,
                                                    )}
                                                </span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={7} lg={7}>
                                            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                                                <Typography
                                                    className="estimated-value-stats"
                                                    style={
                                                        userContext.lightTheme
                                                            ? { color: 'black', fontSize: 14, marginRight: 10 }
                                                            : { fontSize: 14, marginRight: 10 }
                                                    }
                                                >
                                                    Item number:{' '}
                                                    <span>
                                                        <b>#{objectDetailsContext.artifact.id}</b>
                                                    </span>
                                                </Typography>
                                                <Typography
                                                    className="estimated-value-stats"
                                                    style={
                                                        userContext.lightTheme
                                                            ? { color: 'black', fontSize: 14, marginRight: 10 }
                                                            : { fontSize: 14, marginRight: 10 }
                                                    }
                                                >
                                                    Time Stamp:{' '}
                                                    <span>
                                                        <b>
                                                            {moment(
                                                                objectDetailsContext.artifact.createdDate.replace(
                                                                    'T',
                                                                    'Z',
                                                                ),
                                                            ).format('HH:mm a')}
                                                        </b>
                                                    </span>
                                                </Typography>
                                                <Typography
                                                    className="estimated-value-stats"
                                                    style={
                                                        userContext.lightTheme
                                                            ? { color: 'black', fontSize: 14, marginRight: 10 }
                                                            : { fontSize: 14, marginRight: 10 }
                                                    }
                                                >
                                                    Date:{' '}
                                                    <span>
                                                        <b>
                                                            {moment(
                                                                objectDetailsContext.artifact.createdDate.replace(
                                                                    'T',
                                                                    'Z',
                                                                ),
                                                            ).format('MM/DD/YYYY')}
                                                        </b>
                                                    </span>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Gallery
                                        photos={objectDetailsContext.artifact.photos.filter(
                                            (p) => !p.deleted || !p.wasDeleted,
                                        )}
                                        isMyObject={!objectDetailsContext.onEditMode}
                                        displayDeleteButton={displayDeleteButtonInGallery}
                                        artifactId={objectDetailsContext.artifact.id}
                                        onPhotoDeleteSuccess={onDeleteFromGallery}
                                    />

                                    {loadingPhotos && (
                                        <Box display={'flex'} alignItems={'center'} marginTop={'10px'}>
                                            <CircularProgress className="circular-progress" />
                                            <Typography color={'primary'} style={{ fontSize: 13 }}>
                                                We are loading your photos, please wait...
                                            </Typography>
                                        </Box>
                                    )}

                                    {!loadingPhotos &&
                                        objectDetailsContext.artifact.photos?.filter((p) => !p.deleted).length > 1 &&
                                        !objectDetailsContext.onEditMode && (
                                            <Box marginTop={'5px'}>
                                                <Typography
                                                    color="secondary"
                                                    style={{ cursor: 'pointer', width: 'fit-content' }}
                                                    onClick={() => toggleReorderPhotosModal(true)}
                                                >
                                                    Reorder Photos
                                                </Typography>
                                                <ReorderPhotosModal
                                                    open={displayReorderPhotosModal}
                                                    handleClose={() => toggleReorderPhotosModal(false)}
                                                    photos={objectDetailsContext.artifact.photos.filter(
                                                        (p) => !p.deleted,
                                                    )}
                                                />
                                            </Box>
                                        )}

                                    {objectDetailsContext.artifact.isFeedbackImported && (
                                        <ObjectLabels
                                            canDeleteLabels={false}
                                            artifactId={objectDetailsContext.artifact.id}
                                            categories={objectDetailsContext.artifact.categories}
                                            materials={objectDetailsContext.artifact.materials}
                                            styles={objectDetailsContext.artifact.styles}
                                            origins={objectDetailsContext.artifact.origins}
                                            conditions={objectDetailsContext.artifact.conditions}
                                            eras={objectDetailsContext.artifact.eras}
                                            setNewCategories={() => null}
                                        />
                                    )}
                                </Box>
                            </Grid>
                            {!objectDetailsContext.onEditMode && (
                                <>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Box marginTop={'30px'}>
                                            <AboutObject object={objectDetailsContext.artifact} />

                                            <Box marginTop={'20px'}>
                                                <ObjectComments
                                                    objectId={objectDetailsContext.artifact.id}
                                                    userIsObjectOwner={true}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </>
                            )}
                            {objectDetailsContext.onEditMode && (
                                <Grid item sm={12} md={12} lg={6}>
                                    <Box marginTop={'30px'}>
                                        <ObjectForm
                                            onAddNew={false}
                                            values={values}
                                            disableEditFields={shouldDisableEdit()}
                                            photos={photos.filter(
                                                (p) => !(p as IPhoto).deleted && !(p as IPhoto).wasDeleted,
                                            )}
                                            documents={documents.filter((d) => !d.wasDeleted)}
                                            setValues={(values: IObject) => {
                                                setValues(values);
                                                setHasEditChanges(true);
                                            }}
                                            setFormErrors={(hasErrors: boolean) => setErrors(hasErrors)}
                                            setPhotos={setPhotos}
                                            setDocuments={(documents: File[]) => setNewDocuments(documents)}
                                            onPhotoDelete={(id: number) => markPhotoAsDeleted(id)}
                                            onDocumentDelete={(id: number) => markDocumentAsDeleted(id)}
                                        />
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>

                    {objectDetailsContext.modals.displayConfirmDelete && (
                        <RemoveObjectModal
                            objectId={objectDetailsContext.artifact.id}
                            open={true}
                            handleClose={() => objectDetailsContext.modals.toggleConfirmDeleteModal()}
                            onRemove={() => history.push(getMyObjectsRoute(userContext.user?.role))}
                        />
                    )}

                    <UnsavedChangesModal
                        open={objectDetailsContext.modals.displayUnsavedChangesModal}
                        handleClose={() => objectDetailsContext.modals.toggleUnsavedChangesModal()}
                        onConfirm={() => {
                            if (objectDetailsContext.modals.displayUnsavedPhotosModal) {
                                objectDetailsContext.modals.toggleUnsavedChangesModal();
                                saveObject(true);
                            } else {
                                cancelEditMode();
                                if (shouldLeavePage) {
                                    history.goBack();
                                }
                            }
                        }}
                    />

                    <SelectShareOptionModal
                        artifactId={objectDetailsContext.artifact.id}
                        isSharedToPublicFeed={objectDetailsContext.artifact.isSharedToPublicFeed}
                        //canBeSharedToFeed={objectDetailsContext.artifact.feedbacks?.length > 0}
                        canBeSharedToFeed={true}
                        open={objectDetailsContext.modals.displayShareObjectOptionsModal}
                        handleClose={() => objectDetailsContext.modals.toggleShareObjectOptionsModal()}
                        onSelect={(type: number) => {
                            if (type === ShareArtifactOptions.People) {
                                objectDetailsContext.modals.toggleShareObjectToPersonsModal();
                            }
                            if (type === ShareArtifactOptions.PublicFeed) {
                                objectDetailsContext.modals.toggleShareObjectToFeedModal();
                            }
                            objectDetailsContext.modals.toggleShareObjectOptionsModal();
                        }}
                        onRemove={() => {
                            objectDetailsContext.artifact.isSharedToPublicFeed = false;
                            setShowSharedToPublicFeed(false);
                        }}
                    />

                    <ShareObjectToPublicFeedModal
                        artifactId={objectDetailsContext.artifact.id}
                        artifact={{
                            title: objectDetailsContext.artifact.title,
                            description: objectDetailsContext.artifact.description,
                            photo: objectDetailsContext.artifact.photos.length
                                ? objectDetailsContext.artifact.photos[0].url
                                : null,
                        }}
                        open={objectDetailsContext.modals.displayShareObjectToFeedModal}
                        handleClose={() => objectDetailsContext.modals.toggleShareObjectToFeedModal()}
                        onShareSuccess={() => {
                            objectDetailsContext.artifact.isSharedToPublicFeed = true;
                        }}
                    />

                    <ShareObjectToPersonsModal
                        artifactId={objectDetailsContext.artifact.id}
                        title={objectDetailsContext.artifact.title}
                        description={objectDetailsContext.artifact.description}
                        photo={
                            objectDetailsContext.artifact.photos.length
                                ? objectDetailsContext.artifact.photos[0].url
                                : null
                        }
                        open={objectDetailsContext.modals.displayShareObjectToPersonsModal}
                        handleClose={() => objectDetailsContext.modals.toggleShareObjectToPersonsModal()}
                    />
                </>
            )}
            {isLoading && <Loader />}

            <MissingInformationModal
                open={objectDetailsContext.modals.displayMissingInfoModal}
                handleClose={() => objectDetailsContext.modals.toggleMissingInfoModal()}
                missingFields={computeMissingFields(objectDetailsContext.artifact)}
                primaryAction={() => {
                    objectDetailsContext.setEditMode(true);
                    objectDetailsContext.modals.toggleMissingInfoModal();
                }}
                secondaryAction={() => {
                    objectDetailsContext.modals.toggleRequestAssessmentModal();
                    objectDetailsContext.modals.toggleMissingInfoModal();
                }}
            />

            <RequestAssessmentModal
                paymentFreePass={
                    userContext.user?.role === UserRoles.COLLECTOR
                        ? userContext.user.paymentFreePass
                        : objectDetailsContext.artifact.paymentFreePass
                }
                open={objectDetailsContext.modals.displayRequestAssessmentModal}
                object={objectDetailsContext.artifact}
                handleClose={() => objectDetailsContext.modals.toggleRequestAssessmentModal()}
            />

            <RecommendedExpertsModal
                open={objectDetailsContext.modals.displayRecommendedExpertsModal}
                handleClose={() => objectDetailsContext.modals.toggleRecommendedExpertsModal()}
                experts={objectDetailsContext.artifact.experts}
            />
        </Container>
    );
};
