import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, InputLabel, TextField } from '@material-ui/core';

type AddCommentProps = {
    onAddComment: (comment: string) => any;
};

export const AddComment: React.FC<AddCommentProps> = ({ onAddComment }) => {
    const classes = useStyles();

    const [comment, setComment] = useState('');

    const onAddCommentBtnClick = (): void => {
        if (!comment?.trim().length) {
            return;
        }
        onAddComment(comment);
        setComment('');
    };

    return (
        <form id="add-comment-form" className={classes.root}>
            <InputLabel htmlFor="add-comment-textarea" className={classes.label}>
                Add a comment
            </InputLabel>
            <TextField
                id="add-comment-textarea"
                multiline
                fullWidth
                value={comment}
                onChange={(e: any) => setComment(e.target.value)}
                rows={2}
                rowsMax={5}
                inputProps={{ maxLength: 2000 }}
                variant="outlined"
            />
            <Button
                variant="outlined"
                color="primary"
                id="add-comment-btn"
                className={classes.button}
                onClick={() => onAddCommentBtnClick()}
            >
                Add Comment
            </Button>
        </form>
    );
};

const useStyles = makeStyles({
    root: {
        marginTop: 15,
    },
    label: {
        marginBottom: 15,
        fontSize: 14,
    },
    button: {
        float: 'right',
        marginTop: 10,
        padding: '0px 15px',
        fontWeight: 300,
        fontSize: 16,
    },
});
