import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

type SearchboxProps = {
    searchBy: string;
    setSearchBy: (value: string) => void;
    onSearch: (value: string) => void;
    onClear: () => void;
};

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        height: 35,
        marginLeft: 0,
        width: '100%',
        backgroundColor: theme.rootBackground.secondary,
        borderColor: theme.rootBackground.progressBarBackground,
        border: '1px solid',
        fontSize: 13,
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(3),
            maxWidth: 180,
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#B2B2B2',
    },
    closeIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        color: '#B2B2B2',
        right: 0,
        top: 0,
    },
    inputRoot: {
        color: theme.label.text,
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '40ch',
        },
    },
}));

export const Searchbox: React.FC<SearchboxProps> = ({ searchBy, setSearchBy, onSearch, onClear }) => {
    const classes = useStyles();

    const handleKeyPress = (e: any): void => {
        if (e.key === 'Enter') {
            onSearch(searchBy);
            e.preventDefault();
        }
    };

    return (
        <div className={`${classes.search} hide-on-mobile hide-small-screens`}>
            <div className={classes.searchIcon}>
                <SearchIcon onClick={() => onSearch(searchBy)} />
            </div>
            <InputBase
                placeholder="Search here"
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={searchBy}
                onChange={(e) => setSearchBy(e.target.value)}
                onKeyPress={(e: any) => handleKeyPress(e)}
            />
            <div className={classes.closeIcon}>
                <CloseIcon onClick={() => onClear()} style={{ visibility: searchBy ? 'visible' : 'hidden' }} />
            </div>
        </div>
    );
};
