import React, {useContext, useEffect, useState} from "react";
import { modalStyles } from "components/_others/modals/modal-styles";
import { Backdrop, Box, Button, CircularProgress, Fade, Modal, TextField, Typography } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

import { TaxonomyApi } from "core/api/admin";
import {UtilsContext} from "../../../../../../core/context/utils.context";

type AddNewLabelModalProps = {
    open: boolean;
    parentId: number;
    handleClose: () => void;
    onSuccess: () => void;
}

export const AddNewLabelModal: React.FC<AddNewLabelModalProps> = (
    {open, parentId, handleClose, onSuccess}
) => {

    const classes = modalStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [labelName, setLabelName] = useState({text: '', error: ''});
    const utilsContext = useContext(UtilsContext);
    useEffect(() => {
        setLabelName({text: '', error: ''})
    }, [parentId])

    const onSave = (): void => {
        if(!labelName.text.length) {
            setLabelName({...labelName, error: 'Please enter a name'});
            return;
        }

        TaxonomyApi.addLabel({parentId, name: labelName.text})
            .then(() => {
                setIsLoading(false);
                onSuccess();
                utilsContext.addSnackbar('success', 'The label was saved.')
                handleClose();
            })
            .catch((error: any) => {
                console.error(error.response);
                if(error.response.data.message === 'LabelAlreadyExists') {
                    utilsContext.addSnackbar('error', 'There is already a label with this name.')
                } else {
                    utilsContext.addSnackbar('error');
                }
                setIsLoading(false);
            })

        setIsLoading(true);
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Box textAlign={'right'}>
                        <CloseIcon cursor={'pointer'} onClick={handleClose} />
                    </Box>

                    <Box>
                        <Typography className={classes.modalTitle}>
                            Add Subcategory
                        </Typography>
                        <Typography className={classes.modalSubtitle} style={{marginBottom: 20}}>
                            You can type below a subcategory type.
                        </Typography>
                    </Box>

                    <Box>
                        <TextField
                            id="new-label-name"
                            variant="outlined"
                            size="small"
                            type="text"
                            placeholder="Name..."
                            fullWidth
                            error={labelName.error !== ''}
                            helperText={labelName.error}
                            value={labelName.text}
                            inputProps={{ maxLength: 100 }}
                            onChange={(e: any) => setLabelName({
                                text: e.target.value,
                                error: e.target.value.length === 0 ? 'Please enter a name' : ''
                            })}
                        />
                    </Box>

                    <Box textAlign={'right'} marginTop={'60px'}>
                        <Button
                            id="cancel-delete-btn"
                            variant="contained"
                            size={"small"}
                            style={{marginRight: '15px'}}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>

                        <Button
                            id="delete-btn"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            className={classes.primaryButton}
                            onClick={() => onSave()}
                        >
                            {
                                isLoading && <CircularProgress className="circular-progress sm" />
                            }
                            {
                                !isLoading && <SaveIcon style={{
                                    width: 20, marginRight: 5
                                }} />
                            }
                            Save
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    )
}
