import React, {useContext, useState} from "react";
import { ICategory } from "core/context/consumer/objectDetails";
import { Box, Typography } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import "./styles/object-labels.less";

import { ConfirmDeleteModal } from "components/_others/ConfirmDeleteModal";
import {UtilsContext} from "../../../core/context/utils.context";
import {ObjectApi} from "../../../core/api";

type ObjectLabelsProps = {
    canDeleteLabels: boolean;
    artifactId: number;
    categories: ICategory[];
    materials: ICategory[];
    styles: ICategory[];
    origins: ICategory[];
    conditions: ICategory[];
    eras: ICategory[];
    setNewCategories: (categories: ICategory[]) => void;
}

export const ObjectLabels: React.FC<ObjectLabelsProps> = (
    {
        canDeleteLabels, categories, materials, styles,
        origins, conditions, eras, artifactId, setNewCategories
    }
) => {

    const utilsContext = useContext(UtilsContext);

    const [deletedLabelId, setDeletedLabelId] = useState<number>(-1);
    const [isLoading, setIsLoading] = useState(false);

    const openConfirmDelete = (categoryId: number): void => {
        setDeletedLabelId(categoryId);
    }

    const onDelete = (): void => {
        const labelIndex = categories.findIndex((c: ICategory) => c.id === deletedLabelId);
        if(!labelIndex) {
            return;
        }

        setIsLoading(true);
        categories.splice(labelIndex, 1);

        ObjectApi.updateArtifactCategories(categories.map((c: ICategory) => c.id), artifactId)
            .then(() => {
                setNewCategories(categories);
                setDeletedLabelId(-1);
                setIsLoading(false);
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error');

            })
    }

    return (
        <Box>
            {
                categories.length > 0 &&
                    <>
                        <Typography className="object-labels-title">
                            Category
                        </Typography>
                        {
                            categories.map((category: ICategory) => (
                                <div key={category.id} className="object-label">
                                    {category.name}
                                    {
                                        canDeleteLabels &&
                                        <CloseIcon
                                            className="remove-label"
                                            onClick={() => openConfirmDelete(category.id)}
                                        />
                                    }
                                </div>
                            ))
                        }
                    </>
            }

            {
                materials.length > 0 &&
                    <>
                        <Typography className="object-labels-title">
                            Material
                        </Typography>
                        {
                            materials.map((category: ICategory) => (
                                <div key={category.id} className="object-label">
                                    {category.name}
                                    {
                                        canDeleteLabels &&
                                        <CloseIcon
                                            className="remove-label"
                                            onClick={() => openConfirmDelete(category.id)}
                                        />
                                    }
                                </div>
                            ))
                        }
                    </>
            }

            {
                styles.length > 0 &&
                    <>
                        <Typography className="object-labels-title">
                            Style
                        </Typography>
                        {
                            styles.map((category: ICategory) => (
                                <div key={category.id} className="object-label">
                                    {category.name}
                                    {
                                        canDeleteLabels &&
                                        <CloseIcon
                                            className="remove-label"
                                            onClick={() => openConfirmDelete(category.id)}
                                        />
                                    }
                                </div>
                            ))
                        }
                    </>
            }

            {
                origins.length > 0 &&
                    <>
                        <Typography className="object-labels-title">
                            Origin
                        </Typography>
                        {
                            origins.map((category: ICategory) => (
                                <div key={category.id} className="object-label">
                                    {category.name}
                                    {
                                        canDeleteLabels &&
                                        <CloseIcon
                                            className="remove-label"
                                            onClick={() => openConfirmDelete(category.id)}
                                        />
                                    }
                                </div>
                            ))
                        }
                    </>
            }

            {
                conditions.length > 0 &&
                    <>
                        <Typography className="object-labels-title">
                            Condition
                        </Typography>
                        {
                            conditions.map((category: ICategory) => (
                                <div key={category.id} className="object-label">
                                    {category.name}
                                    {
                                        canDeleteLabels &&
                                        <CloseIcon
                                            className="remove-label"
                                            onClick={() => openConfirmDelete(category.id)}
                                        />
                                    }
                                </div>
                            ))
                        }
                    </>
            }

            {
                eras.length > 0 &&
                    <>
                        <Typography className="object-labels-title">
                            Era
                        </Typography>
                        {
                            eras.map((category: ICategory) => (
                                <div key={category.id} className="object-label">
                                    {category.name}
                                    {
                                        canDeleteLabels &&
                                        <CloseIcon
                                            className="remove-label"
                                            onClick={() => openConfirmDelete(category.id)}
                                        />
                                    }
                                </div>
                            ))
                        }
                    </>
            }

            <ConfirmDeleteModal
                subtitle="Are you sure you want to delete this label?"
                isLoading={isLoading}
                open={deletedLabelId !== -1}
                handleClose={() => setDeletedLabelId(-1)}
                onConfirm={() => onDelete()}
            />
        </Box>
    )
}
