import * as api from './_utils/api';


export class ObjectCommentsApi {

    static async getObjectComments(objectId: number, page: number|null = null, size: number|null = null): Promise<any> {
        if(page !== null && size !== null) {
            return api._get(`comment/${objectId}/get?page=${page}&size=${size}`);
        }
        return api._get(`comment/${objectId}/get`);
    }

    static async addComment(objectId: number, comment: string): Promise<any> {
        return api._post(
            `comment/${objectId}/create`,
            JSON.stringify(comment),
            {'Content-Type': "application/json-patch+json"}
        );
    }

    static async editComment(commentId: number, comment: string): Promise<any> {
        return api._put(
            `comment/${commentId}/update`,
            JSON.stringify(comment),
            {'Content-Type': "application/json-patch+json"}
        )
    }

    static async deleteComment(commentId: number): Promise<any> {
        return api._delete(`comment/${commentId}/delete`)
    }
}
