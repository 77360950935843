import React, {useContext, useState} from "react";
import {Box, Button, ThemeOptions, Tooltip, Typography} from "@material-ui/core";
import {IArtifact} from "../../../../../../core/context/admin/collector-profile";
import {makeStyles} from "@material-ui/styles";
import {getPhotoUrl} from "../../../../../../core/helpers";
import {FileSize} from "../../../../../../core/enums";
import imagePlaceholder from "../../../../../../assets/placeholders/artifact-placeholder.png";
import RefreshIcon from '@material-ui/icons/Refresh';
import {CollectorsApi} from "../../../../../../core/api/admin";
import {UtilsContext} from "../../../../../../core/context/utils.context";

type DeletedObjectCardProps = {
    artifact: IArtifact;
}

export const DeletedObjectCard: React.FC<DeletedObjectCardProps> = ({artifact}) => {

    const classes = useStyles();

    const utilsContext = useContext(UtilsContext);

    const [isRestored, setIsRestored] = useState(false);


    const onRecover = (): void => {
        if(!confirm('Are you sure you want to recover this object?')) {
            return;
        }

        CollectorsApi.restoreObject(artifact.id)
            .then((_res: any) => {
                utilsContext.addSnackbar('success', 'This object was restored.');
                setIsRestored(true);
            })
            .catch((error: any) => {
                console.log(error);
                utilsContext.addSnackbar('error');
            })
    }

    return (
        <Box className={classes.root}>
            <img
                src={getPhotoUrl(artifact.photoUrl, FileSize.SM) || imagePlaceholder}
                alt={artifact.title}
                className={classes.img}
            />
            <Box className={classes.titleBox}>
                <Tooltip
                    arrow
                    interactive={true}
                    title={artifact.title}
                    className="full-artifact-name-tooltip"
                >
                    <Typography className={classes.title}>
                        {artifact.title}
                    </Typography>
                </Tooltip>
 
                <Button
                    id="restore-btn"
                    size={"small"}
                    variant="contained"
                    color="secondary"
                    disabled={isRestored}
                    onClick={() => onRecover()}
                    className={classes.restoreBtn}
                >
                    <RefreshIcon /> {isRestored ? 'Recovered' : 'Recover'}
                </Button>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles((_theme: ThemeOptions) => ({
    root: {
        width: '32%',
        marginBottom: 10,
        marginRight: '1.3%'
    },
    img: {
        width: '100%',
        height: 180,
        objectFit: 'cover'
    },
    titleBox: {
        marginTop: -6,
        backgroundColor: '#2B2B33',
        padding: '3px 10px',
    },
    title: {
        fontSize: 16,
        textTransform: 'capitalize',
        color: 'white',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginTop: 5,
    },
    restoreBtn: {
        width: '100%',
        marginTop: 5,
        marginBottom: 5,
    }
}))
