import React, {useContext, useEffect, useState} from "react";
import {Box, Container} from "@material-ui/core";

import {AppRoutes, getToken} from "core/helpers";
import {UserRoles} from "core/enums";
import {PrivateRoute} from "components/_others/PrivateRoute";
import {DashboardDrawer} from "components/layout/DashboardDrawer";

import {DashboardToolbar} from "components/layout/DashboardToolbar";
import {FeedbackRequestsListing} from "./feedbackRequests/scenes/FeedbackRequestsListing";
import {Taxonomy} from "./admin-settings/scenes/Taxonomy";
import {CollectorListing} from "./collectors/scenes/CollectorListing";
import {ExpertListing} from "./experts/scenes/ExpertListing";
import {ArchivistListing} from "./archivists/scenes/ArchivistListing";
import {CuratorListing} from "./curators/scenes/CuratorListing";

import {TaxonomyProvider} from "core/context/admin/taxonomy";
import {CollectorListingProvider} from "core/context/admin/collector-listing";
import {ExpertListingProvider} from "core/context/admin/expert-listing";
import {ArchivistListingProvider} from "../../core/context/admin/archivist-listing";
import {CuratorListingProvider} from "../../core/context/admin/curator-listing";
import {UserContext} from "../../core/context/user";
import {FeedbackRequestsListingProvider} from "../../core/context/feedback-requests/listing";
import {CollectorProfileProvider} from "../../core/context/admin/collector-profile";
import {CollectorProfile} from "./collectors/scenes/CollectorProfile";
import {FeedbackRequest} from "./feedbackRequests/scenes/FeedbackRequest";
import {Notifications} from "../auth/scenes/Notifications";
import {ArchivistProfileProvider} from "../../core/context/admin/archivist-profile";
import {ArchivistProfile} from "./archivists/scenes/ArchivistProfile";
import { CuratorProfileProvider } from "core/context/admin/curator-profile";
import {CuratorProfile} from "./curators/scenes/CuratorProfile";
import { ExpertProfileProvider } from "core/context/admin/expert-profile";
import {ExpertProfile} from "./experts/scenes/ExpertProfile";
import { FeedbackRequestProvider } from "core/context/feedback-requests/feedback";
import { useHistory } from "react-router-dom";
import {homepageByUserRole} from "../../core/helpers/redirect-helper";
import {CollectorArtifact} from "./collectors/scenes/CollectorArtifact";
import {CollectorArtifactProvider} from "./collectors/scenes/CollectorArtifact/context/collector-artifact.context";
import {Statistics} from "./admin-settings/scenes/Statistics";
import {responsiveStyles} from "../../components/layout/_styles/responsive-styles";
import {CustomSnackbar} from "../../components/_others/CustomSnackbar";
import {UtilsContext} from "../../core/context/utils.context";

export const DashboardApp: React.FC = () => {

    const userContext = useContext(UserContext);
    const history = useHistory();
    const [displayPage, setDisplayPage] = useState(false);

    const generalClasses = responsiveStyles();

    const utilsContext = useContext(UtilsContext);

    useEffect(() => {
        if(!userContext.user || userContext.user.role === UserRoles.COLLECTOR) {
            history.push({pathname: homepageByUserRole(userContext.user?.role), search: !getToken()
                    ? `redirectUrl=${window.location.pathname}${window.location.search}`
                    : ''})
            return;
        }
        setDisplayPage(true);
    }, [])

    return (
        <Box display={'flex'}>

            {
                displayPage &&
                    <>
                        <Box className={generalClasses.hideMobile}>
                            <DashboardDrawer />
                        </Box>

                        <Box width={'100%'}>
                            <DashboardToolbar />

                            <Container maxWidth={false} style={{paddingTop: 35, paddingBottom: 35}}>

                                {
                                    userContext.user?.role === UserRoles.EXPERT &&
                                    <>
                                        <FeedbackRequestsListingProvider>
                                            <FeedbackRequestProvider>
                                                <ExpertProfileProvider>
                                                    <PrivateRoute
                                                        exact={true}
                                                        path={AppRoutes.FEEDBACK_REQUESTS}
                                                        component={FeedbackRequestsListing}
                                                        restrictedRoles={[UserRoles.ADMIN, UserRoles.EXPERT]}
                                                    />
                                                    <PrivateRoute
                                                        exact={true}
                                                        path={AppRoutes.FEEDBACK_REQUEST}
                                                        component={FeedbackRequest}
                                                        restrictedRoles={[UserRoles.ADMIN, UserRoles.EXPERT]}
                                                    />
                                                    <PrivateRoute
                                                        path={AppRoutes.DASHBOARD_NOTIFICATIONS}
                                                        component={Notifications}
                                                        restrictedRoles={[UserRoles.ADMIN, UserRoles.EXPERT]}
                                                    />
                                                    <PrivateRoute
                                                        path={AppRoutes.ADMIN_EXPERT_PROFILE}
                                                        component={ExpertProfile}
                                                        restrictedRoles={[UserRoles.ADMIN, UserRoles.EXPERT]}
                                                    />
                                                </ExpertProfileProvider>
                                            </FeedbackRequestProvider>
                                        </FeedbackRequestsListingProvider>
                                    </>
                                }

                                {
                                    userContext.user?.role === UserRoles.ADMIN &&
                                    <>
                                        <TaxonomyProvider>
                                        <CollectorListingProvider>
                                        <ExpertListingProvider>
                                        <ArchivistListingProvider>
                                        <CuratorListingProvider>
                                        <FeedbackRequestsListingProvider>
                                        <FeedbackRequestProvider>
                                        <CollectorProfileProvider>
                                        <ArchivistProfileProvider>
                                        <CuratorProfileProvider>
                                        <ExpertProfileProvider>
                                        <CollectorArtifactProvider>
                                            <PrivateRoute
                                                exact={true}
                                                path={AppRoutes.FEEDBACK_REQUESTS}
                                                component={FeedbackRequestsListing}
                                                restrictedRoles={[UserRoles.ADMIN, UserRoles.EXPERT]}
                                            />
                                            <PrivateRoute
                                                exact={true}
                                                path={AppRoutes.FEEDBACK_REQUEST}
                                                component={FeedbackRequest}
                                                restrictedRoles={[UserRoles.ADMIN, UserRoles.EXPERT]}
                                            />
                                            <PrivateRoute
                                                exact={true}
                                                path={AppRoutes.COLLECTOR_LISTING}
                                                component={CollectorListing}
                                                restrictedRoles={[UserRoles.ADMIN]}
                                            />
                                            <PrivateRoute
                                                exact={true}
                                                path={AppRoutes.COLLECTOR_ARTIFACT}
                                                component={CollectorArtifact}
                                                restrictedRoles={[UserRoles.ADMIN]}
                                            />
                                            <PrivateRoute
                                                exact={true}
                                                path={AppRoutes.EXPERT_LISTING}
                                                component={ExpertListing}
                                                restrictedRoles={[UserRoles.ADMIN]}
                                            />
                                            <PrivateRoute
                                                exact={true}
                                                path={AppRoutes.ARCHIVIST_LISTING}
                                                component={ArchivistListing}
                                                restrictedRoles={[UserRoles.ADMIN]}
                                            />
                                            <PrivateRoute
                                                exact={true}
                                                path={AppRoutes.CURATOR_LISTING}
                                                component={CuratorListing}
                                                restrictedRoles={[UserRoles.ADMIN]}
                                            />
                                            <PrivateRoute
                                                path={AppRoutes.ADMIN_COLLECTOR_PROFILE}
                                                component={CollectorProfile}
                                                restrictedRoles={[UserRoles.ADMIN]}
                                            />
                                            <PrivateRoute
                                                path={AppRoutes.ADMIN_ARCHIVIST_PROFILE}
                                                component={ArchivistProfile}
                                                restrictedRoles={[UserRoles.ADMIN]}
                                            />
                                            <PrivateRoute
                                                path={AppRoutes.ADMIN_CURATOR_PROFILE}
                                                component={CuratorProfile}
                                                restrictedRoles={[UserRoles.ADMIN]}
                                            />
                                            <PrivateRoute
                                                path={AppRoutes.ADMIN_EXPERT_PROFILE}
                                                component={ExpertProfile}
                                                restrictedRoles={[UserRoles.ADMIN]}
                                            />
                                            <PrivateRoute
                                                exact={true}
                                                path={AppRoutes.TAXONOMY}
                                                component={Taxonomy}
                                                restrictedRoles={[UserRoles.ADMIN]}
                                            />
                                            <PrivateRoute
                                                path={AppRoutes.DASHBOARD_NOTIFICATIONS}
                                                component={Notifications}
                                                restrictedRoles={[UserRoles.ADMIN, UserRoles.EXPERT]}
                                            />
                                            <PrivateRoute
                                                path={AppRoutes.ADMIN_STATISTICS}
                                                component={Statistics}
                                                restrictedRoles={[UserRoles.ADMIN]}
                                            />
                                        </CollectorArtifactProvider>
                                        </ExpertProfileProvider>
                                        </CuratorProfileProvider>
                                        </ArchivistProfileProvider>
                                        </CollectorProfileProvider>
                                        </FeedbackRequestProvider>
                                        </FeedbackRequestsListingProvider>
                                        </CuratorListingProvider>
                                        </ArchivistListingProvider>
                                        </ExpertListingProvider>
                                        </CollectorListingProvider>
                                        </TaxonomyProvider>
                                    </>
                                }
                            </Container>
                        </Box>
                    </>
            }

            <CustomSnackbar
                open={utilsContext.snackbar.text !== ''}
                handleClose={() => utilsContext.closeSnackbar()}
                severity={utilsContext.snackbar.severity}
                message={utilsContext.snackbar.text}
            />

        </Box>
    )
}
