import React, {FunctionComponent, useContext} from 'react'
import { Redirect, Route } from 'react-router-dom'

import { getToken } from "core/helpers/storage-helper";
import { UserContext } from "core/context/user";
import { AppRoutes } from "../../core/helpers";

type PrivateRouteProps = {
    component: FunctionComponent;
    path: string;
    exact?: boolean;
    restrictedRoles?: string[];
}

export const PrivateRoute: React.FC<PrivateRouteProps> = (
    { component, path, restrictedRoles, exact, ...rest }
) => {

    const userContext = useContext(UserContext);

    const shouldAllow = (): boolean => {
        if(!getToken()) {
            return false;
        }
        if(!restrictedRoles) {
            return true;
        }
        return restrictedRoles.findIndex((r: string) => r === userContext.user?.role) > -1;
    }

    return (
        <Route
            {...rest}
            render={props =>
                shouldAllow() ? (
                    <Route path={path} component={component} exact={exact === true} />
                ) : (
                    <Redirect
                        to={
                            { pathname: AppRoutes.SIGN_IN, state: { from: props.location },
                            search: !getToken()
                                ? `redirectUrl=${window.location.pathname}${window.location.search}`
                                : ''
                        }}
                    />
                )
            }
        />
    )
}
