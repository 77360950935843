import React, {useState} from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Box, TextField, Typography } from "@material-ui/core";

import avatarPlaceholder from "assets/placeholders/avatar-placeholder.png";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';

import {FileSize} from "core/enums";
import {IObjectComment} from "types";
import {getPhotoUrl} from "core/helpers";

type CommentProps = {
    canDelete: boolean;
    canEdit: boolean;
    comment: IObjectComment;
    onDelete: () => void;
    onEdit: (comment: string) => void;
}

export const CommentDetails: React.FC<CommentProps> = (
    {comment, canDelete, canEdit, onDelete, onEdit}
) => {

    const classes = useStyles();

    const [editMode, setEditMode] = useState(false);
    const [commentText, setCommentText] = useState(comment.text);

    return (
        <Box className={classes.root} id={`${comment.id}`}>
            <img
                src={getPhotoUrl(comment.authorPhotoUrl, FileSize.SM) || avatarPlaceholder}
                alt="user-avatar"
                className={classes.avatar}
            />
            <div style={{width: '100%'}}>
                {
                    !editMode &&
                    <Typography className={classes.text}>
                        {commentText}
                    </Typography>
                }
                {
                    editMode &&
                        <TextField
                            id="edit-comment-textarea"
                            multiline
                            fullWidth
                            value={commentText}
                            className={classes.textarea}
                            onChange={(e: any) => setCommentText(e.target.value)}
                            rows={2}
                            rowsMax={5}
                            inputProps={{ maxLength: 500 }}
                            variant="outlined"
                        />
                }

                <Typography className={classes.date}>
                    {comment.authorFirstName} {comment.authorLastName} on{' '}
                    {moment(comment.dateCreated).format('MM/DD/YY')}
                    {
                        editMode &&
                            <SaveIcon
                                className={classes.saveIcon}
                                onClick={() => {onEdit(commentText); setEditMode(false)}}
                            />
                    }
                    {
                        editMode &&
                            <ClearIcon
                                className={classes.editIcon}
                                onClick={() => {setCommentText(comment.text); setEditMode(false)}}
                            />
                    }
                    {
                        canEdit && !editMode &&
                            <EditIcon
                                className={classes.editIcon}
                                onClick={() => setEditMode(true)}
                            />
                    }
                    {
                        canDelete && !editMode &&
                            <DeleteForeverIcon
                                className={classes.deleteIcon}
                                onClick={() => onDelete()}
                            />
                    }
                </Typography>
            </div>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: 12,
    },
    avatar: {
        width: 40,
        height: 40,
        borderRadius: 40,
        marginRight: 12,
    },
    text: {
        color: theme.palette.text.primary,
        fontSize: 13,
    },
    date: {
        color: '#707070',
        fontSize: 12,
        display: 'flex',
        alignItems: 'center'
    },
    deleteIcon: {
        marginLeft: 10,
        width: 18,
        cursor: 'pointer',
    },
    editIcon: {
        marginLeft: 10,
        width: 18,
        cursor: 'pointer',
    },
    saveIcon: {
        marginLeft: 10,
        width: 18,
        cursor: 'pointer',
        color: '#FE6502'
    },
    textarea: {
        marginTop: 5
    }
}))
