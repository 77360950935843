import { ISharedObjectState } from './shared-object.interfaces';
import { IAction } from '../../interfaces';

export default (state: ISharedObjectState, action: IAction): ISharedObjectState => {
    switch (action.type) {
        case 'SET_ARTIFACT':
            return {
                ...state,
                id: action.payload.id,
                artifact: {
                    id: action.payload.id,
                    // eslint-disable-next-line max-len
                    collectorName: `${action.payload.collector?.firstName} ${action.payload.collector?.lastName}`,
                    collectorPhoto: action.payload.collector?.photoUrl,
                    title: action.payload.title,
                    description: action.payload.description,
                    exactValue: action.payload.exactValue,
                    minimumValue: action.payload.minimumValue,
                    maximumValue: action.payload.maximumValue,
                    width: action.payload.width,
                    height: action.payload.height,
                    depth: action.payload.depth,
                    weight: action.payload.weight,
                    exactProductionYear: action.payload.exactProductionYear,
                    lowerProductionYear: action.payload.lowerProductionYear,
                    upperProductionYear: action.payload.upperProductionYear,
                    isFeedbackImported: action.payload.isFeedbackImported,
                    origin: action.payload.origin,
                    material: action.payload.material,
                    style: action.payload.style,
                    artist: action.payload.artist,
                    condition: action.payload.condition,
                    message: action.payload.message,
                    createdDate: action.payload.dateCreated,
                    photos: action.payload.photos || [],
                    documents: action.payload.documents || [],
                    categories: action.payload.categories || [],
                    materials: action.payload.materials || [],
                    conditions: action.payload.conditions || [],
                    eras: action.payload.eras || [],
                    origins: action.payload.origins || [],
                    styles: action.payload.styles || [],
                    feedbacks: action.payload.feedbacks || [],
                    artistImageUrl: action.payload.artistImageUrl,
                },
            };
        default:
            return state;
    }
};
