import {makeStyles} from "@material-ui/core/styles";

export const rootStyles = makeStyles({
    root: {
        padding: '0 30px 15px',
    }
});

export const emptyPageStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: 'calc(100vh - 65px)',
    },
    content: {
        margin: 'auto',
        textAlign: 'center',
    },
    icon: {
        marginBottom: 30,
    },
    typography: {
        marginBottom: 50,
        fontSize: 20,
        maxWidth: 525,
        lineHeight: 1,
    },
    button: {
        width: 335,
        fontSize: 18,
    }
});
