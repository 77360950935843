import { Box, Button, CircularProgress, Fade, Modal, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { modalStyles } from 'components/_others/modals/modal-styles';
import { ModalHeader } from 'components/_others/modals/ModalHeader';
import { validateEmail } from 'core';
import { UserApi } from 'core/api';
import React, { useState } from 'react';
import { ConfirmationPin } from '../ConfirmationPin/ConfirmationPin';

interface EnterResendEmailModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const EnterResendEmailModal: React.FC<EnterResendEmailModalProps> = ({ isOpen, onClose }) => {
    const classes = modalStyles();
    const [email, setEmail] = useState({ text: '', error: '' });
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [validEmail, setValidEmail] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const generateErrorText = (message: string): string => {
        switch (message) {
            case 'UserNotFound':
                return 'The E-mail does not belong to any account.';
            case 'UserAlreadyConfirmed':
                return 'This E-mail is already confirmed.';
            default:
                return 'Sorry, there was an error. Please try again later.';
        }
    };

    const onFinish = (): void => {
        setLoading(true);
        setError('');
        UserApi.resendRegisterConfirmationEmail(email.text)
            .then(() => {
                setLoading(false);
                setValidEmail(true);
            })
            .catch((error: any) => {
                setLoading(false);
                console.error(error.response);
                setError(
                    error.response?.data?.message
                        ? generateErrorText(error.response?.data?.message)
                        : 'Sorry, there was an error. Please try again later.',
                );
            });
    };

    const computeEmailError = (): void => {
        if (!email?.text.length) {
            setEmail({ ...email, error: 'Please enter your email' });
        } else if (!validateEmail(email.text)) {
            setEmail({ ...email, error: 'Please enter a valid email address' });
        } else {
            setEmail({ ...email, error: '' });
        }
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setEmail({ ...email, text: e.target.value });
    };

    const handleKeyPress = (e: any): void => {
        if (e.key === 'Enter') {
            onFinish();
            e.preventDefault();
        }
    };

    const handleSuccess = (): void => {
        setError('');
        setSuccess(true);
    };

    const handleClose = (): void => {
        setEmail({ text: '', error: '' });
        setValidEmail(false);
        setSuccess(false);
        setLoading(false);
        setError('');
        onClose();
    };

    return (
        <Modal
            open={isOpen}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            onClose={handleClose}
            className={classes.modal}
        >
            <Fade in={isOpen}>
                <div className={classes.paper}>
                    <ModalHeader title={'Re-send registration E-mail'} handleClose={onClose} />

                    {!!error && (
                        <Typography className="error-message" color="error">
                            {error}
                        </Typography>
                    )}

                    {success ? (
                        <Typography className={classes.modalSubtitle}>Your E-mail has been confirmed.</Typography>
                    ) : validEmail ? (
                        <Box>
                            <Typography className={classes.modalSubtitle}>
                                An E-mail with your confirmation code should arrive shortly. Enter the confirmation code
                                here:
                            </Typography>
                            <ConfirmationPin email={email.text} setError={setError} onSuccess={handleSuccess} />
                        </Box>
                    ) : (
                        <>
                            <Typography>Enter your E-mail here:</Typography>
                            <TextField
                                id="forgot-password-email-field"
                                variant="outlined"
                                size="small"
                                type="text"
                                placeholder="Email"
                                fullWidth
                                error={email.error !== ''}
                                helperText={email.error}
                                value={email.text}
                                inputProps={{ maxLength: 100 }}
                                onChange={handleEmailChange}
                                onBlur={computeEmailError}
                                onKeyPress={handleKeyPress}
                            />
                        </>
                    )}
                    <Box textAlign={'right'} marginTop={'60px'}>
                        <Button
                            id="resend-conf-email-btn"
                            variant="contained"
                            size={'small'}
                            style={{ marginRight: success ? '0px' : '15px' }}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> {success ? 'Close' : 'Cancel'}
                        </Button>

                        {!success && (
                            <Button
                                id="resend-conf-email-btn"
                                variant="contained"
                                color="primary"
                                size={'small'}
                                className={classes.primaryButton}
                                onClick={onFinish}
                            >
                                {loading && <CircularProgress className="circular-progress sm" />}
                                Resend Email
                            </Button>
                        )}
                    </Box>
                </div>
            </Fade>
        </Modal>
    );
};
