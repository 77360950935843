import React, { useState } from "react";
import { Button, Grid, InputLabel, TextField } from "@material-ui/core";

import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import {addCommentStyles} from "../../styles/comments.styles";

type AddCommentProps = {
    id: number;
    onAddNewComment: (objectId: number, comment: string) => Promise<boolean>;
}

export const AddComment: React.FC<AddCommentProps> = ({id, onAddNewComment}) => {

    const classes = addCommentStyles();

    const [comment, setComment] = useState('');

    const addComment = (): void => {

        if(!comment?.trim().length) {
            return;
        }

        onAddComment();
        setComment('');
    }

    const onAddComment = (): void => {
        void onAddNewComment(id, comment).then((res: boolean) => {
            if(res) {
                setComment('');
            }
        })
    }

    const handleKeyPress = (key: string): void => {
        if(key === 'Enter') {
            addComment();
        }
    }

    return (
        <form id="add-comment-form" className={classes.root}>
            <InputLabel htmlFor="add-comment-textarea" className={classes.label}>
                Add a comment
            </InputLabel>

            <Grid container spacing={1}>
               <Grid item xs={12} sm={12} md={10} lg={11}>
                   <TextField
                       id="add-comment-textarea"
                       multiline
                       fullWidth
                       value={comment}
                       onChange={(e: any) => setComment(e.target.value)}
                       onKeyPress={(e: any) => handleKeyPress(e.key)}
                       rows={2}
                       rowsMax={5}
                       inputProps={{ maxLength: 1000 }}
                       variant="outlined"
                       className={classes.textarea}
                   />
               </Grid>
                <Grid item xs={12} sm={12} md={2} lg={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        id="add-comment-btn"
                        className={classes.button}
                        onClick={() => addComment()}
                    >
                        <ChatBubbleIcon />
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}


