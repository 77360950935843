import * as api from '../_utils/api';

export class CollectorShareApi {

    static async getSharedObjects(size: number, page: number): Promise<any> {
        return api._post(`artifact/get?page=${page}&size=${size}`, {shared: true})
    }

    static async getSharedObject(artifactId: number, collectionShareId = ''): Promise<any> {
        if(collectionShareId) {
            return api._get(`artifact/get/${artifactId}`);
        }
        return api._get(`artifact/get/${artifactId}`);
    }

    static async getSharedCollections(size: number, page: number): Promise<any> {
        return api._post(`collection/get?page=${page}&size=${size}`, {shared: true})
    }
}
