import { Box, Container } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

import { fixedHeaderStyles } from 'assets/styles/fixed-header';
import { rootStyles } from './styles/root.style';

import { UserContext } from 'core/context/user';
import { ReportTypeEnum } from 'core/enums';

import Pagination from '@material-ui/lab/Pagination';
import { Statistics } from '../_components/Statistics';
import { ListOfObjects } from './components/ListOfObjects';
import { Widget } from './components/Widget';
import { useReportingData } from './hooks/use-reporting-data.hook';

export const Reporting: React.FC = () => {
    const classes = rootStyles();
    const fixedHeaderCss = fixedHeaderStyles();

    const [selectedReportType, setSelectedReportType] = useState('');

    const userContext = useContext(UserContext);
    const {
        objectsData,
        getObjectsData,
        isObjectDataLoading,
        pagination,
        widgetData,
        getWidgetData,
        onRemoveFromCommunityFeed,
    } = useReportingData();

    useEffect(() => {
        getWidgetData();
    }, []);

    useEffect(() => {
        if (selectedReportType === '') {
            return;
        }
        getObjectsData(1, selectedReportType);
    }, [selectedReportType]);

    const selectReportType = (type: string): void => {
        if (selectedReportType === type) {
            setSelectedReportType('');
            return;
        }
        setSelectedReportType(type);
    };

    return (
        <Container maxWidth={false} className={classes.root}>
            <Box className={`${fixedHeaderCss.root}`}>
                <Statistics statistics={[{ label: 'Total Objects', value: `${widgetData.totalCount}` }]} />
            </Box>

            <Box className={classes.widgetsContainer}>
                <Widget
                    isLightTheme={userContext.lightTheme}
                    isSelected={selectedReportType === ReportTypeEnum.MissingPhotos}
                    title={'Objects with 1 photo or less'}
                    subtitle={`${widgetData.missingPhotos.count} of your ${widgetData.totalCount} objects`}
                    count={widgetData.missingPhotos.count}
                    percentage={widgetData.missingPhotos.percentage}
                    onSelect={() => selectReportType(ReportTypeEnum.MissingPhotos)}
                />
                <Widget
                    isLightTheme={userContext.lightTheme}
                    isSelected={selectedReportType === ReportTypeEnum.MissingDetails}
                    title={'Objects with missing details'}
                    subtitle={
                        widgetData.missingPhotos.count === widgetData.totalCount
                            ? 'All of your objects are missing details!'
                            : `${widgetData.missingDetails.count} of your ${widgetData.totalCount} objects`
                    }
                    count={widgetData.missingDetails.count}
                    percentage={widgetData.missingDetails.percentage}
                    onSelect={() => selectReportType(ReportTypeEnum.MissingDetails)}
                />
                <Widget
                    isLightTheme={userContext.lightTheme}
                    isSelected={selectedReportType === ReportTypeEnum.SharedOnCommunityFeed}
                    title={'Objects shared on Community Feed'}
                    subtitle={`${widgetData.sharedOnCommunityFeed.count} of your ${widgetData.totalCount} objects`}
                    count={widgetData.sharedOnCommunityFeed.count}
                    percentage={widgetData.sharedOnCommunityFeed.percentage}
                    onSelect={() => selectReportType(ReportTypeEnum.SharedOnCommunityFeed)}
                />
            </Box>

            {selectedReportType !== '' && (
                <Box className={classes.objectsContainer}>
                    <ListOfObjects
                        data={objectsData}
                        reportType={selectedReportType}
                        isLoading={isObjectDataLoading}
                        onObjectRemove={onRemoveFromCommunityFeed}
                    />

                    {pagination.numberOfPages > 1 && !isObjectDataLoading && (
                        <Pagination
                            count={pagination.numberOfPages}
                            page={pagination.page}
                            siblingCount={1}
                            shape="rounded"
                            style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10, marginTop: 10 }}
                            onChange={(_e: any, value: number) => getObjectsData(value, selectedReportType)}
                        />
                    )}
                </Box>
            )}
        </Container>
    );
};
