import React from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';

type TitleProps = {
    name: string;
};

export const Title: React.FC<TitleProps> = ({ name }) => {
    return (
        <Box className="page-title-container">
            <Tooltip arrow title={name} className="full-artifact-name-tooltip" placement="bottom-start">
                <Typography
                    className="page-title"
                    variant="h2"
                    color="secondary"
                    style={{ width: 'unset', textTransform: 'none' }}
                >
                    {name}
                </Typography>
            </Tooltip>
        </Box>
    );
};
