import React, {ReactElement} from "react";
import "./style.less";
import { Button } from "@material-ui/core";

type ButtonGrowProps = {
    icon: ReactElement;
    text: string;
    id: string;
    highlightOnHover?: boolean;
    onClick: () => void
}

export const ButtonGrow: React.FC<ButtonGrowProps> = ({icon, text, id, highlightOnHover = false, onClick}) => {
    return (
        <Button
            className={`button-grow ${highlightOnHover ? 'highlight-on-hover' : ''}`}
            id={id}
            variant="contained"
            onClick={() => onClick()}
        >
            {icon}
            <span className="button-grow-text">&nbsp;{text}</span>
        </Button>
    )
}
