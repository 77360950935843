import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import AssessmentIcon from '@material-ui/icons/Assessment';

import { getMyObjectsRoute } from 'core/helpers/app-routes';
import { BackNavigation } from 'components/_others/BackNavigation';
import { ButtonGrow } from 'components/_others/ButtonGrow';
import { UserContext } from '../../../../core/context/user';
import { UserRoles } from '../../../../core/enums';

const useStyles = makeStyles((theme) => ({
    box: {
        textAlign: 'center',
        marginBottom: 20,
    },
    content: {
        position: 'fixed',
        left: 24,
        right: 24,
        zIndex: 1,
        paddingTop: 15,
        backgroundColor: theme.palette.background.default,
    },
    pageTitle: {
        fontSize: 36,
        fontWeight: 'bold',
    },
    addAndAssesButton: {
        height: 28,
    },
}));

type HeaderProps = {
    onAdd: () => void;
    onAddAndAssess: () => void;
};

export const Header: React.FC<HeaderProps> = ({ onAdd, onAddAndAssess }) => {
    const userContext = useContext(UserContext);
    const history = useHistory();
    const classes = useStyles();

    return (
        <Box className="page-header-container">
            <div
                className={classes.content}
                style={userContext.user?.role === UserRoles.COLLECTOR ? { top: 65 } : { top: 130 }}
            >
                <BackNavigation />

                <Box className={classes.box}>
                    <Typography color="secondary" variant="h5" className={classes.pageTitle}>
                        Add New Object
                    </Typography>

                    <div className="actions">
                        <ButtonGrow
                            icon={<CloseIcon className="share-icon" />}
                            text={'Cancel'}
                            id={'cancel-add-object-btn'}
                            onClick={() => history.push(getMyObjectsRoute(userContext.user?.role))}
                        />
                        <Button color={'primary'} style={{ height: 28 }} variant="contained" onClick={onAdd}>
                            <SaveIcon className="share-icon" style={{ width: 19, height: 19 }} />
                            <span>&nbsp;Add</span>
                        </Button>

                        <Button
                            className={classes.addAndAssesButton}
                            color="primary"
                            variant="contained"
                            onClick={onAddAndAssess}
                        >
                            <AssessmentIcon style={{ width: 19, height: 19 }} /> &nbsp;Add and assess
                        </Button>
                    </div>
                </Box>
            </div>
        </Box>
    );
};
