import {makeStyles} from "@material-ui/core/styles";

export const mobileMenuStyles = makeStyles(theme => ({
    hamburgerMenu: {
        ["@media (max-width:1000px)"]: {
            position: 'absolute',
            right: 0
        }
    },
    hamburgerMenuSvg: {
        color: theme.palette.text.primary,
        ["@media (max-width:1000px)"]: {
            width: '4rem',
            marginTop: -17,
            height: '2rem',
            cursor: 'pointer'
        }
    },
    headerBox: {
        position: 'fixed',
        width: '100%',
        zIndex: 1,
        backgroundColor: theme.palette.background.paper,
    },
    logoBox: {
        height: 65,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 24
    },
    iconBox: {
        height: 65,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    closeIcon: {
        width: '4rem',
        height: '2rem',
        color: theme.palette.text.primary,
    },
    divider: {
        width: 115,
        marginLeft: 12,
        marginTop: 20,
        marginBottom: 20,
        backgroundColor: theme.divider.main,
    },
    navBox: {
        marginTop: 60,
        marginBottom: 20,
    },
    nav: {
        display: 'block',
        paddingLeft: 40,
        paddingRight: 40,
    },
}))
