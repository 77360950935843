import React, {useContext, useState} from "react";
import { Backdrop, Box, Button, CircularProgress, Fade, Modal, Typography } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { modalStyles } from "components/_others/modals/modal-styles";

import { FeedbackRequestsApi } from "core/api/feedback-requests/feedback-requests.api";
import {UtilsContext} from "../../../../../../core/context/utils.context";

type RequestBetterPhotosProps = {
    feedbackRequestId: number;
    onSuccess: () => void;
}

export const RequestBetterPhotos: React.FC<RequestBetterPhotosProps> = ({feedbackRequestId, onSuccess}) => {

    const classes = modalStyles();

    const [displayConfirmModal, toggleConfirmModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const utilsContext = useContext(UtilsContext);
    const onConfirm = (): void => {
        setIsLoading(true)

        FeedbackRequestsApi.requestBetterPhotos(feedbackRequestId)
            .then(() => {
                setIsLoading(false);
                utilsContext.addSnackbar('success', 'Success!');
                onSuccess();
                toggleConfirmModal(false);
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar('error');
            })
    }

    return (
        <>
            <Typography
                color={'primary'}
                style={{cursor: 'pointer', textDecoration: 'underline', fontSize: 13}}
                onClick={() => toggleConfirmModal(true)}
            >
                Request better photos
            </Typography>

            <Modal
                className={classes.modal}
                open={displayConfirmModal}
                onClose={() => toggleConfirmModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={displayConfirmModal}>
                    <div className={classes.paper}>
                        <Box textAlign={'right'}>
                            <CloseIcon cursor={'pointer'} onClick={() => toggleConfirmModal(false)} />
                        </Box>

                        <Box>
                            <Typography className={classes.modalTitle}>
                                Request better photos
                            </Typography>
                            <Typography className={classes.modalSubtitle}>
                                The collector will receive a notification, asking him to upload better photos.
                                Are you sure you want to continue?
                            </Typography>
                        </Box>

                        <Box textAlign={'right'} marginTop={'60px'}>
                            <Button
                                id="cancel-delete-btn"
                                variant="contained"
                                size={"small"}
                                style={{marginRight: '15px'}}
                                className={classes.secondaryButton}
                                onClick={() => toggleConfirmModal(false)}
                            >
                                <CloseIcon height={'20px'} /> Cancel
                            </Button>

                            <Button
                                id="delete-btn"
                                variant="contained"
                                color="primary"
                                size={"small"}
                                className={classes.primaryButton}
                                onClick={() => onConfirm()}
                            >
                                {
                                    isLoading && <CircularProgress className="circular-progress sm" />
                                }
                                Request better photos
                            </Button>
                        </Box>
                    </div>
                </Fade>
            </Modal>
        </>

    )
}
