import React, {useContext, useState} from "react";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Fade, InputLabel,
    Modal,
    TextField,
    Typography
} from "@material-ui/core";
import {modalStyles} from "./modals/modal-styles";
import {CommonApi} from "../../core/api/shared";
import CloseIcon from "@material-ui/icons/Close";
import ReplyIcon from "@material-ui/icons/Reply";
import {UtilsContext} from "../../core/context/utils.context";
import {UserContext} from "../../core/context/user";
import {validateEmail} from "../../core/helpers";

type ContactUsModalProps = {
    open: boolean;
    handleClose: () => void;
}

export const ContactUsModal: React.FC<ContactUsModalProps> = ({open, handleClose}) => {

    const classes = modalStyles();

    const utilsContext = useContext(UtilsContext);
    const userContext = useContext(UserContext);

    const [message, setMessage] = useState('');
    const [email, setEmail] = useState(userContext.user?.email || '');

    const [errors, setErrors] = useState({
        email: '',
        message: 'Please enter your message'
    })

    const [isLoading, setIsLoading] = useState(false);


    const onSend = (): void => {
        if(!message || !message.trim().length) {
            return;
        }

        setIsLoading(true);
        CommonApi.sendContactUsEmail({text: message, email})
            .then(() => {
                setIsLoading(false);
                utilsContext.addSnackbar('success', 'Thank you! Your message was sent to us.');
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar('error');
            })
    }

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Box textAlign={'right'}>
                        <CloseIcon cursor={'pointer'} onClick={handleClose} />
                    </Box>

                    <Box>
                        <Typography className={classes.modalTitle}>
                            Contact Us
                        </Typography>
                        <Typography className={classes.modalSubtitle}>
                            We'd love to hear from you. Send us a message, suggestion or feedback using the form below
                        </Typography>
                        <Typography className={classes.modalSubtitle} style={{color: '#FE6502'}}>
                            Your email is not required, you can be anonymous,
                            but if you have a problem related to your account, your address will help us get back to you.
                        </Typography>
                    </Box>

                    <InputLabel style={{marginTop: 20}}>
                        Email
                    </InputLabel>
                    <TextField
                        fullWidth
                        style={{maxWidth: 700}}
                        variant="outlined"
                        margin="normal"
                        id="email"
                        name="email"
                        type="email"
                        inputProps={{ maxLength: 200 }}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setErrors({...errors, email: email ? !validateEmail(email) ? 'Please enter a valid email' : '' : ''})
                        }}
                        onBlur={() => setErrors({...errors, email: email ? !validateEmail(email) ? 'Please enter a valid email' : '' : ''})}
                    />

                    <InputLabel style={{marginTop: 20}}>
                        Message
                    </InputLabel>
                    <TextField
                        fullWidth
                        multiline
                        rows={10}
                        rowsMax={20}
                        style={{maxWidth: 700}}
                        variant="outlined"
                        margin="normal"
                        id="answer"
                        name="answer"
                        type="text"
                        inputProps={{ maxLength: 2000 }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onBlur={() => setErrors({...errors, message: message.trim().length === 0 ? 'Please enter your message' : ''})}
                    />

                    <Box textAlign={'right'}>
                        <Button
                            className={classes.primaryButton}
                            variant="contained"
                            color="primary"
                            size={"small"}
                            onClick={() => onSend()}
                            disabled={errors.message !== '' || errors.email !== ''}
                        >
                            {
                                isLoading && <CircularProgress className="circular-progress sm" />
                            }
                            {
                                !isLoading && <ReplyIcon className="share-icon" style={{marginRight: 5}} />
                            }
                            Send
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    )
}
