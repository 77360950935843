import * as api from './_utils/api';
import { ExpertsSearchType } from '../enums';

export class AdminUsersApi {
    static async getCollectors(size: number, page: number, searchBy: string): Promise<any> {
        return api._get(`collectors/get?page=${page}&size=${size}&search=${searchBy}`);
    }

    static async getExperts(
        size: number,
        page: number,
        searchBy: string,
        searchType = ExpertsSearchType.Type,
    ): Promise<any> {
        return api._get(`experts/get?page=${page}&size=${size}&search=${searchBy}&searchType=${searchType}`);
    }

    static async getArchivists(size: number, page: number, searchBy: string): Promise<any> {
        return api._get(`archivists/get?page=${page}&size=${size}&search=${searchBy}`);
    }

    static async getCurators(size: number, page: number, searchBy: string): Promise<any> {
        return api._get(`curators/get?page=${page}&size=${size}&search=${searchBy}`);
    }

    static async confirmUserEmail(userId: number): Promise<any> {
        return api._put(`Users/ChangeUserConfirmation/${userId}?confirmed=true`);
    }
}
