import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";

import ReactPlayer from "react-player";
import video from "assets/videos/tutorial_org.mp4";
import {howToOrganizeStyles} from "../styles/how-to-organize.style";

type HowToOrganizeProps = {
    onSkip: () => void;
    onNext: () => void;
    hideNavigation?: boolean;
}

export const HowToOrganize: React.FC<HowToOrganizeProps> = ({onSkip, onNext, hideNavigation}) => {

    const classes = howToOrganizeStyles();

    return (
        <Box className={classes.root}>
            <div className={classes.videoContainer}>
                <ReactPlayer
                    url={video}
                    width='100%'
                    height='100%'
                    controls = {true}
                />
            </div>

            <Typography variant="h6" className={classes.title}>
                How to organize, share & connect through our platform?
            </Typography>

            <Typography className={classes.subtitle}>
                The Artifact platform provides a simple, intuitive & reliable tool
                for collectors to manage their content. It’s powerful features allow
                the everyday user to stay organized and up-to-date with their precious valuables.
            </Typography>

            {
                !hideNavigation &&
                <Box className={classes.navigationContainer}>
                    <Grid container>
                        <Grid item xs={6} md={6} className={classes.leftCol}>
                            <Button
                                id="skip-tutorial-btn"
                                color="primary"
                                onClick={() => onSkip()}
                            >
                                Skip this
                            </Button>
                        </Grid>
                        <Grid item xs={6} md={6} className={classes.rightCol}>
                            <Button
                                id="tutorial-next-btn"
                                variant="contained"
                                color="primary"
                                onClick={() => onNext()}
                            >
                                Next Step
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            }
        </Box>
    )
}


