import {ITaxonomyState} from "./taxonomy.interfaces";
import {IAction} from "../../interfaces";

export default (state: ITaxonomyState, action: IAction): ITaxonomyState => {
    switch(action.type) {
        case 'SET_ROOT_TYPES':
            return {
                ...state,
                rootTypes: action.payload,
            }
        case 'SELECT_ROOT_TYPE':
            return {
                ...state,
                selectedRootType: action.payload,
            }
        case 'SET_SELECTED_FOR_DELETE_ID':
            return {
                ...state,
                selectedForDeleteId: action.payload,
            }
        case 'PUSH_DELETED_LABEL':
            // eslint-disable-next-line no-case-declarations
            const deleted = state.deletedLabels;
            deleted.push(action.payload);
            return {
                ...state,
                deletedLabels: deleted,
            }
        case 'TOGGLE_ADD_LABEL_MODAL':
            return {
                ...state,
                displayAddLabelModal: action.payload,
            }
        default:
            return state;
    }
}
