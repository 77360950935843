import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export const artifactCardStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.rootBackground.cardBackground,
        marginBottom: 35,
        padding: 30,
        maxWidth: 700,
        margin: 'auto',
    },
    artifactPhoto: {
        width: '100%',
        maxHeight: 600,
        objectFit: 'cover',
        cursor: 'pointer',
        ["@media (max-width:1280px)"]: { maxHeight: 400 },
    },
    artifactTitle: {
        fontSize: 20,
        fontWeight: 600,
    },
    artifactSubtitle: {
        fontSize: 15,
        color: theme.formLabel.main,
        opacity: 0.9,
        lineHeight: 1.3,
        marginTop: 10,
        marginBottom: 15,
    },
    divider: {
        width: '100%',
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: theme.divider.main,
    },
    showMore: {
        cursor: 'pointer',
        color: "#029BFE"
    },
    commentsContainer: {
        maxHeight: 300,
        overflow: 'auto',
        marginBottom: 5,
    }
}))
