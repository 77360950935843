import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import {myriadProBold, myriadProLight, myriadProRegular, myriadProSemiBold} from "../fonts/myriad-pro";

export const lightTheme: ThemeOptions = createMuiTheme({
    rootBackground: {
        main: "#FFFFFF",
        secondary: "#EFEFEF",
        secondaryBorder: "#e2e2e2",
        cardBackground: "#EFEFEF",
        progressBarBackground: "#acacac",
        modalBackground: '#FFFFFF',
        highlightBackground: '#ffd8bf',
    },
    divider: {
        main: '#e2e2e2',
    },
    dashboardHeader: {
        main: '#FFFFFF'
    },
    label: {
        main: '#EFEFEF',
        text: '#23232B',
        labelBackground: '#EFEFEF',
    },
    formLabel: {
        main: "#383838",
    },
    palette: {
        common: {
            black: "#000",
            white: "#fff"
        },
        background: {
            default: "#FFFFFF",
            paper: "#FFFFFF",
        },
        text: {
            primary: "#383838",
            secondary: "#B2B2B2",
        },
        primary: {
            main: "#FE6502",
            contrastText: "#FFFFFF"
        },
        secondary: {
            main: "#029BFE",
            contrastText: "#FFFFFF"
        },
        error: {
            main: "#B32222",
        },
        warning: {
            main: "#FE6502",
        },
        info: {
            main: "#707070"
        },
        success: {
            main: "#57B894",
        }
    },
    typography: {
        fontFamily: [
            'Myriad Pro',
            'Myriad Pro Regular',
            'Myriad Pro Semibold',
            'sans-serif'
        ].join(','),
    },
    shape: {
        borderRadius: 3,
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [
                    myriadProRegular,
                    myriadProBold,
                    myriadProLight,
                    myriadProSemiBold,
                ],
            },
        },
        MuiTextField: {
            root: {
                borderRadius: 3,
                color: '#fff'
            },
        },
        MuiOutlinedInput: {
            root: {
                backgroundColor: '#F7F7F7',
                borderRadius: 3,
                color: '#23232B'
            },
        },
        MuiButton: {
            root: {
                textTransform: 'none',
                fontSize: 16,
                borderRadius: 2,
            }
        },
        MuiSelect: {
            root: {
                paddingTop: 10,
                paddingBottom: 10,
            }
        },
        MuiChip: {
            root: {
                backgroundColor: '#EFEFEF !important',
                color: '#23232B !important'
            }
        }
    },
});
