import React, { useContext, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { UserContext } from "core/context/user";
import { formatUSD } from "core/helpers/format-currency";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EditIcon from "@material-ui/icons/Edit";

import { AvatarEditorModal } from "../AvatarEditorModal";
import { getPhotoUrl } from "core/helpers/file-size.helper";
import {FileSize, UserRoles} from "core/enums";

type LightboxProps = {
    stats: {
        totalObjects: number,
        totalValue: number
    }
}

export const Lightbox: React.FC<LightboxProps> = ({stats}) => {

    const userContext = useContext(UserContext);

    const [openAvatarEditor, toggleAvatarEditor] = useState(false);

    return (
        <Box className="user-profile-lightbox">
            <Box position={'relative'}>
                {
                    !userContext.user?.photoUrl &&
                        <AccountCircleIcon
                            className="avatar-placeholder"
                        />
                }
                {
                    userContext.user?.photoUrl &&
                        <img
                            src={getPhotoUrl(userContext.user.photoUrl, FileSize.SM) || ''}
                            alt="avatar"
                            className="user-avatar"
                        />
                }

                <Button
                    id="edit-avatar-btn"
                    variant="contained"
                    size={"small"}
                    onClick={() => toggleAvatarEditor(true)}
                >
                    <EditIcon />
                </Button>
            </Box>

            <Box className="lightbox-details">
                <Typography variant="h5" className="user-name" color="textPrimary">
                    {userContext.user?.firstName}{' '}{userContext.user?.lastName}
                </Typography>

                {
                    userContext.user?.role === UserRoles.COLLECTOR &&
                    <Box>
                        <Typography className="stats" color="textSecondary">
                            Total Objects: <span className="value" color="secondary">{stats.totalObjects}</span>
                        </Typography>
                        <Typography className="stats" color="textSecondary">
                            Total Value: <span className="value" color="secondary">{formatUSD(stats.totalValue)}</span>
                        </Typography>
                    </Box>
                }

            </Box>

            <AvatarEditorModal
                open={openAvatarEditor}
                handleClose={() => toggleAvatarEditor(false)}
            />
        </Box>
    )
}
