import {AppRoutes} from "core/helpers/app-routes";

export const navLinks = {
    default: [
        {
            id: 0,
            name: 'Home',
            path: AppRoutes.CREATE_ACCOUNT,
            onAuthPages: true,
        },
        {
            id: 1,
            name: 'Our Mission',
            path: AppRoutes.OUR_MISSION,
            onAuthPages: true,
        },
    ],
    dashboard: [
        {
            id: 10,
            name: 'My Objects',
            path: AppRoutes.MY_OBJECTS,
            onAuthPages: false,
        },
        {
            id: 11,
            name: 'My Collections',
            path: AppRoutes.MY_COLLECTIONS,
            onAuthPages: false,
        },
        {
            id: 12,
            name: 'Shared with me',
            path: AppRoutes.SHARED_WITH_ME,
            onAuthPages: false,
        },
        {
            id: 13,
            name: 'Community Feed',
            path: AppRoutes.PUBLIC_FEED,
            onAuthPages: false,
        },
        {
            id: 14,
            name: 'Reporting',
            path: AppRoutes.REPORTING,
            onAuthPages: false,
        },
    ],
    auth: [
        {
            id: 20,
            name: 'Log In',
            path: AppRoutes.SIGN_IN,
            onAuthPages: true,
        },
        {
            id: 21,
            name: 'Sign Up',
            path: AppRoutes.CREATE_ACCOUNT,
            onAuthPages: true,
        },
        {
            id: 22,
            name: 'Edit Profile',
            path: AppRoutes.COLLECTOR_PROFILE,
            onAuthPages: false,
        },
        {
            id: 23,
            name: 'Notifications',
            path: AppRoutes.NOTIFICATIONS,
            onAuthPages: false,
        }
    ],
    helpers: [
        {
            id: 30,
            name: 'Home',
            path: AppRoutes.ASSIGNED_COLLECTORS_INDEX,
            onAuthPages: false,
        },
        {
            id: 31,
            name: 'Our Mission',
            path: AppRoutes.OUR_MISSION,
            onAuthPages: false,
        },
    ]
}
