import React from "react";
import { Backdrop, Box, Button, CircularProgress, Fade, Modal, Typography } from "@material-ui/core";
import { modalStyles } from "components/_others/modals/modal-styles";

import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

import {useRemoveCollection} from "./hook";
import {ModalHeader} from "components/_others/modals/ModalHeader";

type DeleteCollectionModalProps = {
    collectionId: number;
    open: boolean;
    handleClose: (wasDeleted: boolean) => void;
}

export const RemoveCollectionModal: React.FC<DeleteCollectionModalProps> = (
    {collectionId, open, handleClose}
) => {

    const classes = modalStyles();

    const {isLoading, removeCollection} = useRemoveCollection();

    const onDelete = (): void => {
        removeCollection(collectionId).then((res: boolean) => {
            if(res) {
                handleClose(true);
            }
        });
    }

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>

                    <ModalHeader
                        title={'Delete Collection?'}
                        handleClose={() => handleClose(false)}
                    />

                    <Typography className={classes.modalSubtitle}>
                        Are you sure you want to delete this collection?
                    </Typography>

                    <Box textAlign={'right'} marginTop={'60px'}>
                        <Button
                            id="cancel-delete-btn"
                            variant="contained"
                            size={"small"}
                            style={{marginRight: '15px'}}
                            className={classes.secondaryButton}
                            onClick={() => handleClose(false)}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>

                        <Button
                            id="delete-btn"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            className={classes.primaryButton}
                            onClick={() => onDelete()}
                        >
                            {
                                isLoading && <CircularProgress className="circular-progress sm" />
                            }
                            {
                                !isLoading && <DeleteIcon />
                            }
                            Delete
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    )
}
