import React, {useContext, useEffect, useState} from "react";
import {Box, Button, Typography} from "@material-ui/core";

import {ExpertListingContext} from "core/context/admin/expert-listing";
import {GalleryTypeEnum} from "components/Gallery/helpers/GalleryTypeEnum";

import {Gallery} from "components/Gallery";
import {Loader} from "components/_others";
import {Searchbox} from "components/_dashboard/Searchbox/Searchbox";
import {ExpertsSearchType, UserRoles} from "core/enums";
import {InviteUsersModal} from "components/_dashboard/InviteUsersModal";
import {useHistory} from "react-router-dom";
import {getCurrentPageFromUrl} from "core/helpers/utils";
import {AppRoutes} from "core/helpers";
import {UtilsContext} from "core/context/utils.context";
import {AdminUsersApi} from "core/api/admin-users.api";
import {SearchType} from "./SearchType";

export const ExpertListing: React. FC = () => {

    const history = useHistory();

    const expertListingContext = useContext(ExpertListingContext);
    const utilsContext = useContext(UtilsContext);

    const [displayInviteModal, toggleInviteModal] = useState(false);
    const [searchBy, setSearchBy] = useState('');
    const [searchType, setSearchType] = useState(ExpertsSearchType.Default);

    useEffect(() => {
        const page = getCurrentPageFromUrl();
        expertListingContext.setCurrentPage(page);
        getExperts(page, '');
    }, [])

    useEffect(() => {
        if(searchBy.trim().length < 4) {
            return;
        }
        getExperts(expertListingContext.pagination.page, searchBy);
    }, [searchType])

    const getExperts = (page: number, searchBy: string): void => {
        expertListingContext.setLoading(true);

        AdminUsersApi.getExperts(expertListingContext.pagination.size, page, searchBy, searchType)
            .then((res: any) => {
                expertListingContext.setExperts(res.data);
                window.scrollTo(0, 0);
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error');
            })
    }

    const handlePageChange = (page: number, searchBy: string): void => {
        history.push(`${AppRoutes.EXPERT_LISTING}?page=${page}`);
        expertListingContext.setCurrentPage(page);
        getExperts(page, searchBy);
    }

    return (
        <Box>
            {
                !expertListingContext.isLoading &&
                <>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Box>
                            <Typography variant="h5" color="textPrimary">
                                Experts
                            </Typography>
                            <Typography variant="h5" color="textSecondary" style={{fontSize: 16}}>
                                {expertListingContext.statistics.totalUsers}
                            </Typography>
                        </Box>

                        <Box display={'flex'} alignItems={'center'}>
                            <Button
                                id="delete-btn"
                                variant="contained"
                                color="primary"
                                size={"small"}
                                onClick={() => toggleInviteModal(true)}
                            >
                                Invite Experts
                            </Button>

                            <Box>
                                <Searchbox
                                    searchBy={searchBy}
                                    setSearchBy={(val: string) => setSearchBy(val)}
                                    onClear={() => {
                                        setSearchBy('');
                                        handlePageChange(1, '');
                                    }}
                                    onSearch={(val: string) => val && val.length > 3
                                        ? handlePageChange(1, val)
                                        : handlePageChange(1, '')
                                    }
                                />
                                <Box marginLeft={'24px'}>
                                    <SearchType
                                        searchType={searchType}
                                        onChange={(type: any) => setSearchType(type === searchType
                                            ? ExpertsSearchType.Default
                                            : type
                                        )}
                                    />
                                </Box>
                            </Box>

                        </Box>
                    </Box>

                    <Box marginTop={'15px'}>
                        <Gallery
                            galleryType={GalleryTypeEnum.Experts}
                            data={expertListingContext.experts}
                            pagination={{
                                numberOfPages: expertListingContext.pagination.numberOfPages,
                                currentPage: expertListingContext.pagination.page,
                            }}
                            handlePageChange={(page: number) => handlePageChange(page, searchBy)}
                        />
                    </Box>

                    {
                        expertListingContext.displayEmptyPage &&
                        <Typography variant="h5" color="textSecondary" style={{fontSize: 16}}>
                            This list is empty.
                        </Typography>
                    }

                </>
            }

            {
                expertListingContext.isLoading &&
                <Loader />
            }

            <InviteUsersModal
                open={displayInviteModal}
                role={UserRoles.EXPERT}
                handleClose={() => toggleInviteModal(false)}
            />
        </Box>
    )
}
