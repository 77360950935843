import React, {useContext, useState} from "react";
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';

import {Box, Button, Grid, Tooltip, Typography} from "@material-ui/core";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import StarIcon from '@material-ui/icons/Star';

import { IFeedbackRequest } from "core/context/feedback-requests/listing";
import { FileSize, UserRoles } from "core/enums";
import { AppRoutes, getPhotoUrl } from "core/helpers";
import { UserContext } from "core/context/user";
import { Link } from "react-router-dom";
import {FeedbackRequestsApi} from "../../../../../../core/api/feedback-requests/feedback-requests.api";
import {UtilsContext} from "../../../../../../core/context/utils.context";

type FeedbackRequestItemProps = {
    item: IFeedbackRequest
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    collectorBox: {
        textAlign: 'center',
        borderRight: '1px solid',
        borderColor: `${theme.divider.main}!important`,
    },
    actionsBox: {
        position: 'relative',
        height: '100%',
        textAlign: 'right'
    },
    profileIconPlaceholder: {
        width: 40, height: 40, color: '#FE6502',
    },
    profilePic: {
        width: 40, height: 40, borderRadius: 40,
    },
    collectorName: {
        fontSize: 16,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    feedbackDate: {
        marginTop: 27,
        fontSize: 12,
    },
    artifactPhoto: {
        width: '100%',
        height: '110px',
        objectFit: 'cover',
    },
    artifactName: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    artifactId: {
        fontSize: 12,
        fontWeight: 'normal',
        color: theme.palette?.text?.secondary,
        marginLeft: 5,
    },
    artifactDescription: {
        fontSize: 13,
        marginTop: 7,
    },
    giveFeedbackBtn: {
        fontSize: 13,
        minWidth: 115,
        paddingTop: 2,
        paddingBottom: 2,
        [theme?.breakpoints?.up('md')]: {
            position: 'absolute',
            bottom: 0,
            right: 0,
        },
    },
    deleteFeedbackBtn: {
        fontSize: 13,
        //minWidth: 115,
        paddingTop: 2,
        paddingBottom: 2,
        [theme?.breakpoints?.up('md')]: {
            position: 'absolute',
            top: 0,
            right: 0,
        },
    },
    link: {
        textDecoration: 'none',
        color: "#029BFE",
    }
}))

export const FeedbackRequestItem: React.FC<FeedbackRequestItemProps> = ({item}) => {

    const classes = useStyles();

    const userContext = useContext(UserContext);
    const utilsContext = useContext(UtilsContext);

    const { artifact, collector } = item;
    const [isDeleted, setIsDeleted] = useState(false);

    const onDelete = (): void => {
        FeedbackRequestsApi.deleteFeedbackRequest(item.id)
            .then(() => {
                setIsDeleted(true);
                utilsContext.addSnackbar('success', 'Deleted!');
            })
            .catch((error) => {
                console.error(error.response);
                utilsContext.addSnackbar('error');
            })
    }

    return (
        <Box className={classes.root} style={isDeleted ? {opacity: 0.5}:{}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={2}>
                    <Box className={classes.collectorBox}>
                        {
                            !collector.photoUrl &&
                            <AccountCircleIcon className={classes.profileIconPlaceholder} />
                        }
                        {
                            collector.photoUrl &&
                            <img
                                src={getPhotoUrl(collector.photoUrl || '', FileSize.SM)  || '' }
                                className={classes.profilePic}
                                alt={collector.firstName}
                            />
                        }

                        <Tooltip
                            arrow
                            interactive={true}
                            title={`${collector.firstName} ${collector.lastName}`}
                            className="full-artifact-name-tooltip"
                        >
                            <Typography color="textPrimary" className={classes.collectorName}>
                                {
                                    userContext.user?.role === UserRoles.ADMIN
                                        ? <Link
                                            to={`${AppRoutes.ADMIN_COLLECTOR_PROFILE}?id=${collector.userId}`}
                                            className={classes.link}
                                        >
                                            {collector.firstName}{' '}{collector.lastName}
                                        </Link>
                                        : <>{collector.firstName}{' '}{collector.lastName}</>
                                }
                            </Typography>

                        </Tooltip>

                        <Typography color="textSecondary" className={classes.feedbackDate}>
                            Created on: {moment(item.dateCreated).format('h:mma M/D/YY')}
                        </Typography>
                        {
                            item.isCompleted &&
                            <Typography color="textSecondary" className={classes.feedbackDate} style={{marginTop: 5}}>
                                Completed on: {moment(item.feedbackDateCreated).format('h:mma M/D/YY')}
                            </Typography>
                        }

                        {
                            item.isCompleted &&
                            <>
                                <Typography color="primary" className={classes.feedbackDate} style={{marginTop: 5}}>
                                    User Rating: {item.rating === null || item.rating === 0 ? 'none' : `${item.rating  }/5`}
                                </Typography>
                                <Typography color="primary" className={classes.feedbackDate} style={{marginTop: 5}}>
                                    Feedback Read: <b>{item.isRead ? 'YES' : 'NO'}</b>
                                </Typography>
                            </>

                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                    <Box>
                        {
                            !artifact.photoUrl &&
                            <Box>
                                <p>no photo</p>
                            </Box>
                        }
                        {
                            artifact.photoUrl &&
                            <img
                                src={getPhotoUrl(artifact.photoUrl || '', FileSize.SM)  || '' }
                                className={classes.artifactPhoto}
                                alt={artifact.title}
                            />
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Box>
                        <Typography color="textPrimary" className={classes.artifactName}>
                            {artifact.title}{' '}
                            <span className={classes.artifactId}>
                            #{artifact.id}
                        </span>
                        </Typography>

                        <Typography color="textPrimary" className={classes.artifactDescription}>
                            {
                                artifact.description.length > 500
                                    ? `${artifact.description.substring(0,500)}...`
                                    : artifact.description
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={2} className={classes.actionsBox}>
                    {
                        item.isArtifactUpdated &&
                        <Tooltip
                            arrow
                            interactive={true}
                            title={'The collector updated photos or documents in the last 10 days.'}
                            className="full-artifact-name-tooltip"
                        >
                            <StarIcon />
                        </Tooltip>
                    }
                    <Box>
                        {
                            userContext.user?.role === UserRoles.ADMIN && !isDeleted &&
                            <Button
                                id="delete-feedback-btn"
                                variant="text"
                                color="secondary"
                                type="button"
                                className={classes.deleteFeedbackBtn}
                                style={item.isArtifactUpdated ? {right: 35, top: 7} : {}}
                                onClick={() => {
                                    if(confirm('Are you sure you want to delete this feedback request?')) {
                                        onDelete();
                                    }
                                }}
                            >
                                Delete
                            </Button>
                        }
                        {
                            !isDeleted &&
                            <Link to={`${AppRoutes.FEEDBACK_REQUEST}?id=${item.id}`}>
                                <Button
                                    id="give-feedback-btn"
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    className={classes.giveFeedbackBtn}
                                >
                                    {
                                        item.isCompleted || userContext.user?.role === UserRoles.ADMIN
                                            ? 'See Request'
                                            : 'Give Feedback'
                                    }
                                </Button>
                            </Link>
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
