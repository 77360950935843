import * as api from '../_utils/api';

export class CollectorCollectionApi {

    static async getCollections(sortBy: string, asc: boolean|undefined, page?: number, size?: number): Promise<any> {
        const payload = {
            shared: false,
        }
        return api._post(`collection/get?page=${page}&size=${size}&desc=${!asc}&orderBy=${sortBy}`, payload);
    }

    static async getCollectionItems(
        collectionId: number, page: number, size: number, sortBy: string, asc: boolean|undefined,
        isSharedWithMe: boolean
    ): Promise<any> {
        if(isSharedWithMe) {
            // eslint-disable-next-line max-len
            return api._get(`collection/get/${collectionId}?page=${page}&size=${size}&desc=${!asc}&orderBy=${sortBy}`);
        }
        return api._get(`collection/get/${collectionId}?page=${page}&size=${size}&desc=${!asc}&orderBy=${sortBy}`);
    }

    static async addArtifactsToCollection(collectionId: number, artifacts: number[]): Promise<any> {
        return api._post(`collection/addArtifactsToCollection/${collectionId}`, artifacts);
    }

    static async updateCollection(collectionId: number, payload: unknown): Promise<any> {
        return api._put(`collection/update/${collectionId}`, payload);
    }

    static async shareCollection(collectionId: number, emails: string[], message: string): Promise<any> {
        return api._post(`share/shareCollection/${collectionId}`, {message, emails});
    }
}
