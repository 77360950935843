import {IAction} from "../../interfaces";
import {ICuratorProfileState} from "./curator-profile.interfaces";

export default (state: ICuratorProfileState, action: IAction): ICuratorProfileState => {
    switch(action.type) {
        case 'SET_DATA':
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
}
