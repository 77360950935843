import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import {myriadProBold, myriadProLight, myriadProRegular, myriadProSemiBold} from "../fonts/myriad-pro";

export const darkTheme: ThemeOptions = createMuiTheme({
    rootBackground: {
        main: "#23232B",
        secondary: "#383838",
        secondaryBorder: "#444444",
        cardBackground: "#2B2B33",
        progressBarBackground: "#707070",
        modalBackground: '#33333B',
        highlightBackground: '#423534',
    },
    divider: {
      main: '#383838',
    },
    dashboardHeader: {
        main: '#2B2B33'
    },
    label: {
        main: '#707070',
        text: '#EFEFEF',
        labelBackground: '#34343a'
    },
    formLabel: {
        main: "#ACACAC",
    },
    palette: {
        common: {
            black: "#000",
            white: "#fff"
        },
        background: {
            default: "#23232B",
            paper: "#2B2B33"
        },
        text: {
            primary: "#EFEFEF",
            secondary: "#ACACAC",
        },
        primary: {
            main: "#FE6502",
            contrastText: "#FFFFFF"
        },
        secondary: {
            main: "#029BFE",
            contrastText: "#FFFFFF"
        },
        error: {
            main: "#B32222",
        },
        warning: {
            main: "#FE6502",
        },
        info: {
            main: "#707070"
        },
        success: {
            main: "#57B894",
        }
    },
    typography: {
        fontFamily: [
            'Myriad Pro',
            'Myriad Pro Regular',
            'Myriad Pro Semibold',
            'sans-serif'
        ].join(','),
    },
    shape: {
        borderRadius: 3,
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [
                    myriadProRegular,
                    myriadProBold,
                    myriadProLight,
                    myriadProSemiBold,
                ],
            },
        },
        MuiTextField: {
            root: {
                borderRadius: 3,
                color: '#fff',
                '& input:disabled': {
                    color: '#ACACAC',
                },
                '& textarea:disabled': {
                    color: '#ACACAC',
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                backgroundColor: '#383838',
                borderRadius: 3,
                color: '#B2B2B2',
                '& input:disabled': {
                    color: '#ACACAC',
                },
            },
        },
        MuiButton: {
            root: {
                textTransform: 'none',
                fontSize: 16,
                borderRadius: 2,
            }
        },
        MuiSelect: {
            root: {
                paddingTop: 10,
                paddingBottom: 10,
                '& input:disabled': {
                    color: '#ACACAC',
                },
            }
        },
        MuiChip: {
            root: {
               backgroundColor: '#707070 !important',
            }
        }
    },
});
