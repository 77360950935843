import React, {useContext} from "react";
import { modalStyles } from "components/_others/modals/modal-styles";
import { Backdrop, Box, Button, Fade, Modal, Typography } from "@material-ui/core";

import InfoIcon from '@material-ui/icons/Info';
import {UserContext} from "../../../../../core/context/user";
import {ModalHeader} from "../../../../../components/_others/modals/ModalHeader";

type MissingInformationModalProps = {
    open: boolean;
    missingFields: string[];
    handleClose: () => void;
    primaryAction: () => void;
    secondaryAction: () => void;
}

export const MissingInformationModal: React.FC<MissingInformationModalProps> = (
    {open, handleClose, missingFields, primaryAction, secondaryAction}
) => {

    const classes = modalStyles();
    const userContext = useContext(UserContext);

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <ModalHeader
                        title={'Missing Information'}
                        handleClose={() => handleClose()}
                    />

                    <Typography
                        style={{fontSize: '13px', color: userContext.lightTheme ? 'black' : '#B2B2B2'}}
                    >
                        Don't spare the details! Make sure all your object's information is
                        logged for our team of experts.
                    </Typography>

                    <Typography
                        style={{
                            fontSize: '12px',
                            color: userContext.lightTheme ? 'black' : '#B2B2B2',
                            margin: '15px 0',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <InfoIcon style={
                            {marginRight: '7px', color: userContext.lightTheme ? 'black' : '#B2B2B2', width: '15px'}
                        } />
                        You can still request an assessment with limited information and we will try our best
                        to make an accurate evaluation of your object.
                    </Typography>

                    {
                        missingFields.indexOf('photos') > -1 &&
                        <Typography className="error-message" color="error" style={{marginBottom: 7}}>
                            <b>There is one exception: </b> please add at least one photo to your artifact,
                            otherwise the expert will not be able to evaluate it.
                        </Typography>
                    }

                    <Typography
                        style={{fontSize: '13px', color: userContext.lightTheme ? 'black' : '#B2B2B2',
                            marginBottom: '10px'}}
                    >
                        Currently Empty Fields:
                    </Typography>

                    <Typography
                        style={{fontSize: '14px', color: userContext.lightTheme ? 'black' : '#B2B2B2',
                            fontWeight: 'bold'}}
                    >
                        {missingFields.join(', ')}
                    </Typography>

                    <Typography className="error-message" color="error">
                        <b>Note: </b> You will not be able to edit your object information once an
                        assessment order is placed. This excludes photos and documentation.
                    </Typography>

                    <Box textAlign={'right'} marginTop={'20px'}>
                        {
                            missingFields.indexOf('photos') === -1 &&
                            <Button
                                id="go-to-assessment-btn"
                                variant="contained"
                                color="primary"
                                size={"small"}
                                style={{marginRight: '10px', fontSize: '14px'}}
                                className={classes.secondaryButton}
                                onClick={() => secondaryAction()}
                            >
                                Continue to Assessment
                            </Button>
                        }

                        <Button
                            id="go-to-edit-btn"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            style={{fontSize: '14px'}}
                            className={classes.primaryButton}
                            onClick={() => primaryAction()}
                        >
                            Add the missing information
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    )
}
