import React from "react";
import { Box, Button, Typography } from "@material-ui/core";

import ReactPlayer from "react-player";
import video from "assets/videos/tutorial_photo.mp4";
import {howToPhotographStyles} from "../styles/how-to-photograph.style";

type HowToPhotographProps = {
    onAddObject: () => void;
    hideNavigation?: boolean;
}

export const HowToPhotograph: React.FC<HowToPhotographProps> = ({onAddObject, hideNavigation}) => {

    const classes = howToPhotographStyles();

    return (
        <Box className={classes.root}>
            <div className={classes.videoContainer}>
                <ReactPlayer
                    url={video}
                    width='100%'
                    height='100%'
                    controls = {true}
                />
            </div>

            <Typography variant="h6" className={classes.title}>
                How to photograph, archive & catalog all of your collectibles?
            </Typography>

            {
                !hideNavigation &&
                <Box className={classes.navigationContainer}>
                    <Button
                        id="go-to-add-object-btn"
                        variant="contained"
                        color="primary"
                        onClick={() => onAddObject()}
                    >
                        Add object
                    </Button>
                </Box>
            }

        </Box>
    )
}

