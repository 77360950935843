import { createContext, useReducer } from 'react';
import { ISharedObjectState } from './shared-object.interfaces';
import SharedObjectReducer from './shared-object.reducer';

const initialState: ISharedObjectState = {
    id: 0,
    artifact: {
        id: 0,
        collectorName: '',
        collectorPhoto: '',
        title: '',
        description: '',
        exactValue: null,
        minimumValue: null,
        maximumValue: null,
        width: null,
        height: null,
        depth: null,
        weight: null,
        exactProductionYear: null,
        lowerProductionYear: null,
        upperProductionYear: null,
        isFeedbackImported: false,
        artistImageUrl: null,
        origin: '',
        material: '',
        style: '',
        artist: '',
        condition: '',
        message: '',
        createdDate: '',
        photos: [],
        categories: [],
        materials: [],
        conditions: [],
        eras: [],
        origins: [],
        styles: [],
        feedbacks: [],
        documents: [],
    },
    setArtifact: () => null,
};

export const SharedObjectContext = createContext(initialState);

export const SharedObjectProvider: any = ({ children }: any) => {
    const [state, dispatch] = useReducer(SharedObjectReducer, initialState);

    const setArtifact = (artifact: unknown): void => {
        dispatch({
            type: 'SET_ARTIFACT',
            payload: artifact,
        });
    };

    return (
        <SharedObjectContext.Provider
            value={{
                ...state,
                setArtifact,
            }}
        >
            {children}
        </SharedObjectContext.Provider>
    );
};
