import React, { useContext, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { makeStyles } from '@material-ui/styles';
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Fade,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    ThemeOptions,
    Typography,
} from '@material-ui/core';

import { modalStyles } from 'components/_others/modals/modal-styles';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import { CommonApi, ICity, ICountry, ICurrency, IState } from 'core/api/shared';
import { ManageUsersApi } from 'core/api/admin/manage-users.api';

import { UserRoles, WEBSITE } from '../../../core';
import { cleanPhoneNumber } from '../../../core/helpers/utils';
import { UtilsContext } from '../../../core/context/utils.context';

type EditUserProfileModalProps = {
    open: boolean;
    handleClose: () => void;
    onConfirm: () => void;
    modalTitle: string;
    userId: number;
    data: {
        role: string;
        firstName: string;
        lastName: string;
        title: string;
        phoneNumber: string;
        description: string;
        organization: string;
        expertise?: string;
        website: string;
        address: string;
        currencyId: number;
        countryId: number;
        stateId: number;
        cityId: number;
    };
};

const useStyles = makeStyles((theme: ThemeOptions) => ({
    root: {
        marginTop: 20,
    },
    label: {
        color: theme.formLabel.main,
        opacity: 0.9,
    },
    errorLabel: {
        color: '#B32222',
    },
    focusLabel: {
        color: '#FE6502',
    },
    formControl: {
        marginBottom: 15,
        marginTop: 4,
    },
}));

export const EditUserProfileModal: React.FC<EditUserProfileModalProps> = ({
    open,
    handleClose,
    onConfirm,
    modalTitle,
    userId,
    data,
}) => {
    const classes = modalStyles();
    const formClasses = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [onFocus, setOnFocus] = useState('');
    const utilsContext = useContext(UtilsContext);

    const [currencies, setCurrencies] = useState<ICurrency[]>([]);
    const [countries, setCountries] = useState<ICountry[]>([]);
    const [states, setStates] = useState<IState[]>([]);
    const [cities, setCities] = useState<ICity[]>([]);

    const [values, setValues] = useState({
        role: '',
        firstName: '',
        lastName: '',
        title: '',
        description: '',
        organization: '',
        website: '',
        address: '',
        phoneNumber: '',
        expertise: '',
        currencyId: -1,
        countryId: -1,
        stateId: -1,
        cityId: -1,
    });
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        website: '',
        expertise: '',
        phoneNumber: '',
    });

    useEffect(() => {
        setIsLoading(false);
        setValues({ ...data, expertise: data.expertise || '' });
        getCurrencies();
        getCountries();

        if (data.stateId !== undefined) {
            getStates(data.countryId);
        }
        if (data.cityId !== undefined) {
            getCities(data.stateId);
        }
    }, []);

    const getCurrencies = (): void => {
        CommonApi.getCurrencies()
            .then((res: any) => {
                setCurrencies(res.data);
            })
            .catch((error: any) => {
                console.error(error.response);
            });
    };

    const getCountries = (): void => {
        CommonApi.getCountries()
            .then((res: any) => {
                setCountries(res.data);
            })
            .catch((error: any) => {
                console.error(error.response);
            });
    };

    const getStates = (countryId: number): void => {
        CommonApi.getStates(countryId)
            .then((res: any) => {
                setStates(res.data);
            })
            .catch((error: any) => {
                console.error(error.response);
            });
    };

    const getCities = (stateId: number): void => {
        CommonApi.getCities(stateId)
            .then((res: any) => {
                setCities(res.data);
            })
            .catch((error: any) => {
                console.error(error.response);
            });
    };

    const manageErrors = (): void => {
        const errF = !values.firstName
            ? 'Please enter your first name'
            : values.firstName.replace(/[a-z]/g, '').length === values.firstName.length
            ? 'Please enter a valid first name'
            : '';

        const errL = !values.lastName
            ? 'Please enter your last name'
            : values.lastName.replace(/[a-z]/g, '').length === values.lastName.length
            ? 'Please enter a valid last name'
            : '';

        const errW =
            values.website && !WEBSITE.test(values.website) ? 'Website format is not valid. Eg. www.artifact.com' : '';
        const errExp = !values.expertise.trim().length ? 'Please enter the expertise.' : '';
        const errP =
            cleanPhoneNumber(values.phoneNumber).length !== 10 && cleanPhoneNumber(values.phoneNumber).length !== 0
                ? 'Invalid phone number'
                : '';
        setErrors({
            firstName: errF,
            lastName: errL,
            expertise: errExp,
            website: errW,
            phoneNumber: errP,
        });
    };

    const onSave = (): void => {
        manageErrors();
        if (
            !values.firstName ||
            !values.lastName ||
            (values.website && !WEBSITE.test(values.website)) ||
            (errors.expertise !== '' && data.role === UserRoles.EXPERT) ||
            errors.phoneNumber !== ''
        ) {
            return;
        }
        setIsLoading(true);
        ManageUsersApi.updateUserProfile(userId, values, data.role)
            .then(() => {
                setIsLoading(false);
                onConfirm();
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar('error');
            });
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            style={{ top: '20px', bottom: '20px' }}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper} style={{ maxHeight: '600px', overflow: 'auto' }}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography className={classes.modalTitle} style={{ color: '#029BFE' }}>
                            {modalTitle}
                        </Typography>
                        <CloseIcon cursor={'pointer'} onClick={handleClose} />
                    </Box>

                    <form id="edit-collector-form" className={formClasses.root}>
                        <>
                            <InputLabel
                                htmlFor="first-name"
                                className={
                                    errors.firstName !== ''
                                        ? formClasses.errorLabel
                                        : onFocus === 'firstName'
                                        ? formClasses.focusLabel
                                        : formClasses.label
                                }
                            >
                                First Name*
                            </InputLabel>
                            <TextField
                                id="first-name"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                inputProps={{ maxLength: 30 }}
                                className={formClasses.formControl}
                                helperText={errors.firstName}
                                error={errors.firstName !== ''}
                                value={values.firstName}
                                onChange={(e: any) => setValues({ ...values, firstName: e.target.value })}
                                onFocus={() => setOnFocus('firstName')}
                                onBlur={() => {
                                    setOnFocus('');
                                    manageErrors();
                                }}
                            />
                        </>

                        <>
                            <InputLabel
                                htmlFor="last-name"
                                className={
                                    errors.lastName !== ''
                                        ? formClasses.errorLabel
                                        : onFocus === 'lastName'
                                        ? formClasses.focusLabel
                                        : formClasses.label
                                }
                            >
                                Last Name*
                            </InputLabel>
                            <TextField
                                id="last-name"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                inputProps={{ maxLength: 30 }}
                                className={formClasses.formControl}
                                helperText={errors.lastName}
                                error={errors.lastName !== ''}
                                value={values.lastName}
                                onChange={(e: any) => setValues({ ...values, lastName: e.target.value })}
                                onFocus={() => setOnFocus('lastName')}
                                onBlur={() => {
                                    setOnFocus('');
                                    manageErrors();
                                }}
                            />
                        </>

                        <>
                            <InputLabel
                                htmlFor="title"
                                className={onFocus === 'title' ? formClasses.focusLabel : formClasses.label}
                            >
                                Title
                            </InputLabel>
                            <TextField
                                id="title"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                inputProps={{ maxLength: 100 }}
                                className={formClasses.formControl}
                                value={values.title}
                                onChange={(e: any) => setValues({ ...values, title: e.target.value })}
                                onFocus={() => setOnFocus('title')}
                                onBlur={() => {
                                    setOnFocus('');
                                }}
                            />
                        </>

                        <>
                            <InputLabel
                                htmlFor="phoneNumber"
                                className={
                                    errors.phoneNumber !== ''
                                        ? formClasses.errorLabel
                                        : onFocus === 'phoneNumber'
                                        ? formClasses.focusLabel
                                        : formClasses.label
                                }
                            >
                                Phone Number
                            </InputLabel>
                            <InputMask
                                mask="(999) 999-9999"
                                value={values.phoneNumber}
                                onChange={(e: any) => setValues({ ...values, phoneNumber: e.target.value })}
                                onFocus={() => setOnFocus('phoneNumber')}
                                onBlur={() => {
                                    setOnFocus('');
                                    manageErrors();
                                }}
                                disabled={false}
                            >
                                {(inputProps: any) => (
                                    <TextField
                                        {...inputProps}
                                        id="phoneNumber"
                                        variant="outlined"
                                        size="small"
                                        type="tel"
                                        fullWidth
                                        error={errors.phoneNumber}
                                        helperText={errors.phoneNumber}
                                        className={formClasses.formControl}
                                    />
                                )}
                            </InputMask>
                        </>

                        <>
                            <InputLabel
                                htmlFor="description"
                                className={onFocus === 'description' ? formClasses.focusLabel : formClasses.label}
                            >
                                Bio
                            </InputLabel>
                            <TextField
                                multiline
                                rows={3}
                                rowsMax={10}
                                id="description"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                inputProps={{ maxLength: 1000 }}
                                className={formClasses.formControl}
                                value={values.description}
                                onChange={(e: any) => setValues({ ...values, description: e.target.value })}
                                onFocus={() => setOnFocus('description')}
                                onBlur={() => {
                                    setOnFocus('');
                                }}
                            />
                        </>

                        {data.role === UserRoles.EXPERT && (
                            <>
                                <InputLabel
                                    htmlFor="expertise"
                                    className={
                                        errors.expertise !== ''
                                            ? formClasses.errorLabel
                                            : onFocus === 'expertise'
                                            ? formClasses.focusLabel
                                            : formClasses.label
                                    }
                                >
                                    Expertise
                                </InputLabel>
                                <TextField
                                    multiline
                                    rows={3}
                                    rowsMax={10}
                                    id="expertise"
                                    variant="outlined"
                                    size="small"
                                    type="text"
                                    fullWidth
                                    error={errors.expertise !== ''}
                                    helperText={errors.expertise}
                                    className={formClasses.formControl}
                                    value={values.expertise}
                                    inputProps={{ maxLength: 1000 }}
                                    onChange={(e: any) => setValues({ ...values, expertise: e.target.value })}
                                    onFocus={() => setOnFocus('expertise')}
                                    onBlur={() => {
                                        setOnFocus('');
                                        manageErrors();
                                    }}
                                />
                            </>
                        )}

                        <>
                            <InputLabel
                                htmlFor="address"
                                className={onFocus === 'address' ? formClasses.focusLabel : formClasses.label}
                            >
                                Address
                            </InputLabel>
                            <TextField
                                id="address"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                inputProps={{ maxLength: 100 }}
                                className={formClasses.formControl}
                                value={values.address}
                                onChange={(e: any) => setValues({ ...values, address: e.target.value })}
                                onFocus={() => setOnFocus('address')}
                                onBlur={() => {
                                    setOnFocus('');
                                }}
                            />
                        </>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <>
                                    <InputLabel
                                        htmlFor="country"
                                        className={onFocus === 'country' ? formClasses.focusLabel : formClasses.label}
                                    >
                                        Country
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        labelId="country"
                                        id="country"
                                        className={formClasses.formControl}
                                        label="Country"
                                        value={values.countryId}
                                        disabled={!countries}
                                        onFocus={() => setOnFocus('country')}
                                        onBlur={() => {
                                            setOnFocus('');
                                            manageErrors();
                                        }}
                                        onChange={(e) => {
                                            setValues({
                                                ...values,
                                                countryId: Number(e.target.value),
                                                stateId: -1,
                                                cityId: -1,
                                            });
                                            setCities([]);
                                            getStates(Number(e.target.value));
                                        }}
                                    >
                                        <MenuItem value={-1}>
                                            <em>None</em>
                                        </MenuItem>
                                        {countries.map((c) => (
                                            <MenuItem value={c.id} key={c.id}>
                                                {c.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <>
                                    <InputLabel
                                        htmlFor="state"
                                        className={onFocus === 'state' ? formClasses.focusLabel : formClasses.label}
                                    >
                                        State
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        labelId="state"
                                        id="state"
                                        className={formClasses.formControl}
                                        label="State"
                                        value={values.stateId}
                                        disabled={!values.countryId || !states}
                                        onFocus={() => setOnFocus('state')}
                                        onBlur={() => {
                                            setOnFocus('');
                                        }}
                                        onChange={(e) => {
                                            setValues({
                                                ...values,
                                                stateId: Number(e.target.value),
                                                cityId: -1,
                                            });
                                            getCities(Number(e.target.value));
                                        }}
                                    >
                                        <MenuItem value={-1}>
                                            <em>None</em>
                                        </MenuItem>
                                        {states.map((c) => (
                                            <MenuItem value={c.id} key={c.id}>
                                                {c.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <>
                                    <InputLabel
                                        htmlFor="city"
                                        className={onFocus === 'city' ? formClasses.focusLabel : formClasses.label}
                                    >
                                        City
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        labelId="city"
                                        id="city"
                                        className={formClasses.formControl}
                                        label="City"
                                        value={values.cityId}
                                        disabled={!values.stateId || !cities}
                                        onFocus={() => setOnFocus('city')}
                                        onBlur={() => {
                                            setOnFocus('');
                                        }}
                                        onChange={(e) => {
                                            setValues({ ...values, cityId: Number(e.target.value) });
                                        }}
                                    >
                                        <MenuItem value={-1}>
                                            <em>None</em>
                                        </MenuItem>
                                        {cities.map((c) => (
                                            <MenuItem value={c.id} key={c.id}>
                                                {c.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </>
                            </Grid>
                        </Grid>

                        <>
                            <InputLabel
                                htmlFor="organization"
                                className={onFocus === 'organization' ? formClasses.focusLabel : formClasses.label}
                            >
                                Organization
                            </InputLabel>
                            <TextField
                                id="organization"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                inputProps={{ maxLength: 100 }}
                                className={formClasses.formControl}
                                value={values.organization}
                                onChange={(e: any) => setValues({ ...values, organization: e.target.value })}
                                onFocus={() => setOnFocus('organization')}
                                onBlur={() => {
                                    setOnFocus('');
                                    manageErrors();
                                }}
                            />
                        </>

                        <>
                            <InputLabel
                                htmlFor="website"
                                className={
                                    errors.website !== ''
                                        ? formClasses.errorLabel
                                        : onFocus === 'website'
                                        ? formClasses.focusLabel
                                        : formClasses.label
                                }
                            >
                                Website
                            </InputLabel>
                            <TextField
                                id="website"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                inputProps={{ maxLength: 100 }}
                                error={errors.website !== ''}
                                helperText={errors.website}
                                className={formClasses.formControl}
                                value={values.website}
                                onChange={(e: any) => setValues({ ...values, website: e.target.value })}
                                onFocus={() => setOnFocus('website')}
                                onBlur={() => {
                                    setOnFocus('');
                                    manageErrors();
                                }}
                            />
                        </>

                        <>
                            <InputLabel
                                htmlFor="currency"
                                className={onFocus === 'currency' ? formClasses.focusLabel : formClasses.label}
                            >
                                Currency
                            </InputLabel>
                            <Select
                                fullWidth
                                variant="outlined"
                                labelId="currency"
                                id="currency"
                                className={formClasses.formControl}
                                label="Currency"
                                value={values.currencyId}
                                onFocus={() => setOnFocus('currency')}
                                onBlur={() => {
                                    setOnFocus('');
                                }}
                                onChange={(e) => setValues({ ...values, currencyId: Number(e.target.value) })}
                            >
                                <MenuItem value={-1}>
                                    <em>None</em>
                                </MenuItem>
                                {currencies.map((c) => (
                                    <MenuItem value={c.id} key={c.id}>
                                        {c.code} - {c.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </>
                    </form>

                    <Box textAlign={'right'} marginTop={'60px'}>
                        <Button
                            id="cancel-delete-btn"
                            variant="contained"
                            size={'small'}
                            style={{ marginRight: '15px' }}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>

                        <Button
                            id="delete-btn"
                            variant="contained"
                            color="primary"
                            size={'small'}
                            className={classes.primaryButton}
                            onClick={() => onSave()}
                        >
                            {isLoading && <CircularProgress className="circular-progress sm" />}
                            {!isLoading && (
                                <SaveIcon
                                    style={{
                                        width: 20,
                                        marginRight: 5,
                                    }}
                                />
                            )}
                            Save
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    );
};
