import React, {useState} from "react";
import {Divider, Link, ListItem, Typography} from "@material-ui/core";

import {mobileMenuStyles} from "../_styles/mobile-menu.styles";
import {lightTheme} from "assets/themes/light-theme";
import {darkTheme} from "assets/themes/dark-theme";

import {INavLink} from "../_helpers/nav-link.interfaces";

import {ContactUsModal} from "components/_others/ContactUsModal";
import {ThemeProvider} from "@material-ui/core/styles";
import {MobileMenuNavLink} from "./MobileMenuNavLink";

type NavigationProps = {
    navLinks: INavLink[];
    authLinks: INavLink[];
    onAuthPages: boolean;
    onLightTheme: boolean;
    handleClose: () => void;
}

export const MobileMenuNavigation: React.FC<NavigationProps>  = (
    {navLinks, authLinks, onAuthPages, onLightTheme, handleClose}
) => {

    const mobileMenuClasses = mobileMenuStyles();

    const [displayContactUsModal, toggleContactUsModal] = useState(false);

    return (
        <>
            {navLinks.map((navLink: INavLink) => (
                <MobileMenuNavLink key={navLink.id} navLink={navLink} onClick={() => handleClose()} />
            ))}

            {
                onAuthPages &&
                <Link className={'active-link'} onClick={() => toggleContactUsModal(true)}>
                    <ListItem>
                        <Typography variant="h6" color={'textPrimary'}>
                            Contact Us
                        </Typography>
                    </ListItem>
                </Link>
            }

            {
                authLinks.length > 0 &&
                    <>
                        <Divider className={mobileMenuClasses.divider}/>

                        {authLinks
                            .filter((navLink: INavLink) => navLink.onAuthPages === onAuthPages)
                            .map((navLink: INavLink) => (
                                <MobileMenuNavLink key={navLink.id} navLink={navLink} onClick={() => handleClose()} />
                            ))
                        }
                    </>
            }

            <ThemeProvider theme={onLightTheme ? lightTheme : darkTheme}>
                <ContactUsModal
                    open={displayContactUsModal}
                    handleClose={() => toggleContactUsModal(false)}
                />
            </ThemeProvider>
        </>
    )
}
