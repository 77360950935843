import {IAction} from "../../interfaces";
import {IArchivistProfileState} from "./archivist-profile.interfaces";

export default (state: IArchivistProfileState, action: IAction): IArchivistProfileState => {
    switch(action.type) {
        case 'SET_DATA':
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
}
