import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UtilsContext } from 'core/context/utils.context';
import { CollectionApi } from 'core/api/collection.api';
import { ICollection } from 'types';
import { usePagination } from 'core/hooks/use-pagination.hook';
import { AppRoutes, getMyObjectsRoute } from 'core/helpers';
import { SortCollectionsOptions, UserRoles } from 'core/enums';
import { getNewSortingOption } from 'scenes/consumer/_helpers/collections-sorting.helper';
import { UserContext } from '../../../../core/context/user';

export const useMyCollectionsData = (): any => {
    const userContext = useContext(UserContext);
    const utilsContext = useContext(UtilsContext);
    const history = useHistory();

    const [collections, setCollections] = useState<ICollection[]>([]);

    const { pagination, setPagination } = usePagination();

    useEffect(() => {
        utilsContext.setAppLoaderState(true);
    }, []);

    const getCollections = (page: number, size: number, sortBy: string, asc: boolean | undefined): void => {
        utilsContext.setAppLoaderState(true); // eslint-disable-next-line max-len
        // const sortAscending: boolean | undefined = ['item', 'datecreated', 'value'].includes(sortBy.toLowerCase())
        //     ? !asc
        //     : asc;
        CollectionApi.getMyCollections(page, size, sortBy, !!asc)
            .then((res: any) => {
                setCollections(mapCollections(res.data?.data || [], userContext.user?.role, userContext.user?.id));
                setPagination({
                    ...pagination,
                    page,
                    size,
                    sortBy,
                    asc,
                    totalCount: res.data.totalCount,
                    numberOfPages: Math.ceil(res.data.totalCount / res.data.size),
                });
                utilsContext.setAppLoaderState(false);
                window.scrollTo(0, 0);
            })
            .catch((error: any) => {
                console.error(error.response);
                setCollections([]);
                utilsContext.addSnackbar(
                    'error',
                    'Sorry, there was an error and we were unable to display your collections.',
                );
                history.push(getMyObjectsRoute(userContext.user?.role));
                utilsContext.setAppLoaderState(false);
            });
    };

    const handlePageChange = (page: number): void => {
        const { sortBy, asc, size } = pagination;
        history.push(`${AppRoutes.MY_COLLECTIONS}?page=${page}&size=${size}&sortBy=${sortBy}&desc=${!asc}`);
        getCollections(page, size, pagination.sortBy || SortCollectionsOptions.DateCreated, pagination.asc);
    };

    const handlePageSizeChange = (size: number): void => {
        const { sortBy, asc, page } = pagination;
        history.push(`${AppRoutes.MY_COLLECTIONS}?page=${page}&size=${size}&sortBy=${sortBy}&desc=${!asc}`);
        getCollections(page, size, pagination.sortBy || SortCollectionsOptions.DateCreated, pagination.asc);
    };

    const handleSortByChange = (option: string): void => {
        const { sortBy, asc, size, page } = pagination;
        const newOption = getNewSortingOption(sortBy || '', option, asc || false);
        history.push(`${AppRoutes.MY_COLLECTIONS}?page=${1}&sortBy=${newOption.type}&desc=${!newOption.asc}`);
        getCollections(page, size, newOption.type, newOption.asc);
    };

    return {
        collections,
        pagination,
        getCollections,
        handlePageChange,
        handlePageSizeChange,
        handleSortByChange,
    };
};

const mapCollections = (data: any, role: string | undefined, userId: number | undefined): ICollection[] => {
    return data.map((c: any) => {
        const canDelete = role === UserRoles.COLLECTOR ? true : c.createdBy === userId;
        const canShare = role === UserRoles.COLLECTOR || role === UserRoles.CURATOR;

        return {
            id: c.id,
            name: c.name,
            photoUrl: c.photoUrl,
            totalObjects: c.totalObjects,
            displayShareButtonOnCard: canShare && c.totalObjects > 0,
            displayDeleteButtonOnCard: canDelete,
        };
    });
};
