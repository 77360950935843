import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";

import { CollectorListingContext } from "core/context/admin/collector-listing";
import { GalleryTypeEnum } from "components/Gallery/helpers/GalleryTypeEnum";
import { UserRoles } from "core/enums";

import { Loader} from "components/_others";
import { Gallery } from "components/Gallery";
import { Searchbox } from "components/_dashboard/Searchbox/Searchbox";
import { InviteUsersModal } from "components/_dashboard/InviteUsersModal";
import {useHistory} from "react-router-dom";
import {getCurrentPageFromUrl} from "../../../../../core/helpers/utils";
import {AppRoutes} from "../../../../../core/helpers";
import {UtilsContext} from "../../../../../core/context/utils.context";
import {AdminUsersApi} from "../../../../../core/api/admin-users.api";


export const CollectorListing: React.FC = () => {

    const history = useHistory();

    const collectorListingContext = useContext(CollectorListingContext);

    const [displayInviteModal, toggleInviteModal] = useState(false);
    const utilsContext = useContext(UtilsContext);    const [searchBy, setSearchBy] = useState('');

    useEffect(() => {
        const page = getCurrentPageFromUrl();
        collectorListingContext.setCurrentPage(page);
        getCollectors(page, '');
    }, [])

    const getCollectors = (page: number, searchBy: string): void => {
        collectorListingContext.setLoading(true);

        AdminUsersApi.getCollectors(collectorListingContext.pagination.size, page, searchBy)
            .then((res: any) => {
                collectorListingContext.setCollectors(res.data);
                window.scrollTo(0, 0);
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error');
            })
    }

    const handlePageChange = (page: number, searchBy: string): void => {
        history.push(`${AppRoutes.COLLECTOR_LISTING}?page=${page}`);
        collectorListingContext.setCurrentPage(page);
        getCollectors(page, searchBy);
    }

    return (
        <Box>
            {
                !collectorListingContext.isLoading &&
                    <>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Box>
                                <Typography variant="h5" color="textPrimary">
                                    Collectors
                                </Typography>
                                <Typography variant="h5" color="textSecondary" style={{fontSize: 16}}>
                                    {collectorListingContext.statistics.totalUsers}
                                </Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'}>
                                <Button
                                    id="delete-btn"
                                    variant="contained"
                                    color="primary"
                                    size={"small"}
                                    onClick={() => toggleInviteModal(true)}
                                >
                                    Invite Collectors
                                </Button>

                                <Searchbox
                                    searchBy={searchBy}
                                    setSearchBy={(val: string) => setSearchBy(val)}
                                    onClear={() => {
                                        setSearchBy('');
                                        handlePageChange(1, '');
                                    }}
                                    onSearch={(val: string) => val && val.length > 3
                                        ? handlePageChange(1, val)
                                        : handlePageChange(1, '')
                                    }
                                />
                            </Box>
                        </Box>


                        <Box marginTop={'15px'}>
                            <Gallery
                                galleryType={GalleryTypeEnum.Collectors}
                                data={collectorListingContext.collectors}
                                pagination={{
                                    numberOfPages: collectorListingContext.pagination.numberOfPages,
                                    currentPage: collectorListingContext.pagination.page,
                                }}
                                handlePageChange={(page: number) => handlePageChange(page, searchBy)}
                            />
                        </Box>

                        {
                            collectorListingContext.displayEmptyPage &&
                            <Typography variant="h5" color="textSecondary" style={{fontSize: 16}}>
                                This list is empty.
                            </Typography>
                        }

                    </>
            }

            {
                collectorListingContext.isLoading &&
                <Loader />
            }

            <InviteUsersModal
                open={displayInviteModal}
                role={UserRoles.COLLECTOR}
                handleClose={() => toggleInviteModal(false)}
            />
        </Box>
    )
}
