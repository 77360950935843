import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container } from '@material-ui/core';

import 'scenes/consumer/addObject/styles/style.less';
import 'scenes/consumer/addObject/styles/mobile.less';

import { Header } from 'scenes/consumer/addObject/components/Header';
import { ObjectForm } from 'components/_consumer/ObjectForm';
import { Loader } from 'components/layout/_components/Loader';
import { IObject } from 'components/_consumer/ObjectForm/helpers/object.interface';
import { AppRoutes } from 'core/helpers/app-routes';

import { splitArrayIntoChunksOfLen } from '../../../../../core/helpers/utils';
import { GlobalContext } from '../../../../../core/context/global';
import { IPhoto, ObjectDetailsContext } from '../../../../../core/context/consumer/objectDetails';
import { UnsavedChangesModal } from '../../../../../components/_others';
import { UtilsContext } from '../../../../../core/context/utils.context';
import { ObjectApi } from '../../../../../core/api';

const useStyles = makeStyles({
    objectFormBox: {
        marginTop: 110,
        maxWidth: 685,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});

export const AddObject: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();

    const globalContext = useContext(GlobalContext);
    const objectDetailsContext = useContext(ObjectDetailsContext);

    const [hasErrors, setErrors] = useState(true);
    const [displayErrors, setDisplayErrors] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [photos, setPhotos] = useState<Array<string | IPhoto>>([]);
    const [documents, setDocuments] = useState<File[]>([]);
    const [values, setValues] = useState<IObject>({
        title: '',
        description: '',
        height: '',
        width: '',
        depth: '',
        weight: '',
        material: '',
        style: '',
        origin: '',
        condition: '',
        artist: '',
        artistBio: '',
        exactProductionYear: null,
        lowerProductionYear: null,
        upperProductionYear: null,
        productionYearOption: '',
        exactYearNotation: 'AD',
        lowerYearNotation: 'AD',
        upperYearNotation: 'AD',
        era: '',
        exactValue: '',
        minimumValue: '',
        maximumValue: '',
        estimatedValueOption: '',
        categories: [],
        collections: [],
        artistImageUrl: '',
    });

    const utilsContext = useContext(UtilsContext);
    useEffect(() => {
        window.scrollTo(0, 0);
        const params = new URLSearchParams(window.location.search);
        const id = params.get('collectionId');
        const name = params.get('collectionName');
        if (!id || !name) {
            return;
        }
        setValues({ ...values, collections: [{ id: Number(id), name }] });
    }, []);

    const addObject = (bypassPhoto = false): void => {
        if (hasErrors) {
            setDisplayErrors(displayErrors + 1);
            return;
        }

        if (objectDetailsContext.modals.displayUnsavedPhotosModal && !bypassPhoto) {
            objectDetailsContext.modals.toggleUnsavedChangesModal();
            return;
        }

        objectDetailsContext.modals.toggleDisplayUnsavedPhotosModal(false);
        setIsLoading(true);

        if(photos?.length > 0) {
            const firstPhoto = photos[0];
            const remainingPhotos = photos.slice(1);

            ObjectApi.preUploadPhoto(firstPhoto as string)
                .then((res: any) => {
                    const formValues = { ...values, photoId: res.data.id };
                    ObjectApi.addObject(formValues)
                        .then((res: any) => {
                            if (remainingPhotos.length) {
                                uploadPhotos(res.data.id, remainingPhotos as string[]);
                            }

                            if (documents.length) {
                                uploadDocuments(res.data.id);
                            }

                            setIsLoading(false);
                            history.push({
                                pathname: AppRoutes.OBJECT,
                                search: `?id=${res.data.id}`,
                            });
                        })
                        .catch((error: any) => {
                            throw error;
                        });
                })
                .catch((error: any) => {
                    console.error(error.response);
                    setIsLoading(false);
                    utilsContext.addSnackbar('error');
                });
        } else {
            ObjectApi.addObject(values)
                .then((res: any) => {
                    if (photos.length) {
                        uploadPhotos(res.data.id, photos as string[]);
                    }

                    if (documents.length) {
                        uploadDocuments(res.data.id);
                    }

                    setIsLoading(false);
                    history.push({
                        pathname: AppRoutes.OBJECT,
                        search: `?id=${res.data.id}`,
                    });
                })
                .catch((error: any) => {
                    throw error;
                });
        }



    };

    const addAndAssessObject = (): void => {
        if (hasErrors) {
            setDisplayErrors(displayErrors + 1);
            return;
        }

        if (objectDetailsContext.modals.displayUnsavedPhotosModal) {
            objectDetailsContext.modals.toggleUnsavedChangesModal();
            return;
        }

        objectDetailsContext.modals.toggleDisplayUnsavedPhotosModal(false);
        setIsLoading(true);
        const firstPhoto = photos[0];
        const remainingPhotos = photos.slice(1);

        ObjectApi.preUploadPhoto(firstPhoto as string)
            .then((res: any) => {
                const formValues = { ...values, photoId: res.data.id };
                ObjectApi.addObject(formValues)
                    .then((res: any) => {
                        if (remainingPhotos.length) {
                            uploadPhotos(res.data.id, remainingPhotos as string[]);
                        }

                        if (documents.length) {
                            uploadDocuments(res.data.id);
                        }

                        setIsLoading(false);
                        history.push({
                            pathname: AppRoutes.OBJECT,
                            search: `?id=${res.data.id}&assess=open`,
                        });
                    })
                    .catch((error: any) => {
                        throw error;
                    });
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar('error');
            });
    };

    const uploadPhotos = (artifactId: number, remainingPhotos?: string[]): void => {
        const uploadPhotosChunks = splitArrayIntoChunksOfLen(
            remainingPhotos ? remainingPhotos : photos,
            Math.ceil(photos.length / 3),
        );

        if (uploadPhotosChunks[0]?.length) {
            globalContext.setUploadPhotosBatch1(true);
            globalContext.setShouldReloadBatch1(true);
            ObjectApi.addPhoto(artifactId, uploadPhotosChunks[0], 0)
                .then(() => {
                    globalContext.setUploadPhotosBatch1(false);
                })
                .catch((error: any) => {
                    console.error(error);
                    setIsLoading(false);
                    utilsContext.addSnackbar(
                        'error',
                        'Sorry, there was an error and some of your photos were ' +
                            'not saved. Please try again later.',
                    );
                });
        }

        if (uploadPhotosChunks[1]?.length) {
            globalContext.setUploadPhotosBatch2(true);
            globalContext.setShouldReloadBatch2(true);
            ObjectApi.addPhoto(artifactId, uploadPhotosChunks[1], 1)
                .then(() => {
                    globalContext.setUploadPhotosBatch2(false);
                })
                .catch((error: any) => {
                    console.error(error);
                    setIsLoading(false);
                    utilsContext.addSnackbar(
                        'error',
                        'Sorry, there was an error and some of your photos were ' +
                            'not saved. Please try again later.',
                    );
                });
        }

        if (uploadPhotosChunks[2]?.length) {
            globalContext.setUploadPhotosBatch3(true);
            globalContext.setShouldReloadBatch3(true);
            ObjectApi.addPhoto(artifactId, uploadPhotosChunks[2], 2)
                .then(() => {
                    globalContext.setUploadPhotosBatch3(false);
                })
                .catch((error: any) => {
                    console.error(error);
                    setIsLoading(false);
                    utilsContext.addSnackbar(
                        'error',
                        'Sorry, there was an error and some of your photos were not' +
                            ' saved. Please try again later.',
                    );
                });
        }
    };

    const uploadDocuments = (artifactId: number): void => {
        ObjectApi.addFile(artifactId, documents)
            .then(() => {
                setIsLoading(false);
            })
            .catch((error: any) => {
                console.error(error);
                setIsLoading(false);
                utilsContext.addSnackbar(
                    'error',
                    'Sorry, there was an error and your documents were not saved.' + ' Please try again later.',
                );
            });
    };

    return (
        <Container maxWidth={false} className="add-object-container">
            {isLoading && <Loader />}

            {!isLoading && (
                <>
                    <Header onAdd={addObject} onAddAndAssess={addAndAssessObject} />

                    <Box className={classes.objectFormBox}>
                        <ObjectForm
                            onAddNew={true}
                            values={values}
                            displayErrors={displayErrors}
                            setValues={(values: IObject) => setValues(values)}
                            setFormErrors={(hasErrors: boolean) => setErrors(hasErrors)}
                            setPhotos={setPhotos}
                            setDocuments={(documents: File[]) => setDocuments(documents)}
                        />
                    </Box>
                </>
            )}

            <UnsavedChangesModal
                open={objectDetailsContext.modals.displayUnsavedChangesModal}
                handleClose={() => objectDetailsContext.modals.toggleUnsavedChangesModal()}
                onConfirm={() => {
                    objectDetailsContext.modals.toggleUnsavedChangesModal();
                    addObject(true);
                }}
            />
        </Container>
    );
};
