import React from "react";
import {Box, Typography} from "@material-ui/core";

import {emptyStyles} from "../styles/empty.style";

import {ReactComponent as EmptyPageIcon} from "assets/images/empty-page.svg";
import {AddNewCollectionBtn} from "./AddNewCollectionBtn";

export const Empty: React.FC = () => {

    const classes = emptyStyles();

    return (
        <Box className={classes.root}>
            <div className={classes.content}>
                <EmptyPageIcon className={classes.icon}/>

                <Typography color="textPrimary" className={classes.typography}>
                    You do not have any collections yet.
                    Please click the button below to start organizing your collectibles!
                </Typography>

                <AddNewCollectionBtn />

            </div>
        </Box>
    )
}
