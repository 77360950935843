import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    label: {
        color: '#B2B2B2',
        fontSize: 13,
        marginTop: 10,
    },
    value: {
        fontSize: 13,
        marginBottom: 10,
    }
})

type DimensionsProps = {
    height: number|null;
    width: number|null;
    depth: number|null;
}

export const Dimensions: React.FC<DimensionsProps> = ({height, width, depth}) => {

    const classes = useStyles();

    return (
        <>
            <Typography className={classes.label}>
                Dimensions
            </Typography>
            <Typography className={classes.value} color={"textPrimary"}>
                {height ? `${height}''` : 'unknown'}&nbsp;H
                &nbsp;&nbsp;
                x
                &nbsp;&nbsp;
                {width ? `${width}''` : 'unknown'}&nbsp;&nbsp;W
                &nbsp;&nbsp;
                x&nbsp;&nbsp;
                {depth ? `${depth}''` : 'unknown'}&nbsp;&nbsp;D
            </Typography>
        </>
    )
}
