import AXIOS from './axios-config';
import { getToken } from "../../helpers/storage-helper";

export async function _get(url: string): Promise<any> {
    AXIOS.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
    return AXIOS.get(url);
}

export async function _put(url: string, payload: unknown = null, headers: unknown = null): Promise<any> {
    AXIOS.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
    return headers ? AXIOS.put(url, payload, {headers}) : AXIOS.put(url, payload);
}

export async function _post(url: string, payload: unknown = null, headers: unknown = null): Promise<any> {
    AXIOS.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
    return headers ? AXIOS.post(url, payload, {headers}) : AXIOS.post(url, payload);
}

export async function _patch(url: string, payload: unknown = null, headers: unknown = null): Promise<any> {
    AXIOS.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
    return headers ? AXIOS.patch(url, payload, {headers}) : AXIOS.patch(url, payload);
}

export async function _delete(url: string, payload: any = null): Promise<any> {
    AXIOS.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
    return AXIOS.delete(url, {data: payload});
}
