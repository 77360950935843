import React, {useContext, useEffect, useState} from "react";
import {Box} from "@material-ui/core";
import {UtilsContext} from "../../core/context/utils.context";
import {ConsumerFooter, CustomSnackbar, Loader, PrivateRoute} from "../../components";
import {AppRoutes, setJobIdInStorage} from "../../core/helpers";
import {AssignedCollectors} from "./AssignedCollectors";
import {getJobIdFromUrl} from "../../core/helpers/utils";


export const HelperApp: React.FC = () => {

    const utilsContext = useContext(UtilsContext);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const jobId = getJobIdFromUrl();
        if(jobId !== -1) {
            utilsContext.selectJob(jobId);
            setJobIdInStorage(jobId);
        }
        setIsLoading(false);
    }, [])

    return (
        <Box>
            <Box style={{minHeight: '72vh'}}>
                {
                    utilsContext.appLoader && <Loader />
                }

                {
                    !isLoading &&
                    <Box style={utilsContext.appLoader ? {display: 'none'} : {}}>
                        <PrivateRoute component={AssignedCollectors} path={AppRoutes.ASSIGNED_COLLECTORS_INDEX} />
                    </Box>
                }

            </Box>

            <ConsumerFooter />

            <CustomSnackbar
                open={utilsContext.snackbar.text !== ''}
                handleClose={() => utilsContext.closeSnackbar()}
                severity={utilsContext.snackbar.severity}
                message={utilsContext.snackbar.text}
            />
        </Box>
    )
}
