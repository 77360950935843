import React, {useContext, useEffect, useState} from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Typography } from "@material-ui/core";

import { PaymentApi } from "core/api/consumer/payment.api";
import { CollectorApi } from "core/api/consumer";
import {UserContext} from "../../../../../core/context/user";

type PaymentProps = {
    artifactId: number;
    paymentFreePass: boolean;
    message: string;
    reload: number;
    onSuccess: () => void;
    onError: (err: any) => void;
}

const cardStyle = {
    style: {
        base: {
            color: "#B2B2B2",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            backgroundColor: window.localStorage.getItem('theme') !== 'light' ? '#383838' : '#EFEFEF',
            "::placeholder": {
                color: "#707070"
            },
        },

        invalid: {
            color: "#B32222",
            iconColor: "#B32222"
        }
    },
    hidePostalCode: true,
};

export const Payment: React.FC<PaymentProps> = ({paymentFreePass, artifactId, message,reload, onSuccess, onError}) => {

    const stripe = useStripe();
    const elements = useElements();

    const userContext = useContext(UserContext);

    const [error, setError] = useState<string|null>(null);

    useEffect(() => {
        if(artifactId === -1) {
            return;
        }
        onRequestAssessment();
    }, [reload])

    const handleCardChange = (event: any): void => {
        setError(event.error ? event.error.message : "");
    };

    const onRequestAssessment = (): void => {
        if(paymentFreePass) {
            submitFeedback('');
            return;
        }
        PaymentApi.createPaymentIntent(artifactId)
            .then((res: any) => {
                void handlePaymentSubmit(res.data.paymentClientSecret);
            })
            .catch((error: any) => {
                console.error(error.response);
                setError('There was an error and the payment could not be completed.');
            })
    }

    const handlePaymentSubmit = async (paymentClientSecret: string): Promise<any> => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const payload: any = await stripe.confirmCardPayment(
            paymentClientSecret, {
            payment_method: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                card: elements.getElement(CardElement),
            }})

        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            onError(`Sorry, there was an error. Please try again later.`);
        } else {
            setError(null);
            submitFeedback(paymentClientSecret);
        }
    };

    const submitFeedback = (paymentClientSecret: string): void => {
        CollectorApi.requestFeedback(artifactId, message, paymentClientSecret)
            .then(() => {
                setTimeout(() => {
                    onSuccess();
                }, 5000)
            })
            .catch((error: any) => {
                console.error(error.response);
                console.error(error)
                onError(error?.data?.message);
            })
    }

    return (
        <form id="payment-form">
            <div style={{backgroundColor: userContext.lightTheme ? '#EFEFEF' : '#383838', padding: 10}}>
                <CardElement
                    id="card-element"
                    options={cardStyle}
                    onChange={handleCardChange}
                />
            </div>

            {error && (
                <Typography className="error-message" color={'error'}>
                    {error}
                </Typography>
            )}
        </form>
    )
}
