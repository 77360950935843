export enum RegisterTypesEnum {
    Web = 'Web',
    Mobile = 'Mobile',
    Facebook = 'Facebook',
    Google = 'Google',
    Apple = 'Apple',
    System = 'System',
}

export enum CollectorTypes {
    AVERAGE = 'Average',
    AVID = 'Avid',
}

export enum FileSize {
    XS = '/xs', //150
    SM = '/s', //400
    MD = '/m', //900
    LG = '/l', //1920
    DEFAULT = '',
}

export enum SortObjectsOptions {
    Title = 'Title',
    EstimatedValue = 'Value',
    Feedbacks = 'Feedback',
    DateCreated = 'DateCreated',
    Artist = 'Artist',
}

export enum SortCollectionsOptions {
    Title = 'Name',
    NumberOfItems = 'Item',
    EstimatedValue = 'Value',
    DateCreated = 'DateCreated',
}

export enum UserRoles {
    COLLECTOR = 'Collector',
    EXPERT = 'Expert',
    ARCHIVIST = 'Archivist',
    CURATOR = 'Curator',
    ADMIN = 'Admin',
}

export enum ShareArtifactOptions {
    PublicFeed,
    People,
}

export enum AdminStatistics {
    Date = 'Date',
    User = 'User',
    UserByDay = 'UserByDay',
    TotalUsersStats = 'TotalUsersStats',
    TotalUsersByDate = 'TotalUsersByDate',
}

export enum ReportTypeEnum {
    MissingPhotos = 'OnePhoto',
    MissingDetails = 'MissingDetails',
    SharedOnCommunityFeed = 'PublicFeed',
}

export enum ExpertsSearchType {
    Default = 'Default',
    Type = 'Type',
    Expertise = 'Expertise',
    Location = 'Location',
}

export const PreferencesDictionary = {
    1: 'Title',
    2: 'Name',
    3: 'Value',
    4: 'Feedback',
    5: 'DateCreated',
    6: 'Artist',
    7: 'Item',
};
