import React, { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Button, LinearProgress, Paper, Typography } from '@material-ui/core';

import FileCopyIcon from '@material-ui/icons/FileCopy';

import { TextDivider } from '../../../_others/TextDivider';
import { UserContext } from '../../../../core/context/user';
import { IDocument } from 'core/context/consumer/objectDetails';

type UploadDocumentsProps = {
    onUpload: (file: File) => void;
    initialDocuments: IDocument[];
    documents: File[];
};

export const UploadDocuments: React.FC<UploadDocumentsProps> = ({ onUpload, initialDocuments, documents }) => {
    const userContext = useContext(UserContext);

    const [file, setFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);

    const onDrop = useCallback((acceptedFiles) => {
        handleUpload(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleUpload = (files: File[]): void => {
        if (!files?.length) {
            return;
        }

        if (files.length + initialDocuments.length + documents.length > 10) {
            alert('We are sorry, but you can upload a maximum of 10 documents to an artifact.');
            return;
        }

        const file = files[0];
        if (file.size > 40 * 1000000) {
            alert('We are sorry, but your file size is too large. You can only upload files of up to 40 MB.');
            return;
        }

        if (
            file?.name.substr(file.name.length - 4, 4).toLowerCase() === '.exe' ||
            file?.name.substr(file.name.length - 4, 4).toLowerCase() === '.bat'
        ) {
            alert('We are sorry, but we do not accept this file type.');
            return;
        }

        setTimeout(() => {
            onUpload(file);
            setUploading(false);
            setFile(null);
        }, 100);
    };

    return (
        <Paper
            elevation={0}
            className="upload-photos-paper"
            style={
                userContext.lightTheme
                    ? { backgroundColor: '#F7F7F7', border: '1px solid #bababa' }
                    : { backgroundColor: '#383838' }
            }
        >
            <div {...getRootProps()}>
                <input {...getInputProps()} multiple={false} accept=".doc,.docx,.png,.jpeg,.jpg,.pdf,.xls,.xlsx,.txt" />
                <Box className={file ? 'dropzone-area has-file' : 'dropzone-area'}>
                    {!file && <FileCopyIcon className={'dropzone-area-icon'} />}
                    {file && <Typography style={{ margin: 'auto' }}>{file.name}</Typography>}
                </Box>
            </div>

            {uploading && (
                <Box textAlign={'left'} marginTop={'15px'}>
                    <Typography className="updating-text">Updating...</Typography>
                    <LinearProgress color="primary" />
                </Box>
            )}

            {!file && (
                <>
                    <Typography className="drag-and-drop-text">Drag & drop your files</Typography>

                    <Box margin={'15px auto'} width={'40%'}>
                        <TextDivider text="or" />
                    </Box>

                    <input
                        style={{ display: 'none' }}
                        accept=".doc,.docx,.png,.jpeg,.jpg,.pdf,.xls,.xlsx,.txt"
                        id="upload-documents"
                        type="file"
                        onChange={(e: any) => handleUpload(e.target.files)}
                    />
                    <label htmlFor="upload-documents">
                        <Button id="upload-documents-btn" variant="contained" color="primary" component="span">
                            Select a file from your computer
                        </Button>
                    </label>
                </>
            )}
        </Paper>
    );
};
