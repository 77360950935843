import React, {useContext, useState} from "react";
import {Box, Container, Typography} from "@material-ui/core";
import {UserContext} from "core/context/user";
import {rootStyles} from "./styles/root.style";
import imgSrc from "assets/images/assign_collectors.svg";
import {AssignJobModal} from "components/jobs/AssignJobModal";
import {UserRoles} from "core/enums";
import {UtilsContext} from "core/context/utils.context";
import { useHistory } from "react-router-dom";
import {AppRoutes, setJobIdInStorage} from "../../../core/helpers";


export const AssignedCollectors: React.FC = () => {

    const userContext = useContext(UserContext);
    const utilsContext = useContext(UtilsContext);

    const history = useHistory();

    const [displayJobsModal, toggleDisplayJobsModal] = useState(true);

    const classes = rootStyles();

    const onSelectJob = (id: number): void => {
        utilsContext.selectJob(id);
        setJobIdInStorage(id);
        toggleDisplayJobsModal(false);
        history.push(`${AppRoutes.MY_OBJECTS}?jobId=${id}`);
    }

    return (
        <Container maxWidth={false} className={classes.root}>

            <Box className={classes.content}>
                <Typography color={'textPrimary'} className={classes.title}>
                    Hi, {userContext.user?.firstName}!
                </Typography>

                <Typography color={'textSecondary'} className={classes.subtitle}>
                    In order to start working,{' '}
                    <span
                        className={classes.seeCollectors}
                        onClick={() => toggleDisplayJobsModal(true)}
                    >
                        click here
                    </span>
                    {' '}to see the list of your assigned Collectors.
                </Typography>

                <img src={imgSrc} alt={'assign-collectors'} />
            </Box>

            {
                displayJobsModal &&
                <AssignJobModal
                    open={true}
                    handleClose={() => toggleDisplayJobsModal(false)}
                    title={'Collectors'}
                    jobRole={UserRoles.COLLECTOR}
                    userId={userContext.user?.id || 0}
                    userRole={userContext.user?.role || ''}
                    notAssigned={false}
                    onJobSelect={(userId: number) => onSelectJob(userId)}
                />
            }
        </Container>
    )
}
