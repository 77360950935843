import { IUserState } from "./user.interfaces";
import { IAction } from "../interfaces";
import {clearLocalStorage, setRole, setToken} from "../../helpers";

export default (state: IUserState, action: IAction): IUserState => {
    switch(action.type) {
        case 'SET_USER':
            if(action.payload.token !== undefined) {
                setToken(action.payload.token, action.payload.refreshToken);
            }
            setRole(action.payload.roles);
            return {
                ...state,
                newNotifications: action.payload.newNotifications,
                user: {
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                    email: action.payload.email,
                    title: action.payload.title,
                    phoneNumber: action.payload.phoneNumber,
                    description: action.payload.description,
                    address: action.payload.address,
                    country: action.payload.country,
                    state: action.payload.state,
                    city: action.payload.city,
                    organization: action.payload.organization,
                    website: action.payload.website,
                    currency: action.payload.currency,
                    role: action.payload.roles,
                    id: action.payload.id,
                    photoUrl: action.payload.photoUrl,
                    registerType: action.payload.registerType,
                    paymentFreePass: action.payload.paymentFreePass || false,
                },
                lightTheme: localStorage.getItem('theme') === 'light',
            };
        case 'SET_PROFILE_PIC':
            return {
                ...state,
                user: {
                    id: state.user?.id || 0,
                    firstName: state.user?.firstName || '',
                    lastName: state.user?.lastName || '',
                    role: state.user?.role || '',
                    email: state.user?.email || '',
                    title: state.user?.title || '',
                    phoneNumber: state.user?.phoneNumber || '',
                    description: state.user?.description || '',
                    address: state.user?.address || '',
                    country: state.user?.country || null,
                    state: state.user?.state || null,
                    city: state.user?.city || null,
                    organization: state.user?.organization || '',
                    website: state.user?.website || '',
                    currency: state.user?.currency || null,
                    registerType: state.user?.registerType || '',
                    paymentFreePass: state.user?.paymentFreePass || false,
                    photoUrl: action.payload
                }
            }
        case 'LOGOUT':
            clearLocalStorage();
            return {
                ...state,
                user: null,
                lightTheme: false,
            }
        case 'SET_HAS_NEW_NOTIFICATIONS':
            return {
                ...state,
                newNotifications: action.payload,
            }
        case 'SET_THEME':
            if(action.payload.save) {
                localStorage.setItem('theme', action.payload.value === true ? 'light' : 'dark')
            }
            return {
                ...state,
                lightTheme: action.payload.value,
            }
        default:
            return state;
    }
}
