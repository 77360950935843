import {IAction} from "../../interfaces";
import {ICollectionState} from "./collection.interfaces";

export default (state: ICollectionState, action: IAction): ICollectionState => {
    switch (action.type) {
        case 'SET_ARTIFACTS':

            // eslint-disable-next-line no-case-declarations
            const artifacts = action.payload.artifacts.data;
            artifacts?.forEach((a: any) => {
                a.displayDeleteButtonOnCard = true;
            })

            return {
                ...state,
                name: action.payload.name,
                artifacts,
                isLoading: false,
                displayEmptyPage: artifacts?.length === 0,
                statistics: {
                    totalObjects: action.payload.artifacts.totalCount,
                    totalValue: action.payload.artifacts.totalValue,
                },
                pagination: {
                    ...state.pagination,
                    page: state.pagination.page,
                    size: state.pagination.size,
                    totalCount: action.payload.artifacts.totalCount,
                    numberOfPages: Math.ceil(action.payload.artifacts.totalCount/state.pagination.size),
                },
            }
        case 'SET_CURRENT_PAGE':
            return {
                ...state,
                isLoading: false,
                pagination: {
                    ...state.pagination,
                    page: action.payload.page,
                    size: action.payload.size,
                }
            }
        case 'SET_SORT_OPTION':
            return {
                ...state,
                isLoading: false,
                pagination: {
                    ...state.pagination,
                    sortBy: action.payload.sortBy,
                    asc: action.payload.asc,
                }
            }
        case 'SET_LOADING':
            return {
                ...state,
                isLoading: action.payload,
            }
        case 'SET_COLLECTION_ID':
            return {
                ...state,
                id: action.payload,
            }
        case 'TOGGLE_DELETE_COLLECTION_MODAL':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    displayDeleteCollectionModal: action.payload,
                },
            }
        case 'TOGGLE_ADD_OBJECT_MODAL':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    displayAddObjectModal: action.payload,
                },
            }
        case 'TOGGLE_DELETE_OBJ_MODAL':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    displayDeleteObjectModal: action.payload
                }
            }
        case 'TOGGLE_EDIT_COLLECTION_NAME_MODAL':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    displayEditCollectionNameModal: action.payload
                }
            }
        default:
            return state;
    }
}
