import {useContext, useState} from "react";
import {usePagination} from "core/hooks/use-pagination.hook";
import {UtilsContext} from "core/context/utils.context";
import {IJob} from "../../../types";
import {JobsApi} from "core/api/jobs.api";
import {UserRoles} from "core/enums";

export const useAssignedUsersData = (userId: number): any => {

    const utilsContext = useContext(UtilsContext);

    const [archivists, setArchivists] = useState<IJob[]>([]);
    const [curators, setAssignedCurators] = useState<IJob[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const {pagination, setPagination} = usePagination();

    const getAssignedArchivists = (page: number, isOld = false): void => {
        setIsLoading(true);

        const body = {
            userId,
            old: isOld,
        }

        JobsApi.getAssignedUsers(10, page, UserRoles.ARCHIVIST, body)
            .then((res: any) => {
                setArchivists(mapData(res?.data?.data || []));
                setPagination({
                    ...pagination,
                    page,
                    size: 10,
                    totalCount: res.data?.totalCount,
                    numberOfPages: Math.ceil(res.data?.totalCount/res.data?.size)
                });
                setIsLoading(false);
            })
            .catch((error: any) => {
                console.log(error)
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar(
                    'error',
                    'Sorry, there was an error and we were unable to display the assigned users.'
                );
            })
    }

    const getAssignedCurators = (page: number, isOld = false): void => {
        const body = {
            userId,
            old: isOld,
        }

        JobsApi.getAssignedUsers(10, page, UserRoles.CURATOR, body)
            .then((res: any) => {
                setAssignedCurators(mapData(res?.data?.data || []));
                setPagination({
                    ...pagination,
                    page,
                    size: 10,
                    totalCount: res.data?.totalCount,
                    numberOfPages: Math.ceil(res.data?.totalCount/res.data?.size)
                });
                setIsLoading(false);
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar(
                    'error',
                    'Sorry, there was an error and we were unable to display the assigned users.'
                );
            })
    }

    return {
        archivists,
        isLoading,
        curators,
        getAssignedArchivists,
        getAssignedCurators,
    }
}

const mapData = (data: any): IJob[] => {
    return data.map((j: any) => {
        return {
            userPhotoUrl: j.photoUrl,
            userName: `${j.firstName || ''  } ${  j.lastName || ''}`,
            userEmail: j.email,
            userId: j.userId,
            info: ''
        }
    })
}
