import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Box, ThemeOptions, Typography } from "@material-ui/core";

import { IActiveRequest } from "core/context/admin/collector-profile";
import { AppRoutes } from "core/helpers";

type ActiveRequestsProps = {
    activeRequests: IActiveRequest[]
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
    title: {
        fontSize: 16,
        marginBottom: 7
    },
    emptyText: {
        fontSize: 14,
    },
    requestBox: {
        backgroundColor: theme.label.main,
        borderLeft: '7px solid #FE6502',
        padding: '10px',
        borderRadius: 2,
        marginBottom: 10,
        width: '100%'
    },
    objectTitle: {
        fontSize: 15,
        color: theme.label.text,
    },
    subtitle: {
        fontSize: 14,
    },
    link: {
        color: '#029BFE'
    }
}))

export const ActiveRequests: React.FC<ActiveRequestsProps> = ({activeRequests}) => {

    const classes = useStyles();

    return (
        <Box>
            <Typography variant="h5" className={classes.title} color="textSecondary">
                Active Requests:
            </Typography>
            {
                activeRequests.map(object => (
                    <Box key={object.artifact.id} className={classes.requestBox}>
                        <Typography variant="h5" className={classes.objectTitle}>
                            {object.artifact.title}
                        </Typography>
                        <Typography variant="h5" className={classes.subtitle} color="textSecondary">
                            This collector requested feedback for{' '}
                            <Link
                                to={`${AppRoutes.FEEDBACK_REQUEST}?id=${object.id}`}
                                className={classes.link}>this object
                            </Link>.
                        </Typography>
                    </Box>
                ))
            }
            {
                !activeRequests.length &&
                <Typography variant="h5" className={classes.emptyText} color="textPrimary">
                    This collector has no active requests.
                </Typography>
            }
        </Box>
    )
}
