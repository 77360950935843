import React, { useContext } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';

import { ReactComponent as EmptyPageIcon } from 'assets/images/empty-page.svg';
import { emptyTheme } from 'assets/themes/components/empty-theme';
import { CollectionContext } from 'core/context/consumer/collection';
import { UserContext } from 'core/context/user';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    content: {
        margin: 'auto',
        textAlign: 'center',
    },
    icon: {
        marginBottom: 30,
    },
    typography: {
        marginBottom: 50,
        fontSize: 20,
        maxWidth: 525,
        lineHeight: 1,
    },
    button: {
        width: 335,
        fontSize: 18,
    },
});

export const EmptyCollection: React.FC = () => {
    const collectionContext = useContext(CollectionContext);

    const classes = useStyles();

    const { user } = useContext(UserContext);
    return (
        <ThemeProvider theme={emptyTheme}>
            <Box className={classes.root}>
                <div className={classes.content}>
                    <EmptyPageIcon className={classes.icon} />

                    <Typography variant="h6" color="textPrimary" className={classes.typography}>
                        {`Opps! You have no artifacts in this collection. ${
                            user?.role.toLowerCase() !== 'archivist' ? " I think it's time to add one." : ''
                        }`}
                    </Typography>

                    {user?.role.toLowerCase() !== 'archivist' && (
                        <Button
                            id="add-artifact-to-collection-btn"
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                            onClick={() => collectionContext.modals.toggleAddObjectModal()}
                        >
                            Add Object
                        </Button>
                    )}
                </div>
            </Box>
        </ThemeProvider>
    );
};
