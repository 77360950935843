import React from "react";
import { Box, ThemeOptions, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import InfoIcon from "@material-ui/icons/Info";

type InfoTextProps = {
    text: string;
    fontSize?: number;
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        width: 15,
        height: 15,
        color: theme.palette?.text?.secondary
    },
    text: {
        fontSize: 14,
        marginLeft: 5,
        color: theme.palette?.text?.secondary,
        lineHeight: 1.2
    }
}))

export const InfoText: React.FC<InfoTextProps> = ({text, fontSize}) => {

    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <InfoIcon className={classes.icon} />
            <Typography className={classes.text} style={fontSize ? {fontSize}: {}}>
                {text}
            </Typography>
        </Box>
    )
}
