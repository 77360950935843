import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";

import logo from "assets/artifact-logo.png";

import {AppRoutes} from "core/helpers";

export const useStyles = makeStyles(_theme => ({
    root: {
        height: 30,
        cursor: 'pointer',
        ["@media (max-width:1200px)"]: { height: 22, },
    },
}))

export const Logo: React.FC = () => {

    const classes = useStyles();

    const history = useHistory();

    return (
        <img
            onClick={() => history.push(AppRoutes.BASE)}
            src={logo}
            alt="artifact-logo"
            className={classes.root}
        />
    )
}
