import React from "react";
import {Box, Button, CircularProgress} from "@material-ui/core";

type GiveFeedbackActionsProps = {
    onCancel: () => void;
    onSaveFeedback: () => void;
    onSaveDraft: () => void;
    isSaveDraftLoading: boolean;
    isLoading: boolean;
}

export const GiveFeedbackActions: React.FC<GiveFeedbackActionsProps> = (
    {onCancel, onSaveFeedback,onSaveDraft, isLoading, isSaveDraftLoading}
) => {
    return (
        <Box>
            <Button
                id="cancel-save-feedback-btn"
                color="default"
                type="button"
                onClick={() => onCancel()}
                style={{marginRight: 10}}
                disabled={isLoading || isSaveDraftLoading}
            >
                Cancel
            </Button>
            <Button
                id="draft-save-feedback-btn"
                color="secondary"
                variant="contained"
                type="button"
                onClick={() => onSaveDraft()}
                style={{marginRight: 10}}
                disabled={isLoading || isSaveDraftLoading}
            >
                {
                    isSaveDraftLoading && <CircularProgress className="circular-progress sm" style={{color: '#029BFE'}}/>
                }
                Save Draft
            </Button>
            <Button
                id="save-feedback-btn"
                variant="contained"
                color="primary"
                type="button"
                onClick={() => onSaveFeedback()}
                disabled={isLoading || isSaveDraftLoading}
            >
                Submit Feedback
            </Button>
        </Box>
    )
}
