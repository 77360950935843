import React from "react";
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { Box, Button, Typography } from "@material-ui/core";

import { ReactComponent as EmptyPageIcon } from "assets/images/empty-notifications.svg";
import { emptyTheme } from "assets/themes/components/empty-theme";
import { AppRoutes } from "core/helpers";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    content: {
        margin: 'auto',
        textAlign: 'center',
    },
    icon: {
        marginBottom: 30,
    },
    typography: {
        marginBottom: 50,
        fontSize: 20,
        maxWidth: 525,
        lineHeight: 1,
    },
    button: {
        width: 335,
        fontSize: 18,
    }
});

export const Empty: React.FC = () => {

    const classes = useStyles();
    const history = useHistory();

    return (
        <ThemeProvider theme={emptyTheme}>
            <Box className={classes.root}>
                <div className={classes.content}>
                    <EmptyPageIcon className={classes.icon}/>

                    <Typography variant="h6" color="textPrimary" className={classes.typography}>
                        Opps! It appears that you don’t have any notifications
                    </Typography>

                    <Button
                        id="go-to-my-objects-btn"
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            history.push(AppRoutes.BASE)
                        }}
                    >
                        Back to Homepage
                    </Button>
                </div>
            </Box>
        </ThemeProvider>
    )
}
