import React from "react";
import {Box, ClickAwayListener, Hidden, Theme, Tooltip, Zoom} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';

import "./styles/style.less";

import { ObjectCard } from "./components/ObjectCard"; 
import { CollectionCard } from "./components/CollectionCard";
import { SharedWithMeCard} from "./components/SharedWithMeCard";
import { CollectorCard } from "./components/CollectorCard";
import { ExpertCard } from "./components/ExpertCard";
import { ArchivistCard } from "./components/ArchivistCard";
import { CuratorCard } from "./components/CuratorCard";

import { ICollection } from "types";
import { ICollector } from "core/context/admin/collector-listing";
import { IExpert } from "core/context/admin/expert-listing";
import { ISharedArtifact } from "core/context/consumer/sharedWithMe";
import { GalleryTypeEnum } from "./helpers/GalleryTypeEnum";
import { IArchivist } from "core/context/admin/archivist-listing";
import { ICurator } from "core/context/admin/curator-listing";
import {makeStyles} from "@material-ui/styles";
import {IObjectCard} from "../../types";

const useStylesOptionsMenu = makeStyles((theme: Theme) => ({
    option: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        paddingLeft: 14,
        paddingRight: 14,
        marginLeft: -7,
        marginRight: -7,
        height: 30,
        '&:hover': {
            color: theme.palette.secondary.main,
            backgroundColor: '#313131'
        },
        '&.active': {
            color: theme.palette.secondary.main,
            backgroundColor: '#313131'
        },
        '&:last-child': {
            marginBottom: 0
        }
    }
}))

type GalleryProps = {
    pagination: {
        numberOfPages: number,
        currentPage: number;
        pageSize?: number;
    },
    galleryType: number;
    data: any[];
    handlePageChange: (page: number) => void;
    onObjectDelete?: (id: number) => void;
    handlePageSizeChange?: (size: number) => void;
}

export const Gallery: React.FC<GalleryProps> = (
    {
        galleryType, data, pagination, handlePageChange,
        onObjectDelete, handlePageSizeChange
    }
) => {

    const [open, setOpen] = React.useState(false);

    const classes = useStylesOptionsMenu();

    return (
        <>
            <Box className="gallery-container">
                {
                    galleryType === GalleryTypeEnum.Artifacts &&
                        data.map((object: IObjectCard) => (
                            <ObjectCard
                                key={`${object.id}`}
                                artifact={object}
                                onDelete={() => onObjectDelete ? onObjectDelete(object.id) : null}
                            />
                        ))
                }
                {
                    galleryType === GalleryTypeEnum.Collections &&
                        data.map((object: ICollection) => (
                            <CollectionCard
                                key={object.id}
                                collection={object}
                                onDelete={() => onObjectDelete ? onObjectDelete(object.id) : null}
                            />
                        ))
                }
                {
                    galleryType === GalleryTypeEnum.CollectionsSharedWithMe &&
                    data.map((object: ICollection) => (
                        <CollectionCard
                            key={object.id}
                            collection={object}
                            isSharedWithMe={true}
                        />
                    ))
                }
                {
                    galleryType === GalleryTypeEnum.SharedWithMe &&
                       data.map((object: ISharedArtifact) => (
                           <SharedWithMeCard
                                key={object.id}
                                artifact={object}
                           />
                       ))
                }
                {
                    galleryType === GalleryTypeEnum.Collectors &&
                    data.map((collector: ICollector) => (
                        <CollectorCard
                            key={collector.userId}
                            collector={collector}
                        />
                    ))
                }
                {
                    galleryType === GalleryTypeEnum.Experts &&
                    data.map((expert: IExpert) => (
                        <ExpertCard
                            key={expert.userId}
                            expert={expert}
                        />
                    ))
                }
                {
                    galleryType === GalleryTypeEnum.Archivists &&
                    data.map((archivist: IArchivist) => (
                        <ArchivistCard
                            key={archivist.userId}
                            archivist={archivist}
                        />
                    ))
                }
                {
                    galleryType === GalleryTypeEnum.Curators &&
                    data.map((curator: ICurator) => (
                        <CuratorCard
                            key={curator.userId}
                            curator={curator}
                        />
                    ))
                }
            </Box>


            <Box textAlign="right" display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                <Hidden smDown={true}>
                    <>
                        {
                            handlePageSizeChange &&
                            <ClickAwayListener onClickAway={() => open ? setOpen(false) : null}>
                                <Tooltip
                                    arrow
                                    interactive
                                    TransitionComponent={Zoom}
                                    title={
                                        <Box>
                                            <Box
                                                className={
                                                    `${classes.option} ${pagination.pageSize === 20 ? 'active' : ''}`
                                                }
                                                onClick={(

                                                ) => handlePageSizeChange ? handlePageSizeChange(20) : null
                                                }
                                            >
                                                20 items/page
                                            </Box>
                                            <Box
                                                className={
                                                    `${classes.option} ${pagination.pageSize === 50 ? 'active' : ''}`
                                                }
                                                onClick={
                                                    () => handlePageSizeChange ? handlePageSizeChange(50) : null
                                                }
                                            >
                                                50 items/page
                                            </Box>
                                            <Box
                                                className={
                                                    `${classes.option} ${pagination.pageSize === 100 ? 'active' : ''}`
                                                }
                                                onClick={
                                                    () => handlePageSizeChange ? handlePageSizeChange(100) : null
                                                }
                                            >
                                                100 items/page
                                            </Box>
                                        </Box>
                                    }
                                    onClose={() => setOpen(false)}
                                    open={open}
                                    disableFocusListener
                                    disableHoverListener
                                    placement={'bottom-end'}
                                    id="sort-by-tooltip"
                                >
                        <span style={{marginBottom: 13, cursor: 'pointer'}} onClick={() => setOpen(!open)}>
                            {pagination.pageSize} items/page
                        </span>
                                </Tooltip>
                            </ClickAwayListener>
                        }
                    </>

                </Hidden>

                {
                    pagination.numberOfPages > 1 &&
                    <Pagination
                        count={pagination.numberOfPages}
                        page={pagination.currentPage}
                        siblingCount={0}
                        shape="rounded"
                        style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 10}}
                        onChange={(_e: any, value: number) => handlePageChange(value)}
                    />
                }

            </Box>

        </>
    )
}
