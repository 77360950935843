import React from "react";
import {List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {Link, useRouteMatch} from "react-router-dom";

import {drawerNavigationStyles} from "../styles";
import {INavLink} from "../../_helpers/nav-link.interfaces";

type NavigationProps = {
    navLinks: INavLink[]
}

export const Navigation: React.FC<NavigationProps> = ({navLinks}) => {

    const classes = drawerNavigationStyles();

    const isRouteMatch = (path: string, exact: boolean): any => {
        return useRouteMatch({
            path, exact
        });
    }

    return (
        <List>
            {navLinks.map((navLink: INavLink) => (
                <ListItem
                    key={navLink.id}
                    className={classes.listItem}
                    style={isRouteMatch(navLink.path, false) ? {backgroundColor: '#FE6502'} : {}}
                >
                    <Link to={navLink.path} className={classes.link}>
                        <ListItemIcon className={classes.icon}>
                            {
                                navLink.icon
                            }
                        </ListItemIcon>
                        <ListItemText primary={navLink.name} />
                    </Link>
                </ListItem>
            ))}
        </List>
    )
}
