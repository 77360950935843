import React, { useContext, useState } from 'react';
import { modalStyles } from 'components/_others/modals/modal-styles';
import { Backdrop, Box, Button, CircularProgress, Fade, Modal, Typography } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import { ManageUsersApi } from 'core/api/admin/manage-users.api';
import { UtilsContext } from '../../../core/context/utils.context';

type EditUserAccessModalProps = {
    open: boolean;
    userId: number;
    enable: boolean;
    handleClose: () => void;
    onSuccess: () => void;
    hasActiveJobs?: boolean;
};

export const EditUserAccessModal: React.FC<EditUserAccessModalProps> = ({
    open,
    userId,
    enable,
    handleClose,
    onSuccess,
    hasActiveJobs,
}) => {
    const classes = modalStyles();

    const utilsContext = useContext(UtilsContext);

    const [isLoading, setIsLoading] = useState(false);

    const onConfirm = (): void => {
        if (hasActiveJobs) {
            if (!confirm('This user has active jobs. If you continue, those will be deactivated.')) {
                return;
            }
        }
        setIsLoading(true);

        ManageUsersApi.editAccess(userId, enable)
            .then(() => {
                setIsLoading(false);
                onSuccess();
                handleClose();
                location.reload();
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar('error');
            });
    };

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Box textAlign={'right'}>
                        <CloseIcon cursor={'pointer'} onClick={handleClose} />
                    </Box>

                    <Box>
                        <Typography className={classes.modalTitle}>Manage user access</Typography>
                        <Typography className={classes.modalSubtitle}>
                            Are you sure you want to <b>{enable ? 'enable ' : 'disable '}</b> this user?
                        </Typography>
                    </Box>

                    <Box textAlign={'right'} marginTop={'60px'}>
                        <Button
                            id="cancel-delete-btn"
                            variant="contained"
                            size={'small'}
                            style={{ marginRight: '15px' }}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>

                        <Button
                            id="delete-btn"
                            variant="contained"
                            color="primary"
                            size={'small'}
                            className={classes.primaryButton}
                            onClick={() => onConfirm()}
                        >
                            {isLoading && <CircularProgress className="circular-progress sm" />}
                            {!isLoading && (
                                <SaveIcon
                                    style={{
                                        width: 20,
                                        marginRight: 5,
                                    }}
                                />
                            )}
                            {enable ? 'Enable' : 'Disable'}
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    );
};
