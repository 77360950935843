import React from "react";
import moment from "moment";

import { footerStyles } from "./styles";
import { Paper, Grid, Hidden, Typography, Box } from "@material-ui/core";

import { Logo } from "../..";
import { Navigation } from "./components/Navigation";

export const ConsumerFooter: React.FC = () => {

    const classes = footerStyles();

    return (
        <Box
            id="app-footer"
        >
            <Paper
                elevation={0}
                className={classes.paper}
            >
                <Grid container>
                    <Hidden smDown>
                        <Grid item xs={12} sm={12} md={2}>
                            <Logo />
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={12} md={8}>
                        <Navigation />
                    </Grid>
                    <Hidden smDown>
                        <Grid item md={2} />
                    </Hidden>
                </Grid>
            </Paper>
            <Paper
                elevation={0}
                className={classes.copyrightPaper}
            >
                <Typography className={classes.copyrightText}>
                    © {moment().format('YYYY')}  - Artifact
                </Typography>
            </Paper>
        </Box>
    )
}



