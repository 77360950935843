export enum AfterRegisterAction {
    DisplayConfirmEmailModal,
    DisplayWaitForActivationMessage,
    ContinueToLogin,
}

export enum AfterLoginAction {
    DisplayBlockedPlatformMessage,
    AskForRegisterReason,
    GoToApp,
}
