import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

import avatarPlaceholder from "assets/placeholders/avatar-placeholder.png";
import { getPhotoUrl } from "core/helpers/file-size.helper";
import { FileSize } from "core/enums";

type ExpertDetailsProps = {
    url: string;
    name: string;
    title: string;
    id: number;
}

const useStyles = makeStyles( theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 12,
    },
    avatar: {
        width: 40,
        height: 40,
        borderRadius: 40,
        marginRight: 12,
    },
    name: {
        color: theme.palette.text.primary,
        fontSize: 16,
        fontWeight: 'bold',
    },
    title: {
        color: '#707070',
        fontSize: 12,
    }
}))

export const ExpertDetails: React.FC<ExpertDetailsProps> = ({url, name, title, id}) => {

    const classes = useStyles();

    return (
        <Box className={classes.root} id={id.toString()}>
            <img src={getPhotoUrl(url, FileSize.XS) || avatarPlaceholder} alt="user-avatar" className={classes.avatar}/>
            <div>
                <Typography className={classes.name}>
                    {name}
                </Typography>
                <Typography className={classes.title}>
                    {title}
                </Typography>
            </div>
        </Box>
    )
}
