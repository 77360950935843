import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {Box, Container} from "@material-ui/core";

import {rootStyles} from "./styles/root.style";

import Pagination from "@material-ui/lab/Pagination";

import {AppRoutes} from "core/helpers";
import {ICommunityFeedArtifact} from "./types";
import {getCurrentPageFromUrl} from "core/helpers/utils";
import {useFeedData} from "./hooks/use-feed-data.hook";

import {EmptyFeed} from "./components/EmptyFeed";
import {ArtifactCard} from "./components/ArtifactCard";
import {Statistics} from "../_components/Statistics";

export const CommunityFeed: React.FC = () => {

    const classes = rootStyles();

    const history = useHistory();

    const {feedData, getFeedData, pagination} = useFeedData();

    useEffect(() => {
        const page = getCurrentPageFromUrl();
        getFeedData(page, 50);
    }, [])

    const handlePageChange = (page: number): void => {
        history.push(`${AppRoutes.PUBLIC_FEED}?page=${page}`);
        getFeedData(page, 50);
    }

    return (
        <Container maxWidth={false} className={classes.root}>
            {
                feedData?.length > 0 &&
                    <Box>
                        <Box className={classes.statistics}>

                            <Statistics
                                statistics={[{label: 'Total Objects', value: `${pagination.totalCount}`}]}
                            />

                            {
                                pagination.numberOfPages > 1 &&
                                    <Box className={classes.pagination}>
                                        <Pagination
                                            count={pagination.numberOfPages}
                                            page={pagination.page}
                                            siblingCount={0}
                                            shape="rounded"
                                            style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 10}}
                                            onChange={(_e: any, value: number) => handlePageChange(value)}
                                        />
                                    </Box>
                            }
                        </Box>

                        <Box marginTop={3} className={classes.feed}>
                            {
                                feedData.map((artifact: ICommunityFeedArtifact) => (
                                    <ArtifactCard
                                        key={artifact.id}
                                        artifact={artifact}
                                    />
                                ))
                            }
                        </Box>
                    </Box>
            }

            {
                feedData?.length === 0 &&
                <EmptyFeed />
            }
        </Container>
    )
}
