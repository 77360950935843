import React, { useState } from "react";
import { modalStyles } from "components/_others/modals/modal-styles";
import {
    Backdrop,
    Box, Button,
    Checkbox, CircularProgress,
    Fade,
    FormControlLabel,
    Modal, TextField,
    Typography
} from "@material-ui/core";

import registerReasonImg from "assets/images/register-reason.png";
import {CollectorApi} from "../../../core/api/consumer";
import {ModalHeader} from "../../../components/_others/modals/ModalHeader";

type RegisterReasonModalProps = {
    open: boolean;
    handleClose: () => void;
}

export const RegisterReasonModal: React.FC<RegisterReasonModalProps> = ({open, handleClose}) => {

    const classes = modalStyles();

    const [answer, setAnswer] = useState({
        portfolio: false,
        feedback: false,
        other: false,
        otherText: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = (): void => {
        setIsLoading(true);
        const registerPurpose = [];
        if(answer.portfolio) {
            registerPurpose.push("You want to use Artifact portfolio tools to manage your collection");
        }
        if(answer.feedback) {
            registerPurpose.push("Would like expert feedback on your collection");
        }
        if(answer.other && answer.otherText) {
            registerPurpose.push("Other")
            registerPurpose.push(answer.otherText.replaceAll(";",','));
        }

        CollectorApi.updateRegisterPurpose(registerPurpose)
            .then(() => {
                setIsLoading(false);
                handleClose();
            })
            .catch((error) => {
                console.error(error.response);
                setIsLoading(false);
                handleClose();
            })
    }

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>

                        <ModalHeader
                            title={'Why are you registering?'}
                            handleClose={() => handleClose()}
                        />

                        <Box textAlign={'center'} marginTop={'20px'} marginBottom={'20px'}>
                            <img
                                src={registerReasonImg}
                                alt="register-reason"
                                style={{width: '40%'}}
                            />
                        </Box>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="checkedB"
                                    color="primary"
                                    checked={answer.portfolio}
                                    onChange={() => setAnswer({...answer, portfolio: !answer.portfolio})}
                                />
                            }
                            label={
                                <Typography className="checkbox-label" color="textPrimary">
                                    You want to use Artifact portfolio tools to manage your collection
                                </Typography>
                            }
                            style={{marginBottom: '15px'}}
                        />
                        <br />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="checkedB"
                                    color="primary"
                                    checked={answer.feedback}
                                    onChange={() => setAnswer({...answer, feedback: !answer.feedback})}
                                />
                            }
                            label={
                                <Typography className="checkbox-label" color="textPrimary">
                                    Would like expert feedback on your collection
                                </Typography>
                            }
                            style={{marginBottom: '8px'}}
                        />
                        <br />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="checkedB"
                                    color="primary"
                                    checked={answer.other}
                                    onChange={() => setAnswer({...answer, other: !answer.other})}
                                />
                            }
                            label={
                                <Typography className="checkbox-label" color="textPrimary">
                                    Other
                                </Typography>
                            }
                        />

                        {
                            answer.other &&
                            <>
                                <TextField
                                    multiline
                                    rows={2}
                                    rowsMax={5}
                                    type="text"
                                    id="another-reason"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{ maxLength: 500 }}
                                    value={answer.otherText}
                                    onChange={(e: any) => setAnswer({...answer, otherText: e.target.value})}
                                />
                            </>
                        }

                        <Box textAlign={'right'} marginTop={'10px'}>
                            <Button
                                id="submit-btn"
                                variant="contained"
                                color="primary"
                                size={"small"}
                                className={classes.primaryButton}
                                onClick={() => onSubmit()}
                            >
                                {
                                    isLoading && <CircularProgress className="circular-progress sm" />
                                }
                                Save & Continue
                            </Button>
                        </Box>

                    </div>
                </Fade>
            </Modal>
        </>
    )
}
