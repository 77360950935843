import React, {useEffect, useState} from "react";
import { modalStyles } from "components/_others/modals/modal-styles";
import { Backdrop, Box, Button, CircularProgress, Fade, Modal, Typography } from "@material-ui/core";

import confirmEmailImg from "assets/images/confirm-email.svg";
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

import { ConfirmationPin } from "scenes/auth/components/ConfirmationPin/ConfirmationPin";

import { UserApi } from "core/api/user";
import {ModalHeader} from "../../../../components/_others/modals/ModalHeader";

type ConfirmEmailModalProps = {
    credentials: {email: string, password: string};
    message: string;
    open: boolean;
    handleClose: () => void;
    onSuccess: () => void;
}

export const ConfirmEmailModal: React.FC<ConfirmEmailModalProps> = (
    {credentials, message, open, handleClose, onSuccess}
) => {

    const classes = modalStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [displaySuccessIcon, setDisplaySuccessIcon] = useState(false);
    const [unknownError, setUnknownError] = useState('');

    useEffect(() => {
        setUnknownError('');
    }, [])

    const resendConfEmail = (): void => {

        setIsLoading(true);
        setDisplaySuccessIcon(false);
        setUnknownError('');

        UserApi.resendRegisterConfirmationEmail(credentials.email)
            .then(() => {
                setIsLoading(false);
                setDisplaySuccessIcon(true);
            })
            .catch((error: any) => {
                setIsLoading(false);
                console.error(error);
                setUnknownError('Sorry, there was an error. Please try again later.');
            })
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>

                    <ModalHeader
                        title={'Confirm your email'}
                        handleClose={() => handleClose()}
                    />

                    {
                        unknownError &&
                        <Typography className="error-message" color="error">
                            {unknownError}
                        </Typography>
                    }

                    <Typography
                        className={classes.modalSubtitle}
                        style={!unknownError ? {textAlign: 'center'} : {}}
                    >
                        {message}
                    </Typography>

                    <ConfirmationPin
                        email={credentials.email}
                        setError={(error: string) => setUnknownError(error)}
                        onSuccess={() => onSuccess ? onSuccess() : {}}
                    />

                    <Box textAlign={'center'} marginTop={'20px'}>
                        <img src={confirmEmailImg} alt="confirm-email" />
                    </Box>

                    <Box textAlign={'right'} marginTop={'60px'}>
                        <Button
                            id="resend-conf-email-btn"
                            variant="contained"
                            size={"small"}
                            style={{marginRight: '15px'}}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>

                        <Button
                            id="resend-conf-email-btn"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            className={classes.primaryButton}
                            onClick={() => resendConfEmail()}
                        >
                            {
                                isLoading && <CircularProgress className="circular-progress sm" />
                            }
                            {
                                displaySuccessIcon && <DoneIcon />
                            }
                            Resend Email
                        </Button>
                    </Box>

                </div>
            </Fade>
        </Modal>
    )
}
