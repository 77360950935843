export enum GalleryTypeEnum {
    Artifacts,
    Collections,
    SharedWithMe,
    Collectors,
    Experts,
    Archivists,
    Curators,
    CollectionsSharedWithMe,
}
