import {makeStyles} from "@material-ui/core/styles";

export const responsiveStyles = makeStyles({
    hideMobile: {
        ["@media (max-width:1000px)"]: { display: 'none !important' },
    },
    justOnMobile: {
        display: 'none',
        ["@media (max-width:1000px)"]: { display: 'inline' },
    },
})
