import { IAction } from "../interfaces";
import {ISearchResultsState} from "./search-results.interfaces";

export default (state: ISearchResultsState, action: IAction): ISearchResultsState => {
    switch(action.type) {
        case 'SET_ARTIFACTS_RESULT': {
            return {
                ...state,
                artifacts: action.payload?.data || [],
                paginationArtifacts: {
                    page: state.paginationArtifacts.page,
                    size: state.paginationArtifacts.size,
                    totalCount: action.payload?.totalCount,
                    numberOfPages: Math.ceil(action.payload.totalCount/state.paginationArtifacts.size),

                },
            }
        }
        case 'SET_COLLECTIONS_RESULT': {
            return {
                ...state,
                collections: action.payload?.data || [],
                paginationCollections: {
                    page: state.paginationCollections.page,
                    size: state.paginationCollections.size,
                    totalCount: action.payload?.totalCount,
                    numberOfPages: Math.ceil(action.payload.totalCount/state.paginationCollections.size),
                },
            }
        }
        case 'SET_SEARCH_BY': {
            return {
                ...state,
                searchBy: action.payload
            }
        }
        case 'CLEAR_SEARCH_RESULTS': {
            return {
                ...state,
                artifacts: [],
                collections: [],
            }
        }
        case 'SET_CURRENT_PAGE': {
            if(action.payload.scope === 'artifacts') {
                return {
                    ...state,
                    paginationArtifacts: {
                        ...state.paginationArtifacts,
                        page: action.payload.page
                    }
                }
            }

            if(action.payload.scope === 'collections') {
                return {
                    ...state,
                    paginationCollections: {
                        ...state.paginationCollections,
                        page: action.payload.page
                    }
                }
            }

            return state;
        }
        default:
            return state;
    }
}
