import { Box, Button, CircularProgress, Fade, Modal, Typography } from '@material-ui/core';
import { modalStyles } from 'components/_others/modals/modal-styles';
import { ModalHeader } from 'components/_others/modals/ModalHeader';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { AdminUsersApi } from 'core/api/admin-users.api';

interface ConfirmUserEmailModalProps {
    onClose: () => void;
    isOpen: boolean;
    name: string;
    userId: number;
    onSuccess: () => void;
}

export const ConfirmUserEmailModal: React.FC<ConfirmUserEmailModalProps> = ({
    onClose,
    isOpen,
    name,
    userId,
    onSuccess,
}) => {
    const classes = modalStyles();
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const generateErrorText = (message: string): string => {
        return message;
    };

    const onFinish = (): void => {
        setLoading(true);
        setError('');
        AdminUsersApi.confirmUserEmail(userId)
            .then(() => {
                setLoading(false);
                setSuccess(true);
                onSuccess();
            })
            .catch((error: any) => {
                setLoading(false);
                console.error(error.response);
                setError(
                    error.response?.data?.message
                        ? generateErrorText(error.response?.data?.message)
                        : 'Sorry, there was an error. Please try again later.',
                );
            });
    };

    const handleClose = (): void => {
        onClose();
    };

    return (
        <Modal
            open={isOpen}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            onClose={handleClose}
            className={classes.modal}
        >
            <Fade in={isOpen}>
                <div className={classes.paper}>
                    <ModalHeader title={'Re-send registration E-mail'} handleClose={onClose} />

                    {!!error && (
                        <Typography className="error-message" color="error">
                            {error}
                        </Typography>
                    )}

                    {success ? (
                        <Typography className={classes.modalSubtitle}>
                            {name}'s status set to confirmed Email.
                        </Typography>
                    ) : (
                        <Box>
                            <Typography className={classes.modalSubtitle}>
                                {`Are you sure you want to change ${name}'s email status to confirmed?`}
                            </Typography>
                        </Box>
                    )}
                    <Box textAlign={'right'} marginTop={'60px'}>
                        <Button
                            variant="contained"
                            size={'small'}
                            style={{ marginRight: success ? '0px' : '15px' }}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> {success ? 'Close' : 'Cancel'}
                        </Button>

                        <Button
                            disabled={success}
                            variant="contained"
                            color="primary"
                            size={'small'}
                            className={classes.primaryButton}
                            onClick={onFinish}
                            style={{ marginLeft: '8px' }}
                        >
                            {loading && <CircularProgress className="circular-progress sm" />}
                            {success && <DoneIcon />}
                            Confirm Email
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    );
};
