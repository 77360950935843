import React, {useContext} from "react";
import AppleSignin from 'react-apple-signin-auth';
import {RegisterTypesEnum} from "core/enums";
import appleIcon from "assets/images/apple-icon.png";
import {Button} from "@material-ui/core";
import {UtilsContext} from "core/context/utils.context";

type AppleAuthProps = {
    acceptTerms?: boolean;
    onSuccess: (data: any) => void;
}

export const AppleAuth: React.FC<AppleAuthProps> = ({onSuccess, acceptTerms}) => {

    const utilsContext = useContext(UtilsContext);

    const onSubmit = (data: any): void => {

        if(acceptTerms === false) {
            utilsContext.addSnackbar('error', 'Please accept the terms and conditions');
            return;
        }

        const payload = {
            firstName: data.user?.name.firstName || '',
            lastName: data.user?.name.lastName || '',
            email: data.user?.email || '',
            token: data.authorization.id_token,
            termsAccepted: true,
            registerType: RegisterTypesEnum.Apple,

        }
        onSuccess(payload);
    }

    const onError = (data: any): void => {
        console.error(data);
    }

    return (
        <>
            <AppleSignin
                authOptions={{
                    clientId: 'id.dev.artifact.service',
                    scope: 'email name',
                    redirectURI: 'https://artifact.id',
                    state: 'origin:web',
                    usePopup : true,
                }}
                uiType="dark"
                className="apple-auth-btn"
                noDefaultStyle={false}
                onSuccess={(response: any) => onSubmit(response)}
                onError={(resp: any) => onError(resp)}
                iconprop={{ style: { marginTop: '10px' } }}
                render={(props: any) => (
                    <Button
                        {...props}
                        id="continue-with-apple-btn"
                        variant="contained"
                        className="external-auth-btn"
                    >
                        <img src={appleIcon} alt="apple-icon" />
                    </Button>
                )}
            />
        </>

    )
}
