import React, { useContext } from 'react';
import { Box, Grid, Hidden } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { BackNavigation } from 'components/_others/BackNavigation';
import { ButtonGrow } from 'components/_others/ButtonGrow';

import { formatUSD } from 'core/helpers/format-currency';
import { CollectionContext } from 'core/context/consumer/collection';
import EditIcon from '@material-ui/icons/Edit';
import { EditCollectionNameModal } from './EditCollectionNameModal';
import { fixedHeaderStyles } from '../../../../assets/styles/fixed-header';
import { SortBy } from '../../../../components/_others/SortBy';
import { getSortingOptions } from '../../_helpers/objects-sorting.helper';
import { Statistics } from '../../_components/Statistics';
import { RemoveCollectionModal } from '../../_components/RemoveCollectionModal';
import { UserContext } from '../../../../core/context/user';
import { UserRoles } from '../../../../core/enums';

type CollectionPageHeader = {
    isSharedWithMe: boolean;
    changeSortByOption: (option: string) => void;
    sortByValue: string;
};

export const CollectionPageHeader: React.FC<CollectionPageHeader> = ({
    changeSortByOption,
    isSharedWithMe,
    sortByValue,
}) => {
    const collectionContext = useContext(CollectionContext);
    const userContext = useContext(UserContext);
    const fixedHeaderCss = fixedHeaderStyles();

    return (
        <Box className={`${fixedHeaderCss.root} header-fixed`}>
            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={12} sm={12} md={7}>
                    <Box display={'flex'}>
                        <BackNavigation />
                        <Box marginLeft={'30px'}>
                            <Statistics
                                statistics={[
                                    {
                                        label: collectionContext.name,
                                        value: `${collectionContext.statistics.totalObjects}`,
                                    },
                                    {
                                        label: 'Total Est. Value',
                                        value: formatUSD(collectionContext.statistics.totalValue),
                                    },
                                ]}
                            />
                        </Box>
                    </Box>
                </Grid>
                {!isSharedWithMe && (
                    <Grid item xs={12} sm={12} md={5}>
                        <Box
                            className="actions"
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'flex-end'}
                            margin={'0'}
                            padding={'0'}
                        >
                            <Hidden smDown>
                                <span style={{ marginRight: 15 }}>
                                    <SortBy
                                        options={getSortingOptions(
                                            collectionContext.pagination.sortBy || '',
                                            collectionContext.pagination.asc || false,
                                        )}
                                        onSelect={(option: string) => changeSortByOption(option)}
                                        sortByValue={sortByValue}
                                    />
                                </span>
                            </Hidden>

                            {(userContext.user?.role === UserRoles.CURATOR ||
                                userContext.user?.role === UserRoles.COLLECTOR) && (
                                <>
                                    <ButtonGrow
                                        icon={<AddIcon className="save-icon" />}
                                        text={'Add Object'}
                                        id={'add-object-to-collection-btn'}
                                        highlightOnHover={true}
                                        onClick={() => collectionContext.modals.toggleAddObjectModal()}
                                    />
                                    <ButtonGrow
                                        icon={<EditIcon className="edit-icon" />}
                                        text={'Edit Name'}
                                        id={'edit-object-btn'}
                                        onClick={() => collectionContext.modals.toggleEditCollectionNameModal(true)}
                                    />
                                    <ButtonGrow
                                        icon={<DeleteIcon className="delete-icon" />}
                                        text={'Delete Collection'}
                                        id={'delete-collection-btn'}
                                        onClick={() => collectionContext.modals.toggleDeleteCollectionModal()}
                                    />
                                </>
                            )}
                        </Box>
                    </Grid>
                )}
            </Grid>

            <EditCollectionNameModal
                open={collectionContext.modals.displayEditCollectionNameModal}
                name={collectionContext.name}
                id={collectionContext.id}
                handleClose={() => collectionContext.modals.toggleEditCollectionNameModal(false)}
            />

            <RemoveCollectionModal
                collectionId={collectionContext.id}
                open={collectionContext.modals.displayDeleteCollectionModal}
                handleClose={() => collectionContext.modals.toggleDeleteCollectionModal()}
            />
        </Box>
    );
};
