import React, {useContext, useState} from "react";
import { DragDropContext } from 'react-beautiful-dnd';

import { Box, Typography } from "@material-ui/core";

import InfoIcon from '@material-ui/icons/Info';

import { ITaxonomyLabel, TaxonomyContext } from "core/context/admin/taxonomy";
import { TaxonomyApi } from "core/api/admin";

import { Label } from "./Label";
import { SubTypes } from "./SubTypes";
import {UtilsContext} from "../../../../../../core/context/utils.context";

export const RootTypes: React.FC = () => {

    const taxonomyContext = useContext(TaxonomyContext);
    const [subTypes, setSubtypes] = useState<ITaxonomyLabel[]>([]);
    const utilsContext = useContext(UtilsContext);
    const onRootTypeClick = (rootType: ITaxonomyLabel|null): void => {
        if(!rootType) {
            return;
        }
        taxonomyContext.selectRootType(rootType);
        getTypes(rootType, true);
    }

    const getTypes = (type: ITaxonomyLabel, isRootType = false): void => {
        TaxonomyApi.getRootTypes(type.id)
            .then((res: any) => {

                const list = res.data;
                list.forEach((element: any) => {
                    element.parentId = type.id;
                })

                if(isRootType) {
                    setSubtypes(list);
                } else {
                    const result = subTypes.filter(l => l.parentId !== type.id) || [];
                    setSubtypes(result.concat(list));
                }

            })
            .catch((error: any) => {
                console.error(error.response);
                //TODO: manage error
            })
    }

    const onDragEnd = (result: any): void => {
        const { destination, source } = result;
        if (!destination) {
            return;
        }
        
        if(source.droppableId !== destination.droppableId) {
            const el = subTypes.filter(s => s.parentId === Number(source.droppableId))[source.index];
            if(el) {
                el.parentId = Number(destination.droppableId);
                setSubtypes(subTypes.slice());
                updateParent(el);
            }
        }
    }

    const updateParent = (label: ITaxonomyLabel): void => {
        TaxonomyApi.updateLabel(label.id, label.name, label.parentId, label.isActive)
            .then(() => {
                utilsContext.addSnackbar('success',  'The label was moved.');
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error');
            })
    }

    return (
        <Box>
            <Box display="flex">
                <Typography color="textPrimary" style={{fontSize: 18}}>
                    Root Types
                </Typography>
                <Typography
                    color="textSecondary"
                    style={{fontSize: 13, marginLeft: 15, display: 'flex', alignItems: 'center'}}
                >
                    <InfoIcon style={{width: 15, marginRight: 5}} />
                    You can select a label to see all taxonomies that in that category.
                </Typography>
            </Box>

            <Box marginTop={'10px'}>
                {
                    taxonomyContext.rootTypes.map((label: ITaxonomyLabel) => (
                    <Label
                        key={label.id}
                        label={label}
                        isSelected={taxonomyContext.selectedRootType?.id === label.id}
                        hasActions={false}
                        onClick={() => onRootTypeClick(label)}
                    />
                ))}
            </Box>

            {
                taxonomyContext.selectedRootType && subTypes && subTypes.length > 0 &&
                    <Box>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <SubTypes
                                id={taxonomyContext.selectedRootType?.id || -1}
                                parent={taxonomyContext.selectedRootType}
                                types={subTypes}
                                onRefresh={() => onRootTypeClick(taxonomyContext.selectedRootType)}
                                onTypeClick={(type) => getTypes(type)}
                            />
                        </DragDropContext>
                    </Box>
            }
        </Box>
    )
}
