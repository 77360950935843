import React, { useContext, useEffect, useState } from 'react';
import { Backdrop, Box, Button, CircularProgress, Fade, Modal, TextField, Typography } from '@material-ui/core';
import { modalStyles } from 'components/_others/modals/modal-styles';
import { ModalHeader } from '../../../../../components/_others/modals/ModalHeader';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import { UserApi } from '../../../../../core/api/user';
import PinInput from 'react-pin-input';
import { validateEmail } from '../../../../../core/helpers';
import { UserContext } from '../../../../../core/context/user';
import { UtilsContext } from '../../../../../core/context/utils.context';

type ForgetPasswordModalProps = {
    open: boolean;
    userEmail?: string;
    handleClose: () => void;
};

export const ConfirmEmailChangeModal: React.FC<ForgetPasswordModalProps> = ({ open, userEmail, handleClose }) => {
    const classes = modalStyles();
    const userContext = useContext(UserContext);

    const [view, setView] = useState('send-confirm-current-email');
    const [isLoading, setIsLoading] = useState(false);
    const utilsContext = useContext(UtilsContext);
    const [values, setValues] = useState({ pin: '', email: '' });
    const [errors, setErrors] = useState({ email: '' });

    useEffect(() => {
        if (!userEmail) {
            setView('setup-new-email');
        } else {
            setView('send-confirm-current-email');
        }
        setValues({ pin: '', email: '' });
    }, [open]);

    const confirmCurrentEmail = (): void => {
        if (!userEmail) {
            return;
        }

        setIsLoading(true);

        UserApi.sendConfirmChangeEmail(userEmail, true)
            .then(() => {
                setIsLoading(false);
                setView('confirm-current-email');
            })
            .catch((error: any) => {
                setIsLoading(false);
                console.error(error.response);
                const errorMessage = 'Sorry, there was an error and we were unable to send the confirmation email.';
                utilsContext.addSnackbar('error', errorMessage);
            });
    };

    const confirmNewEmail = (): void => {
        if (!validateEmail(values.email)) {
            return;
        }

        setIsLoading(true);

        UserApi.setNewEmail({ email: values.email, code: values.pin })
            .then(() => {
                setIsLoading(false);
                setView('finish');
                setTimeout(() => {
                    userContext.onLogout();
                    location.reload();
                }, 5000);
            })
            .catch((error: any) => {
                setIsLoading(false);
                console.error(error.response);
                let errorMessage = 'Sorry, there was an error and we were unable to send the confirmation email.';
                if (error.response?.data?.message === 'InvalidCode') {
                    errorMessage = 'Looks like the confirmation code is invalid.';
                }
                if (error.response?.data?.message === 'AlreadyExists') {
                    errorMessage = 'This email already exists.';
                }
                utilsContext.addSnackbar('error', errorMessage);
            });
    };

    const changeEmail = (): void => {
        if (values.pin?.length !== 6 || !validateEmail(values.email)) {
            return;
        }
        setIsLoading(true);

        UserApi.changeEmail({ email: values.email, code: values.pin })
            .then(() => {
                setValues({ ...values, pin: '' });
                setIsLoading(false);
                setView('confirm-new-email');
            })
            .catch((error: any) => {
                setIsLoading(false);
                console.error(error);
                let errorMessage = 'Sorry, there was an error and we were unable to send the confirmation email.';
                if (error.response?.data?.message === 'InvalidCode') {
                    errorMessage = 'Looks like the confirmation code is invalid.';
                }
                if (error.response?.data?.message === 'AlreadyExists') {
                    errorMessage = 'This email already exists.';
                }
                utilsContext.addSnackbar('error', errorMessage);
            });
    };

    const sendConfirmationOnNewEmail = (): void => {
        if (!validateEmail(values.email)) {
            return;
        }

        UserApi.sendConfirmChangeEmail(values.email, false)
            .then(() => {
                setIsLoading(false);
                setView('confirm-new-email');
            })
            .catch((error: any) => {
                setIsLoading(false);
                console.error(error.response);
                const errorMessage = 'Sorry, there was an error and we were unable to send the confirmation email.';
                utilsContext.addSnackbar('error', errorMessage);
            });
    };

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <ModalHeader title={'Change Email'} handleClose={() => handleClose()} />

                    {view === 'send-confirm-current-email' && (
                        <Typography className={classes.modalSubtitle}>
                            If you wish to change your email, please confirm your current address. <br />
                            <br />
                            We will send you an email at <b>{userEmail}</b>, with instructions about the next steps.
                        </Typography>
                    )}

                    {view === 'setup-new-email' && (
                        <>
                            <Typography className={classes.modalSubtitle}>
                                Please enter your new email below.
                            </Typography>

                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                id="email"
                                name="email"
                                label=""
                                autoComplete="new-password"
                                placeholder="New Email"
                                type="email"
                                inputProps={{ maxLength: 100 }}
                                error={errors.email.length !== 0}
                                helperText={errors.email}
                                value={values.email}
                                onChange={(e) => {
                                    setValues({ ...values, email: e.target.value });
                                    setErrors({ ...errors, email: '' });
                                }}
                            />
                        </>
                    )}

                    {view === 'confirm-current-email' && (
                        <>
                            <Typography className={classes.modalSubtitle}>
                                Please enter the confirmation code received, and your new email address.
                            </Typography>

                            <Box style={{ float: 'left', marginLeft: '-10px', marginTop: 15 }}>
                                <PinInput
                                    length={6}
                                    initialValue=""
                                    type="numeric"
                                    inputMode="number"
                                    style={{ padding: '10px' }}
                                    inputStyle={{ borderColor: 'red' }}
                                    inputFocusStyle={{ borderColor: 'blue' }}
                                    autoSelect={true}
                                    onComplete={(value) => setValues({ ...values, pin: value })}
                                    regexCriteria={/^[0-9]*$/}
                                />
                            </Box>

                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                id="email"
                                name="email"
                                label=""
                                autoComplete="new-password"
                                placeholder="New Email"
                                type="email"
                                inputProps={{ maxLength: 100 }}
                                error={errors.email.length !== 0}
                                helperText={errors.email}
                                value={values.email}
                                onChange={(e) => {
                                    setValues({ ...values, email: e.target.value });
                                    setErrors({ ...errors, email: '' });
                                }}
                            />
                        </>
                    )}

                    {view === 'confirm-new-email' && (
                        <>
                            <Typography className={classes.modalSubtitle}>
                                Please confirm your new email, by entering below the code you just received:
                            </Typography>

                            <Box style={{ float: 'left', marginLeft: '-10px', marginTop: 15 }}>
                                <PinInput
                                    length={6}
                                    initialValue=""
                                    type="numeric"
                                    inputMode="number"
                                    style={{ padding: '10px' }}
                                    inputStyle={{ borderColor: 'red' }}
                                    inputFocusStyle={{ borderColor: 'blue' }}
                                    autoSelect={true}
                                    onComplete={(value) => setValues({ ...values, pin: value })}
                                    regexCriteria={/^[0-9]*$/}
                                />
                            </Box>
                            <br />
                            <br />
                            <br />
                        </>
                    )}

                    {view === 'finish' && (
                        <Typography className={classes.modalSubtitle}>
                            Success! Your email was changed. Please log in again.
                        </Typography>
                    )}

                    {view !== 'finish' && (
                        <>
                            <Box textAlign={'right'} marginTop={'60px'}>
                                <Button
                                    id="cancel-delete-btn"
                                    variant="contained"
                                    size={'small'}
                                    style={{ marginRight: '15px' }}
                                    className={classes.secondaryButton}
                                    onClick={handleClose}
                                >
                                    <CloseIcon height={'20px'} /> Cancel
                                </Button>

                                <Button
                                    id="confirm-btn"
                                    variant="contained"
                                    color="primary"
                                    size={'small'}
                                    className={classes.primaryButton}
                                    disabled={
                                        view === 'confirm-current-email'
                                            ? values.pin?.length !== 6 || !validateEmail(values.email)
                                            : view === 'confirm-new-email'
                                            ? values.pin?.length !== 6
                                            : false
                                    }
                                    onClick={() => {
                                        if (view === 'send-confirm-current-email') {
                                            confirmCurrentEmail();
                                        }
                                        if (view === 'confirm-current-email') {
                                            changeEmail();
                                        }
                                        if (view === 'confirm-new-email') {
                                            confirmNewEmail();
                                        }
                                        if (view === 'setup-new-email') {
                                            sendConfirmationOnNewEmail();
                                        }
                                    }}
                                >
                                    {isLoading && <CircularProgress className="circular-progress sm" />}
                                    {!isLoading && (
                                        <EmailIcon
                                            style={{
                                                width: 20,
                                                marginRight: 5,
                                            }}
                                        />
                                    )}
                                    {view === 'send-confirm-current-email' && 'Send confirmation email'}
                                    {(view === 'confirm-current-email' || view === 'setup-new-email') && 'Change Email'}
                                    {view === 'confirm-new-email' && 'Confirm Email'}
                                </Button>
                            </Box>
                        </>
                    )}
                </div>
            </Fade>
        </Modal>
    );
};
