import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, ThemeOptions, Typography } from "@material-ui/core";

import { ICategory } from "core/context/consumer/objectDetails";
import { AddNewLabel } from "./AddNewLabel";

import CloseIcon from "@material-ui/icons/Close";
import {ObjectApi} from "../../../../../../../core/api";

type LabelsProps = {
    disabled: boolean;
    forAdmins: boolean;
    artifactId: number;
    categories: ICategory[];
    materials: ICategory[];
    styles: ICategory[];
    origins: ICategory[];
    conditions: ICategory[];
    setNewCategories: (c: ICategory[]) => void;
    setNewMaterials: (c: ICategory[]) => void;
    setNewStyles: (c: ICategory[]) => void;
    setNewOrigins: (c: ICategory[]) => void;
    setNewConditions: (c: ICategory[]) => void;
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
    subtitle: {
        fontSize: 14,
        marginBottom: 10,
    },
    labelSectionName: {
        fontSize: 14,
        marginRight: 10,
    },
    noLabelsText: {
        fontSize: 13,
    },
    labelsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: 10,
    },
    label: {
        fontSize: 13,
        backgroundColor: theme.label.main,
        color: theme.label.text,
        padding: '3px 7px',
        marginRight: 7,
        borderRadius: 2,
        marginTop: 5,
        display: 'flex',
        alignItems: 'center'
    },
    closeIcon: {
        color: theme.label.text,
        width: 14,
        height: 14,
        marginLeft: 7,
        marginTop: 2,
        cursor: 'pointer'
    }
}));

export const AdminAssignLabels: React.FC<LabelsProps> = (
    {
        disabled, forAdmins, artifactId, categories, materials, styles, origins, conditions,
        setNewCategories, setNewMaterials, setNewStyles, setNewOrigins, setNewConditions
    }
) => {

    const classes = useStyles();

    const handleNewLabels = (labels: ICategory[], type: string): void => {

        if(forAdmins) {
            ObjectApi.updateArtifactLabel(artifactId, labels[0].id)
                .then(() => {
                    console.log('res');
                })
                .catch((error: any) => {
                    console.error(error);
                })
        }

        if(type === 'Category') {
            let newCategories = categories;
            newCategories = newCategories.concat(labels);
            setNewCategories(newCategories.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.name === thing.name && t.name === thing.name
                ))
            ).slice());
            return;
        }

        if(type === 'Material') {
            let newCategories = materials;
            newCategories = newCategories.concat(labels);
            setNewMaterials(newCategories.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.name === thing.name && t.name === thing.name
                ))
            ).slice());
            return;
        }

        if(type === 'Style') {
            let newCategories = styles;
            newCategories = newCategories.concat(labels);
            setNewStyles(newCategories.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.name === thing.name && t.name === thing.name
                ))
            ).slice());
            return;
        }

        if(type === 'Origin') {
            let newCategories = origins;
            newCategories = newCategories.concat(labels);
            setNewOrigins(newCategories.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.name === thing.name && t.name === thing.name
                ))
            ).slice());
            return;
        }

        if(type === 'Condition') {
            let newCategories = conditions;
            newCategories = newCategories.concat(labels);
            setNewConditions(newCategories.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.name === thing.name && t.name === thing.name
                ))
            ).slice());
        }
    }

    const handleDeleteLabel = (name: string, id: number, type: string): void => {

        if(forAdmins) {
            if(forAdmins) {
                ObjectApi.updateArtifactLabel(artifactId, id)
                    .then(() => {
                        console.log('res');
                    })
                    .catch((error: any) => {
                        console.error(error);
                    })
            }
        }

        if(type === 'Category') {
            const newCategories = categories;
            const index = newCategories.findIndex(el => el.name === name);
            newCategories.splice(index, 1);
            setNewCategories(newCategories.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.name === thing.name && t.name === thing.name
                ))
            ).slice());
            return;
        }

        if(type === 'Material') {
            const newCategories = materials;
            const index = newCategories.findIndex(el => el.name === name);
            newCategories.splice(index, 1);
            setNewMaterials(newCategories.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.name === thing.name && t.name === thing.name
                ))
            ).slice());
            return;
        }

        if(type === 'Style') {
            const newCategories = styles;
            const index = newCategories.findIndex(el => el.name === name);
            newCategories.splice(index, 1);
            setNewStyles(newCategories.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.name === thing.name && t.name === thing.name
                ))
            ).slice());
            return;
        }

        if(type === 'Origin') {
            const newCategories = origins;
            const index = newCategories.findIndex(el => el.name === name);
            newCategories.splice(index, 1);
            setNewOrigins(newCategories.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.name === thing.name && t.name === thing.name
                ))
            ).slice());
            return;
        }

        if(type === 'Condition') {
            const newCategories = conditions;
            const index = newCategories.findIndex(el => el.name === name);
            newCategories.splice(index, 1);
            setNewConditions(newCategories.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.name === thing.name && t.name === thing.name
                ))
            ).slice());
        }
    }

    return (
        <Box>
            {
                !forAdmins &&
                <Typography className={classes.subtitle} color={'textPrimary'}>
                    The following labels were generated based on your feedback.
                </Typography>
            }

            <>
                <Box display={'flex'} alignItems={'center'} marginBottom={'7px'}>
                    <Typography className={classes.labelSectionName} color={'textSecondary'}>
                        Category
                    </Typography>
                    {
                        !disabled && <AddNewLabel
                            isMulti={false}
                            disableCreate={true}
                            type={'Category'}
                            onSave={(labels) => handleNewLabels(labels, 'Category')}
                        />
                    }
                </Box>

                {
                    categories.length === 0 &&
                    <Typography className={classes.noLabelsText} color={'textSecondary'}>
                        No labels found
                    </Typography>
                }

                <Box className={classes.labelsContainer}>
                    {
                        categories.map(c => (
                            <span className={classes.label} key={c.id}>
                                {c.name}
                                {
                                    !disabled && <CloseIcon
                                        className={classes.closeIcon}
                                        onClick={() => handleDeleteLabel(c.name, c.id, 'Category')}
                                    />
                                }
                            </span>
                        ))
                    }
                </Box>
            </>

            <>
                <Box display={'flex'} alignItems={'center'} marginBottom={'7px'} marginTop={'20px'}>
                    <Typography className={classes.labelSectionName} color={'textSecondary'}>
                        Material
                    </Typography>
                    {
                        !disabled && <AddNewLabel
                            isMulti={false}
                            disableCreate={true}
                            type={'Material'}
                            onSave={(labels) => handleNewLabels(labels, 'Material')}
                        />
                    }
                </Box>
                {
                    materials.length === 0 &&
                    <Typography className={classes.noLabelsText} color={'textSecondary'}>
                        No labels found
                    </Typography>
                }
                <Box className={classes.labelsContainer}>
                    {
                        materials.map(c => (
                            <span className={classes.label} key={c.id}>
                                {c.name}
                                {
                                    !disabled && <CloseIcon
                                        className={classes.closeIcon}
                                        onClick={() => handleDeleteLabel(c.name,c.id, 'Material')}
                                    />
                                }
                            </span>
                        ))
                    }
                </Box>
            </>

            <>
                <Box display={'flex'} alignItems={'center'} marginBottom={'7px'} marginTop={'20px'}>
                    <Typography className={classes.labelSectionName} color={'textSecondary'}>
                        Style
                    </Typography>
                    {
                        !disabled && <AddNewLabel
                            isMulti={false}
                            disableCreate={true}
                            type={'Style'}
                            onSave={(labels) => handleNewLabels(labels, 'Style')}
                        />
                    }
                </Box>
                {
                    styles.length === 0 &&
                    <Typography className={classes.noLabelsText} color={'textSecondary'}>
                        No labels found
                    </Typography>
                }
                <Box className={classes.labelsContainer}>
                    {
                        styles.map(c => (
                            <span className={classes.label} key={c.id}>
                                {c.name}
                                {
                                    !disabled && <CloseIcon
                                        className={classes.closeIcon}
                                        onClick={() => handleDeleteLabel(c.name, c.id,'Style')}
                                    />
                                }
                            </span>
                        ))
                    }
                </Box>
            </>

            <>
                <Box display={'flex'} alignItems={'center'} marginBottom={'7px'} marginTop={'20px'}>
                    <Typography className={classes.labelSectionName} color={'textSecondary'}>
                        Origin
                    </Typography>
                    {
                        !disabled && <AddNewLabel
                            isMulti={false}
                            disableCreate={true}
                            type={'Origin'}
                            onSave={(labels) => handleNewLabels(labels, 'Origin')}
                        />
                    }
                </Box>
                {
                    origins.length === 0 &&
                    <Typography className={classes.noLabelsText} color={'textSecondary'}>
                        No labels found
                    </Typography>
                }
                <Box className={classes.labelsContainer}>
                    {
                        origins.map(c => (
                            <span className={classes.label} key={c.id}>
                                {c.name}
                                {
                                    !disabled && <CloseIcon
                                        className={classes.closeIcon}
                                        onClick={() => handleDeleteLabel(c.name, c.id,'Origin')}
                                    />
                                }
                            </span>
                        ))
                    }
                </Box>
            </>

            <>
                <Box display={'flex'} alignItems={'center'} marginBottom={'7px'} marginTop={'20px'}>
                    <Typography className={classes.labelSectionName} color={'textSecondary'}>
                        Condition
                    </Typography>
                    {
                        !disabled && <AddNewLabel
                            isMulti={false}
                            disableCreate={true}
                            type={'Condition'}
                            onSave={(labels) => handleNewLabels(labels, 'Condition')}
                        />
                    }
                </Box>
                {
                    conditions.length === 0 &&
                    <Typography className={classes.noLabelsText} color={'textSecondary'}>
                        No labels found
                    </Typography>
                }
                <Box className={classes.labelsContainer}>
                    {
                        conditions.map(c => (
                            <span className={classes.label} key={c.id}>
                                {c.name}
                                {
                                    !disabled && <CloseIcon
                                        className={classes.closeIcon}
                                        onClick={() => handleDeleteLabel(c.name, c.id, 'Condition')}
                                    />
                                }
                            </span>
                        ))
                    }
                </Box>
            </>
        </Box>
    )
}
