import { ICollectorArtifactState } from './collector-artifact.interfaces';
import { IAction } from 'core/context/interfaces';

export default (state: ICollectorArtifactState, action: IAction): ICollectorArtifactState => {
    switch (action.type) {
        case 'SET_ARTIFACT':
            return {
                ...state,
                artifact: {
                    id: action.payload.artifact.id,
                    title: action.payload.artifact.title,
                    description: action.payload.artifact.description,
                    exactValue: action.payload.artifact.exactValue,
                    minimumValue: action.payload.artifact.minimumValue,
                    maximumValue: action.payload.artifact.maximumValue,
                    width: action.payload.artifact.width,
                    height: action.payload.artifact.height,
                    depth: action.payload.artifact.depth,
                    weight: action.payload.artifact.weight,
                    exactProductionYear: action.payload.artifact.exactProductionYear,
                    lowerProductionYear: action.payload.artifact.lowerProductionYear,
                    upperProductionYear: action.payload.artifact.upperProductionYear,
                    origin: action.payload.artifact.origin,
                    material: action.payload.artifact.material,
                    style: action.payload.artifact.style,
                    artist: action.payload.artifact.artist,
                    artistBio: action.payload.artifact.artistBio,
                    condition: action.payload.artifact.condition,
                    createdDate: action.payload.artifact.dateCreated,
                    paymentFreePass: action.payload.artifact.paymentFreePass || false,
                    isFeedbackImported: action.payload.artifact.isFeedbackImported,
                    isSharedToPublicFeed: action.payload.artifact.isSharedToPublicFeed,
                    documents: action.payload.artifact.documents || [],
                    feedbackRequests: action.payload.artifact.feedbackRequests || [],
                    experts: action.payload.artifact.experts || [],
                    photos: action.payload.artifact.photos || [],
                    categories: action.payload.artifact.categories || [],
                    materials: action.payload.artifact.materials || [],
                    conditions: action.payload.artifact.conditions || [],
                    eras: action.payload.artifact.eras || [],
                    origins: action.payload.artifact.origins || [],
                    styles: action.payload.artifact.styles || [],
                    collections: action.payload.artifact.collections || [],
                    feedbacks: action.payload.artifact.feedbacks || [],
                    artistImageUrl: action.payload.artifact.artistImageUrl,
                },
            };
        case 'SET_LOADING_STATE':
            return {
                ...state,
                isLoading: action.payload,
            };
        default:
            return state;
    }
};
