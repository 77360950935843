import React, {useContext, useEffect, useState} from "react";

import { TextField, Button, CircularProgress } from "@material-ui/core";
import { PasswordFormControl } from "components/_others/PasswordFormControl";
import { ForgotPassword } from "scenes/auth/components/ForgotPassword/ForgotPassword";
import { UserContext } from "core/context/user";

type SignInFormProps = {
    isLoading: boolean;
    onLoginUser: (userData: any) => void
}

export const SignInForm: React.FC<SignInFormProps> = ({isLoading, onLoginUser}) => {

    const userContext = useContext(UserContext);

    const [onFocus, setOnFocus] = useState({
        email: false,
        password: false,
    });

    const [values, setValues] = useState({
        email: '',
        password: '',
    });

    const [errors, setErrors] = useState({
        email: '',
        password: ''
    });

    useEffect(() => {
        userContext.setTheme(false, false);
    }, [])

    const onSignIn = ():void => {
        setFormErrors();
        if(!isValidForm()) {
            return;
        }
        onLoginUser(values);
    }

    const setFormErrors = (): void => {
        setErrors({
            email: !onFocus.email && !values.email.length
                ? 'Please enter your email.'
                : '',
            password: !onFocus.password && !values.password.length
                ? 'Please enter your password.'
                : ''
        });
    }

    const isValidForm = (): boolean => {
        return values.email?.length > 0 && values.password?.length > 0;
    }

    const handleKeyPress = (e: any): void => {
        if(e.key === 'Enter') {
            onSignIn();
            e.preventDefault();
        }
    }

    return (
        <form id="sign-in-form" className="auth-form" noValidate>
            <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                id="email"
                name="email"
                label="Email"
                type="email"
                autoComplete="new-password"
                placeholder="Email"
                inputProps={{ maxLength: 100 }}
                error={errors.email.length !== 0}
                helperText={errors.email}
                value={values.email}
                onChange={(e) => {
                    setValues({...values, email: e.target.value});
                    setErrors({...errors, email: ''})
                }}
                onFocus={() => setOnFocus({...onFocus, email: true})}
                onBlur={() => setOnFocus({...onFocus, email: false})}
                onKeyPress={(e: any) => handleKeyPress(e)}
            />

            <PasswordFormControl
                value={values.password}
                className={onFocus.password || values.password?.length ? 'mt-30' : ''}
                error={errors.password.length !== 0}
                helperText={errors.password}
                onChange={(e) => {
                    setValues({...values, password: e.target.value});
                    setErrors({...errors, password: ''})
                }}
                onFocus={() => setOnFocus({...onFocus, password: true})}
                onBlur={() => setOnFocus({...onFocus, password: false})}
                onKeyPress={(key: string) => handleKeyPress(key)}
            />

            <ForgotPassword />

            <Button
                fullWidth
                id="sign-in-btn"
                variant="contained"
                color="primary"
                type="button"
                className="auth-button"
                onClick={() => onSignIn()}
                onKeyPress={(e: any) => handleKeyPress(e.key)}
            >
                {
                    isLoading && <CircularProgress className="circular-progress" />
                }
                Sign In
            </Button>
        </form>
    )
}
