import React, {useState} from "react";
import moment from "moment";
import {Box, TextField, Typography} from "@material-ui/core";

import {commentsStyles} from "../../styles/comments.styles";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import SaveIcon from "@material-ui/icons/Save";

import {ConfirmDeleteModal} from "components/_others";
import {IObjectComment} from "types";

type CommentDetailsProps = {
    comment: IObjectComment;
    isAllowedToDelete: boolean;
    isAllowedToEdit: boolean;
    onDeleteComment: (commentId: number) => Promise<boolean>;
    onEditComment: (commentId: number, comment: string) => Promise<boolean>;
}

export const CommentDetails: React.FC<CommentDetailsProps> = (
    {isAllowedToDelete, isAllowedToEdit, comment, onDeleteComment, onEditComment}
) => {

    const classes = commentsStyles();

    const [displayFullComment, toggleFullComment] = useState(false);
    const [displayConfirmDelete, toggleConfirmDelete] = useState(false);
    const [commentText, setCommentText] = useState(comment.text);
    const [onEditMode, setOnEditMode] = useState(false);

    const onDelete = (): void => {
        void onDeleteComment(comment.id).then((res: boolean) => {
            if(res) {
                toggleConfirmDelete(false);
            }
        })
    }

    const onEdit = (): void => {
        void onEditComment(comment.id, commentText).then((res: boolean) => {
            if(res) {
                setOnEditMode(false);
            }
        })
    }

    return (
        <Box className={classes.root}>

            <Typography className={classes.name} color={'textPrimary'}>
                {comment.authorFirstName} {comment.authorLastName}
                <span className={classes.date}>
                    {' '}{moment(comment.dateCreated).format('MM/DD/YYYY')}
                </span>
                {
                    onEditMode &&
                    <SaveIcon
                        className={classes.saveIcon}
                        onClick={() => onEdit()}
                    />
                }
                {
                    onEditMode &&
                    <ClearIcon
                        className={classes.editIcon}
                        onClick={() => {setOnEditMode(false); setCommentText(comment.text)}}
                    />
                }
                {
                    isAllowedToEdit && !onEditMode &&
                    <EditIcon
                        className={classes.editIcon}
                        onClick={() => setOnEditMode(true)}
                    />
                }
                {
                    isAllowedToDelete && !onEditMode &&
                    <DeleteForeverIcon
                        className={classes.deleteIcon}
                        onClick={() => toggleConfirmDelete(true)}
                    />
                }

            </Typography>

            <Typography className={classes.text}>
                {
                    !onEditMode && commentText.length > 300 && !displayFullComment &&
                    <>
                        {commentText.substr(0, 300)}
                        <span
                            onClick={() => toggleFullComment(true)}
                            className={classes.showMore}
                        >
                            {' '}...see more
                        </span>
                    </>
                }
                {
                    !onEditMode && (commentText.length <= 300 || displayFullComment) &&
                    <>
                        {commentText}
                    </>
                }
                {
                    onEditMode &&
                    <TextField
                        id="edit-comment-textarea"
                        multiline
                        fullWidth
                        value={commentText}
                        className={classes.textarea}
                        onChange={(e: any) => setCommentText(e.target.value)}
                        rows={2}
                        rowsMax={5}
                        inputProps={{ maxLength: 500 }}
                        variant="outlined"
                    />
                }
            </Typography>

            {
                displayConfirmDelete &&
                <ConfirmDeleteModal
                    subtitle="Are you sure you want to delete this comment?"
                    isLoading={false}
                    open={true}
                    handleClose={() => toggleConfirmDelete(false)}
                    onConfirm={() => onDelete()}
                />
            }
        </Box>
    )
}
