import * as api from './_utils/api';
import { base64ToFile } from '../helpers';
import { ICategory } from '../context/consumer/objectDetails';

export class ObjectApi {
    static async getMyObjects(
        page: number,
        size: number,
        sortBy: string,
        asc: boolean | undefined,
        jobId = -1,
    ): Promise<any> {
        if (jobId === -1) {
            return api._post(`artifact/get?page=${page}&size=${size}&desc=${!asc}&orderBy=${sortBy}`, {});
        }
        return api._post(`artifact/get?page=${page}&size=${size}&desc=${!asc}&orderBy=${sortBy}`, { userId: jobId });
    }

    static async getArtifact(artifactId: number): Promise<any> {
        return api._get(`artifact/get/${artifactId}`);
    }

    static async deleteObject(objectId: number): Promise<any> {
        return api._delete(`artifact/delete/${objectId}`);
    }

    static async searchObjects(page: number, size: number, searchBy: string, jobId = -1): Promise<any> {
        if (jobId === -1) {
            return api._post(`artifact/get?page=${page}&size=${size}`, { search: searchBy });
        }
        return api._post(`artifact/get?page=${page}&size=${size}`, { userId: jobId, search: searchBy });
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static async addObject(payload: any): Promise<any> {
        const body: any = { ...payload };
        body.exactProductionYear =
            body.exactProductionYear !== null
                ? body.exactYearNotation === 'BC'
                    ? body.exactProductionYear * -1
                    : body.exactProductionYear
                : null;
        body.lowerProductionYear =
            body.lowerProductionYear !== null
                ? body.lowerYearNotation === 'BC'
                    ? body.lowerProductionYear * -1
                    : body.lowerProductionYear
                : null;
        body.upperProductionYear =
            body.upperProductionYear !== null
                ? body.upperYearNotation === 'BC'
                    ? body.upperProductionYear * -1
                    : body.upperProductionYear
                : null;
        body.categories = payload.categories.map((c: any) => c.id);
        body.collections = payload.collections.map((c: any) => c.id);

        Object.keys(body).map((key) => {
            if (typeof body[key] === 'string') {
                body[key] = body[key].trim();
            }
        });

        return api._post(`artifact/create`, body);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static async updateObject(payload: any, artifactId: number): Promise<any> {
        const body: any = { ...payload };
        body.exactProductionYear =
            body.exactProductionYear !== null
                ? body.exactYearNotation === 'BC'
                    ? body.exactProductionYear * -1
                    : body.exactProductionYear
                : null;
        body.lowerProductionYear =
            body.lowerProductionYear !== null
                ? body.lowerYearNotation === 'BC'
                    ? body.lowerProductionYear * -1
                    : body.lowerProductionYear
                : null;
        body.upperProductionYear =
            body.upperProductionYear !== null
                ? body.upperYearNotation === 'BC'
                    ? body.upperProductionYear * -1
                    : body.upperProductionYear
                : null;
        body.collections = payload.collections.map((c: ICategory) => c.id);

        Object.keys(body).map((key) => {
            if (typeof body[key] === 'string') {
                body[key] = body[key].trim();
            }
        });

        return api._put(`artifact/update/${artifactId}`, body);
    }

    static async updateArtifactCategories(payload: number[], artifactId: number): Promise<any> {
        return api._put(`artifact/update/${artifactId}`, { categories: payload });
    }

    static async addPhoto(objectId: number, files: string[], batch: number): Promise<any> {
        const formData = new FormData();
        files.forEach((file: string, index: number) => {
            const f = base64ToFile(file, `photo_${objectId}_${index}_${batch}.png`);
            formData.append('photos', f);
        });
        return api._post(`photo/create/${objectId}`, formData);
    }

    static async deletePhoto(photosId: number[]): Promise<any> {
        return api._delete(`photo/delete`, photosId);
    }

    static async addFile(artifactId: number, files: File[]): Promise<any> {
        const formData = new FormData();
        files.forEach((file: File) => {
            formData.append('documents', file);
        });
        return api._post(`document/create/${artifactId}`, formData);
    }

    static async deleteDocument(documentId: number): Promise<any> {
        return api._delete(`document/delete/${documentId}`);
    }

    static async updateArtifactLabel(artifactId: number, labelId: number): Promise<any> {
        return api._put(`label/updateArtifactLabel/${artifactId}/${labelId}`);
    }

    static async preUploadPhoto(photo: string): Promise<any> {
        const formData = new FormData();
        const f = base64ToFile(photo, `photo.png`);
        formData.append('file', f);
        return api._post(`File/Upload-Photo`, formData);
    }

    static async getObjectPreferences(userId = -1): Promise<any> {
        return api._get(userId !== -1 ? `Collectors/Preference/Get?userId=${userId}` : 'Collectors/Preference/Get');
    }
}
