import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, Card, Box, Button } from '@material-ui/core';

import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import imagePlaceholder from 'assets/placeholders/artifact-placeholder.png';

import { ICollection } from 'types';
import { FileSize } from 'core/enums';
import { AppRoutes } from 'core/helpers/app-routes';
import { getPhotoUrl } from 'core/helpers/file-size.helper';
import { UserContext } from '../../../core/context/user';
import ReplyIcon from '@material-ui/icons/Reply';
import { ShareCollectionsToPersonsModal } from '../../_consumer/share-object/ShareCollectionsToPersonsModal';
import DeleteIcon from '@material-ui/icons/Delete';

type ObjectCardProps = {
    collection: ICollection;
    isSharedWithMe?: boolean;
    onDelete?: () => void;
};

export const CollectionCard: React.FC<ObjectCardProps> = ({ collection, isSharedWithMe, onDelete }) => {
    const userContext = useContext(UserContext);

    const [displayShareCollectionModal, toggleShareCollectionModal] = useState(false);

    const generateObjectsLink = (): string => {
        const params = new URLSearchParams(window.location.search);
        const jobId = params.get('jobId');
        const link = `${AppRoutes.COLLECTION}?id=${collection.id}${jobId ? `&jobId=${jobId}` : ''}${
            isSharedWithMe ? '&sharedWithMe=true' : ''
        }`;
        return link;
    };

    return (
        <div className={'root card-link'}>
            <Card
                className="root object-card-root"
                id={`${collection.id}`}
                style={{ backgroundColor: userContext.lightTheme ? '#EFEFEF' : '#2B2B33' }}
            >
                <CardActionArea>
                    <Link to={generateObjectsLink()}>
                        <CardMedia
                            className="media"
                            image={getPhotoUrl(collection.photoUrl || '', FileSize.SM) || imagePlaceholder}
                            style={!getPhotoUrl(collection.photoUrl, FileSize.SM) ? { opacity: 0.4 } : {}}
                            title={collection.name}
                        />
                    </Link>

                    <Box className="object-actions">
                        {collection.displayShareButtonOnCard && (
                            <Button
                                id="share-btn"
                                variant="contained"
                                size={'small'}
                                className="action action-share"
                                style={collection.displayDeleteButtonOnCard ? { right: 50 } : { right: 15 }}
                                onClick={() => toggleShareCollectionModal(true)}
                            >
                                <ReplyIcon />
                            </Button>
                        )}
                        {collection.displayDeleteButtonOnCard && (
                            <Button
                                id="delete-btn"
                                variant="contained"
                                size={'small'}
                                className="action action-delete"
                                onClick={() => (onDelete ? onDelete() : null)}
                            >
                                <DeleteIcon />
                            </Button>
                        )}
                    </Box>

                    <CardContent>
                        <Tooltip
                            arrow
                            interactive={true}
                            title={collection.name}
                            className="full-artifact-name-tooltip"
                        >
                            <Typography component="h2" className="card-title">
                                {collection.name}
                            </Typography>
                        </Tooltip>
                        <Typography variant="body2" color="textSecondary" component="p">
                            <span className="label">Total objects:</span>
                            <span className="value">{collection.totalObjects}</span>
                        </Typography>
                    </CardContent>
                </CardActionArea>

                {displayShareCollectionModal && (
                    <ShareCollectionsToPersonsModal
                        collection={collection}
                        open={true}
                        handleClose={() => toggleShareCollectionModal(false)}
                    />
                )}
            </Card>
        </div>
    );
};
