import React, {useState} from "react";
import {Backdrop, Box, Button, CircularProgress, Fade, Modal, Typography} from "@material-ui/core";

import {modalStyles} from "components/_others/modals/modal-styles";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

import {ModalHeader} from "components/_others/modals/ModalHeader";

import {useRemoveObject} from "./hook";

type RemoveFeedObjectModalProps = {
    objectId: number;
    open: boolean;
    handleClose: () => void;
    onRemove: () => void;
}

export const RemoveObjectModal: React.FC<RemoveFeedObjectModalProps> = (
    {objectId, open, handleClose, onRemove}
) => {

    const classes = modalStyles();

    const {isLoading, removeObject} = useRemoveObject(objectId);
    const [hasError, setHasError] = useState(false);

    const onConfirm = (): void => {
        setHasError(false);
        removeObject().then((res: boolean) => {
            if(res) {
                onRemove();
                handleClose();
                setHasError(false);
            } else {
                setHasError(true);
            }
        })
    }

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>

                    <ModalHeader
                        title={'Delete Object'}
                        handleClose={handleClose}
                    />

                    <Typography className={classes.modalSubtitle}>
                        Are you sure you want to delete this object?
                    </Typography>

                    {
                        hasError &&
                        <Typography color={"error"}>
                            Sorry, there was an error. Please try again later.
                        </Typography>
                    }

                    <Box textAlign={'right'} marginTop={'60px'}>
                        <Button
                            id="cancel-btn"
                            variant="contained"
                            size={"small"}
                            style={{marginRight: '15px'}}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>

                        <Button
                            id="confirm-btn"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            className={classes.primaryButton}
                            onClick={() => onConfirm()}
                        >
                            {
                                isLoading && <CircularProgress className="circular-progress sm" />
                            }
                            {
                                !isLoading && <DeleteIcon />
                            }
                            Delete Object
                        </Button>
                    </Box>

                </div>
            </Fade>
        </Modal>
    )
}
