import { Box, Button, CircularProgress, makeStyles, Paper, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { UserApi } from 'core/api';
import React, { useEffect, useState } from 'react';
import { DeleteEmailSentModal } from './DeleteEmailSentModal';

export const useStyles = makeStyles((theme) => ({
    deleteAccountPaper: {
        padding: 20,
        boxShadow: 'none',
        marginTop: 10,
        marginBottom: 10,
    },
    deleteAccountButton: {
        backgroundColor: theme.palette.error.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.error.main,
        },
    },
}));

export const DeleteAccountSegment: React.FC = () => {
    const classes = useStyles();
    const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState<boolean>(false);
    const [deleteAccountEmailLoading, setDeleteAccountEmailLoading] = useState<boolean>(false);
    const [code, setCode] = useState<string>('');

    const sendDeleteAccountEmail = (): void => {
        setDeleteAccountEmailLoading(true);

        UserApi.sendDeleteAccountEmail()
            .then((_res) => {
                setOpenDeleteAccountModal(true);
            })
            .catch((error) => {
                console.error(error.response);
            })
            .finally(() => {
                setDeleteAccountEmailLoading(false);
            });
    };

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const deleteCode = urlSearchParams.get('data');
        if (deleteCode) {
            setCode(deleteCode);
            setOpenDeleteAccountModal(true);
        }
    }, []);

    return (
        <>
            <Typography className="user-form-section-title" />
            <Paper className={classes.deleteAccountPaper}>
                <Typography variant="h5" color="textPrimary" style={{ fontWeight: 600 }}>
                    Delete My Account
                </Typography>

                <Typography style={{ marginTop: 7, marginBottom: 7 }}>
                    You will recieve an E-Mail to confirm your decision. <br />
                    Please note, that all your account information will permanently be erased.
                </Typography>

                <Box display="flex" justifyContent="flex-end" style={{ marginTop: 7, marginBottom: 7 }}>
                    <Button
                        variant="contained"
                        className={classes.deleteAccountButton}
                        onClick={sendDeleteAccountEmail}
                    >
                        {deleteAccountEmailLoading ? (
                            <CircularProgress className="circular-progress sm" />
                        ) : (
                            <DeleteIcon />
                        )}
                        Delete my account
                    </Button>
                </Box>
            </Paper>

            <DeleteEmailSentModal
                open={openDeleteAccountModal}
                handleClose={() => {
                    setOpenDeleteAccountModal(false);
                }}
                deleteCode={code}
            />
        </>
    );
};
