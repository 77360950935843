import {createMuiTheme, ThemeOptions} from "@material-ui/core";
import {darkTheme} from "assets/themes/dark-theme";

export const mobileDrawerDarkTheme: ThemeOptions = createMuiTheme({
    ...darkTheme,
    overrides: {
        MuiPopover: {
            root: {
                top: 0,
                maxWidth: '100vw',
                maxHeight: '100vh',
                overflow: 'auto!important',
            },
            paper: {
                position: 'absolute',
                maxWidth: '100vw',
                maxHeight: '100vh',
                overflow: 'auto!important',
                top: '0!important',
                left: '0!important',
                bottom: 0,
                right: 0
            }
        }
    },
});
