const TOKEN_KEY = 'art_token';
const REFRESH_TOKEN_KEY = 'art_ref_token';
const ROLE = 'art_role';
const JOB_ID = 'jobId';

export function setToken(token: string, refreshToken: string): void {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
}

export function setRole(role: string): void {
    localStorage.setItem(ROLE, role);
}

export function getRole(): string {
    return localStorage.getItem(ROLE) || '';
}

export function getToken(): string|null {
    return localStorage.getItem(TOKEN_KEY);
}

export function getRefreshToken(): string|null {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export function clearLocalStorage(): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(ROLE);
    localStorage.removeItem(JOB_ID);
}

export function setJobIdInStorage(jobId: number): void {
    localStorage.setItem(JOB_ID, jobId.toString());
}

export function getJobIdFromStorage(): string|null {
    return localStorage.getItem(JOB_ID);
}
