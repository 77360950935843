import {INotification} from "core/context/user";
import {Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {AppRoutes} from "../../../../../core/helpers";

export function computeDescription(notification: INotification): any {
    if(notification.type === 'AdminFeedbackRequestHasNoFeedback') {
        const id = notification.values?.length > 0 ? notification.values[0] : '';
        return (
            <Typography style={{fontSize: 14}}>
                <Link
                    to={`${AppRoutes.FEEDBACK_REQUEST}?id=${id}`}
                    style={{color: '#FE6502'}}
                >
                    Feedback request
                </Link>
                {
                    notification.description.replace('[[feedbackRequestId]]', '')
                }
            </Typography>
        )
    }

    if(notification.type === 'AdminCollectorPurchaseFeedbackRequest') {
        const id = notification.values?.length > 0 ? notification.values[0] : '';
        return (
            <Typography style={{fontSize: 14}}>
                A new{' '}
                <Link
                    to={`${AppRoutes.FEEDBACK_REQUEST}?id=${id}`}
                    style={{color: '#FE6502'}}
                >
                    feedback request
                </Link>
                {' '} was purchased.
            </Typography>
        )
    }

    if(notification.type === 'AdminExpertRegister') {
        const id = notification.values?.length > 0 ? notification.values[0] : '';
        return (
            <Typography style={{fontSize: 14}}>
                {
                    notification.description.replace('[[userId]].', '')
                }
                <Link
                    to={`${AppRoutes.ADMIN_EXPERT_PROFILE}?id=${id}`}
                    style={{color: '#FE6502'}}
                >
                    expert.
                </Link>
            </Typography>
        )
    }

    if(notification.type === 'AdminCollectorRegister') {
        const id = notification.values?.length > 0 ? notification.values[0] : '';
        return (
            <Typography style={{fontSize: 14}}>
                {
                    notification.description.replace('[[userId]].', '')
                }
                <Link
                    to={`${AppRoutes.ADMIN_COLLECTOR_PROFILE}?id=${id}`}
                    style={{color: '#FE6502'}}
                >
                    collector.
                </Link>
            </Typography>
        )
    }

    if(notification.type === 'AdminArchivistRegister') {
        const id = notification.values?.length > 0 ? notification.values[0] : '';
        return (
            <Typography style={{fontSize: 14}}>
                {
                    notification.description.replace('[[userId]].', '')
                }
                <Link
                    to={`${AppRoutes.ADMIN_ARCHIVIST_PROFILE}?id=${id}`}
                    style={{color: '#FE6502'}}
                >
                    archivist.
                </Link>
            </Typography>
        )
    }

    if(notification.type === 'AdminCuratorRegister') {
        const id = notification.values?.length > 0 ? notification.values[0] : '';
        return (
            <Typography style={{fontSize: 14}}>
                {
                    notification.description.replace('[[userId]].', '')
                }
                <Link
                    to={`${AppRoutes.ADMIN_CURATOR_PROFILE}?id=${id}`}
                    style={{color: '#FE6502'}}
                >
                    curator.
                </Link>
            </Typography>
        )
    }

    if(notification.type === 'ExpertBadRating') {
        const id = notification.values?.length > 0 ? notification.values[0] : '';
        return (
            <Typography style={{fontSize: 14}}>
                <Link
                    to={`${AppRoutes.ADMIN_EXPERT_PROFILE}?id=${id}`}
                    style={{color: '#FE6502'}}
                >
                    This expert's
                </Link>
                {
                    notification.description.replace('[[userId]]', '')
                }
            </Typography>
        )
    }

    if(notification.type === 'CollectorAssessmentReceived') {
        const id = notification.values?.length > 0 ? notification.values[0] : '';
        return (
            <Typography style={{fontSize: 14}}>
                {
                    notification.description.replace('[[artifactId]].', '')
                }
                <Link
                    to={`${AppRoutes.OBJECT}?id=${id}`}
                    style={{color: '#FE6502'}}
                >
                    this object
                </Link>.

            </Typography>
        )
    }

    if(notification.type === 'CollectorShare') {
        const id = notification.values?.length > 0 ? notification.values[0] : '';
        return (
            <Typography style={{fontSize: 14}}>
                {
                    notification.description.replace('[[shareId]] with you.', '')
                }
                <Link
                    to={`${AppRoutes.SHARED_OBJECT}?id=${id}`}
                    style={{color: '#FE6502'}}
                >
                    this object
                </Link>
                {' '}with you.

            </Typography>
        )
    }

    if(notification.type === 'CollectorShareComment') {
        const id = notification.values?.length > 0 ? notification.values[0] : '';
        return (
            <Typography style={{fontSize: 14}}>
                {
                    notification.description.replace('[[artifactId]].', '')
                }
                <Link
                    to={`${AppRoutes.OBJECT}?id=${id}`}
                    style={{color: '#FE6502'}}
                >
                    this object.
                </Link>
            </Typography>
        )
    }

    if(notification.type === 'CollectorPublicFeedComment') {
        const id = notification.values?.length > 0 ? notification.values[0] : '';
        return (
            <Typography style={{fontSize: 14}}>
                {
                    notification.description.replace('[[artifactId]].', '')
                }
                <Link
                    to={`${AppRoutes.OBJECT}?id=${id}`}
                    style={{color: '#FE6502'}}
                >
                    this object.
                </Link>
            </Typography>
        )
    }

    if(notification.type === 'CollectorExpertRequiredMorePhotos') {
        const id = notification.values?.length > 0 ? notification.values[0] : '';
        return (
            <Typography style={{fontSize: 14}}>
                {
                    notification.description.replace('[[artifactId]].', '')
                }
                <Link
                    to={`${AppRoutes.OBJECT}?id=${id}`}
                    style={{color: '#FE6502'}}
                >
                    this object.
                </Link>
            </Typography>
        )
    }

    if(notification.type === 'CollectorRegister') {
        return (
            <Typography style={{fontSize: 14}}>
                {
                    notification.description
                }
            </Typography>
        )
    } 

    if(notification.type === 'ExpertCollectorAddedMorePhotos') {
        const id = notification.values?.length > 0 ? notification.values[0] : '';
        return (
            <Typography style={{fontSize: 14}}>
                {
                    notification.description.replace('[[feedbackRequestId]].', '')
                }
                <Link
                    to={`${AppRoutes.FEEDBACK_REQUEST}?id=${id}`}
                    style={{color: '#FE6502'}}
                >
                    this object.
                </Link>
            </Typography>
        )
    }

    if(notification.type === 'CollectorFeedbackRequestComment') {
        const id = notification.values?.length > 0 ? notification.values[0] : '';
        return (
            <Typography style={{fontSize: 14}}>
                {
                    notification.description.replace('[[artifactId]].', '')
                }
                <Link
                    to={`${AppRoutes.OBJECT}?id=${id}`}
                    style={{color: '#FE6502'}}
                >
                    this object.
                </Link>
            </Typography>
        )
    }

    if(notification.type === 'ExpertFeedbackRequestComment') {
        const id = notification.values?.length > 0 ? notification.values[0] : '';
        return (
            <Typography style={{fontSize: 14}}>
                {
                    notification.description.replace('[[feedbackRequestId]].', '')
                }
                <Link
                    to={`${AppRoutes.FEEDBACK_REQUEST}?id=${id}`}
                    style={{color: '#FE6502'}}
                >
                    this object.
                </Link>
            </Typography>
        )
    }

    if(notification.type === 'CollectorCollectionShare') {
        const id = notification.values?.length > 0 ? notification.values[0] : '';
        return (
            <Typography style={{fontSize: 14}}>
                {
                    notification.description.replace('[[shareId]] with you.', '')
                }
                <Link
                    to={`${AppRoutes.COLLECTION}?id=${id}&sharedWithMe=true`}
                    style={{color: '#FE6502'}}
                >
                    this collection.
                </Link>
            </Typography>
        )
    }
}
