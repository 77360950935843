import { Box, Container } from '@material-ui/core';
import { Gallery } from 'components/Gallery';
import { GalleryTypeEnum } from 'components/Gallery/helpers/GalleryTypeEnum';
import { SortObjectsOptions } from 'core/enums';
import { getCurrentPageFromUrl, getPageSizeFromUrl, getSortPreference } from 'core/helpers/utils';
import React, { useEffect, useState } from 'react';
import { Empty } from 'scenes/consumer/MyObjects/components/Empty';
import { RemoveObjectModal } from '../_components/RemoveObjectModal';
import { getSortingOptions } from '../_helpers/objects-sorting.helper';
import { PageHeader } from './components/PageHeader';
import { useMyObjectsData } from './hooks/use-my-objects-data.hook';
import { rootStyles } from './styles/root.styles';

export const MyObjects: React.FC = () => {
    const classes = rootStyles();

    const [deleteObjectId, setDeleteObjectId] = useState(-1);
    const [selectedSortBy, setSelectedSortBy] = useState('');
    const {
        objects,
        totalValue,
        getObjects,
        pagination,
        isError,
        handlePageChange,
        handlePageSizeChange,
        handleSortByChange,
    } = useMyObjectsData();

    const getObjectsAsync = async (): Promise<void> => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('jobId');
        const page = getCurrentPageFromUrl();
        const pageSize = getPageSizeFromUrl();
        const preferences =
            (await getSortPreference('objects', id ? parseInt(id) : -1)) || SortObjectsOptions.DateCreated;
        setSelectedSortBy(preferences.sortPreference);
        getObjects(page, pageSize, preferences.sortPreference, preferences.ascPreference);
    };

    useEffect(() => {
        void getObjectsAsync();
    }, []);

    return (
        <Container maxWidth={false} className={classes.root}>
            {objects.length > 0 && (
                <>
                    <PageHeader
                        totalCount={pagination.totalCount}
                        totalValue={totalValue}
                        sortingOptions={getSortingOptions(pagination.sortBy, pagination.asc)}
                        onSortByChange={handleSortByChange}
                        sortByValue={selectedSortBy}
                    />

                    <Box>
                        <Gallery
                            galleryType={GalleryTypeEnum.Artifacts}
                            data={objects}
                            pagination={{
                                numberOfPages: pagination.numberOfPages,
                                currentPage: pagination.page,
                                pageSize: pagination.size,
                            }}
                            handlePageChange={(page: number) => handlePageChange(page)}
                            onObjectDelete={(id: number) => setDeleteObjectId(id)}
                            handlePageSizeChange={(size: number) => handlePageSizeChange(size)}
                        />
                    </Box>
                </>
            )}
            {objects.length === 0 && <Empty hasError={isError} />}

            {deleteObjectId !== -1 && (
                <RemoveObjectModal
                    objectId={deleteObjectId}
                    open={true}
                    handleClose={() => setDeleteObjectId(-1)}
                    onRemove={() => getObjects(pagination.page, pagination.size, pagination.sortBy, pagination.asc)}
                />
            )}
        </Container>
    );
};
