import {makeStyles, ThemeOptions} from "@material-ui/core/styles";

export const footerStyles = makeStyles((theme: ThemeOptions) => ({
    paper: {
        padding: 30,
        marginTop: 0,
        backgroundColor: theme.rootBackground.cardBackground
    },
    copyrightPaper: {
        padding: '10px 30px',
        backgroundColor: theme.divider.main
    },
    copyrightText: {
        color: theme.label.text,
        fontSize: 14,
    }
}))

export const navigationListStyles = makeStyles((theme) => ({
    box: {
        width: 'fit-content',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    },
    listTitle: {
        color: theme.label.text,
        fontSize: 20,
        opacity: 0.95
    },
    list: {
        paddingTop: 15,
    },
    link: {
        color: '#B2B2B2',
        cursor: 'pointer',
        textDecoration: 'none'
    },
    listItem: {
        paddingLeft: 0,
        paddingBottom: 0,
        fontSize: 16,
        color: theme.label.text,
        opacity: 0.7
    }
}))
