import {IAction} from "../../interfaces";
import {ISharedWithMeState} from "./shared-with-me.interfaces";

export default (state: ISharedWithMeState, action: IAction): ISharedWithMeState => {
    switch(action.type) {
        case 'SET_ARTIFACTS':
            return {
                ...state,

                statistics: {
                    ...state.statistics,
                    totalObjects: action.payload.totalCount,
                },
                paginationObjects: {
                    page: state.paginationObjects.page,
                    size: state.paginationObjects.size,
                    totalCount: action.payload.totalCount,
                    numberOfPages: Math.ceil(action.payload.totalCount/state.paginationObjects.size),
                },
                artifacts: action.payload.data?.map((a: any) => {
                    return {
                        id: a.id,
                        title: a.title,
                        description: a.description,
                        collectorName: `${a.collector.firstName || ''} ${a.collector.lastName || ''}`,
                        collectorPhoto: a.collector.photoUrl || '',
                        photo: {
                            fileName: a.title,
                            url: a.photoUrl,
                        }
                    }
                }),
                isLoadingObjects: false,
            }
        case 'SET_COLLECTIONS':
            // eslint-disable-next-line no-case-declarations
            const collections = action.payload.data?.map((c: any) => {
                return {
                    id: c.id,
                    message: c.message,
                    collection: {
                        id: c.id,
                        name: c.name,
                        photoUrl: c.photoUrl,
                        totalObjects: c.totalObjects,
                    }
                }
            })


            return {
                ...state,
                isLoadingCollections: false,
                statistics: {
                    ...state.statistics,
                    totalCollections: action.payload.totalCount,
                },
                paginationCollections: {
                    page: state.paginationCollections.page,
                    size: state.paginationCollections.size,
                    totalCount: action.payload.totalCount,
                    numberOfPages: Math.ceil(action.payload.totalCount/state.paginationCollections.size),
                },
                collections,
            }
        case 'SET_CURRENT_PAGE_OBJECTS':
            return {
                ...state,
                isLoadingObjects: false,
                paginationObjects: {
                    ...state.paginationObjects,
                    page: action.payload,
                }
            }
        case 'SET_CURRENT_PAGE_COLLECTIONS':
            return {
                ...state,
                isLoadingCollections: false,
                paginationCollections: {
                    ...state.paginationCollections,
                    page: action.payload,
                }
            }
        case 'SET_LOADING_OBJECTS':
            return {
                ...state,
                isLoadingObjects: action.payload
            }
        case 'SET_LOADING_COLLECTIONS':
            return {
                ...state,
                isLoadingCollections: action.payload
            }
        default:
            return state;
    }
}
