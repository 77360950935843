import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { objectsListStyles } from '../styles/objects-list.style';

import { IReportObject } from '../types';
import { ReportTypeEnum } from 'core/enums';

import { ObjectDetails } from './ObjectDetails';
import { Loader } from 'components/_others';

type ListOfObjectsProps = {
    data: IReportObject[];
    reportType: string;
    isLoading: boolean;
    onObjectRemove: (id: number) => void;
};

export const ListOfObjects: React.FC<ListOfObjectsProps> = ({ reportType, data, isLoading, onObjectRemove }) => {
    const classes = objectsListStyles();

    return (
        <Box>
            <Typography className={classes.title}>
                {reportType === ReportTypeEnum.MissingPhotos && <span>List of objects with 1 photo or less:</span>}
                {reportType === ReportTypeEnum.MissingDetails && <span>List of objects with missing details:</span>}
                {reportType === ReportTypeEnum.SharedOnCommunityFeed && (
                    <span>List of objects shared on Community Feed:</span>
                )}
            </Typography>

            <Box className={classes.objectsList}>
                {!isLoading && (
                    <>
                        {data?.map((object: IReportObject) => (
                            <ObjectDetails
                                key={object.id}
                                object={object}
                                reportType={reportType}
                                onObjectRemove={onObjectRemove}
                            />
                        ))}
                    </>
                )}

                {isLoading && <Loader />}
            </Box>
        </Box>
    );
};
