import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Card, CardContent, ThemeOptions, Tooltip, Typography } from '@material-ui/core';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import { AppRoutes, getPhotoUrl } from 'core/helpers';
import { ICategory, IExpert } from 'core/context/admin/expert-listing';
import { FileSize } from 'core/enums';
import { EditUserAccessModal } from '../../_dashboard/EditUserAccessModal/EditUserAccessModal';
import { getCustomerSatisfactionColor, getCustomerSatisfactionText } from 'core/helpers/customer-satisfaction';
import { formatAddress } from 'core/helpers/format-address';
import { ConfirmUserEmailModal } from 'components/_dashboard/ConfirmUserEmailModal/ConfirmUserEmailModal';

const useStyles = makeStyles((theme: ThemeOptions) => ({
    root: {
        border: '1px solid',
        borderColor: theme.divider.main,
        borderRadius: 2,
    },
    profileIconPlaceholder: {
        width: 40,
        height: 40,
        color: '#FE6502',
    },
    profilePic: {
        width: 40,
        height: 40,
        borderRadius: 40,
    },
    name: {
        fontSize: 16,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    email: {
        fontSize: 12,
        wordBreak: 'break-all',
    },
    address: {
        fontSize: 13,
        marginTop: 7,
    },
    totalFeedbacks: {
        fontSize: 13,
        marginRight: 10,
    },
    type: {
        fontSize: 13,
        marginRight: 10,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    rating: {
        color: '#0F940A',
        fontSize: 13,
        marginRight: 10,
    },
    activeIcon: {
        color: '#0F940A',
        width: 22,
    },
    inactiveIcon: {
        color: '#FF0000',
        width: 22,
    },
    category: {
        backgroundColor: theme.label.main,
        color: theme.label.text,
        fontSize: 13,
        marginLeft: 7,
        padding: '3px 10px',
        borderRadius: 2,
        display: 'inline-block',
        marginTop: 7,
    },
    divider: {
        width: '85%',
        margin: '7px auto',
        borderTop: '1px solid',
        borderColor: theme.divider.main,
    },
    activateEmailButton: {
        fontSize: 12,
    },
}));

type ExpertCardProps = {
    expert: IExpert;
};

export const ExpertCard: React.FC<ExpertCardProps> = ({ expert }) => {
    const classes = useStyles();
    const [emailConfirmed, setEmailConfirmed] = useState<boolean>(expert.emailConfirmed);
    const [isEnabled, setIsEnabled] = useState(expert.isActive);
    const [displayManageAccessModal, toggleManageAccessModal] = useState({ display: false, enable: false });
    const [displayConfirmEmailModal, setDisplayConfirmEmailModal] = useState<boolean>(false);

    const handleConfirmEmailClose = (): void => {
        setDisplayConfirmEmailModal(false);
    };

    const handleConfirmEmailOpen = (): void => {
        setDisplayConfirmEmailModal(true);
    };

    const onConfirmEmailSuccess = (): void => {
        setEmailConfirmed(true);
    };

    return (
        <Card className={`${classes.root} root object-card-root expert-card`} id={`${expert.userId}`}>
            <CardContent>
                <Box display={'flex'}>
                    <Box textAlign={'center'} width={'30%'}>
                        {!expert.photoUrl && <AccountCircleIcon className={classes.profileIconPlaceholder} />}
                        {expert.photoUrl && (
                            <img
                                src={getPhotoUrl(expert.photoUrl || '', FileSize.SM) || ''}
                                className={classes.profilePic}
                                alt={expert.firstName}
                            />
                        )}

                        <Tooltip
                            arrow
                            interactive={true}
                            title={`${expert.firstName} ${expert.lastName}`}
                            className="full-artifact-name-tooltip"
                        >
                            <Typography color="textPrimary" className={classes.name}>
                                {expert.firstName} {expert.lastName}
                            </Typography>
                        </Tooltip>

                        <Typography color="textSecondary" className={classes.email}>
                            {expert.email}
                        </Typography>

                        <Typography color="textSecondary" className={classes.email}>
                            {expert.phoneNumber}
                        </Typography>
                        <Typography color="secondary" className={classes.address}>
                            {formatAddress(
                                expert.country?.name || '',
                                expert.state?.name || '',
                                expert.city?.name || '',
                            )}
                        </Typography>

                        <div className={classes.divider} />

                        <Link to={`${AppRoutes.ADMIN_EXPERT_PROFILE}?id=${expert.userId}`} color="primary">
                            <Typography color="primary">View Profile</Typography>
                        </Link>

                        {!emailConfirmed && (
                            <Box width={'100%'} display="flex" justifyContent={'space-between'} alignItems="center">
                                <span style={{ fontSize: 12, color: 'red', marginRight: 5 }}>Email not confirmed</span>

                                <Button
                                    className={classes.activateEmailButton}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={handleConfirmEmailOpen}
                                >
                                    Activate Email
                                </Button>
                            </Box>
                        )}
                    </Box>
                    <Box textAlign={'right'} width={'70%'}>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                            <Typography color="textSecondary" className={classes.type}>
                                {expert.type}
                            </Typography>

                            <Tooltip
                                arrow
                                interactive={true}
                                title={getCustomerSatisfactionText(expert.rating)}
                                className="customer-satisfaction-tooltip"
                            >
                                <Typography
                                    className={classes.rating}
                                    style={{ color: getCustomerSatisfactionColor(expert.rating) }}
                                >
                                    {expert.rating.toFixed(0)}%
                                </Typography>
                            </Tooltip>

                            <Typography color="primary" className={classes.totalFeedbacks}>
                                {expert.totalFeedbacks} {expert.totalFeedbacks === 1 ? 'feedback' : 'feedbacks'}
                            </Typography>
                            {isEnabled && (
                                <CheckCircleIcon
                                    style={{ cursor: 'pointer' }}
                                    className={classes.activeIcon}
                                    onClick={() => toggleManageAccessModal({ display: true, enable: false })}
                                />
                            )}
                            {!isEnabled && (
                                <CancelIcon
                                    style={{ cursor: 'pointer' }}
                                    className={classes.inactiveIcon}
                                    onClick={() => toggleManageAccessModal({ display: true, enable: true })}
                                />
                            )}
                        </Box>
                        <Box marginTop={'10px'} maxHeight={'100px'} overflow={'auto'}>
                            {expert.categories.map((category: ICategory) => (
                                <span className={classes.category} key={category.id}>
                                    {category.name}
                                </span>
                            ))}
                        </Box>
                    </Box>
                </Box>

                <EditUserAccessModal
                    open={displayManageAccessModal.display}
                    userId={expert.userId}
                    enable={displayManageAccessModal.enable}
                    handleClose={() => toggleManageAccessModal({ display: false, enable: false })}
                    onSuccess={() => setIsEnabled(displayManageAccessModal.enable)}
                />

                <ConfirmUserEmailModal
                    isOpen={displayConfirmEmailModal}
                    userId={expert.userId}
                    onClose={handleConfirmEmailClose}
                    name={`${expert.firstName} ${expert.lastName}`}
                    onSuccess={onConfirmEmailSuccess}
                />
            </CardContent>
        </Card>
    );
};
