import {IArtifact} from "../../../../core/context/consumer/objectDetails";

export function computeMissingFields(object: IArtifact): string[] {
    const result = [];

    if(!object.width || !object.height || !object.depth || !object.weight) {
        let r = 'Dimensions (';
        if(!object.height) {
            r += 'height, ';
        }
        if(!object.width) {
            r += 'width, ';
        }
        if(!object.depth) {
            r += 'depth, ';
        }
        if(!object.weight) {
            r += 'weight, '
        }
        r = r.substring(r.length - 3, 0);
        r += ')';
        result.push(r);
    }

    if(!object.exactProductionYear && !object.lowerProductionYear) {
        result.push('approximate age');
    }

    if(!object.material) { result.push('material') }
    if(!object.style) { result.push('style') }
    if(!object.origin) { result.push('origin') }
    if(!object.condition) { result.push('condition') }
    if(!object.artist) { result.push('artist/maker') }

    if(!object.exactValue && !object.minimumValue) {
        result.push('estimated value');
    }

    if(!object.photos?.length) { result.push('photos') }
    if(!object.documents?.length) { result.push('documents') }

    return result;
}

