import { IAction } from '../../interfaces';
import { IObjectDetailsState } from './object-details.interfaces';

export default (state: IObjectDetailsState, action: IAction): IObjectDetailsState => {
    switch (action.type) {
        case 'SET_ARTIFACT':
            return {
                ...state,
                artifact: {
                    id: action.payload.artifact.id,
                    title: action.payload.artifact.title,
                    description: action.payload.artifact.description,
                    exactValue: action.payload.artifact.exactValue,
                    minimumValue: action.payload.artifact.minimumValue,
                    maximumValue: action.payload.artifact.maximumValue,
                    width: action.payload.artifact.width,
                    height: action.payload.artifact.height,
                    depth: action.payload.artifact.depth,
                    weight: action.payload.artifact.weight,
                    exactProductionYear: action.payload.artifact.exactProductionYear,
                    lowerProductionYear: action.payload.artifact.lowerProductionYear,
                    upperProductionYear: action.payload.artifact.upperProductionYear,
                    origin: action.payload.artifact.origin,
                    material: action.payload.artifact.material,
                    style: action.payload.artifact.style,
                    artist: action.payload.artifact.artist,
                    artistBio: action.payload.artifact.artistBio,
                    condition: action.payload.artifact.condition,
                    createdDate: action.payload.artifact.dateCreated,
                    detailsPercent: Math.round(action.payload.artifact.detailsPercent || 0),
                    isFeedbackImported: action.payload.artifact.isFeedbackImported,
                    isSharedToPublicFeed: action.payload.artifact.isSharedToPublicFeed,
                    fileOcrContent: action.payload.artifact.fileOcrContent,
                    createdBy: action.payload.artifact.createdBy,
                    paymentFreePass: action.payload.artifact.paymentFreePass,
                    documents: !action.payload.justObject
                        ? action.payload.artifact.documents || []
                        : state.artifact.documents,
                    feedbackRequests: action.payload.artifact.feedbackRequests || [],
                    experts: action.payload.artifact.experts || [],
                    photos: !action.payload.justObject ? action.payload.artifact.photos || [] : state.artifact.photos,
                    categories: action.payload.artifact.categories || [],
                    materials: action.payload.artifact.materials || [],
                    conditions: action.payload.artifact.conditions || [],
                    eras: action.payload.artifact.eras || [],
                    origins: action.payload.artifact.origins || [],
                    styles: action.payload.artifact.styles || [],
                    collections: action.payload.artifact.collections || [],
                    feedbacks: action.payload.artifact.feedbacks || [],
                    artistImageUrl: action.payload.artifact.artistImageUrl,
                },
            };
        case 'SET_TITLE_AND_DESCRIPTION':
            return {
                ...state,
                artifact: {
                    ...state.artifact,
                    title: action.payload.title,
                    description: action.payload.description,
                },
            };
        case 'SET_CATEGORIES':
            return {
                ...state,
                artifact: {
                    ...state.artifact,
                    categories: action.payload,
                },
            };
        case 'TOGGLE_CONF_DELETE_MODAL':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    displayConfirmDelete: action.payload,
                },
            };
        case 'TOGGLE_UNSAVED_CHANGES_MODAL':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    displayUnsavedChangesModal: action.payload,
                },
            };
        case 'TOGGLE_SHARE_OBJECT_OPTIONS_MODAL':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    displayShareObjectOptionsModal: action.payload,
                },
            };
        case 'TOGGLE_SHARE_OBJECT_PERSONS_MODAL':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    displayShareObjectToPersonsModal: action.payload,
                },
            };
        case 'TOGGLE_SHARE_OBJECT_FEED_MODAL':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    displayShareObjectToFeedModal: action.payload,
                },
            };
        case 'TOGGLE_MISSING_INFO_MODAL':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    displayMissingInfoModal: action.payload,
                },
            };
        case 'TOGGLE_REQUEST_ASSESSMENT_MODAL':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    displayRequestAssessmentModal: action.payload,
                },
            };
        case 'TOGGLE_RECOMMENDED_EXPERTS_MODAL':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    displayRecommendedExpertsModal: action.payload,
                },
            };
        case 'SET_DELETE_LOADER':
            return {
                ...state,
                loader: {
                    ...state.loader,
                    delete: action.payload,
                },
            };
        case 'SET_EDIT_MODE':
            return {
                ...state,
                onEditMode: action.payload,
            };
        case 'ADD_NEW_PHOTOS':
            return {
                ...state,
                artifact: {
                    ...state.artifact,
                    photos: state.artifact.photos.concat(action.payload),
                },
            };
        case 'ADD_NEW_DOCUMENTS':
            return {
                ...state,
                artifact: {
                    ...state.artifact,
                    documents: state.artifact.documents.concat(action.payload),
                },
            };
        case 'TOGGLE_UNSAVED_PHOTOS_MODAL':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    displayUnsavedPhotosModal: action.payload,
                },
            };
        case 'SET_PHOTOS':
            return {
                ...state,
                artifact: {
                    ...state.artifact,
                    photos: action.payload,
                },
            };
        default:
            return state;
    }
};
