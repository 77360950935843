import React from "react";
import { Box, Typography } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { getPhotoUrl } from "core/helpers/file-size.helper";
import { FileSize } from "core/enums";
import {makeStyles} from "@material-ui/core/styles";

type SharedByProps = {
    avatar: string|null;
    name: string;
    message: string;
}

const useStyles = makeStyles({
    root: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#707070'
    },
    divider: {
        maxWidth: 55,
        height: 0,
        marginTop: 0,
        borderRadius: 2,
        marginBottom: 15,
        marginLeft: 0,
        borderTop: '1px solid #707070',
    }
});

export const SharedBy: React.FC<SharedByProps> = ({avatar, name, message}) => {

    const classes = useStyles();

    return (
        <>
            <>
                <Typography className={classes.root}>
                    Shared with you by
                </Typography>
                <div className={classes.divider} />
            </>
            <Box style={{display: 'flex', alignItems: 'center'}}>
                {
                    !avatar &&
                    <AccountCircleIcon
                        style={{width: 40, height: 40, color: '#FE6502', cursor: 'pointer'}}
                    />
                }
                {
                    avatar &&
                    <img
                        src={getPhotoUrl(avatar || '', FileSize.SM)  || '' }
                        alt={name}
                        style={{width: '40px', height: '40px', borderRadius: '40px'}}
                    />
                }

                <Typography color={'textPrimary'} style={
                    {fontSize: 16, marginLeft: 10}
                }>
                    {name}
                </Typography>
            </Box>

            <Typography color="textPrimary" style={{fontSize: 13, marginBottom: '20px', marginTop: '7px'}}>
                {message}
            </Typography>
        </>
    )
}
