import { createMuiTheme, ThemeOptions } from "@material-ui/core";
import { darkTheme } from "assets/themes/dark-theme";
import {lightTheme} from "../../../assets/themes/light-theme";

export function getHelperToolbarTheme(isLightTheme: boolean): ThemeOptions {
    return isLightTheme ? helperToolbarLightTheme : helperToolbarDarkTheme;
}

export const helperToolbarDarkTheme: ThemeOptions = createMuiTheme({
    ...darkTheme,
    palette: {
        ...darkTheme.palette,
        primary: {
            main: "rgb(43,43,51, 0.7)",
            contrastText: "#EFEFEF",
        },
        secondary: {
            main: "#FE6502",
            contrastText: "#EFEFEF",
        }
    },
    typography: {
        htmlFontSize: 18,
        fontFamily: "Myriad Pro Regular"
    },
    overrides: {
        MuiAppBar: {
            root: {
                boxShadow: "0 3px 10px #00000014",
                minHeight: 65,
                backgroundColor: 'rgb(43,43,51, 1) !important',
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 65,
            }
        },
        MuiLink: {
            root: {
                cursor: "pointer",
            }
        },
        MuiButton: {
            root: {
                borderRadius: 0,
            }
        },
        MuiSelect: {
            root: {
                padding: '10px 14px',
                backgroundColor: darkTheme.rootBackground.secondary,
            }
        },
        MuiPopover: {
            paper: {
                backgroundColor: darkTheme.rootBackground.secondary,
            }
        }
    },
});

export const helperToolbarLightTheme: ThemeOptions = createMuiTheme({
    ...lightTheme,
    palette: {
        ...darkTheme.palette,
        primary: {
            main: "rgb(43,43,51, 0.7)",
            contrastText: "#EFEFEF",
        },
        secondary: {
            main: "#FE6502",
            contrastText: "#EFEFEF",
        },
        background: {
            default: "#FFFFFF",
            paper: "#FFFFFF",
        },
        text: {
            primary: "#383838",
            secondary: "#B2B2B2",
        },
    },
    typography: {
        htmlFontSize: 18,
        fontFamily: "Myriad Pro Regular"
    },
    overrides: {
        MuiAppBar: {
            root: {
                boxShadow: "0 3px 10px #00000014",
                minHeight: 65,
                backgroundColor: '#FFFFFF !important',
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 65,
            }
        },
        MuiLink: {
            root: {
                cursor: "pointer",
            }
        },
        MuiButton: {
            root: {
                borderRadius: 0,
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: "#EFEFEF",
            }
        },
        MuiSelect: {
            root: {
                padding: '10px 14px',
                backgroundColor: lightTheme.rootBackground.secondary,
            }
        },
        MuiPopover: {
            paper: {
                backgroundColor: lightTheme.rootBackground.secondary,
            }
        }
    },
});
