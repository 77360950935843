import { Backdrop, Box, Button, CircularProgress, Fade, Modal, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { PhotoCropper } from 'components/_consumer/ObjectForm/components/PhotoCropper';
import { FileSize, getPhotoUrl } from 'core';
import { ObjectApi } from 'core/api';
import { GlobalContext } from 'core/context/global';
import React, { useContext, useState } from 'react';
import { modalStyles } from '../../../../../components/_others/modals/modal-styles';
import { IPhoto } from '../../../../../core/context/consumer/objectDetails';

type CropPhotoModalProps = {
    artifactId: number;
    photo: IPhoto;
    open: boolean;
    handleClose: () => void;
};

export const CropPhotoModal: React.FC<CropPhotoModalProps> = ({ open, handleClose, artifactId, photo }) => {
    const classes = modalStyles();
    const globalContext = useContext(GlobalContext);

    const [loading, setIsLoading] = useState(false);

    const onUpload = (photo: string): void => {
        setIsLoading(true);
        ObjectApi.addPhoto(artifactId, [photo], 0)
            .then(() => {
                onDelete();
                globalContext.setUploadPhotosBatch1(false);
            })
            .catch((error: any) => {
                console.error(error);
            });
    };

    const onDelete = (): void => {
        ObjectApi.deletePhoto([photo.id])
            .then(() => {
                handleClose();
                history.go(0);
            })
            .catch((error: any) => {
                console.error(error.response);
            });
    };

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Box textAlign={'right'}>
                        <CloseIcon cursor={'pointer'} onClick={handleClose} />
                    </Box>

                    <Box>
                        <Typography className={classes.modalTitle}>Crop Photo</Typography>
                    </Box>

                    {!loading && (
                        <>
                            <PhotoCropper
                                onDoneClick={onUpload}
                                src={getPhotoUrl(photo.url, FileSize.SM) || ''}
                                onCancelClick={handleClose}
                                hideCancelButton
                            />
                            <Box textAlign={'right'} marginTop={'20px'}>
                                <Button
                                    id="cancel-delete-btn"
                                    variant="contained"
                                    size={'small'}
                                    className={classes.secondaryButton}
                                    onClick={handleClose}
                                >
                                    <CloseIcon height={'20px'} /> Cancel
                                </Button>
                            </Box>
                        </>
                    )}

                    {loading && (
                        <Box style={{ height: 100, textAlign: 'center', marginTop: 30 }}>
                            <CircularProgress className="circular-progress sm" />
                            <Typography color={'textPrimary'}>Please Wait...</Typography>
                        </Box>
                    )}
                </div>
            </Fade>
        </Modal>
    );
};
