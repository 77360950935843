import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

import { ExpertDetails } from "./ExpertDetails";
import { IExpert } from "core/context/consumer/objectDetails";

const useStyles = makeStyles({
    noOfExperts: {
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: 1,
    },
    expertsCount: {
        color: '#029BFE',
        fontWeight: 'bold',
        fontSize: 16,
    },
});

type RecommendedExpertsProps = {
    experts: IExpert[];
}

export const RecommendedExperts: React.FC<RecommendedExpertsProps> = ({experts}) => {

    const classes = useStyles();

    const noOfExperts = experts?.length || 0;

    return (
        <Box>

            <Typography className={classes.noOfExperts}>
                <span className={classes.expertsCount}>{noOfExperts}</span>
                {Number(noOfExperts) === 1 ? ' expert has ' : ' experts have '}
                been recommended
            </Typography>

            <Box className="experts-box" style={{maxHeight: '300px', marginTop: 15, overflow: 'auto'}}>
                {
                    experts.map((expert: IExpert) => (
                        <ExpertDetails
                            key={expert.userId}
                            url={expert.photoUrl}
                            name={`${expert.firstName} ${expert.lastName?.length > 0 ? `${expert.lastName[0]}.` : ''}`}
                            title={expert.title}
                            id={expert.userId}
                        />
                    ))
                }
            </Box>
        </Box>
    )
}
