import React, { useContext, useState } from 'react';

import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { CollectorsApi } from 'core/api/admin';
import { UtilsContext } from '../../../../../../core/context/utils.context';

type PaymentFreePasProps = {
    userId: number;
    paymentFreePass: boolean;
};

export const PaymentFreePas: React.FC<PaymentFreePasProps> = ({ userId, paymentFreePass }) => {
    const [value, setValue] = useState(paymentFreePass);
    const utilsContext = useContext(UtilsContext);
    const onChange = (val: boolean): void => {
        const answer = confirm('Are you sure you want to change this setting?');
        if (answer) {
            setValue(val);
            CollectorsApi.changeUserPaymentFreePass(userId, val)
                .then(() => {
                    utilsContext.addSnackbar('success', 'Success!');
                })
                .catch((error: any) => {
                    console.error(error?.response);
                    utilsContext.addSnackbar('error');
                });
        }
    };

    return (
        <>
            <FormControlLabel
                control={
                    <Checkbox
                        name="accurate-statement"
                        color="secondary"
                        style={{ transform: 'scale(0.8)' }}
                        checked={value}
                        onChange={() => onChange(!value)}
                    />
                }
                label={
                    <Typography className="checkbox-label" style={{ marginLeft: -5 }}>
                        Allow the user to submit assessments without paying
                    </Typography>
                }
            />
        </>
    );
};
