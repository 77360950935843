import React, {useContext} from "react";
import { modalStyles } from "components/_others/modals/modal-styles";
import { Backdrop, Box, Button, Fade, Modal, Typography } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {ObjectDetailsContext} from "../../core/context/consumer/objectDetails";
import SaveIcon from "@material-ui/icons/Save";

type ConfirmDeleteModalProps = {
    open: boolean;
    handleClose: () => void;
    onConfirm: () => void
}

export const UnsavedChangesModal: React.FC<ConfirmDeleteModalProps> = (
    {open, handleClose, onConfirm}
) => {

    const classes = modalStyles();
    const objectDetailsContext = useContext(ObjectDetailsContext);

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Box textAlign={'right'}>
                        <CloseIcon cursor={'pointer'} onClick={handleClose} />
                    </Box>

                    <Box>
                        <Typography className={classes.modalTitle}>
                            Unsaved Changes
                        </Typography>
                        <Typography className={classes.modalSubtitle}>
                            Your changes won't be saved if you cancel this.
                            {objectDetailsContext?.modals?.displayUnsavedPhotosModal ? ' You have an unsaved photo.' +
                                ' Please click on the Done button below your photo if ' +
                                'you want to save it, or on Delete to remove it.' : ''}
                        </Typography>
                    </Box>

                    <Box textAlign={'right'} marginTop={'60px'}>

                        {
                            !objectDetailsContext?.modals?.displayUnsavedPhotosModal &&
                            <Button
                                id="cancel-btn"
                                variant="contained"
                                size={"small"}
                                style={
                                    !objectDetailsContext?.modals?.displayUnsavedPhotosModal
                                        ? {marginRight: '15px'}
                                        : {}
                                }
                                className={classes.secondaryButton}
                                onClick={() => onConfirm()}
                            >
                                {
                                    objectDetailsContext?.modals?.displayUnsavedPhotosModal ?
                                        <SaveIcon style={{
                                            width: 20, marginRight: 5
                                        }} />
                                        : <CloseIcon height={'20px'} />
                                }
                                Cancel
                            </Button>
                        }

                        <Button
                            id="continue-btn"
                            variant="contained"
                            size={"small"}
                            color="primary"
                            className={classes.primaryButton}
                            onClick={handleClose}
                        >
                            <ArrowBackIosIcon style={{height: '18px'}} />
                            Continue Editing
                        </Button>



                    </Box>
                </div>

            </Fade>
        </Modal>
    )
}
