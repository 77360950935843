import React, {useState} from "react";
import { Box, Grid, Hidden, Typography } from "@material-ui/core";

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import photo from "assets/images/auth_image.png";
import photoMD from "assets/images/auth_image.png";

import { TutorialViews } from "../../consumer/Tutorial/views.enum";
import { Tutorial } from "../../consumer/Tutorial";

export const PhotoArea: React.FC = () => {

    const [onVideoMode, setOnVideoMode] = useState(false);

    return (
        <Grid item md={5} lg={6} style={{backgroundColor: '#1e1e24'}}>
            <Hidden smDown>
                <Hidden mdDown>
                    {
                        !onVideoMode && <img
                            src={photo}
                            alt="auth-image"
                            className="photo-image-auth"
                        />
                    }
                </Hidden>

                <Hidden lgUp>
                    {
                        !onVideoMode && <img
                            src={photoMD}
                            alt="auth-image"
                            className="photo-image-auth"
                        />
                    }
                </Hidden>

                {
                    onVideoMode &&
                    <Box style={{height: 'calc(100vh - 65px)', display: 'flex', alignItems: 'center'}}>
                        <Tutorial
                            hideNavigation={true}
                            activeStep={TutorialViews.HowToOrganize}
                        />
                    </Box>
                }

                <Typography className="copyright-photo-text" onClick={() => setOnVideoMode(!onVideoMode)}>
                    {onVideoMode ? 'Hide Videos' : 'See our Videos'} <FiberManualRecordIcon />
                </Typography>
            </Hidden>
        </Grid>
    )
}

