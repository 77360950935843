import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

type PageTitleProps = {
    title: string;
    subtitle?: string;
}

export const useStyles = makeStyles((theme) => ({
    pageTitleContainer: {
        zIndex: 2,
        position: 'fixed',
        maxWidth: 515,
        width: '100%',
        backgroundColor: theme.rootBackground.main,
    },
    text: {
        fontSize: '2.25rem',
        marginTop: 15,
    },
}))

export const PageTitle: React.FC<PageTitleProps> = ({title, subtitle}) => {

    const classes = useStyles();

    return (
        <Box className={classes.pageTitleContainer}>
            <Typography variant="h4" className={classes.text}>
                {title}
            </Typography>

            {
                subtitle &&
                <Typography className={'auth-page-subtitle'}>
                    {subtitle}
                </Typography>
            }

        </Box>
    )
}
