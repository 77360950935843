import React, {useContext, useEffect, useState} from "react";
import { makeStyles } from "@material-ui/styles";
import { modalStyles } from "components/_others/modals/modal-styles";
import { Backdrop, Box, Button, CircularProgress, Fade, Modal, ThemeOptions, Typography } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from '@material-ui/icons/Save';

import { CollectorsApi } from "core/api/admin";
import { CollectorTypes } from "core/enums";

import {UtilsContext} from "../../../../../../core/context/utils.context";

type EditUserAccessModalProps = {
    open: boolean;
    userId: number;
    type: string;
    handleClose: () => void;
    onSuccess: () => void;
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
    label: {
        backgroundColor: theme.label.main,
        fontSize: 14,
        padding: '5px 15px',
        borderRadius: 2,
        marginRight: 7,
        cursor: 'pointer',
    }
}))

export const ChangeCollectorTypeModal: React.FC<EditUserAccessModalProps> = (
    {open, userId, type, handleClose, onSuccess}
) => {

    const classes = modalStyles();
    const labelClasses = useStyles();

    const [selectedType, setSelectedType] = useState(type);
    const [isLoading, setIsLoading] = useState(false);
    const utilsContext = useContext(UtilsContext);
    useEffect(() => {
        setSelectedType(type);
    }, [open])

    const onConfirm = (): void => {
        setIsLoading(true);

        CollectorsApi.changeCollectorType(userId, selectedType)
            .then(() => {
                setIsLoading(false);
                onSuccess();
                handleClose();
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar('error');
            })
    }

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Box textAlign={'right'}>
                        <CloseIcon cursor={'pointer'} onClick={handleClose} />
                    </Box>

                    <Box>
                        <Typography className={classes.modalTitle}>
                            Change Collector Type
                        </Typography>
                    </Box>

                    <Box marginTop={'20px'}>
                        <span
                            onClick={() => setSelectedType(CollectorTypes.AVID)}
                            className={labelClasses.label}
                            style={selectedType === CollectorTypes.AVID
                                ? {backgroundColor: '#FE6502', color: 'white'}
                                : {}}
                        >
                            {CollectorTypes.AVID}
                        </span>
                        <span
                            onClick={() => setSelectedType(CollectorTypes.AVERAGE)}
                            className={labelClasses.label}
                            style={selectedType === CollectorTypes.AVERAGE
                                ? {backgroundColor: '#FE6502', color: 'white'}
                                : {}}
                        >
                            {CollectorTypes.AVERAGE}
                        </span>
                    </Box>

                    <Box textAlign={'right'} marginTop={'60px'}>
                        <Button
                            id="cancel-delete-btn"
                            variant="contained"
                            size={"small"}
                            style={{marginRight: '15px'}}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>

                        <Button
                            id="delete-btn"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            className={classes.primaryButton}
                            onClick={() => onConfirm()}
                        >
                            {
                                isLoading && <CircularProgress className="circular-progress sm" />
                            }
                            {
                                !isLoading && <SaveIcon style={{
                                    width: 20, marginRight: 5
                                }} />
                            }
                            Save
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    )
}
