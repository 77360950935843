import React from "react";
import { modalStyles } from "components/_others/modals/modal-styles";
import { Backdrop, Box, Button, CircularProgress, Fade, Modal, Typography } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from '@material-ui/icons/Delete';
import {ModalHeader} from "./modals/ModalHeader";

type ConfirmDeleteModalProps = {
    open: boolean;
    isLoading: boolean;
    subtitle: string;
    handleClose: () => void;
    onConfirm: () => void
}

export const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = (
    {open, handleClose, isLoading, onConfirm, subtitle}
) => {

    const classes = modalStyles();

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>

                    <ModalHeader
                        title={'Confirm Delete'}
                        handleClose={handleClose}
                    />

                    <Typography className={classes.modalSubtitle}>
                        {subtitle}
                    </Typography>

                    <Box textAlign={'right'} marginTop={'60px'}>
                        <Button
                            id="cancel-delete-btn"
                            variant="contained"
                            size={"small"}
                            style={{marginRight: '15px'}}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>

                        <Button
                            id="delete-btn"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            className={classes.primaryButton}
                            onClick={() => onConfirm()}
                        >
                            {
                                isLoading && <CircularProgress className="circular-progress sm" />
                            }
                            {
                                !isLoading && <DeleteIcon />
                            }
                            Delete
                        </Button>
                    </Box>
                </div>

            </Fade>
        </Modal>
    )
}
