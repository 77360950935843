import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export const assignJobModalStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '90%',
        maxWidth: 'unset',
        height: '80%',
        backgroundColor: theme.rootBackground.modalBackground,
    },
    searchBox: {
        marginLeft: -24,
        maxWidth: 400,
    },
    jobsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        marginLeft: '-1%',
        marginRight: '-1%',
        marginTop: 15,
        maxHeight: '75%',
        overflow: 'auto',
    }
}))
