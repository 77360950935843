import React, {useContext, useEffect, useState} from "react";
import { modalStyles } from "components/_others/modals/modal-styles";
import {Backdrop,
    Box,
    Button,
    CircularProgress,
    Fade,
    Modal,
    TextField,
    Typography
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from '@material-ui/icons/Save';

import {CollectorCollectionApi} from "../../../../core/api/consumer";
import {UtilsContext} from "../../../../core/context/utils.context";

type AddNewCollectionModalProps = {
    open: boolean;
    name: string;
    id: number;
    handleClose: () => void;
}

export const EditCollectionNameModal: React.FC<AddNewCollectionModalProps> = (
    {open,handleClose, id, name}
) => {

    const classes = modalStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [collectionName, setCollectionName] = useState({text: name, error: ''});
    const utilsContext = useContext(UtilsContext);
    useEffect(() => {
        setCollectionName({text: name, error: ''})
    }, [name])

    const onSave = (): void => {
        if(!collectionName.text.length) {
            setCollectionName({...collectionName, error: 'Please enter a collection name'});
            return;
        }

        setIsLoading(true);
        CollectorCollectionApi.updateCollection(id, {name: collectionName.text?.trim()})
            .then(() => {
                setIsLoading(false);
                utilsContext.addSnackbar('success', 'Success! Please wait...')

                setTimeout(() => {
                    history.go(0)
                }, 1000)
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar('error');
            })
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Box textAlign={'right'}>
                        <CloseIcon cursor={'pointer'} onClick={handleClose} />
                    </Box>

                    <Box>
                        <Typography className={classes.modalTitle}>
                            Edit Collection Name
                        </Typography>
                        <Typography className={classes.modalSubtitle} style={{marginBottom: 20}}>
                            Please enter a new name for your collection
                        </Typography>
                    </Box>

                    <Box>
                        <TextField
                            id="collectionName"
                            variant="outlined"
                            size="small"
                            type="text"
                            placeholder="Collection Name"
                            fullWidth
                            error={collectionName.error !== ''}
                            helperText={collectionName.error}
                            value={collectionName.text}
                            inputProps={{ maxLength: 100 }}
                            onChange={(e: any) => setCollectionName({
                                text: e.target.value,
                                error: e.target.value.length === 0 ? 'Please enter a collection name' : ''
                            })}
                        />
                    </Box>

                    <Box textAlign={'right'} marginTop={'60px'}>
                        <Button
                            id="cancel-delete-btn"
                            variant="contained"
                            size={"small"}
                            style={{marginRight: '15px'}}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>

                        <Button
                            id="delete-btn"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            className={classes.primaryButton}
                            onClick={() => onSave()}
                        >
                            {
                                isLoading && <CircularProgress className="circular-progress sm" />
                            }
                            {
                                !isLoading && <SaveIcon style={{
                                    width: 20, marginRight: 5
                                }} />
                            }
                            Save
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    )
}
