import {UserRoles} from "core/enums";
import {AfterLoginAction, AfterRegisterAction} from "./enums";
import {RegisterTypesEnum} from "core/enums";

export const waitForAccountConfirmationMessage = 'Your account has been created. ' +
    'Please wait for an admin to confirm your account.';
export const confirmEmailModalMessage = 'Please enter the code received on your email here:';

export const initialRegisterData = {
    acceptTerms: false,
    email: '',
    password: '',
    token: '',
    externalId: '',
    firstName: '',
    lastName: '',
    expertise: '',
    reference: '',
    registerType: RegisterTypesEnum.Web,
    shouldAskForExpertise: false,
    shouldAskForReferral: false,
    shouldAskForRegisterReason: false,
};

// info: currently the app is not available for archivists and curators
export function isBlockedPlatform(userRole: string): boolean {
    return userRole === UserRoles.ARCHIVIST || userRole === UserRoles.CURATOR;
}

// only the collectors can log in after they create an account
// the rest of the users must be activated by an admin
export function isAllowedToLoginAfterRegister(accountRole: string): boolean {
    return accountRole === UserRoles.COLLECTOR;
}

// info: if the user is a collector and he logs in for the first time,
// we display him a modal asking him about the register reason
export function askForRegisterReason(isFirstLogin: boolean, userRole: string): boolean {
    return userRole === UserRoles.COLLECTOR && isFirstLogin;
}

// the experts must provide their expertise
export function askForExpertise(isExternalRegister: boolean, accountRole: string): boolean {
    if(!askForExtraRegisterInformation(isExternalRegister, accountRole)) {
        return false;
    }

    return accountRole === UserRoles.EXPERT;
}

// the curators/archivists must provide information about how they heard about artifact
export function askForReferral(isExternalRegister: boolean, accountRole: string): boolean {
    if(!askForExtraRegisterInformation(isExternalRegister, accountRole)) {
        return false;
    }

    return accountRole === UserRoles.ARCHIVIST || accountRole === UserRoles.CURATOR;
}

export function manageRegister(isExternalRegister: boolean, accountRole: string): number {
    if(!isExternalRegister) {
        return AfterRegisterAction.DisplayConfirmEmailModal;
    }

    if(!isAllowedToLoginAfterRegister(accountRole)) {
        return AfterRegisterAction.DisplayWaitForActivationMessage;
    }

    return AfterRegisterAction.ContinueToLogin;
}

export function manageLogin(isFirstLogin: boolean, accountRole: string): any {
    if(askForRegisterReason(isFirstLogin, accountRole)) {
        return AfterLoginAction.AskForRegisterReason;
    }

    return AfterLoginAction.GoToApp;
}
// for experts, archivists and curators, on register,
// we ask for some additional information in modals, if external auth
export function askForExtraRegisterInformation(isExternalRegister: boolean, accountRole: string): boolean {
    if(!isExternalRegister) {
        return false;
    }

    if(accountRole === UserRoles.EXPERT) {
        return true; // we ask for expertise
    }

    return accountRole === UserRoles.ARCHIVIST || accountRole === UserRoles.CURATOR;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getLoginPayload(data: any): any {
    return {
        token: data.token || undefined,
        email: data.email,
        password: data.password || undefined,
        loginType: data.registerType,
        externalId: data.registerType === RegisterTypesEnum.Facebook ? data.externalId : undefined,
        rememberMe: true,
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getRegisterPayload(isExternalRegister: boolean, data: any): any {
    if(isExternalRegister) {
        return data;
    }
    return {
        email: data.email,
        password: data.password,
        token: data.token || undefined,
        externalId: data.externalId || undefined,
        firstName: data.firstName,
        lastName: data.lastName,
        termsAccepted: true,
        expertise: data.expertise || undefined,
        reference: data.reference || undefined,
        registerType: data.registerType || RegisterTypesEnum.Web,
    }
}
