import React, {useContext, useState} from "react";
import {Link, List, ListItem, Typography} from "@material-ui/core";

import {generalStyles} from "../styles/styles";
import {lightTheme} from "assets/themes/light-theme";
import {darkTheme} from "assets/themes/dark-theme";

import {NavLink} from "./NavLink";

import {ContactUsModal} from "components/_others/ContactUsModal";
import {ThemeProvider} from "@material-ui/core/styles";
import {responsiveStyles} from "../../_styles/responsive-styles";
import {INavLink} from "../../_helpers/nav-link.interfaces";
import {UserContext} from "../../../../core/context/user";
import {UserRoles} from "../../../../core/enums";

type NavigationProps = {
    isLoggedOut: boolean;
    links: INavLink[];
}

export const Navigation: React.FC<NavigationProps> = ({isLoggedOut, links}) => {

    const userContext = useContext(UserContext);

    const generalClasses = generalStyles();
    const responsiveClasses = responsiveStyles();

    const [displayContactUsModal, toggleContactUsModal] = useState(false);

    return (
        <>
            <List component="nav" aria-labelledby="main navigation" className={responsiveClasses.hideMobile}>
                {links.map((navLink: INavLink) => (
                    <NavLink key={navLink.id} navLink={navLink} />
                ))}
                {
                    (isLoggedOut || (userContext.user?.role === UserRoles.ARCHIVIST || userContext.user?.role === UserRoles.CURATOR)) &&
                    <Link className={'active-link'} onClick={() => toggleContactUsModal(true)}>
                        <ListItem className={generalClasses.navItem}>
                            <Typography variant="h6" color={'textPrimary'} className={generalClasses.navLink}>
                                Contact Us
                            </Typography>
                        </ListItem>
                    </Link>
                }
            </List>

            <ThemeProvider theme={userContext.lightTheme ? lightTheme : darkTheme}>
                <ContactUsModal
                    open={displayContactUsModal}
                    handleClose={() => toggleContactUsModal(false)}
                />
            </ThemeProvider>
        </>
    )
}
