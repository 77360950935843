import React from "react";
import { Typography } from "@material-ui/core";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { SectionHeader } from "./SectionHeader";
import { IDocument } from "core/context/consumer/objectDetails";

type DocumentsProps = {
    documents: IDocument[],
    hideSectionHeader?: boolean;
}

export const Documents: React.FC<DocumentsProps> = ({documents, hideSectionHeader}) => {
    return (
        <>
            {
                hideSectionHeader !== true &&
                    <SectionHeader title={'Documents & Receipts'} />
            }

            {
                documents?.length > 0 &&
                <>
                    {
                        documents.map((document: IDocument) => (
                            <a
                                href={document.url}
                                target="_blank"
                                key={document.id}
                                style={{display: 'flex', alignItems: 'center'}}
                            >
                                <InsertDriveFileIcon
                                    className="document-icon"
                                />
                                <Typography
                                    color={'textPrimary'}
                                    style={{fontSize: 13}}
                                >
                                    {document.fileName}
                                </Typography>
                            </a>
                        ))
                    }
                </>
            }
            {
                documents?.length === 0 &&
                <Typography style={{fontSize: 13, marginBottom: 10}}>
                    No documents
                </Typography>
            }
        </>
    )
}
