import { makeStyles } from '@material-ui/core/styles';

export const fullOpacityStyles = makeStyles((theme) => ({
    root: {
        width: '50%',
        height: 65,
        position: 'absolute',
        right: 0,
        ['@media (max-width:1280px)']: { width: '100%' },
    },
    full: {
        width: '100%',
        backgroundColor: theme.dashboardHeader.main,
    },
    partial: {
        padding: 30,
        marginTop: 0,
        backgroundColor: theme.dashboardHeader.main,
    },
}));

export const logoStyles = makeStyles({
    root: {
        paddingLeft: 24,
    },
});

export const generalStyles = makeStyles((_theme) => ({
    authButtonsContainer: {
        display: 'flex',
        height: 64,
        '&button': {
            height: 64,
            fontSize: 18,
            textTransform: 'capitalize',
        },
        '&#sign-up-header-btn': {
            minWidth: 141,
        },
        '#log-in-header-btn': {
            minWidth: 100,
        },
    },
    navItem: {
        ['@media (max-width:1340px)']: { paddingLeft: 5, paddingRight: 9 },
    },
    navLink: {
        fontSize: 17,
        lineHeight: 1,
        ['@media (max-width:1140px)']: { fontSize: 15, lineHeight: 1 },
        ['@media (max-width:1345px)']: { lineHeight: 1 },
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

export const authButtonsStyles = makeStyles((_theme) => ({
    authButtonsContainer: {
        display: 'flex',
        height: 64,
    },
    button: {
        height: 64,
        fontSize: 18,
        textTransform: 'capitalize',
    },
    signUp: {
        minWidth: 141,
    },
    logIn: {
        minWidth: 100,
    },
}));
