import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import 'components/_consumer/ObjectDetails/styles/style.less';

import { Loader } from 'components/layout/_components/Loader';
import { Header } from './components/Header';
import { Gallery } from 'components/_consumer/ObjectDetails/Gallery';
import { AboutObject } from './components/AboutObject';
import { SharedBy } from './components/SharedBy';
import { ObjectComments } from 'components/object/ObjectComments';
import { ObjectLabels } from 'components/_consumer/ObjectDetails/ObjectLabels';

import { AppRoutes } from 'core/helpers/app-routes';
import { CollectorShareApi } from 'core/api/consumer/collector-share.api';
import { SharedObjectContext } from 'core/context/consumer/sharedObject';
import { getEstimatedDisplayValue } from 'core/helpers';
import { UserContext } from 'core/context/user';
import { UtilsContext } from '../../../../../core/context/utils.context';

export const SharedObjectDetails: React.FC = () => {
    const history = useHistory();
    const sharedObjectContext = useContext(SharedObjectContext);
    const userContext = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(true);
    const utilsContext = useContext(UtilsContext);
    const [sharedFromCollection, setSharedFromCollection] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('id');

        const sharedWithMe = params.get('collectionShare');

        if (sharedWithMe) {
            setSharedFromCollection(true);
            console.log(sharedFromCollection);
        }

        if (!id) {
            history.push(AppRoutes.SHARED_WITH_ME);
        }

        CollectorShareApi.getSharedObject(Number(id), sharedWithMe || '')
            .then((res: any) => {
                sharedObjectContext.setArtifact(res.data);
                setIsLoading(false);
                window.scrollTo(0, 0);
            })
            .catch((error: any) => {
                console.error(error.response);
                history.push(AppRoutes.SHARED_WITH_ME);
                utilsContext.addSnackbar(
                    'error',
                    'Sorry, there was an error and we were unable to display your object.',
                );
            });
    }, []);

    return (
        <Container maxWidth={false} className="object-details-container">
            {isLoading && <Loader />}
            {!isLoading && (
                <>
                    <Header name={sharedObjectContext.artifact.title} />

                    <Box className="page-content-container">
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={12} md={6} lg={6} id="left-section">
                                <Grid container style={{ marginBottom: 10, alignItems: 'center' }}>
                                    <Grid item xs={12} sm={12} md={5} lg={5}>
                                        <Typography
                                            className="estimated-value-stats"
                                            style={userContext.lightTheme ? { color: 'black' } : {}}
                                        >
                                            Estimated Value:
                                            <span className="estimated-value-amount">
                                                {getEstimatedDisplayValue(
                                                    sharedObjectContext.artifact.exactValue,
                                                    sharedObjectContext.artifact.minimumValue,
                                                    sharedObjectContext.artifact.maximumValue,
                                                )}
                                            </span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={7} lg={7}>
                                        <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                                            <Typography
                                                className="estimated-value-stats"
                                                style={
                                                    userContext.lightTheme
                                                        ? { color: 'black', fontSize: 14, marginRight: 10 }
                                                        : { fontSize: 14, marginRight: 10 }
                                                }
                                            >
                                                Item number:{' '}
                                                <span>
                                                    <b>#{sharedObjectContext.artifact.id}</b>
                                                </span>
                                            </Typography>
                                            <Typography
                                                className="estimated-value-stats"
                                                style={
                                                    userContext.lightTheme
                                                        ? { color: 'black', fontSize: 14, marginRight: 10 }
                                                        : { fontSize: 14, marginRight: 10 }
                                                }
                                            >
                                                Time Stamp:{' '}
                                                <span>
                                                    <b>
                                                        {moment(
                                                            sharedObjectContext.artifact.createdDate?.replace('T', 'Z'),
                                                        ).format('HH:mm a')}
                                                    </b>
                                                </span>
                                            </Typography>
                                            <Typography
                                                className="estimated-value-stats"
                                                style={
                                                    userContext.lightTheme
                                                        ? { color: 'black', fontSize: 14, marginRight: 10 }
                                                        : { fontSize: 14, marginRight: 10 }
                                                }
                                            >
                                                Date:{' '}
                                                <span>
                                                    <b>
                                                        {moment(
                                                            sharedObjectContext.artifact.createdDate?.replace('T', 'Z'),
                                                        ).format('MM/DD/YYYY')}
                                                    </b>
                                                </span>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Box className={'object-details-left-side'}>
                                    <Gallery photos={sharedObjectContext.artifact.photos} />
                                    {sharedObjectContext.artifact.isFeedbackImported && (
                                        <ObjectLabels
                                            canDeleteLabels={false}
                                            artifactId={sharedObjectContext.artifact.id}
                                            categories={sharedObjectContext.artifact.categories}
                                            materials={sharedObjectContext.artifact.materials}
                                            styles={sharedObjectContext.artifact.styles}
                                            origins={sharedObjectContext.artifact.origins}
                                            conditions={sharedObjectContext.artifact.conditions}
                                            eras={sharedObjectContext.artifact.eras}
                                            setNewCategories={() => null}
                                        />
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box marginTop={'30px'}>
                                    <Box marginTop={'15px'} marginBottom={'25px'}>
                                        <SharedBy
                                            avatar={sharedObjectContext.artifact.collectorPhoto}
                                            name={sharedObjectContext.artifact.collectorName}
                                            message={sharedObjectContext.artifact.message}
                                        />
                                    </Box>

                                    <AboutObject artifact={sharedObjectContext.artifact} />

                                    <ObjectComments
                                        objectId={sharedObjectContext.artifact.id}
                                        userIsObjectOwner={false}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </Container>
    );
};
