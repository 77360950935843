import {
    Box,
    Button,
    CircularProgress,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { CommonApi, ICity, ICountry, ICurrency, IState } from 'core/api/shared';
import { UserContext } from 'core/context/user';
import { RegisterTypesEnum } from 'core/enums';
import { WEBSITE } from 'core/helpers';
import React, { useContext, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import { UserApi } from '../../../../../core/api/user';
import { UtilsContext } from '../../../../../core/context/utils.context';
import { cleanPhoneNumber } from '../../../../../core/helpers/utils';
import { ForgotPasswordModal } from '../../../components/ForgotPassword/ForgotPasswordModal';
import { ConfirmEmailChangeModal } from '../components/ConfirmEmailChangeModal';
import { DeleteAccountSegment } from '../components/DeleteAccountSegment';

export const useStyles = makeStyles((theme) => ({
    profileActionsContainer: {
        backgroundColor: theme.rootBackground.main,
    },
    formControl: {
        marginBottom: 15,
        marginTop: 4,
    },
    label: {
        color: theme.formLabel.main,
        opacity: 0.9,
    },
}));

export const UserProfileForm: React.FC = () => {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [openChangePasswordModal, toggleChangePasswordModal] = useState(false);
    const [openChangeEmailModal, toggleChangeEmailModal] = useState(false);

    const [onFocus, setOnFocus] = useState('');
    const [values, setValues] = useState({
        firstName: userContext.user?.firstName || '',
        lastName: userContext.user?.lastName || '',
        title: userContext.user?.title || '',
        phoneNumber: userContext.user?.phoneNumber || '',
        description: userContext.user?.description || '',
        address: userContext.user?.address || '',
        organization: userContext.user?.organization || '',
        website: userContext.user?.website || '',
        country: userContext.user?.country || null,
        countryId: userContext.user?.country?.id || -1,
        state: userContext.user?.state || null,
        stateId: userContext.user?.state?.id || -1,
        city: userContext.user?.city || null,
        cityId: userContext.user?.city?.id || -1,
        currencyId: userContext.user?.currency?.id || -1,
    });

    const [errors, setErrors] = useState({ firstName: '', lastName: '', website: '', phoneNumber: '' });
    const utilsContext = useContext(UtilsContext);
    const [countries, setCountries] = useState<ICountry[]>([]);
    const [states, setStates] = useState<IState[]>([]);
    const [cities, setCities] = useState<ICity[]>([]);
    const [currencies, setCurrencies] = useState<ICurrency[]>([]);

    useEffect(() => {
        setValues({
            firstName: userContext.user?.firstName || '',
            lastName: userContext.user?.lastName || '',
            title: userContext.user?.title || '',
            phoneNumber: userContext.user?.phoneNumber || '',
            description: userContext.user?.description || '',
            address: userContext.user?.address || '',
            organization: userContext.user?.organization || '',
            website: userContext.user?.website || '',
            country: userContext.user?.country || null,
            countryId: userContext.user?.country?.id || -1,
            state: userContext.user?.state || null,
            stateId: userContext.user?.state?.id || -1,
            city: userContext.user?.city || null,
            cityId: userContext.user?.city?.id || -1,
            currencyId: userContext.user?.currency?.id || -1,
        });
    }, [userContext.user]);

    useEffect(() => {
        getCountries();
        if (values.countryId !== -1) {
            getStates(values.countryId);
        }
        if (values.stateId !== -1) {
            getCities(values.stateId);
        }
        getCurrencies();
    }, []);

    const manageErrors = (): void => {
        const errFN = !values.firstName
            ? 'Please enter your first name'
            : values.firstName.replace(/[a-z]/g, '').length === values.firstName.length
            ? 'Please enter a valid first name'
            : '';
        const errLN = !values.lastName
            ? 'Please enter your last name'
            : values.lastName.replace(/[a-z]/g, '').length === values.lastName.length
            ? 'Please enter a valid last name'
            : '';

        const errW =
            values.website && !WEBSITE.test(values.website) ? 'Website format is not valid. Eg. www.artifact.com' : '';
        const errP =
            cleanPhoneNumber(values.phoneNumber).length !== 10 && cleanPhoneNumber(values.phoneNumber).length !== 0
                ? 'Invalid phone number'
                : '';

        setErrors({
            firstName: errFN,
            lastName: errLN,
            website: errW,
            phoneNumber: errP,
        });
    };

    const onSave = (): void => {
        if (errors.firstName !== '' || errors.lastName !== '' || errors.website !== '' || errors.phoneNumber !== '') {
            return;
        }
        setIsLoading(true);
        const payload = {
            firstName: values.firstName,
            lastName: values.lastName,
            title: values.title,
            description: values.description,
            organization: values.organization,
            website: values.website,
            address: values.address,
            phoneNumber: values.phoneNumber,
            currencyId: values.currencyId === -1 ? null : values.currencyId,
            countryId: values.countryId === -1 ? null : values.countryId,
            stateId: values.stateId === -1 ? null : values.stateId,
            cityId: values.cityId === -1 ? null : values.cityId,
        };
        UserApi.updateUserProfile(payload, userContext.user?.id || 0)
            .then((_res: any) => {
                setIsLoading(false);
                history.go(0);
                utilsContext.addSnackbar('success', 'Your profile information was updated.');
            })
            .catch((error: any) => {
                console.log(error);
                console.error(error.response);
                utilsContext.addSnackbar(
                    'error',
                    'Sorry, there was an error and we were unable to save your information.',
                );
            });
    };

    const getCountries = (): void => {
        CommonApi.getCountries()
            .then((res: any) => {
                setCountries(res.data);
            })
            .catch((error: any) => {
                console.error(error.response);
            });
    };

    const getStates = (countryId: number): void => {
        CommonApi.getStates(countryId)
            .then((res: any) => {
                setStates(res.data);
            })
            .catch((error: any) => {
                console.error(error.response);
            });
    };

    const getCities = (stateId: number): void => {
        CommonApi.getCities(stateId)
            .then((res: any) => {
                setCities(res.data);
            })
            .catch((error: any) => {
                console.error(error.response);
            });
    };

    const getCurrencies = (): void => {
        CommonApi.getCurrencies()
            .then((res: any) => {
                setCurrencies(res.data);
            })
            .catch((error: any) => {
                console.error(error.response);
            });
    };

    //checks if there have been changes made to the user
    const shouldDisableSaveButton = (): boolean => {
        for (const key of Object.keys(values)) {
            if (!['state', 'country', 'city', 'currencyId', 'stateId', 'countryId', 'cityId'].includes(key)) {
                if (values[key as keyof typeof values] !== userContext.user?.[key as keyof typeof userContext.user]) {
                    return false;
                }
            }
        }

        if (values.stateId !== userContext.user?.state?.id) {
            if (values.stateId === -1) {
                if (userContext.user?.state) {
                    return false;
                }
            } else {
                return false;
            }
        }
        if (values.cityId !== userContext.user?.city?.id) {
            if (values.cityId === -1) {
                if (userContext.user?.city) {
                    return false;
                }
            } else {
                return false;
            }
        }
        if (values.countryId !== userContext.user?.country?.id) {
            if (values.countryId === -1) {
                if (userContext.user?.country) {
                    return false;
                }
            } else {
                return false;
            }
        }
        if (values.currencyId !== userContext.user?.currency?.id) {
            if (values.currencyId === -1) {
                if (userContext.user?.currency) {
                    return false;
                }
            } else {
                return false;
            }
        }

        return true;
    };

    return (
        <>
            <Box className={`${classes.profileActionsContainer} user-profile-actions`}>
                <Button
                    id="cancel-edit-profile-btn"
                    variant="contained"
                    size={'small'}
                    style={{ marginRight: '10px' }}
                    onClick={() => history.goBack()}
                >
                    <CloseIcon height={'20px'} /> Cancel
                </Button>

                <Button
                    id="save-profile-btn"
                    variant="contained"
                    color="primary"
                    size={'small'}
                    onClick={onSave}
                    disabled={shouldDisableSaveButton()}
                >
                    {isLoading && <CircularProgress className="circular-progress sm" />}
                    {!isLoading && <SaveIcon />}
                    Save
                </Button>
            </Box>

            <Box className="user-profile-form">
                <Typography className="user-form-section-title" color="textPrimary">
                    My Profile
                </Typography>

                <Box marginBottom="15px">
                    <>
                        <InputLabel
                            htmlFor="firstName"
                            className={
                                onFocus === 'firstName'
                                    ? 'label focus-label'
                                    : errors.firstName !== ''
                                    ? 'label error-label'
                                    : `${classes.label} label`
                            }
                        >
                            First Name
                        </InputLabel>
                        <TextField
                            id="firstName"
                            variant="outlined"
                            size="small"
                            type="text"
                            fullWidth
                            helperText={errors.firstName}
                            error={errors.firstName !== ''}
                            value={values.firstName}
                            inputProps={{ maxLength: 30 }}
                            onChange={(e: any) => setValues({ ...values, firstName: e.target.value })}
                            onFocus={() => setOnFocus('firstName')}
                            onBlur={() => {
                                setOnFocus('');
                                manageErrors();
                            }}
                        />
                    </>

                    <>
                        <InputLabel
                            htmlFor="lastName"
                            className={
                                onFocus === 'lastName'
                                    ? 'label focus-label'
                                    : errors.lastName !== ''
                                    ? 'label error-label'
                                    : `${classes.label} label`
                            }
                        >
                            Last Name
                        </InputLabel>
                        <TextField
                            id="lastName"
                            variant="outlined"
                            size="small"
                            type="text"
                            fullWidth
                            helperText={errors.lastName}
                            error={errors.lastName !== ''}
                            value={values.lastName}
                            inputProps={{ maxLength: 30 }}
                            onChange={(e: any) => setValues({ ...values, lastName: e.target.value })}
                            onFocus={() => setOnFocus('lastName')}
                            onBlur={() => {
                                setOnFocus('');
                                manageErrors();
                            }}
                        />
                    </>

                    <>
                        <InputLabel htmlFor="email" className={`${classes.label} label`}>
                            Email
                        </InputLabel>
                        <TextField
                            id="email"
                            variant="outlined"
                            size="small"
                            type="text"
                            fullWidth
                            error={!userContext.user?.email}
                            helperText={!userContext.user?.email ? 'You will not receive any email from us.' : ''}
                            inputProps={{ maxLength: 100 }}
                            disabled={true}
                            value={
                                userContext.user?.email ||
                                'You registered with an external provider that does not provide us your e-mail.'
                            }
                        />
                        {(userContext.user?.registerType === RegisterTypesEnum.Web ||
                            userContext.user?.registerType === RegisterTypesEnum.Mobile ||
                            userContext.user?.registerType === RegisterTypesEnum.System ||
                            !userContext.user?.email) && (
                            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography id="change-password-link" onClick={() => toggleChangeEmailModal(true)}>
                                    {userContext.user?.email ? 'Change Email' : 'Set Email'}
                                </Typography>
                            </Box>
                        )}
                    </>

                    <>
                        <InputLabel
                            htmlFor="title"
                            className={onFocus === 'title' ? 'label focus-label' : `${classes.label} label`}
                        >
                            Title
                        </InputLabel>
                        <TextField
                            id="title"
                            variant="outlined"
                            size="small"
                            type="text"
                            fullWidth
                            inputProps={{ maxLength: 100 }}
                            value={values.title}
                            onChange={(e: any) => setValues({ ...values, title: e.target.value })}
                            onFocus={() => setOnFocus('title')}
                            onBlur={() => {
                                setOnFocus('');
                            }}
                        />
                    </>

                    <>
                        <InputLabel
                            htmlFor="phoneNumber"
                            className={
                                onFocus === 'phoneNumber'
                                    ? 'label focus-label'
                                    : errors.phoneNumber !== ''
                                    ? 'label error-label'
                                    : `${classes.label} label`
                            }
                        >
                            Phone Number
                        </InputLabel>
                        <InputMask
                            mask="(999) 999-9999"
                            value={values.phoneNumber}
                            onChange={(e: any) => setValues({ ...values, phoneNumber: e.target.value })}
                            disabled={false}
                            onFocus={() => setOnFocus('phoneNumber')}
                            onBlur={() => {
                                setOnFocus('');
                                manageErrors();
                            }}
                        >
                            {(inputProps: any) => (
                                <TextField
                                    {...inputProps}
                                    id="phoneNumber"
                                    variant="outlined"
                                    size="small"
                                    type="tel"
                                    fullWidth
                                    error={errors.phoneNumber !== ''}
                                    helperText={errors.phoneNumber}
                                    className={classes.formControl}
                                />
                            )}
                        </InputMask>
                    </>

                    <>
                        <InputLabel
                            htmlFor="description"
                            className={onFocus === 'description' ? 'label focus-label' : `${classes.label} label`}
                        >
                            Bio
                        </InputLabel>
                        <TextField
                            multiline
                            rows={3}
                            rowsMax={10}
                            id="description"
                            variant="outlined"
                            size="small"
                            type="text"
                            fullWidth
                            inputProps={{ maxLength: 1000 }}
                            className={classes.formControl}
                            value={values.description}
                            onChange={(e: any) => setValues({ ...values, description: e.target.value })}
                            onFocus={() => setOnFocus('description')}
                            onBlur={() => {
                                setOnFocus('');
                            }}
                        />
                    </>

                    <>
                        <InputLabel
                            htmlFor="address"
                            className={onFocus === 'address' ? 'label focus-label' : `${classes.label} label`}
                        >
                            Address
                        </InputLabel>
                        <TextField
                            id="address"
                            variant="outlined"
                            size="small"
                            type="text"
                            fullWidth
                            inputProps={{ maxLength: 150 }}
                            value={values.address}
                            onChange={(e: any) => setValues({ ...values, address: e.target.value })}
                            onFocus={() => setOnFocus('address')}
                            onBlur={() => {
                                setOnFocus('');
                            }}
                        />
                    </>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <>
                                <InputLabel
                                    htmlFor="country"
                                    className={onFocus === 'country' ? 'label focus-label' : `${classes.label} label`}
                                >
                                    Country
                                </InputLabel>
                                <Select
                                    fullWidth
                                    variant="outlined"
                                    labelId="country"
                                    id="country"
                                    className={classes.formControl}
                                    label="Country"
                                    value={values.countryId}
                                    disabled={!countries}
                                    onFocus={() => setOnFocus('country')}
                                    onBlur={() => {
                                        setOnFocus('');
                                    }}
                                    onChange={(e) => {
                                        setValues({
                                            ...values,
                                            countryId: Number(e.target.value),
                                            stateId: -1,
                                            cityId: -1,
                                        });
                                        getStates(Number(e.target.value));
                                        setCities([]);
                                    }}
                                >
                                    <MenuItem value={-1}>
                                        <em>None</em>
                                    </MenuItem>
                                    {countries.map((c) => (
                                        <MenuItem value={c.id} key={c.id}>
                                            {c.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <>
                                <InputLabel
                                    htmlFor="state"
                                    className={onFocus === 'state' ? 'label focus-label' : `${classes.label} label`}
                                >
                                    State
                                </InputLabel>
                                <Select
                                    fullWidth
                                    variant="outlined"
                                    labelId="state"
                                    id="state"
                                    className={classes.formControl}
                                    label="State"
                                    value={values.stateId}
                                    disabled={!values.countryId || !states}
                                    onFocus={() => setOnFocus('state')}
                                    onBlur={() => {
                                        setOnFocus('');
                                    }}
                                    onChange={(e) => {
                                        setValues({
                                            ...values,
                                            stateId: Number(e.target.value),
                                            cityId: -1,
                                        });
                                        getCities(Number(e.target.value));
                                    }}
                                >
                                    <MenuItem value={-1}>
                                        <em>None</em>
                                    </MenuItem>
                                    {states.map((c) => (
                                        <MenuItem value={c.id} key={c.id}>
                                            {c.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <>
                                <InputLabel
                                    htmlFor="city"
                                    className={onFocus === 'city' ? 'label focus-label' : `${classes.label} label`}
                                >
                                    City
                                </InputLabel>
                                <Select
                                    fullWidth
                                    variant="outlined"
                                    labelId="city"
                                    id="city"
                                    className={classes.formControl}
                                    label="City"
                                    value={values.cityId}
                                    disabled={!values.stateId || !cities}
                                    onFocus={() => setOnFocus('city')}
                                    onBlur={() => {
                                        setOnFocus('');
                                    }}
                                    onChange={(e) => {
                                        setValues({ ...values, cityId: Number(e.target.value) });
                                    }}
                                >
                                    <MenuItem value={-1}>
                                        <em>None</em>
                                    </MenuItem>
                                    {cities.map((c) => (
                                        <MenuItem value={c.id} key={c.id}>
                                            {c.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        </Grid>
                    </Grid>

                    <>
                        <InputLabel
                            htmlFor="organization"
                            className={onFocus === 'organization' ? 'label focus-label' : `${classes.label} label`}
                        >
                            Organization
                        </InputLabel>
                        <TextField
                            id="organization"
                            variant="outlined"
                            size="small"
                            type="text"
                            fullWidth
                            inputProps={{ maxLength: 150 }}
                            value={values.organization}
                            onChange={(e: any) => setValues({ ...values, organization: e.target.value })}
                            onFocus={() => setOnFocus('organization')}
                            onBlur={() => {
                                setOnFocus('');
                            }}
                        />
                    </>

                    <>
                        <InputLabel
                            htmlFor="website"
                            className={
                                onFocus === 'website'
                                    ? 'label focus-label'
                                    : errors.website !== ''
                                    ? 'label error-label'
                                    : `${classes.label} label`
                            }
                        >
                            Website
                        </InputLabel>
                        <TextField
                            id="website"
                            variant="outlined"
                            size="small"
                            type="text"
                            fullWidth
                            helperText={errors.website}
                            error={errors.website !== ''}
                            inputProps={{ maxLength: 150 }}
                            value={values.website}
                            onChange={(e: any) => setValues({ ...values, website: e.target.value })}
                            onFocus={() => setOnFocus('website')}
                            onBlur={() => {
                                setOnFocus('');
                                manageErrors();
                            }}
                        />
                    </>

                    <>
                        <InputLabel
                            htmlFor="currency"
                            className={onFocus === 'currency' ? 'label focus-label' : `${classes.label} label`}
                        >
                            Currency
                        </InputLabel>
                        <Select
                            fullWidth
                            variant="outlined"
                            labelId="currency"
                            id="currency"
                            className={classes.formControl}
                            label="Currency"
                            value={values.currencyId}
                            onFocus={() => setOnFocus('currency')}
                            onBlur={() => {
                                setOnFocus('');
                            }}
                            onChange={(e) => setValues({ ...values, currencyId: Number(e.target.value) })}
                        >
                            <MenuItem value={-1}>
                                <em>None</em>
                            </MenuItem>
                            {currencies.map((c) => (
                                <MenuItem value={c.id} key={c.id}>
                                    {c.code} - {c.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </>
                </Box>

                {(userContext.user?.registerType === RegisterTypesEnum.Web ||
                    userContext.user?.registerType === RegisterTypesEnum.Mobile ||
                    userContext.user?.registerType === RegisterTypesEnum.System) && (
                    <>
                        <Typography className="user-form-section-title">Settings</Typography>

                        <Box marginBottom="15px">
                            <>
                                <InputLabel htmlFor="password" className={`${classes.label} label`}>
                                    Password
                                </InputLabel>
                                <TextField
                                    id="password"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled={true}
                                    value={'●●●●●●●●●●●●●●'}
                                />
                            </>
                            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography id="change-password-link" onClick={() => toggleChangePasswordModal(true)}>
                                    Change Password
                                </Typography>
                            </Box>
                        </Box>
                    </>
                )}

                <DeleteAccountSegment />

                <ForgotPasswordModal
                    open={openChangePasswordModal}
                    handleClose={() => toggleChangePasswordModal(false)}
                    userEmail={userContext.user?.email}
                    forChangePassword={true}
                />

                <ConfirmEmailChangeModal
                    open={openChangeEmailModal}
                    handleClose={() => toggleChangeEmailModal(false)}
                    userEmail={userContext.user?.email}
                />
            </Box>
        </>
    );
};
