import React, { useContext } from "react";
import { FormControlLabel, Switch } from "@material-ui/core";

import { UserContext } from "core/context/user";

export const ThemeSwitch: React.FC = () => {

    const userContext = useContext(UserContext);

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={userContext.lightTheme}
                    onChange={() =>  userContext.setTheme(!userContext.lightTheme, true)}
                    color={"primary"}
                    name="change-theme"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            }
            style={{color: 'gray'}}
            label={userContext.lightTheme ? 'Light' : 'Dark'}
        />
    )
}
