import React, {useContext} from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Paper, Typography } from "@material-ui/core";

import CloseIcon from '@material-ui/icons/Close';
import {INotification, UserContext} from "core/context/user";
import {computeDescription} from "./helpers/compute-description";

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#383838',
        borderRadius: 2,
        borderLeft: '7px solid #FE6502',
        padding: 5,
        paddingLeft: 10,
        marginTop: 15,
        marginBottom: 15,
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
        fontSize: 17,
        fontWeight: 400,
    },
    subtitle: {
        fontSize: 12,
        fontWeight: 400,
        color: '#B2B2B2'
    },
    closeIcon: {
        width: 15,
        cursor: 'pointer',
        color: 'gray'
    }
}))

type NotificationItemProps = {
    notification: INotification;
    onClick: () => void;
}

export const NotificationItem: React.FC<NotificationItemProps> = ({notification, onClick}) => {

    const classes = useStyles();
    const userContext = useContext(UserContext);

    return (
        <Paper className={classes.root} style={userContext.lightTheme ? {backgroundColor: '#EFEFEF'} : {}}>
            <Box className={classes.titleContainer}>
                <Typography className={classes.title} color={'textPrimary'}>{notification.title}</Typography>
                {
                    !notification.isRead && <CloseIcon className={classes.closeIcon} onClick={onClick} />
                }
            </Box>
            <Typography className={classes.subtitle}>
                {computeDescription(notification)}
            </Typography>
        </Paper>
    )
}
