import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Box, ThemeOptions, Typography } from "@material-ui/core";

import { ICompletedFeedback } from "core/context/admin/expert-profile";
import { AppRoutes } from "core/helpers";

type CompletedFeedbackProps = {
    feedback: ICompletedFeedback;
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
    root: {
        backgroundColor: theme.label.main,
        borderLeft: '7px solid #FE6502',
        padding: '10px',
        borderRadius: 2,
        marginBottom: 10,
        width: '100%'
    },
    objectTitle: {
        fontSize: 15,
        color: theme.label.text,
    },
    subtitle: {
        fontSize: 14,
    },
    link: {
        color: '#029BFE'
    }
}))

export const CompletedFeedback: React.FC<CompletedFeedbackProps> = ({feedback}) => {

    const classes = useStyles();


    return (
        <Box key={feedback.artifact.id} className={classes.root}>
            <Typography variant="h5" className={classes.objectTitle}>
                {feedback.artifact.title}
            </Typography>
            <Typography variant="h5" className={classes.subtitle} color="textSecondary">
                From: <b>{feedback.collector.firstName}{' '}{feedback.collector.lastName}</b>
            </Typography>
            <Typography variant="h5" className={classes.subtitle} color="textSecondary">
                This collector requested feedback for{' '}
                <Link
                    to={`${AppRoutes.FEEDBACK_REQUEST}?id=${feedback.id}`}
                    className={classes.link}>this object
                </Link>.
            </Typography>
        </Box>
    )
}
