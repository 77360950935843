import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export const rootStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: '15px 10px 30px 15px',
        height: '72vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    content: {
        textAlign: 'center',
    },
    title: {
        fontSize: 36,
        marginBottom: 20,
    },
    subtitle: {
        fontSize: 18,
        marginBottom: 35,
        maxWidth: 430,
    },
    seeCollectors: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        textDecoration: 'underline',
    }
}))
