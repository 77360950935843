import React from "react";
import { useHistory } from 'react-router-dom';
import { Button} from "@material-ui/core";

import { authButtonsStyles } from "../styles/styles";

import { AppRoutes } from "core/helpers/app-routes";

export const LogInBtn: React.FC = () => {

    const history = useHistory();
    const classes = authButtonsStyles();

    return (
        <Button
            fullWidth
            id="log-in-header-btn"
            onClick={() => history.push(AppRoutes.SIGN_IN)}
            className={`${classes.button} ${classes.logIn}`}
        >
            Log In
        </Button>
    )
}
