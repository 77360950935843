import React from "react";
import { Box } from "@material-ui/core";

import { fullOpacityStyles } from "../styles/styles";

type FullOpacityProps = {
    fullOpacity: boolean;
}

export const FullOpacity: React.FC<FullOpacityProps> = ({fullOpacity}) => {

    const classes = fullOpacityStyles();

    return (
        <Box
            className={
                fullOpacity
                    ? `${classes.full} ${classes.root}`
                    : `${classes.partial} ${classes.root}`
            }
        />
    )
}
