import React, {useState} from "react";
import {Box, List, ListItem, Typography } from "@material-ui/core";

import {navigationListStyles} from "../styles";

import {ContactUsModal} from "../../../_others/ContactUsModal";
import {INavLink, INavList} from "../../_helpers/nav-link.interfaces";

type NavigationListProps = {
    list: INavList;
}

export const NavigationLinks: React.FC<NavigationListProps> = ({list}) => {

    const classes = navigationListStyles();

    const [displayContactUsModal, toggleContactUsModal] = useState(false);

    return (
        <Box className={classes.box}>
            <Typography className={classes.listTitle}>
                {list.title}
            </Typography>
            <List
                component="nav"
                aria-labelledby="footer navigation"
                className={classes.list}
            >
                {
                    list.list.map((link: INavLink) => (
                        <span key={link.id}>
                            {
                                link.name !== 'Contact Us' &&
                                <a
                                    key={link.id}
                                    className={classes.link}
                                    href={link.path}
                                    target="_blank"
                                >
                                    <ListItem className={classes.listItem}>
                                        {link.name}
                                    </ListItem>
                                </a>
                            }
                            {
                                link.name === 'Contact Us' &&
                                <a
                                    key={link.id}
                                    className={classes.link}
                                    onClick={() => toggleContactUsModal(true)}
                                >
                                    <ListItem className={classes.listItem}>
                                        {link.name}
                                    </ListItem>
                                </a>
                            }
                        </span>
                    ))
                }
            </List>

            <ContactUsModal
                open={displayContactUsModal}
                handleClose={() => toggleContactUsModal(false)}
            />
        </Box>
    )
}


