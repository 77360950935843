import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Box, Theme, Typography } from '@material-ui/core';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';

import { UserContext } from 'core/context/user';
import { IExpertProfile } from 'core/context/admin/expert-profile';
import { FileSize, UserRoles, RegisterTypesEnum } from 'core/enums';
import { getPhotoUrl } from 'core/helpers';

import { AccessStatusLabel } from 'components/_dashboard/_user-profiles';
import { EditUserProfileModal } from 'components/_dashboard/_user-profiles/EditUserProfileModal';
import { formatAddress } from 'core/helpers/format-address';
import { AvatarEditorModal } from '../../../../../auth/scenes/UserProfile/AvatarEditorModal';
import { ForgotPasswordModal } from '../../../../../auth/components/ForgotPassword/ForgotPasswordModal';
import { ConfirmEmailChangeModal } from '../../../../../auth/scenes/UserProfile/components/ConfirmEmailChangeModal';

type LightboxProps = {
    user: IExpertProfile;
    isMyProfile: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    detailsBox: {
        marginTop: 10,
    },
    actionsBox: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'center',
    },
    avatarPlaceholder: {
        width: 160,
        height: 160,
        color: '#FE6502',
        marginLeft: -10,
        cursor: 'pointer',
    },
    userAvatar: {
        width: 160,
        height: 160,
        borderRadius: 150,
        marginRight: 15,
        cursor: 'pointer',
    },
    title: {
        fontSize: 12,
    },
    userName: {
        fontSize: 32,
        fontWeight: 'bold',
        lineHeight: 1,
    },
    contactDetails: {
        fontSize: 14,
    },
    typeLabel: {
        backgroundColor: theme.label.main,
        fontSize: 14,
        padding: '5px 15px',
        borderRadius: 2,
        marginRight: 7,
        cursor: 'default',
    },
    editButton: {
        marginLeft: 15,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    editIcon: {
        width: 20,
        height: 20,
        marginRight: 2,
    },
    changeEmail: {
        color: theme.palette?.primary.main,
        fontSize: 13,
        cursor: 'pointer',
    },
}));

export const Lightbox: React.FC<LightboxProps> = ({ user, isMyProfile }) => {
    const userContext = useContext(UserContext);
    const classes = useStyles();
    const history = useHistory();

    const [openAvatarEditor, toggleAvatarEditor] = useState(false);
    const [displayEditProfileModal, toggleDisplayEditProfileModal] = useState(false);
    const [openChangePasswordModal, toggleChangePasswordModal] = useState(false);
    const [openChangeEmailModal, toggleChangeEmailModal] = useState(false);

    return (
        <Box className={classes.root}>
            <Box>
                {((isMyProfile && !userContext.user?.photoUrl) || (!isMyProfile && !user.photoUrl)) && (
                    <AccountCircleIcon
                        className={classes.avatarPlaceholder}
                        onClick={() => (isMyProfile ? toggleAvatarEditor(true) : null)}
                        style={!isMyProfile ? { cursor: 'default' } : {}}
                    />
                )}
                {((isMyProfile && userContext.user?.photoUrl) || (!isMyProfile && user.photoUrl)) && (
                    <img
                        src={
                            getPhotoUrl(
                                (isMyProfile && userContext.user?.photoUrl) || (!isMyProfile && user.photoUrl) || '',
                                FileSize.SM,
                            ) || ''
                        }
                        alt="avatar"
                        className={classes.userAvatar}
                        onClick={() => (isMyProfile ? toggleAvatarEditor(true) : null)}
                        style={!isMyProfile ? { cursor: 'default' } : {}}
                    />
                )}
            </Box>

            <Box className={classes.detailsBox}>
                <Typography variant="h5" className={classes.title} color="textSecondary">
                    {user.title}
                </Typography>
                <Typography variant="h5" className={classes.userName} color="textPrimary">
                    {user.firstName} {user.lastName}
                </Typography>
                <Typography className={classes.contactDetails} color="textSecondary">
                    <span style={{ marginRight: 7 }}>{user.email}</span>
                    {((isMyProfile && userContext.user?.registerType === RegisterTypesEnum.Web) ||
                        userContext.user?.registerType === RegisterTypesEnum.Mobile ||
                        userContext.user?.registerType === RegisterTypesEnum.System ||
                        !userContext.user?.email) && (
                        <>
                            <span
                                id="change-password-link"
                                className={classes.changeEmail}
                                onClick={() => toggleChangeEmailModal(true)}
                            >
                                {userContext.user?.email ? 'Change Email' : 'Set Email'}
                            </span>
                        </>
                    )}
                </Typography>
                {!user.email && (
                    <Typography className={classes.contactDetails} color="error">
                        Registered with an external provider that does not provide us the e-mail.
                        <br />
                        No email will be sent from us.
                    </Typography>
                )}
                <Typography className={classes.contactDetails} color="textSecondary">
                    {user.phoneNumber}
                </Typography>
                <Typography className={classes.contactDetails} color="secondary" style={{ marginTop: '5px' }}>
                    {formatAddress(user.country?.name || '', user.state?.name || '', user.city?.name || '')}
                </Typography>

                <Box className={classes.actionsBox}>
                    {userContext.user?.role === UserRoles.ADMIN && (
                        <AccessStatusLabel isActive={user.isActive} userId={user.userId} />
                    )}

                    <Typography
                        className={classes.editButton}
                        style={isMyProfile ? { marginLeft: 0 } : {}}
                        color="primary"
                        onClick={() => toggleDisplayEditProfileModal(true)}
                    >
                        <EditIcon className={classes.editIcon} /> Edit
                    </Typography>

                    {isMyProfile &&
                        (userContext.user?.registerType === RegisterTypesEnum.Web ||
                            userContext.user?.registerType === RegisterTypesEnum.Mobile) && (
                            <Typography
                                color={'primary'}
                                style={{ marginLeft: 15, cursor: 'pointer' }}
                                onClick={() => toggleChangePasswordModal(true)}
                            >
                                Change Password
                            </Typography>
                        )}
                </Box>

                {displayEditProfileModal && (
                    <EditUserProfileModal
                        open={displayEditProfileModal}
                        handleClose={() => toggleDisplayEditProfileModal(false)}
                        modalTitle={isMyProfile ? 'Edit your profile' : 'Edit expert’s profile'}
                        onConfirm={() => history.go(0)}
                        userId={user.id ?? user.userId}
                        data={{
                            role: UserRoles.EXPERT,
                            firstName: user.firstName || '',
                            lastName: user.lastName || '',
                            title: user.title || '',
                            phoneNumber: user.phoneNumber || '',
                            description: user.description || '',
                            address: user.address || '',
                            organization: user.organization || '',
                            expertise: user.expertise || '',
                            website: user.website || '',
                            currencyId: user.currency?.id,
                            countryId: user.country?.id,
                            stateId: user.state?.id,
                            cityId: user.city?.id,
                        }}
                    />
                )}

                <AvatarEditorModal open={openAvatarEditor} handleClose={() => toggleAvatarEditor(false)} />

                <ForgotPasswordModal
                    open={openChangePasswordModal}
                    handleClose={() => toggleChangePasswordModal(false)}
                    userEmail={userContext.user?.email}
                    forChangePassword={true}
                />

                <ConfirmEmailChangeModal
                    open={openChangeEmailModal}
                    handleClose={() => toggleChangeEmailModal(false)}
                    userEmail={userContext.user?.email}
                />
            </Box>
        </Box>
    );
};
