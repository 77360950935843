import { ObjectApi } from 'core/api';
import {PreferencesDictionary, SortObjectsOptions} from 'core/enums';

export const intersectArrays = (list1: any[], list2: any[], field: string, isUnion = false): any[] =>
    list1.filter((a) => isUnion === list2.some((b) => a[field] === b[field]));

export function cleanPhoneNumber(value: string): string {
    return value.replaceAll('(', '').replaceAll(')', '').replaceAll('_', '').replaceAll(' ', '').replaceAll('-', '');
}

export function getCurrentPageFromUrl(field: string | null = null): number {
    const params = new URLSearchParams(window.location.search);
    const pageParam = params.get(field || 'page');
    let page;
    if (!pageParam || isNaN(Number(pageParam))) {
        page = 1;
    } else {
        page = Number(pageParam);
    }

    return page;
}

export function getPageSizeFromUrl(defaultSize = 20): number {
    const params = new URLSearchParams(window.location.search);
    const pageParam = params.get('size');

    let page = defaultSize;
    if (!(!pageParam || isNaN(Number(pageParam)))) {
        page = Number(pageParam);
    }
    return page;
}

export function getSortByFromUrl(): string {
    const params = new URLSearchParams(window.location.search);
    const sortParam = params.get('sortBy');
    return sortParam || '';
}

export function getAscFromUrl(): boolean {
    const params = new URLSearchParams(window.location.search);
    const sortParam = params.get('desc');
    return sortParam !== 'true';
}

export function getJobIdFromUrl(): number {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('jobId');
    return Number(id) || -1;
}

export function splitArrayIntoChunksOfLen(arr: any[], len: number): any[] {
    // eslint-disable-next-line prefer-const
    let chunks = [],
        i = 0;
    const n = arr.length;
    while (i < n) {
        chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
}

export function isMobileBrowser(): boolean {
    let check = false;
    (function (a) {
        if (
            // eslint-disable-next-line max-len
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series([46])0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                a,
            ) || // eslint-disable-next-line max-len
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br([ev])w|bumb|bw-([nu])|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do([cp])o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly([-_])|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-([mpt])|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([ \-/])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja([tv])a|jbro|jemu|jigs|kddi|keji|kgt([ /])|klon|kpt |kwc-|kyo([ck])|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t([- ov])|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30([02])|n50([025])|n7(0([01])|10)|ne(([cm])-|on|tf|wf|wg|wt)|nok([6i])|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel([im])|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c([- ])|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                a.substr(0, 4),
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor);
    return check;
}

export async function getSortPreference(
    preferenceFor: 'objects' | 'collection',
    id = -1,
): Promise<{
    sortPreference: string;
    ascPreference: boolean;
}> {
    const sortFromUrl = getSortByFromUrl();
    const ascFromUrl = getAscFromUrl();
    try {
        const res = await ObjectApi.getObjectPreferences(id);
        const enumValue: 1 | 2 | 3 | 4 | 5 | 6 =
            preferenceFor === 'objects' ? res.data.artifactSortByPreference : res.data.collectionSortByPreference;
        const sortPreference = preferenceFor ? PreferencesDictionary[enumValue] : sortFromUrl;

        const ascEnum =
            preferenceFor === 'objects' ? res.data.artifactOrderPreference : res.data.collectionOrderPreference;

        const ascPreference = ascEnum === null ? true : ascEnum === 8;
        return {
            sortPreference:sortPreference || SortObjectsOptions.DateCreated,
            ascPreference,
        };
    } catch (error: any) {
        console.error(error);
        console.error(error.response);
        return {
            sortPreference: sortFromUrl,
            ascPreference: ascFromUrl,
        };
    }
}
