import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Box, ThemeOptions, Typography } from "@material-ui/core";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EditIcon from '@material-ui/icons/Edit';

import { ICuratorProfile } from "core/context/admin/curator-profile";
import {FileSize, UserRoles} from "core/enums";
import { getPhotoUrl } from "core/helpers";

import { AccessStatusLabel } from "components/_dashboard/_user-profiles";
import { EditUserProfileModal } from "components/_dashboard/_user-profiles/EditUserProfileModal";
import { formatAddress } from "core/helpers/format-address";


type LightboxProps = {
    user: ICuratorProfile;
    hasActiveJobs?: boolean;
}

const useStyles = makeStyles((_theme: ThemeOptions) => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    detailsBox: {
        marginTop: 10
    },
    actionsBox: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'center'
    },
    avatarPlaceholder: {
        width: 160,
        height: 160,
        color: '#FE6502',
        marginLeft: -10
    },
    userAvatar: {
        width: 160,
        height: 160,
        borderRadius: 150,
        marginRight: 15
    },
    title: {
      fontSize: 12,
    },
    userName: {
        fontSize: 32,
        fontWeight: 'bold',
        lineHeight: 1
    },
    contactDetails: {
        fontSize: 14,
    },
    editButton: {
        marginLeft: 15,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center'
    },
    editIcon: {
        width: 20,
        height: 20,
        marginRight: 2
    }
}))

export const Lightbox: React.FC<LightboxProps> = ({user, hasActiveJobs}) => {

    const classes = useStyles();
    const history = useHistory();

    const [displayEditProfileModal, toggleDisplayEditProfileModal] = useState(false);

    return (
        <Box className={classes.root}>
            <Box>
                {
                    !user.photoUrl &&
                    <AccountCircleIcon className={classes.avatarPlaceholder} />
                }
                {
                    user.photoUrl &&
                    <img
                        src={getPhotoUrl(user.photoUrl, FileSize.SM) || ''}
                        alt="avatar"
                        className={classes.userAvatar}
                    />
                }
            </Box>

            <Box className={classes.detailsBox}>
                <Typography variant="h5" className={classes.title} color="textSecondary">
                    {user.title}
                </Typography>
                <Typography variant="h5" className={classes.userName} color="textPrimary">
                    {user.firstName}{' '}{user.lastName}
                </Typography>
                <Typography className={classes.contactDetails} color="textSecondary">
                    {user.email}
                </Typography>
                {
                    !user.email &&
                    <Typography className={classes.contactDetails} color="error">
                        Registered with an external provider that does not provide us the e-mail.
                        <br/>
                        No email will be sent from us.
                    </Typography>
                }
                <Typography className={classes.contactDetails} color="textSecondary">
                    {user.phoneNumber}
                </Typography>
                <Typography className={classes.contactDetails} color="secondary" style={{marginTop: '5px'}}>
                    {
                        formatAddress(
                            user.country?.name || '',
                            user.state?.name || '',
                            user.city?.name || ''
                        )
                    }
                </Typography>

                <Box className={classes.actionsBox}>
                    <AccessStatusLabel isActive={user.isActive} userId={user.userId} hasActiveJobs={hasActiveJobs}/>

                    <Typography
                        className={classes.editButton}
                        color="primary"
                        onClick={() => toggleDisplayEditProfileModal(true)}
                    >
                        <EditIcon className={classes.editIcon} /> Edit
                    </Typography>
                </Box>

                {
                    displayEditProfileModal &&
                    <EditUserProfileModal
                        open={displayEditProfileModal}
                        handleClose={() => toggleDisplayEditProfileModal(false)}
                        modalTitle={'Edit curator’s profile'}
                        onConfirm={() => history.go(0)}
                        userId={user.userId}
                        data={{
                            role: UserRoles.CURATOR,
                            firstName: user.firstName || '',
                            lastName: user.lastName || '',
                            title: user.title || '',
                            phoneNumber: user.phoneNumber || '',
                            description: user.description || '',
                            address: user.address || '',
                            organization: user.organization || '',
                            website: user.website || '',
                            currencyId: user.currency?.id,
                            countryId: user.country?.id,
                            stateId: user.state?.id,
                            cityId: user.city?.id,
                        }}
                    />
                }

            </Box>
        </Box>
    )
}
