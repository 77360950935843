import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Dimensions } from 'components/_consumer/ObjectDetails/Dimensions';
import { Documents } from 'components/_consumer/ObjectDetails/Documents';
import { Feedbacks } from 'components/_consumer/ObjectDetails/Feedbacks';
import { SectionHeader } from 'components/_consumer/ObjectDetails/SectionHeader';
import { IArtifact, IDocument } from 'core/context/consumer/objectDetails';
import { IFeedbackResult } from 'core/context/feedback-requests/feedback';
import React, { useContext, useEffect, useState } from 'react';
import { FeedbackResultModal } from '../../../../../components/_consumer/ObjectDetails/FeedbackResultModal';
import { UserContext } from '../../../../../core/context/user';

type AboutObjectProps = {
    object: IArtifact;
};

const useStyles = makeStyles((theme) => ({
    label: {
        color: '#B2B2B2',
        fontSize: 13,
        marginTop: 5,
    },
    value: {
        color: theme.palette.text.primary,
        fontSize: 13,
        marginBottom: 5,
    },
}));

export const AboutObject: React.FC<AboutObjectProps> = ({ object }) => {
    const userContext = useContext(UserContext);
    const classes = useStyles();

    const [displayFeedbackResultModal, toggleFeedbackResultModal] = useState<IFeedbackResult | null>(null);

    const inProgressFeedback = object.feedbackRequests.find((f) => !f.isCompleted);
    const activeFeedbacksComments = inProgressFeedback ? inProgressFeedback.comments : [];

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const feedbackId = params.get('feedbackId');
        if (feedbackId) {
            const feedback = object.feedbacks.find((f) => f.id === Number(feedbackId));
            toggleFeedbackResultModal(feedback || null);
        }
    }, []);

    return (
        <>
            <SectionHeader title={'About the object'} />

            <Typography
                className="object-description"
                style={userContext.lightTheme ? { marginBottom: 15, color: 'black' } : { marginBottom: 15 }}
            >
                {object.description}
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Dimensions height={object.height} width={object.width} depth={object.depth} />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.label}>Weight</Typography>
                    <Typography className={classes.value}>
                        {object.weight ? `${object.weight} lb.` : 'unknown'}
                    </Typography>
                </Grid>
            </Grid>

            <Typography className={classes.label}>Approximate age</Typography>
            <Typography className={classes.value}>
                {object.exactProductionYear !== null && (
                    <>
                        {object.exactProductionYear < 0
                            ? `${object.exactProductionYear * -1} BC`
                            : object.exactProductionYear}
                    </>
                )}
                {object.lowerProductionYear !== null && object.upperProductionYear !== null && (
                    <>
                        {object.lowerProductionYear < 0
                            ? `${object.lowerProductionYear * -1} BC`
                            : object.lowerProductionYear}
                        {' - '}
                        {object.upperProductionYear < 0
                            ? `${object.upperProductionYear * -1} BC`
                            : object.upperProductionYear}
                    </>
                )}
                {object.lowerProductionYear === null && object.exactProductionYear === null && <>{' unknown '}</>}
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.label}>Material</Typography>
                    <Typography className={classes.value}>{object.material || 'unknown'}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.label}>Style</Typography>
                    <Typography className={classes.value}>{object.style || 'unknown'}</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.label}>Origin</Typography>
                    <Typography className={classes.value}>{object.origin || 'unknown'}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.label}>Condition</Typography>
                    <Typography className={classes.value}>{object.condition || 'unknown'}</Typography>
                </Grid>
            </Grid>

            <Typography className={classes.label}>Artist or Maker</Typography>
            <Typography className={classes.value}>{object.artist || 'unknown'}</Typography>

            {object.artistImageUrl && (
                <div>
                    <img src={object.artistImageUrl} style={{ objectFit: 'cover', width: '300px', maxWidth: '100%' }} />
                </div>
            )}

            <Typography className={classes.label}>Artist Bio</Typography>
            <Typography className={classes.value} style={{ maxHeight: 100, overflow: 'auto', marginBottom: 10 }}>
                {object.artistBio || '-'}
            </Typography>

            <Typography className={classes.label}>Scanned Documents</Typography>
            <Typography className={`${classes.value} object-description`}>{object.fileOcrContent || '-'}</Typography>

            <Box marginTop={'20px'}>
                <Documents documents={object.documents.filter((d: IDocument) => !d.deleted)} />
            </Box>

            <Box marginTop={'20px'}>
                <Feedbacks
                    feedbacks={object.feedbacks}
                    displayFeedbackRequestMessage={
                        object.feedbackRequests.findIndex((f) => !f.isCompleted) > -1
                            ? `You have 1 pending assessments. We will notify you when the
                        expert reviews your artifact.`
                            : ''
                    }
                    onSelect={(feedback) => toggleFeedbackResultModal(feedback)}
                    activeFeedbacksComments={activeFeedbacksComments}
                    activeFeedbackRequestId={inProgressFeedback ? inProgressFeedback.id : undefined}
                    artifactId={object.id}
                />
            </Box>

            {displayFeedbackResultModal !== null && (
                <FeedbackResultModal
                    open={true}
                    handleClose={() => toggleFeedbackResultModal(null)}
                    isOwnArtifact={true}
                    photo={object.photos?.find((p) => p.isPrimary)?.url || ''}
                    feedback={displayFeedbackResultModal}
                    artifactId={object.id}
                />
            )}
        </>
    );
};
