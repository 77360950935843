import { formatUSD } from "./format-currency";

export function getEstimatedDisplayValue(exactValue: number|null, min: number|null, max: number|null): string {
    if(exactValue !== null) {
        return formatUSD(exactValue);
    }

    if(min !== null && max !== null) {
        return `${formatUSD(min)} - ${formatUSD(max)}`
    }

    return 'unknown';
}
