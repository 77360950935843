export function getAuthError(errorType: string|null): string {
    switch(errorType) {
        case 'InvalidCredentials':
            return 'Oops! Looks like your credentials are invalid.';
        case 'UserNotFound':
            return 'Oops! Looks like there is no account associated with your credentials.';
        case 'WrongLoginType':
            return 'Oops! Looks like there is no account associated with your credentials.';
        case 'UserNotActive':
            return 'Your account has been created. We will notify you when the admin approves your registration.';
        case 'DuplicateEmail':
            return 'This email already exists.';
        default:
            return 'Sorry, there was an error. Please try again later.'
    }
}

export function getBlockedPlatformMessage(): string {
   return 'Thank you for joining Artifact! For the moment, this platform ' +
       'is not available for you. We will let you know as soon as you will be able to access ' +
       ' our platform.'
}
