import React from "react";
import {Box, ThemeOptions, Tooltip, Typography} from "@material-ui/core";
import {IArtifact} from "../../../../../../core/context/admin/collector-profile";
import {makeStyles} from "@material-ui/styles";
import {AppRoutes, getPhotoUrl} from "../../../../../../core/helpers";
import {FileSize} from "../../../../../../core/enums";
import imagePlaceholder from "../../../../../../assets/placeholders/artifact-placeholder.png";
import {Link} from "react-router-dom";

type ArtifactCardProps = {
    artifact: IArtifact;
    collectorId: number;
}

const useStyles = makeStyles((_theme: ThemeOptions) => ({
    root: {
        width: '32%',
        marginBottom: 10,
        marginRight: '1.3%'
    },
    img: {
        width: '100%',
        height: 180,
        objectFit: 'cover'
    },
    titleBox: {
        marginTop: -6,
    },
    title: {
        fontSize: 16,
        textTransform: 'capitalize',
        backgroundColor: '#2B2B33',
        color: 'white',
        padding: '3px 10px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}))

export const ArtifactCard: React.FC<ArtifactCardProps> = ({artifact, collectorId}) => {

    const classes = useStyles();

    return (
        <Link
            to={`${AppRoutes.COLLECTOR_ARTIFACT}?collectorId=${collectorId}&artifactId=${artifact.id}`}
            color="primary"
            className={classes.root}
        >
            <Box>
                <img
                    src={getPhotoUrl(artifact.photoUrl, FileSize.SM) || imagePlaceholder}
                    alt={artifact.title}
                    className={classes.img}
                />
                <Box className={classes.titleBox}>
                    <Tooltip
                        arrow
                        interactive={true}
                        title={artifact.title}
                        className="full-artifact-name-tooltip"
                    >
                        <Typography className={classes.title}>
                            {artifact.title}
                        </Typography>
                    </Tooltip>

                </Box>
            </Box>
        </Link>
    )
}

