import * as api from '../_utils/api';
import {getJobIdFromStorage} from "../../helpers";

export class CollectorApi {

    static async shareArtifact(artifactId: number, emails: string[], message: string): Promise<any> {
        return api._post(`share/shareArtifact/${artifactId}`, {message, emails});
    }

    static async searchArtifactsByCollection(searchInput: string, collectionId: number): Promise<any> {
        if(getJobIdFromStorage()) {
            return api._post(
                `artifact/searchByCollection/${collectionId}?userId=${getJobIdFromStorage()}`,
                searchInput,
                {'Content-Type': "application/json-patch+json"}
            )
        }

        return api._post(
            `artifact/searchByCollection/${collectionId}`,
            searchInput,
            {'Content-Type': "application/json-patch+json"}
        )
    }

    static async removeArtifactFromCollection(artifactId: number, collectionId: number): Promise<any> {
        return api._put(`collection/removeArtifact/${artifactId}/fromCollection/${collectionId}`);
    }

    static async requestFeedback(artifactId: number, message: string, paymentClientSecret: string): Promise<any> {
        return api._post(`feedback/createRequest/${artifactId}`, {message, paymentClientSecret});
    }

    static async updateRegisterPurpose(registerPurpose: string[]): Promise<any> {
        return api._put(`collectors/updateRegisterPurpose`, {registerPurpose});
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static async updateFeedbackResult(feedbackId: number, payload: any): Promise<any> {
        if(payload.rating === 0) {
            payload.rating = null;
        }
        return api._put(`feedback/updateFeedback/${feedbackId}`, payload);
    }

    static async reorderPhotos(payload: unknown): Promise<any>  {
        return api._put(`photo/changeOrder`, payload);
    }
}
