import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Fade, Modal, Typography } from '@material-ui/core';
import { modalStyles } from '../../_others/modals/modal-styles';
import { ModalHeader } from '../../_others/modals/ModalHeader';
import { assignJobModalStyles } from './style';
import { Searchbox } from '../../_dashboard/Searchbox/Searchbox';
import { JobCard } from '../JobCard';
import { useAvailableJobsData } from '../_hooks/use-available-jobs-data.hook';
import { IJob } from '../../../types';
import { Loader } from '../..';
import Pagination from '@material-ui/lab/Pagination';
import { useAssignJob } from '../_hooks/use-assign-job.hook';

type AssignJobModalProps = {
    open: boolean;
    handleClose: () => void;
    title: string;
    jobRole: string;
    userId: number;
    userRole: string;
    notAssigned?: boolean;
    onJobSelect?: (userId: number) => void;
};

export const AssignJobModal: React.FC<AssignJobModalProps> = ({
    open,
    handleClose,
    title,
    jobRole,
    userId,
    userRole,
    notAssigned = true,
    onJobSelect,
}) => {
    const classes = modalStyles();
    const assignJobModalClasses = assignJobModalStyles();

    const [searchBy, setSearchBy] = useState('');

    const { jobs, getAvailableJobs, isLoading, isEmptyList, pagination } = useAvailableJobsData(
        userId,
        jobRole,
        userRole,
        notAssigned,
    );
    const { assignJob } = useAssignJob(userId, userRole);

    useEffect(() => {
        if (!notAssigned) {
            getAvailableJobs(1, null);
        }
    }, [open]);

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={`${assignJobModalClasses.root} ${classes.paper}`}>
                    <ModalHeader title={title} handleClose={() => handleClose()} />

                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} marginTop={2}>
                        <Box className={assignJobModalClasses.searchBox}>
                            <Searchbox
                                searchBy={searchBy}
                                setSearchBy={(val: string) => setSearchBy(val)}
                                onClear={() => {
                                    if (searchBy.trim().length) {
                                        setSearchBy('');
                                        getAvailableJobs(1, '');
                                    }
                                }}
                                onSearch={(val: string) =>
                                    val && (val.length > 3 || val === '')
                                        ? getAvailableJobs(1, val)
                                        : getAvailableJobs(1, '')
                                }
                            />
                        </Box>

                        {pagination.numberOfPages > 1 && !isLoading && (
                            <Pagination
                                count={pagination.numberOfPages}
                                page={pagination.page}
                                siblingCount={1}
                                shape="rounded"
                                style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10, marginTop: 10 }}
                                onChange={(_e: any, value: number) => getAvailableJobs(value, searchBy)}
                            />
                        )}
                    </Box>

                    {!isLoading && (
                        <Box className={assignJobModalClasses.jobsContainer}>
                            {jobs.map((job: IJob) => (
                                <JobCard
                                    key={job.userId}
                                    photoUrl={job.userPhotoUrl || ''}
                                    name={job.userName}
                                    email={job.userEmail}
                                    info={job.info}
                                    actionText={!notAssigned ? 'Manage Artifacts' : 'Assign Job'}
                                    onAssign={() => {
                                        if (notAssigned) {
                                            if (confirm('Are you sure you want to assign this job?')) {
                                                assignJob(job.userId);
                                            }
                                        } else {
                                            if (onJobSelect) {
                                                onJobSelect(job.userId);
                                            }
                                        }
                                    }}
                                />
                            ))}
                        </Box>
                    )}

                    {isEmptyList && <Typography>There are no available jobs based on your search.</Typography>}

                    {isLoading && <Loader />}
                </div>
            </Fade>
        </Modal>
    );
};
