import { ObjectApi } from 'core/api/object.api';
import { UserContext } from 'core/context/user';
import { UtilsContext } from 'core/context/utils.context';
import { SortObjectsOptions, UserRoles } from 'core/enums';
import { AppRoutes, getMyObjectsRoute } from 'core/helpers';
import { usePagination } from 'core/hooks/use-pagination.hook';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IObjectCard } from 'types';
import { getNewSortingOption } from '../../_helpers/objects-sorting.helper';

export const useMyObjectsData = (): any => {
    const userContext = useContext(UserContext);
    const utilsContext = useContext(UtilsContext);
    const history = useHistory();

    const [objects, setObjects] = useState<IObjectCard[]>([]);
    const [totalValue, setTotalValue] = useState(0);
    const [isError, setHasError] = useState(false);

    const { pagination, setPagination } = usePagination();

    useEffect(() => {
        utilsContext.setAppLoaderState(true);
    }, []);

    const getObjects = (page: number, size: number, sortBy: string, asc: boolean | undefined): void => {
        utilsContext.setAppLoaderState(true); // eslint-disable-next-line max-len
        // const sortAscending: boolean | undefined = ['item', 'datecreated', 'value'].includes(sortBy.toLowerCase())
        //     ? !asc
        //     : asc;
        ObjectApi.getMyObjects(page, size, sortBy, !!asc, utilsContext.selectedJob)
            .then((res: any) => {
                setObjects(mapObjects(res.data?.data || [], userContext.user?.role, userContext.user?.id));
                setPagination({
                    ...pagination,
                    page,
                    size,
                    sortBy,
                    asc,
                    totalCount: res.data.totalCount,
                    numberOfPages: Math.ceil(res.data.totalCount / res.data.size),
                });
                setTotalValue(res.data.totalValue);
                setHasError(false);
                utilsContext.setAppLoaderState(false);
                window.scrollTo(0, 0);
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar(
                    'error',
                    'Sorry, there was an error and we were unable to display your objects.',
                );
                setObjects([]);
                setTotalValue(0);
                setHasError(true);
                utilsContext.setAppLoaderState(false);
                if (userContext.user?.role !== UserRoles.COLLECTOR) {
                    history.push(AppRoutes.ASSIGNED_COLLECTORS_INDEX);
                }
            });
    };

    const handlePageChange = (page: number): void => {
        const { sortBy, size, asc } = pagination;
        history.push(
            `${getMyObjectsRoute(
                userContext.user?.role,
            )}?page=${page}&size=${size}&sortBy=${sortBy}&desc=${!asc}&jobId=${utilsContext.selectedJob}`,
        );
        getObjects(page, size, pagination.sortBy || SortObjectsOptions.DateCreated, pagination.asc);
    };

    const handlePageSizeChange = (size: number): void => {
        const { sortBy, page, asc } = pagination;
        history.push(
            `${getMyObjectsRoute(
                userContext.user?.role,
            )}?page=${page}&size=${size}&sortBy=${sortBy}&desc=${!asc}&jobId=${utilsContext.selectedJob}`,
        );
        getObjects(page, size, pagination.sortBy || SortObjectsOptions.DateCreated, pagination.asc);
    };

    const handleSortByChange = (option: string): void => {
        const { sortBy, page, asc, size } = pagination;
        const newOption = getNewSortingOption(sortBy || '', option, asc || false);
        history.push(
            `${getMyObjectsRoute(userContext.user?.role)}?page=${1}&sortBy=${
                newOption.type
            }&desc=${!newOption.asc}&jobId=${utilsContext.selectedJob}`,
        );
        getObjects(page, size, newOption.type, newOption.asc);
    };

    return {
        objects,
        totalValue,
        pagination,
        getObjects,
        isError,
        handlePageChange,
        handlePageSizeChange,
        handleSortByChange,
    };
};

const mapObjects = (data: any, role: string | undefined, userId: number | undefined): IObjectCard[] => {
    return data.map((a: any) => {
        const canDeleteOrEdit =
            role === UserRoles.COLLECTOR || role === UserRoles.CURATOR ? true : a.createdBy === userId;

        return {
            id: a.id,
            title: a.title,
            description: a.description,
            photoUrl: a.photoUrl,
            exactValue: a.exactValue,
            maximumValue: a.maximumValue,
            minimumValue: a.minimumValue,
            hasActiveFeedbackRequest: a.hasActiveFeedbackRequest,
            isSharedToPublicFeed: a.isSharedToPublicFeed,
            canBeSharedToPublicFeed: a.isFeedbackCompleted,
            feedback: {
                exactValue: a.feedback?.exactValue,
                maximumValue: a.feedback?.maximumValue,
                minimumValue: a.feedback?.minimumValue,
            },
            displayDeleteButtonOnCard: !a.hasActiveFeedbackRequest && canDeleteOrEdit,
            displayEditButtonOnCard: canDeleteOrEdit,
            displayShareButtonOnCard: role === UserRoles.COLLECTOR || role === UserRoles.CURATOR,
        };
    });
};
