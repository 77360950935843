import React, {useContext, useEffect, useState} from "react";
import {Droppable, Draggable, DroppableStateSnapshot} from 'react-beautiful-dnd';

import { makeStyles } from "@material-ui/styles";
import { Box, Button, ThemeOptions, Typography } from "@material-ui/core";

import AddIcon from '@material-ui/icons/Add';

import { ITaxonomyLabel, TaxonomyContext } from "core/context/admin/taxonomy";

import { Label } from "./Label";
import { AddNewLabelModal } from "./AddLabelModal";
import { EditLabelModal } from "./EditLabelModal";
import {ActivateLabelModal} from "./ActivateLabelModal";

type SubTypesProps = {
    id: number;
    parent: ITaxonomyLabel;
    types: ITaxonomyLabel[];
    onRefresh: () => void;
    onTypeClick: (type: ITaxonomyLabel) => void;
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
    divider: {
        borderTop: '1px solid',
        borderColor: theme.divider.main,
        width: '100%',
        marginTop: 20,
        marginBottom: 15,
    }
}))

export const SubTypes: React.FC<SubTypesProps> = (
    {id,parent, types, onRefresh, onTypeClick}
) => {

    const classes = useStyles();
    const taxonomyContext = useContext(TaxonomyContext);

    const [selectedType, setSelectedType] = useState<ITaxonomyLabel|null>(null);
    const [editLabel, setEditLabel] = useState<ITaxonomyLabel|null>(null);
    const [activateLabel, setActivateLabel] = useState<ITaxonomyLabel|null>(null);

    useEffect(() => {
        setSelectedType(null);
    }, [parent.id])

    return (
        <>
            <div className={classes.divider} />
            <Box style={{display: 'flex', alignItems: 'center'}}>
                <Typography color="textPrimary" style={{fontSize: 18}}>
                    Types of "{parent.name}"
                </Typography>

                <Button
                    id="add-subcategory-btn"
                    variant="outlined"
                    color="primary"
                    size={"small"}
                    style={{marginLeft: 10, padding: '0px 10px'}}
                    onClick={() => taxonomyContext.toggleAddLabelModal(parent.id)}
                >
                    <AddIcon style={{width: 17}}/> Add subcategory
                </Button>
            </Box>

            {
                types?.length === 0 &&
                <Typography color="textSecondary" style={{fontSize: 13}}>
                    There are no subtypes.
                </Typography>
            }

            <Droppable droppableId={id.toString()} >
                {(provided, snapshot: DroppableStateSnapshot) => (
                    <div style={
                        snapshot.isDraggingOver
                            ? {marginTop: 10, display: 'flex', flexWrap: 'wrap', paddingBottom: 40}
                            : {marginTop: 10, display: 'flex', flexWrap: 'wrap'}
                    }
                         ref={provided.innerRef}
                    >
                        {
                            types
                                .filter(l =>
                                    taxonomyContext.deletedLabels.findIndex(id => id === l.id) === -1 &&
                                    l.parentId === parent.id
                                )
                                .map((label: ITaxonomyLabel, index: number) => (
                                    <Draggable
                                        key={label.id}
                                        draggableId={label.id.toString()}
                                        index={index}
                                        isDragDisabled={label.id === selectedType?.id}
                                    >
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                    <Label
                                                        key={label.id}
                                                        label={label}
                                                        isSelected={selectedType?.id === label.id}
                                                        hasActions={true}
                                                        onClick={() => {onTypeClick(label); setSelectedType(label)}}
                                                        onDelete={
                                                            () => taxonomyContext.setSelectedForDeleteId(label.id)
                                                        }
                                                        onEdit={() => setEditLabel(label)}
                                                        onActivate={() => setActivateLabel(label)}
                                                    />
                                            </div>

                                        )}
                                    </Draggable>
                                ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>

            {
                selectedType &&
                taxonomyContext.deletedLabels.findIndex(id => id === selectedType?.id) === -1 &&
                <Box>
                    <SubTypes
                        id={selectedType.id}
                        parent={selectedType}
                        types={types.filter(l => l.parentId === selectedType.id)}
                        onRefresh={() => onTypeClick(selectedType)}
                        onTypeClick={() => onTypeClick(selectedType)}
                    />
                </Box>
            }

            <AddNewLabelModal
                open={parent.id === taxonomyContext.displayAddLabelModal}
                parentId={parent.id}
                handleClose={() => taxonomyContext.toggleAddLabelModal(-1)}
                onSuccess={() => onRefresh()}
            />

            <EditLabelModal
                open={editLabel !== null}
                label={
                    editLabel ||
                    {id: -1, parentId: -1, name: 'unknown', type: 'unknown', isActive: false, isDeleted: false}
                }
                handleClose={() => setEditLabel(null)}
                onSuccess={(id: number, name: string) => {
                    onRefresh();
                    if(selectedType?.id === id) {
                        selectedType.name = name;
                    }
                    setEditLabel(null);
                }}
            />

            <ActivateLabelModal
                open={activateLabel !== null}
                label={
                    activateLabel ||
                    {id: -1, parentId: -1, name: 'unknown', type: 'unknown', isActive: false, isDeleted: false}
                }
                handleClose={() => setActivateLabel(null)}
                onSuccess={(id: number) => {
                    onRefresh();
                    if(selectedType?.id === id) {
                        selectedType.isActive = true;
                    }
                    setActivateLabel(null);
                }}
            />
        </>
    )
}
