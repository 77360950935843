import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import { UserContext } from 'core/context/user';
import { IObjectComment } from 'types';
import { ObjectCommentsApi } from 'core/api/object-comments.api';

import { SectionHeader } from 'components/_consumer/ObjectDetails';
import { ConfirmDeleteModal } from 'components/_others/ConfirmDeleteModal';
import { CommentDetails } from './CommentDetails';
import { AddComment } from './AddComment';

type CommentsProps = {
    objectId: number;
    userIsObjectOwner: boolean;
};

export const ObjectComments: React.FC<CommentsProps> = ({ objectId, userIsObjectOwner }) => {
    const classes = useStyles();
    const userContext = useContext(UserContext);

    const [comments, setComments] = useState<IObjectComment[]>([]);

    const [commentIdToBeDeleted, setCommentIdToBeDeleted] = useState(-1);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    useEffect(() => {
        getObjectComments();
    }, []);

    const getObjectComments = (): void => {
        ObjectCommentsApi.getObjectComments(objectId)
            .then((res: any) => {
                setComments(res?.data?.data || []);
            })
            .catch((error: any) => {
                console.error(error);
            });
    };

    const addComment = (comment: string): void => {
        ObjectCommentsApi.addComment(objectId, comment)
            .then((res: any) => {
                const result = comments.slice();
                result.unshift(res?.data || {});
                setComments(result);
            })
            .catch((error: any) => {
                console.error(error);
                //TODO: snackbar
            });
    };

    const editComment = (commentId: number, comment: string): void => {
        const commentIndex = comments.findIndex((c) => c.id === commentId);
        const initialText = comments[commentIndex].text;

        ObjectCommentsApi.editComment(commentId, comment)
            .then(() => {
                const result = comments;
                result[commentIndex].text = comment;
                setComments(result.slice());
            })
            .catch((error: any) => {
                console.error(error);
                const result = comments;
                result[commentIndex].text = initialText;
                setComments(result.slice());
                //TODO: snackbar
            });
    };

    const deleteComment = (): void => {
        setIsLoadingDelete(true);

        ObjectCommentsApi.deleteComment(commentIdToBeDeleted)
            .then(() => {
                const commentIndex = comments.findIndex((c) => c.id === commentIdToBeDeleted);
                const result = comments;
                result.splice(commentIndex, 1);
                setComments(result.slice());
                setIsLoadingDelete(false);
                setCommentIdToBeDeleted(-1);
            })
            .catch((error: any) => {
                console.error(error);
                //TODO: snackbar
            });
    };

    return (
        <Box className={classes.root}>
            <SectionHeader title={'Comments'} />

            <Box className={classes.commentsContainer}>
                {comments?.map((comment: IObjectComment) => (
                    <CommentDetails
                        key={comment.id}
                        comment={comment}
                        canDelete={!userIsObjectOwner ? userContext.user?.id === comment.createdBy : true}
                        canEdit={userContext.user?.id === comment.createdBy}
                        onDelete={() => setCommentIdToBeDeleted(comment.id)}
                        onEdit={(text: string) => editComment(comment.id, text)}
                    />
                ))}
            </Box>

            {comments?.length === 0 && <Typography className={classes.emptyMessage}>There are no comments.</Typography>}

            <AddComment onAddComment={addComment} />

            {commentIdToBeDeleted !== -1 && (
                <ConfirmDeleteModal
                    open={true}
                    subtitle="Are you sure you want to delete this comment?"
                    isLoading={isLoadingDelete}
                    handleClose={() => setCommentIdToBeDeleted(-1)}
                    onConfirm={() => deleteComment()}
                />
            )}
        </Box>
    );
};

const useStyles = makeStyles({
    root: {
        marginBottom: 10,
    },
    emptyMessage: {
        fontSize: 13,
        marginBottom: 10,
    },
    commentsContainer: {
        marginTop: 15,
        overflow: 'auto',
        ['@media (max-height:1366px)']: { maxHeight: 250 },
        ['@media (max-height:800px)']: { maxHeight: 107 },
    },
});
