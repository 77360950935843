import React from "react";
import {IFeedbackComment} from "core/context/feedback-requests/feedback";
import {Box, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

import DeleteIcon from '@material-ui/icons/Delete';
import moment from "moment";
import {FeedbackRequestsApi} from "../../../../../../../core/api/feedback-requests/feedback-requests.api";


type CommentsProps = {
    isAdmin: boolean;
    feedbackRequestId: number;
    isFeedbackCompleted: boolean;
    comments: IFeedbackComment[];
    onDeleteComment: (id: number) => void;
}

export const Comments: React.FC<CommentsProps> = (
    {feedbackRequestId, comments, isFeedbackCompleted, onDeleteComment, isAdmin}
) => {

    const classes = useStyles();

    const deleteComment = (id: number): void => {

        if(!confirm('Are you sure you want to delete this comment?')) {
            return;
        }

        FeedbackRequestsApi.deleteComment(feedbackRequestId, id)
            .then(() => {
                onDeleteComment(id);
            })
            .catch((error: any) => {
                console.error(error.response);
            })
    }

    return (
        <Box>
            {
                comments.map(comment => (
                    <Box key={comment.id}>
                        {
                            (comment.isExpert || (isAdmin && comment.isExpert)) &&
                                <Box className={classes.expertBubbleContainer}>
                                    {
                                        !isFeedbackCompleted &&
                                            <>
                                                {
                                                    comment.isExpert &&
                                                    <DeleteIcon
                                                        className={classes.deleteIcon}
                                                        onClick={() => deleteComment(comment.id)}
                                                    />
                                                }

                                                <span className={classes.date}>
                                                    {moment(comment.dateCreated).format('MM/DD/YY HH:mm')}
                                                </span>
                                            </>
                                    }
                                    <Box className={classes.expertBubble}>
                                        <pre className={classes.pre}>{comment.text}</pre>
                                    </Box>
                                </Box>
                        }

                        {
                            !comment.isExpert &&
                            <Box style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                <Box className={classes.collectorBubble}>
                                    {comment.text}
                                </Box>
                                <span className={classes.date} style={{marginBottom: 0, marginLeft: 5}}>
                                    {moment(comment.dateCreated).format('MM/DD/YY HH:mm')}
                                </span>
                            </Box>

                        }
                    </Box>
                ))
            }
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    expertBubbleContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        "&:hover": {
            '& $deleteIcon': {
                visibility: 'visible',
            }
        }
    },
    deleteIcon: {
        color: '#d0d0d0',
        cursor: 'pointer',
        width: 25,
        height: 20,
        marginBottom: 7,
        visibility: 'hidden',
    },
    expertBubble: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: 20,
        textAlign: 'left',
        margin: '4px 0',
        padding: '5px 15px',
        width: 'fit-content',
        maxWidth: '80%',
        lineHeight: 1.3,
    },
    collectorBubble: {
        backgroundColor: theme.label.main,
        color: theme.label.text,
        borderRadius: 20,
        textAlign: 'right',
        margin: '4px 0',
        padding: '5px 15px',
        width: 'fit-content',
        maxWidth: '80%',
        lineHeight: 1.3
    },
    date: {
        fontSize: 11,
        marginBottom: 9,
        marginRight: 5,
    },
    pre: {
        fontFamily: 'inherit',
        whiteSpace: 'break-spaces',
    }
}))
