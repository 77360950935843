import React, {useContext} from "react";
import { Link, useHistory } from "react-router-dom";
import { Box, ClickAwayListener, Tooltip, Typography, Zoom } from "@material-ui/core";
import "../styles/user-menu.less"

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PersonIcon from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { UserContext } from "core/context/user";
import { clearLocalStorage } from "core/helpers/storage-helper";
import { AppRoutes } from "core/helpers/app-routes";
import { getPhotoUrl } from "core/helpers/file-size.helper";
import { FileSize } from "core/enums";
import {UtilsContext} from "../../../../core/context/utils.context";


export const UserAvatar: React.FC = () => {

    const userContext = useContext(UserContext);

    const [open, setOpen] = React.useState(false);

    return (
        <>
            <div style={{paddingRight: 24, paddingLeft: 18, display: 'flex'}} className="hide-on-mobile">

                <Link
                    to={AppRoutes.NOTIFICATIONS}
                    className="notification-icon"
                    style={{display: 'flex', alignItems: 'center'}}
                >
                    <NotificationsIcon />
                    {
                        userContext.newNotifications && <span className="new-notifications" />
                    }
                </Link>

                <ClickAwayListener onClickAway={() => open ? setOpen(false) : null}>
                    <Tooltip
                        arrow
                        interactive
                        TransitionComponent={Zoom}
                        title={<UserMenu />}
                        onClose={() => setOpen(false)}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        placement={'bottom-end'}
                        id="user-menu-tooltip"
                    >
                        {
                            !userContext.user?.photoUrl ?
                                <AccountCircleIcon
                                    onClick={() => setOpen(!open)}
                                    style={{width: 40, height: 40, color: '#FE6502', cursor: 'pointer'}}
                                /> : <img
                                    onClick={() => setOpen(!open)}
                                    src={getPhotoUrl(userContext.user.photoUrl, FileSize.SM) || ''}
                                    alt="avatar"
                                    style={{width: 40, height: 40, borderRadius: 150, cursor: 'pointer'}}
                                />
                        }
                    </Tooltip>
                </ClickAwayListener>
            </div>
        </>

    )
}

const UserMenu: React.FC = () => {

    const userContext = useContext(UserContext);
    const utilsContext = useContext(UtilsContext);
    const history = useHistory();

    const onLogout = (): void => {
        clearLocalStorage();
        userContext.onLogout();
        utilsContext.selectJob(-1);
        history.push(AppRoutes.SIGN_IN);
    }

    return (
        <Box className="user-menu-popover">
            <Box display={'flex'} alignItems={'center'}>
                {
                    !userContext.user?.photoUrl &&
                    <AccountCircleIcon
                        style={{width: 50, height: 50, color: '#FE6502', cursor: 'pointer', marginRight: 10}}
                    />
                }

                {
                    userContext.user?.photoUrl &&
                    <img
                        src={getPhotoUrl(userContext.user.photoUrl, FileSize.SM) || ''}
                        alt="avatar"
                        style={{width: 50, height: 50, borderRadius: 150, cursor: 'pointer', marginRight: 10}}
                    />
                }
                <Typography className="user-name">
                    {userContext.user?.firstName}{' '}{userContext.user?.lastName}
                </Typography>
            </Box>

            <Box className="menu-items">
                <Link to={AppRoutes.COLLECTOR_PROFILE}>
                    <Typography className="menu-item">
                        <PersonIcon /> <span>Edit Profile</span>
                    </Typography>
                </Link>

                <div className="divider"/>

                <Typography className="menu-item" onClick={() => onLogout()}>
                    <ExitToAppIcon /> <span>Log out</span>
                </Typography>
            </Box>
        </Box>
    )
}
