import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CloseIcon from '@material-ui/icons/Close';

import { IDocument } from "core/context/consumer/objectDetails";

type SelectedDocumentsProps = {
    initialDocuments: IDocument[];
    documents: File[];
    onRemove: (index: number) => void;
    onDelete?: (id: number) => void;
}

const useStyles = makeStyles({
    container: {
        display: 'inline-block',
        overflow: 'auto'
    },
    fileContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 15,
        marginRight: 10,
        "&:last-child": {
            marginRight: 0
        }
    },
    fileIcon: {
        fontSize: 18,
    },
    fileName: {
        fontSize: 14,
    },
    removeContainer: {
        backgroundColor: '#707070',
        marginLeft: 8,
        display: 'flex',
        alignItems: 'center',
        width: 16,
        height: 16,
        cursor: 'pointer'
    },
    closeIcon: {
        fontSize: 13,
        margin: 'auto'
    }
})

export const SelectedDocuments: React.FC<SelectedDocumentsProps> = (
    {initialDocuments, documents, onRemove, onDelete}
) => {

    const classes = useStyles();

    return (
        <Box className={classes.container}>
            {
                initialDocuments.filter((document: IDocument) => !document.deleted)
                    .map((document: IDocument, index: number) => (
                        <Box key={document.id} className={classes.fileContainer}>
                            <InsertDriveFileIcon className={classes.fileIcon} />
                            <Typography className={classes.fileName}>{document.fileName}</Typography>
                            <span
                                className={classes.removeContainer}
                                onClick={() => onRemove(index)}
                            >
                                <CloseIcon
                                    className={classes.closeIcon}
                                    onClick={() => onDelete ? onDelete(document.id) : {}}
                                />
                            </span>
                        </Box>
                ))
            }
            {
                documents.map((file: File, index: number) => (
                    <Box key={index} className={classes.fileContainer}>
                        <InsertDriveFileIcon className={classes.fileIcon} />
                        <Typography className={classes.fileName}>{file.name}</Typography>
                        <span
                            className={classes.removeContainer}
                            onClick={() => onRemove(index)}
                        >
                            <CloseIcon className={classes.closeIcon} />
                        </span>
                    </Box>
                ))
            }
        </Box>
    )
}
