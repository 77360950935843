import { Box, FormControl, MenuItem, Select, Theme } from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { makeStyles } from '@material-ui/styles';
import { SortingOption } from 'core/enums';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    icon: {
        cursor: 'pointer',
    },
}));

type SortByProps = {
    options: SortingOption[];
    onSelect: (option: string) => void;
    sortByValue: string;
};

export const SortBy: React.FC<SortByProps> = ({ options, onSelect, sortByValue }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(sortByValue);

    const handleChange = (event: any): void => {
        setValue(event.target.value);
        onSelect(event.target.value);
    };

    return (
        <Box className={classes.root}>
            Sort By
            <FormControl variant="outlined" className={classes.formControl}>
                <Select id="sort-by" value={value} label={'Sort By'} onChange={handleChange}>
                    {options.map((option) => (
                        <MenuItem key={option.type} value={option.type}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <ImportExportIcon onClick={() => onSelect(value || '')} className={classes.icon} />
        </Box>
    );
};
