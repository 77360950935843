import {useContext, useState} from "react";
import {usePagination} from "core/hooks/use-pagination.hook";
import {UtilsContext} from "core/context/utils.context";
import {IJob} from "../../../types";
import {JobsApi} from "core/api/jobs.api";
import {UserRoles} from "core/enums";

export const useJobsData = (role: string, userId: number): any => {

    const utilsContext = useContext(UtilsContext);

    const [jobs, setJobs] = useState<IJob[]>([]);
    const [totalJobs, setTotalJobs] = useState({totalJobs: 0, totalActiveJobs: 0});
    const [isLoading, setIsLoading] = useState(false);
    const [isEmptyList, setIsEmptyList] = useState(false);

    const {pagination, setPagination} = usePagination();

    const getActiveJobs = (page: number, history = false): void => {
        setIsLoading(true);
        setIsEmptyList(false);

        const body = {
            userId,
            old: history,
            notAssigned: false,
        }

        JobsApi.getJobs(10, page, role, body)
            .then((res: any) => {
                setTotalJobs({totalJobs: res?.data?.totalJobs, totalActiveJobs: res?.data.totalActiveJobs})
                setJobs(mapData(res?.data?.data || [], UserRoles.COLLECTOR, history));
                setPagination({
                    ...pagination,
                    page,
                    size: 10,
                    totalCount: res.data.totalCount,
                    numberOfPages: Math.ceil(res.data.totalCount/res.data.size)
                });
                setIsLoading(false);
                setIsEmptyList(res?.data?.totalJob === 0 && res?.data.totalActiveJobs === 0);
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar(
                    'error',
                    'Sorry, there was an error and we were unable to display the available jobs based on your search.'
                );
            })
    }

    return {
        jobs,
        totalJobs,
        getActiveJobs,
        isLoading,
        isEmptyList,
        pagination
    }
}

const mapData = (data: any, role: string, isOld: boolean): IJob[] => {
    return data.map((j: any) => {
        return {
            userPhotoUrl: j.photoUrl,
            userName: `${j.firstName || ''  } ${  j.lastName || ''}`,
            userEmail: j.email,
            userId: j.userId,
            info: getInfoText(j, role, isOld)
        }
    })
}

const getInfoText = (job: any, role: string, isOld: boolean): string => {
    switch(role) {
        case UserRoles.COLLECTOR:
            return isOld
                ? `Assigned ${job.totalAssigned} times`
                : `${job.totalObjects} ${job.totalObjects === 1 ? 'collector object':'collector objects'}`
        default:
            return '';
    }
}
