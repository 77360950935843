import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import './gallery.less';

import { IPhoto } from 'core/context/consumer/objectDetails';
import { getPhotoUrl } from 'core/helpers/file-size.helper';
import { FileSize } from 'core/enums';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { PhotoLightbox } from './PhotoLightbox';
import { UserContext } from '../../../../core/context/user';
import { makeStyles } from '@material-ui/core/styles';
import { ConfirmDeleteModal } from '../../../_others';
import { ObjectApi } from '../../../../core/api/consumer';
import { CropPhotoModal } from '../../../../scenes/consumer/objectDetails/components/CropPhotoModal';
import { UtilsContext } from '../../../../core/context/utils.context';

type GalleryProps = {
    photos: IPhoto[];
    isMyObject?: boolean;
    displayDeleteButton?: boolean;
    artifactId?: number;
    maxHeight?: number;
    onPhotoDeleteSuccess?: (id: number) => void;
};

const useStyles = makeStyles({
    remove: {
        width: 15,
        height: 17,
        fontSize: 12,
        cursor: 'pointer',
        marginRight: 25,
    },
    crop: {
        width: 15,
        height: 17,
        fontSize: 12,
        cursor: 'pointer',
        marginRight: 10,
    },
    actionsBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
});

export const Gallery: React.FC<GalleryProps> = ({
    photos,
    isMyObject,
    displayDeleteButton,
    artifactId,
    maxHeight,
    onPhotoDeleteSuccess,
}) => {
    const classes = useStyles();

    const primaryPhoto = photos?.length ? photos.find((p: IPhoto) => p.isPrimary) : null;
    const userContext = useContext(UserContext);
    const utilsContext = useContext(UtilsContext);

    const [currentPhoto, setCurrentPhoto] = useState<IPhoto | null>(primaryPhoto || null);
    const [carouselWidth, setCarouselWidth] = useState(0);
    const [lightbox, setLightbox] = useState<number>(-1);
    const [displayConfirmDeleteModal, toggleConfirmDeleteModal] = useState(-1);
    const [photoOnCrop, setPhotoOnCrop] = useState<IPhoto | null>(null);

    useEffect(() => {
        const currentPhotoContainer = document.getElementById('current-photo-container');
        setCarouselWidth(currentPhotoContainer?.clientWidth || 0);
        function handleWindowResize(): void {
            const currentPhotoContainer = document.getElementById('current-photo-container');
            setCarouselWidth(currentPhotoContainer?.clientWidth || 0);
        }
        window.addEventListener('resize', handleWindowResize);
    }, []);

    useEffect(() => {
        let primaryPhoto = photos?.length
            ? photos.filter((p) => p.deleted !== true).find((p: IPhoto) => p.isPrimary)
            : null;
        if (!primaryPhoto && photos?.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            primaryPhoto = photos.filter((p) => p.deleted !== true)[0];
        }
        setCurrentPhoto(primaryPhoto || null);
    }, [photos]);

    const prevPhoto = (photo: IPhoto | null): void => {
        const currentPhotoIndex = photos.findIndex((p: IPhoto) => p.id === photo?.id);
        if (currentPhotoIndex === 0) {
            return;
        }

        setCurrentPhoto(photos[currentPhotoIndex - 1]);
        scrollCarousel(-50);
    };

    const nextPhoto = (photo: IPhoto | null): void => {
        const currentPhotoIndex = photos.findIndex((p: IPhoto) => p.id === photo?.id);
        if (currentPhotoIndex === photos.length - 1) {
            return;
        }
        setCurrentPhoto(photos[currentPhotoIndex + 1]);
        scrollCarousel(50);
    };

    const scrollCarousel = (x: number): void => {
        const carousel = document.getElementById('carousel');
        if (!carousel) {
            return;
        }
        carousel.scrollBy(x, 0);
    };

    const selectCurrentPhoto = (photo: IPhoto): void => {
        setCurrentPhoto(photo);
        const currentPhotoIndex = photos.findIndex((p: IPhoto) => p.id === photo.id);

        if (currentPhotoIndex === photos.length - 1) {
            scrollCarousel(100);
            return;
        }

        if (currentPhotoIndex === 0) {
            scrollCarousel(-100);
            return;
        }
        if (currentPhotoIndex < photos.length / 2) {
            scrollCarousel(-50);
            return;
        }
        scrollCarousel(50);
    };

    const onRemove = (photoId: number): void => {
        if (!artifactId) {
            return;
        }
        toggleConfirmDeleteModal(photoId);
    };

    const onCrop = (photo: IPhoto): void => {
        setPhotoOnCrop(photo);
    };

    const onPhotoDelete = (photoId: number): void => {
        if (!artifactId) {
            return;
        }

        ObjectApi.deletePhoto([photoId])
            .then(() => {
                const photo = photos.find((p) => p.id === photoId);
                if (photo) {
                    photo.wasDeleted = true;
                }
                toggleConfirmDeleteModal(-1);
                setPhotoOnCrop(null);
                if (onPhotoDeleteSuccess) {
                    onPhotoDeleteSuccess(photoId);
                }
            })
            .catch((error: any) => {
                console.error(error.response);
                toggleConfirmDeleteModal(-1);
                utilsContext.addSnackbar('error');
            });
    };

    return (
        <Box className="artifact-gallery">
            <Box
                className="current-photo"
                id="current-photo-container"
                style={
                    userContext.lightTheme
                        ? { backgroundColor: '#EFEFEF', maxHeight: maxHeight ? maxHeight : 'unset' }
                        : { maxHeight: maxHeight ? maxHeight : 'unset' }
                }
            >
                {currentPhoto && (
                    <>
                        <img
                            src={getPhotoUrl(currentPhoto?.url, FileSize.MD) || ''}
                            alt={''}
                            onClick={() => setLightbox(photos.findIndex((p: IPhoto) => p.id === currentPhoto?.id))}
                            style={maxHeight ? { cursor: 'move', maxHeight } : { cursor: 'move' }}
                        />
                    </>
                )}
                {!currentPhoto && <Typography className="no-photo-text">There are no photos</Typography>}
            </Box>
            {photos?.length > 0 && (
                <Box className="carousel" id="carousel" style={maxHeight ? { maxHeight } : {}}>
                    {photos.length > 1 && (
                        <Box className="left-arrow" onClick={() => prevPhoto(currentPhoto)}>
                            <NavigateBeforeIcon />
                        </Box>
                    )}
                    {photos
                        .filter((p: IPhoto) => !p.deleted && !p.wasDeleted)
                        .map((photo: IPhoto) => (
                            <Box
                                id={`${photo.id}_carousel`}
                                key={photo.id}
                                className={`carousel-img
                                         ${currentPhoto?.id === photo.id && !isMyObject ? 'selected-photo' : ''}
                                         ${isMyObject ? 'thumbnail-action' : ''}`}
                            >
                                <img
                                    src={getPhotoUrl(photo.url, FileSize.SM) || ''}
                                    alt={''}
                                    onClick={() => selectCurrentPhoto(photo)}
                                    style={
                                        photo?.isPrimary
                                            ? { border: '2px solid #d04c19', cursor: 'pointer' }
                                            : { cursor: 'pointer' }
                                    }
                                    className={`${currentPhoto?.id === photo.id && isMyObject ? 'selected-photo' : ''}`}
                                />
                                {isMyObject && (
                                    <Box
                                        className={`${classes.actionsBox} thumbnail-action-btn`}
                                        style={!displayDeleteButton ? { justifyContent: 'center' } : {}}
                                    >
                                        {
                                            <Box className={classes.crop} onClick={() => onCrop(photo)}>
                                                Edit
                                            </Box>
                                        }
                                        {displayDeleteButton && (
                                            <Box className={classes.remove} onClick={() => onRemove(photo.id)}>
                                                Remove
                                            </Box>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        ))}
                    {photos.length > 1 && (
                        <Box
                            className="right-arrow"
                            style={{ left: `${carouselWidth + 11}px` }}
                            onClick={() => nextPhoto(currentPhoto)}
                        >
                            <NavigateNextIcon />
                        </Box>
                    )}
                </Box>
            )}

            <ConfirmDeleteModal
                open={displayConfirmDeleteModal !== -1}
                isLoading={false}
                subtitle={'Are you sure you want to delete this photo?'}
                handleClose={() => toggleConfirmDeleteModal(-1)}
                onConfirm={() => onPhotoDelete(displayConfirmDeleteModal)}
            />

            {photoOnCrop && artifactId && (
                <CropPhotoModal
                    artifactId={artifactId}
                    open={true}
                    handleClose={() => setPhotoOnCrop(null)}
                    photo={photoOnCrop}
                />
            )}

            <PhotoLightbox
                open={lightbox !== -1}
                index={lightbox}
                photos={photos || []}
                handleClose={() => setLightbox(-1)}
            />
        </Box>
    );
};
