export function formatAddress(country: string, state: string, city: string): string {
    const result = [];
    if(country) {
        result.push(country);
    }
    if(state) {
        result.push(state);
    }
    if(city) {
        result.push(city)
    }

    return result.length > 0 ? result.join(', ') : 'unknown address';
}
