import * as api from '../_utils/api';
import { IConfirmRegistrationEmail, IRefreshToken, IResetPassword } from './user.interfaces';
import { base64ToFile } from 'core/helpers/upload-files-helper';

export class UserApi {
    static async getUser(): Promise<any> {
        return api._get(`users/userDetails?full=true`);
    }

    static async updateUserProfile(payload: unknown, userId: number): Promise<any> {
        return api._put(`users/profile/updateUserProfile?userId=${userId}`, payload);
    }

    static async login(payload: unknown): Promise<any> {
        return api._post(`users/login`, payload);
    }

    static async revokeRefreshToken(payload: IRefreshToken): Promise<any> {
        return api._put(`users/revokeToken`, payload);
    }

    static async logout(): Promise<any> {
        return api._post(`users/logout`);
    }

    static async resendRegisterConfirmationEmail(email: string): Promise<any> {
        return api._post(`users/register/resendConfirmationEmail/${email}`);
    }

    static async confirmRegistrationEmail(payload: IConfirmRegistrationEmail): Promise<any> {
        return api._put(`users/register/confirmEmail`, payload);
    }

    static async sendForgotPasswordEmail(email: string): Promise<any> {
        return api._post(`users/sendForgotPasswordEmail/${email}`);
    }

    static async resetPassword(payload: IResetPassword): Promise<any> {
        return api._put(`users/resetPassword`, payload);
    }

    static async changeAvatar(avatar: string): Promise<any> {
        const formData = new FormData();
        formData.append('photo', base64ToFile(avatar, `avatar.png`));
        return api._put(`users/profile/changeAvatar`, formData);
    }

    static async sendConfirmChangeEmail(email: string, isCurrentEmail: boolean): Promise<any> {
        return api._post(`users/profile/sendConfirmationEmail/${email}?old=${isCurrentEmail}`);
    }

    static async changeEmail(payload: unknown): Promise<any> {
        return api._post(`users/profile/validateEmail`, payload);
    }

    static async setNewEmail(payload: unknown): Promise<any> {
        return api._put(`users/profile/changeEmail`, payload);
    }

    static async sendDeleteAccountEmail(): Promise<any> {
        return api._post('users/delete/sendEmail');
    }

    static async deleteAccount(code: string): Promise<any> {
        return api._delete(`users/DeleteAccount?code=${code}`);
    }
}
