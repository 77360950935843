import {makeStyles} from "@material-ui/core/styles";

export const takePhotoStyles = makeStyles({
    root: {
        textAlign: 'center',
        marginTop: '3%',
        ["@media (max-width: 1000px) and (orientation: portrait)"]: { marginTop: '15%'},
    },
    title: {
        fontSize: 22,
        marginBottom: '2%'
    },
    subtitle: {
        color: '#B2B2B2',
        fontSize: 14,
        marginBottom: '2%'
    },
    button: {
        marginBottom: '3%'
    }
})
