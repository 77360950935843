import React, {useContext, useEffect, useState} from "react";
import { modalStyles } from "components/_others/modals/modal-styles";
import { Backdrop, Box, Button, CircularProgress, Fade, Modal, Typography } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

import { TaxonomyApi } from "core/api/admin";
import { ITaxonomyLabel } from "core/context/admin/taxonomy";
import {UtilsContext} from "../../../../../../core/context/utils.context";

type EditLabelModalProps = {
    open: boolean;
    label: ITaxonomyLabel;
    handleClose: () => void;
    onSuccess: (id: number, name: string) => void;
}

export const ActivateLabelModal: React.FC<EditLabelModalProps> = (
    {open, label, handleClose, onSuccess}
) => {

    const classes = modalStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [labelName, setLabelName] = useState({text: '', error: ''});
    const utilsContext = useContext(UtilsContext);
    useEffect(() => {
        setLabelName({text: label?.name || '', error: ''})
    }, [open])

    const onSave = (): void => {
        if(!labelName.text.length) {
            setLabelName({...labelName, error: 'Please enter a name'});
            return;
        }

        TaxonomyApi.updateLabel(label.id, labelName.text, label.parentId, true)
            .then(() => {
                setIsLoading(false);
                onSuccess(label.id, labelName.text);
                utilsContext.addSnackbar('success', 'The label was saved.')
            })
            .catch((error: any) => {
                console.error(error.response);
                setIsLoading(false);
                utilsContext.addSnackbar('error');
            })

        setIsLoading(true);
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Box textAlign={'right'}>
                        <CloseIcon cursor={'pointer'} onClick={handleClose} />
                    </Box>

                    <Box>
                        <Typography className={classes.modalTitle}>
                            Activate type "{label.name}"
                        </Typography>
                        <Typography className={classes.modalSubtitle} style={{marginBottom: 20}}>
                            Are you sure you want to activate this label?
                        </Typography>
                    </Box>

                    <Box textAlign={'right'} marginTop={'60px'}>
                        <Button
                            id="cancel-delete-btn"
                            variant="contained"
                            size={"small"}
                            style={{marginRight: '15px'}}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>

                        <Button
                            id="delete-btn"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            className={classes.primaryButton}
                            onClick={() => onSave()}
                        >
                            {
                                isLoading && <CircularProgress className="circular-progress sm" />
                            }
                            {
                                !isLoading && <SaveIcon style={{
                                    width: 20, marginRight: 5
                                }} />
                            }
                            Activate
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    )
}
