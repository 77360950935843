import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, ThemeOptions } from "@material-ui/core";

import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';

import { ITaxonomyLabel } from "core/context/admin/taxonomy";

type LabelProps = {
    label: ITaxonomyLabel,
    isSelected: boolean,
    hasActions: boolean;
    onClick: () => void,
    onDelete?: () => void;
    onEdit?: () => void;
    onActivate?: () => void;
}
const useStyles = makeStyles((theme: ThemeOptions) => ({
    root: {
        backgroundColor: theme.label.main,
        color: theme.label.text,
        fontSize: 13,
        marginRight: 10,
        padding: '3px 10px',
        borderRadius: 2,
        cursor: 'pointer',
        display: 'inline-block',
        marginTop: 7,
    },
    selected: {
        backgroundColor: '#029BFE',
        color: 'white'
    }
}))

export const Label: React.FC<LabelProps> = (
    {label, isSelected, hasActions, onClick, onDelete, onEdit, onActivate}
) => {

    const classes = useStyles();

    return (
        <Box className={`${classes.root} ${isSelected ? classes.selected : ''}`}>
            <span style={{display: 'flex', alignItems: 'center'}}>
                <span onClick={() => onClick()} >
                    {label.name}
                </span>

                {
                    hasActions && <EditIcon
                        style={{color: isSelected ? 'white' : '#b5b5b5', width: 13, margin: '0 5px'}}
                        onClick={() => onEdit ? onEdit() : null}
                    />
                }
                {
                    hasActions && <DeleteIcon
                        style={{color: isSelected ? 'white' : '#b5b5b5', width: 13}}
                        onClick={() => onDelete ? onDelete() : null}
                    />
                }
                {
                    !label.isActive && <CancelIcon
                        style={{color: '#752828', width: 13, margin: '0 5px'}}
                        onClick={() => onActivate ? onActivate() : null}
                    />
                }
            </span>
        </Box>
    )
}
