import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export const widgetStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.rootBackground.cardBackground,
        border: '1px solid',
        borderColor: theme.rootBackground.secondaryBorder,
        padding: '10px 15px',
        width: '32%',
        borderRadius: 2,
        ["@media (max-width:855px)"]: { width: '100%', marginBottom: 10 },
    },
    selected: {
        borderWidth: 2,
        borderColor: theme.label.text,
    },
    title: {
        color: theme.palette.text.secondary,
        fontSize: 13,
    },
    count: {
        fontWeight: 'bold',
        marginTop: 7,
    },
    flexBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginBottom: 10,
    },
    toggleListBtn: {
        backgroundColor: theme.label.main,
        color: theme.label.text,
        fontSize: 15,
        padding: '0 10px',
        "&:hover": {
            backgroundColor: theme.rootBackground.secondaryBorder
        }
    },
    selectedBtn: {
        backgroundColor: 'transparent',
        borderColor: theme.label.text,
    },
    expandIcon: {
        width: 18,
        marginLeft: -5,
    },
    circularProgressBox: {
        width: 80,
        height: 80,
        textAlign: 'center',
    },
}));
