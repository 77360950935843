import {Backdrop, Box, Button, CircularProgress, Fade, Modal, Typography} from "@material-ui/core";
import React from "react";
import {modalStyles} from "../../_others/modals/modal-styles";
import {ModalHeader} from "../../_others/modals/ModalHeader";
import {useRemoveJob} from "../_hooks/use-remove-job.hook";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

type RemoveJobModalProps = {
    userId: number;
    collectorId: number;
    role: string;
    open: boolean;
    handleClose: () => void;
    onRemove: () => void;
}

export const RemoveJobModal: React.FC<RemoveJobModalProps> = (
    {userId, collectorId, role, onRemove, open, handleClose}
) => {

    const classes = modalStyles();

    const {isLoading, removeJob} = useRemoveJob(userId, collectorId, role);

    const onConfirm = (): void => {
        removeJob().then((res: boolean) => {
            if(res) {
                onRemove();
                handleClose();
            }
        })
    }

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>

                    <ModalHeader
                        title={'Remove Job'}
                        handleClose={handleClose}
                    />

                    <Typography className={classes.modalSubtitle}>
                        Are you sure you want to remove this job?
                    </Typography>

                    <Box textAlign={'right'} marginTop={'60px'}>
                        <Button
                            id="cancel-btn"
                            variant="contained"
                            size={"small"}
                            style={{marginRight: '15px'}}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>

                        <Button
                            id="confirm-btn"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            className={classes.primaryButton}
                            onClick={() => onConfirm()}
                        >
                            {
                                isLoading && <CircularProgress className="circular-progress sm" />
                            }
                            {
                                !isLoading && <DeleteIcon />
                            }
                            Remove Job
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    )
}
