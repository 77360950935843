import { Container, Typography } from '@material-ui/core';
import React from 'react';

export const OurMission: React.FC = () => {
    return (
        <Container style={{ marginTop: 40 }}>
            <Typography color={'secondary'} style={{ fontSize: 35, fontWeight: 'bold' }}>
                Our Mission
            </Typography>

            <Typography color={'textPrimary'} style={{ marginTop: 20, fontSize: 18 }}>
                Artifact’s mission is to help our users find value in the objects they have collected throughout their
                lives. We fuse bold technology with human insight to create a radically simple way of identifying,
                assessing, and archiving collections. From the everyday to the extraordinary, we believe each beloved
                possession has multiple stories to tell.
            </Typography>

            <Typography color={'textPrimary'} style={{ marginTop: 20, fontSize: 18 }}>
                Artifact.id instantly connects users to both our innovative suite of tools and a trusted, vetted
                community of passionate experts, enabling the quick, easy evaluation of almost any object.
            </Typography>

            <Typography color={'textPrimary'} style={{ marginTop: 40, fontWeight: 600, fontSize: 18 }}>
                Users rely on Artifact to:
            </Typography>
            <ul>
                <li style={{ fontSize: 18 }}>
                    <b>
                        <span style={{ color: '#FE6502' }}>Discover </span>
                    </b>
                    the personal history and true value of the objects they love.
                </li>
                <li style={{ fontSize: 18 }}>
                    <b>
                        <span style={{ color: '#FE6502' }}>Protect </span>
                    </b>
                    their collections now and in the future by archiving it.
                </li>
                <li style={{ fontSize: 18 }}>
                    <b>
                        <span style={{ color: '#FE6502' }}>Share </span>
                    </b>
                    their collections with others, fostering curiosity and collectively increasing human knowledge.
                </li>
            </ul>
        </Container>
    );
};
