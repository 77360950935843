import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Box, ThemeOptions } from '@material-ui/core';

import { ExpertProfileContext } from 'core/context/admin/expert-profile';
import { AppRoutes } from 'core/helpers';
import { ExpertsApi } from 'core/api/admin';

import { BackNavigation, Loader } from 'components/_others';

import { Lightbox } from './components/Lightbox';
import { Stats } from './components/Stats';
import { Bio } from './components/Bio';
import { ExpertLabels } from './components/ExpertLabels';
import { CompletedFeedbacksList } from './components/CompletedFeedbacksList';
import { UserApi } from '../../../../../core/api/user';
import { UserRoles } from '../../../../../core/enums';
import { UserContext } from '../../../../../core/context/user';
import { ChangeUserRole } from '../../../../../components/_dashboard/_user-profiles/ChangeUserRole';
import { UtilsContext } from '../../../../../core/context/utils.context';
import { DeleteAccountSegment } from 'scenes/auth/scenes/UserProfile/components/DeleteAccountSegment';

const useStyles = makeStyles((theme: ThemeOptions) => ({
    root: {
        border: '1px solid',
        borderColor: theme.divider.main,
        marginTop: 20,
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px',
    },
    profileBox: {
        width: '46%',
    },
    labelsBox: {
        width: '49%',
    },
    divider: {
        width: '40%',
        borderTop: '1px solid',
        borderColor: theme.divider.main,
        margin: '20px 0',
    },
    stats: {},
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

export const ExpertProfile: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const expertProfileContext = useContext(ExpertProfileContext);

    const [isLoading, setIsLoading] = useState(true);
    const utilsContext = useContext(UtilsContext);
    const [isMyProfile, setIsMyProfile] = useState(false);

    const userContext = useContext(UserContext);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('id');

        if (!id) {
            history.push(AppRoutes.EXPERT_LISTING);
        }

        if (id === 'my-profile') {
            setIsMyProfile(true);
            UserApi.getUser()
                .then((res: any) => {
                    expertProfileContext.setData(res.data);
                    window.scrollTo(0, 0);
                    setIsLoading(false);
                })
                .catch((error: any) => {
                    console.error(error.response);
                    history.push(AppRoutes.EXPERT_LISTING);
                    utilsContext.addSnackbar('error');
                });
            return;
        }

        ExpertsApi.getExpertProfile(Number(id))
            .then((res: any) => {
                expertProfileContext.setData(res.data);
                window.scrollTo(0, 0);
                setIsLoading(false);
            })
            .catch((error: any) => {
                console.error(error.response);
                history.push(AppRoutes.EXPERT_LISTING);
                utilsContext.addSnackbar('error');
            });
    }, []);

    const saveExpertType = (type: string): void => {
        ExpertsApi.updateExpertType(expertProfileContext.data.userId, type)
            .then(() => {
                expertProfileContext.setExpertType(type);
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error');
            });
    };

    return (
        <Box>
            {!isLoading && (
                <>
                    <BackNavigation />
                    <Box className={classes.pageContainer}>
                        <Box className={classes.root}>
                            <Box className={classes.profileBox}>
                                <Box>
                                    <Lightbox user={expertProfileContext.data} isMyProfile={isMyProfile} />

                                    {userContext.user?.role === UserRoles.ADMIN && (
                                        <>
                                            <div className={classes.divider} />

                                            <ChangeUserRole
                                                currentRole={UserRoles.EXPERT}
                                                user={expertProfileContext.data}
                                            />

                                            <div className={classes.divider} />

                                            <Stats
                                                totalFeedbacks={expertProfileContext.data.totalFeedbacks}
                                                totalDeletedFeedbacksCount={
                                                    expertProfileContext.totalDeletedFeedbacksCount
                                                }
                                                type={expertProfileContext.data.type}
                                                customerSatisfaction={expertProfileContext.data.rating}
                                                saveExpertType={(type: string) => saveExpertType(type)}
                                            />

                                            <div className={classes.divider} />
                                        </>
                                    )}
                                    <Bio user={expertProfileContext.data} />
                                </Box>
                            </Box>
                            <Box className={classes.labelsBox}>
                                {userContext.user?.role === UserRoles.ADMIN && (
                                    <Box>
                                        <ExpertLabels
                                            expertId={expertProfileContext.data.userId}
                                            labels={expertProfileContext.categories}
                                        />

                                        <div className={classes.divider} />

                                        <Box marginTop={'20px'}>
                                            <CompletedFeedbacksList userId={expertProfileContext.data.userId} />
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>

                        {userContext.user?.role === UserRoles.EXPERT && (
                            <Box style={{ maxWidth: '1000px' }}>
                                <DeleteAccountSegment />
                            </Box>
                        )}
                    </Box>
                </>
            )}

            {isLoading && <Loader />}
        </Box>
    );
};
