import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export const makeOfferStyles = makeStyles((theme: Theme) => ({
    formContainer: {
        marginTop: 30,
    },
    formControl: {
        marginBottom: 20,
    },
    formLabel: {
        color: theme.formLabel.main,
        fontSize: 14,
        marginBottom: 5,
    },
    focusLabel: {
        color: theme.palette?.primary?.main,
    }
}))

export const makeOfferBtnStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: '#707070',
        color: 'white',
        fontSize: 13,
        padding: '0px 10px',
        '&:hover': {
            backgroundColor: theme.palette?.primary?.main,
        }
    }
}))
