import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';

import addObjectIcon from 'assets/images/add-object.svg';
import { takePhotoStyles } from '../styles/take-photo.style';

type TakePhotoProps = {
    changeView: () => void;
};

export const TakePhoto: React.FC<TakePhotoProps> = ({ changeView }) => {
    const classes = takePhotoStyles();

    return (
        <Box className={classes.root}>
            <Typography variant="h6" className={classes.title}>
                Start adding an object
            </Typography>

            <img src={addObjectIcon} alt="add-object-icon" />

            <Typography className={classes.subtitle}>
                Make sure to get close, find good lightning and focus on your item.
            </Typography>

            <Button
                id="how-to-take-photo-btn"
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={() => changeView()}
            >
                How to take a photo
            </Button>
        </Box>
    );
};
