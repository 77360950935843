import React, {useContext, useEffect} from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, ThemeOptions, Typography } from "@material-ui/core";

import { TaxonomyApi } from "core/api/admin";
import { TaxonomyContext } from "core/context/admin/taxonomy";

import { RootTypes } from "./components/RootTypes";
import { ConfirmDeleteModal } from "components/_others";
import {UtilsContext} from "../../../../../core/context/utils.context";

const useStyles = makeStyles((theme: ThemeOptions) => ({
    labelsContainer: {
        marginTop: 30,
        width: '100%',
        border: '1px solid',
        borderColor: theme.divider.main,
        borderRadius: 1,
        padding: '20px 15px',
    }
}))

export const Taxonomy: React.FC = () => {

    const classes = useStyles();
    const taxonomyContext = useContext(TaxonomyContext);
    const utilsContext = useContext(UtilsContext);


    useEffect(() => {
        TaxonomyApi.getRootTypes()
            .then((res: any) => {
                taxonomyContext.setRootTypes(res.data);
            })
            .catch((error: any) => {
                console.error(error.response);
                //TODO: manage error
            })
    }, [])

    const onDelete = (id: number): void => {
        TaxonomyApi.deleteLabel(id)
            .then(() => {
                taxonomyContext.pushDeletedLabel(id);
                taxonomyContext.setSelectedForDeleteId(null);
                utilsContext.addSnackbar('success', 'The label was deleted.');
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error');
            })
    }

    const isLabelInUse = (id: number): void => {
        TaxonomyApi.isLabelInUse(id)
            .then((res: any) => {
                if(res.data.success !== false) {
                    const userResponse = confirm('This label is already in use.' +
                        ' If you delete it, it will be removed from the feedbacks.');
                    if(userResponse) {
                        onDelete(id);
                    }
                } else {
                    onDelete(id);
                }
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error');
            })
    }

    return (
        <Box>
            <Typography variant="h5" color="textPrimary">
                Taxonomy
            </Typography>

            <Box className={classes.labelsContainer}>
                <RootTypes />
            </Box>

            <ConfirmDeleteModal
                open={taxonomyContext.selectedForDeleteId !== null}
                isLoading={false}
                subtitle={'Are you sure you want to delete this taxonomy type?'}
                handleClose={() => taxonomyContext.setSelectedForDeleteId(null)}
                onConfirm={() => isLabelInUse(taxonomyContext.selectedForDeleteId || -1)}
            />
        </Box>
    )
}
