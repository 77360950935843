import * as api from '../_utils/api';

export class ManageUsersApi {

    static async editAccess(userId: number, enable: boolean): Promise<any> {
        return api._put(`users/changeUserStatus/${userId}?enable=${enable}`);
    }

    static async inviteUsers(payload: unknown): Promise<any> {
        return api._post(`users/invite`, payload);
    }

    static async updateUserProfile(userId: number, payload: any|unknown, _role?: string): Promise<any> {
        const data: any = payload;

        if(data.currencyId === -1) {
            data.currencyId = null;
        }
        if(data.countryId === -1) {
            data.countryId = null;
        }
        if(data.stateId === -1) {
            data.stateId = null;
        }
        if(data.cityId === -1) {
            data.cityId = null;
        }
        delete data.currency;

        return api._put(`users/profile/updateUserProfile?userId=${userId}`, data);
    }

    static async changeUserRole(userId: number, role: string): Promise<any> {
        return api._put(`users/${userId}/changeRole/${role}`);
    }
}
