import React, {useState} from "react";
import {Box, Grid, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ISharedArtifact } from "core/context/consumer/sharedObject";
import { IDocument } from "core/context/consumer/objectDetails";
import { IFeedbackResult } from "core/context/feedback-requests/feedback";

import { SectionHeader } from "components/_consumer/ObjectDetails/SectionHeader";
import { Dimensions } from "components/_consumer/ObjectDetails/Dimensions";
import { Documents } from "components/_consumer/ObjectDetails/Documents";
import { Feedbacks } from "components/_consumer/ObjectDetails/Feedbacks";
import { FeedbackResultModal } from "components/_consumer/ObjectDetails/FeedbackResultModal";

type AboutObjectProps = {
    artifact: ISharedArtifact
}

const useStyles = makeStyles( theme => ({
    label: {
        color: '#B2B2B2',
        fontSize: 13,
        marginTop: 5,
    },
    value: {
        color: theme.palette.text.primary,
        fontSize: 13,
        marginBottom: 5,
    }
}))

export const AboutObject: React.FC<AboutObjectProps> = ({artifact}) => {

    const classes = useStyles();

    const [displayFeedbackResultModal, toggleFeedbackResultModal] = useState<IFeedbackResult|null>(null);

    return (
        <>
            <SectionHeader title={'About the object'} />

            <Typography className="object-description" style={{marginBottom: 15}}>
                {artifact.description}
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Dimensions
                        height={artifact.height}
                        width={artifact.width}
                        depth={artifact.depth}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.label}>Weight</Typography>
                    <Typography className={classes.value}>
                        {artifact.weight ? `${artifact.weight} lb.` : 'unknown'}
                    </Typography>
                </Grid>
            </Grid>

            <Typography className={classes.label}>Approximate age</Typography>
            <Typography className={classes.value}>
                {
                    artifact.exactProductionYear !== null &&
                    <>
                        {
                            artifact.exactProductionYear < 0
                                ? `${artifact.exactProductionYear * -1} BC`
                                : artifact.exactProductionYear
                        }
                    </>
                }
                {
                    artifact.lowerProductionYear !== null && artifact.upperProductionYear !== null &&
                    <>
                        {
                            artifact.lowerProductionYear < 0
                                ? `${artifact.lowerProductionYear * -1} BC`
                                : artifact.lowerProductionYear
                        }{' - '}
                        {
                            artifact.upperProductionYear < 0
                                ? `${artifact.upperProductionYear * -1} BC`
                                : artifact.upperProductionYear
                        }
                    </>
                }
                {
                    artifact.lowerProductionYear === null && artifact.exactProductionYear === null &&
                    <>
                        {' unknown '}
                    </>
                }
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.label}>Material</Typography>
                    <Typography className={classes.value}>{artifact.material || 'unknown'}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.label}>Style</Typography>
                    <Typography className={classes.value}>{artifact.style || 'unknown'}</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.label}>Origin</Typography>
                    <Typography className={classes.value}>{artifact.origin || 'unknown'}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.label}>Condition</Typography>
                    <Typography className={classes.value}>{artifact.condition || 'unknown'}</Typography>
                </Grid>
            </Grid>

            <Typography className={classes.label}>Artist or Maker</Typography>
            <Typography className={classes.value}>{artifact.artist || 'unknown'}</Typography>

            <Box marginTop={'20px'}>
                <Documents documents={artifact.documents.filter((d: IDocument) => !d.deleted)} />
            </Box>

            <Box marginTop={'20px'}>
                <Feedbacks
                    feedbacks={artifact.feedbacks}
                    onSelect={(feedback) => toggleFeedbackResultModal(feedback)}
                    activeFeedbacksComments={[]}
                />
            </Box>

            {
                displayFeedbackResultModal !== null &&
                <FeedbackResultModal
                    open={true}
                    handleClose={() => toggleFeedbackResultModal(null)}
                    photo={artifact.photos?.find(p => p.isPrimary)?.url || ''}
                    feedback={displayFeedbackResultModal}
                    artifactId={artifact.id}
                />
            }
        </>
    )
}
