import React from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import {AppRoutes} from "../../../core/helpers";
import {Link} from "react-router-dom";

type TermsAndConditionsCheckboxProps = {
    error: string;
    checked: boolean;
    handleChange: any;
}

export const TermsAndConditionsCheckbox: React.FC<TermsAndConditionsCheckboxProps> = (
    {error, checked, handleChange}
) => {
    return (
        <Box style={{textAlign: 'left'}}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={() => handleChange(!checked)}
                        name="checkedB"
                        color="primary"
                    />
                }
                label={
                    <Typography className="checkbox-label" color="textPrimary">
                        By signing up, you agree with the{' '}
                        <Link
                            target={'_blank'}
                            to={AppRoutes.TERMS_AND_CONDITIONS}
                            style={{color: 'white', textDecoration: 'underline'}}
                        >
                            Terms of Service
                        </Link> and{' '}
                        <Link
                            target={'_blank'}
                            to={AppRoutes.PRIVACY_POLICY}
                            style={{color: 'white', textDecoration: 'underline'}}
                        >
                            Privacy Policy
                        </Link>
                    </Typography>
                }
            />
            {
                error &&
                    <Typography
                        style={{color: '#B32222', fontSize: '0.75rem'}}
                    >
                        {error}
                    </Typography>
            }
        </Box>
    )
}
