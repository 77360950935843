import React from "react";

import { FormControl, IconButton, InputAdornment, Typography } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";

type PasswordFormControlProps = {
    value: string;
    error: boolean;
    helperText: string;
    className: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => unknown;
    onFocus: () => unknown;
    onBlur: () => unknown;
    onKeyPress?: (key: string) => unknown;
}

export const PasswordFormControl: React.FC<PasswordFormControlProps> = (
    {value, error, helperText, className, onChange, onFocus, onBlur, onKeyPress}
) => {

    const [values, setValues] = React.useState({
        showPassword: false,
    });

    const handleClickShowPassword = (): void => {
        setValues({showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event: any): void => {
        event.preventDefault();
    };

    return (
        <FormControl
            variant="outlined"
            fullWidth
            className={className}
            error={error}
            style={{marginTop: '16px', marginBottom: '8px'}}
        >
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
                id="password"
                autoComplete="new-password"
                error={error}
                type={values.showPassword ? 'text' : 'password'}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                inputProps={{ maxLength: 100 }}
                onKeyPress={(e: any) => onKeyPress ? onKeyPress(e) : null}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            style={error? {color: '#B32222'} : {color: '#707070'}}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            {
                error &&
                <Typography
                    style={{
                        color: '#B32222', fontSize: '0.75rem', textAlign: 'left', marginTop: '5px'
                    }}
                >
                    {helperText}
                </Typography>
            }
        </FormControl>
    )
}
