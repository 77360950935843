import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, ThemeOptions, Typography } from "@material-ui/core";

import { ICuratorProfile } from "core/context/admin/curator-profile";

type BioProps = {
    user: ICuratorProfile
}

const useStyles = makeStyles((_theme: ThemeOptions) => ({
    label: {
        fontSize: 15,
    },
    value: {
        fontSize: 16,
        marginBottom: 5,
    }
}))

export const Bio: React.FC<BioProps> = ({user}) => {

    const classes = useStyles();

    return (
        <Box>
            <Typography variant="h5" className={classes.label} color="textSecondary">
                How did you hear about us?
            </Typography>
            <Typography variant="h5" className={classes.value} color="textPrimary">
                {user.reference || 'unknown'}
            </Typography>

            <Typography variant="h5" className={classes.label} color="textSecondary">
                Bio
            </Typography>
            <Typography variant="h5" className={classes.value} color="textPrimary">
                {user.description || 'unknown'}
            </Typography>

            <Typography variant="h5" className={classes.label} color="textSecondary">
                Address
            </Typography>
            <Typography variant="h5" className={classes.value} color="textPrimary">
                {user.address || 'unknown'}
            </Typography>

            <Typography variant="h5" className={classes.label} color="textSecondary">
                Organization
            </Typography>
            <Typography variant="h5" className={classes.value} color="textPrimary">
                {user.organization || 'unknown'}
            </Typography>

            <Typography variant="h5" className={classes.label} color="textSecondary">
                Website
            </Typography>
            <Typography variant="h5" className={classes.value} color="textPrimary">
                {user.website || 'unknown'}
            </Typography>

            <Typography variant="h5" className={classes.label} color="textSecondary">
                Currency
            </Typography>
            <Typography variant="h5" className={classes.value} color="textPrimary">
                {user.currency?.code || 'unknown'}
            </Typography>
        </Box>
    )
}
