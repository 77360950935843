import React from "react";
import { useHistory } from 'react-router-dom';
import { Button } from "@material-ui/core";

import { authButtonsStyles } from "../styles/styles";

import { AppRoutes } from "core/helpers/app-routes";

export const SignUpBtn: React.FC = () => {

    const history = useHistory();

    const classes = authButtonsStyles();

    return (
        <Button
            fullWidth
            id="sign-up-header-btn"
            variant="contained"
            color="secondary"
            className={`${classes.button} ${classes.signUp}`}
            onClick={() => history.push(AppRoutes.CREATE_ACCOUNT)}
        >
            Sign Up
        </Button>
    )
}
