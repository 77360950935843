import React from "react";
import { Box, Snackbar, Typography } from "@material-ui/core";

import NotificationsIcon from '@material-ui/icons/Notifications';
import CloseIcon from '@material-ui/icons/Close';

import "./style.less";

type CustomSnackbarProps = {
    open: boolean;
    severity: string;
    message: string;
    handleClose: () => void;
}

export const CustomSnackbar: React.FC<CustomSnackbarProps> = ({open, severity, message, handleClose}) => {
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={6000}
            open={open}
            onClose={handleClose}
            key={''}
        >
            <Box className={`custom-snackbar ${severity}`}>
                <Box className="icon-box">
                    <NotificationsIcon />
                </Box>
                <Box className="message">
                    <Typography>{message}</Typography>
                    <CloseIcon onClick={() => handleClose()} />
                </Box>
            </Box>
        </Snackbar>
    )
}
