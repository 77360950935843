import {useState} from "react";
import {IPagination} from "../context/interfaces";
import {SortObjectsOptions} from "../enums";

export const usePagination = (): any => {

    const [pagination, setPagination] = useState<IPagination>({
        page: 1,
        size: 10,
        totalCount: 0,
        numberOfPages: 0,
        sortBy: SortObjectsOptions.DateCreated,
        asc: false,
    })

    return {
        pagination,
        setPagination,
    }
}
