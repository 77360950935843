import { IAction } from "../../interfaces";
import {ICuratorListingState} from "./curator-listing.interfaces";

export default (state: ICuratorListingState, action: IAction): ICuratorListingState => {
    switch(action.type) {
        case 'SET_CURATORS':
            return {
                ...state,
                curators: action.payload.data,
                isLoading: false,
                displayEmptyPage: action.payload.data?.length === 0,
                statistics: {
                    totalUsers: action.payload.totalCount,
                },
                pagination: {
                    page: state.pagination.page,
                    size: state.pagination.size,
                    totalCount: action.payload.totalCount,
                    numberOfPages: Math.ceil(action.payload.totalCount/state.pagination.size),
                },
            };
        case 'SET_CURRENT_PAGE':
            return {
                ...state,
                isLoading: false,
                pagination: {
                    ...state.pagination,
                    page: action.payload,
                }
            }
        case 'SET_LOADING':
            return {
                ...state,
                isLoading: action.payload
            }
        default:
            return state;
    }
}
