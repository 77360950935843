import React, {useEffect} from 'react';

import "assets/styles/root.less";

import { AppRouting } from "./AppRouting";
import { isMobileBrowser } from "./core/helpers/utils";

import { UserProvider } from "core/context/user";
import { SearchResultsProvider } from "./core/context/searchResults";
import { GlobalProvider } from "./core/context/global";
import { UtilsProvider } from "./core/context/utils.context";

const App: React.FC = () => {

    useEffect(() => {
        if(isMobileBrowser() && !window.sessionStorage.getItem('displayMobileAlert')) {
            alert('We are sorry, but this website is not yet optimized for mobile use and some' +
                ' features might not work as expected. Please open www.artifact.id on a desktop/laptop browser, or' +
                ' you can download out mobile app at the Apple Store or Google Play.');
            window.sessionStorage.setItem('displayMobileAlert', 'true');
        }
    }, [])

    return (
        <UserProvider>
            <GlobalProvider>
                <SearchResultsProvider>
                    <UtilsProvider>
                        <AppRouting />
                    </UtilsProvider>
                </SearchResultsProvider>
            </GlobalProvider>
        </UserProvider>
  );
}

export default App;
