import {AppRoutes} from "core/helpers/app-routes";
import {INavLink} from "./nav-link.interfaces";

export const navLinks: INavLink[] = [
    {
        id: 90,
        name: 'Objects',
        path: AppRoutes.MY_OBJECTS,
    },
    {
        id: 91,
        name: 'Collections',
        path: AppRoutes.MY_COLLECTIONS,
    },
    {
        id: 92,
        name: 'Reporting',
        path: AppRoutes.REPORTING,
    },
    {
        id: 93,
        name: 'Community Feed',
        path: AppRoutes.PUBLIC_FEED,
        onAuthPages: false,
    },
]
