import {IAction} from "../../interfaces";
import {IFeedbackRequestState} from "./feedback.interfaces";

export default (state: IFeedbackRequestState, action: IAction): IFeedbackRequestState => {
    switch(action.type) {
        case 'SET_DATA':
            return {
                ...state,
                data: {...action.payload.data, id: action.payload.id},
                isLoading: false,
            }
        case 'SET_LOADING':
            return {
                ...state,
                isLoading: action.payload
            }
        default:
            return state;
    }
}
