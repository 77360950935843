import React, { useState } from 'react';

import { TextField, Button, Typography, CircularProgress } from '@material-ui/core';
import { PasswordFormControl } from 'components/_others/PasswordFormControl';
import { TermsAndConditionsCheckbox } from '../../components/TermsAndConditionsCheckbox';
import { passwordStrength, validateEmail } from 'core/helpers/validators';
import { UserRoles } from '../../../../core/enums';

type CreateAccountFormProps = {
    role: string;
    isLoading: boolean;
    onRegisterUser: (userData: any) => void;
    toggleAcceptTerms: (value: boolean) => void;
};

export const CreateAccountForm: React.FC<CreateAccountFormProps> = ({
    isLoading,
    onRegisterUser,
    toggleAcceptTerms,
    role,
}) => {
    const [onFocus, setOnFocus] = useState({
        firstName: false,
        lastName: false,
        email: false,
        password: false,
        expertise: false,
        reference: false,
    });

    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        expertise: '',
        reference: '',
        acceptTerms: false,
    });

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        expertise: '',
        reference: '',
        acceptTerms: '',
    });

    const onRegister = (): void => {
        if (!isValidForm()) {
            return;
        }
        onRegisterUser(values);
    };

    const setFormErrors = (): any => {
        const hasLetterRegExp = /[a-zA-Z]/g;
        const errors = {
            firstName:
                !onFocus.firstName && !values.firstName.trim().length
                    ? 'Please enter your first name.'
                    : hasLetterRegExp.test(values.firstName)
                    ? ''
                    : 'Your first name must contain letters.',
            lastName:
                !onFocus.lastName && !values.lastName.trim().length
                    ? 'Please enter your last name.'
                    : hasLetterRegExp.test(values.lastName)
                    ? ''
                    : 'Your last name must contain letters.',
            email:
                !onFocus.email && !values.email.length
                    ? 'Please enter your email.'
                    : !validateEmail(values.email)
                    ? 'Please enter a valid email.'
                    : '',
            expertise:
                role === UserRoles.EXPERT && !onFocus.expertise && !values.expertise.trim().length
                    ? 'Please enter your expertise.'
                    : '',
            reference:
                (role === UserRoles.ARCHIVIST || role === UserRoles.CURATOR) &&
                !onFocus.reference &&
                !values.reference.trim().length
                    ? 'Please tell us how did you hear about us.'
                    : '',
            password:
                !onFocus.password && !values.password.length
                    ? 'Please enter your password.'
                    : !passwordStrength(values.password).valid
                    ? 'Your password is not strong enough'
                    : '',
            acceptTerms: !values.acceptTerms ? 'You have to accept the terms and conditions.' : '',
        };
        setErrors(errors);
        return errors;
    };

    const isValidForm = (): boolean => {
        const err = setFormErrors();
        return (
            values.acceptTerms &&
            !err.firstName &&
            !err.lastName &&
            !err.email &&
            !err.password &&
            !err.reference &&
            !err.expertise
        );
    };

    const handleKeyPress = (e: any): void => {
        if (e.key === 'Enter') {
            onRegisterUser(values);
            e.preventDefault();
        }
    };

    return (
        <form id="sign-up-form" className="auth-form" noValidate>
            <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                id="first-name"
                name="first-name"
                label="First Name"
                autoComplete="new-password"
                placeholder="First Name"
                type="text"
                inputProps={{ maxLength: 30 }}
                className={onFocus.firstName || values.firstName?.length ? 'mt-30' : ''}
                error={errors.firstName.length !== 0}
                helperText={errors.firstName}
                value={values.firstName}
                onChange={(e) => {
                    setValues({ ...values, firstName: e.target.value });
                    setErrors({ ...errors, firstName: '' });
                }}
                onFocus={() => setOnFocus({ ...onFocus, firstName: true })}
                onBlur={() => setOnFocus({ ...onFocus, firstName: false })}
            />

            <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                id="last-name"
                name="last-name"
                label="Last Name"
                autoComplete="new-password"
                placeholder="Last Name"
                type="text"
                inputProps={{ maxLength: 30 }}
                className={onFocus.lastName || values.lastName?.length ? 'mt-30' : ''}
                error={errors.lastName.length !== 0}
                helperText={errors.lastName}
                value={values.lastName}
                onChange={(e) => {
                    setValues({ ...values, lastName: e.target.value });
                    setErrors({ ...errors, lastName: '' });
                }}
                onFocus={() => setOnFocus({ ...onFocus, lastName: true })}
                onBlur={() => setOnFocus({ ...onFocus, lastName: false })}
            />

            <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                id="email"
                name="email"
                label="Email"
                autoComplete="new-password"
                placeholder="Email"
                type="email"
                inputProps={{ maxLength: 100 }}
                className={onFocus.email || values.email?.length ? 'mt-30' : ''}
                error={errors.email.length !== 0}
                helperText={errors.email}
                value={values.email}
                onChange={(e) => {
                    setValues({ ...values, email: e.target.value });
                    setErrors({ ...errors, email: '' });
                }}
                onFocus={() => setOnFocus({ ...onFocus, email: true })}
                onBlur={() => setOnFocus({ ...onFocus, email: false })}
            />

            <PasswordFormControl
                value={values.password}
                className={onFocus.password || values.password?.length ? 'mt-30' : ''}
                error={errors.password.length !== 0}
                helperText={errors.password}
                onChange={(e) => {
                    setValues({ ...values, password: e.target.value });
                    setErrors({ ...errors, password: '' });
                }}
                onFocus={() => setOnFocus({ ...onFocus, password: true })}
                onBlur={() => setOnFocus({ ...onFocus, password: false })}
                onKeyPress={(e: any) => handleKeyPress(e)}
            />

            {!passwordStrength(values.password).valid && (
                <>
                    {
                        <Typography className="error-message" color="error">
                            {!passwordStrength(values.password).length.valid && (
                                <>
                                    {passwordStrength(values.password).length.message} <br />
                                </>
                            )}
                            {!passwordStrength(values.password).digits.valid && (
                                <>
                                    {passwordStrength(values.password).digits.message} <br />
                                </>
                            )}
                            {!passwordStrength(values.password).uppercase.valid && (
                                <>
                                    {passwordStrength(values.password).uppercase.message} <br />
                                </>
                            )}
                            {!passwordStrength(values.password).lowercase.valid && (
                                <>
                                    {passwordStrength(values.password).lowercase.message} <br />
                                </>
                            )}
                            {!passwordStrength(values.password).specialChars.valid && (
                                <>
                                    {passwordStrength(values.password).specialChars.message} <br />
                                </>
                            )}
                        </Typography>
                    }
                </>
            )}

            {role === UserRoles.EXPERT && (
                <TextField
                    fullWidth
                    multiline
                    rows={3}
                    rowsMax={10}
                    inputProps={{ maxLength: 1000 }}
                    variant="outlined"
                    margin="normal"
                    id="expertise"
                    name="expertise"
                    label="Please say a few words about your expertise"
                    autoComplete="new-password"
                    placeholder="Please say a few words about your expertise"
                    type="text"
                    className={onFocus.expertise || values.expertise?.length ? 'mt-30' : ''}
                    error={errors.expertise.length !== 0}
                    helperText={errors.expertise}
                    value={values.expertise}
                    onChange={(e) => {
                        setValues({ ...values, expertise: e.target.value });
                        setErrors({ ...errors, expertise: '' });
                    }}
                    onFocus={() => setOnFocus({ ...onFocus, expertise: true })}
                    onBlur={() => setOnFocus({ ...onFocus, expertise: false })}
                />
            )}

            {(role === UserRoles.ARCHIVIST || role === UserRoles.CURATOR) && (
                <TextField
                    fullWidth
                    multiline
                    rows={3}
                    rowsMax={10}
                    inputProps={{ maxLength: 1000 }}
                    variant="outlined"
                    margin="normal"
                    id="reference"
                    name="reference"
                    label="How did you hear about us?"
                    autoComplete="new-password"
                    placeholder="Please say a few words about how did you hear about us"
                    type="text"
                    className={onFocus.reference || values.reference?.length ? 'mt-30' : ''}
                    error={errors.reference.length !== 0}
                    helperText={errors.reference}
                    value={values.reference}
                    onChange={(e) => {
                        setValues({ ...values, reference: e.target.value });
                        setErrors({ ...errors, reference: '' });
                    }}
                    onFocus={() => setOnFocus({ ...onFocus, reference: true })}
                    onBlur={() => setOnFocus({ ...onFocus, reference: false })}
                />
            )}

            <TermsAndConditionsCheckbox
                error={!values.acceptTerms ? errors.acceptTerms : ''}
                checked={values.acceptTerms}
                handleChange={(value: boolean) => {
                    setValues({ ...values, acceptTerms: value });
                    toggleAcceptTerms(value);
                }}
            />

            <Button
                fullWidth
                id="sign-in-btn"
                variant="contained"
                color="primary"
                className="auth-button"
                onClick={() => onRegister()}
            >
                {isLoading && <CircularProgress className="circular-progress" />}
                Register
            </Button>
        </form>
    );
};
