import { Backdrop, Box, CircularProgress, Fade, Modal, Typography } from '@material-ui/core';
import { modalStyles } from 'components/_others/modals/modal-styles';
import { AppRoutes, clearLocalStorage } from 'core';
import { UserApi } from 'core/api';
import { UserContext } from 'core/context/user';
import { UtilsContext } from 'core/context/utils.context';
import React, { useContext, useEffect, useState } from 'react';
import PinInput from 'react-pin-input';
import { useHistory } from 'react-router-dom';
import { ModalHeader } from '../../../../../components/_others/modals/ModalHeader';

type ForgetPasswordModalProps = {
    open: boolean;
    handleClose: () => void;
    deleteCode: string;
};

export const DeleteEmailSentModal: React.FC<ForgetPasswordModalProps> = ({ open, handleClose, deleteCode }) => {
    const classes = modalStyles();
    const history = useHistory();
    const userContext = useContext(UserContext);
    const utilsContext = useContext(UtilsContext);
    const [loading, setLoading] = useState<boolean>(false);

    const onLogout = (): void => {
        clearLocalStorage();
        userContext.onLogout();
        history.push(AppRoutes.SIGN_IN);
    };

    const onPinInputComplete = (value: string): void => {
        setLoading(true);
        UserApi.deleteAccount(value)
            .then((_res) => {
                onLogout();
            })
            .catch((err) => {
                console.error(err);
                utilsContext.addSnackbar('error');
                setLoading(false);
            });
    };

    useEffect(() => {
        if (deleteCode) {
            onPinInputComplete(deleteCode);
        }
    }, [deleteCode]);

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <ModalHeader title={'Delete Account E-Mail Sent'} handleClose={() => handleClose()} />

                    <Typography className={classes.modalSubtitle}>
                        An E-mail with a confirmation code has been sent to you. <br />
                        Enter the confirmation code below in order to permanently delete your account.
                    </Typography>

                    <Box style={{ margin: 10 }} display="flex" flexDirection={'column'} alignItems="center">
                        <PinInput
                            length={6}
                            type="numeric"
                            inputMode="number"
                            autoSelect={true}
                            regexCriteria={/^[0-9]*$/}
                            onComplete={onPinInputComplete}
                            initialValue={deleteCode}
                        />

                        {
                            <CircularProgress
                                className="circular-progress lg"
                                color="primary"
                                style={{
                                    visibility: loading ? 'visible' : 'hidden',
                                    marginTop: '12px',
                                    marginBottom: '12px',
                                }}
                            />
                        }
                    </Box>
                </div>
            </Fade>
        </Modal>
    );
};
