import React, { useContext, useEffect, useState } from 'react';
import { modalStyles } from 'components/_others/modals/modal-styles';
import { Backdrop, Box, Button, CircularProgress, Fade, Modal, Typography } from '@material-ui/core';
import AvatarEditor from 'react-avatar-editor';

import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import { UserApi } from 'core/api/user';
import { UserContext } from 'core/context/user';

type AvatarEditorModalProps = {
    open: boolean;
    handleClose: () => void;
};

export const AvatarEditorModal: React.FC<AvatarEditorModalProps> = ({ open, handleClose }) => {
    const classes = modalStyles();

    const userContext = useContext(UserContext);

    const [file, setFile] = useState<File | null>(null);
    const [editorRef, setEditorRef] = useState<any>(null);

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setFile(null);
        setIsLoading(false);
        setErrorMessage('');
    }, [open]);

    const handleUpload = (event: any): void => {
        if (!event.target.files?.length) {
            return;
        }

        const avatar = event.target.files[0];

        if (avatar.size > 10 * 1000000) {
            alert('We are sorry, but your photo size is too large.');
            return;
        }

        if (
            avatar.name &&
            avatar?.name?.substr(avatar.name?.length - 4, 4).toLowerCase() !== '.jpg' &&
            avatar?.name?.substr(avatar.name?.length - 5, 5).toLowerCase() !== '.jpeg' &&
            avatar?.name?.substr(avatar.name?.length - 4, 4).toLowerCase() !== '.png'
        ) {
            alert('We are sorry, but we do not accept this file type.');
            return;
        }

        setFile(avatar);
    };

    const onSave = (): void => {
        setIsLoading(true);
        setErrorMessage('');

        if (editorRef) {
            const canvas = editorRef.getImageScaledToCanvas().toDataURL();

            UserApi.changeAvatar(canvas)
                .then((res: any) => {
                    userContext.setProfilePic(res.data.url);
                    setIsLoading(false);
                    handleClose();
                })
                .catch((error: any) => {
                    console.error(error.response);
                    setIsLoading(false);
                    setErrorMessage('Sorry, there was an error. Please try again later.');
                });
        } else {
            setIsLoading(false);
            setErrorMessage('Sorry, there was an error. Please try again later.');
        }
    };

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Box textAlign={'right'}>
                        <CloseIcon cursor={'pointer'} onClick={handleClose} />
                    </Box>

                    <Box textAlign={'center'}>
                        <Typography className={classes.modalTitle}>Edit profile picture</Typography>
                    </Box>

                    <Box textAlign={'center'} marginTop={'10px'}>
                        <input
                            accept=".png,.jpeg,.jpg"
                            style={{ display: 'none' }}
                            id="upload-avatar"
                            type="file"
                            onChange={handleUpload}
                        />
                        <label htmlFor="upload-avatar">
                            <Button id="upload-photo-btn" variant="contained" color="primary" component="span">
                                Select a photo from your computer
                            </Button>
                        </label>
                    </Box>

                    {file && (
                        <Box textAlign={'center'} marginTop={'20px'}>
                            <AvatarEditor
                                ref={(ref: any) => setEditorRef(ref)}
                                image={file}
                                width={250}
                                height={250}
                                border={30}
                                borderRadius={150}
                                color={[56, 56, 56, 0.8]} // RGBA
                                scale={1}
                                rotate={0}
                            />
                        </Box>
                    )}

                    {errorMessage && (
                        <Typography className="error-message" style={{ textAlign: 'center' }} color="error">
                            {errorMessage}
                        </Typography>
                    )}

                    {file && (
                        <Box textAlign={'center'} marginTop={'30px'}>
                            <Button
                                id="save-avatar-btn"
                                variant="contained"
                                color="primary"
                                size={'small'}
                                className={classes.primaryButton}
                                onClick={() => onSave()}
                            >
                                {isLoading && <CircularProgress className="circular-progress sm" />}
                                {!isLoading && (
                                    <SaveIcon
                                        style={{
                                            width: 20,
                                            marginRight: 5,
                                        }}
                                    />
                                )}
                                Save
                            </Button>
                        </Box>
                    )}
                </div>
            </Fade>
        </Modal>
    );
};
