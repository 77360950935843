import { createMuiTheme, ThemeOptions } from "@material-ui/core";
import { darkTheme } from "assets/themes/dark-theme";

//TODO: rename
export const emptyTheme: ThemeOptions = createMuiTheme({
    ...darkTheme,
    palette: {
        ...darkTheme.palette,
        text: {
            primary: "#707070",
        },
    }
})
