import {useContext, useState} from "react";
import {useHistory} from "react-router-dom";

import {CommunityFeedApi} from "core/api/consumer";
import {ICommunityFeedArtifact} from "../types";
import {AppRoutes} from "core/helpers";

import {UtilsContext} from "core/context/utils.context";
import {usePagination} from "core/hooks/use-pagination.hook";

export const useFeedData = (): any => {

    const utilsContext = useContext(UtilsContext);
    const history = useHistory();

    const [feedData, setFeedData] = useState<ICommunityFeedArtifact[]>([]);

    const {pagination, setPagination} = usePagination();

    const getFeedData = (page: number, size: number): void => {
        utilsContext.setAppLoaderState(true);
        
        CommunityFeedApi.getCommunityFeedObjects(page, size)
            .then((res: any) => {
                setFeedData(mapObjects(res.data?.data || []));
                setPagination({
                    ...pagination,
                    page,
                    size,
                    totalCount: res.data.totalCount,
                    numberOfPages: Math.ceil(res.data.totalCount/res.data.size)
                });
                window.scrollTo(0, 0);
                utilsContext.setAppLoaderState(false);
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error');
                history.push(AppRoutes.MY_OBJECTS);
                utilsContext.setAppLoaderState(false);
            })
    }

    return {
        feedData,
        getFeedData,
        pagination,
    }

}

const mapObjects = (data: any): ICommunityFeedArtifact[] => {
    return data.map((a: any) => {
        return {
            id: a.id,
            publicFeedId: a.publicFeedId,
            title: a.title,
            description: a.description,
            photos: a.photos || [],
            offersEnabled: a.offersEnabled,
            totalCommentsCount: a.totalCommentsCount,
            collector: {
                userId: a.collector.userId
            },
        }
    })
}
