import React, {useState} from "react";
import { Box, Link } from "@material-ui/core";

import { ForgotPasswordModal } from "./ForgotPasswordModal";

export const ForgotPassword: React.FC = () => {

    const [forgetPassModalOpened, toggleForgetPassModal] = useState(false);

    return (
        <Box style={{textAlign: 'right', marginTop: '8px', marginBottom: '35px'}}>
            <Link
                component="button"
                color="textSecondary"
                style={{fontSize: '16px'}}
                onClick={(e: any) => {e.preventDefault(); toggleForgetPassModal(true)}}
            >
                Forgot Password?
            </Link>

            <ForgotPasswordModal
                open={forgetPassModalOpened}
                handleClose={() => toggleForgetPassModal(false)}
            />
        </Box>
    )
}
