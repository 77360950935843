import React, { useState } from 'react';
import { Box } from '@material-ui/core';

import imagePlaceholder from 'assets/placeholders/artifact-placeholder.png';

import { getPhotoUrl } from 'core/helpers';
import { FileSize } from 'core/enums';
import { IPhoto } from 'core/context/consumer/objectDetails';

import { PhotoLightbox } from 'components/_consumer/ObjectDetails/Gallery/PhotoLightbox';
import { artifactCardStyles } from '../../styles/artifact-card.style';

type PhotosProps = {
    photos: IPhoto[];
    artifactId: number;
};

export const Photos: React.FC<PhotosProps> = ({ photos, artifactId }) => {
    const classes = artifactCardStyles();

    const [lightbox, setLightbox] = useState<number>(-1);

    const mainPhoto = photos.filter((photo) => photo.isPrimary)[0]?.url ?? photos[0]?.url ?? '';

    return (
        <Box marginTop={3.5} textAlign={'center'}>
            <img
                src={getPhotoUrl(mainPhoto || '', FileSize.LG) || imagePlaceholder}
                alt={`${artifactId}`}
                className={classes.artifactPhoto}
                style={!mainPhoto ? { maxHeight: 250, cursor: 'default' } : {}}
                onClick={() => {
                    if (mainPhoto) {
                        setLightbox(0);
                    }
                }}
            />

            <PhotoLightbox
                open={lightbox !== -1}
                index={lightbox}
                photos={photos}
                handleClose={() => setLightbox(-1)}
            />
        </Box>
    );
};
