import React, { useState } from 'react';

import { UploadDocuments } from './UploadDocuments';
import { SelectedDocuments } from './SelectedDocuments';
import { IDocument } from 'core/context/consumer/objectDetails';
import { Typography } from '@material-ui/core';

type DocumentsProps = {
    initialDocuments?: IDocument[];
    setCurrentDocuments: (files: File[]) => void;
    onDelete?: (id: number) => void;
};

export const Documents: React.FC<DocumentsProps> = ({ initialDocuments, setCurrentDocuments, onDelete }) => {
    const [documents, setDocuments] = useState<File[]>([]);

    const onAddDocument = (document: File): void => {
        const current = documents;
        current.push(document);
        setDocuments(current.slice());
        setCurrentDocuments(current.slice());
    };

    const onRemove = (index: number): void => {
        const current = documents;
        current.splice(index, 1);
        setDocuments(current.slice());
        setCurrentDocuments(current.slice());
    };

    return (
        <>
            <SelectedDocuments
                initialDocuments={initialDocuments || []}
                documents={documents}
                onRemove={(index: number) => onRemove(index)}
                onDelete={(id: number) => (onDelete ? onDelete(id) : {})}
            />

            {documents.length + (initialDocuments?.filter((d) => !d.deleted).length || 0) < 10 && (
                <UploadDocuments
                    onUpload={onAddDocument}
                    initialDocuments={initialDocuments ?? []}
                    documents={documents}
                />
            )}

            {documents.length + (initialDocuments?.filter((d) => !d.deleted).length || 0) >= 10 && (
                <Typography className="error-message" color={'error'} style={{ marginBottom: 10 }}>
                    You can not upload more than 10 documents.
                </Typography>
            )}
        </>
    );
};
