import {useContext, useState} from "react";
import {IObjectComment} from "../../../types";
import {ObjectCommentsApi} from "core/api/object-comments.api";
import {UtilsContext} from "core/context/utils.context";
import {UserContext} from "core/context/user";

export const useObjectCommentsData = (objectId: number, pageSize: number): any => {

    const utilsContext = useContext(UtilsContext);
    const userContext = useContext(UserContext);

    const [comments, setComments] = useState<IObjectComment[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [hasMoreComments, setHasMoreComments] = useState(false);

    const getMoreComments = (): void => {
        setCurrentPage(currentPage+1);

        ObjectCommentsApi.getObjectComments(objectId, currentPage+1, pageSize)
            .then((res: any) => {
                const newComments = comments;
                res.data?.data.forEach((comment: IObjectComment) => {
                    if(newComments?.findIndex(c => c.id === comment.id) === -1) {
                        newComments.push(comment);
                    }
                })
                setComments(newComments.slice());
                setHasMoreComments(res.data.totalCount > newComments.length);
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error');
            })
    }

    const addNewComment = async (objectId: number, comment: string): Promise<boolean> => {
        return await ObjectCommentsApi.addComment(objectId, comment)
            .then((res: any) => {
                const newComments = comments;
                newComments.unshift(res.data);
                setComments(newComments.slice());
                return true;
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error');
                return false;
            })
    }

    const editComment = async (commentId: number, comment: string): Promise<boolean> => {
        return ObjectCommentsApi.editComment(commentId, comment)
            .then(() => {
                const commentIndex = comments.findIndex(c => c.id === commentId);
                if(commentIndex > -1) {
                    const newComments = comments;
                    newComments[commentIndex].text = comment;
                    setComments(newComments.slice());
                }
                return true;
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error');
                return false;
            })
    }

    const deleteComment = async (commentId: number): Promise<boolean> => {
        return await ObjectCommentsApi.deleteComment(commentId)
            .then(() => {
                const commentIndex = comments.findIndex(c => c.id === commentId);
                if(commentIndex > -1) {
                    const newComments = comments;
                    newComments.splice(commentIndex, 1);
                    setComments(newComments.slice());
                }
                return true;
            })
            .catch((error: any) => {
                console.error(error.response);
                utilsContext.addSnackbar('error');
                return false;
            })
    }

    const isAllowedToDelete = (objectOwnerId: number, commentCreatedById: number): boolean => {
        return userContext.user?.id === commentCreatedById || userContext.user?.id ===  objectOwnerId;
    }

    const isAllowedToEdit = (commentCreatedById: number): boolean => {
        return userContext.user?.id === commentCreatedById;
    }

    return {
        comments,
        currentPage,
        hasMoreComments,
        getMoreComments,
        addNewComment,
        editComment,
        deleteComment,
        isAllowedToDelete,
        isAllowedToEdit,
    }
}
