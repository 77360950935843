import React, { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { modalStyles } from "components/_others/modals/modal-styles";

import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import DoneIcon from "@material-ui/icons/Done";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


import PinInput from "react-pin-input";
import { PasswordFormControl } from "components/_others/PasswordFormControl";
import { passwordStrength } from "core/helpers/validators";
import { IResetPassword, UserApi } from "core/api/user";

type EnterNewPasswordProps = {
    email: string;
    handleClose: () => void;
    onBack: () => void;
}

export const EnterNewPassword: React.FC<EnterNewPasswordProps> = ({ email, handleClose, onBack}) => {

    const classes = modalStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [reqResult, setReqResult] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [values, setValues] = useState({pin: '', password: ''});
    const [onFocus, setOnFocus] = useState({password: false});
    const [errors, setErrors] = useState({password: ''});

    const onSave = (): void => {
        setFormErrors();
        if(errors.password) {
            return;
        }
        setIsLoading(true);
        setReqResult(false);

        const payload: IResetPassword = {
            email,
            code: values.pin,
            newPassword: values.password
        };

        UserApi.resetPassword(payload)
            .then(() => {
                setIsLoading(false);
                setReqResult(true);
            })
            .catch((error: any) => {
                console.error(error.response);
                if(error.response.data.message === 'InvalidCode') {
                    setErrorMessage('Your confirmation pin is invalid.');
                } else {
                    setErrorMessage('Sorry, there was an error. Please try again later.');
                }
                setIsLoading(false);
                setReqResult(false);
            })
    }

    const setFormErrors = (): void => {
        setErrors({
            password: !onFocus.password && !values.password.length
                ? 'Please enter your password.'
                : !passwordStrength(values.password).valid
                    ? 'Your password is not strong enough'
                    : '',
        });
    }

    return (
        <>
            <Box marginBottom={'20px'}>
                <Typography className={classes.modalSubtitle}>
                    Please enter below the confirmation pin received on your email, and your new password.
                </Typography>
            </Box>

            <Box style={{float: 'left', marginLeft: '-10px'}}>
                <PinInput
                    length={6}
                    initialValue=""
                    type="numeric"
                    inputMode="number"
                    style={{padding: '10px'}}
                    inputStyle={{borderColor: 'red'}}
                    inputFocusStyle={{borderColor: 'blue'}}
                    autoSelect={true}
                    onComplete={(value) => setValues({...values, pin: value})}
                    regexCriteria={/^[0-9]*$/}
                />
            </Box>

            <PasswordFormControl
                value={values.password}
                className={onFocus.password || values.password?.length ? 'mt-30' : ''}
                error={errors.password.length !== 0}
                helperText={errors.password}
                onChange={(e) => {
                    setValues({...values, password: e.target.value});
                    setErrors({...errors, password: ''})
                }}
                onFocus={() => setOnFocus({...onFocus, password: true})}
                onBlur={() => setOnFocus({...onFocus, password: false})}
            />

            {
                !passwordStrength(values.password).valid &&
                <>
                    {
                        <Typography className="error-message" color="error">
                            {
                                !passwordStrength(values.password).length.valid &&
                                <>{passwordStrength(values.password).length.message} <br/></>
                            }
                            {
                                !passwordStrength(values.password).digits.valid &&
                                <>{passwordStrength(values.password).digits.message} <br/></>
                            }
                            {
                                !passwordStrength(values.password).uppercase.valid &&
                                <>{passwordStrength(values.password).uppercase.message} <br/></>
                            }
                            {
                                !passwordStrength(values.password).lowercase.valid &&
                                <>{passwordStrength(values.password).lowercase.message} <br/></>
                            }
                            {
                                !passwordStrength(values.password).specialChars.valid &&
                                <>{passwordStrength(values.password).specialChars.message} <br/></>
                            }
                        </Typography>
                    }
                </>
            }

            {
                !reqResult &&
                <Typography className="error-message" color="error">
                    {errorMessage}
                </Typography>
            }

            {
                reqResult &&
                <Typography style={{color: '#FE6502', fontSize: 13}}>
                    Your password was changed. You can close this screen and login with your new password.
                </Typography>
            }

            <Box textAlign={'right'} marginTop={'60px'}>
                <Button
                    id="cancel-delete-btn"
                    variant="contained"
                    size={"small"}
                    style={{marginRight: '15px'}}
                    className={classes.secondaryButton}
                    onClick={handleClose}
                >
                    <CloseIcon height={'20px'} /> Close
                </Button>

                <Button
                    id="back-btn"
                    variant="contained"
                    size={"small"}
                    style={{marginRight: '15px'}}
                    className={classes.secondaryButton}
                    onClick={onBack}
                >
                    <ArrowBackIosIcon style={{height: '20px'}}/> Back
                </Button>

                <Button
                    id="delete-btn"
                    variant="contained"
                    color="primary"
                    size={"small"}
                    className={classes.primaryButton}
                    disabled={
                        values.pin.length !== 6 ||
                        !passwordStrength(values.password).valid ||
                        !values.password
                    }
                    onClick={() => onSave()}
                >
                    {
                        isLoading && <CircularProgress className="circular-progress sm" />
                    }
                    {
                        !isLoading && !reqResult && <SaveIcon style={{
                            width: 20, marginRight: 5
                        }} />
                    }
                    {
                        reqResult &&
                        <>
                            <DoneIcon />
                        </>
                    }
                    Save
                </Button>
            </Box>
        </>
    )
}
