import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Box,
    Checkbox,
    FormControlLabel, Grid, InputAdornment,
    InputLabel,
    Radio,
    RadioGroup, TextField,
    ThemeOptions,
    Typography
} from "@material-ui/core";

import { getEstimatedDisplayValue } from "core/helpers";
import { managePriceRangesError } from "components/_consumer/ObjectForm/helpers/errors.manager";
import {NumberInput} from "../../../../../../../components/_others/NumberInput";

type ApproximateValueProps = {
    id: string;
    label: string;
    disabled: boolean;
    setError: (hasError: boolean) => void;
    data: {
        exactValue: number|null;
        minimumValue: number|null;
        maximumValue: number|null;
    };
    draftData: {
        exactValue: number|null;
        minimumValue: number|null;
        maximumValue: number|null;
    } | null
    setValue: (v: any) => void;
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
    root: {
        marginBottom: 20
    },
    labelSm: {
        fontSize: 14,
        marginBottom: 5,
        color: theme.palette?.text?.primary,
    },
    focusLabel: {
        fontSize: 14,
        color: "#FE6502",
        marginBottom: 5,
    },
    errorLabel: {
        fontSize: 14,
        color: "#B32222",
        marginBottom: 5,
    },
    collectorInputLabel: {
        color: theme.palette?.text?.secondary,
        fontSize: 14,
        marginBottom: 5,
    },
    collectorInput: {
        color: theme.palette?.text?.primary,
        marginLeft: 4
    }
}));

export const ApproximateValue: React.FC<ApproximateValueProps> = (
    {id, label, setError, data, setValue, disabled, draftData}
) => {

    const classes = useStyles();

    const collectorInput = getEstimatedDisplayValue(
        data.exactValue,
        data.minimumValue,
        data.maximumValue
    );

    const [onFocus, setOnFocus] = useState('');
    const [estimatedValueOption, setEstimatedValueOption] = useState('');
    const [accurateStatement, setAccurateStatement] = useState(false);

    const [values, setValues] = useState({
        exactValue: '',
        minimumValue: '',
        maximumValue: '',
    })

    const [errors, setErrors] = useState({
        estimatedRanges: '',
    })

    useEffect(() => {
        if(disabled) {
            setValuesByCollectorInput();
        }

        if(draftData && !disabled) {
            setEstimatedValueOption(draftData.exactValue !== null ? 'exact':'range');

            setValues({
                exactValue: draftData.exactValue?.toString() || '0',
                minimumValue: draftData.minimumValue?.toString() || '0',
                maximumValue: draftData.maximumValue?.toString() || '0',
            })
        }
    }, [])

    useEffect(() => {
        setValue({
            exactValue: data.exactValue?.toString() || '0',
            minimumValue: data.minimumValue?.toString() || '0',
            maximumValue: data.maximumValue?.toString() || '0',
        })
    }, [values])

    const setValuesByCollectorInput = (): void => {
        setEstimatedValueOption(data.exactValue !== null ? 'exact':'range');

        setValues({
            exactValue: data.exactValue?.toString() || '0',
            minimumValue: data.minimumValue?.toString() || '0',
            maximumValue: data.maximumValue?.toString() || '0',
        })
        setError(false);
    }

    const manageErrors = (): void => {
        const errors: any = {
            estimatedRanges: estimatedValueOption === 'range'
                ? managePriceRangesError(values.minimumValue, values.maximumValue)
                : '',
        }

        const hasErrors = errors.estimatedRanges !== '';

        setErrors({...errors});
        setError(hasErrors);
        setValue(values);
    }

    return (
        <Box className={classes.root}>
            <InputLabel
                htmlFor={id}
                className={onFocus  ? classes.focusLabel : classes.labelSm}
            >
                {label}
            </InputLabel>

            {
                !disabled &&
                <Box display={'flex'} alignItems={'center'}>
                    <Typography className={classes.collectorInputLabel}>
                        Collector's input:
                        <span className={classes.collectorInput}>
                        {
                            collectorInput === 'unknown' ? 'no collector input' : collectorInput
                        }
                    </span>
                    </Typography>

                    {
                        collectorInput !== 'unknown' &&
                        <FormControlLabel
                            style={{marginBottom: 5, marginLeft: 15}}
                            control={
                                <Checkbox
                                    name="accurate-statement"
                                    color="secondary"
                                    style={{ transform: "scale(0.8)" }}
                                    checked={accurateStatement}
                                    onChange={() => {
                                        if(!accurateStatement) {
                                            setValuesByCollectorInput();
                                        }
                                        setAccurateStatement(!accurateStatement);
                                    }}
                                />
                            }
                            label={
                                <Typography className="checkbox-label" color="textSecondary" style={{marginLeft: -5}}>
                                    This statement is accurate.
                                </Typography>
                            }
                        />
                    }
                </Box>
            }

            <RadioGroup
                row
                aria-label="estimatedValue"
                name="estimatedValue"
                value={estimatedValueOption}
                onChange={(e: any) => {
                    setEstimatedValueOption(e.target.value);
                    if(e.target.value === 'exact') {
                        setValues({
                            ...values,
                            minimumValue: '',
                            maximumValue: '',
                        })
                    } else {
                        setValues({
                            ...values,
                            exactValue: '',
                        })
                    }
                }}
            >
                <FormControlLabel value="exact" control={
                    <Radio disabled={accurateStatement || disabled} />
                } label="Exact Value" />
                <FormControlLabel value="range" control={
                    <Radio disabled={accurateStatement || disabled} />
                } label="Range" />
            </RadioGroup>

            {
                estimatedValueOption === 'exact' &&
                <>
                    <TextField
                        id="exactValue"
                        variant="outlined"
                        size="small"
                        type="number"
                        fullWidth
                        disabled={accurateStatement || disabled}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            inputComponent: NumberInput,
                        }}
                        value={values.exactValue}
                        onChange={(e: any) => setValues({
                            ...values,
                            minimumValue: '',
                            maximumValue: '',
                            exactValue: e.target.value
                        })}
                        onFocus={() => setOnFocus('exactValue')}
                        onBlur={() => {setOnFocus(''); manageErrors()}}
                    />
                </>
            }

            {
                estimatedValueOption === 'range' &&
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <InputLabel
                            htmlFor="minimumValue"
                            className={onFocus === 'minimumValue' ? classes.focusLabel : ''}
                        >
                            Min
                        </InputLabel>
                        <TextField
                            id="minimumValue"
                            variant="outlined"
                            size="small"
                            type="number"
                            fullWidth
                            disabled={accurateStatement || disabled}
                            helperText={errors.estimatedRanges}
                            error={errors.estimatedRanges !== ''}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                inputComponent: NumberInput,
                            }}
                            value={values.minimumValue}
                            onChange={(e: any) => setValues({
                                ...values,
                                minimumValue: e.target.value,
                                exactValue: ''
                            })}
                            onFocus={() => setOnFocus('minimumValue')}
                            onBlur={() => {setOnFocus(''); manageErrors()}}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel
                            htmlFor="maximumValue"
                            className={onFocus === 'maximumValue' ? classes.focusLabel : ''}
                        >
                            Max
                        </InputLabel>
                        <TextField
                            id="maximumValue"
                            variant="outlined"
                            size="small"
                            type="number"
                            fullWidth
                            disabled={accurateStatement || disabled}
                            error={errors.estimatedRanges !== ''}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                inputComponent: NumberInput,
                            }}
                            value={values.maximumValue}
                            onChange={(e: any) => setValues({
                                ...values,
                                maximumValue: e.target.value,
                                exactValue: ''
                            })}
                            onFocus={() => setOnFocus('maximumValue')}
                            onBlur={() => {setOnFocus(''); manageErrors()}}
                        />
                    </Grid>
                </Grid>
            }
        </Box>
    )
}
