import React from "react";
import { Typography } from "@material-ui/core";
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { SectionHeader } from "./SectionHeader";
import {IFeedbackComment, IFeedbackResult} from "core/context/feedback-requests/feedback";
import {Chat} from "../../../scenes/consumer/objectDetails/components/Chat";

type FeedbacksProps = {
    artifactId?: number;
    activeFeedbackRequestId?: number;
    hideSectionHeader?: boolean;
    feedbacks: IFeedbackResult[];
    displayFeedbackRequestMessage?: string;
    onSelect: (feedback: IFeedbackResult) => void;
    activeFeedbacksComments: IFeedbackComment[];
}

export const Feedbacks: React.FC<FeedbacksProps> = (
    {hideSectionHeader, feedbacks, displayFeedbackRequestMessage,
        onSelect, activeFeedbacksComments, artifactId, activeFeedbackRequestId}
) => {
    return (
        <>
            {
                hideSectionHeader !== true && <SectionHeader title={'Object Feedback'} />
            }

            {
                displayFeedbackRequestMessage &&
                    <Typography className="error-message" color="error">
                        {displayFeedbackRequestMessage}
                    </Typography>
            }

            {
                feedbacks.map(f => (
                    <FileCopyIcon
                        key={f.id}
                        onClick={() => onSelect(f)}
                        className="document-icon"
                        style={!f.isRead ? {color: '#FE6502'} : {}}
                    />
                ))
            }

            {
                feedbacks?.length === 0 &&
                <Typography style={{fontSize: 13, marginBottom: 10}}>
                    No feedback
                </Typography>
            }

            {
                activeFeedbacksComments?.length > 0 &&
                    <>
                        <SectionHeader title={'Chat with Expert'} />
                        <Chat
                            artifactId={artifactId || -1}
                            feedbackRequestId={activeFeedbackRequestId || -1}
                            isFeedbackCompleted={false}
                            comments={activeFeedbacksComments}
                        />
                    </>
            }

        </>
    )
}
