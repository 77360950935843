import React from "react";
import { Box, ThemeOptions, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

type ShareOptionProps = {
    title: string;
    description: string;
    color: string;
    onSelect: () => void;
}

export const ShareOption: React.FC<ShareOptionProps> = ({title, description, color, onSelect}) => {

    const classes = useStyles();

    return (
        <Box className={classes.root} onClick={onSelect} style={{borderColor: color}}>
            <Typography color={'textPrimary'} className={classes.title}>{title}</Typography>
            <Typography color={'textSecondary'} className={classes.description}>{description}</Typography>
        </Box>
    )
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
    root: {
        backgroundColor: theme.rootBackground.secondary,
        borderRadius: 2,
        marginBottom: 15,
        cursor: 'pointer',
        borderLeft: '7px solid',
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
        '&:hover': {
            boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.16)'
        }
    },
    title: {
        fontSize: 18,
        lineHeight: 1.3,
    },
    description: {
        fontSize: 14,
        lineHeight: 1.3,
    }
}))
